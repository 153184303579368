import StylFit from './StylModules/Fit.module.css';

export const BasicImageSlide = (props) => {


    const item = props.databind.item;


	return(
		<div
		style={{
			width:"100%",
			height:"100%"
		}}
		>

            {
            item.image.src.match(/http/gi) 
            ?

                <img
                className={StylFit.Img}
                // style={{


                //     height:"auto",

                //     minWidth:item.image.maxWidth,
                //     minHeight: "100%",

                //     maxWidth:"auto",
                //     maxHeight:item.image.maxHeight,

                //     marginTop:item.image.top,
                //     marginLeft:item.image.left,


                // }}


                    src={item.image.src}
                    //src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                    //src={window.location.origin + box.image.src} 
                    //width={box.image.w}
                    //height={box.image.h} 
                    alt='noimage'
                /> 



            :


                <img
                className={StylFit.Img}
                // style={{


                //     height:"auto",

                //     minWidth:item.image.maxWidth,
                //     minHeight: "100%",

                //     maxWidth:"auto",
                //     maxHeight:item.image.maxHeight,

                //     marginTop:item.image.top,
                //     marginLeft:item.image.left,


                // }}

                    src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                    //src={window.location.origin + box.image.src} 
                    //width={box.image.w}
                    //height={box.image.h} 
                    alt='noimage'
                /> 


            }

		</div>

	);//return
}
