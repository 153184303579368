export const HELMET_BLUE_SOLID_LAGUNA_BLUE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M295.31,140.592c15.959,0,28.52,9.59,31.176,23.92l3.755,20.187 c4.622,1.241,1.783,8.212,0.386,11.543l-2.126,5.008c-0.975,2.013-2.859,5.337-4.996,5.281l-6.514-0.342 c-3.291-0.191-6.769-0.616-10.016-1.176l-39.141-6.901c-2.97-7.738-5.789-16.426-5.789-26.26	C262.045,152.664,276.121,140.592,295.31,140.592L295.31,140.592z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(23,71,158)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c26.055,0,30.001,22.563,30.001,22.563l3.89,20.986c3.744,0,2.264,5.341,0.289,9.992l-2.115,4.983 c-0.649,1.53-2.321,2.045-3.865,1.964l-6.571-0.344c-2.552-0.135-7.379-0.795-9.718-1.207l-38.247-6.744 C265.748,186.053,263.274,179.661,263.274,171.851L263.274,171.851z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(0,91,170)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338	l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248	l-6.552-0.344c-1.833-0.109-3.734-0.318-5.565-0.569l2.025-4.762c0.626-1.493,4.915-11.167,1.597-11.751l-0.694-0.123l-3.55-19.154 c-0.264-1.425-2.542-15.416-16.416-21.3C294.689,143.494,294.986,143.48,295.307,143.473L295.307,143.473z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(0,125,197)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338	l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248	l-4.869-0.256l1.96-4.615c0.656-1.563,5.192-10.655,1.716-11.268l-0.137-0.024l-3.807-20.54 c-0.938-5.063-4.646-17.723-23.346-21.332C295.15,143.477,295.227,143.475,295.307,143.473L295.307,143.473z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(0,114,188)" 
        d="M301.271,198.808l-30.487-5.376 c-2.691-7.023-6.208-13.934-6.284-21.573c0.185-7.932,3.001-14.31,7.501-18.997c-1.001,7.713-5.249,16.844,6.48,40.427 l23.384,4.121L301.271,198.808L301.271,198.808z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(2,63,136)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c3.355,0,6.343,0.377,9.004,1.029c-3.046-0.186-5.94-0.361-8.969-0.091c-10.236,1.335-14.512,7.489-17.487,16.626l-9.349,33.424 C265.514,185.43,263.274,179.283,263.274,171.851L263.274,171.851z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(3,73,154)" 
        d="M269.416,189.992c-2.382-5.863-4.854-11.744-4.917-18.134 c0.185-7.932,3.001-14.31,7.501-18.997c-0.763,5.877-3.409,12.58,0.564,25.873L269.416,189.992L269.416,189.992z"
    />


    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M296.008,159.869c1.348-1.318,2.801-1.846,4.589-1.877	c1.384,0.087,2.493,0.447,3.72,1.144l4.868,2.983c1.511,0.874,3.318,1.426,5.068,1.468h10.462c2.343,0.171,3.435,1.169,3.916,3.433 l3.176,17.135c0.246,1.495-0.053,2.72-1.793,2.867h-3.879c-1.25-0.016-2.475-0.183-3.704-0.393l-15.239-2.687 c-4.283-2.877-7.226-6.723-9.666-11.216c-0.744-1.408-1.431-2.842-2.09-4.291C294.135,165.568,293.826,162.416,296.008,159.869	L296.008,159.869z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(100,100,100)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.365,167.523c-2.801-6.212,2.553-10.267,7.328-7.334 l4.865,2.981c1.674,1.025,4.138,1.64,5.68,1.64h10.432c2.082,0,2.518,1.143,2.76,2.447l3.171,17.107 c0.182,0.979,0.125,1.432-0.638,1.432l-5.182,0.004l-17.122-3.02C301.553,178.843,298.28,171.768,296.365,167.523L296.365,167.523z M301.424,160.654c-2.267,0-4.115,1.849-4.115,4.115s1.849,4.115,4.115,4.115c2.268,0,4.115-1.849,4.115-4.115 S303.691,160.654,301.424,160.654L301.424,160.654z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M324.67,164.811h-10.432h-0.102l3.662,19.76l6.982,1.23 l4.418-0.003l-3.882-20.944C325.121,164.825,324.906,164.811,324.67,164.811L324.67,164.811z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(230,230,230)" 
        points="318.42,164.811 324.021,164.811 327.912,185.797 324.762,185.797 322.227,185.351 318.42,164.811 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M301.424,161.135c2.002,0,3.636,1.633,3.636,3.635	s-1.634,3.635-3.636,3.635s-3.635-1.633-3.635-3.635S299.422,161.135,301.424,161.135L301.424,161.135z M301.424,162.477 c-1.263,0-2.293,1.03-2.293,2.293c0,1.264,1.03,2.294,2.293,2.294c1.264,0,2.294-1.03,2.294-2.294	C303.718,163.507,302.688,162.477,301.424,162.477L301.424,162.477z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M309.85,166.428c0-0.748-0.611-1.359-1.36-1.359s-1.36,0.611-1.36,1.359 c0,0.313,0.11,0.599,0.289,0.829c-0.572,1.412-1.606,2.598-2.961,3.249c-1.514,0.68-0.701,2.139,0.471,1.58 c1.85-0.891,3.301-2.468,4.05-4.392C309.486,167.497,309.85,167.005,309.85,166.428z"
    />
    {/*Suit*/}
    <polygon 
        fill="rgb(23,71,158)" 
        points="363.313,198.04 353.853,201.166 364.894,201.938 363.313,198.04 	"
    />
    

    

		</svg>
		</div>

	);//return
}