import styled, { keyframes } from 'styled-components';



export const TransparencyLayer = styled.div.attrs(props => ({


    backgroundColor:            props.backgroundColor || "transparent",//#fcfcfc

    // borderTop:                  props.borderTop || "0",
    // borderBottom:               props.borderBottom || "0",
    // borderLeft:                 props.borderLeft || "0",
    // borderRight:                props.borderRight || "0",

    // borderTopLeftRadius:        props.borderTopLeftRadius || "10px",
    // borderTopRightRadius:       props.borderTopRightRadius || "10px",
    // borderBottomLeftRadius:     props.borderBottomLeftRadius || "0px",
    // borderBottomRightRadius:    props.borderBottomRightRadius || "0px",


    width:              props.width || "100%", 
    height:             props.height || "400px",

    margin:             props.margin || "0 0 0 0", 
    padding:            props.padding || "0 0 0 0",

    zIndex:             props.zIndex || "21", 

    yPos:               props.yPos || "calc(0% + 0px)", 
    xPos:               props.xPos || "0%",
    //bottom:             props.bottom || "calc(100% - 50px)",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",



    // border-top:${props => props.borderTop};
    // border-bottom:${props => props.borderBottom};
    // border-left:${props => props.borderLeft};
    // border-right:${props => props.borderRight};


    // border-top-left-radius:${props => props.borderTopLeftRadius};
    // border-top-right-radius: ${props => props.borderTopRightRadius};
    // border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
    // border-bottom-right-radius: ${props => props.borderBottomRightRadius};





}))`
  
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    margin:${props => props.margin};
    padding:${props => props.padding};

    position:absolute;   
    z-index:${props => props.zIndex};

    left:${props => props.xPos};
    bottom:${props => props.yPos};

    @media (min-width: 901px) {

        height:400px;
    }
    @media (max-width: 900px) {

        height:400px; 
    }
    @media (max-height: 500px) {

        height:300px; 
    }
    @media (max-height: 350px) {

        height:250px; 
    }





    opacity:0.6;


    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;




export const Container = styled.div.attrs(props => ({


    backgroundColor:            props.backgroundColor || "transparent",//#fcfcfc

    // borderTop:                  props.borderTop || "0",
    // borderBottom:               props.borderBottom || "0",
    // borderLeft:                 props.borderLeft || "0",
    // borderRight:                props.borderRight || "0",

    // borderTopLeftRadius:        props.borderTopLeftRadius || "10px",
    // borderTopRightRadius:       props.borderTopRightRadius || "10px",
    // borderBottomLeftRadius:     props.borderBottomLeftRadius || "0px",
    // borderBottomRightRadius:    props.borderBottomRightRadius || "0px",


    width:              props.width || "100%", 
    height:             props.height || "400px",

    margin:             props.margin || "0 0 0 0", 
    padding:            props.padding || "0 0 0 0",

    zIndex:             props.zIndex || "22", 

    yPos:               props.yPos || "calc(0% + 0px)", 
    xPos:               props.xPos || "0%",
    //bottom:             props.bottom || "calc(100% - 50px)",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",



    // border-top:${props => props.borderTop};
    // border-bottom:${props => props.borderBottom};
    // border-left:${props => props.borderLeft};
    // border-right:${props => props.borderRight};


    // border-top-left-radius:${props => props.borderTopLeftRadius};
    // border-top-right-radius: ${props => props.borderTopRightRadius};
    // border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
    // border-bottom-right-radius: ${props => props.borderBottomRightRadius};





}))`
  
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    margin:${props => props.margin};
    padding:${props => props.padding};

    position:absolute;   
    z-index:${props => props.zIndex};

 
    left:${props => props.xPos};
    bottom:${props => props.yPos};


    @media (min-width: 901px) {

        height:400px;
    }
    @media (max-width: 900px) {

        height:400px; 
    }
    @media (max-height: 500px) {

        height:300px; 
    }
    @media (max-height: 350px) {

        height:250px; 
    }





    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;






//######################################################
//######################################################

// TM: 


const animateTopSection = (props) => keyframes`

    from {
        margin-left:${props.marginLeftFrom};
        opacity: ${props.opacityFrom};
    }

    to {
        margin-left:${props.marginLeftTo};
        opacity: ${props.opacityTo};
    }


`;



export const TopSection = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "60%", 
    height:             props.height || "auto",

    margin:             props.margin || "0 0 0 15%", 
    padding:            props.padding || "0 0 0 0",

    position:           props.position || "relative", 
    float:              props.float || "left",


    //animation variables

    out:                    props.out || false,
    animationTime:          props.animationTime || '0.4s',
    transitionTime:         props.transitionTime || '0.4s',

    opacity:                props.opacity || "1.0",
    marginLeft:             props.marginLeft || "700px"





}))`
  
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    margin:${props => props.margin};
    padding:${props => props.padding};

    position:${props => props.position};
    float:${props => props.float};

    overflow:hidden;


    @media (max-width: 900px) {

        width:84%;
        margin-left:8%;
        
    }


    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    transition: visibility ${props => props.transitionTime} linear;
    animation: ${props => props.out 
        ? 
            animateTopSection({

                opacityFrom:props.opacity,
                opacityTo:props.opacity,

                marginLeftFrom:"0px",
                marginLeftTo:"-"+props.marginLeft,
        
            }) 

        :null    
        } ${props => props.animationTime} linear;




`;




    //=================================================
    //Category
    //=================================================


    const animateCategory = (props) => keyframes`

        from {
            margin-left:${props.marginLeftFrom};
            opacity: ${props.opacityFrom};
        }

        to {
            margin-left:${props.marginLeftTo};
            opacity: ${props.opacityTo};
        }


    `;

    export const Category = styled.div.attrs(props => ({


        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
        border:             props.border || "0",

    
        width:            props.width || "90%", 
        height:           props.height || "auto",

        margin:            props.margin || "0 0 0 0", 
        padding:           props.padding || "5px 0 5px 0",

        position:           props.position || "relative", 
        float:              props.float || "left",


        fontFamily:         props.fontFamily || '"Arial", sans-serif',
        fontSize:           props.fontSize || "16px",
        fontWeight:         props.fontWeight || "600",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "#fff",



        //animation variables

        out:                    props.out || false,
        animationTime:          props.animationTime || '0.32s',
        transitionTime:         props.transitionTime || '0.32s',

        opacity:                props.opacity || "1.0",
        marginLeft:             props.marginLeft || "200px"


    }))`
    

        background-color:${props => props.backgroundColor};
        border:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};

        margin:${props => props.margin};
        padding:${props => props.padding};

        position:${props => props.position};
        float:${props => props.float};

        overflow:hidden;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};



        display: inline-block;
        visibility: ${props => props.out ? 'hidden' : 'visible'};
        transition: visibility ${props => props.transitionTime} linear;
        animation: ${props => props.out 
            ? 
                animateCategory({
    
                    opacityFrom:props.opacity,
                    opacityTo:"0",

                    marginLeftFrom:"0px",
                    marginLeftTo:"0px"
                    //marginLeftTo:"-"+props.marginLeft,
            
                }) 
            :     
                animateCategory({
    
                    opacityFrom:"0",
                    opacityTo:props.opacity,

                    marginLeftFrom:props.marginLeft,
                    marginLeftTo:"0px"

                })
    
            } ${props => props.animationTime} linear;
    



    `;








    //=================================================
    //Title
    //=================================================

    const animateTitle = (props) => keyframes`


        from {
            margin-left:${props.marginLeftFrom};
            opacity: ${props.opacityFrom};
        }

        to {
            margin-left:${props.marginLeftTo};
            opacity: ${props.opacityTo};
        }


    `;

    export const Title = styled.div.attrs(props => ({


        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
        border:             props.border || "0",

    
        width:              props.width || "90%", 
        height:             props.height || "auto",

        margin:             props.margin || "0 0 25px 0", 
        padding:            props.padding || "5px 0 5px 0",

        position:           props.position || "relative", 
        float:              props.float || "left",

        fontFamily:         props.fontFamily || '"Arial", sans-serif',
        fontSize:           props.fontSize || "45px",
        fontWeight:         props.fontWeight || "600",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "#fff",



        //animation variables

        out:                    props.out || false,
        animationTime:          props.animationTime || '0.25s',
        transitionTime:         props.transitionTime || '0.25s',

        opacity:                props.opacity || "1.0",
        marginLeft:             props.marginLeft || "200px"



        // transition: opacity 0.9s;
        // opacity:${props => props.opacity};


    }))`
    

        background-color:${props => props.backgroundColor};
        border:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};

        margin:${props => props.margin};
        padding:${props => props.padding};

        position:${props => props.position};
        float:${props => props.float};

        overflow:hidden;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};



        @media (max-width: 1025px) {

            font-size:35px;
        
        }
        @media (max-width: 800px) {

            font-size:30px;
  
        }
        @media (max-height: 500px) {

            font-size:25px; 
        }
    






        display: inline-block;
        visibility: ${props => props.out ? 'hidden' : 'visible'};
        transition: visibility ${props => props.transitionTime} linear;
        animation: ${props => props.out 
            ? 
                animateTitle({
    
                    opacityFrom:props.opacity,
                    opacityTo:"0",

                    marginLeftFrom:"0px",
                    marginLeftTo:"0px"
                    //marginLeftTo:"-"+props.marginLeft,
    
                }) 
            :     
                animateTitle({
    
                    opacityFrom:"0",
                    opacityTo:props.opacity,

                    marginLeftFrom:props.marginLeft,
                    marginLeftTo:"0px"

    
                })
    
            } ${props => props.animationTime} linear;



    `;






    //=================================================
    //Button
    //=================================================


    const animateButton = (props) => keyframes`

        from {
            margin-left:${props.marginLeftFrom};
            opacity: ${props.opacityFrom};
        }

        to {
            margin-left:${props.marginLeftTo};
            opacity: ${props.opacityTo};
        }


    `;

    export const Button = styled.div.attrs(props => ({


        backgroundColor:    props.backgroundColor || "#029cd1",//#fcfcfc
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "5px",

        width:              props.width || "200px", 
        height:             props.height || "auto",

        margin:             props.margin || "0 0 0 0", 
        padding:            props.padding || "14px 0 14px 0",

        position:           props.position || "relative", 
        float:              props.float || "left",

        fontFamily:         props.fontFamily || '"Arial", sans-serif',
        fontSize:           props.fontSize || "14px",
        fontWeight:         props.fontWeight || "600",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "white",




        backgroundColorHover:    props.backgroundColorHover || "#02aef6",//#fcfcfc


        //animation variables

        out:                    props.out || false,
        animationTime:          props.animationTime || '0.2s',
        transitionTime:         props.transitionTime || '0.2s',

        opacity:                props.opacity || "1.0",
        marginLeft:             props.marginLeft || "200px"




        //Blue

        // HEX: #02aef6
        // RGB: 2,174,246
        // CMYK: 99,29,0,4
        
        // HEX: #02a0e2
        // RGB: 2,160,226
        // CMYK: 99,29,0,11
        
        // HEX: #0291cd
        // RGB: 2,145,205
        // CMYK: 99,29,0,20



        // Orange

        // HEX: #ff6100
        // RGB: 255,97,0
        // CMYK: 0,62,100,0
        
        // HEX: #ff5c00
        // RGB: 255,92,0
        // CMYK: 0,64,100,0
        
        // HEX: #ff5700
        // RGB: 255,87,0
        // CMYK: 0,66,100,0
        
        // HEX: #ff5200
        // RGB: 255,82,0
        // CMYK: 0,68,100,0
        
        // HEX: #ff4d00
        // RGB: 255,77,0
        // CMYK: 0,70,100,0




        //Darker Orange

        // HEX: #ff3d00
        // RGB: 255,61,0
        // CMYK: 0,76,100,0
        
        // HEX: #ff3800
        // RGB: 255,56,0
        // CMYK: 0,78,100,0
        
        // HEX: #ff3300
        // RGB: 255,51,0
        // CMYK: 0,80,100,0




    }))`
    

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${props => props.height};

        margin:${props => props.margin};
        padding:${props => props.padding};

        position:${props => props.position};
        float:${props => props.float};

        overflow:hidden;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        cursor:pointer;


        &:hover{


            background-color:${props => props.backgroundColorHover};


        }



        display: inline-block;
        visibility: ${props => props.out ? 'hidden' : 'visible'};
        transition: visibility ${props => props.transitionTime} linear;
        animation: ${props => props.out 
            ? 
                animateButton({
    
                    opacityFrom:props.opacity,
                    opacityTo:"0",

                    marginLeftFrom:"0px",
                    marginLeftTo:"0px"
                    //marginLeftTo:"-"+props.marginLeft,
    
                }) 
            :     
                animateButton({
    
                    opacityFrom:"0",
                    opacityTo:props.opacity,

                    marginLeftFrom:props.marginLeft,
                    marginLeftTo:"0px"

    
                })
    
            } ${props => props.animationTime} linear;





    `;










//######################################################
//######################################################
// TM: 


const animateBottomSection = (props) => keyframes`

    from {
        margin-left:${props.marginLeftFrom};
        opacity: ${props.opacityFrom};
    }

    to {
        margin-left:${props.marginLeftTo};
        opacity: ${props.opacityTo};
    }


`;


export const BottomSection = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "100%", 
    height:             props.height || "40px",

    margin:             props.margin || "60px 0 0 0", 
    padding:            props.padding || "0 0 0 0",

    position:           props.position || "relative", 
    float:              props.float || "left",



    //animation variables

    out:                    props.out || false,
    animationTime:          props.animationTime || '0.2s',
    transitionTime:         props.transitionTime || '0.2s',

    opacity:                props.opacity || "1.0",
    marginLeft:             props.marginLeft || "700px"


    // position:${props => props.position};
    // float:${props => props.float};

}))`
    
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    margin:${props => props.margin};
    padding:${props => props.padding};


    position:absolute;
    bottom:25px;
    left:0px;
    z-index:1;

    overflow:hidden;


    @media (max-height: 500px) {

        margin-top:30px; 
    }




    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    transition: visibility ${props => props.transitionTime} linear;
    animation: ${props => props.out 
        ? 
            animateBottomSection({

                opacityFrom:props.opacity,
                opacityTo:"0",

                marginLeftFrom:"0px",
                marginLeftTo:"0px"
        
            }) 

        :
        
            animateBottomSection({

                opacityFrom:"0",
                opacityTo:props.opacity,

                marginLeftFrom:"0px",
                marginLeftTo:"0px"
        
            }) 


        } ${props => props.animationTime} linear;





`;




    export const Navigation = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",

        width:              props.width || "auto", 
        height:             props.height || "auto",

        margin:             props.margin || "0 0 0 0", 
        padding:            props.padding || "0 0 0 0",

        position:           props.position || "absolute", 
        zIndex:             props.zIndex || "2",
        yPos:               props.yPos || "0px",
        xPos:               props.xPos || "calc(50% - 39px)",


        fontFamily:         props.fontFamily || '"Arial", sans-serif',
        fontSize:           props.fontSize || "14px",
        fontWeight:         props.fontWeight || "600",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "white",



    }))`
        
        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};

        margin:${props => props.margin};
        padding:${props => props.padding};

        position:${props => props.position};
        z-index:${props => props.zIndex};
        top:${props => props.yPos};
        left:${props => props.xPos};

        overflow:hidden;


        .Circle {

            background-color:white;
            border:2px solid white;
            border-radius:12px;

            width:12px;
            height:12px;

            margin:0 5px 0 5px;
            padding:0 0 0 0;

            position:relative;
            float:left;

            overflow:hidden;

            font-family:${props => props.fontFamily};     
            font-size: ${props => props.fontSize};
            font-weight: ${props => props.fontWeight};
            text-align: ${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            cursor:pointer;

        }

        .Circle:hover{


            background-color:#0291cd;
            border:2px solid #0291cd;

        }





    `;










export const ProcessBar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#0291cd",

    width:              props.width || "80%", 
    height:             props.height || "5px",

    margin:             props.margin || "6px 0 0 0", 
    padding:            props.padding || "0 0 0 0",

    //position:           props.position || "relative", 
    //float:              props.float || "left",


    position:           props.position || "absolute", 
    zIndex:             props.zIndex || "10",
    yPos:               props.yPos || "0px",
    xPos:               props.xPos || "0px",





}))`
    
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    margin:${props => props.margin};
    padding:${props => props.padding};


    position:${props => props.position};
    z-index:${props => props.zIndex};
    bottom:${props => props.yPos};
    left:${props => props.xPos};

    overflow:hidden;

`;














// export const ArrowButton = styled.div.attrs(props => ({


//     backgroundColor:    props.backgroundColor || "transparent",// #fcfcfc  -  #07a2e3
//     border:             props.border || "0",
//     borderRadius:       props.borderRadius || "50%",

//     width:              props.width || "80%", 
//     height:             props.height || "auto",

//     margin:             props.margin || "0 1px 0 1px", 
//     padding:            props.padding || "7px 7px 7px 7px",

//     position:           props.position || "relative", 
//     float:              props.float || "left",

//     fontFamily:         props.fontFamily || '"Verdana", sans-serif',
//     fontSize:           props.fontSize || "40px",
//     fontWeight:         props.fontWeight || "normal",
//     textAlign:          props.textAlign || "center",
//     textDecoration:     props.textDecoration || "none",
//     color:              props.color || "#fff",




//     // alfa

//     alfaPercent:         props.alfaPercent || '40',
//     alfaDecimal:         props.alfaDecimal || "0.4",






// }))`
  

//     background-color:${props => props.backgroundColor};
//     border:${props => props.border};
//     border-radius:${props => props.borderRadius};

//     width:${props => props.width};
//     height:${props => props.height};

//     margin:${props => props.margin};
//     padding:${props => props.padding};

//     position:${props => props.position};
//     float:${props => props.float};

//     overflow:hidden;

//     font-family:${props => props.fontFamily};     
//     font-size: ${props => props.fontSize};
//     font-weight: ${props => props.fontWeight};
//     text-align: ${props => props.textAlign};
//     text-decoration:${props => props.textDecoration};
//     color:${props => props.color};



//     .arrowbox-alfa{
                
//         background-color:black;

//         width:100%;
//         height:100%;
//         padding:0 0 0 0;
//         margin:0 0 0 0;

//         position:relative;   
//         float:left;

//         -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.alfaPercent})";
//         filter : alpha(opacity=${props => props.alfaPercent});
//         -moz-opacity : ${props => props.alfaDecimal};
//         -khtml-opacity : ${props => props.alfaDecimal};
//         opacity : ${props => props.alfaDecimal};

//     }

//     .arrowbox-bg{
                
//         background-color:white;
//         width:100%;
//         height:100%;
//         padding:0 0 0 0;
//         margin:0 0 0 0;

//         position:absolute;   
//         z-index:1;
//         top:0;
//         left:0;

//     }



//     .arrowbox-mask{
                
//         background-color:transparent;
//         width:100%;
//         height:100%;
//         padding:0 0 0 0;
//         margin:0 0 0 0;

//         position:absolute;   
//         z-index:5;
//         top:0;
//         left:0;

//     }



//     .arrowbox-base{
                
//         background-color:transparent;
//         width:25px;
//         height:20px;
//         padding:0 0 0 0;
//         margin:0 0 0 0;

//         position:absolute;   
//         z-index:3;
//         top:20px;
//         left:12.5px;

//     }

//     .arrowbox{
                
//         background-color:transparent;
//         width:25px;
//         height:20px;
//         padding:0 0 0 0;
//         margin:16px 0 0 12px;

//         position:relative;   
//         float:left;


//     }



//         .arrow-left {
        
//             width: 0; 
//             height: 0; 
//             border-top: 16px solid transparent;
//             border-bottom: 16px solid transparent; 
            
//             border-right:16px solid #454D5F; 
        
        
//             float:left;
//             margin: 0 0 0 0;
//             padding:0 0 0 0;	
        
//         }

//         .arrow-right {

//             width: 0; 
//             height: 0; 
//             border-top: 14px solid transparent;
//             border-bottom: 14px solid transparent;
            
//             border-left: 14px solid #454D5F;
        
        
//             float:right;
//             margin: 0 0 0 0;
//             padding:0 0 0 0;	
        
//         }
        



//         .arrow-up {

        
//             width: 0; 
//             height: 0; 
//             border-left: 12px solid transparent;
//             border-right: 12px solid transparent;
            
//             border-bottom: 12px solid darkred;


//             float:left;
//             margin: 0 0 0 0;
//             padding:0 0 0 0;

//         }
    

//         .arrow-down {

//             width: 0; 
//             height: 0; 
//             border-left: 12px solid transparent;
//             border-right: 12px solid transparent;
            
//             border-top: 12px solid darkred;

//             float:left;
//             margin: 0 0 0 0;
//             padding:0 0 0 0;	

//         }
    




// `;



































/*








        //Blue

        // HEX: #02aef6
        // RGB: 2,174,246
        // CMYK: 99,29,0,4
        
        // HEX: #02a0e2
        // RGB: 2,160,226
        // CMYK: 99,29,0,11
        
        // HEX: #0291cd
        // RGB: 2,145,205
        // CMYK: 99,29,0,20



        // Orange

        // HEX: #ff6100
        // RGB: 255,97,0
        // CMYK: 0,62,100,0
        
        // HEX: #ff5c00
        // RGB: 255,92,0
        // CMYK: 0,64,100,0
        
        // HEX: #ff5700
        // RGB: 255,87,0
        // CMYK: 0,66,100,0
        
        // HEX: #ff5200
        // RGB: 255,82,0
        // CMYK: 0,68,100,0
        
        // HEX: #ff4d00
        // RGB: 255,77,0
        // CMYK: 0,70,100,0




        //Darker Orange

        // HEX: #ff3d00
        // RGB: 255,61,0
        // CMYK: 0,76,100,0
        
        // HEX: #ff3800
        // RGB: 255,56,0
        // CMYK: 0,78,100,0
        
        // HEX: #ff3300
        // RGB: 255,51,0
        // CMYK: 0,80,100,0













.controller .button{
			
    background-color    : transparent;
    width       		: 40px;
    height      		: 50px;

    margin      		: 0 0 0 0;
    padding     		: 0 0 0 0;

    position      		: relative;
    float     			: left;

}




.controller .arrowbox{
			
    background-color:transparent;
    width:30px;
    height:41px;
    padding:9px 0 0 0;
    margin:0 0 0 0;
    float:left;

}



    .controller .arrowbox .arrow-left {
    
        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent; 
        
        border-right:16px solid #454D5F; 
    
    
        float:left;
        margin: 0 0 0 9px;
        padding:0 0 0 0;	
    
    }



    .controller .arrowbox .arrow-right {

        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        
        border-left: 16px solid #454D5F;
    
    
        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	
    
    }
      



    .controller .arrowbox .arrow-down {

        width: 0; 
        height: 0; 
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        
        border-top: 25px solid #454D5F;


        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	

    }
  



/******************************************************************
ARROW REFERENCES



.controller .arrowbox .arrow-up {

    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid black;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
  
.controller .arrowbox .arrow-down {

    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid #f00;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
  
.controller .arrowbox .arrow-right {

    width: 0; 
    height: 0; 
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    
    border-left: 25px solid white;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
  
.controller .arrowbox .arrow-left {

    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid blue; 


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}





*/







