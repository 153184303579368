import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import { useCollage } from 'utils/hooks/LiquidFormula/Collage';
//import { useLiqGrid } from 'utils/hooks/LiquidFormula/LiqGrid';

import FixedStyl from './StylModules/Fixed.module.css';
import FlexStyl from './StylModules/Flex.module.css';


export const LiquidImageSlide = (props) => {



    // -- ref -- //
    const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    //const [gridItems, setGridItems] = useState([]);

     // -- multi refs -- //
     const [cellRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
     const setCellRef = (ref) => {
 
         if(ref && ref !== null && ref !== undefined)
         {
             //console.log("ref::::"+ref.id);
             var found;
             found = false;
             for(var i=0; i < cellRefs.length; i++)
             {
                 if(cellRefs[i].id === ref.id) found = true;
             }
             if(!found) cellRefs.push(ref);
             //console.log(cellRefs.length);
 
         }
 
     };

     
 
    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            // if(refA.current.offsetHeight > 0)
            // {
            //     props.handle({
            //         call:"size",
            //         size:{
            //             height:refA.current.offsetHeight
            //         }
            //     });
    
            // } 
            //console.log(JSON.stringify(props,null,2));
            //alert('props');

            //console.log(refA.current.offsetHeight);
    

        } 



        // // This caused reset on every render error

        // if(refA.current.offsetHeight > 0)
        // {
        //     props.handle({
        //         call:"size",
        //         size:{
        //             height:refA.current.offsetHeight
        //         }
        //     });

        // }

    
    },
    [
        props,
        isLoaded,
        refA
 
    ])




    //var colors = ['darkred','darkblue','purple','darkgreen','#fc0','orange','lavender','pink','seagreen'];



    //console.log("COLLAGE ITEM: "+JSON.stringify(props.databind.item));

    let gridObj; 
    gridObj = useCollage([props.databind.item], props.databind.info);
    //gridObj = useLiqGrid(props.items, props.databind.info);
    //console.log("myBuilds/FGrid/FlexGrid - vars..."+JSON.stringify(gridObj['vars'],null,2));
    //console.log("Collage - items..."+JSON.stringify(gridObj['items'],null,2));

    const liqItems = gridObj['items'];
    // console.log("LIQITEMS: "+JSON.stringify(liqItems,null,2));
    // //alert('check liqItems')




    var gridClass;
    var cellClass;

    switch(props.databind.info.gridType)
    {
    case'Flex':

        gridClass = FlexStyl.FlexGrid;
        cellClass = FlexStyl.CellA;

        if(props.databind.info.cellClass 
        && props.databind.info.cellClass !== undefined
        ) 
        {
            switch(props.databind.info.cellClass)
            {
            case'B':
                cellClass = FlexStyl.CellB
            break;
            case'B2':
                cellClass = FlexStyl.CellB2
            break;
            case'C':
                cellClass = FlexStyl.CellC;
            break;
            case'D':
                cellClass = FlexStyl.CellD;
            break;
            default:
            }
        }



    break;
    default:


        gridClass = FixedStyl.FixedGrid;
        cellClass = FixedStyl.CellA;

        if(props.databind.info.cellClass 
        && props.databind.info.cellClass !== undefined
        ) 
        {
            switch(props.databind.info.cellClass)
            {
            case'B':
                cellClass = FixedStyl.CellB
            break;
            case'B2':
                cellClass = FixedStyl.CellB2
            break;
            case'C':
                cellClass = FixedStyl.CellC;
            break;
            case'D':
                cellClass = FixedStyl.CellD;
            break;
            default:
            }
        }




    }


    //alert(cellClass+" "+gridClass);


    return (<div>


        {
        props.databind.info.gridType === 'Fixed' 
        ?


            <div
            ref={refA}
            className={gridClass}
            style={{
                backgroundColor :props.databind.info.gridBgColor,
                //backgroundColor :"darkred",
                maxWidth        :props.databind.info.gridMaxWidth
        
            }}
            >
                {liqItems.data.map((box, i) => (


                    <div
                    key={i}   
                    ref={setCellRef}
                    //id={box.id.toString()}   
                    //className={FixedStyl.Cell}
                    className={cellClass}
                    style={{

                        flex: "0 1 "+liqItems.cell.width,
                        cursor:"default"

                    }}
                    >


                        <div 
                        className={FixedStyl.Sizer}
                        style={{

                            //backgroundColor:"magenta",
                            width:liqItems.cell.thumb.width,
                            //height:liqItems.cell.thumb.height,
                            margin:liqItems.cell.thumb.margin,
                            border:liqItems.cell.border,
                            paddingTop:liqItems.cell.paddingTop

                        }}
                        >


                            {/*
                            <div
                            //ref={setMaskRef}
                            //id={"Mask_"+box.id.toString()}
                            className={FixedStyl.Mask}
                            style={{

                                backgroundColor:"darkblue",
                                //width:liqItems.cell.mask.width,
                                //height:liqItems.cell.mask.height,
                                //top:liqItems.cell.mask.top,
                                //left:liqItems.cell.mask.left
                                opacity:0.3
                                
                            }}
                            >

                                {props.databind.info.mask.message.boolean ?
                                
                    
                                    <div 
                                    className={FixedStyl.MaskBox}
                                    >
                                        <div
                                        className={FixedStyl.MaskTxt}
                                        style={{

                                            backgroundColor:props.databind.info.mask.message.backgroundColor,
                                            color:props.databind.info.mask.message.textColor

                                        }}
                                        >

                                            M 

                                        </div>

                                    </div>
        

                                :null}



                            </div>

                            */}




                            <div
                            className={FixedStyl.Thmb}
                            style={{
                                //backgroundColor:box.bgColor
                                backgroundColor:props.databind.info.thumb.bgColor
                            }}
                            >


                                {


                                box.image.src.match(/http/gi) 
                                ?

                                    <img
                                    className={FixedStyl.ImageOnly_StaticImg}
                                    style={{

                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,
                                    
                                        marginTop:box.image.top,
                                        marginLeft:box.image.left,

                                        opacity:props.databind.info.opacity,

                                        //webkitFilter:props.databind.info.webkitFilter,
                                        filter: props.databind.info.filter

                                    }}

                                        src={box.image.src} 
                                        //src={"https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3489addfile_sys20200406240792id_.jpg"}
                                    
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='(!)'


                                    />  



                                :


                                    <img
                                    className={FixedStyl.ImageOnly_StaticImg}
                                    style={{

                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,
                                    
                                        marginTop:box.image.top,
                                        marginLeft:box.image.left,

                                        opacity:props.databind.info.opacity,

                                        //webkitFilter:props.databind.info.webkitFilter,
                                        filter: props.databind.info.filter

                                    }}

                                    
                                        //src={"https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3489addfile_sys20200406240792id_.jpg"}
                              
                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='(!)'


                                    />  

                                
                                }

                        
                            </div>



                        </div>


                    </div>

                
                ))} 


            </div>

        :
        null
        }






        {
        props.databind.info.gridType === 'Flex' 
        ?


            <div
            ref={refA}
            className={FlexStyl.FlexGrid}
            style={{
                backgroundColor :props.databind.info.gridBgColor,
                maxWidth        :props.databind.info.gridMaxWidth,
                height          :props.databind.info.viewHeight + "px"
            }}
            >

                {liqItems.data.map((col, i) => (

                    <div
                    key={i}
                    className={FlexStyl.Column}
                    style={{
                        //backgroundColor : colors[i],
                        height          : props.databind.info.viewHeight + "px"
                    }}
                    >

                        {col.map((box, j) => (

                            <div 
                            key={j}       
                            //ref={setCellRef}
                            id={box.id}
                            className={FlexStyl.Cell}
                            style={{
                                //backgroundColor: "lightyellow",
                                width: liqItems.cell.width,
                                //height: lgFlexImages.cell.height,
                                margin: liqItems.cell.margin
                            }}

                            > 
        

                                <div 
                                className={FlexStyl.Sizer}
                                style={{

                                    //backgroundColor:"magenta",
                                    //width:liqItems.cell.thumb.width,
                                    paddingTop:box.image.h,
                                    //margin:liqItems.cell.thumb.margin,
                                    border:liqItems.cell.border

                                }}
                                >

            
                                    <div 
                                    className={FlexStyl.Thmb}
                                    style={{

                                        //backgroundColor: "yellow",
                                        //width: liqItems.cell.thumb.width,
                                        height: box.thumb.h,
                                        //height: box.image.maxHeight,
                                        //margin: lgFlexImages.cell.thumb.margin

                                    }}
                                    >

                                        {

                                        box.image.src.match(/http/gi) 
                                        ?

                                            <img
                                            className={FlexStyl.ImageOnly_StaticImg}
                                            style={{

                                                //marginTop:"25px",
                                                marginTop:box.image.top,
                                                marginLeft:box.image.left,
                                                maxWidth:box.image.maxWidth,
                                                maxHeight:box.image.maxHeight,
                                                //height:box.image.h
                                                
                                                opacity:props.databind.info.opacity,

                                                //webkitFilter:props.databind.info.webkitFilter,
                                                filter: props.databind.info.filter



                                            }}

                                            src={box.image.src} 
                                            //src={"https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3489addfile_sys20200406240792id_.jpg"}
                                            //src={window.location.origin + box.image.src} 
                                            //width={box.image.w}
                                            //height={box.image.h} 
                                            alt='(!)'

                                            />


                                        :

                                            <img
                                            className={FlexStyl.ImageOnly_StaticImg}
                                            style={{

                                                //marginTop:"25px",
                                                marginTop:box.image.top,
                                                marginLeft:box.image.left,
                                                maxWidth:box.image.maxWidth,
                                                maxHeight:box.image.maxHeight,
                                                //height:box.image.h
                                                
                                                opacity:props.databind.info.opacity,

                                                //webkitFilter:props.databind.info.webkitFilter,
                                                filter: props.databind.info.filter



                                            }}

                                        
                                    
                                            src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                            //src={window.location.origin + box.image.src} 
                                            //width={box.image.w}
                                            //height={box.image.h} 
                                            alt='(!)'

                                            />



                                        }

                                    </div>

                                </div>

                
                            </div>

                        ))}

                    </div>
                                                
                ))} 

            </div>

        :
        null
        }






    </div>)


}






