export const WING_RACING_THEME_MARLBORO_12 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

    
    {/*Wing Backgroung*/}
     <path 
        fill="rgb(100,0,0)" 
        d="M76.175,164.732v26.066c0,4.445,3.33,7.995,8.097,8.632 l37.004,3.821h8.348c2.311,0,4.487-0.903,6.126-2.543c1.641-1.641,2.544-3.816,2.544-6.127l-0.002-29.851 c-0.001-4.78-3.89-8.67-8.67-8.67H84.846C80.064,156.062,76.175,159.951,76.175,164.732L76.175,164.732z"
    />
    {/*Center*/}
    <path 
        fill="rgb(175,0,0)" 
        d="M78.335,164.787v25.961c0.177,3.737,2.663,5.918,6.234,6.542 l36.818,3.802h8.21c3.675-0.092,6.451-2.854,6.537-6.534l-0.002-29.771c-0.191-3.798-2.766-6.374-6.564-6.565H84.9 C81.099,158.412,78.529,160.988,78.335,164.787L78.335,164.787z"
    />
    {/*Filler*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M76.175,203.256v-12.457c0,2.741,1.266,5.141,3.354,6.729L76.175,203.256 L76.175,203.256z"
    />
    {/*12 Back*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M104.502,180.788c1.57-2.282,3.605-3.695,6.149-4.762h-8.134l0.077-1.772 c0.526-6.951,4.55-10.447,11.45-10.551l0.669,0.008c5.875,0.238,10.435,2.649,10.822,9.005c0.063,3.343-0.662,6.214-3.542,8.181 c-2.381,1.494-5.047,1.884-7.656,2.777h10.846v8.75h-23.275l0.053-1.51c0.061-1.347,0.178-2.68,0.387-4.012 C102.721,184.721,103.332,182.681,104.502,180.788L104.502,180.788z M111.963,175.548c0.698-0.234,1.401-0.452,2.1-0.681
		c0.588-0.209,1.149-0.436,1.683-0.765c0.224-0.157,0.574-0.37,0.595-0.678c-0.04-0.952-1.03-0.991-1.77-1.009l-0.398,0.012 c-1.09,0.1-1.802,0.334-2.111,1.445c-0.062,0.336-0.087,0.656-0.099,0.999V175.548L111.963,175.548z M88.932,168.228	c2.654-0.712,3.881-1.425,5.2-3.862h6.862v28.059h-8.563v-16.238c-1.175,0.357-2.27,0.445-3.5,0.545V168.228L88.932,168.228z"
    />
    {/*12*/}
     <path 
        fill="rgb(255,255,255)" 
        d="M103.026,191.344c0.124-4.156,0.834-7.338,2.128-9.557	c1.294-2.213,3.418-3.861,6.376-4.94c0.615-0.227,1.437-0.503,2.467-0.821c2.284-0.711,3.426-1.569,3.426-2.587	c0-0.692-0.244-1.22-0.727-1.575c-0.483-0.35-1.193-0.527-2.128-0.527c-1.27,0-2.204,0.281-2.794,0.852	c-0.595,0.57-0.891,1.459-0.891,2.667v0.092h-7.238c0.092-3.401,1.002-5.946,2.731-7.632c1.729-1.686,4.284-2.531,7.669-2.531 c3.461,0,6.065,0.711,7.805,2.139c1.745,1.423,2.615,3.543,2.615,6.357c0,2.151-0.435,3.844-1.309,5.075 c-0.875,1.226-2.34,2.183-4.4,2.882c-0.763,0.25-1.821,0.563-3.17,0.932c-2.855,0.778-4.608,1.643-5.254,2.586h13.77v6.59H103.026 L103.026,191.344z M93.512,191.344v-16.734c-0.623,0.288-1.225,0.509-1.801,0.662c-0.571,0.159-1.139,0.251-1.699,0.288v-6.504	c1.127-0.276,2.083-0.711,2.869-1.312c0.79-0.602,1.421-1.367,1.895-2.299h5.139v25.898H93.512L93.512,191.344z"
    />


    

		</svg>
		</div>

	);//return
}