export const BODY_SOLID_GRAY_SILVERSTONE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    {/*Two*/}
    <polygon 
        fill="rgb(150,150,150)" 
        points="60.552,329.4 92.127,396 655.779,396 655.779,275.4 642.933,250.745 608.1,230.634 436.5,209.563 86.8,185.109 76.176,203.256 76.193,275.4 60.568,275.4 60.552,329.4"
    />
    {/*One*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M86.8,185.109l349.7,24.454l171.6,21.07l34.833,20.111v15.957 H612.34c-15.189-20.885-39.639-34.682-67.349-35.165c-23.848-0.417-45.626,9.108-61.376,24.737 c-26.417-13.027-57.538-16.255-83.05-17.921l-147.315-9.01c-18.387-1.125-5.965,30.828-22.414,18.791 c-13.847-10.339-30.925-16.596-49.456-16.92c-32.641-0.569-61.421,17.41-76.258,44.186H76.176v-72.144L86.8,185.109L86.8,185.109z"
    />
    {/*One Front Bumper*/}   
    <polygon 
        fill="rgb(170,170,170)" 
        points="655.779,275.399 642.933,250.744 608.099,230.634 642.933,285.278 642.933,298.921 655.779,298.921 655.779,275.399 	"
    />
    {/*Three*/}
    <polygon 
        fill="rgb(130,130,130)" 
        points="92.127,396 642.933,396 642.933,298.921 227.025,275.4 60.568,275.4 60.552,329.4 92.127,396"
    />
    {/*Three Front Bumper*/}
    <polygon 
        fill="rgb(150,150,150)" 
        points="655.779,379.3 655.779,298.921 642.933,298.921 642.933,379.3 655.779,379.3 	"
    />
    {/*Vent Background*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M629.269,348.577l18.681,4.628l2.489-0.004 c1.779-0.004,3.422-0.325,3.422-2.821l-0.001-29.823c0-1.021-0.404-1.261-0.984-1.281l-21.414-0.759 c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603C625.877,344.809,626.697,347.94,629.269,348.577L629.269,348.577z"
    />
    {/*Vent*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M648.527,353.204l-9.437-5.126c-1.72-0.934-2.519-2.618-2.52-4.77 l-0.005-11.837c0-2.773,0.814-4.013,2.066-4.716l13.386-7.511l-20.558-0.729c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603 c0,2.714,0.82,5.846,3.392,6.482l18.681,4.628L648.527,353.204L648.527,353.204z"
    />
    {/*Vent Shadow*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M628.042,324.644c-0.001-2.038,1.858-2.907,3.285-3.068 l20.691-2.33l-20.558-0.729c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603c0,2.714,0.82,5.846,3.392,6.482 c-0.957-1.553-1.22-4.483-1.221-5.71L628.042,324.644L628.042,324.644z"
    />
    {/*Vent Highlight*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M632.043,348.424l12.192,3.385l-7.12-4.247 c-6.029-3.596-8.149-15.236-8.107-8.605C629.051,345.587,629.347,347.676,632.043,348.424L632.043,348.424z"
    />
    {/*Rear Bumper*/}
    <polygon 
        fill="rgb(120,120,120)" 
        points="76.176,329.4 92.127,396 179.855,396 179.855,329.4 76.176,329.4"
    />
    {/*Rear Bumper Highlight*/}
    <polygon 
        fill="rgb(190,190,190)" 
        points="76.176,285.279 60.552,285.279 60.568,275.4 76.176,275.4 76.176,285.279"
    />
    {/*Rear Bumper Shadow*/}
    <polygon 
        fill="rgb(110,110,110)" 
        points="60.552,329.4 76.176,329.4 92.127,396 60.552,329.4"
    />
    {/*Rocker*/}
    <path 
        fill="rgb(110,110,110)" 
        d="M642.933,379.3V396H92.127l-0.001-0.002L92.127,396l0,0l-4-16.7 C283.727,379.3,447.309,379.3,642.933,379.3L642.933,379.3z"
    />
    {/*Rocker Front*/}
    <polygon 
        fill="rgb(130,130,130)" 
        points="642.933,379.3 642.933,396 655.779,396 655.779,379.3 642.933,379.3 	"
    />
    {/*Inset Highlight*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	L179.855,318.02v41.717l280.313-0.024C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />
    {/*Inset Shadow*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	L179.855,318.02v12.312l251.604,10.985c10.855,0.475,22.532,9.438,26.881,16.582c0.765,1.256,1.308,1.813,1.828,1.813 C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />  
    {/*Front Lip Highlight*/}
    <polygon 
        fill="rgb(210,210,210)" 
        points="543.528,365.473 655.779,365.473 655.779,364.026 642.933,362.38 633.41,364.026 543.528,364.026 543.528,365.473 	"
    />
    {/*Door Handle Highlight*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M302.899,232.38c-0.903-1.849-2.974-4.587-8.14-6.69 c-9.912-4.039-26.723-4.895-28.843-5.051c-2.937-0.219-9.122-0.149-8.186,5.914l0.478,3.094L302.899,232.38L302.899,232.38z"
    />
    {/*Door Handle Shadow*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M266.162,231.082c1.954,0,3.548,1.594,3.548,3.548	s-1.594,3.548-3.548,3.548c-1.953,0-3.547-1.594-3.547-3.548S264.209,231.082,266.162,231.082L266.162,231.082z M300.152,232.212 l-29.457-1.802c0.471,5.016,2.687,6.109,4.926,6.132l20.105,1.186C300.893,237.779,300.152,232.212,300.152,232.212 L300.152,232.212z"
    />
    {/*One Highlight*/}
    <polygon 
        fill="rgb(190,190,190)" 
        points="436.5,209.563 86.8,185.109 76.176,203.256 77.452,203.257 436.5,209.563"
    />
    {/*Mirror Body Shadow*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M420.018,215.521l1.227,1.87 c-8.865-0.307-14.482,2.165-16.687,3.683c-2.327,1.602-2.546,5.234,3.085,4.68l25.139-2.47c1.26,0.041,2.572-0.003,3.807-0.202 c1.378-0.223,4.542-1.097,3.238-3.086l-2.383-3.635c-1.073,0.17-2.324,0.216-3.747,0.117 C429.137,216.16,424.577,215.84,420.018,215.521L420.018,215.521z"
    />
    {/*Mirror Background*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M437.443,216.361c4.697-0.74,6.028-3.823,3.786-7.244l-3.907-5.96 c-0.822-1.253-3.626-4.647-5.669-6.215c-6.813-5.228-12.349-6.552-21.103-6.552c-4.264,0-5.566,0.952-7.2,4.458 c-0.749,1.604-1.124,3.95-1.124,5.72v5.09c0,1.447,0.091,3.408,1.29,4.729l2.525,2.78c0.802,0.882,2.007,1.519,3.195,1.601 l10.78,0.754L423.61,221c0.935,1.426,3.336,1.578,4.617,1.802c2.035,0.354,5.423,0.755,8.36,0.28	c1.378-0.223,4.542-1.097,3.238-3.086L437.443,216.361L437.443,216.361z"
    />
    {/*Mirror Base*/}
    <path 
        fill="rgb(110,110,110)" 
        d="M437.443,216.361c-1.073,0.17-2.324,0.217-3.747,0.117	l-13.679-0.957L423.61,221c0.935,1.426,3.336,1.578,4.617,1.802c2.035,0.354,5.423,0.755,8.36,0.28	c1.378-0.223,4.542-1.097,3.238-3.086L437.443,216.361L437.443,216.361z"
    />
    {/*Mirror_Base_Highlight*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M438.299,219.592l-1.062-1.619
		c-0.336-0.513-1.093-0.805-1.733-0.85l-14.267-0.997l0.845,1.288l7.073,0.523c2.369,0.174,3.686,0.219,5.114,2.258
		c0.733,1.045,2.132,1.713,3.148,1.344C438.434,221.169,438.88,220.408,438.299,219.592L438.299,219.592z"
    />
    {/*"Mirror_Shadow"*/}
    <path 
        fill="rgb(130,130,130)" 
        d="M437.443,216.361c-1.073,0.17-2.324,0.217-3.747,0.117	l-24.459-1.711c-1.188-0.082-2.394-0.719-3.195-1.601l-2.525-2.78c-0.374-0.412-0.64-0.886-0.829-1.388	c6.532,0.457,33.813,2.364,38.542,0.118C443.472,212.538,442.141,215.621,437.443,216.361L437.443,216.361z"
    />
    {/*"Mirror_Highlight"*/}
    <path 
        fill="rgb(200,200,200)" 
        d="M402.947,203.555c10.225,2.229,20.418-6.53,32.119-2.197 c-1.167-1.412-2.479-2.742-3.855-3.847c-3.146-2.373-6.389-4.21-10.196-5.287c-3.183-0.815-6.384-1.06-9.66-1.109	c-3.919-0.018-5.603,0.411-7.351,4.036c-0.744,1.691-1.035,3.584-1.057,5.423V203.555L402.947,203.555z"
    />
    
    {/*Marker Light*/}
    <path 
        fill="rgb(245,130,30)" 
        d="M443.446,276.035c-2.121-0.125-2.616-1.916-2.568-2.723 c0.048-0.806,0.11-1.85,0.203-3.414s1.187-2.311,2.907-2.209l13.417,0.798c1.721,0.102,2.718,0.974,2.625,2.536 c-0.093,1.565-0.155,2.609-0.202,3.415c-0.049,0.807-0.752,2.525-2.873,2.399L443.446,276.035L443.446,276.035z"
    />
    <path 
        fill="rgb(255,185,20)" 
        d="M446.124,268.973l7.938,0.472	c0.567,0.033,1.004,0.525,0.971,1.093l-0.255,4.287c-0.033,0.567-0.525,1.004-1.094,0.971l-7.938-0.473	c-0.567-0.033-1.004-0.525-0.97-1.093l0.254-4.286C445.065,269.376,445.557,268.939,446.124,268.973L446.124,268.973z"
    />


    {/*"Livery"*/}
 
    {/*R*/}
    <path 
        fill="rgb(0,0,0)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M425.555,270.73l0.081-1.341l6.62,0.395c0.367,0.02,0.65,0.339,0.629,0.708	c-0.021,0.369-0.342,0.652-0.709,0.631L425.555,270.73L425.555,270.73z M423.523,267.695c0,0,8.837,0.525,9.871,0.588 c1.034,0.06,2.01,0.34,1.89,2.344c-0.119,2.005-1.847,1.901-2.208,1.881l3.103,2.443l-3.748-0.223l-2.94-2.434l-4.013-0.239 l-0.135,2.253l-2.202-0.132L423.523,267.695L423.523,267.695z"
    /> 
    {/*S2*/}
    <path 
        fill="rgb(0,0,0)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M413.716,268.677l2.57,0.158c0.281,0.017,0.397,0.19,0.387,0.352l-0.015,0.241 c-0.009,0.158-0.144,0.319-0.425,0.302l-2.134-0.127l-4.47-0.266c-1.05-0.063-1.58,0.561-1.634,1.472 c-0.049,0.82-0.099,1.641-0.147,2.461l10.47,0.613l0.092-1.562l-8.145-0.479c-0.281-0.018-0.396-0.191-0.386-0.352l0.013-0.241	c0.01-0.159,0.146-0.32,0.427-0.303l3.34,0.199l3.262,0.193c1.051,0.063,1.581-0.56,1.636-1.472l0.053-0.896 c0.055-0.912-0.399-1.592-1.449-1.655l-4.192-0.249l-3.142-0.187c-1.049-0.063-1.579,0.559-1.634,1.472l-0.021,0.327l1.919,0.114 c0.01-0.161,0.144-0.321,0.425-0.305L413.716,268.677L413.716,268.677z M419.345,270.354l2.628,0.156l-0.055,0.904l-2.626-0.156 L419.345,270.354L419.345,270.354z M402.666,270.493l2.134,0.127c0.281,0.017,0.396,0.19,0.388,0.351l-0.015,0.241 c-0.01,0.159-0.146,0.319-0.427,0.303l-8.28-0.486l-0.093,1.558l9.063,0.539c1.05,0.063,1.581-0.561,1.636-1.472l0.052-0.896 c0.055-0.911-0.398-1.592-1.447-1.654l-3.264-0.193l-3.339-0.199c-0.282-0.017-0.398-0.19-0.389-0.352l0.016-0.241 c0.01-0.16,0.144-0.319,0.425-0.302l8.03,0.478l0.092-1.563l-8.81-0.524c-1.05-0.063-1.581,0.561-1.636,1.472l-0.053,0.895	c-0.055,0.914,0.399,1.594,1.448,1.655L402.666,270.493L402.666,270.493z"
    />


    

		</svg>
		</div>

	);//return
}