import {useState, useEffect} from 'react';

const getOrientation = () => {

    var orient;
    orient = 'NA';

    // If you need to obtain the width of the window minus the scrollbar and borders, 
    // use the root <html> element's clientWidth property instead.

    // CLIENT - obtain the width of the window minus scrollbar and borders

    // if(window.clientHeight > window.clientWidth){
    //     orient = 'PORTRAIT';
    // }
    // else
    // if(window.clientHeight < window.clientWidth){
    //     orient = 'LANDSCAPE';
    // }


    if(window.innerHeight > window.innerWidth){
        orient = 'PORTRAIT';
    }
    else
    if(window.innerHeight < window.innerWidth){
        orient = 'LANDSCAPE';
    }
    return orient;


}
const useScreenOrientation = () => {


    const [orientation, setOrientation] = useState(getOrientation())
    const updateOrientation = event => {

        setOrientation(getOrientation())
    }
    useEffect(() => {

        window.addEventListener('resize',updateOrientation)

        return () => {

            window.removeEventListener('resize',updateOrientation)
        }
        
    }, [])

    return orientation

}

export default useScreenOrientation


//https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia


