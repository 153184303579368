export const CALIPER_OEM_FRONT_FACTORY = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        


    <defs>
        <linearGradient id="BrakeRotor" x1="0%" y1="50%" x2="70%" y2="0%" gradientTransform="rotate(0)">
            <stop offset="0%" style={{"stopColor":"rgb(10,10,10)","stopOpacity":"1"}} />
            <stop offset="50%" style={{"stopColor":"rgb(100,100,100)","stopOpacity":"1"}} />
            <stop offset="100%" style={{"stopColor":"rgb(10,10,10)","stopOpacity":"1"}} />
        </linearGradient>
    </defs>


    {/*Brake Rotor Front*/}
    <path 
        fill="url(#BrakeRotor)" 
        d="M520.212,350.874	c-18.871-12.828-23.798-38.678-10.973-57.549c12.828-18.871,38.682-23.802,57.547-10.977c18.869,12.828,23.8,38.678,10.973,57.549 C564.927,358.769,539.079,363.699,520.212,350.874L520.212,350.874z M528.522,338.643c12.137,8.249,28.761,5.078,37.007-7.056
		c8.247-12.137,5.076-28.759-7.054-37.007c-12.137-8.248-28.759-5.078-37.006,7.058C513.22,313.772,516.387,330.396,528.522,338.643
		L528.522,338.643z"
    />

    
    {/*Caliper Backer*/}
    <path 
        fill="rgb(110,110,110)" 
        d="M515.158,313.327c-0.154,1.444-0.172,2.909-0.053,4.394 l0.367,0.558l-0.83,5.56c-0.049,0.331-0.266,0.666-0.551,0.84l-2.178,1.328l-0.262,2.043c-0.066,0.515-0.443,0.75-1.076,0.657 l-2.648-0.391c-0.467-0.068-0.994-0.67-1.219-1.172l-0.488-1.093l0.992-0.263l2.627,0.396c0.396,0.06,0.838-0.258,0.896-0.654 l0.105-0.702l-10.203-4.909l2.84-19.001l11.193-1.711l0.104-0.702c0.061-0.396-0.27-0.83-0.666-0.889l-2.629-0.39l-0.871-0.541	l0.787-0.902c0.361-0.414,1.041-0.836,1.508-0.765l2.646,0.401c0.633,0.096,0.924,0.431,0.836,0.942l-0.346,2.031l1.693,1.905 c0.223,0.251,0.332,0.634,0.283,0.966l-0.832,5.559l-0.514,0.426c-0.549,1.385-0.961,2.79-1.234,4.217L515.158,313.327 L515.158,313.327z"
    /> 
    {/*Brake Pad*/}
    <path 
        fill="rgb(65,65,65)" 
        d="M504.209,301.905l9.82,1.472c0.807,0.12,1.227,0.459,1.412,1.042
		c0.195,0.741,0.33,1.608,0.396,2.571c-0.676,1.66-1.166,3.423-1.443,5.271c-0.275,1.848-0.32,3.678-0.16,5.463
		c-0.346,0.9-0.729,1.691-1.131,2.344c-0.35,0.502-0.85,0.704-1.654,0.583l-9.822-1.464L504.209,301.905L504.209,301.905z"
    /> 
    {/*Caliper Shadow*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M499.113,309.977l0.996-5.409c0.723-3.926,3.092-7.69,3.629-8.819
		c0.535-1.13,1.199-1.106,1.783-1.016l2.307,0.361c0.482,0.075,0.902,0.425,1.336,0.692l7.086,4.399
		c0.93,0.577,1.08,1.16,0.912,2.035l-0.633,4.304c-0.115,0.777-0.756,1.03-1.385,0.948l-5.555-0.724
		c-2.906-0.292-5.338-0.067-5.932,3.907c-0.596,3.976,1.666,4.9,4.531,5.471l5.521,0.933c0.627,0.105,1.166,0.534,1.047,1.312
		l-0.652,4.301c-0.096,0.886-0.408,1.399-1.469,1.68l-8.061,2.135c-0.492,0.13-0.996,0.341-1.48,0.272l-2.311-0.329
		c-0.586-0.084-1.227-0.256-1.41-1.492c-0.184-1.237-1.348-5.529-0.891-9.496L499.113,309.977L499.113,309.977z"
    /> 
    {/*Caliper*/}
    <path 
        fill="rgb(140,140,140)" 
        d="M503.844,314.568c0.734,1.275,1.627,1.666,4.352,2.203
		l5.016,0.843c0.568,0.095,1.055,0.472,0.955,1.148l-0.559,3.795c-0.082,0.772-0.363,1.229-1.324,1.456l-4.629,1.093l-3.789-0.573
		c-0.438-0.066-1.041-0.215-1.318-0.645c-1.656-2.55-1.951-4.871-1.984-7.899c-0.014-1.12,0.072-1.897,1.176-1.733L503.844,314.568
		L503.844,314.568z M504.98,306.97c1.074-1.005,2.041-1.117,4.805-0.834l5.041,0.66c0.572,0.075,1.148-0.143,1.252-0.819
		l0.574-3.792c0.146-0.763,0.012-1.282-0.84-1.78l-4.107-2.397l-3.793-0.56c-0.438-0.064-1.057-0.1-1.449,0.229
		c-2.328,1.955-3.289,4.09-4.207,6.975c-0.34,1.068-0.484,1.837,0.617,2.002L504.98,306.97L504.98,306.97z M504.895,300.14
		l0.689-0.223l9.521,1.778c0.611,0.114,1.268,0.839,1.268,0.839l-3.797-0.281l-0.771,1.526c-0.176,0.35,0.053,0.486,0.252,0.553
		l3.852,1.288l-6.195-0.654c-1.02-0.108-1.35-1.006-0.895-1.823l1.123-2.025L504.895,300.14L504.895,300.14z M501.766,321.075
		l0.594,0.415l9.625,1.082c0.619,0.07,1.459-0.431,1.459-0.431l-3.715-0.841l-0.289-1.686c-0.068-0.386,0.191-0.45,0.4-0.455
		l4.061-0.106l-6.117-1.185c-1.006-0.195-1.582,0.568-1.389,1.482l0.482,2.265L501.766,321.075L501.766,321.075z M499.445,310.027
		c-0.148,0.993-0.143,1.386-0.037,2.284c0.057,0.478,0.127,0.845,0.598,0.916l3.572,0.533c-0.525-0.779-0.699-1.796-0.49-3.189
		s0.672-2.315,1.402-2.907l-3.572-0.533c-0.471-0.07-0.646,0.261-0.84,0.701C499.715,308.66,499.594,309.034,499.445,310.027
		L499.445,310.027z"
    /> 
    {/*Caliper 1*/}
    <path 
        fill="rgb(140,140,140)" 
        d="M518.566,301.049c0.092-0.599-0.199-1.251-0.563-1.635
		l-1.428-1.503l0.232-1.491c0.107-0.693-0.127-0.913-0.74-1.005l-4.594-0.687l-0.477,0.987l-0.871-0.151
		c0.035-0.752,0.105-2.08-0.512-2.35l-1.699-0.741c-0.193-0.086-0.367-0.383-0.326-0.638l0.287-1.779
		c0.053-0.316,0.322-0.572,0.566-0.713l2.225-1.278c0.727-0.418,1.191-0.593,1.496-0.526l2.699,0.403l3.811,4.289
		c0.438,0.491,0.635,0.701,0.779,1.379l0.926,4.398c0.088,0.417,0.277,1.166,0.172,1.875c-1.291,8.672-2.588,17.343-3.887,26.014
		c-0.107,0.708-0.508,1.37-0.715,1.742l-2.17,3.936c-0.336,0.607-0.588,0.75-1.148,1.092l-4.896,2.987l-2.701-0.403
		c-0.311-0.025-0.703-0.329-1.275-0.94l-1.756-1.874c-0.191-0.205-0.375-0.529-0.332-0.847l0.246-1.785
		c0.035-0.256,0.287-0.49,0.498-0.515l1.842-0.212c0.668-0.078,0.988-1.368,1.176-2.098l0.877,0.109l0.168,1.084l4.594,0.686
		c0.613,0.092,0.9-0.049,1-0.744l0.215-1.493l1.805-1.021c0.461-0.26,0.928-0.799,1.016-1.398
		C516.238,316.481,517.391,308.764,518.566,301.049L518.566,301.049z"
    />
    {/*Caliper Hightlight*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M514.619,301.726c1.041,0.128,1.754,0.809,1.754,0.809
		l-3.797-0.281l-1.586-0.975L514.619,301.726L514.619,301.726z M511.805,303.779c-0.176,0.35,0.053,0.486,0.252,0.553l3.852,1.288
		l-6.195-0.654c-0.51-0.054-0.848-0.306-1-0.646C509.598,303.438,510.387,304.045,511.805,303.779L511.805,303.779z
		 M511.529,322.402c1.033,0.182,1.914-0.261,1.914-0.261l-3.715-0.841l-1.801,0.469L511.529,322.402L511.529,322.402z
		 M509.439,319.615c-0.068-0.386,0.191-0.45,0.4-0.455l4.061-0.106l-6.117-1.185c-0.502-0.098-0.898,0.044-1.145,0.325
		C507.229,319.296,508.16,318.947,509.439,319.615L509.439,319.615z"
    />
    {/*Shadow*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M509.027,333.49l0.307-2.049l4.119,0.615
		c0.107-0.124,0.211-0.275,0.324-0.479l-4.668-0.697c-0.473-0.071-0.801-0.514-0.73-0.986l0.211-1.404l-0.564-0.085l-0.287,1.921
		c-0.07,0.472,0.258,0.915,0.73,0.985l0.467,0.07l-0.305,2.049l-6.629-0.993l1.756,1.874c0.572,0.611,0.965,0.915,1.275,0.94
		l2.699,0.402l3.074-1.896L509.027,333.49L509.027,333.49z M515.469,290.391l-0.307,2.05l4.119,0.615
		c0.068,0.15,0.121,0.325,0.17,0.553l-4.668-0.698c-0.471-0.07-0.916,0.258-0.986,0.729l-0.209,1.405l-0.564-0.084l0.287-1.921
		c0.07-0.472,0.516-0.8,0.986-0.729l0.469,0.07l0.307-2.049l-6.629-0.988l2.225-1.279c0.727-0.417,1.191-0.592,1.496-0.526
		l2.699,0.404l2.385,2.713L515.469,290.391L515.469,290.391z"
    />

    

		</svg>
		</div>

	);//return
}