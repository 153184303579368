export const HELMET_GRAY_DRIVER_STEVE_MCQUEEN = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M295.31,140.592c15.959,0,28.52,9.59,31.176,23.92l3.755,20.187 c4.622,1.241,1.783,8.212,0.386,11.543l-2.126,5.008c-0.975,2.013-2.859,5.337-4.996,5.281l-6.514-0.342 c-3.291-0.191-6.769-0.616-10.016-1.176l-39.141-6.901c-2.97-7.738-5.789-16.426-5.789-26.26	C262.045,152.664,276.121,140.592,295.31,140.592L295.31,140.592z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c26.055,0,30.001,22.563,30.001,22.563l3.89,20.986c3.744,0,2.264,5.341,0.289,9.992l-2.115,4.983 c-0.649,1.53-2.321,2.045-3.865,1.964l-6.571-0.344c-2.552-0.135-7.379-0.795-9.718-1.207l-38.247-6.744 C265.748,186.053,263.274,179.661,263.274,171.851L263.274,171.851z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338	l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248	l-6.552-0.344c-1.833-0.109-3.734-0.318-5.565-0.569l2.025-4.762c0.626-1.493,4.915-11.167,1.597-11.751l-0.694-0.123l-3.55-19.154 c-0.264-1.425-2.542-15.416-16.416-21.3C294.689,143.494,294.986,143.48,295.307,143.473L295.307,143.473z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338	l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248	l-4.869-0.256l1.96-4.615c0.656-1.563,5.192-10.655,1.716-11.268l-0.137-0.024l-3.807-20.54 c-0.938-5.063-4.646-17.723-23.346-21.332C295.15,143.477,295.227,143.475,295.307,143.473L295.307,143.473z"
    />
    {/*Stripe*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338l3.996,21.561 l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248l-1.723-0.104 l3.105-6.948c1.755-3.928,2.774-8.144,0.561-8.533l-0.721-0.137l-3.807-20.539c-0.938-5.063-7.199-18.164-25.898-21.773 C295.15,143.477,295.227,143.475,295.307,143.473L295.307,143.473z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M301.271,198.808l-30.487-5.376 c-2.691-7.023-6.208-13.934-6.284-21.573c0.185-7.932,3.001-14.31,7.501-18.997c-1.001,7.713-5.249,16.844,6.48,40.427 l23.384,4.121L301.271,198.808L301.271,198.808z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c3.355,0,6.343,0.377,9.004,1.029c-3.046-0.186-5.94-0.361-8.969-0.091c-10.236,1.335-14.512,7.489-17.487,16.626l-9.349,33.424 C265.514,185.43,263.274,179.283,263.274,171.851L263.274,171.851z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M269.416,189.992c-2.382-5.863-4.854-11.744-4.917-18.134 c0.185-7.932,3.001-14.31,7.501-18.997c-0.763,5.877-3.409,12.58,0.564,25.873L269.416,189.992L269.416,189.992z"
    />


    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M296.008,159.869c1.348-1.318,2.801-1.846,4.589-1.877	c1.384,0.087,2.493,0.447,3.72,1.144l4.868,2.983c1.511,0.874,3.318,1.426,5.068,1.468h10.462c2.343,0.171,3.435,1.169,3.916,3.433 l3.176,17.135c0.246,1.495-0.053,2.72-1.793,2.867h-3.879c-1.25-0.016-2.475-0.183-3.704-0.393l-15.239-2.687 c-4.283-2.877-7.226-6.723-9.666-11.216c-0.744-1.408-1.431-2.842-2.09-4.291C294.135,165.568,293.826,162.416,296.008,159.869	L296.008,159.869z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(253,185,19)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.365,167.523c-2.801-6.212,2.553-10.267,7.328-7.334 l4.865,2.981c1.674,1.025,4.138,1.64,5.68,1.64h10.432c2.082,0,2.518,1.143,2.76,2.447l3.171,17.107 c0.182,0.979,0.125,1.432-0.638,1.432l-5.182,0.004l-17.122-3.02C301.553,178.843,298.28,171.768,296.365,167.523L296.365,167.523z M301.424,160.654c-2.267,0-4.115,1.849-4.115,4.115s1.849,4.115,4.115,4.115c2.268,0,4.115-1.849,4.115-4.115 S303.691,160.654,301.424,160.654L301.424,160.654z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(255,209,0)" 
        d="M324.67,164.811h-10.432h-0.102l3.662,19.76l6.982,1.23 l4.418-0.003l-3.882-20.944C325.121,164.825,324.906,164.811,324.67,164.811L324.67,164.811z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(255,242,0)" 
        points="320.891,164.811 324.021,164.811 327.912,185.797 324.762,185.797 320.891,164.811"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M301.424,161.135c2.002,0,3.636,1.633,3.636,3.635	s-1.634,3.635-3.636,3.635s-3.635-1.633-3.635-3.635S299.422,161.135,301.424,161.135L301.424,161.135z M301.424,162.477 c-1.263,0-2.293,1.03-2.293,2.293c0,1.264,1.03,2.294,2.293,2.294c1.264,0,2.294-1.03,2.294-2.294	C303.718,163.507,302.688,162.477,301.424,162.477L301.424,162.477z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M309.85,166.428c0-0.748-0.611-1.359-1.36-1.359s-1.36,0.611-1.36,1.359 c0,0.313,0.11,0.599,0.289,0.829c-0.572,1.412-1.606,2.598-2.961,3.249c-1.514,0.68-0.701,2.139,0.471,1.58 c1.85-0.891,3.301-2.468,4.05-4.392C309.486,167.497,309.85,167.005,309.85,166.428z"
    />
    {/*Suit*/}
    <polygon 
        fill="rgb(215,215,215)" 
        points="363.313,198.04 353.853,201.166 364.894,201.938 363.313,198.04 	"
    />

    {/*Flag & Name Black*/}
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M302.316,180.853c-0.537,0.276-1.146,0.435-1.79,0.435	c-2.166,0-3.934-1.768-3.934-3.935c0-1.056,0.421-2.015,1.102-2.724c-0.07-0.118-0.14-0.237-0.209-0.356 c-0.797,0.786-1.293,1.877-1.293,3.08c0,2.388,1.946,4.335,4.334,4.335c0.753,0,1.462-0.195,2.08-0.535 C302.509,181.053,302.412,180.953,302.316,180.853L302.316,180.853z M303.133,181.674c-0.762,0.461-1.654,0.729-2.606,0.729 c-2.78,0-5.05-2.269-5.05-5.05c0-1.47,0.635-2.796,1.645-3.721c-0.02-0.034-0.039-0.067-0.057-0.102 c-0.067-0.12-0.133-0.239-0.198-0.36c-1.165,1.021-1.903,2.519-1.903,4.183c0,3.065,2.498,5.564,5.563,5.564 c1.101,0,2.128-0.323,2.992-0.878c-0.062-0.058-0.124-0.114-0.185-0.172C303.266,181.803,303.199,181.738,303.133,181.674
		L303.133,181.674z M282.623,186.037c-0.01,0.063-0.021,0.129-0.032,0.198c-0.013,0.071-0.039,0.157-0.041,0.252l0.001,1.396	l1.046-1.098c0.097-0.102,0.144-0.218,0.196-0.306c0.056-0.087,0.106-0.173,0.154-0.257l1.746,0.246 c-0.085,0.226-0.179,0.482-0.282,0.77c-0.022,0.063-0.037,0.105-0.045,0.129l-0.884,2.476c-0.007,0.021-0.021,0.062-0.043,0.122 c-0.021,0.06-0.037,0.109-0.051,0.147c-0.029,0.093-0.061,0.197-0.098,0.315c-0.034,0.119-0.07,0.247-0.108,0.385l-1.21-0.17 c0.051-0.112,0.103-0.229,0.154-0.354c0.052-0.124,0.1-0.256,0.146-0.386l0.566-1.508c0.012-0.031,0.061-0.168,0.145-0.408 c0.084-0.241,0.158-0.455,0.225-0.639l-1.459,1.642c-0.1,0.11-0.145,0.219-0.19,0.292c-0.046,0.071-0.089,0.144-0.128,0.215
		l-1.003-0.141c0.012-0.066,0.024-0.141,0.038-0.219c0.013-0.08,0.035-0.168,0.035-0.26l0.018-1.878l-0.854,2.103 c-0.051,0.129-0.11,0.282-0.18,0.464c-0.07,0.183-0.154,0.412-0.258,0.691l-1.08-0.151c0.248-0.522,0.522-1.15,0.823-1.881	c0.3-0.73,0.614-1.539,0.942-2.428L282.623,186.037L282.623,186.037z M289.396,188.085c-0.139-0.084-0.283-0.149-0.433-0.202 c-0.147-0.049-0.298-0.086-0.451-0.107c-0.416-0.059-0.778,0.018-1.087,0.229c-0.312,0.211-0.537,0.533-0.676,0.968 c-0.132,0.406-0.126,0.72,0.016,0.942c0.144,0.223,0.427,0.363,0.856,0.425c0.154,0.021,0.312,0.032,0.473,0.03 c0.16,0,0.33-0.014,0.511-0.038l-0.466,1.003c-0.188,0.02-0.376,0.027-0.566,0.023c-0.189-0.002-0.378-0.018-0.566-0.045 c-0.728-0.101-1.22-0.365-1.48-0.789c-0.261-0.426-0.276-0.994-0.047-1.703c0.24-0.749,0.635-1.303,1.179-1.66 s1.188-0.482,1.933-0.379c0.186,0.027,0.369,0.064,0.548,0.113c0.18,0.048,0.358,0.11,0.534,0.184L289.396,188.085L289.396,188.085	z M296.803,188.029c-0.064,0.145-0.127,0.292-0.189,0.441c-0.062,0.151-0.119,0.301-0.173,0.448l-0.634,1.677 c-0.009,0.022-0.02,0.049-0.03,0.078s-0.026,0.074-0.044,0.133c-0.07,0.216-0.065,0.383,0.016,0.503 c0.078,0.121,0.234,0.196,0.469,0.229c0.256,0.036,0.467,0,0.629-0.107c0.163-0.109,0.297-0.302,0.401-0.581l0.568-1.516	c0.08-0.212,0.14-0.373,0.178-0.484c0.04-0.111,0.075-0.217,0.106-0.315c0.027-0.084,0.048-0.147,0.061-0.19 c0.014-0.043,0.024-0.082,0.034-0.119l1.429,0.2c-0.062,0.136-0.125,0.275-0.185,0.423c-0.062,0.144-0.127,0.303-0.192,0.473 l-0.572,1.505c-0.025,0.063-0.057,0.151-0.099,0.265c-0.159,0.434-0.308,0.736-0.45,0.912c-0.206,0.249-0.508,0.42-0.9,0.515 c-0.396,0.095-0.858,0.104-1.393,0.028c-0.627-0.087-1.079-0.272-1.353-0.554c-0.274-0.284-0.345-0.633-0.21-1.049 c0.033-0.105,0.18-0.502,0.438-1.19c0.258-0.687,0.484-1.327,0.68-1.918L296.803,188.029L296.803,188.029z M298.68,192.85
		c0.232-0.527,0.484-1.151,0.755-1.874c0.272-0.721,0.563-1.54,0.871-2.452l3.592,0.504l-0.38,1.001	c-0.177-0.032-0.332-0.062-0.47-0.087c-0.137-0.025-0.229-0.04-0.277-0.048l-1.418-0.195c-0.069,0.164-0.122,0.288-0.153,0.368 c-0.03,0.081-0.057,0.155-0.079,0.225l-0.027,0.082l1.162,0.164c0.15,0.021,0.286,0.038,0.405,0.051	c0.121,0.013,0.233,0.021,0.336,0.027l-0.384,1.017c-0.071-0.017-0.149-0.031-0.232-0.046s-0.211-0.034-0.384-0.059l-0.31-0.044	l-0.966-0.134c-0.042,0.088-0.08,0.174-0.115,0.259c-0.035,0.086-0.065,0.172-0.094,0.259c-0.018,0.053-0.027,0.09-0.034,0.116 c-0.009,0.025-0.014,0.048-0.019,0.069c0.124,0.032,0.262,0.063,0.409,0.089c0.148,0.028,0.332,0.059,0.55,0.089	c0.229,0.031,0.449,0.059,0.659,0.078c0.212,0.021,0.411,0.037,0.6,0.045l-0.38,1.006L298.68,192.85L298.68,192.85z M302.864,193.438c0.232-0.527,0.483-1.151,0.755-1.874c0.272-0.722,0.563-1.539,0.871-2.452l3.591,0.504l-0.378,1.002 c-0.177-0.033-0.335-0.063-0.471-0.088c-0.137-0.024-0.229-0.041-0.278-0.047l-1.418-0.197c-0.07,0.165-0.122,0.289-0.153,0.37 c-0.029,0.079-0.056,0.154-0.078,0.224l-0.027,0.084l1.16,0.162c0.152,0.021,0.288,0.038,0.406,0.05
		c0.119,0.013,0.232,0.021,0.337,0.027l-0.384,1.018c-0.072-0.018-0.15-0.032-0.233-0.046s-0.21-0.033-0.385-0.057l-0.309-0.045 l-0.966-0.135c-0.041,0.088-0.079,0.174-0.113,0.258c-0.035,0.087-0.068,0.173-0.095,0.26c-0.017,0.053-0.029,0.091-0.035,0.115 c-0.008,0.027-0.013,0.049-0.019,0.069c0.125,0.034,0.264,0.063,0.41,0.091c0.148,0.028,0.332,0.057,0.55,0.088 c0.229,0.032,0.447,0.059,0.658,0.079c0.212,0.021,0.412,0.036,0.6,0.043l-0.379,1.007L302.864,193.438L302.864,193.438z M311.672,191.168l0.007-0.021c0.092-0.257,0.158-0.449,0.199-0.577c0.017-0.049,0.037-0.115,0.063-0.205 c0.027-0.09,0.047-0.155,0.06-0.198l1.263,0.178c-0.091,0.195-0.166,0.362-0.225,0.504c-0.059,0.141-0.112,0.273-0.158,0.393
		l-0.928,2.464c-0.035,0.087-0.063,0.17-0.091,0.247c-0.028,0.077-0.055,0.156-0.08,0.236c-0.016,0.043-0.036,0.117-0.069,0.227 s-0.059,0.194-0.079,0.256l-1.761-0.246c-0.045-0.428-0.092-0.834-0.141-1.217l-0.007-0.051l-0.298-2.025l-0.643,1.724 c-0.056,0.153-0.153,0.429-0.291,0.833c-0.077,0.221-0.138,0.395-0.182,0.517l-1.265-0.179c0.147-0.32,0.333-0.771,0.562-1.355 c0.229-0.583,0.598-1.572,1.107-2.965l1.713,0.24l0.492,3.324L311.672,191.168L311.672,191.168z M290.654,189.443 c-0.137,0.423-0.15,0.751-0.043,0.986c0.108,0.232,0.338,0.376,0.688,0.426c0.063,0.009,0.126,0.014,0.186,0.013l-0.051-0.422 l0.423-0.203l0.161,0.488c0.059-0.031,0.115-0.07,0.171-0.114c0.257-0.205,0.454-0.52,0.591-0.941	c0.126-0.392,0.133-0.703,0.021-0.932c-0.114-0.229-0.342-0.367-0.687-0.416c-0.329-0.046-0.623,0.029-0.881,0.225 S290.782,189.045,290.654,189.443L290.654,189.443z M292.349,191.738l0.521,1.582l-1.01,0.646l-0.252-2.08 c-0.218,0.013-0.445,0.004-0.686-0.029c-0.721-0.102-1.216-0.372-1.488-0.811c-0.273-0.439-0.297-1.003-0.076-1.694 c0.238-0.734,0.63-1.282,1.175-1.643c0.546-0.36,1.181-0.489,1.901-0.388c0.748,0.105,1.264,0.373,1.541,0.805 c0.28,0.434,0.306,1.002,0.079,1.703c-0.238,0.742-0.634,1.291-1.184,1.65C292.705,191.586,292.531,191.673,292.349,191.738
		L292.349,191.738z M287.588,184.414c-4.322-1.163-9.289-1.802-13.04-2.031c2.18-2.404,2.294-6.686-0.083-8.612 c4.016,1.425,7.849,1.67,9.855,1.993c-0.393-1.215-0.8-2.535-1.222-3.331s0.406-0.727,1.597-0.511 c2.625,0.493,7.721,1.594,11.015,1.486c-0.746,1.03-1.419,2.524-1.419,3.944s0.758,3.19,1.671,4.275 c-2.573-0.72-5.353-1.297-7.926-1.628c-0.11-0.014-0.245,0.108-0.187,0.278l1.061,3.043	C289.285,184.395,289.003,184.795,287.588,184.414L287.588,184.414z"
    />
    {/*Flag Gray*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M287.3,180.002l-0.099-0.051c-1.057-0.191-1.1,0.47-1.061,0.772 c0.091,0.707,1.677,1.38,2.197,2.279L287.3,180.002L287.3,180.002z"
    />
    {/*Flag White*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M279.369,177.553l-2.761-0.484 c-0.076-0.851-0.215-1.537-0.57-2.293c0.882,0.271,1.822,0.485,2.704,0.641L279.369,177.553L279.369,177.553z M283.306,178.243	l0.792,2.567l-3.938-0.69l-0.791-2.567L283.306,178.243L283.306,178.243z M282.651,176.023l1.857,0.245l0.72,2.312l-1.923-0.338
		L282.651,176.023L282.651,176.023z M287.568,183.844c-0.916-0.241-1.832-0.482-2.748-0.723l-0.723-2.311l1.744,0.308 c0.33,0.868,1.355,1.347,2.016,2.032C288.347,183.695,288.217,184.014,287.568,183.844L287.568,183.844z M280.16,180.12 l0.718,2.312c-1.138-0.214-2.543-0.343-3.954-0.465l-0.594-1.92c0.054-0.189,0.1-0.381,0.139-0.574L280.16,180.12L280.16,180.12z M292.753,180.257l-0.728-2.206l1.92,0.35c0.131,0.77,0.508,1.65,0.951,2.371L292.753,180.257L292.753,180.257z M285.168,176.864 l3.304,0.566l0.749,2.273l-1.926-0.286c-0.521-0.048-0.799-0.134-1.235,0.172L285.168,176.864L285.168,176.864z M293.983,173.916 l0.343,1.137c-0.098,0.262-0.26,0.737-0.299,1.088l-2.789-0.529l-0.67-2.092C291.344,173.698,292.958,173.873,293.983,173.916 L293.983,173.916z M287.686,174.991l3.553,0.62l0.787,2.439l-3.554-0.62L287.686,174.991L287.686,174.991z M286.993,172.813 l0.692,2.179l-3.305-0.566l-0.494-1.582c-0.189-0.454,0.065-0.611,0.805-0.44L286.993,172.813L286.993,172.813z"
    />
    

    

		</svg>
		</div>

	);//return
}