export const BUTTON_WHEEL_OZ_RACING_BLACK = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*OZ Logo*/}
    
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.454,328.454l25.008,0.001l25.773-34.348h-45.611 c-24.395-1.242-41.677-17.389-42.94-42.1c1.284-24.585,18.679-40.855,42.951-42.113l25.542-0.002v-34.346l-30.724-0.001 C196.605,175.547,162,210.145,162,252C162,294.05,196.403,328.454,238.454,328.454L238.454,328.454z M444.062,175.578 l-88.869,118.434h83.45v34.361H275.938l88.868-118.434h-85.698v-34.361H444.062L444.062,175.578z M476.375,294.107h-27.802v34.347
		h32.972C523.588,328.454,558,294.051,558,252c0-41.856-34.605-76.454-76.455-76.455h-25.044l-25.773,34.349l45.638-0.001 c24.271,1.26,41.666,17.53,42.95,42.115C518.051,276.72,500.77,292.865,476.375,294.107L476.375,294.107z"
    />

    

    

		</svg>
		</div>

	);//return
}