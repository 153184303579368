export const BUTTON_LOGO_GULF = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    {/*Blue*/}
    <polygon 
        fill="rgb(121,187,221)" 
        points="630,72 90,72 90,432 630,432 630,72 	"
    />
    {/*Blue HL*/}
    <polygon 
        fill="rgb(146,203,232)" 
        points="630,72 90,72 630,432 630,72 	"
    />
    {/*Orange*/}
    <polygon 
        fill="rgb(243,112,33)" 
        points="90,377.084 90,432 630,432 630,377.084 90,377.084 	"
    />
    {/*Orange HL*/}
    <polygon 
        fill="rgb(245,130,32)" 
        points="547.626,377.084 630,432 630,377.084 547.626,377.084 	
		"
    />
    {/*Navy*/}
    <path 
        fill="rgb(46,49,146)" 
        d="M487.318,248.913c1.384-9.884,1.482-24.708,0.197-36.074 c-1.777-17.791-13.046-22.634-17.691-23.919c-8.4-21.645-22.93-40.127-42.104-53.568c-19.966-13.838-43.291-21.151-67.703-21.151 s-47.837,7.313-67.703,21.151c-19.174,13.441-33.703,31.924-42.104,53.568c-4.744,1.285-15.913,6.128-17.691,23.919 c-1.286,11.366-1.286,26.19,0.197,36.074c1.285,14.528,9.982,21.743,17.395,23.722c8.402,21.645,22.93,40.127,42.105,53.568
		c19.964,13.936,43.389,21.25,67.801,21.25s47.836-7.314,67.703-21.25c19.174-13.441,33.802-31.924,42.104-53.568 C477.238,270.656,485.936,263.441,487.318,248.913L487.318,248.913z"
    />
    {/*White*/}
    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M466.24,268.693c-0.582,0.097-1.07,0.484-1.266,1.069 c-7.863,20.97-21.938,38.937-40.388,51.848c-19.032,13.301-41.266,20.293-64.568,20.293c-23.304,0-45.636-6.992-64.569-20.293 c-18.544-12.911-32.526-30.878-40.488-51.848c-0.196-0.585-0.68-0.973-1.264-1.069c-6.309-1.361-14.077-7.478-15.338-20.489 c0,0,0,0,0-0.093c-1.361-9.42-1.361-23.693-0.1-34.568c1.553-16.02,11.362-19.809,15.534-20.778 c0.585-0.097,1.069-0.485,1.264-1.067c7.962-20.873,21.944-38.838,40.489-51.75c18.933-13.207,41.168-20.196,64.472-20.196 c23.206,0,45.536,6.989,64.469,20.196c18.549,12.912,32.526,30.877,40.487,51.75c0.195,0.582,0.684,0.971,1.266,1.067 c4.077,0.97,13.982,4.759,15.532,20.778c1.263,10.875,1.167,25.148-0.192,34.568c0,0.093,0,0.093,0,0.093 C480.416,261.216,472.551,267.332,466.24,268.693L466.24,268.693z M439.203,258.964v-20.153h-7.988v-13.402h7.988v-8.556 c0.284-5.704,3.134-10.551,11.691-10.551c8.459,0,13.021,1.14,18.154,4.563l-0.284,9.981c-5.417-3.139-11.216-3.993-11.216,1.14
		v3.423h7.982v13.402h-7.982v20.153H439.203L439.203,258.964z M419.239,258.964v-51.807h-19.108v51.807H419.239L419.239,258.964z M364.389,225.408h19.014v33.556h-19.014v-1.14c-3.707,2.279-13.402,2.565-15.684,2.565s-17.967-1.996-17.967-13.973v-21.009	h19.108v15.684c-0.286,3.041,0.855,7.891,6.558,7.891c5.703,0,7.984-1.713,7.984-6.465V225.408L364.389,225.408z M286.822,237.385 v-10.266h28.992v31.845h-19.011v-1.14c-3.71,1.14-10.742,1.14-14.449,1.14c-13.688,0.189-32.035-8.364-32.035-29.467 c0-21.105,20.057-30.42,33.459-30.42c13.404,0,23.481,3.802,28.043,6.654v11.121c-6.272-1.997-12.168-4.847-20.153-4.847	c-7.984,0-19.961,4.847-19.677,18.535c0.284,13.592,10.553,15.876,14.831,15.876c4.275,0,8.556-0.952,9.981-2.947v-6.085H286.822 L286.822,237.385z"
    />
    {/*Orange*/}
    <path 
        fill="rgb(243,112,33)" 
        d="M460.21,268.66c-15.399,39.924-54.47,68.349-100.192,68.349 c-45.725,0-84.794-28.425-100.193-68.349C322.944,279.306,396.996,279.876,460.21,268.66L460.21,268.66z M460.21,192.898 c-15.399-39.83-54.47-68.254-100.192-68.254c-45.725,0-84.794,28.424-100.193,68.254	C322.944,182.346,396.996,181.775,460.21,192.898L460.21,192.898z"
    />
    

    

		</svg>
		</div>

	);//return
}