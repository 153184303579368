export const SOFTTOP_GRAY_BLACK = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
    <defs>
        <linearGradient id="Gradient1" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(7)">
            <stop offset="0%" style={{"stopColor":"rgb(45,60,65)","stopOpacity":"1"}} />
            <stop offset="100%" style={{"stopColor":"rgb(90,105,115)","stopOpacity":"1"}} />
        </linearGradient>
    </defs> 


    

    {/*Softtop*/}
    <path 
        fill="rgb(50,50,50)" 
        d="M151.118,189.606l76.259-63.988l50.179-15.341l93.086,5.693 l17.973,27.413l-84.655-5.178c-18.271-1.117-27.086,5.952-31.021,20.143l-10.821,39.02L151.118,189.606L151.118,189.606z"
    />
      
    {/*Softtop Fold*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M250.536,187.059c0.194,0.535-0.091,1.123-0.638,1.313c-0.546,0.189-1.146-0.092-1.342-0.628l-19.816-54.556
		c-0.195-0.535,0.091-1.124,0.637-1.313c0.547-0.189,1.148,0.092,1.342,0.627L250.536,187.059L250.536,187.059z"
    />
    {/*Trim*/}
        <path 
        fill="rgb(0,0,0)" 
        d="M151.118,189.606l2.879-2.415l100.039,6.995l10.593-38.207	c5.502-19.843,20.23-27.599,39.799-26.401l78.272,4.785l5.914,9.021l-84.655-5.178c-18.272-1.117-27.086,5.951-31.021,20.143 l-10.109,36.452l132.196,9.244l-0.548,2.579L151.118,189.606L151.118,189.606z"
 />
        


    

		</svg>
		</div>

	);//return
}