
const FormatPrice = (txt) => {

    /*
    const reverseString = (str) => {

        return str.split("").reverse().join("");

    }
    */
    const commafy = (str) => {

        return parseFloat(str).toLocaleString();

    }
    var price;
    price = "$" + commafy(txt);
    //newTxt = txt;
    if(!price.match(/\./gi)) price = price + ".00";
    if(price.split(".")[1].length < 2) price = price + "0";

    return price;

}


export default FormatPrice;