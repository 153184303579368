export const HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M295.31,140.592c15.959,0,28.52,9.59,31.176,23.92l3.755,20.187 c4.622,1.241,1.783,8.212,0.386,11.543l-2.126,5.008c-0.975,2.013-2.859,5.337-4.996,5.281l-6.514-0.342 c-3.291-0.191-6.769-0.616-10.016-1.176l-39.141-6.901c-2.97-7.738-5.789-16.426-5.789-26.26	C262.045,152.664,276.121,140.592,295.31,140.592L295.31,140.592z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(0,141,72)" 
        d="M276.645,164.147l11.273,19.528 c1.203,2.244-0.338,2.344-2.334,2.059l-14.273-2.516l0.587-2.099l6.589,1.161c3.529,0.623,4.536-1.516,2.744-4.621l-5.636-9.76 L276.645,164.147L276.645,164.147z M280.54,153.029l43.523,7.674c0.952,2.435,1.246,4.107,1.246,4.107l3.89,20.986	c3.744,0,2.264,5.341,0.289,9.992l-2.115,4.983c-0.649,1.53-2.321,2.045-3.865,1.964l-6.571-0.344 c-2.552-0.135-7.379-0.795-9.718-1.207l-38.247-6.744c-0.155-0.406-0.312-0.809-0.464-1.206l0.951-3.396l37.506,6.614 c4.426,0.779,6.86-1.519,4.322-5.914l-19.658-34.05l-11.795-2.079C280.061,153.932,280.296,153.473,280.54,153.029L280.54,153.029z M304.32,143.277c5.582,1.371,9.721,3.963,12.773,6.889l-28.299-4.99c3.945-1.783,8.846-2.307,14.818-1.941L304.32,143.277 L304.32,143.277z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(0,114,54)" 
        d="M274.267,183.739l-2.956-0.521l0.587-2.1l1.519,0.269 C273.678,182.15,273.962,182.936,274.267,183.739L274.267,183.739z M269.459,189.839l8.033,1.417 c0.316,0.665,0.645,1.342,0.988,2.032l23.384,4.121l-0.594,1.398l-30.487-5.376c-0.439-1.147-0.902-2.294-1.367-3.439 L269.459,189.839L269.459,189.839z"
    /> 
    {/*Roof Shadow*/}
    <path 
        fill="rgb(0,88,38)" 
        d="M266.453,158.911c1.004-2.022,2.261-4.001,3.791-5.849	l0.615,1.066c-1.27,1.507-2.379,3.167-3.299,4.978L266.453,158.911L266.453,158.911z M271.592,155.395l5.053,8.753l-1.05,3.753 l-4.739-8.209C271.076,158.146,271.35,156.732,271.592,155.395L271.592,155.395z M266.488,182.367l-1.814-0.319 c-0.203-0.767-0.387-1.531-0.549-2.298l1.645,0.289C265.99,180.82,266.23,181.596,266.488,182.367L266.488,182.367z M270.495,152.764c0.376-0.441,0.767-0.874,1.175-1.298l8.87,1.563c-0.244,0.443-0.479,0.902-0.706,1.381l-7.856-1.386 c0.008-0.055,0.015-0.108,0.022-0.163c-0.045,0.047-0.091,0.096-0.136,0.143L270.495,152.764L270.495,152.764z M268.508,193.235 c-0.504-1.315-0.986-2.583-1.441-3.818l2.279,0.401c0.023,0.059,0.047,0.115,0.07,0.174L268.508,193.235L268.508,193.235z M283.137,144.178c3.517-1.222,7.559-1.93,12.172-1.93c3.358,0,6.349,0.377,9.012,1.029l-0.707-0.043	c-5.973-0.365-10.873,0.158-14.818,1.941L283.137,144.178L283.137,144.178z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(0,166,81)" 
        d="M308.754,158.004l13.832,2.439c0.59,1.383,1.074,2.84,1.436,4.367 l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248 l-6.552-0.344c-1.833-0.109-3.734-0.318-5.565-0.569l2.025-4.763c0.626-1.492,4.915-11.166,1.598-11.75l-0.695-0.123l-3.55-19.154 C310.708,164.219,310.248,161.455,308.754,158.004L308.754,158.004z M314.001,149.621l-13.278-2.342 c-1.78-1.433-3.868-2.729-6.321-3.768c0.288-0.018,0.585-0.031,0.905-0.039C302.362,143.663,308.889,145.82,314.001,149.621 L314.001,149.621z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(114,191,68)" 
        d="M316.834,159.429l5.752,1.015c0.59,1.383,1.074,2.84,1.436,4.367 l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248 l-4.869-0.256l1.96-4.615c0.656-1.563,5.192-10.655,1.716-11.268l-0.137-0.024l-3.807-20.54 C318.168,163.455,317.718,161.555,316.834,159.429L316.834,159.429z M314.001,149.621l-6.067-1.07 c-3.209-2.187-7.393-4.018-12.859-5.072c0.076-0.002,0.152-0.004,0.232-0.006C302.362,143.663,308.889,145.82,314.001,149.621	L314.001,149.621z"
    />
    {/*Pattern Base*/}
    <path 
        fill="rgb(167,169,172)" 
        d="M263.274,171.851c0-3.713,0.891-8.062,2.854-12.266l4.27,0.753	l10.203,17.673c1.864,3.228,0.103,3.931-2,3.56l-14.63-2.579C263.525,176.682,263.274,174.338,263.274,171.851L263.274,171.851z M272.288,150.844c2.556-2.502,5.708-4.646,9.517-6.169l36.176,6.378c2.867,3.014,4.666,6.272,5.762,8.863L272.288,150.844 L272.288,150.844z M266.781,188.636c-0.725-2-1.367-3.919-1.898-5.819l20.588,3.63c4.006,0.706,4.127-1.302,3.077-3.121 l-17.129-29.668l19.754,3.483l19.484,33.746c2.011,3.484,0.46,5.566-3.58,4.854L266.781,188.636L266.781,188.636z"
    />
    {/*Pattern Shadow*/}
    <path 
        fill="rgb(128,130,133)" 
        d="M263.274,171.851c0-3.713,0.891-8.062,2.854-12.266l4.27,0.753	l4.926,8.532l-3.231,11.554l-8.121-1.433C263.525,176.682,263.274,174.338,263.274,171.851L263.274,171.851z M272.288,150.844 c2.556-2.502,5.708-4.646,9.517-6.169l5.897,1.04c-2.747,1.477-4.993,3.646-6.782,6.65L272.288,150.844L272.288,150.844z M266.781,188.636c-0.725-2-1.367-3.919-1.898-5.819l6.232,1.099l-1.462,5.228L266.781,188.636L266.781,188.636z M276.916,163.178	l-5.497-9.521l8.106,1.429c-0.63,1.426-1.186,2.998-1.669,4.727L276.916,163.178L276.916,163.178z"
    />
    {/*Pattern Shadow 1*/}
    <path 
        fill="rgb(128,130,133)" 
        d="M267.23,159.779l3.167,0.559l0.304,0.526 c-0.564,4.79-0.482,10.873,2.451,19.746l-7.592-1.339c-0.631-2.412-1.036-4.871-1.062-7.413	C264.605,167.313,265.574,163.279,267.23,159.779L267.23,159.779z M266.754,183.146l7.814,1.377
		c0.728,1.864,1.572,3.84,2.549,5.936l-8.093-1.428C268.231,187.08,267.445,185.125,266.754,183.146L266.754,183.146z"
    />
    {/*Pattern Roof Shadow*/}
    <path 
        fill="rgb(88,89,91)" 
        d="M267.23,159.779l3.167,0.559l0.304,0.526 c-0.523,4.446-0.491,10.005,1.861,17.874l-0.471,1.686l-6.531-1.152c-0.631-2.412-1.036-4.871-1.062-7.413 C264.605,167.313,265.574,163.279,267.23,159.779L267.23,159.779z M266.754,183.146l4.361,0.769l-1.462,5.228l-0.629-0.111 C268.231,187.08,267.445,185.125,266.754,183.146L266.754,183.146z"
    />
    {/*Highlight Base*/}
    <path 
        fill="rgb(209,211,212)" 
        d="M313.193,177.633l13.65,2.406l1.174,6.332l1.332,0.235	l0.078,0.425c0.929,0.139,1.037,1.269,0.798,2.66l-13.983-2.467c0.203-1.654-0.036-2.936-1.181-3.137l-0.694-0.123L313.193,177.633 L313.193,177.633z"
    />
    {/*Hightlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M309.259,149.516l5.94,1.047c2.955,2.459,5.365,5.512,7.033,9.088 l-5.744-1.014C315.135,155.672,312.922,152.362,309.259,149.516L309.259,149.516z M301.189,185.237 c2.736,4.739-0.287,7.462-4.34,8.136l10.34,1.656c3.271,0.453,4.438-0.762,2.837-3.794l-19.309-33.444l-5.891-0.895 L301.189,185.237L301.189,185.237z"
    />
    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M296.008,159.869c1.348-1.318,2.801-1.846,4.589-1.877	c1.384,0.087,2.493,0.447,3.72,1.144l4.868,2.983c1.511,0.874,3.318,1.426,5.068,1.468h10.462c2.343,0.171,3.435,1.169,3.916,3.433 l3.176,17.135c0.246,1.495-0.053,2.72-1.793,2.867h-3.879c-1.25-0.016-2.475-0.183-3.704-0.393l-15.239-2.687 c-4.283-2.877-7.226-6.723-9.666-11.216c-0.744-1.408-1.431-2.842-2.09-4.291C294.135,165.568,293.826,162.416,296.008,159.869	L296.008,159.869z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(2,63,136)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.365,167.523c-2.801-6.212,2.553-10.267,7.328-7.334 l4.865,2.981c1.674,1.025,4.138,1.64,5.68,1.64h10.432c2.082,0,2.518,1.143,2.76,2.447l3.171,17.107 c0.182,0.979,0.125,1.432-0.638,1.432l-5.182,0.004l-17.122-3.02C301.553,178.843,298.28,171.768,296.365,167.523L296.365,167.523z M301.424,160.654c-2.267,0-4.115,1.849-4.115,4.115s1.849,4.115,4.115,4.115c2.268,0,4.115-1.849,4.115-4.115 S303.691,160.654,301.424,160.654L301.424,160.654z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(0,102,179)" 
        d="M324.67,164.811h-10.432h-0.102l3.662,19.76l6.982,1.23 l4.418-0.003l-3.882-20.944C325.121,164.825,324.906,164.811,324.67,164.811L324.67,164.811z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(0,174,239)" 
        points="318.42,164.811 324.021,164.811 327.912,185.797 324.762,185.797 322.227,185.351 318.42,164.811 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M301.424,161.135c2.002,0,3.636,1.633,3.636,3.635	s-1.634,3.635-3.636,3.635s-3.635-1.633-3.635-3.635S299.422,161.135,301.424,161.135L301.424,161.135z M301.424,162.477 c-1.263,0-2.293,1.03-2.293,2.293c0,1.264,1.03,2.294,2.293,2.294c1.264,0,2.294-1.03,2.294-2.294	C303.718,163.507,302.688,162.477,301.424,162.477L301.424,162.477z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M309.85,166.428c0-0.748-0.611-1.359-1.36-1.359s-1.36,0.611-1.36,1.359 c0,0.313,0.11,0.599,0.289,0.829c-0.572,1.412-1.606,2.598-2.961,3.249c-1.514,0.68-0.701,2.139,0.471,1.58 c1.85-0.891,3.301-2.468,4.05-4.392C309.486,167.497,309.85,167.005,309.85,166.428z"
    />
    {/*Blue Visor Stripe Background*/}
    <path 
        fill="rgb(241,90,34)" 
        d="M310.899,165.252l-1.489,4.53h18.488l-0.469-2.524	c-0.149-0.809-0.375-1.555-1.02-2.006H310.899L310.899,165.252z"
    />
    {/*Blue Visor Stripe Highlight*/}
    <polygon 
        fill="rgb(245,130,32)" 
        points="314.219,165.252 315.059,169.782 326.23,169.782 325.392,165.252 314.219,165.252 	"
    />
    {/*Blue Visor Stripe Highlight1*/}
    <polygon 
        fill="rgb(253,185,19)" 
        points="318.502,165.252 324.104,165.252 324.943,169.782 319.347,169.782 318.502,165.252 	"
    />
    {/*Suit*/}
    <polygon 
        fill="rgb(0,141,72)" 
        points="363.313,198.04 353.853,201.166 364.894,201.938 363.313,198.04 	"
    />
    
    

    

		</svg>
		</div>

	);//return
}