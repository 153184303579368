
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0

*/
    



const SpecBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "1px solid lightgray",

    width:              props.width || "96%", 
    height:             props.height || "auto",

    margin:            props.margin || "20px 20px 20px 20px",
    padding:           props.padding || "0 2% 0 2%",


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    min-height:50px;

    width   :${props => props.width};
    height  :${props => props.height};
    margin  :${props => props.margin};
    padding  :${props => props.padding};


    flex-grow:1;

    position:relative;

    @media screen and (max-width: 800px) {

        width:100%;
        flex-grow:0;
        margin:20px 0 20px 0;

    }



`;




    const SpecHeading = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`

        background-color:${props => props.backgroundColor};
    
        width:auto;
        height:auto;
        margin:15px 0 0 0;
        padding:0 0 0 0;

        font-size: 22px;
        font-weight: bold;
        text-align: left;
        text-decoration:none;
        color:#454d5f;
        line-height: 1.0;


    `;




    const SpecTop = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
 
    }))`

        background-color:${props => props.backgroundColor};
        width:90%;
        height:auto;
        margin:0 auto 0 auto;
        padding:0 0 0 0;

        position:relative;

    `;


    const SpecBottom = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
 
    }))`

        background-color:${props => props.backgroundColor};
        width:90%;
        height:auto;
        margin:0 auto 0 auto;
        padding:0 0 0 0;

        position:relative;

    `;





    
    const SpecInput = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
        border:             props.border || "1px solid lightgray",
        width:              props.width || "100%"

    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
      
        width:${props => props.width};
        height:auto;
        margin:22px 0 22px 0;

        padding:7px 0 6px 0;

        position:relative;
        float:left;

        font-size: 18px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:black;
        line-height: 1.2;


    `;






    const SpecControls = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`


        background-color:${props => props.backgroundColor};
    
        width:auto;
        height:auto;
        padding:0 0 0 0;
        margin:18px 0 0 0;

        position:relative;
        float:right;



        .Button {

            background-color:transparent;
    
            width:40px;
            height:40px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:pointer;

        }

        .ButtonMask {

            background-color:transparent;
    
            width:40px;
            height:40px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:1;

            cursor:pointer;

        }



        .Img {

            width:40px;
            height:40px;

            position:relative;
            float:right;
        }



    `;








export {

    SpecBox,
    SpecTop,
    SpecBottom,
    SpecControls,
    SpecHeading,
    SpecInput

}
