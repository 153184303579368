
const Handler = (props) => {

    //console.log(JSON.stringify(props,null,2));
    //console.log("Hander Call: "+props.call);


    var scrollbox;
    var scrollpos;
    //var indx;



    //console.log("== HANDLER ==");


    switch(props.call)
    {
    case'flyrHeight':

        props.handleState({

            call:props.call,
            value:props.data

        });
        
    break;
    case'adjustContentsHeight':

        // contents are set

        console.log("CONTENTS HEIGHT: "+props.data.h);

        //setFlyrHeight(1000);//causes a reset
        //setContentsHeight(props.data.h);//causes a reset 

        props.handleState({call:'contentsHeight',value:props.data.h});
        

    break;
    default:

        console.log("Readr Handler Default");

        // reset 

        scrollbox = document.getElementById('flyrBase');
        scrollpos = scrollbox.scrollTop;
        scrollbox.scrollTop = scrollpos;


        // -- close flyr -- //
        props.handleState({

            call:"handleCloseReadr",
            value:''

        });


    break;
    }//==


}


export default Handler;

