
import React from 'react';


import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';


import Messngr from 'utils/components/Process/Messngr';


export const FScomplete = (props) => {


    //console.log(JSON.stringify(props.errorArr,null,2));
    //alert('FSerror')

    const portalTarget = usePortal("stage");


    // -- dynamic message -- //

    // var items = [];
    // for(var obj of props.successArr)
    // {

    //     items.push({

    //         call:'span',
    //         text:obj['message']
    //     })


    // }


    // let data = {

    //     isError         : false,
    //     errorType       : '',
    //     isTimed         : true,
    //     timedDuration   : 2000,
    //     items           : items

    // };



    // -- generic message -- //

    var successArr;
    successArr = [

        {
            "call":"heading",
            "text":"Success" 
        },
        {
            "call":"span",
            "text":"Message Was Sent Successfully!!" 
        }

    ];

    let data = {

        isError         : false,
        errorType       : '',
        isTimed         : true,
        timedDuration   : 2000,
        items           : successArr

    };



    return createPortal(<div>

        <Messngr 

            handle={(v) => props.handle(v)}
            data={data}

        />

    </div>, portalTarget);


}


