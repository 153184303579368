import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



export const ProgressTimer = (props) => {


    // -- ref -- //
    const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);
    // const refControls = useRef(null);



    // -- state -- //
    const [progressCompleted, setProgressCompleted] = useState(1);
    const [finished, setFinished] = useState(false);

    useEffect(() => {

        // if(progressCompleted)
        // {
        //     const timer = setInterval(() => setProgressCompleted(Math.floor(Math.random() * 100) + 1), props.duration);
        //     return () => clearInterval(timer);
        // }

        if(props.complete)
        {
            setFinished(true);
            setProgressCompleted(100);
        }
        else
        if(!finished)
        {
            const timer = setInterval(() => {

                //console.log("offsetWidth: "+refA.current.offsetWidth);

                //setProgressCompleted(Math.floor(Math.random() * 100) + 1);

                var number = progressCompleted + 0.5;
                if(number < 101)
                {
                    setProgressCompleted(parseFloat(number))

                    //if(number === 25) console.log("progress timer = 25% done")
                    //if(number === 50) console.log("progress timer = 50% done")
                    //if(number === 75) console.log("progress timer = 75% done")
                    if(number === 100)
                    {

                        props.handle({

                            call:'transition',
                            case:'out'

                        });

                    }



                }else{
                    setFinished(true);
                    //console.log("finished");
                    //console.log(props.duration);
                    props.handle({

                        call:'progressChange'

                    });
 
                }
    
            }, 40);

            return () => clearInterval(timer);

        }else{

            if(props.timerReset)
            {
                //console.log("timerReset");
                setProgressCompleted(1);
                setFinished(false);
            }

        }




    }, [
        
        props,
        progressCompleted,
        finished,
        //refA
    
    ]);





    // // -- state -- //
    // const [done, setDone] = useState(false);

    // useEffect(() => {

    //     if(finished && !done)
    //     {
    //         setDone(true);
   

    //     }


    // }, [
        
    //     props,
    //     finished,
    //     done
    
    // ]);





    const progressBar = {

        backgroundColor: props.barColor,

        width: `${progressCompleted}%`,      
        height: '5px',

        margin: "0 0 0 0", 
        padding: "0 0 0 0",

        position: "absolute", 
        zIndex: "10",
        bottom: "0px",
        left: "0px",


    }


    return (

        <div 
        ref={refA}
        style={progressBar}
        >
        </div>
                
    )  

}





