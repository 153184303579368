export const BUTTON_HELMET_RED_DRIVER_NELSON_PIQUET = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

{/*White*/}
    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M349.306,75.195c81.227,0,145.155,48.81,158.675,121.745l19.111,102.742 c23.525,6.317,9.076,41.796,1.963,58.75l-10.82,25.488c-4.961,10.244-14.554,27.163-25.429,26.88l-33.152-1.739 c-16.75-0.975-34.45-3.137-50.977-5.984l-199.213-35.126C194.35,328.565,180,284.35,180,234.298 C180,136.64,251.643,75.195,349.306,75.195L349.306,75.195z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c132.609,0,152.695,114.836,152.695,114.836l19.797,106.813c19.057,0,11.521,27.184,1.473,50.857l-10.768,25.363 c-3.305,7.789-11.814,10.408-19.673,9.996l-33.445-1.75c-12.987-0.686-37.557-4.046-49.46-6.144l-194.665-34.325 C198.848,306.577,186.258,274.046,186.258,234.292L186.258,234.292z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M349.291,89.858c70.36,1.903,130.402,42.188,146.149,108.603 l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154l-10.701,25.205 c-2.614,5.388-8.206,6.501-13.748,6.352l-33.347-1.749c-9.329-0.557-19.007-1.621-28.326-2.898l10.309-24.235 c3.187-7.6,25.016-56.836,8.127-59.809l-3.534-0.626l-18.067-97.489c-1.342-7.252-12.938-78.463-83.552-108.409 C346.149,89.968,347.66,89.897,349.291,89.858L349.291,89.858z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M349.291,89.858c70.36,1.903,130.402,42.188,146.149,108.603 l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154l-10.701,25.205 c-2.614,5.388-8.206,6.501-13.748,6.352l-24.782-1.302l9.976-23.49c3.34-7.958,26.428-54.231,8.732-57.349l-0.695-0.124 L466.93,198.461c-4.776-25.767-23.648-90.202-118.822-108.573C348.495,89.878,348.883,89.868,349.291,89.858L349.291,89.858z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(220,220,220)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c17.078,0,32.282,1.919,45.827,5.239l-3.559-0.219c-70.719-4.324-111.903,15.766-131.095,84.377l-26.938,96.316	c6.317,21.124,15.909,45.509,30.115,74.063l119.016,20.975l-3.022,7.118l-155.17-27.362c-2.236-5.84-4.593-11.67-6.958-17.506 l-4.623,16.512C197.654,303.405,186.258,272.122,186.258,234.292L186.258,234.292z"
    />


    
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M217.517,326.627c-12.123-29.842-24.703-59.773-25.026-92.295
		c0.939-40.369,15.274-72.831,38.178-96.688c-3.882,29.911-17.352,64.028,2.873,131.686L217.517,326.627L217.517,326.627z"
    />
    {/*Front Edge*/}
    <path 
        fill="rgb(210,210,210)" 
        d="M490.843,166.512l-8.092-1.427 c5.542,10.224,9.846,21.372,12.689,33.376l20.339,109.736l6.779,1.197l0.398,2.162c9.568,1.427,2.033,23.545-2.654,35.409 l6.223,1.094c8.15-21.367,12.182-42.785-4.732-42.785l-19.797-106.813C501.996,198.461,499.57,184.604,490.843,166.512 L490.843,166.512z"
    />

{/*Red*/}


    {/*Base Color*/}
    <path 
        fill="rgb(200,0,0)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c86.534,0,125.145,48.904,141.537,82.887l-223.176-39.355c-34.071-6.005-63.859,31.378-72.289,74.884 c-6.958,43.773,8.236,89.084,42.323,95.093l288.819,50.927c-1.029,2.709-2.123,5.413-3.25,8.072l-10.768,25.363 c-3.305,7.789-11.814,10.408-19.673,9.996l-33.445-1.75c-12.987-0.686-37.557-4.046-49.46-6.144l-194.665-34.325 C198.848,306.577,186.258,274.046,186.258,234.292L186.258,234.292z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(180,0,0)" 
        d="M349.291,89.858c57.642,1.561,108.35,28.883,133.46,75.227	l-70.366-12.411c-11.899-22.427-32.441-47.666-67.701-62.622C346.149,89.968,347.66,89.897,349.291,89.858L349.291,89.858z M520.303,346.966c-1.264,3.187-2.316,5.642-2.779,6.745l-10.701,25.205c-2.614,5.388-8.206,6.501-13.748,6.352l-33.347-1.749	c-9.329-0.557-19.007-1.621-28.326-2.898l10.309-24.235c1.203-2.878,5.08-11.725,8.52-21.775L520.303,346.966L520.303,346.966z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(255,0,0)" 
        d="M349.291,89.858c57.642,1.561,108.35,28.883,133.46,75.227	l-29.162-5.145c-14.638-27.377-44.275-58.243-105.481-70.053C348.495,89.878,348.883,89.868,349.291,89.858L349.291,89.858z M520.303,346.966c-1.264,3.187-2.316,5.642-2.779,6.745l-10.701,25.205c-2.614,5.388-8.206,6.501-13.748,6.352l-24.782-1.302 l9.976-23.49c1.192-2.838,4.896-10.557,8.36-19.444L520.303,346.966L520.303,346.966z"
    />
    {/*Red Shadow*/}
    <path 
        fill="rgb(160,0,0)" 
        d="M379.645,371.495l-155.17-27.362 c-2.236-5.845-4.588-11.67-6.958-17.506l9.234-33.033c3.4,1.65,7.048,2.854,10.945,3.539l5.795,1.024 c5.373,13.827,11.999,28.843,20.16,45.245l119.016,20.975L379.645,371.495L379.645,371.495z"
    />
    {/*Red Roof Shadow*/}
    <path 
        fill="rgb(160,0,0)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c17.088,0,32.298,1.919,45.847,5.244l-3.578-0.224c-52.518-3.211-88.752,7.058-111.779,40.652l-12.128-2.142 c-34.071-6.005-63.859,31.378-72.289,74.884c-6.163,38.769,5.055,78.74,31.383,91.544l-13.862,49.555 C197.654,303.405,186.258,272.122,186.258,234.292L186.258,234.292z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(100,0,0)" 
        d="M217.517,326.627c-12.123-29.842-24.703-59.773-25.026-92.295 c0.145-6.356,0.632-12.51,1.427-18.465c-1.819,34.063,9.643,66.439,32.834,77.727L217.517,326.627L217.517,326.627z"
    />
    


{/*Logo*/}


    {/*Logo Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M419.896,241.634l-27.015-9.29c-16.546-6.377-31.338-15.269-45.916-25.274 c-10.567-7.465-20.826-15.313-31.065-23.216c-9.205-7.118-18.42-14.191-28.053-20.732c-7.992-5.219-15.736-9.905-25.274-11.979 c-32.063-4.344-49.778,26.801-54.917,53.06c-4.16,26.486,1.839,61.727,33.46,68.646c9.647,1.298,18.46-0.423,27.85-2.614 c11.282-2.858,22.361-6.353,33.45-9.886c12.321-3.932,24.648-7.794,37.134-11.188c17.113-4.434,34.058-7.72,51.786-8.053	L419.896,241.634L419.896,241.634z"
    />
    {/*Logo Red*/}
    <path 
        fill="rgb(200,0,0)" 
        d="M395.987,237.409c-47.074-15.165-91.146-62.313-122.604-78.558	c-31.462-16.243-56.434,14.439-62,46.012c-5.566,31.562,7.406,68.938,42.521,64.44C289.02,264.801,346.567,235.564,395.987,237.409 L395.987,237.409z"
    />
    {/*Logo White*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M307.6,186.999c-8.848-6.804-17.72-13.524-27.069-19.628 c-6.073-3.758-12.167-7.231-19.274-8.772c-27.113-3.663-41.876,25.18-46.15,46.92c-3.425,21.91,0.592,54.009,27.322,59.874 c7.316,0.983,13.946-0.164,21.109-1.66c10.875-2.53,21.517-5.815,32.153-9.181c12.326-3.927,24.643-7.854,37.084-11.387 c14.598-3.977,29.956-7.68,45.066-8.957c-13.753-6.357-26.939-15.1-39.286-23.828C328.067,202.805,317.838,194.902,307.6,186.999 L307.6,186.999z"
    /> 
    {/*Logo Yellow*/}
    <path 
        fill="rgb(255,185,20)" 
        d="M284.716,174.708c-7.436-5.056-15.215-10.443-24.116-12.382 c-24.683-3.33-37.83,23.823-41.771,43.849c-3.102,19.911-0.05,50.161,24.256,55.489c9.046,1.213,18.172-1.178,26.895-3.39 c11.268-3.052,22.372-6.625,33.495-10.174c9.395-2.978,18.803-5.891,28.287-8.584c11.084-3.018,22.147-5.656,33.495-7.525 c-10.021-5.646-19.523-11.899-28.897-18.529c-7.993-5.776-15.841-11.73-23.649-17.744 C303.474,188.585,294.259,181.428,284.716,174.708L284.716,174.708z"
    />
    {/*Logo Yellow Shadow*/}
    <path 
        fill="rgb(250,150,30)" 
        d="M263.527,163.092c-0.96-0.293-1.938-0.557-2.928-0.766	c-24.683-3.33-37.83,23.823-41.771,43.849c-2.783,17.894-0.596,44.127,17.516,53.134l24.131-86.286	C261.435,169.593,262.458,166.288,263.527,163.092L263.527,163.092z"
    />
    

{/*Visor*/}    

    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M352.859,173.311c6.859-6.71,14.255-9.394,23.356-9.553 c7.043,0.442,12.689,2.276,18.932,5.82l24.777,15.185c7.689,4.448,16.89,7.257,25.797,7.471h53.247 c11.924,0.869,17.482,5.949,19.932,17.471l16.164,87.21c1.252,7.61-0.268,13.843-9.125,14.594h-19.743 c-6.362-0.08-12.596-0.93-18.854-1.998l-77.563-13.674c-21.8-14.643-36.775-34.216-49.196-57.085
		c-3.788-7.167-7.282-14.464-10.637-21.84C343.326,202.318,341.756,186.273,352.859,173.311L352.859,173.311z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(0,60,130)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.679,212.269c-14.255-31.616,12.992-52.254,37.298-37.327 l24.763,15.174c8.519,5.22,21.06,8.346,28.907,8.346h53.094c10.597,0,12.813,5.815,14.047,12.456l16.139,87.071 c0.924,4.985,0.637,7.286-3.246,7.286l-26.373,0.02l-87.146-15.368C381.081,269.881,364.426,233.87,354.679,212.269 L354.679,212.269z M380.426,177.307c-11.537,0-20.945,9.409-20.945,20.945s9.408,20.945,20.945,20.945 c11.541,0,20.944-9.409,20.944-20.945S391.967,177.307,380.426,177.307L380.426,177.307z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(0,125,200)" 
        d="M498.74,198.461h-53.094h-0.517l18.639,100.57l35.538,6.263 l22.486-0.015l-19.757-106.6C501.037,198.535,499.943,198.461,498.74,198.461L498.74,198.461z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(0,175,240)" 
        points="466.93,198.461 495.44,198.461 515.242,305.274 499.208,305.274 486.305,303.003 466.93,198.461 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.426,179.753c10.188,0,18.504,8.31,18.504,18.499 s-8.315,18.5-18.504,18.5c-10.189,0-18.5-8.311-18.5-18.5S370.236,179.753,380.426,179.753L380.426,179.753z M380.426,186.582 c-6.427,0-11.671,5.243-11.671,11.67c0,6.432,5.244,11.676,11.671,11.676c6.432,0,11.675-5.244,11.675-11.676 C392.101,191.825,386.857,186.582,380.426,186.582L380.426,186.582z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M423.31,206.692c0-3.808-3.111-6.919-6.924-6.919 c-3.812,0-6.924,3.111-6.924,6.919c0,1.595,0.563,3.047,1.472,4.22c-2.913,7.187-8.177,13.221-15.07,16.536 c-7.704,3.459-3.568,10.885,2.396,8.042c9.414-4.533,16.8-12.56,20.612-22.352C421.461,212.135,423.31,209.63,423.31,206.692z"
    />

    

    

		</svg>
		</div>

	);//return
}