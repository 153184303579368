export const BUTTON_LOGO_CANON = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    
    {/*Red*/}
    <path 
        fill="rgb(180,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.067,175l-44.978,27l23.988-39c-6.997-4-14.993-6-23.988-6	c-24.988,0-44.979,20-44.979,45s19.99,45,44.979,45c15.992,0,29.985-9,37.981-22l2.998,7c-10.994,15-31.984,26-58.971,26 c-44.978,0-69.966-27-69.966-56s25.987-56,69.966-56C190.084,146,210.074,157,223.067,175L223.067,175z M423.969,255h-32.983v-66 c0-4-2.999-8-7.996-8c-3.998,0-7.996,4-7.996,8v66h-32.984v-74H320.02l31.984-15c1.999-1,3.998-1,6.997-1	c7.996,0,14.992,6,15.992,14l25.987-13c1.999,0,3.998-1,6.996-1c8.996,0,15.992,7,15.992,16V255z M312.023,185l19.99,70H299.03 l-3.998-15c-6.997,9-20.99,17-37.981,17c-14.992,0-33.983-12-33.983-29c0-18,18.991-30,33.983-30c10.994,0,20.989,3,27.986,9	l-6.997-26h-42.979l38.98-14C294.032,160,309.025,173,312.023,185L312.023,185z M274.042,245c8.996,0,15.992-8,15.992-17 c0-10-6.996-17-15.992-17c-9.995,0-16.991,7-16.991,17C257.051,237,264.047,245,274.042,245L274.042,245z M434.964,211	c0-25,20.989-46,46.977-46s45.978,21,45.978,46c0,26-19.99,47-45.978,47S434.964,237,434.964,211L434.964,211z M492.935,251	c4.998-2,7.996-7,6.997-12l-16.992-60c-0.999-6-5.997-9-11.994-7c-4.997,1-7.996,7-6.996,12l16.991,60 C481.94,249,486.938,252,492.935,251L492.935,251z M629.867,255h-32.983v-65c0-5-3.998-9-7.996-9c-4.998,0-8.995,4-8.995,9v65 h-32.984v-74h-20.989l30.984-15c1.999-1,3.998-1,6.997-1c7.996,0,14.992,6,15.992,14l25.986-13c1.999,0,4.998-1,6.997-1 c8.995,0,16.991,7,16.991,16V255z"
    />
    {/*Yellow*/}
    <polygon 
        fill="rgb(255,212,0)" 
        points="630,358 630,303.084 90,303.084 90,358 630,358 	"
    />
    
    {/*Navy*/}
    <polygon 
        fill="rgb(31,45,94)" 
        points="630,302.671 630,276.803 90,276.803 90,302.671 630,302.671 	"
    />
    
    

    

		</svg>
		</div>

	);//return
}