export const BUTTON_MAIN_HELMET = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Helmet*/}

    <path 
        fill="rgb(150,150,150)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.261,234.291c0-59.354,44.646-150.687,163.063-150.687
		c114.133,0,144.925,85.046,151.295,108.771c-0.521-0.058-1.055-0.107-1.602-0.147h-53.255c-8.909-0.215-18.109-3.024-25.8-7.472
		l-24.783-15.188c-6.242-3.547-11.892-5.377-18.933-5.818c-9.103,0.156-16.503,2.842-23.359,9.555
		c-11.108,12.966-9.538,29.009-2.916,43.602c3.356,7.382,6.851,14.674,10.639,21.848c12.424,22.87,27.399,42.445,49.206,57.091
		l77.571,13.677c6.259,1.069,12.493,1.921,18.855,1.997h19.749c2.313-0.196,4.125-0.768,5.519-1.639
		c5.477,8.742-0.651,28.494-8.197,46.272l-10.767,25.363c-3.307,7.788-11.816,10.411-19.675,9.999l-33.451-1.752
		c-12.99-0.681-37.562-4.047-49.467-6.146l-194.692-34.329C198.853,306.589,186.261,274.051,186.261,234.291L186.261,234.291z
		 M380.459,179.744c10.191,0,18.504,8.313,18.504,18.504s-8.313,18.505-18.504,18.505c-10.193,0-18.506-8.313-18.506-18.505
		S370.266,179.744,380.459,179.744L380.459,179.744z M380.459,186.574c6.43,0,11.675,5.244,11.675,11.674
		c0,6.432-5.245,11.676-11.675,11.676c-6.432,0-11.677-5.244-11.677-11.676C368.782,191.818,374.027,186.574,380.459,186.574
		L380.459,186.574z M466.973,198.459h28.517l19.8,106.829h-16.035l-12.903-2.275L466.973,198.459L466.973,198.459z M349.333,75.171
		c80.117,0,143.405,47.48,158.104,118.761c6.312,2.426,9.734,7.397,11.514,15.768l16.167,87.224c0.44,2.672,0.536,5.173,0.095,7.327
		c11.24,11.896-0.052,39.775-6.106,54.202l-10.82,25.492c-4.964,10.245-14.556,27.164-25.431,26.881l-33.156-1.738
		c-16.754-0.973-34.456-3.138-50.989-5.984l-199.241-35.132C194.353,328.583,180,284.358,180,234.295
		C180,136.629,251.652,75.171,349.333,75.171L349.333,75.171z"
    />
    





    

		</svg>
		</div>

	);//return
}