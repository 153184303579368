export const STATIC_BACKGROUND = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
    



{/*Static Base*/}

    
    
    {/*Seat*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M255.376,162.66h-2.837c-2.884,0-4.508,2.578-4.507,4.561l0.007,29.164	l16.594,1.16C259.459,180.779,263.311,162.66,255.376,162.66L255.376,162.66z"
    /> 
    {/*A Pillar & Dash*/}   
    <path 
        fill="rgb(0,0,0)" 
        d="M478.626,214.736l-42.126-5.173l-42.022-2.939l2.631-12.377 c0.997-4.69,5.101-4.503,9.115-2.083l23.766,14.326l-59.348-90.52l17.208,1.053L478.626,214.736L478.626,214.736z"
    />
    {/*Roll Hoop*/}
    <path 
        fill="rgb(65,65,65)" 
        d="M227.025,152.904c1.351,0,11.844,0.094,15.035,9.756 l11.264,34.094l-26.299-1.839V152.904L227.025,152.904z"
    />
    {/*Roll Hoop 1*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M227.025,152.904c-1.35,0-11.871,0.086-15.035,9.756 l-9.984,30.505l25.02,1.75V152.904L227.025,152.904z"
    />
    {/*Glass*/}
    <path 
        fill="rgb(60,70,80)" 
        d="M478.626,214.736l-29.052-3.566l-31.893-48.645 c-0.013-3.48,1.26-6.768,3.664-9.446L478.626,214.736L478.626,214.736z"
    />
    {/*Glass Highlight*/}   
    <path 
        fill="rgb(90,100,120)" 
        d="M421.756,162.66l32.316,44.306l3.999,0.597l-35.375-47.591	C420.692,157.275,421.212,161.914,421.756,162.66L421.756,162.66z"
    />
    
    

    

		</svg>
		</div>

	);//return
}