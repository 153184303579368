export const BUTTON_PILL_GRAY_CARBON_FIBER = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Back*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M360,67.5c101.619,0,184.5,82.881,184.5,184.5S461.619,436.5,360,436.5	S175.5,353.619,175.5,252S258.381,67.5,360,67.5L360,67.5z"
    />
{/*Base Dark*/}
    <path 
        fill="rgb(40,40,40)" 
        d="M440.997,330.004l47.66,47.658 c9.972-10.207,18.743-21.589,26.089-33.913l-43.748-43.744L440.997,330.004L440.997,330.004z M311.998,333.003L372,392.999	l29.998-29.997l-60.001-59.998L311.998,333.003L311.998,333.003z M248.998,336.002l60,59.998l30-29.999l-59.999-59.998 L248.998,336.002L248.998,336.002z M377.999,333.003l59.996,59.998l30.004-29.999l-60.001-59.998L377.999,333.003L377.999,333.003z M247.042,391.958l25.955-25.957L213,306.003l-17.942,17.941C206.745,350.579,224.698,373.88,247.042,391.958L247.042,391.958z M506.998,330.004l9.948,9.948c6.197-10.997,11.272-22.703,15.068-34.964L506.998,330.004L506.998,330.004z M444,201.003	l59.996,60.001L534,231l-60.001-59.996L444,201.003L444,201.003z M314.999,204.002L375,264.003l29.997-30.004L345,174.003 L314.999,204.002L314.999,204.002z M252.001,207.001l59.997,60.001l29.998-30l-59.997-60L252.001,207.001L252.001,207.001z M186,207.001l59.999,60.001l30.003-30l-60.003-60L186,207.001L186,207.001z M380.994,204.002l60,60.001l30.011-29.997 l-60.003-60.003L380.994,204.002L380.994,204.002z M509.997,201.003l28.606,28.606c-1.979-15.834-6.037-31.037-11.877-45.336
		L509.997,201.003L509.997,201.003z M180.695,267.697l2.306,2.306l30-30.001l-28.411-28.411C181.589,224.588,180,238.113,180,252	C180,257.289,180.244,262.522,180.695,267.697L180.695,267.697z M319.585,76.591l58.414,58.411l29.997-30l-32.309-32.308 C370.516,72.244,365.285,72,360,72C346.112,72,332.584,73.59,319.585,76.591L319.585,76.591z M272.049,95.053l42.95,42.948 L345,108.002l-30.229-30.227C299.677,81.708,285.346,87.563,272.049,95.053L272.049,95.053z M234.338,123.342l14.66,14.659 l30.001-29.999l-10.748-10.748C255.926,104.598,244.546,113.371,234.338,123.342L234.338,123.342z M383.999,75.006l59.994,60.002 l25.613-25.613c-24.074-18.557-52.945-31.171-84.384-35.612L383.999,75.006L383.999,75.006z"
    />
{/*Base Light*/}
    <path 
        fill="rgb(50,50,50)" 
        d="M440.997,396l10.748,10.749 c12.325-7.344,23.704-16.115,33.911-26.086l-14.658-14.658L440.997,396L440.997,396z M311.998,399l32.307,32.305
		C349.479,431.756,354.711,432,360,432c13.885,0,27.412-1.586,40.406-4.589L341.997,369L311.998,399L311.998,399z M334.781,430.219 l1.215-1.216L276,369l-25.606,25.605C274.471,413.163,303.34,425.777,334.781,430.219L334.781,430.219z M374.995,395.998 l30.229,30.229c15.094-3.934,29.426-9.787,42.723-17.278l-42.944-42.947L374.995,395.998L374.995,395.998z M444,267.002 L503.996,327L534,297.001l-60.001-59.999L444,267.002L444,267.002z M377.999,267.002l59.996,59.996l30.004-29.992l-60.001-60.008	L377.999,267.002L377.999,267.002z M314.999,270.003L375,330.004l29.997-29.999L345,240.002L314.999,270.003L314.999,270.003z M248.998,270.003l60,59.997l30-29.995l-59.999-60.003L248.998,270.003L248.998,270.003z M186,273.002l59.999,60.001L276,303.004 l-60-60.001L186,273.002L186,273.002z M506.998,264.003l28.411,28.41C538.412,279.416,540,265.888,540,252
		c0-5.288-0.244-10.52-0.695-15.694l-2.306-2.307L506.998,264.003L506.998,264.003z M193.274,319.73l16.728-16.727L181.397,274.4	C183.381,290.232,187.434,305.436,193.274,319.73L193.274,319.73z M446.999,138.001l59.999,60.003l17.944-17.945 c-11.683-26.639-29.642-49.937-51.984-68.017L446.999,138.001L446.999,138.001z M317.998,141.004l60.001,59.999l29.997-29.999 L348,111.001L317.998,141.004L317.998,141.004z M252.001,141.004l59.997,59.999l30-29.999L282,111.003L252.001,141.004 L252.001,141.004z M205.251,160.256l43.747,43.746l30.001-29.999l-47.66-47.661C221.368,136.55,212.595,147.93,205.251,160.256 L205.251,160.256z M380.994,138.001l60,59.997l30.011-30l-60.003-59.996L380.994,138.001L380.994,138.001z M187.983,199.02	L213,174.003l-9.949-9.95C196.854,175.052,191.778,186.759,187.983,199.02L187.983,199.02z"
    />
  {/*Shadow Dark*/}
    <path 
        fill="rgb(25,25,25)" 
        d="M461.045,350.051l27.612,27.611 c9.972-10.207,18.743-21.589,26.089-33.913l-22.274-22.272C482.906,331.938,472.387,341.513,461.045,350.051L461.045,350.051z M370.144,391.143l1.856,1.856l2.886-2.886C373.311,390.472,371.73,390.816,370.144,391.143L370.144,391.143z M308.187,395.188 l0.812,0.812l0.709-0.709C309.2,395.259,308.693,395.225,308.187,395.188L308.187,395.188z M419.778,374.783l18.217,18.218 l30.004-29.999l-10.404-10.403C445.785,361.148,433.128,368.594,419.778,374.783L419.778,374.783z M247.044,391.955l7.275-7.275 c-8.966-2.917-17.693-6.375-26.139-10.337C234.04,380.65,240.347,386.536,247.044,391.955L247.044,391.955z M506.998,330.004 l9.948,9.948c6.197-10.997,11.272-22.703,15.068-34.964L506.998,330.004L506.998,330.004z"
    />
{/*Shadow Light*/}
    <path 
        fill="rgb(35,35,35)" 
        d="M440.997,396l10.748,10.749
		c12.325-7.344,23.704-16.115,33.911-26.086l-14.658-14.658L440.997,396L440.997,396z M311.998,399l32.317,32.315 c5.17,0.45,10.401,0.685,15.685,0.685c13.885,0,27.412-1.586,40.406-4.589l-35.295-35.296c-13.202,2.397-26.795,3.651-40.671,3.651	c-3.025,0-6.035-0.061-9.031-0.179L311.998,399L311.998,399z M334.772,430.228l1.224-1.225l-34.373-34.377 c-14.696-1.476-28.987-4.359-42.738-8.513l-8.491,8.49C274.469,413.164,303.335,425.784,334.772,430.228L334.772,430.228z M374.995,395.998l30.229,30.229c15.094-3.934,29.426-9.787,42.723-17.278l-32.298-32.3c-10.548,4.637-21.508,8.5-32.81,11.509 L374.995,395.998L374.995,395.998z M495.311,318.314l8.686,8.686L534,297.001l-13.435-13.434 C513.233,295.944,504.77,307.576,495.311,318.314L495.311,318.314z M522.766,279.77l12.644,12.644	C538.412,279.416,540,265.888,540,252c0-3.262-0.094-6.502-0.267-9.723C535.2,255.353,529.499,267.89,522.766,279.77 L522.766,279.77z"
    /> 
{/*HL Dark*/}
    <path 
        fill="rgb(55,55,55)" 
        d="M198.521,219.521l13.943,13.943 c5.197-14.074,12.123-27.321,20.506-39.494L216,177.002l-5.068,5.068C205.368,193.864,201.16,206.414,198.521,219.521	L198.521,219.521z M195.384,257.619l13.55-13.551c0.621-2.072,1.268-4.134,1.958-6.176l-13.303-13.303 c-1.506,8.919-2.301,18.074-2.301,27.41C195.288,253.881,195.32,255.754,195.384,257.619L195.384,257.619z M332.585,89.59 l27.705,27.703c6.644-0.754,13.393-1.154,20.233-1.154c5.274,0,10.495,0.232,15.655,0.684l11.818-11.82l-14.217-14.217	c-10.908-2.289-22.207-3.498-33.779-3.498C350.662,87.287,341.506,88.084,332.585,89.59L332.585,89.59z M283.31,106.313 l25.062,25.062c6.796-3.01,13.813-5.607,21.017-7.764L345,108.002l-17.481-17.48C311.875,93.672,297.025,99.055,283.31,106.313 L283.31,106.313z M245.14,134.144l3.858,3.857l28.419-28.417C265.662,116.435,254.826,124.702,245.14,134.144L245.14,134.144z M401.628,92.637l31.364,31.369c5.503,1.693,10.894,3.646,16.158,5.846l9.557-9.557C441.872,107.637,422.553,98.121,401.628,92.637 L401.628,92.637z"
    />
{/*HL Light*/}
    <path 
        fill="rgb(65,65,65)" 
        d="M198.786,285.789l2.811,2.811	c0.456-12.454,2.192-24.587,5.083-36.277l-11.003,11.004C196.2,270.967,197.249,278.466,198.786,285.789L198.786,285.789z M201.471,294.474l-0.832-0.831c0.271,1.033,0.551,2.063,0.842,3.089c-0.004-0.511-0.02-1.02-0.02-1.531 C201.462,294.957,201.47,294.716,201.471,294.474L201.471,294.474z M462.092,122.908l-8.755,8.754 c15.01,6.723,28.938,15.451,41.438,25.846C485.596,144.463,474.571,132.805,462.092,122.908L462.092,122.908z M354.974,117.977	L348,111.001l-10.355,10.356C343.321,119.953,349.102,118.821,354.974,117.977L354.974,117.977z M252.001,141.004l15.496,15.497	c11.21-9.156,23.545-16.984,36.759-23.241L282,111.003L252.001,141.004L252.001,141.004z M216.431,171.435l19.022,19.022 c8.337-11.508,18.006-21.992,28.778-31.223l-22.091-22.091C232.163,147.381,223.5,158.902,216.431,171.435L216.431,171.435z M424.666,121.664l-13.664-13.662l-9.384,9.38C409.466,118.311,417.159,119.748,424.666,121.664L424.666,121.664z"
    />



    

		</svg>
		</div>

	);//return
}