import styled, { keyframes } from 'styled-components';


// const fadeIn = keyframes`

//     from {
//         transform: scale(.3);
//         opacity: 0;
//     }

//     to {
//         transform: scale(1);
//         opacity: 1;
//     }
// `;

// const fadeOut = keyframes`

//     from {
//         transform: scale(1);
//         opacity: 0;
//     }

//     to {
//         transform: scale(.3);
//         opacity: 1;
//     }
// `;



const fadeMoveAnimation = (props) => keyframes`

    from {
        transform: scale(1.04);
        opacity: ${props.opacityTo};
    }

    to {
        transform: scale(1);
        opacity: ${props.opacityTo};
    }


`;

export const FadeMove = styled.div.attrs(props => ({


    marginTop:      props.marginTop || "0px",
    marginLeft:     props.marginLeft || "0px",

    opacity:        props.opacity || "1",


    out:                    props.out || false,
    animationTime:          props.animationTime || '0.5s',
    transitionTime:         props.transitionTime || '0.5s'


    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;

    /* transition: opacity .5s ease; */
    /* animation:ImageOnly_FadeIn .5s infinite; */


    // display: inline-block;
    // visibility: ${props => props.out ? 'hidden' : 'visible'};
    // animation: ${props => props.out ? fadeOut : fadeIn} 1s linear;
    // transition: visibility 1s linear;


}))`

    position:relative;
    float:left;

    margin:${props => props.marginTop} 0 0 ${props => props.marginLeft};

    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    transition: visibility ${props => props.transitionTime} linear;
    animation: ${props => props.out 
        ? 
            fadeMoveAnimation({

                opacityFrom:props.opacity,
                opacityTo:"0"

            }) 
        :     
            fadeMoveAnimation({

                opacityFrom:"0",
                opacityTo:props.opacity

            })

        } ${props => props.animationTime} linear;

`;








// export const Fade = styled.div.attrs(props => ({


//     out:                    props.out || false,
//     animationTime:          props.animationTime || '1s',
//     transitionTime:         props.transitionTime || '1s'


//     // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
//     //transition: visibility ${props => props.transitionTime} linear;


// }))`
  
//     display: inline-block;
//     visibility: ${props => props.out ? 'hidden' : 'visible'};
//     animation: ${props => props.out ? fadeOut : fadeIn} ${props => props.animationTime} linear;
 
// `;


