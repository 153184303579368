export const STATIC_GROUND = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


		{/*Ground*/}

		<polygon 
			fill="rgb(0,0,0)" 
			points="655.779,396 655.779,388.799 88.713,388.799 92.127,396 655.779,396"
		/>
	

		{/* <polygon 
			fill="rgb(0,0,0)" 
			points="655.779,396.799 655.779,388.799 88.713,388.799 92.127,396.799 655.779,396.799"
		/>
	 	*/}


		{/* <polygon 
			fill="rgb(0,0,0)" 
			points="655.779,398 655.779,388.799 88.713,388.799 92.127,398 655.779,398"
		/> */}


		{/* <polygon 
			fill="rgb(0,0,0)" 
			points="655.779,400 655.779,388.799 88.713,388.799 92.127,400 655.779,400"
		/> */}

    

		</svg>
		</div>

	);//return
}