export const BUTTON_BANNER_GRAY_BLACK = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Banner*/}
    <path 
        fill="rgb(30,30,30)" 
        d="M597.6,151.506c-108.774-0.688-226.397-4.169-283.313,14.889 c-56.914,19.059-141.949,108.238-165.658,143.266c-23.708,35.027-16.308,25.807-26.229,43.524 c130.597-37.84,323.753-53.146,475.2-55.646V151.506L597.6,151.506z"
    />
    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M459.79,190.365l13.403,0.023c1.469,0,2.122,0.873,2.122,1.709	v1.256c0,0.836-0.653,1.709-2.122,1.709h-11.131h-23.315c-5.476,0-8.043,3.398-8.043,8.156c0,4.276,0,8.553,0,12.83l54.615-0.033 v-8.154l-42.49,0.029c-1.469,0-2.123-0.871-2.123-1.708v-1.256c0-0.836,0.654-1.708,2.123-1.708h17.425h17.021	c5.475,0,8.043-3.398,8.043-8.156v-4.674c0-4.757-2.568-8.156-8.043-8.156h-21.87l-16.395-0.007c-5.476,0-8.043,3.399-8.043,8.157 v1.712l10.002-0.003c0-0.836,0.654-1.709,2.123-1.709L459.79,190.365L459.79,190.365z M489.568,197.352h13.711v4.717h-13.711
		V197.352L489.568,197.352z M402.899,203.219h11.131c1.469,0,2.123,0.872,2.123,1.708v1.256c0,0.837-0.654,1.708-2.123,1.708	l-43.205,0.025v8.133h47.287c5.476,0,8.043-3.399,8.043-8.158v-4.672c0-4.758-2.567-8.156-8.043-8.156h-17.021h-17.425 c-1.469,0-2.122-0.873-2.122-1.709v-1.256c0-0.836,0.653-1.709,2.122-1.709h41.887v-8.156h-45.969 c-5.476,0-8.043,3.399-8.043,8.156v4.674c0,4.758,2.567,8.156,8.043,8.156H402.899L402.899,203.219z M521.974,197.379v-6.99h34.538 c1.922,0,3.494,1.574,3.494,3.496l0,0c0,1.922-1.572,3.494-3.494,3.494H521.974L521.974,197.379z M510.478,182.232	c0,0,46.103,0,51.499,0c5.396,0,10.546,1.152,10.546,11.609c0,10.458-9.012,10.458-10.898,10.458l16.888,11.749h-19.554
		L542.917,204.3h-20.943v11.749h-11.496V182.232L510.478,182.232z"
    />

    

    

		</svg>
		</div>

	);//return
}