import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';
//import WindowDimensionsProvider from 'utils/WindowDimensionsProvider';

import { 

    SliderContainer,
    SliderMessage,
    SliderBox,
    Ranger 


} from './Styled';


import { LibNumber } from 'library';


/*
const getPercentage = (current, max) => (100 * current) / max;
//const getLeft = percentage => `calc(${percentage}% - 10px)`;
const getLeft = (percentage,difference) => {

    let calcd = `calc(${percentage}% - ${difference}px)`;
    return calcd;

}
*/

const roundPercent = (obj) => {

    var call = obj.call;
    var percent = obj.percent;
    var precision = obj.precision;
    
    switch(call)
    {
    case'30-50-70':

        if(percent > 0 && percent < 40) percent = 30;
        if(percent > 30 && percent < 50) percent = 50;
        if(percent > 50 && percent < 70) percent = 70;
        if(percent > 70 && percent < 90) percent = 70;
        if(percent > 90) percent = 100;


    break;
    default:

        if(percent > 0 && percent < precision)
        {
            percent = LibNumber.Round({

                call        :'roundUp',//round,roundDown,roundUp
                number      : percent,
                precision   :10//10, 100, 10000

            })

        }else{

            percent = LibNumber.Round({

                call        :'round',//round,roundDown,roundUp
                number      : percent,
                precision   :10//10, 100, 10000

            }); 

        }

        //percent = parseFloat(percent).toFixed(1);

    }


    if(isNaN(percent)) percent = 0;

    return percent;

}





const defSliderInfo = {

    title               :"Slider(Ranger)",
    bandColor           :'#0000cc',
    bandOpacity         :"0.7",
    useStickyX          :true,
    stickyIncrement     :10,

    //stickyStops         :"default",// default or 30-50-70
    //trackDivider        :10 // 10 

};


const Slider = (props) => {


    // -- ref -- //
    const refBox = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [sliderInfo, setSliderInfo] = useState(defSliderInfo);
    const [readout,setReadout] = useState('');
    const [rangerValue, setRangerValue] = useState(0);
    const [colourWidth, setColourWidth] = useState("50%");
   


    useEffect(()=>{


        if(!isLoaded)
        {
            setIsLoaded(true);
            //setReadout("0%");
            //setReadoutB("0%");


            var val;
            val = 50;

            if(props.data && props.data !== undefined)
            {
                if(props.data.info && props.data.info !== undefined)
                {
                    setSliderInfo(props.data.info);

                }

                if(props.data.value    
                && props.data.value !== undefined
                && !isNaN(props.data.value)
                )
                {
                    val = props.data.value;
                }

            } 

            setRangerValue(val);
            setColourWidth(val+"%");
            setReadout(val+"%");


        }



    },[
        props,
        isLoaded
    ])




    const handleRanger = (hndl) => {

        
        /*
        console.log(""
        +"\nOUT FROM: "+hndl.from
        +"\nOUT VALUE: "+hndl.target.value
        +"\nIN ID: "+hndl.target.id
        );
        */
        

        var val;
        if(sliderInfo.useStickyX)
        {
            var increment;
            increment = 10;

            if(sliderInfo.stickyIncrement 
            || sliderInfo.stickyIncrement !== undefined) 
                increment = sliderInfo.stickyIncrement;

            val = roundPercent({

                call        : "default",
                percent     : hndl.target.value,
                precision   : increment
    
            });

        }
        else
        {

            val = hndl.target.value;

        }
        setRangerValue(val);
        setColourWidth(val+"%");
        setReadout(val+"%");


        handleCapture({

            value: val
    
        });


    }


    const handleCapture = (hndl) => {

        props.handle({

            call        :'capture',
            fieldCall   :"slider",
            key         :props.indx,
            data        :props.data,
            newValue    :hndl.value

        })

    }





    return (


        <SliderContainer>

            <SliderMessage>

                <div className="Title">

                    {sliderInfo.title}

                </div>
                <div className="Readout">

                    {readout}
                    
                </div>

            </SliderMessage>


            <SliderBox 
            ref={refBox} 
            id={"sliderA_box"}
            >

                <Ranger

                bandColourBgColor={sliderInfo.bandColor}       
                bandColourWidth={colourWidth}
                bandColourOpacity={sliderInfo.bandOpacity}

                >

                    <div 
                    className="RangeBand"
                    >
                        <div className="Bg">

                            <div className="Colour"></div>

                        </div>
              
                    </div>

                    <div 
                    className="RangeWrap"
                    >
                        <input
                            className="RangeInp" 
                            type="range"
                            id={props.data.id}
                            value={rangerValue}
                            onChange={event => {

                                handleRanger({

                                    target      :event.target,
                                    from        :'onChange'
                                
                                })

                            }}

                            /*
                            onInput={event => {

                                handleRanger({

                                    target      :event.target,
                                    from        :'onInput'

                                })

                            }}
                            */

                        />

                    </div>


                </Ranger>

            </SliderBox>

        </SliderContainer>




    );

};

export default Slider;