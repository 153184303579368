

export const ChartSamples = {


    pieChart:{

        backgroundColor:"transparent",
        border:"0",
        borderRadius:"0",
        width:"96%",
        margin:"15px auto 18px auto",

        drawCheckerBoard: false,
        chart:{
            call:'pie',
            data:{

                items: [ 

                    {
                        name:"Classical Music",
                        value:10
                    },
                    {
                        name:"Alternative Rock",
                        value:20
                    },    
                    {
                        name:"Pop",
                        value:30
                    },
                    {
                        name:"Jazz",
                        value:10
                    },
                    {
                        name:"Hard Rock",
                        value:16
                    },
                    {
                        name:"Heavy Metal",
                        value:14
                    }
        
                ],
        
                colors: [
                    "#fde23e",
                    "#f16e23", 
                    "#57d9ff",
                    "#937e88",
                    "#20b2aa",
                    "#ccff00"
                ],
                hole:{
                    color:"#181b21",
                    outlineColor:"#181b21",
                    textColor:"white"
                }
        
            }

        }


    },


    barChart:{


        backgroundColor:"transparent",
        border:"0",
        borderRadius:"0",
        width:"96%",
        margin:"15px auto 18px auto",

        drawCheckerBoard: false,
        chart:{
            call:'bar',
            data:{

                name: "Test Bar Graph",
                barPadding          :20,
                gridScaleMax        :10,
                gridScaleIncr       :10,
                gridColor           :"#bbb",
                
                items: [ 

                    {
                        name:"Classical Music",
                        value:10
                    },
                    {
                        name:"Alternative Rock",
                        value:20
                    },  
                    
                    
                    {
                        name:"Pop",
                        value:30
                    },
                    {
                        name:"Jazz",
                        value:10
                    },

                    
                    {
                        name:"Hard Rock",
                        value:16
                    },
                    {
                        name:"Heavy Metal",
                        value:14
                    }
                    

                ],
                colors: [
                    "#fde23e",
                    "#f16e23", 
                    "#57d9ff",
                    "#937e88",
                    "#20b2aa",
                    "#ccff00",
                    "pink",
                    "lavender",
                    "lightblue",
                    "lightgreen",
                    "blue",
                    "green",
                    "purple",
                    "red"
                ],
                

                keyStyle:{

                    backgroundColor :"white",
                    border          :"1px solid #999",
                    margin          :"0 0 0 0",
                    textColor       :"black"
        
                }


            
            }


        }

    },




    barChartDark:{


        backgroundColor:"#454d5f",
        border:"0",
        borderRadius:"0",
        width:"96%",
        margin:"15px auto 18px auto",

        drawCheckerBoard: false,
        chart:{
            call:'bar',
            data:{

                name: "Test Bar Graph",
                barPadding          :20,
                gridScaleMax        :30,
                gridScaleIncr       :10,
                gridColor           :"lightgray",
                
                items: [ 

                    {
                        name:"Classical Music",
                        value:10
                    },
                    {
                        name:"Alternative Rock",
                        value:20
                    },  
                    
                    
                    {
                        name:"Pop",
                        value:30
                    },
                    {
                        name:"Jazz",
                        value:10
                    },

                    
                    {
                        name:"Hard Rock",
                        value:16
                    },
                    {
                        name:"Heavy Metal",
                        value:14
                    }
                    

                ],
                colors: [
                    "#fde23e",
                    "#f16e23", 
                    "#57d9ff",
                    "#937e88",
                    "#20b2aa",
                    "#ccff00"
                ]
                

            
            }


        }

    }



}




/*
Original
data: {
                
    "Classical Music": 10,
    "Alternative Rock": 14,
    "Pop": 20,
    "Jazz": 12,
    "Hard Rock": 30,
    "LeftOver":14
},
*/
