import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


/*
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,  
    //useHistory,
    //useParams


} from "react-router-dom";

*/


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
import useDeviceDetect from 'utils/hooks/DeviceDetect';
import useScreenOrientation from 'utils/hooks/ScreenOrientation';


import {

    Band,
    Section

} from './Styled';


import FGrid from 'utils/components/Grid/FGrid';


//import { Fade } from 'utils/components/Transitions';


const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));

    /*

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    */


    // -- window dims -- //
    const { 
        width, 
        height
    } = useWindowDimensions();

    // -- device detect -- //
    const { isMobile } = useDeviceDetect();

    // -- screen orientation -- //
    const screenOrientation = useScreenOrientation()




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);
    // const refControls = useRef(null);



    //---------------------------------------------
    // state - fade variables
    //---------------------------------------------

    //const [fadeImage, setFadeImage] = useState(true);


    //---------------------------------------------
    // state/effect - currentGroup
    //---------------------------------------------
    const [isLoaded, setIsLoaded] = useState(false);


    //const [isWide, setIsWide] = useState(true);
    const [isLarge, setIsLarge] = useState(true);
    const [isMedium, setIsMedium] = useState(true);
    const [isSmall, setIsSmall] = useState(true);

    const [threshold] = useState(1200);
    const [thresh] = useState({small:701,medium:901});

    const [currentOrientation, setCurrentOrientation] = useState(false);
    const [currentGroup, setCurrentGroup] = useState({

        height:"auto",
        info:false,
        alfa:false,
        base:false,
        controller:false,
        images:[]

    });

    //const [currentParentWidth, setCurrentParentWidth] = useState(false);
 
    useEffect(() => {



        // -- effect -- //

        const effectCurrentGroup = (eff) => {

            setIsLoaded(true);

            const props = eff.props;
            //const isMobile = eff.isMobile;
            const screenOrientation = eff.screenOrientation;
            const info = props.data.info;
            const items = eff.items;
            //const currentGroup = eff.currentGroup;

            var revInfo;
            revInfo = JSON.parse(JSON.stringify(info));

            var revItems;
            revItems = JSON.parse(JSON.stringify(items));


            var small,medium,large;


            // pop - Removes from the End of an Array
            // shift - Removes from the beginning of an Array
            // splice - removes from a specific Array index
            // filter - allows you to programatically remove elements from an Array

            if(props.refid)
            {
                revInfo.parentWidth = document.getElementById(props.refid).offsetWidth;
                    

                if(document.getElementById(props.refid).offsetWidth < 700)
                {
                    //revItems = revItems.pop();
                    //array = [1,2,3,4,5,6,7,8].slice(0, -4)
                    //revItems = revItems.slice(0, -1);

                    //alert(JSON.stringify(revInfo,null,2));

                    revInfo.cells.call = 'band';
                    revInfo.cells.total = revItems.length;
                    revInfo.cells.across = "2";

                    small = true;
                    medium = false;
                    large = false; 

                }
         
                else
                if(document.getElementById(props.refid).offsetWidth < 900)
                {
                    //revItems = revItems.pop();
                    //array = [1,2,3,4,5,6,7,8].slice(0, -4)
                    //revItems = revItems.slice(0, -1);

                    //alert(JSON.stringify(revInfo,null,2));

                    revInfo.cells.call = 'band';
                    revInfo.cells.total = "4";
                    revInfo.cells.across = "4";

                    small = false;
                    medium = true;
                    large = false;
                    
                }

                else
                {
                    revInfo.cells.call = 'band';
                    // revInfo.cells.total = revInfo.cells.total;
                    // revInfo.cells.across = revInfo.cells.across;

                    small = false;
                    medium = false;
                    large = true;
                    
                }
          
            }




            // let images = [];       
            // let imgW,imgH,imgPerc,imgChoice;
            
            // let itemA = items[0];
            // let itemB = items[1];

            // if(itemA.image && itemA.image !== undefined)
            // {
            //     if(itemA.image.w && itemA.image.w !== undefined)
            //     {
            //         imgW = parseInt(itemA.image.w);
            //         imgH = parseInt(itemA.image.h);
            //         imgPerc = (imgH/imgW) * 100;
            //         if(imgPerc < 50)
            //         {
            //             imgChoice={
            //                 wide:itemA,
            //                 mobi:itemB
            //             }

            //         }else{

            //             imgChoice={
            //                 wide:itemB,
            //                 mobi:itemA
            //             }
            //         } 
            //     }
            // }


            var bandHeight = "350px";
            // switch(screenOrientation)
            // {
            // case'PORTRAIT':

            //     images.push(imgChoice.mobi);
            //     bandHeight = images[0].image.height + "px";
            // break;
            // default:
            //     images.push(imgChoice.wide);
            //     bandHeight = "550px";
            // }


            // if(!eff.wide)
            // {
            //     images.push(imgChoice.mobi);
            //     bandHeight = images[0].image.height + "px";
            // }else{
            //     images.push(imgChoice.wide);
            //     bandHeight = "300px";
            // }



            //setIsWide(eff.wide);
            setIsLarge(large);
            setIsMedium(medium);
            setIsSmall(small);

            setCurrentOrientation(screenOrientation);
            setCurrentGroup({

                height:bandHeight,
                info:revInfo,
                items:revItems,
                alfa:false,
                base:false,
                controller:false

            });


        };





        // -- cause -- //

        //var wide;
        var cause = false;
        if(!isLoaded) cause = true;
        if(currentOrientation !== screenOrientation) cause = true;

        // if(props.refid)
        // {
        //     var parentWidth = document.getElementById(props.refid).offsetWidth;
        //     if(parentWidth < 701 && currentParentWidth !== "small")
        //     {
        //         cause = true;
        //     }
        //     else
        //     if(parentWidth < 901 && currentParentWidth !== "medium")
        //     {
        //         cause = true;
        //     }
        //     else
        //     if(parentWidth > 899 && currentParentWidth !== "large")
        //     {
        //         cause = true;
        //     }
        // }



        // if(width < threshold && isWide) 
        // {
        //     cause = true;
        //     wide = false;
        // }
        // if(width > threshold && !isWide) 
        // {
        //     cause = true;
        //     wide = true;
        // }


        if(props.refid)
        {
            var parentWidth = document.getElementById(props.refid).offsetWidth;

            if(parentWidth < thresh.small) 
            {
                //wide = false;
                if(!isSmall) cause = true;
            }
            else
            if(parentWidth < thresh.medium) 
            {
                //wide = false;
                if(!isMedium) cause = true;  
            }
            else
            if(parentWidth > threshold) 
            {
                if(!isLarge) cause = true;
            }

        }



        if(cause)
        {
            //console.log('cause - effect');
            effectCurrentGroup({
        
                props:props,
                width:width,
                threshold:threshold,
                //wide:wide,
                isMobile:isMobile,
                screenOrientation:screenOrientation,
                items:props.data.items,
                currentGroup:currentGroup

            });

        }

        //console.log("WideScreen width: "+width+" "+threshold);
        //console.log("currentGroup: "+JSON.stringify(currentGroup,null,2));


    },
    [

        props, 
        width,height,  
        isMobile,
        screenOrientation, 
        isLoaded,
        //isWide,
        isLarge,isMedium,isSmall,
        threshold,thresh,
        currentOrientation,
        currentGroup,
        refA
 
    ])









    //---------------------------------------------
    // handle - general
    //---------------------------------------------

    // const handle = (hndl) => {


    //     // var obj;
    //     // var num;

    //     //alert(JSON.stringify(hndl));
    //     switch(hndl.call)
    //     {
    //     case'size':




    //         //  obj = boardInfo;
    //         // if(currentGroup.info.view === 'wideScreen') 
    //         // {
    //         //     //alert(hndl.size.height.toString() + " --- " +currentGroup.info.view);
    //         //     //num = hndl.size.height.toString();
    //         //     num = currentGroup.info.viewHeight.toString();
    //         //     if(num.match(/px/gi))
    //         //     {
    //         //         num = num.replace(/px/gi,"");  
    //         //     }
    //         //     //console.log("wideScreen Number: "+num);

    //         //     obj.viewHeight = num+"px";
    //         //     setBoardInfo(obj);
    //         // }
    //         // else
    //         // if(currentGroup.info.view === 'fullScreen') 
    //         // {
    //         //     if(refA.current.offsetWidth - 200 < refA.current.offsetHeight)
    //         //     {
    //         //         obj.viewHeight = "auto";
    //         //         setBoardInfo(obj);
    //         //     }
    //         // }
    //         // else
    //         // {
    //         //     //alert(hndl.size.height.toString() + " --- " +currentGroup.info.view);
    //         //     num = hndl.size.height.toString();
    //         //     if(num.match(/px/gi))
    //         //     {
    //         //         num = num.replace(/px/gi,"");  
    //         //     }
    //         //     //console.log("size number: "+num);

    //         //     obj.viewHeight = num+"px";
    //         //     setBoardInfo(obj);

    //         // }
        

    //     break;
    //     case'progressChange':

    //         // num = boardNumber + 1;
    //         // if(num === boards.length) num = 0;

    //         // //alert(hndl.call+" "+num);
    //         // setBoardNumber(num);

    //     break;
    //     case'clickChange':

    //         // setBoardNumber(hndl.number);
    //     break;
    //     default:
    //     }

    // }












    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

    
            <Band
            ref={refA}          
            //height={boardInfo.viewHeight}
            //height={currentGroup.height}
            height={"auto"}
            >
                <Section 
                >
                </Section>

            </Band>

        )

    } 
    else 
    {



        return (


            <Band
            ref={refA}
            //backgroundColor={'red'}
            //height={currentGroup.height}
            height={"auto"}
            >

                {
                currentGroup.items.length > 0
                ?

                    <Section 
                    >
                        {

                        <FGrid

                            handle      = {(v) => props.handle(v)} 
                            info        = {currentGroup.info}          
                            items       = {currentGroup.items}
                            refid       = {props.refid}
                            
                        />

                        }

                    </Section>


                :null
                }


            </Band>

                   
        )


    }


}

export default Index;







/*

{
    "styl": {
      "call": "full",
      "class": "FullRow",
      "marginTop": "0px",
      "marginBottom": "0px"
    },
    "cover": {
      "alfa": {
        "backgroundColor": "black",
        "percent": "100",
        "decimal": "1.0",
        "zIndex": "7",
        "width": "70%",
        "height": "400px",
        "left": "15%",
        "top": "calc(50% - 200px)"
      },
      "base": {
        "backgroundColor": "yellow",
        "border": "1px solid red",
        "width": "70%",
        "height": "400px",
        "zIndex": "8",
        "left": "15%",
        "top": "calc(50% - 200px)"
      }
    },
    "call": "Band",
    "info": {
      "call": "CollageGrid",
      "gridType": "Fixed",
      "gridBgColor": "transparent",
      "gridCall": "grid_cell",
      "cellCall": "container",
      "modCall": "fill_div",
      "cells": {
        "call": "default",
        "total": "4",
        "across": "4"
      },
      "thumb": {
        "call": "default",
        "diff": "0",
        "bgColor": "lightgray"
      },
      "cellBorder": {
        "boolean": "0",
        "thickness": "0",
        "color": "transparent"
      },
      "gutter": {
        "boolean": "0",
        "size": "0"
      },
      "thresh": {
        "refWidth": false,
        "across1": "501",
        "across2": "801",
        "across3": "1051"
      },
      "mask": {
        "boolean": "0",
        "message": {
          "boolean": "0",
          "backgroundColor": "transparent",
          "textColor": "black"
        }
      },
      "opacity": "1.0",
      "webkitFilter": "grayscale(0)",
      "filter": "grayscale(0)"
    },
    "items": [
        {
          "id": "20210407087118",
          "title": "sp-18000-ap1-040_01",
          "text": "",
          "image": {
            "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_01_sys20210407087118id_.jpg",
            "w": "1024",
            "h": "683"
          }
        },
        {
          "id": "20210407088066",
          "title": "sp-18000-ap1-040_02",
          "text": "",
          "image": {
            "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/sp-18000-ap1-040_02_sys20210407088066id_.jpg",
            "w": "1024",
            "h": "683"
          }
        },
        {
          "id": "20210407064008",
          "title": "spoon1",
          "text": "",
          "image": {
            "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon1_sys20210407064008id_.jpg",
            "w": "1600",
            "h": "900"
          }
        },
        {
          "id": "20210407065570",
          "title": "spoon2",
          "text": "",
          "image": {
            "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/Spoon/spoon2_sys20210407065570id_.jpg",
            "w": "1600",
            "h": "900"
          }
        },
    ],
    "recid": "20211027121543",
    "reckey": "rec-blogsegment-entry-20211027121543",
    "name": "Image--Collage_sys20211027121543id_.json"
},


*/