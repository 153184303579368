export const BUTTON_LOGO_MICHELIN = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.839,223.833l-20.12,30.429c0,0,1.515-15.975,1.515-19.776
		c0.38-3.047,0-10.652-7.216-10.652c-6.834,0-31.512,0-31.512,0L72,280.133h24.682l14.807-40.701l-3.033,40.701h22.021
		l26.955-39.944l-14.808,39.944h24.682l20.887-56.3H149.839L149.839,223.833z M284.252,278.991
		c-1.52,0.378-10.63,2.282-20.882,2.282c-17.468,0.765-33.038-6.471-33.038-19.017c-0.375-22.442,22.026-34.622,38.729-37.66
		c7.218-1.525,21.646-3.427,35.696,0l-8.36,19.779c-2.272-5.326-8.35-5.326-11.007-5.326c-5.694-0.38-11.01,1.522-14.429,3.041
		c-8.354,3.804-15.564,15.978-3.8,20.929c6.46,2.653,14.811,0.379,23.545-0.763L284.252,278.991L284.252,278.991z M537.135,280.133
		h-26.962l20.499-56.3h27.347L537.135,280.133L537.135,280.133z M210.209,280.133h-26.955l20.502-56.3h27.343L210.209,280.133
		L210.209,280.133z M357.154,223.833l-7.22,20.161h-14.044l7.596-20.161h-25.818l-20.887,56.3h25.815l7.218-19.018h13.672
		l-6.839,19.018h25.827l20.876-56.3H357.154L357.154,223.833z M482.079,261.484l13.657-37.651h-26.575l-20.499,56.3h53.151
		l6.832-18.648H482.079L482.079,261.484z M625.22,223.833l-14.43,38.801c0.378-3.802,1.142-19.396,1.52-28.148
		c0.378-3.805-0.378-6.468-2.274-8.37c-2.283-2.282-4.943-2.282-4.943-2.282h-31.511l-20.884,56.3h23.158l14.428-39.944
		l-1.518,39.944h38.359l20.875-56.3H625.22L625.22,223.833z M409.17,264.916l2.275-6.084h29.621l4.936-14.076h-29.613l2.275-6.086
		h31.141l5.306-14.837h-56.574l-20.876,56.3h57.716l5.304-15.217H409.17L409.17,264.916z"
    />
    





    

		</svg>
		</div>

	);//return
}