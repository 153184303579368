export const BUTTON_TIRE_PIRELLI_MEDIUM_WHITE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Black*/}

    <polygon 
        fill="rgb(0,0,0)" 
        points="108,90 612,90 612,414 108,414 108,90 	"
    />
{/*Brackets*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M344.358,345.169c-44.636-7.491-78.834-46.46-78.834-93.169 c0-46.708,34.198-85.679,78.834-93.17v-22.777C287.267,143.738,243,192.861,243,252s44.267,108.262,101.358,115.947V345.169 L344.358,345.169z M375.642,158.83c44.636,7.491,78.834,46.462,78.834,93.17c0,46.709-34.198,85.678-78.834,93.169v22.778 C432.732,360.262,477,311.139,477,252s-44.268-108.262-101.358-115.947V158.83L375.642,158.83z"
    />  
{/*Letter*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M290.885,296.997l6.016-68.604 c0.854-10.239,3.647-17.62,8.384-22.143c4.735-4.522,10.729-6.784,17.982-6.784c7.765,0,13.93,2.369,18.495,7.104 c4.565,4.735,7.488,11.198,8.768,19.391l7.551,47.74c0.171,1.024,0.448,1.792,0.832,2.304c0.384,0.513,1.003,0.769,1.856,0.769 c0.853,0,1.493-0.299,1.92-0.896c0.427-0.598,0.682-1.408,0.768-2.433l7.296-47.612c1.193-8.106,4.074-14.527,8.639-19.263 c4.565-4.735,10.688-7.104,18.367-7.104c8.105,0,14.143,2.283,18.11,6.848c3.968,4.565,6.378,11.925,7.231,22.079l6.016,68.604	h-24.702l-5.12-72.315c-0.085-0.854-0.234-1.578-0.447-2.176c-0.214-0.598-0.747-0.896-1.6-0.896c-0.684,0-1.238,0.277-1.664,0.832 c-0.427,0.555-0.683,1.217-0.769,1.984l-8.575,51.324c-1.109,6.826-3.861,12.33-8.255,16.511 c-4.395,4.182-10.176,6.272-17.344,6.272c-7.423,0-13.354-2.155-17.79-6.464c-4.438-4.31-7.21-9.792-8.32-16.447l-8.959-51.068	c-0.17-0.938-0.469-1.663-0.896-2.176c-0.427-0.512-0.981-0.769-1.665-0.769c-0.853,0-1.429,0.299-1.728,0.896 c-0.298,0.598-0.49,1.365-0.576,2.304l-5.12,72.188H290.885L290.885,296.997z"
    />




    

		</svg>
		</div>

	);//return
}