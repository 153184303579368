
import React, { 

    useState, 
    useEffect, 
    useRef,
    //useMemo

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";
  

//import { useLiquidNavBar } from 'utils/myHooks/LiquidFormula/NavBar';
import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


//import logo from 'grfx/logo.svg';
//import { fontSize } from 'pdfkit/js/mixins/fonts';


import 'App.css';
//import './Styl.css';
import { 
    Header, 
    HeaderMain, 
    HeaderLogo,
    //HeaderTitle,
    //HeaderArrowButton,

    Hamburger

} from './StylAttrs';





const Index = (props) => {


    const primeHandle = props.databind.primeProps.handle;


    //.log("HEADER PROPS zoneObj:" + props.zoneObj);

    const { width } = useWindowDimensions()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    //let zoneId;
    //zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
  
  


    // -- ref -- //
    const refMain = useRef(null);
    const refLogo = useRef(null);
    const refBurger = useRef(null);
    const refBun = useRef(null);



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false)  
    const [winThresh, setWinThresh] = useState(10000)
    const [thresh] = useState({

        a: 700,
        b: 500,
        c: 350

    });

    const [headerGrfx, setHeaderGrfx] = useState(false);
    const [headerStyl, setHeaderStyl] = useState({

        height:false

    });
    const [logoStyl, setLogoStyl] = useState({

        margin:false

    });
    const [hamburgerStyl, setHamburgerStyl] = useState({

        margin:false

    });




    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            var newThresh;

            if(width < thresh.c
            )
            {
                newThresh = thresh.c;
            }
            else
            if(width < thresh.b
            && width > thresh.c
            )
            {
                newThresh = thresh.b;
            }
            else
            if(width < thresh.a
            && width > thresh.b
            )
            {
            
                newThresh = thresh.a
            }
            else
            if(width > thresh.a)
            {
                newThresh = 10000;
            }



            if(winThresh !== newThresh)
            {

                //alert(newThresh);
                setWinThresh(newThresh);
            
            }


            // props.handle({

            //     call:'dispatch',
            //     data:{

            //         action:'UPDATE_ZONE',
            //         key:'headerHInt',
            //         value:200

            //     }

            // });



        }



        //---------------------------------------
        // setHeaderGrfx
        //---------------------------------------

        if(!headerGrfx)
        {
            if(zoneObj.settings 
            && zoneObj.settings !== undefined)
            {


                var headerHInt;
                if(zoneObj.settings.appearance.header)
                {
                    headerHInt = zoneObj.settings.appearance.header.headerMainHeight;
                    if(headerHInt.match(/px/gi))
                    {
                        headerHInt = headerHInt.replace(/px/gi,"");
                    }
                    //headerHInt = headerHInt + 20;
                    setHeaderStyl({

                        height:  headerHInt + "px"

                    });

                }


                if(zoneObj.settings.graphic.header)
                {

                    setHeaderGrfx({

                        logo:{

                            src:zoneObj.settings.graphic.header.image.src,
                            w:zoneObj.settings.graphic.header.image.w,
                            h:zoneObj.settings.graphic.header.image.h
                        }

                    });

                    var logoTop = ( refMain.current.offsetHeight - parseInt(zoneObj.settings.graphic.header.image.h) )/2;
                    setLogoStyl({

                        margin: logoTop + "px 0 0 15px"

                    });

                    var burgerTop = (refMain.current.offsetHeight - refBurger.current.offsetHeight)/2;
                    //var burgerTop = (refMain.current.offsetHeight - refBurger.current.offsetHeight)/2 - refBun.current.offsetTop;
                    setHamburgerStyl({

                        margin: burgerTop + "px 15px 0 0"

                    });

                }

            }

        }




    },[
        

        props,
        zoneObj,
        width,
        isLoaded,
        winThresh,
        thresh,

        refMain,
        refLogo,
        refBurger,
        refBun,

        headerGrfx



    ])

    






    if(!isLoaded)
    {


        return (


            <Header>

                <HeaderMain
                ref={refMain}
                height={headerStyl.height}
                >
  
                    <Link 
                    to={"/"}
                    >
                        {
                        headerGrfx
                        ?

                            <HeaderLogo
                            ref={refLogo}
                            width={headerGrfx.logo.w + "px"}
                            height={headerGrfx.logo.h + "px"}
                            margin={logoStyl.margin}
                            >

                                <div>

                                    <img 
                                        src={headerGrfx.logo.src}
                                        w={headerGrfx.logo.w} 
                                        h={headerGrfx.logo.h}
                                        alt="logo" 
                                        // style={{
                                        //     width:headerGrfx.logo.w + "px",
                                        //     height:headerGrfx.logo.h + "px"
                                        // }} 
                                            
                                    />

                                </div>

                            </HeaderLogo>

                        :
                    
                            <HeaderLogo
                            ref={refLogo}
                            >
                            </HeaderLogo>
                        }
                        

                    </Link>



                    <Hamburger
                    ref={refBurger}
                    >

                        <div ref={refBun} className='bun'></div>
                        <div className='bun'></div>
                        <div className='bun'></div>

                    </Hamburger>

                </HeaderMain>


            </Header>


        );




    }else{



        return (


            <Header>

                <HeaderMain
                ref={refMain}
                height={headerStyl.height}
                >

                    <Link 
                    to={""}
                    >
                        {
                        headerGrfx
                        ?

                            <HeaderLogo
                            ref={refLogo}
                            width={headerGrfx.logo.w + "px"}
                            height={headerGrfx.logo.h + "px"}
                            margin={logoStyl.margin}
                            >

                                <div>

                                    <img 
                                        src={headerGrfx.logo.src}
                                        w={headerGrfx.logo.w} 
                                        h={headerGrfx.logo.h}
                                        alt="logo" 
                                        // style={{
                                        //     width:headerGrfx.logo.w + "px",
                                        //     height:headerGrfx.logo.h + "px"
                                        // }} 
                                            
                                    />

                                </div>

                            </HeaderLogo>

                        :
                    
                            <HeaderLogo
                            ref={refLogo}
                            >
                            </HeaderLogo>
                        }
                        

                    </Link>


                    <Hamburger
                    ref={refBurger}
        
                        margin={hamburgerStyl.margin}
                        onClick={() => primeHandle({

                            call:"showMenu",
                            data:[]

                        })}

                    >

                        <div ref={refBun} className='bun'></div>
                        <div className='bun'></div>
                        <div className='bun'></div>

                    </Hamburger>
        
                </HeaderMain>


            </Header>



        );


    }






}

export default Index;



