

const VPoverhead = {


    //---------------------
    //refer
    //---------------------

    "settings":{


        "name":"Settings",
        "value":{


            "billable_hours_percentage" : {
            
                "print"		:'price',
                "name"		:'Billable Hours Percentage',
                "value"		:75,
                "required"	:true,
                "default"	:75
            },


            "projected_monthly_sales" : {

                "print"		:'price',
                "name"		:'Projected Monthly Sales',
                "value"		:50000,
                "required"	:true,
                "default"	:50000

            }


        }


    },



    //---------------------
    //work_schedule
    //---------------------

    "work_schedule":{

        "name":"Work Schedule",
        "value":{

            "work_weeks" : {

                "print"		:'standard',
                "name"		:'Weeks Per Year',
                "value"		:52,
                "required"	:true,
                "default"	:52

            },  		
            
            "work_days" : {
    
                "print"		:'standard',
                "name"		:'Days Per Week',
                "value"		:5,
                "required"	:true,
                "default"	:5

            },  
            "work_hours" : {
    
                "print"		:'standard',
                "name"		:'Hours Per Day',
                "value"		:8,
                "required"	:true,
                "default"	:8
            }


        }


    },



    //------------------------
    //FACILITY INPUTS
    //------------------------

    "facility":{

        "name":"Facility",
        "value":{

            "fac_rent_mortgage" : {

                "print"		:'price',
                "name"		:'Rent/Mortgage',
                "value"		:3000,
                "required"	:true,
                "default"	:3000
            },  
            "fac_utilities" : {
    
                "print"		:'price',
                "name"		:'Utilities',
                "value"		:300,
                "required"	:true,
                "default"	:300
            },  
            "fac_phone_internet" : {
    
                "print"		:'price',
                "name"		:'Phone/Internet',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
            "fac_misc" : {
    
                "print"		:'price',
                "name"		:'Misc.',
                "value"		:0,
                "required"	:true,
                "default"	:0
            },  

        }

    },



    //------------------------
    //BUSINESS INPUTS
    //------------------------

    "business":{

        "name":"Business",
        "value":{


            "biz_insurance" : {

                "print"		:'price',
                "name"		:'Insurance',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "biz_accounting" : {
    
                "print"		:'price',
                "name"		:'Accounting',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
        
            "biz_legal" : {
    
                "print"		:'price',
                "name"		:'Legal',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
            "biz_licenses" : {
    
                "print"		:'price',
                "name"		:'Licenses',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
            "biz_loans" : {
    
                "print"		:'price',
                "name"		:'Loans',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
            "biz_advertising" : {
    
                "print"		:'price',
                "name"		:'Advertising',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
            "biz_website" : {
    
                "print"		:'price',
                "name"		:'Website',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  		
            
            "biz_misc" : {
    
                "print"		:'price',
                "name"		:'Misc.',
                "value"		:0,
                "required"	:true,
                "default"	:0
            },  	
            
        }

    },






    //--------------------------
    //VEHICLES INPUTS
    //--------------------------

    "vehicles":{

        "name":"Vehicles",
        "value":{


            "veh_vehicles" : {

                "print"		:'price',
                "name"		:'Vehicles',
                "value"		:500,
                "required"	:true,
                "default"	:500
            },  
    
            "veh_gas" : {
    
                "print"		:'price',
                "name"		:'Gas.',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "veh_insurance" : {
    
                "print"		:'price',
                "name"		:'Insurance',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
    
            "veh_maintenance" : {
    
                "print"		:'price',
                "name"		:'Maintenance',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "veh_misc" : {
    
                "print"		:'price',
                "name"		:'Misc.',
                "value"		:0,
                "required"	:true,
                "default"	:0
            },  

        }
            
    },

    //---------------------------
    //EQUIPMENT / SOFTWARE
    //---------------------------

    "equipment_software":{

        "name":"Equip. / Software",
        "value":{


            "es_digital_printer" : {

                "print"		:'price',
                "name"		:'Digital Printer',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "es_laminator" : {
    
                "print"		:'price',
                "name"		:'Laminator',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "es_plotter" : {
    
                "print"		:'price',
                "name"		:'Plotter',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "es_computers" : {
    
                "print"		:'price',
                "name"		:'Computers',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
            "es_software_subscriptions" : {
    
                "print"		:'price',
                "name"		:'Software Subscriptions.',
                "value"		:100,
                "required"	:true,
                "default"	:100
            },  
    
    
            "es_misc" : {
    
                "print"		:'price',
                "name"		:'Misc.',
                "value"		:0,
                "required"	:true,
                "default"	:0
            },   
            
        }
        
    }









};




export {

    VPoverhead

}