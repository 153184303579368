//import styled from "styled-components";
import styled from "styled-components/macro";


const Board = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightgray",
    width:              props.width || "100%",
    height:             props.height || "500px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    min-height:100px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;


`;


const Sky = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "500px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top || "0px",
    right:              props.right || "0px",
    zIndex:             props.zIndex  || "50",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    right:${props => props.right}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;





const Controls = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "200px",
    height:             props.height || "75px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    top:                props.top || "0px",
    right:              props.right || "0px",
    zIndex:             props.zIndex  || "3",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    right:${props => props.right}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;


const Button = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#454d5f",
    borderRadius:       props.borderRadius || "50px",

    width:              props.width || "50px",
    height:             props.height || "50px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",



}))`

    background-color:${props => props.backgroundColor};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;

    cursor:pointer;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};




    .Text{

        background-color:transparent;
        width:100%;
        height:auto;

        padding:0 0 4px 0;
        margin:16px 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


    }



`;









const ControlPanel = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightseagreen",

    width:              props.width || "300px",
    height:             props.height || "100%",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    top:                props.top || "0px",
    right:              props.right || "-1000px",
    zIndex:             props.zIndex  || "5",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    right:${props => props.right}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;


const ControlPanelButton = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#454d5f",
    borderRadius:       props.borderRadius || "1px",

    width:              props.width || "60px",
    height:             props.height || "60px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "7px 0 0 7px",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",



}))`

    background-color:${props => props.backgroundColor};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;

    cursor:pointer;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};




    .Text{

        background-color:transparent;
        width:100%;
        height:auto;

        padding:0 0 4px 0;
        margin:16px 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


    }



`;



const Container = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "90%",
    height:             props.height || "500px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    bottom:             props.bottom || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "300",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",


    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.60",


    reflectHeight:     props.reflectHeight || "180px",


    //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Images/Using_CSS_gradients

  
}))`
  
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


    .ReflectOverlay{

        background-color:#000015;

        width:${props => props.width};
        height:${props => props.height};

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:absolute;   
        bottom:${props => props.bottom}; 
        left:${props => props.left}; 
        z-index:301; 

        overflow:hidden;



        -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
        filter : alpha(opacity=${props => props.percent});
        -moz-opacity : ${props => props.decimal};
        -khtml-opacity : ${props => props.decimal};
        opacity : ${props => props.decimal};



    }


    .ReflectGradient{

        background-image:linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1));

        width:${props => props.width};
        height:${props => props.reflectHeight};

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:absolute;   
        bottom:${props => props.bottom}; 
        left:${props => props.left}; 
        z-index:300; 

        overflow:hidden;

    }



`;




const MainContainer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "90%",
    height:             props.height || "500px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    bottom:             props.bottom || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "300",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",


    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.60",


    reflectHeight:     props.reflectHeight || "180px",


    //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Images/Using_CSS_gradients

  
}))`
  
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};



`;


const ReflectContainer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "90%",
    height:             props.height || "500px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top || "0px",
    bottom:             props.bottom || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "300",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",


    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.60",


    reflectHeight:     props.reflectHeight || "180px",


    //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Images/Using_CSS_gradients

  
}))`
  
    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;   
    top:${props => props.top}; 
    left:${props => props.left}; 
    z-index:3; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


    .ReflectOverlay{

        background-color:#000015;

        width:${props => props.width};
        height:${props => props.height};

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:absolute;   
        bottom:${props => props.bottom}; 
        left:${props => props.left}; 
        z-index:301; 

        overflow:hidden;



        -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
        filter : alpha(opacity=${props => props.percent});
        -moz-opacity : ${props => props.decimal};
        -khtml-opacity : ${props => props.decimal};
        opacity : ${props => props.decimal};



    }


    .ReflectGradient{

        background-image:linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1));

        width:${props => props.width};
        height:${props => props.reflectHeight};

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:absolute;   
        bottom:${props => props.bottom}; 
        left:${props => props.left}; 
        z-index:300; 

        overflow:hidden;

    }



`;










const Layer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "90%",
    height:             props.height || "500px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "1",


  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    left:${props => props.left}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;


`;



const Slide = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "90%",
    height:             props.height || "504px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow:hidden;

`;


const SlideReflect = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "90%",
    height:             props.height || "500px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    percent:           props.percent || "85", 
    decimal:           props.decimal || "0.85",

    // percent:           props.percent || "100", 
    // decimal:           props.decimal || "1.0",


}))`
  

    background-color:${props => props.backgroundColor};
  
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow:hidden;


    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;




export {

    Sky,
    Board,
    ControlPanel,
    ControlPanelButton,
    Controls,
    Button,
    Container,

    MainContainer,
    ReflectContainer,

    Layer,
    Slide,
    SlideReflect

}
  


