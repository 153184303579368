


const SceneInfo = [





    //##########################################################
    //##########################################################
    //##########################################################

    // ImageWrapText TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px"
        },
        "call": "TxtComponent",
        "info": {
            "call": "flex",
            "gridType": "ImageWrapText",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": [
            {  
            "image": {

                "align":"left",
                "maxWidth":"400",
                "maxHeight":"400",
                "relief": "20",
         
                "h": "360",
                "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                "w": "640"
     
            }, 
            "heading": "Fetch Page",
            "pgraph": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            }
        ]

    }





];


export default SceneInfo;






/*

{
    "call": "collage",
    "gridType": "Fixed",
    "gridBgColor": "transparent",
    "gridCall": "grid_cell",
    "cellCall": "container",
    "modCall": "fill_div",
    "cells": {
      "call": "default",
      "total": 1,
      "across": 1
    },
    "thumb": {
      "call": "default",
      "diff": "0",
      "bgColor": "black"
    },
    "cellBorder": {
      "boolean": false,
      "thickness": 0,
      "color": "lightgray"
    },
    "gutter": {
      "boolean": "0",
      "size": "0"
    },
    "thresh": {
      "refWidth": false,
      "across1": "501",
      "across2": "801",
      "across3": "1051"
    },
    "mask": {
      "boolean": false,
      "message": {
        "boolean": false,
        "backgroundColor": "#4CAF50",
        "textColor": "white"
      }
    },
    "opacity": 1,
    "webkitFilter": "grayscale(0%)",
    "filter": "grayscale(0%)",
    "view": "fullScreen",
    "viewHeight": "613px",
    "thumbHInt": 723
}


*/

