import React, { 

    //useState, 
    //useEffect,
    //useRef

} from 'react';


import '../../StyleSheets/dynamic-form.css';


const FFheader = (props) => {

    return (

        <div
        key={props.indx+"_ffheader"} 
        className = "header"
        >

            {props.data.text}
            
        </div>

    )

}


const FFheadline = (props) => {

    return (

        <div 
        key={props.indx+"_ffheadline"} 
        className = "headline"
        >

            {props.data.text}
            
        </div>

    )

}


const FFbar = (props) => {

    return (

        <div 
        key={props.indx+"_ffbar"} 
        className = "bar"
        >

            {props.data.text}
            
        </div>

    )

}


const FFtitleline = (props) => {

    return (

        <div 
        key={props.indx+"_fftitleline"} 
        className = "titleline"
        >

            {props.data.text}
            
        </div>

    )

}



const FFspacer = (props) => {

    return (

        <div 
        key={props.indx+"_ffspacer"} 
        className = "spacer"
        style={{

            //backgroundColor     :"yellow",
            width               :"100%",
            height              :props.data.height,
            margin              :"0 0 0 0"

        }}

        >

            {props.data.text}
            
        </div>

    )

}




export {

    FFheader,
    FFspacer,  
    FFheadline,   
    FFtitleline,
    FFbar  

}

