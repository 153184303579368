import {BANNER_BLUE_BLUE} from "./SVGS/BANNER_BLUE_BLUE";
import {BANNER_BLUE_DARK_BLUE} from "./SVGS/BANNER_BLUE_DARK_BLUE";
import {BANNER_GRAY_BLACK} from "./SVGS/BANNER_GRAY_BLACK";
import {BANNER_GRAY_SILVER} from "./SVGS/BANNER_GRAY_SILVER";
import {BANNER_GREEN_LIME_GREEN} from "./SVGS/BANNER_GREEN_LIME_GREEN";
import {BANNER_ORANGE_ORANGE} from "./SVGS/BANNER_ORANGE_ORANGE";
import {BANNER_PURPLE_PURPLE} from "./SVGS/BANNER_PURPLE_PURPLE";
import {BANNER_RED_RED} from "./SVGS/BANNER_RED_RED";
import {BANNER_YELLOW_YELLOW} from "./SVGS/BANNER_YELLOW_YELLOW";
import {BODY_SOLID_BLUE_APEX_BLUE} from "./SVGS/BODY_SOLID_BLUE_APEX_BLUE";
import {BODY_SOLID_BLUE_CR_APEX_BLUE} from "./SVGS/BODY_SOLID_BLUE_CR_APEX_BLUE";
import {BODY_SOLID_BLUE_GULF_BLUE} from "./SVGS/BODY_SOLID_BLUE_GULF_BLUE";
import {BODY_SOLID_BLUE_LAGUNA_BLUE} from "./SVGS/BODY_SOLID_BLUE_LAGUNA_BLUE";
import {BODY_SOLID_BLUE_MONTE_CARLO_BLUE} from "./SVGS/BODY_SOLID_BLUE_MONTE_CARLO_BLUE";
import {BODY_SOLID_BLUE_ROYAL_NAVY} from "./SVGS/BODY_SOLID_BLUE_ROYAL_NAVY";
import {BODY_SOLID_BLUE_SUZUKA_BLUE} from "./SVGS/BODY_SOLID_BLUE_SUZUKA_BLUE";
import {BODY_SOLID_GRAY_BERLINA_BLACK} from "./SVGS/BODY_SOLID_GRAY_BERLINA_BLACK";
import {BODY_SOLID_GRAY_CR_BERLINA_BLACK} from "./SVGS/BODY_SOLID_GRAY_CR_BERLINA_BLACK";
import {BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL} from "./SVGS/BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL";
import {BODY_SOLID_GRAY_GRAND_PRIX_WHITE} from "./SVGS/BODY_SOLID_GRAY_GRAND_PRIX_WHITE";
import {BODY_SOLID_GRAY_GRAND_PRIX_WHITE_HL} from "./SVGS/BODY_SOLID_GRAY_GRAND_PRIX_WHITE_HL";
import {BODY_SOLID_GRAY_MOONROCK} from "./SVGS/BODY_SOLID_GRAY_MOONROCK";
import {BODY_SOLID_GRAY_SEBRING_SILVER} from "./SVGS/BODY_SOLID_GRAY_SEBRING_SILVER";
import {BODY_SOLID_GRAY_SILVERSTONE} from "./SVGS/BODY_SOLID_GRAY_SILVERSTONE";
import {BODY_SOLID_GRAY_SUNSET_MAUVE} from "./SVGS/BODY_SOLID_GRAY_SUNSET_MAUVE";
import {BODY_SOLID_GREEN_LIME_GREEN} from "./SVGS/BODY_SOLID_GREEN_LIME_GREEN";
import {BODY_SOLID_GREEN_TAHITIAN_GREEN} from "./SVGS/BODY_SOLID_GREEN_TAHITIAN_GREEN";
import {BODY_SOLID_ORANGE_IMOLA_ORANGE} from "./SVGS/BODY_SOLID_ORANGE_IMOLA_ORANGE";
import {BODY_SOLID_RED_NEW_FORMULA_RED} from "./SVGS/BODY_SOLID_RED_NEW_FORMULA_RED";
import {BODY_SOLID_YELLOW_CR_RIO_YELLOW} from "./SVGS/BODY_SOLID_YELLOW_CR_RIO_YELLOW";
import {BODY_SOLID_YELLOW_RIO_YELLOW} from "./SVGS/BODY_SOLID_YELLOW_RIO_YELLOW";
import {BODY_SOLID_YELLOW_SPA_YELLOW} from "./SVGS/BODY_SOLID_YELLOW_SPA_YELLOW";
import {BODY_THEME_BLUE_GULF} from "./SVGS/BODY_THEME_BLUE_GULF";
import {BODY_THEME_BLUE_REDBULL} from "./SVGS/BODY_THEME_BLUE_REDBULL";
import {BODY_THEME_BLUE_REDBULL_XL} from "./SVGS/BODY_THEME_BLUE_REDBULL_XL";
import {BODY_THEME_BLUE_ROTHMANS} from "./SVGS/BODY_THEME_BLUE_ROTHMANS";
import {BODY_THEME_BLUE_S2R_BLUE_AND_RED} from "./SVGS/BODY_THEME_BLUE_S2R_BLUE_AND_RED";
import {BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW} from "./SVGS/BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW";
import {BODY_THEME_GRAY_MARTINI} from "./SVGS/BODY_THEME_GRAY_MARTINI";
import {BODY_THEME_GRAY_RA300} from "./SVGS/BODY_THEME_GRAY_RA300";
import {BODY_THEME_GRAY_S2R_BERLINA_BLACK} from "./SVGS/BODY_THEME_GRAY_S2R_BERLINA_BLACK";
import {BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE} from "./SVGS/BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE";
import {BODY_THEME_GRAY_S2R_SEBRING_SILVER} from "./SVGS/BODY_THEME_GRAY_S2R_SEBRING_SILVER";
import {BODY_THEME_RED_LUCKY_STRIKE} from "./SVGS/BODY_THEME_RED_LUCKY_STRIKE";
import {BODY_THEME_RED_MARLBORO} from "./SVGS/BODY_THEME_RED_MARLBORO";
import {BODY_THEME_RED_S2R_NEW_FORMULA_RED} from "./SVGS/BODY_THEME_RED_S2R_NEW_FORMULA_RED";
import {BODY_THEME_YELLOW_BENSON_AND_HEDGES} from "./SVGS/BODY_THEME_YELLOW_BENSON_AND_HEDGES";
import {BODY_THEME_YELLOW_CAMEL} from "./SVGS/BODY_THEME_YELLOW_CAMEL";
import {BODY_THEME_YELLOW_CANON} from "./SVGS/BODY_THEME_YELLOW_CANON";
import {BODY_THEME_YELLOW_SPOON} from "./SVGS/BODY_THEME_YELLOW_SPOON";
import {CALIPER_4P_FRONT_BLUE_BLUE} from "./SVGS/CALIPER_4P_FRONT_BLUE_BLUE";
import {CALIPER_4P_FRONT_BLUE_DARK_BLUE} from "./SVGS/CALIPER_4P_FRONT_BLUE_DARK_BLUE";
import {CALIPER_4P_FRONT_GRAY_GOLD} from "./SVGS/CALIPER_4P_FRONT_GRAY_GOLD";
import {CALIPER_4P_FRONT_GRAY_SILVER} from "./SVGS/CALIPER_4P_FRONT_GRAY_SILVER";
import {CALIPER_4P_FRONT_GREEN_LIME_GREEN} from "./SVGS/CALIPER_4P_FRONT_GREEN_LIME_GREEN";
import {CALIPER_4P_FRONT_ORANGE_ORANGE} from "./SVGS/CALIPER_4P_FRONT_ORANGE_ORANGE";
import {CALIPER_4P_FRONT_PURPLE_PURPLE} from "./SVGS/CALIPER_4P_FRONT_PURPLE_PURPLE";
import {CALIPER_4P_FRONT_RED_RED} from "./SVGS/CALIPER_4P_FRONT_RED_RED";
import {CALIPER_4P_FRONT_YELLOW_YELLOW} from "./SVGS/CALIPER_4P_FRONT_YELLOW_YELLOW";
import {CALIPER_OEM_FRONT_FACTORY} from "./SVGS/CALIPER_OEM_FRONT_FACTORY";
import {GLOVE_GRAY_WHITE} from "./SVGS/GLOVE_GRAY_WHITE";
import {HARDTOP_OEM_SOLID_BLUE_APEX_BLUE} from "./SVGS/HARDTOP_OEM_SOLID_BLUE_APEX_BLUE";
import {HARDTOP_OEM_SOLID_BLUE_GULF_BLUE} from "./SVGS/HARDTOP_OEM_SOLID_BLUE_GULF_BLUE";
import {HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE} from "./SVGS/HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE";
import {HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE} from "./SVGS/HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE";
import {HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY} from "./SVGS/HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY";
import {HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE} from "./SVGS/HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE";
import {HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK";
import {HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE";
import {HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL";
import {HARDTOP_OEM_SOLID_GRAY_MOONROCK} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_MOONROCK";
import {HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER";
import {HARDTOP_OEM_SOLID_GRAY_SILVERSTONE} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_SILVERSTONE";
import {HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE} from "./SVGS/HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE";
import {HARDTOP_OEM_SOLID_GREEN_LIME_GREEN} from "./SVGS/HARDTOP_OEM_SOLID_GREEN_LIME_GREEN";
import {HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN} from "./SVGS/HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN";
import {HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE} from "./SVGS/HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE";
import {HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED} from "./SVGS/HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED";
import {HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW} from "./SVGS/HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW";
import {HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW} from "./SVGS/HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW";
import {HARDTOP_OEM_THEME_GRAY_MARTINI} from "./SVGS/HARDTOP_OEM_THEME_GRAY_MARTINI";
import {HARDTOP_OEM_THEME_RED_LUCKY_STRIKE} from "./SVGS/HARDTOP_OEM_THEME_RED_LUCKY_STRIKE";
import {HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES} from "./SVGS/HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES";
import {HARDTOP_OEM_THEME_YELLOW_CAMEL} from "./SVGS/HARDTOP_OEM_THEME_YELLOW_CAMEL";
import {HELMET_BLACK_DRIVER_JAMES_HUNT} from "./SVGS/HELMET_BLACK_DRIVER_JAMES_HUNT";
import {HELMET_BLACK_SOLID_BERLINA_BLACK} from "./SVGS/HELMET_BLACK_SOLID_BERLINA_BLACK";
import {HELMET_BLUE_DRIVER_REDBULL} from "./SVGS/HELMET_BLUE_DRIVER_REDBULL";
import {HELMET_BLUE_SOLID_APEX_BLUE} from "./SVGS/HELMET_BLUE_SOLID_APEX_BLUE";
import {HELMET_BLUE_SOLID_LAGUNA_BLUE} from "./SVGS/HELMET_BLUE_SOLID_LAGUNA_BLUE";
import {HELMET_GRAY_DRIVER_NIGEL_MANSELL} from "./SVGS/HELMET_GRAY_DRIVER_NIGEL_MANSELL";
import {HELMET_GRAY_DRIVER_STEVE_MCQUEEN} from "./SVGS/HELMET_GRAY_DRIVER_STEVE_MCQUEEN";
import {HELMET_GRAY_DRIVER_THE_STIG} from "./SVGS/HELMET_GRAY_DRIVER_THE_STIG";
import {HELMET_GRAY_SOLID_GRAND_PRIX_WHITE} from "./SVGS/HELMET_GRAY_SOLID_GRAND_PRIX_WHITE";
import {HELMET_GRAY_SOLID_SUNSET_MAUVE} from "./SVGS/HELMET_GRAY_SOLID_SUNSET_MAUVE";
import {HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA} from "./SVGS/HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA";
import {HELMET_GREEN_SOLID_LIME_GREEN} from "./SVGS/HELMET_GREEN_SOLID_LIME_GREEN";
import {HELMET_ORANGE_SOLID_IMOLA_ORANGE} from "./SVGS/HELMET_ORANGE_SOLID_IMOLA_ORANGE";
import {HELMET_RED_DRIVER_NELSON_PIQUET} from "./SVGS/HELMET_RED_DRIVER_NELSON_PIQUET";
import {HELMET_RED_DRIVER_VAN_HALEN} from "./SVGS/HELMET_RED_DRIVER_VAN_HALEN";
import {HELMET_RED_SOLID_NEW_FORMULA_RED} from "./SVGS/HELMET_RED_SOLID_NEW_FORMULA_RED";
import {HELMET_YELLOW_DRIVER_AYRTON_SENNA} from "./SVGS/HELMET_YELLOW_DRIVER_AYRTON_SENNA";
import {HELMET_YELLOW_SOLID_RIO_YELLOW} from "./SVGS/HELMET_YELLOW_SOLID_RIO_YELLOW";
import {HELMET_YELLOW_SOLID_SPA_YELLOW} from "./SVGS/HELMET_YELLOW_SOLID_SPA_YELLOW";
import {ROLLBAR_BLUE_APEX_BLUE} from "./SVGS/ROLLBAR_BLUE_APEX_BLUE";
import {ROLLBAR_BLUE_LAGUNA_BLUE} from "./SVGS/ROLLBAR_BLUE_LAGUNA_BLUE";
import {ROLLBAR_BLUE_MONTE_CARLO_BLUE} from "./SVGS/ROLLBAR_BLUE_MONTE_CARLO_BLUE";
import {ROLLBAR_BLUE_ROYAL_NAVY} from "./SVGS/ROLLBAR_BLUE_ROYAL_NAVY";
import {ROLLBAR_BLUE_SUZUKA_BLUE} from "./SVGS/ROLLBAR_BLUE_SUZUKA_BLUE";
import {ROLLBAR_GRAY_BATTLESHIP_GRAY} from "./SVGS/ROLLBAR_GRAY_BATTLESHIP_GRAY";
import {ROLLBAR_GRAY_BERLINA_BLACK} from "./SVGS/ROLLBAR_GRAY_BERLINA_BLACK";
import {ROLLBAR_GRAY_GRAND_PRIX_WHITE} from "./SVGS/ROLLBAR_GRAY_GRAND_PRIX_WHITE";
import {ROLLBAR_GREEN_LIME_GREEN} from "./SVGS/ROLLBAR_GREEN_LIME_GREEN";
import {ROLLBAR_ORANGE_IMOLA_ORANGE} from "./SVGS/ROLLBAR_ORANGE_IMOLA_ORANGE";
import {ROLLBAR_RED_NEW_FORMULA_RED} from "./SVGS/ROLLBAR_RED_NEW_FORMULA_RED";
import {ROLLBAR_YELLOW_RIO_YELLOW} from "./SVGS/ROLLBAR_YELLOW_RIO_YELLOW";
import {ROLLBAR_YELLOW_SPA_YELLOW} from "./SVGS/ROLLBAR_YELLOW_SPA_YELLOW";
import {SOFTTOP_GRAY_BLACK} from "./SVGS/SOFTTOP_GRAY_BLACK";
import {STATIC_BACKGROUND} from "./SVGS/STATIC_BACKGROUND";
import {STATIC_BACKGROUND_GLASS} from "./SVGS/STATIC_BACKGROUND_GLASS";
import {STATIC_BRAKES_REAR} from "./SVGS/STATIC_BRAKES_REAR";
import {STATIC_GROUND} from "./SVGS/STATIC_GROUND";
import {STATIC_HEADLIGHT_TAILLIGHT} from "./SVGS/STATIC_HEADLIGHT_TAILLIGHT";
import {STATIC_MUFFLER} from "./SVGS/STATIC_MUFFLER";
import {STATIC_TIRES} from "./SVGS/STATIC_TIRES";
import {STEERING_WHEEL_GRAY_BLACK} from "./SVGS/STEERING_WHEEL_GRAY_BLACK";
import {STEERING_WHEEL_RED_RED} from "./SVGS/STEERING_WHEEL_RED_RED";
import {TIRES_ADVAN} from "./SVGS/TIRES_ADVAN";
import {TIRES_BRIDGESTONE} from "./SVGS/TIRES_BRIDGESTONE";
import {TIRES_DUNLOP} from "./SVGS/TIRES_DUNLOP";
import {TIRES_DUNLOP_REVERSED} from "./SVGS/TIRES_DUNLOP_REVERSED";
import {TIRES_FIRESTONE} from "./SVGS/TIRES_FIRESTONE";
import {TIRES_GOODYEAR} from "./SVGS/TIRES_GOODYEAR";
import {TIRES_GOODYEAR_XL} from "./SVGS/TIRES_GOODYEAR_XL";
import {TIRES_MICHELIN_FULL_COLOR} from "./SVGS/TIRES_MICHELIN_FULL_COLOR";
import {TIRES_PIRELLI_HARD_BLUE} from "./SVGS/TIRES_PIRELLI_HARD_BLUE";
import {TIRES_PIRELLI_HARD_ORANGE} from "./SVGS/TIRES_PIRELLI_HARD_ORANGE";
import {TIRES_PIRELLI_INTER_GREEN} from "./SVGS/TIRES_PIRELLI_INTER_GREEN";
import {TIRES_PIRELLI_MEDIUM_WHITE} from "./SVGS/TIRES_PIRELLI_MEDIUM_WHITE";
import {TIRES_PIRELLI_MEDIUM_YELLOW} from "./SVGS/TIRES_PIRELLI_MEDIUM_YELLOW";
import {TIRES_PIRELLI_SOFT_MAGENTA} from "./SVGS/TIRES_PIRELLI_SOFT_MAGENTA";
import {TIRES_PIRELLI_SOFT_PINK} from "./SVGS/TIRES_PIRELLI_SOFT_PINK";
import {TIRES_PIRELLI_SOFT_RED} from "./SVGS/TIRES_PIRELLI_SOFT_RED";
import {TIRES_PIRELLI_WET_BLUE} from "./SVGS/TIRES_PIRELLI_WET_BLUE";
import {WHEELS_BBS_GRAY_BLACK} from "./SVGS/WHEELS_BBS_GRAY_BLACK";
import {WHEELS_BBS_GRAY_GOLD} from "./SVGS/WHEELS_BBS_GRAY_GOLD";
import {WHEELS_BBS_GRAY_GUNMETAL} from "./SVGS/WHEELS_BBS_GRAY_GUNMETAL";
import {WHEELS_BBS_GRAY_SILVER} from "./SVGS/WHEELS_BBS_GRAY_SILVER";
import {WHEELS_FONDMETAL} from "./SVGS/WHEELS_FONDMETAL";
import {WHEELS_OEM_AP1_GRAY_SILVER} from "./SVGS/WHEELS_OEM_AP1_GRAY_SILVER";
import {WHEELS_OEM_AP2V1_GRAY_SILVER} from "./SVGS/WHEELS_OEM_AP2V1_GRAY_SILVER";
import {WHEELS_OEM_AP2V3_GRAY_SILVER} from "./SVGS/WHEELS_OEM_AP2V3_GRAY_SILVER";
import {WHEELS_OEM_CR_GRAY_SILVER} from "./SVGS/WHEELS_OEM_CR_GRAY_SILVER";
import {WHEELS_OZ_RACING_BLUE_BLACK_BLUE} from "./SVGS/WHEELS_OZ_RACING_BLUE_BLACK_BLUE";
import {WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE} from "./SVGS/WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE";
import {WHEELS_OZ_RACING_GRAY_BLACK_BLACK} from "./SVGS/WHEELS_OZ_RACING_GRAY_BLACK_BLACK";
import {WHEELS_OZ_RACING_GRAY_BLACK_WHITE} from "./SVGS/WHEELS_OZ_RACING_GRAY_BLACK_WHITE";
import {WHEELS_OZ_RACING_GREEN_BLACK_GREEN} from "./SVGS/WHEELS_OZ_RACING_GREEN_BLACK_GREEN";
import {WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE} from "./SVGS/WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE";
import {WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE} from "./SVGS/WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE";
import {WHEELS_OZ_RACING_RED_BLACK_RED} from "./SVGS/WHEELS_OZ_RACING_RED_BLACK_RED";
import {WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW} from "./SVGS/WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW";
import {WHEELS_RA300} from "./SVGS/WHEELS_RA300";
import {WHEELS_RA300_GOLD} from "./SVGS/WHEELS_RA300_GOLD";
import {WHEELS_RA300_SILVER} from "./SVGS/WHEELS_RA300_SILVER";
import {WHEELS_SW388_BLUE_BLACK_BLUE} from "./SVGS/WHEELS_SW388_BLUE_BLACK_BLUE";
import {WHEELS_SW388_GRAY_BLACK_GRAY} from "./SVGS/WHEELS_SW388_GRAY_BLACK_GRAY";
import {WHEELS_SW388_GREEN_BLACK_GREEN} from "./SVGS/WHEELS_SW388_GREEN_BLACK_GREEN";
import {WHEELS_SW388_ORANGE_BLACK_ORANGE} from "./SVGS/WHEELS_SW388_ORANGE_BLACK_ORANGE";
import {WHEELS_SW388_PURPLE_BLACK_PURPLE} from "./SVGS/WHEELS_SW388_PURPLE_BLACK_PURPLE";
import {WHEELS_SW388_RED_BLACK_RED} from "./SVGS/WHEELS_SW388_RED_BLACK_RED";
import {WHEELS_SW388_YELLOW_BLACK_YELLOW} from "./SVGS/WHEELS_SW388_YELLOW_BLACK_YELLOW";
import {WHEELS_TRS9_GRAY_BLACK} from "./SVGS/WHEELS_TRS9_GRAY_BLACK";
import {WING_RACING_SOLID_BLUE_APEX_BLUE} from "./SVGS/WING_RACING_SOLID_BLUE_APEX_BLUE";
import {WING_RACING_SOLID_BLUE_APEX_BLUE_1} from "./SVGS/WING_RACING_SOLID_BLUE_APEX_BLUE_1";
import {WING_RACING_SOLID_BLUE_GULF_BLUE_1} from "./SVGS/WING_RACING_SOLID_BLUE_GULF_BLUE_1";
import {WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1} from "./SVGS/WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1";
import {WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1} from "./SVGS/WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1";
import {WING_RACING_SOLID_BLUE_ROYAL_NAVY_BLUE_1} from "./SVGS/WING_RACING_SOLID_BLUE_ROYAL_NAVY_BLUE_1";
import {WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1} from "./SVGS/WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1";
import {WING_RACING_SOLID_CARBON_FIBER} from "./SVGS/WING_RACING_SOLID_CARBON_FIBER";
import {WING_RACING_SOLID_CARBON_FIBER_WHITE_1} from "./SVGS/WING_RACING_SOLID_CARBON_FIBER_WHITE_1";
import {WING_RACING_SOLID_GRAY_BERLINA_BLACK} from "./SVGS/WING_RACING_SOLID_GRAY_BERLINA_BLACK";
import {WING_RACING_SOLID_GRAY_BERLINA_BLACK_1} from "./SVGS/WING_RACING_SOLID_GRAY_BERLINA_BLACK_1";
import {WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE} from "./SVGS/WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE";
import {WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1} from "./SVGS/WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1";
import {WING_RACING_SOLID_GRAY_MAUVE_SUNSET_PEARL_1} from "./SVGS/WING_RACING_SOLID_GRAY_MAUVE_SUNSET_PEARL_1";
import {WING_RACING_SOLID_GRAY_MOONROCK_1} from "./SVGS/WING_RACING_SOLID_GRAY_MOONROCK_1";
import {WING_RACING_SOLID_GRAY_SEBRING_SILVER_1} from "./SVGS/WING_RACING_SOLID_GRAY_SEBRING_SILVER_1";
import {WING_RACING_SOLID_GRAY_SILVERSTONE_1} from "./SVGS/WING_RACING_SOLID_GRAY_SILVERSTONE_1";
import {WING_RACING_SOLID_GREEN_LIME_GREEN_1} from "./SVGS/WING_RACING_SOLID_GREEN_LIME_GREEN_1";
import {WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1} from "./SVGS/WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1";
import {WING_RACING_SOLID_ORANGE_IMOLA_ORANGE_1} from "./SVGS/WING_RACING_SOLID_ORANGE_IMOLA_ORANGE_1";
import {WING_RACING_SOLID_RED_NEW_FORMULA_RED_1} from "./SVGS/WING_RACING_SOLID_RED_NEW_FORMULA_RED_1";
import {WING_RACING_SOLID_YELLOW_RIO_YELLOW} from "./SVGS/WING_RACING_SOLID_YELLOW_RIO_YELLOW";
import {WING_RACING_SOLID_YELLOW_RIO_YELLOW_1} from "./SVGS/WING_RACING_SOLID_YELLOW_RIO_YELLOW_1";
import {WING_RACING_SOLID_YELLOW_SPA_YELLOW_1} from "./SVGS/WING_RACING_SOLID_YELLOW_SPA_YELLOW_1";
import {WING_RACING_THEME_CAMEL_12} from "./SVGS/WING_RACING_THEME_CAMEL_12";
import {WING_RACING_THEME_CANON_5} from "./SVGS/WING_RACING_THEME_CANON_5";
import {WING_RACING_THEME_LUCKY_STRIKE_1} from "./SVGS/WING_RACING_THEME_LUCKY_STRIKE_1";
import {WING_RACING_THEME_MARLBORO_12} from "./SVGS/WING_RACING_THEME_MARLBORO_12";
import {WING_RACING_THEME_ROTHMANS_1} from "./SVGS/WING_RACING_THEME_ROTHMANS_1";



import React, { 

    //useState, 
    useEffect, 
    useRef 

} from 'react';



const Index = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};



    // -- ref -- //
    const refDiv = useRef(null);    
    const refStaticGround = useRef(null);    





    //##############################################################
    //##############################################################
    //##############################################################

    useEffect(() => {

		//console.log(refDiv.current.offsetHeight);
		if(refStaticGround && refStaticGround !== undefined) 
		{
			if(refStaticGround.current && refStaticGround.current !== undefined) 
			{
				console.log("staticGround Height: "+refStaticGround.current.offsetHeight);
			}

		}
		
			
    },
    [
        props,
		refDiv,
		refStaticGround,
		svgHeight
    ])




	return(

		<div
		ref={refDiv}
		style={{
			//backgroundColor:"pink",
			width:"100%",
			height:"auto"
		}}
		>

			{
			props.call === 'BANNER_BLUE_BLUE' ?
				<BANNER_BLUE_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_BLUE_DARK_BLUE' ?
				<BANNER_BLUE_DARK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_GRAY_BLACK' ?
				<BANNER_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_GRAY_SILVER' ?
				<BANNER_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_GREEN_LIME_GREEN' ?
				<BANNER_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_ORANGE_ORANGE' ?
				<BANNER_ORANGE_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_PURPLE_PURPLE' ?
				<BANNER_PURPLE_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_RED_RED' ?
				<BANNER_RED_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BANNER_YELLOW_YELLOW' ?
				<BANNER_YELLOW_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_APEX_BLUE' ?
				<BODY_SOLID_BLUE_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_CR_APEX_BLUE' ?
				<BODY_SOLID_BLUE_CR_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_GULF_BLUE' ?
				<BODY_SOLID_BLUE_GULF_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_LAGUNA_BLUE' ?
				<BODY_SOLID_BLUE_LAGUNA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_MONTE_CARLO_BLUE' ?
				<BODY_SOLID_BLUE_MONTE_CARLO_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_ROYAL_NAVY' ?
				<BODY_SOLID_BLUE_ROYAL_NAVY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_BLUE_SUZUKA_BLUE' ?
				<BODY_SOLID_BLUE_SUZUKA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_BERLINA_BLACK' ?
				<BODY_SOLID_GRAY_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_CR_BERLINA_BLACK' ?
				<BODY_SOLID_GRAY_CR_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL' ?
				<BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_GRAND_PRIX_WHITE' ?
				<BODY_SOLID_GRAY_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_GRAND_PRIX_WHITE_HL' ?
				<BODY_SOLID_GRAY_GRAND_PRIX_WHITE_HL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_MOONROCK' ?
				<BODY_SOLID_GRAY_MOONROCK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_SEBRING_SILVER' ?
				<BODY_SOLID_GRAY_SEBRING_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_SILVERSTONE' ?
				<BODY_SOLID_GRAY_SILVERSTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GRAY_SUNSET_MAUVE' ?
				<BODY_SOLID_GRAY_SUNSET_MAUVE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GREEN_LIME_GREEN' ?
				<BODY_SOLID_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_GREEN_TAHITIAN_GREEN' ?
				<BODY_SOLID_GREEN_TAHITIAN_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_ORANGE_IMOLA_ORANGE' ?
				<BODY_SOLID_ORANGE_IMOLA_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_RED_NEW_FORMULA_RED' ?
				<BODY_SOLID_RED_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_YELLOW_CR_RIO_YELLOW' ?
				<BODY_SOLID_YELLOW_CR_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_YELLOW_RIO_YELLOW' ?
				<BODY_SOLID_YELLOW_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_SOLID_YELLOW_SPA_YELLOW' ?
				<BODY_SOLID_YELLOW_SPA_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_BLUE_GULF' ?
				<BODY_THEME_BLUE_GULF
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_BLUE_REDBULL' ?
				<BODY_THEME_BLUE_REDBULL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_BLUE_REDBULL_XL' ?
				<BODY_THEME_BLUE_REDBULL_XL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_BLUE_ROTHMANS' ?
				<BODY_THEME_BLUE_ROTHMANS
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_BLUE_S2R_BLUE_AND_RED' ?
				<BODY_THEME_BLUE_S2R_BLUE_AND_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW' ?
				<BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_GRAY_MARTINI' ?
				<BODY_THEME_GRAY_MARTINI
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_GRAY_RA300' ?
				<BODY_THEME_GRAY_RA300
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_GRAY_S2R_BERLINA_BLACK' ?
				<BODY_THEME_GRAY_S2R_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE' ?
				<BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_GRAY_S2R_SEBRING_SILVER' ?
				<BODY_THEME_GRAY_S2R_SEBRING_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_RED_LUCKY_STRIKE' ?
				<BODY_THEME_RED_LUCKY_STRIKE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_RED_MARLBORO' ?
				<BODY_THEME_RED_MARLBORO
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_RED_S2R_NEW_FORMULA_RED' ?
				<BODY_THEME_RED_S2R_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_YELLOW_BENSON_AND_HEDGES' ?
				<BODY_THEME_YELLOW_BENSON_AND_HEDGES
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_YELLOW_CAMEL' ?
				<BODY_THEME_YELLOW_CAMEL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_YELLOW_CANON' ?
				<BODY_THEME_YELLOW_CANON
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BODY_THEME_YELLOW_SPOON' ?
				<BODY_THEME_YELLOW_SPOON
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_BLUE_BLUE' ?
				<CALIPER_4P_FRONT_BLUE_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_BLUE_DARK_BLUE' ?
				<CALIPER_4P_FRONT_BLUE_DARK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_GRAY_GOLD' ?
				<CALIPER_4P_FRONT_GRAY_GOLD
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_GRAY_SILVER' ?
				<CALIPER_4P_FRONT_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_GREEN_LIME_GREEN' ?
				<CALIPER_4P_FRONT_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_ORANGE_ORANGE' ?
				<CALIPER_4P_FRONT_ORANGE_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_PURPLE_PURPLE' ?
				<CALIPER_4P_FRONT_PURPLE_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_RED_RED' ?
				<CALIPER_4P_FRONT_RED_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_4P_FRONT_YELLOW_YELLOW' ?
				<CALIPER_4P_FRONT_YELLOW_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'CALIPER_OEM_FRONT_FACTORY' ?
				<CALIPER_OEM_FRONT_FACTORY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'GLOVE_GRAY_WHITE' ?
				<GLOVE_GRAY_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_BLUE_APEX_BLUE' ?
				<HARDTOP_OEM_SOLID_BLUE_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_BLUE_GULF_BLUE' ?
				<HARDTOP_OEM_SOLID_BLUE_GULF_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE' ?
				<HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE' ?
				<HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY' ?
				<HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE' ?
				<HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK' ?
				<HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE' ?
				<HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL' ?
				<HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_MOONROCK' ?
				<HARDTOP_OEM_SOLID_GRAY_MOONROCK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER' ?
				<HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_SILVERSTONE' ?
				<HARDTOP_OEM_SOLID_GRAY_SILVERSTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE' ?
				<HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GREEN_LIME_GREEN' ?
				<HARDTOP_OEM_SOLID_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN' ?
				<HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE' ?
				<HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED' ?
				<HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW' ?
				<HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW' ?
				<HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_THEME_GRAY_MARTINI' ?
				<HARDTOP_OEM_THEME_GRAY_MARTINI
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_THEME_RED_LUCKY_STRIKE' ?
				<HARDTOP_OEM_THEME_RED_LUCKY_STRIKE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES' ?
				<HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HARDTOP_OEM_THEME_YELLOW_CAMEL' ?
				<HARDTOP_OEM_THEME_YELLOW_CAMEL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_BLACK_DRIVER_JAMES_HUNT' ?
				<HELMET_BLACK_DRIVER_JAMES_HUNT
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_BLACK_SOLID_BERLINA_BLACK' ?
				<HELMET_BLACK_SOLID_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_BLUE_DRIVER_REDBULL' ?
				<HELMET_BLUE_DRIVER_REDBULL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_BLUE_SOLID_APEX_BLUE' ?
				<HELMET_BLUE_SOLID_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_BLUE_SOLID_LAGUNA_BLUE' ?
				<HELMET_BLUE_SOLID_LAGUNA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GRAY_DRIVER_NIGEL_MANSELL' ?
				<HELMET_GRAY_DRIVER_NIGEL_MANSELL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GRAY_DRIVER_STEVE_MCQUEEN' ?
				<HELMET_GRAY_DRIVER_STEVE_MCQUEEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GRAY_DRIVER_THE_STIG' ?
				<HELMET_GRAY_DRIVER_THE_STIG
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE' ?
				<HELMET_GRAY_SOLID_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GRAY_SOLID_SUNSET_MAUVE' ?
				<HELMET_GRAY_SOLID_SUNSET_MAUVE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA' ?
				<HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_GREEN_SOLID_LIME_GREEN' ?
				<HELMET_GREEN_SOLID_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_ORANGE_SOLID_IMOLA_ORANGE' ?
				<HELMET_ORANGE_SOLID_IMOLA_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_RED_DRIVER_NELSON_PIQUET' ?
				<HELMET_RED_DRIVER_NELSON_PIQUET
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_RED_DRIVER_VAN_HALEN' ?
				<HELMET_RED_DRIVER_VAN_HALEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_RED_SOLID_NEW_FORMULA_RED' ?
				<HELMET_RED_SOLID_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_YELLOW_DRIVER_AYRTON_SENNA' ?
				<HELMET_YELLOW_DRIVER_AYRTON_SENNA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_YELLOW_SOLID_RIO_YELLOW' ?
				<HELMET_YELLOW_SOLID_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'HELMET_YELLOW_SOLID_SPA_YELLOW' ?
				<HELMET_YELLOW_SOLID_SPA_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_BLUE_APEX_BLUE' ?
				<ROLLBAR_BLUE_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_BLUE_LAGUNA_BLUE' ?
				<ROLLBAR_BLUE_LAGUNA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_BLUE_MONTE_CARLO_BLUE' ?
				<ROLLBAR_BLUE_MONTE_CARLO_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_BLUE_ROYAL_NAVY' ?
				<ROLLBAR_BLUE_ROYAL_NAVY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_BLUE_SUZUKA_BLUE' ?
				<ROLLBAR_BLUE_SUZUKA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_GRAY_BATTLESHIP_GRAY' ?
				<ROLLBAR_GRAY_BATTLESHIP_GRAY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_GRAY_BERLINA_BLACK' ?
				<ROLLBAR_GRAY_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_GRAY_GRAND_PRIX_WHITE' ?
				<ROLLBAR_GRAY_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_GREEN_LIME_GREEN' ?
				<ROLLBAR_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_ORANGE_IMOLA_ORANGE' ?
				<ROLLBAR_ORANGE_IMOLA_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_RED_NEW_FORMULA_RED' ?
				<ROLLBAR_RED_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_YELLOW_RIO_YELLOW' ?
				<ROLLBAR_YELLOW_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'ROLLBAR_YELLOW_SPA_YELLOW' ?
				<ROLLBAR_YELLOW_SPA_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'SOFTTOP_GRAY_BLACK' ?
				<SOFTTOP_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_BACKGROUND' ?
				<STATIC_BACKGROUND
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_BACKGROUND_GLASS' ?
				<STATIC_BACKGROUND_GLASS
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_BRAKES_REAR' ?
				<STATIC_BRAKES_REAR
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_GROUND' ?
				<STATIC_GROUND
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_HEADLIGHT_TAILLIGHT' ?
				<STATIC_HEADLIGHT_TAILLIGHT
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_MUFFLER' ?
				<STATIC_MUFFLER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STATIC_TIRES' ?
				<STATIC_TIRES
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STEERING_WHEEL_GRAY_BLACK' ?
				<STEERING_WHEEL_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'STEERING_WHEEL_RED_RED' ?
				<STEERING_WHEEL_RED_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_ADVAN' ?
				<TIRES_ADVAN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_BRIDGESTONE' ?
				<TIRES_BRIDGESTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_DUNLOP' ?
				<TIRES_DUNLOP
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_DUNLOP_REVERSED' ?
				<TIRES_DUNLOP_REVERSED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_FIRESTONE' ?
				<TIRES_FIRESTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_GOODYEAR' ?
				<TIRES_GOODYEAR
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_GOODYEAR_XL' ?
				<TIRES_GOODYEAR_XL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_MICHELIN_FULL_COLOR' ?
				<TIRES_MICHELIN_FULL_COLOR
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_HARD_BLUE' ?
				<TIRES_PIRELLI_HARD_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_HARD_ORANGE' ?
				<TIRES_PIRELLI_HARD_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_INTER_GREEN' ?
				<TIRES_PIRELLI_INTER_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_MEDIUM_WHITE' ?
				<TIRES_PIRELLI_MEDIUM_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_MEDIUM_YELLOW' ?
				<TIRES_PIRELLI_MEDIUM_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_SOFT_MAGENTA' ?
				<TIRES_PIRELLI_SOFT_MAGENTA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_SOFT_PINK' ?
				<TIRES_PIRELLI_SOFT_PINK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_SOFT_RED' ?
				<TIRES_PIRELLI_SOFT_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'TIRES_PIRELLI_WET_BLUE' ?
				<TIRES_PIRELLI_WET_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_BBS_GRAY_BLACK' ?
				<WHEELS_BBS_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_BBS_GRAY_GOLD' ?
				<WHEELS_BBS_GRAY_GOLD
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_BBS_GRAY_GUNMETAL' ?
				<WHEELS_BBS_GRAY_GUNMETAL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_BBS_GRAY_SILVER' ?
				<WHEELS_BBS_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_FONDMETAL' ?
				<WHEELS_FONDMETAL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OEM_AP1_GRAY_SILVER' ?
				<WHEELS_OEM_AP1_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OEM_AP2V1_GRAY_SILVER' ?
				<WHEELS_OEM_AP2V1_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OEM_AP2V3_GRAY_SILVER' ?
				<WHEELS_OEM_AP2V3_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OEM_CR_GRAY_SILVER' ?
				<WHEELS_OEM_CR_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_BLUE_BLACK_BLUE' ?
				<WHEELS_OZ_RACING_BLUE_BLACK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE' ?
				<WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_GRAY_BLACK_BLACK' ?
				<WHEELS_OZ_RACING_GRAY_BLACK_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_GRAY_BLACK_WHITE' ?
				<WHEELS_OZ_RACING_GRAY_BLACK_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_GREEN_BLACK_GREEN' ?
				<WHEELS_OZ_RACING_GREEN_BLACK_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE' ?
				<WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE' ?
				<WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_RED_BLACK_RED' ?
				<WHEELS_OZ_RACING_RED_BLACK_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW' ?
				<WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_RA300' ?
				<WHEELS_RA300
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_RA300_GOLD' ?
				<WHEELS_RA300_GOLD
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_RA300_SILVER' ?
				<WHEELS_RA300_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_BLUE_BLACK_BLUE' ?
				<WHEELS_SW388_BLUE_BLACK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_GRAY_BLACK_GRAY' ?
				<WHEELS_SW388_GRAY_BLACK_GRAY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_GREEN_BLACK_GREEN' ?
				<WHEELS_SW388_GREEN_BLACK_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_ORANGE_BLACK_ORANGE' ?
				<WHEELS_SW388_ORANGE_BLACK_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_PURPLE_BLACK_PURPLE' ?
				<WHEELS_SW388_PURPLE_BLACK_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_RED_BLACK_RED' ?
				<WHEELS_SW388_RED_BLACK_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_SW388_YELLOW_BLACK_YELLOW' ?
				<WHEELS_SW388_YELLOW_BLACK_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WHEELS_TRS9_GRAY_BLACK' ?
				<WHEELS_TRS9_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_APEX_BLUE' ?
				<WING_RACING_SOLID_BLUE_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_APEX_BLUE_1' ?
				<WING_RACING_SOLID_BLUE_APEX_BLUE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_GULF_BLUE_1' ?
				<WING_RACING_SOLID_BLUE_GULF_BLUE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1' ?
				<WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1' ?
				<WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_ROYAL_NAVY_BLUE_1' ?
				<WING_RACING_SOLID_BLUE_ROYAL_NAVY_BLUE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1' ?
				<WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_CARBON_FIBER' ?
				<WING_RACING_SOLID_CARBON_FIBER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_CARBON_FIBER_WHITE_1' ?
				<WING_RACING_SOLID_CARBON_FIBER_WHITE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_BERLINA_BLACK' ?
				<WING_RACING_SOLID_GRAY_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_BERLINA_BLACK_1' ?
				<WING_RACING_SOLID_GRAY_BERLINA_BLACK_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE' ?
				<WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1' ?
				<WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_MAUVE_SUNSET_PEARL_1' ?
				<WING_RACING_SOLID_GRAY_MAUVE_SUNSET_PEARL_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_MOONROCK_1' ?
				<WING_RACING_SOLID_GRAY_MOONROCK_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_SEBRING_SILVER_1' ?
				<WING_RACING_SOLID_GRAY_SEBRING_SILVER_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GRAY_SILVERSTONE_1' ?
				<WING_RACING_SOLID_GRAY_SILVERSTONE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GREEN_LIME_GREEN_1' ?
				<WING_RACING_SOLID_GREEN_LIME_GREEN_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1' ?
				<WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_ORANGE_IMOLA_ORANGE_1' ?
				<WING_RACING_SOLID_ORANGE_IMOLA_ORANGE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_RED_NEW_FORMULA_RED_1' ?
				<WING_RACING_SOLID_RED_NEW_FORMULA_RED_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_YELLOW_RIO_YELLOW' ?
				<WING_RACING_SOLID_YELLOW_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_YELLOW_RIO_YELLOW_1' ?
				<WING_RACING_SOLID_YELLOW_RIO_YELLOW_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_SOLID_YELLOW_SPA_YELLOW_1' ?
				<WING_RACING_SOLID_YELLOW_SPA_YELLOW_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_THEME_CAMEL_12' ?
				<WING_RACING_THEME_CAMEL_12
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_THEME_CANON_5' ?
				<WING_RACING_THEME_CANON_5
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_THEME_LUCKY_STRIKE_1' ?
				<WING_RACING_THEME_LUCKY_STRIKE_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_THEME_MARLBORO_12' ?
				<WING_RACING_THEME_MARLBORO_12
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'WING_RACING_THEME_ROTHMANS_1' ?
				<WING_RACING_THEME_ROTHMANS_1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
		</div>

	);//return
}
export default Index;