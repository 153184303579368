import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';

//import { useSelector, useDispatch } from 'react-redux';

//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {

    CmpPage,
    CmpFull,
    CmpSingle,
    CmpSmall

} from './Cmp';



const CardComponent = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    



    /*

    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    const refC = useRef(null);

    */


    // -- state -- //

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    //const [thmbWidth, setThmbWidth] = useState(300);
    //const [thmbHeight, setThmbHeight] = useState(225);



    useEffect(() => {

        /*

        alert(""
            +"\n"
            +`PUBLIC: ${process.env.PUBLIC_URL}`
            +"\n"
            +"WINDOW LOCATION ORIGIN: "+window.location.origin
            +"\n"
            +"WINDOW LOCATION HREF: "+window.location.href 
        );
        */
        
       const posArr = [];
       const tempArr = [];

       const Complete = (array) => {

           //alert('images finished loading');
           //console.log(":::"+location.pathname+":::");
           //console.log("Images Finished Loading (Fluid)")

           if(array.length > 0)
           {
                //console.log("Image Loaded Successfully!!");


                //console.log("ARRAY: "+JSON.stringify(array,null,2));

                setIsLoaded(true);
                setItems(array);

           }else{

                console.log("Images Loaded Failed");

                setIsLoaded(true);
                setError({message:"Process Image (FAILED)"});
           }
       
       }



       var skip = false;
       if(props.info.gridType.match(/image/gi)
       && !skip
       )
       {
            if(items.length === 0)
            {

                props.items.map(item => (

                    updateData(item)

                ))

                function updateData(item) {


                    //console.log("ENV PUBLIC URL: "+process.env.PUBLIC_URL);
                
                    var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;
                    //var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;

                    //console.log(sourceurl);
                
                    var img = new Image();
                    img.error = function(){
                        console.log("IMAGE ERROR");
                    };
                    img.onload = function(){
                
                        //console.log("IMAGE IS LOADED");
                
                        //console.log('My width is: ', img.naturalWidth);
                        //console.log('My height is: ', img.naturalHeight);
                
                        var imgW = img.naturalWidth;
                        var imgH = img.naturalHeight;
                
                    

                        /* Original
                        //---------------------------------------

                        posArr.push(item.position);
                        tempArr.push({

                            position    :item.position,
                
                            id          :item.id,
                            title       :item.title,
                            text        :item.text,
                            bgColor     :item.bgColor,
                            image:{
                
                                src :item.image.src,
                                w   :imgW,
                                h   :imgH
                            }
                
                        })

                        //----------------------------------------
                        */


                        //----------------------------------------

                        /* Sample ImageText

                        {
                            "heading": "Image Text Sample 3",
                            "pgraph": "Lorem, ipsum dolor sit",
                            "image": {
                                "src": "/_resources/images/raceaway.jpg",
                                "w": "150",
                                "h": "150"
                            },
                            "button": false
                        }

                        */

                        posArr.push(item.image.src);
                        tempArr.push({

                            heading: item.heading,
                            pgraph: item.pgraph,
                            image:{
                
                                src :item.image.src,
                                w   :imgW,
                                h   :imgH
                            },
                            button: item.button


                        })

                        //----------------------------------------




                        if(tempArr.length === props.items.length)
                        {
                            posArr.sort();

                            //console.log("POS: "+posArr.length)
                            //console.log("TEMP: "+tempArr.length)
                            //console.log("TEST LEN: "+testData.length)
                        

                            var array = [];




                            /* ORIGINAL
                            //--------------------------------------------

                            for(var i=0; i < posArr.length; i++)
                            {
                                for(var j=0; j < tempArr.length; j++)
                                {
                                    //console.log(posArr[i]+" === "+tempArr[j]['position']);

                                    if(posArr[i] === tempArr[j]['position'])
                                    {
                                    
                                        array.push(tempArr[j]);
                                        //console.log("break");
                                        break;

                                    }//==
                
                                }//==

                            }//==

                            //---------------------------------------------
                            */



                            
                            //--------------------------------------------

                            for(var i=0; i < posArr.length; i++)
                            {
                                for(var j=0; j < tempArr.length; j++)
                                {
                                    //console.log(posArr[i]+" === "+tempArr[j]['position']);

                                    if(posArr[i] === tempArr[j].image.src)
                                    {
                                    
                                        array.push(tempArr[j]);
                                        //console.log("break");
                                        break;

                                    }//==
                
                                }//==

                            }//==

                            //---------------------------------------------
                            



                            Complete(array);

                        }//==

                
                
                    };
                    img.setAttribute("crossOrigin","anonymous");
                    img.setAttribute("src",sourceurl);
                
                }



            }// -- if items



        }
        else
        {


            // console.log("ITEMS: "+JSON.stringify(props.items,null,2));
            // alert('card');
    
            setError(false);
            setIsLoaded(true);
            setItems(props.items);

        }




    },[


        height,
        //boxRefs,
        location,
        items,
        props
    ])


    





    // -- vars -- //

    /*

    var ssg;
    var gridMaxWidth;  
    ssg = false;
    gridMaxWidth = "1920px";
    if(location.search.match(/\?/gi))
    {
        var qr = location.search;
        if(qr.charAt(0) === '?') 
            qr = qr.substring(1,qr.length);

        if(qr === 'ssg=yes') 
        {
            ssg = true;
            gridMaxWidth = "1680px";

        }
    }

    */


    //var call;
    // = props.info.call;
    //(ssg) call = 'fixed';


    const info = props.info;
    //info.gridMaxWidth = gridMaxWidth;
    //info.ssg = ssg;




    //alert(props.refid);



    var columnClass;
    //var boxClass;

    columnClass = 'full';

    //columnClass = StylColumn.Full;
    //boxClass = StylColumn.Box;

    if(items.length === 1) 
    {
        columnClass = 'single';

        //columnClass = StylColumn.Single;
        //boxClass = StylColumn.SingleBox;
    }


    if(props.refid 
    && props.refid !== undefined
    && props.refid !== null
    )
    {
        const ref = document.getElementById(props.refid);

        if(ref)
        {
            var refDecimal = (parseInt(ref.offsetWidth)/width);
            var newWidth  = width * refDecimal;
          
            //console.log(props.refid+"\n"+ref.offsetWidth+"\n"+refDecimal+"\n"+newWidth);
    
            info.thresh.refWidth = newWidth;
        }

        if(props.refid.match(/pagerow/))
        {
            
            columnClass = 'page';  

            //columnClass = StylColumn.Page;
            //boxClass = StylColumn.Box;

        }


        if(props.refid.match(/smallrow/))
        {
            
            columnClass = 'small';  

            //columnClass = StylColumn.Page;
            //boxClass = StylColumn.Box;

        }


    }//==







    if(error) 
    {
        return (

            <div 
            style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

            }}
            >
                    Error: {error.message}

            </div>

        )


    } 
    else 
    if(!isLoaded) 
    {

        return (
        
             <div 
             style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

             }}
             >
                 Loading...

            </div>
        )


    } 
    else 
    {



        return (


            <div>


            {

                columnClass === 'single' 
                ? 
                    <CmpSingle 
                    
                        handle={(v) => props.handle(v)}
                 
                        refid={props.refid}
                        info={info}
                        items={items}

                    />
                :
                columnClass === 'full' 
                ? 
                    <CmpFull 
                    
                        handle={(v) => props.handle(v)}
                 
                        refid={props.refid}
                        info={info}
                        items={items}

                    />
                :
                columnClass === 'page' 
                ? 
                    <CmpPage 
                    
                        handle={(v) => props.handle(v)}
                     
                        refid={props.refid}
                        info={info}
                        items={items}
                    
                    />

                :
                columnClass === 'small' 
                ? 

                    <CmpSmall
                    
                        handle={(v) => props.handle(v)}
                    
                        refid={props.refid}
                        info={info}
                        items={items}

                    />
                    

                :null


            }


            </div>


        )


    }


}

export default CardComponent;











/*


                    {
                    columnClass === 'full' ?

                        items.length === 1 ?

                

                        :// === FULL MORE THAN ONE ===


                    :// === PAGE ===



                    }




*/