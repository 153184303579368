export const BUTTON_LOGO_OZ_RACING = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.11,208.775h7.515c3.413,0,6.195,2.779,6.195,6.182v7.498 c0,3.41-2.783,6.193-6.195,6.193h-7.515c-3.4,0-6.189-2.783-6.189-6.193v-7.498C350.921,211.555,353.71,208.775,357.11,208.775 L357.11,208.775z M377.396,243.141l131.536-48.281H380.753c-5.467,0-9.932-4.465-9.932-9.932c0-5.469,4.465-9.941,9.932-9.941	h184.231c5.469,0,9.932,4.473,9.932,9.941c0,3.992-2.387,7.455-5.802,9.029l-132.511,48.598h128.381 c5.469,0,9.932,4.477,9.932,9.939c0,5.457-4.463,9.932-9.932,9.932H380.753c-5.467,0-9.932-4.475-9.932-9.932 C370.821,248.199,373.568,244.523,377.396,243.141L377.396,243.141z M180.298,194.859h117.896c13.11,0,23.843,10.73,23.843,23.848 c0,13.115-10.733,23.848-23.843,23.848H180.298c-13.116,0-23.846-10.732-23.846-23.848 C156.452,205.59,167.182,194.859,180.298,194.859L180.298,194.859z M180.298,174.986h117.896c24.041,0,43.714,19.678,43.714,43.721	c0,24.051-19.673,43.719-43.714,43.719H180.298c-24.041,0-43.721-19.668-43.721-43.719
		C136.577,194.664,156.257,174.986,180.298,174.986L180.298,174.986z M111.146,287.996c-1.732,0-3.146,1.414-3.146,3.145
		c0,1.734,1.415,3.154,3.146,3.154h497.707c1.73,0,3.146-1.42,3.146-3.154c0-1.73-1.416-3.145-3.146-3.145H111.146L111.146,287.996z M111.146,277.146c-1.732,0-3.146,1.42-3.146,3.148c0,1.73,1.415,3.15,3.146,3.15h497.707c1.73,0,3.146-1.42,3.146-3.15 c0-1.729-1.416-3.148-3.146-3.148H111.146L111.146,277.146z M151.914,311.195v5.521h10.61c2.304,0,4.006-0.221,5.083-0.664 c1.092-0.443,1.633-1.141,1.633-2.094c0-0.902-0.532-1.588-1.606-2.063c-1.055-0.469-2.626-0.701-4.688-0.701H151.914 L151.914,311.195z M151.914,326.406c0,0.771-0.374,1.34-1.118,1.721c-0.744,0.379-1.886,0.559-3.417,0.559
		c-1.512,0-2.644-0.184-3.388-0.57c-0.744-0.385-1.121-0.949-1.121-1.709v-15.518c0-1.271,0.402-2.141,1.191-2.6
		c0.801-0.459,2.232-0.686,4.296-0.686h15.811c4.794,0,8.34,0.494,10.646,1.465c2.312,0.971,3.458,2.453,3.458,4.453
		c0,1.271-0.552,2.33-1.684,3.174c-1.118,0.855-2.743,1.432-4.849,1.746c1.648,0.26,2.906,0.682,3.756,1.24 c0.871,0.57,1.374,1.328,1.559,2.264c0.079,0.385,0.144,0.885,0.168,1.508c0.049,0.627,0.119,1.045,0.217,1.271 c0.08,0.252,0.225,0.602,0.451,1.076c0.211,0.469,0.321,0.791,0.321,0.986c0,0.59-0.405,1.061-1.221,1.408 c-0.814,0.342-1.908,0.527-3.278,0.527c-1.894,0-3.238-0.264-4.035-0.781c-0.805-0.512-1.372-1.82-1.666-3.924 c-0.233-1.672-0.808-2.707-1.705-3.092c-0.906-0.395-2.517-0.59-4.829-0.59h-9.563V326.406L151.914,326.406z M242.72,311.781 l-6.08,8.859h12.197L242.72,311.781L242.72,311.781z M232.221,327.135c-0.353,0.521-0.885,0.918-1.606,1.172 c-0.716,0.258-1.64,0.379-2.769,0.379c-1.29,0-2.338-0.17-3.149-0.518c-0.804-0.338-1.208-0.791-1.208-1.359	c0-0.191,0.029-0.375,0.099-0.549c0.073-0.186,0.191-0.365,0.349-0.555l12.136-16.178c0.7-0.934,1.469-1.539,2.33-1.799	c0.851-0.273,2.285-0.406,4.317-0.406c1.932,0,3.326,0.127,4.187,0.381c0.87,0.264,1.659,0.85,2.376,1.768l12.374,16.234 c0.163,0.189,0.273,0.369,0.343,0.555c0.071,0.174,0.108,0.357,0.108,0.549c0,0.564-0.413,1.006-1.228,1.355 c-0.813,0.352-1.88,0.521-3.188,0.521c-1.115,0-2.019-0.121-2.737-0.375c-0.699-0.242-1.225-0.639-1.588-1.176l-2.084-2.98h-17.028 L232.221,327.135L232.221,327.135z M306.344,318.348c0-3.555,1.862-6.33,5.581-8.33c3.71-1.982,8.901-2.975,15.557-2.975 c2.625,0,5.037,0.17,7.24,0.502c2.199,0.344,4.275,0.875,6.25,1.598c1.344,0.48,2.411,1.029,3.179,1.65 c0.783,0.617,1.17,1.209,1.17,1.768c0,0.627-0.387,1.123-1.17,1.492c-0.769,0.371-1.818,0.564-3.13,0.564
		c-0.728,0-1.439-0.068-2.091-0.211c-0.675-0.141-1.336-0.357-1.992-0.658c-0.332-0.154-0.707-0.375-1.12-0.686
		c-0.401-0.307-0.698-0.518-0.872-0.607c-0.956-0.527-2.084-0.922-3.405-1.182c-1.305-0.254-2.811-0.385-4.504-0.385
		c-3.559,0-6.364,0.627-8.408,1.895c-2.042,1.26-3.074,3-3.074,5.227c0,2.273,1.031,4.029,3.084,5.27 c2.059,1.244,4.99,1.857,8.78,1.857c1.673,0,3.183-0.127,4.509-0.377c1.336-0.24,2.417-0.605,3.251-1.086 c0.333-0.195,0.686-0.438,1.013-0.732c0.34-0.297,0.565-0.49,0.68-0.564c0.755-0.459,1.461-0.775,2.087-0.955 c0.649-0.174,1.365-0.254,2.181-0.254c1.21,0,2.241,0.18,3.084,0.561c0.846,0.375,1.272,0.838,1.272,1.377	c0,0.527-0.377,1.102-1.121,1.74c-0.744,0.623-1.73,1.207-2.961,1.73c-2.033,0.848-4.188,1.471-6.497,1.861	c-2.283,0.381-4.955,0.576-7.993,0.576c-6.382,0-11.412-0.951-15.077-2.85C308.18,324.26,306.344,321.654,306.344,318.348 L306.344,318.348z M393.852,309.617c0-0.775,0.375-1.35,1.118-1.729c0.741-0.377,1.875-0.566,3.389-0.566	c1.504,0,2.637,0.195,3.397,0.576c0.751,0.379,1.128,0.955,1.128,1.719v16.789c0,0.771-0.377,1.34-1.12,1.721 c-0.744,0.379-1.881,0.559-3.405,0.559c-1.495,0-2.616-0.184-3.378-0.57c-0.754-0.385-1.129-0.949-1.129-1.709V309.617 L393.852,309.617z M461.131,326.143c0,0.865-0.363,1.504-1.1,1.926c-0.744,0.406-1.865,0.617-3.37,0.617
		c-1.56,0-2.69-0.201-3.385-0.611c-0.709-0.396-1.063-1.051-1.063-1.932v-15.549c0-1.271,0.398-2.121,1.192-2.58
		c0.788-0.469,2.227-0.691,4.296-0.691c2.366,0,4.731,0.945,7.09,2.838c0.221,0.18,0.39,0.295,0.485,0.385l14.221,11.373v-12.053	c0-0.859,0.366-1.508,1.097-1.914c0.723-0.422,1.854-0.629,3.376-0.629c1.532,0,2.652,0.201,3.377,0.619
		c0.713,0.404,1.064,1.055,1.064,1.924v15.609c0,1.211-0.362,2.051-1.082,2.514c-0.725,0.465-2.002,0.697-3.797,0.697
		c-1.599,0-2.852-0.133-3.77-0.391c-0.912-0.27-2.015-0.906-3.305-1.941l-15.328-12.23V326.143L461.131,326.143z M567.922,320.727 h-6.738c-1.146,0-2.089-0.17-2.788-0.512c-0.696-0.338-1.052-0.781-1.052-1.346c0-0.521,0.34-0.934,1.025-1.244
		c0.678-0.301,1.625-0.459,2.814-0.459h9.725c1.953,0,3.307,0.141,4.087,0.432c0.778,0.291,1.165,0.803,1.165,1.52v7.959
		c0,0.645-0.263,1.125-0.762,1.43c-0.508,0.301-1.308,0.449-2.402,0.449c-0.876,0-1.596-0.127-2.152-0.359
		c-0.545-0.238-0.949-0.59-1.162-1.055l-0.681-1.572c-1.659,1.049-3.524,1.814-5.592,2.316c-2.081,0.48-4.512,0.729-7.292,0.729 c-6.155,0-11.148-1.008-14.945-3.029c-3.793-2.02-5.684-4.674-5.684-7.975c0-3.34,1.891-5.992,5.676-7.971
		c3.789-1.982,8.854-2.969,15.196-2.969c2.046,0,3.974,0.094,5.784,0.285c1.805,0.184,3.485,0.459,5.033,0.828
		c2.17,0.537,3.849,1.145,5.037,1.83c1.193,0.686,1.791,1.387,1.791,2.088c0,0.59-0.383,1.092-1.145,1.488
		c-0.762,0.391-1.705,0.596-2.824,0.596c-0.957,0-1.78-0.1-2.49-0.289c-0.699-0.186-1.564-0.576-2.574-1.156
		c-1.189-0.723-2.426-1.244-3.699-1.545s-2.788-0.449-4.523-0.449c-3.688,0-6.58,0.645-8.698,1.932
		c-2.112,1.281-3.169,3.053-3.169,5.305c0,2.26,1.094,4.02,3.297,5.307c2.188,1.287,5.189,1.938,8.99,1.938
		c2.855,0,5.248-0.408,7.176-1.203C566.273,323.215,567.471,322.123,567.922,320.727L567.922,320.727z"
    />
    





    

		</svg>
		</div>

	);//return
}