
import React, { 

    useState, 
    useEffect, 
    //useRef,
    //useMemo

} from 'react';


import 'App.css';

import NavA from './A';
import NavB from './B';
import NavC from './C';


const Index = (props) => {


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false)  

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }

    },[
    
        props,
        isLoaded

    ])

    
    // TM - this page renders the requested Header using "headerCall" variable


    return (
    
        <div>
        {
        props.databind.headerCall === 'A'
        ?
            <NavA 

                databind = {props.databind}
            />
        :
        props.databind.headerCall === 'B'
        ?
            <NavB 
            
                databind = {props.databind}
            />
        :
        props.databind.headerCall === 'C'
        ?
            <NavC 
            
                databind = {props.databind}
            
            />

        :
        null
        }
        </div>


    );

}

export default Index;



