

const Settings = {

    ssg:false,

    bullet:{

        colors: [

            "#20b2aa"


        ]


    },

    pieChart:{

        /*
        Original
        data: {
                        
            "Classical Music": 10,
            "Alternative Rock": 14,
            "Pop": 20,
            "Jazz": 12,
            "Hard Rock": 30,
            "LeftOver":14
        },
        */



        /*
        data: [ 

            {
                name:"Classical Music",
                value:10
            },
            {
                name:"Alternative Rock",
                value:20
            },    
            {
                name:"Pop",
                value:30
            },
            {
                name:"Jazz",
                value:10
            },
            {
                name:"Hard Rock",
                value:16
            },
            {
                name:"Heavy Metal",
                value:14
            }

        ],

        colors: [
            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"
        ],
        hole:{
            color:"#181b21",
            outlineColor:"#181b21",
            textColor:"white"
        }

        */

        
        items: [ 

            {
                name:"Classical Music",
                value:10
            },
            {
                name:"Alternative Rock",
                value:20
            },    
            {
                name:"Pop",
                value:30
            },
            {
                name:"Jazz",
                value:10
            },
            {
                name:"Hard Rock",
                value:16
            },
            {
                name:"Heavy Metal",
                value:14
            }

        ],

        colors: [
            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00",
            "lightblue",
            "lightgreen",
            "yellow",
            "pink",
            "lavender"
        ],
        hole:{
            color:"#181b21",
            outlineColor:"#181b21",
            textColor:"white"
        }






    },


    barChart:{

        /*
        Original
        data: {
                        
            "Classical Music": 10,
            "Alternative Rock": 14,
            "Pop": 20,
            "Jazz": 12,
            "Hard Rock": 30,
            "LeftOver":14
        },
        */

        name: "Test Bar Graph",
        barPadding          :20,
        gridScaleMax        :30,
        gridScaleIncr       :10,
        gridColor           :"#999",
        

        
        items: [ 

            {
                name:"Classical Music",
                value:10
            },
            {
                name:"Alternative Rock",
                value:20
            },  
            
            
            {
                name:"Pop",
                value:30
            },
            {
                name:"Jazz",
                value:10
            },

            
            {
                name:"Hard Rock",
                value:16
            },
            {
                name:"Heavy Metal",
                value:14
            }
            

        ],
        colors: [
            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"
        ],

        keyStyle:{

            backgroundColor :"white",
            border          :"1px solid #999",
            margin          :"0 0 0 0",
            textColor       :"black"

        }
        

        

    
    }





}




const Lorem = "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque.";

const SpecialImages = [


    {
        position: 1,

        id: Math.random(),
        title: "Logo Band",
        text: Lorem,
        bgColor: "#9FEACD",

        image:{

            src:"/_resources/specials/logoband.jpg",
            w:"150",
            h:"150"

        }


    },

    
    {
        position: 2,

        id: Math.random(),
        title: "Wide Image",
        text: Lorem,
        bgColor: "#9FEACD",

        image:{

            src:"/_resources/specials/wide_tomsvw.jpg",
            w:"150",
            h:"150"

        }


    }



];



const TestImages = [

    {

        position: 1, 

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "palegreen",

        image:{

            src: "/_resources/images/raceaway.jpg",
            w:"150",
            h:"150"

        }
    },


    {

        position: 2,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "antiquewhite",

        image:{

            src:"/_resources/images/ferrari-red-backlight.jpg",
            w:"150",
            h:"150"

        }


    },



    {

        position: 3,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "gainsboro",

        image:{

            src:"/_resources/images/engine.jpg",
            w:"150",
            h:"150"

        }


    },
    {
        position: 4,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "lavender",

        image:{

            src:"/_resources/images/ferrari-red-bw.jpg",
            w:"150",
            h:"150"

        }


    },



    
    {

        position: 5, 

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "lemonchiffon",

        image:{

            src:"/_resources/images/ferrari-snow.jpg",
            w:"150",
            h:"150"

        }
    },


    {

        position: 6,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "lightcyan",

        image:{

            src:"/_resources/images/ferrari-fender-logo.jpg",
            w:"150",
            h:"150"

        }


    },


    {

        position: 7,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "mistyrose",

        image:{

            src:"/_resources/images/ferrari-frontlight.jpg",
            w:"150",
            h:"150"

        }


    },



    {
        position: 8,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "plum",

        image:{

            src:"/_resources/images/ferrari-towards.jpg",
            w:"150",
            h:"150"

        }


    },




    {
        position: 9,

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "powderblue",

        image:{

            src:"/_resources/images/formula1.jpg",
            w:"150",
            h:"150"

        }


    },





    
    {

        position: 10, 

        id: Math.random(),
        title: "Ferrari",
        text: Lorem,
        bgColor: "tan",

        image:{

            src:"/_resources/images/wheel.jpg",
            w:"150",
            h:"150"

        }
    }



];






const NavigationData =  {


    //=======================================
    //controls
    //=======================================

    "controls":[


        {

            "to"    :"EXIT",    
            "name"  :"<< Back 2 Examples",
            "descr" :"Examples"

        },


        {

            "to"    :"",    
            "name"  :"[ Reactr Home ]",
            "descr" :"Reactr Home"

        },




        /*

        {

            "to"    :"LandingPage/",    
            "name"  :"Landing Page",
            "descr" :"Various landing page examples"
  
        },
        {

            "to"    :"counter/",     
            "name"  :"Counter",
            "descr" :"Redux Test Counter"
  

        }

        */
        
    ],






    //=======================================
    //AuthArea
    //=======================================

    "AuthArea":[


        {

            "name"  :"Home",
            "to"    :"AuthArea"

        },

        {

            "name"  :"Page 1",
            "to"    :"AuthArea/Page1"

        },

        {

            "name"  :"Logout",
            "to"    :"AuthArea/Logout"

        },



        {

            "name"  :"Login Form",
            "to"    :"AuthArea/FormLogin"

        },

        {

            "name"  :"Forgot Form",
            "to"    :"AuthArea/FormForgot"

        },


        {

            "name"  :"Sign Up Form",
            "to"    :"AuthArea/FormSignup"

        },

    
        {

            "name"  :"Form Sample",
            "to"    :"AuthArea/FormSample"

        }


        
    ],









    //=======================================
    //Dashboard
    //=======================================


    "Dashboard": [


        {

            "name"  :"Home",
            "to"    :"Dashboard"

        },
        {

            "name"  :"Company",
            "to"    :"Dashboard/Company"

        },
        {

            "name"  :"Overhead",
            "to"    :"Dashboard/Overhead"

        },
        {

            "name"  :"Owners",
            "to"    :"Dashboard/Owners"

        },
        {

            "name"  :"Employees",
            "to"    :"Dashboard/Employees"

        },
        {

            "name"  :"Expenses",
            "to"    :"Dashboard/Expenses"

        },
        {

            "name"  :"Results",
            "to"    :"Dashboard/Results"

        },



        {

            "name"  :"Editor",
            "to"    :"Dashboard/Editor"

        },
    
        {

            "name"  :"Form Sample",
            "to"    :"Dashboard/FormSample"

        },

        {

            "name"  :"Form Page",
            "to"    :"Dashboard/FormPage"

        }



        /*
        {

            "name"  :"Indirect Labor Employees",
            "to"    :"Dashboard/Idls"

        },
        {

            "name"  :"Form Overhead",
            "to"    :"Dashboard/FormOverhead"

        },

        {

            "name"  :"Form EmployeeRate",
            "to"    :"Dashboard/FormEmployeeRate"

        },
        */







       

    ],






    //=======================================
    //Landing Page
    //=======================================


    "LandingPage": [

        {

            "name"  :"Home",
            "to"    :"LandingPage"

        },

        {

            "name"  :"A Page",
            "to"    :"LandingPage/Apage"

        },

        {

            "name"  :"B Page",
            "to"    :"LandingPage/Bpage"

        },

        {

            "name"  :"C Page",
            "to"    :"LandingPage/Cpage"

        }



    ],










    //=======================================
    //Template01
    //=======================================


    "Template01": [

        {

            "name"  :"Home",
            "to"    :"Template01"

        },



        {

            "name"  :"Text Page",
            "to"    :"Template01/TextPage"

        },


        {

            "name"  :"Image Gallery",
            "to"    :"Template01/ImageGallery"

        },


        {

            "name"  :"Video Gallery",
            "to"    :"Template01/VideoGallery"

        },

        {

            "name"  :"Page Gallery",
            "to"    :"Template01/PageGallery"

        },


        {

            "name"  :"PageNSide",
            "to"    :"Template01/PageNSide"

        },


        {

            "name"  :"Billboards",
            "to"    :"Template01/Billboards"

        }





    ],




    //=======================================
    //TrialPage
    //=======================================


    "TrialPage": [

        {

            "name"  :"Home",
            "to"    :"TrialPage"

        },



        {

            "name"  :"Text Page",
            "to"    :"Trialpage/TextPage"

        },


        {

            "name"  :"Image Gallery",
            "to"    :"TrialPage/ImageGallery"

        },


        {

            "name"  :"Video Gallery",
            "to"    :"TrialPage/VideoGallery"

        },

        {

            "name"  :"Page Gallery",
            "to"    :"TrialPage/PageGallery"

        }






    ],











}




const TestVideos = [


    {
        position: 1,

        id: Math.random(),
        title: "Van Halen - Jump",
        text: Lorem,
        bgColor: "#9FEACD",

        image:{

            src     :'https://img.youtube.com/vi/SwYN7mTi6HM/0.jpg',
            w       :120,
            h       :90

        },

        video:{

            type    :"external",
            site    :"youtube",
            id      :"SwYN7mTi6HM",
            url     :""
        }




    },

    
    {
        position: 2,

        id: Math.random(),
        title: "Neffron",
        text: Lorem,
        bgColor: "#9FEACD",

        image:{

            src:"https://powerdigitalmedia-com.s3.amazonaws.com/admin/inventory/vid/neffron_sys20200330137028id_.jpg",
            w:"150",
            h:"150"

        },

        video:{

            type    :"internal",
            site    :"",
            id      :"",
            src     :"https://powerdigitalmedia-com.s3.amazonaws.com/admin/inventory/vid/neffron_sys20200330137028id_.mp4"
        }




    }



];









export {

    Settings,
    Lorem,
    TestImages,
    TestVideos,
    SpecialImages,
    NavigationData

};

