import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

import {

    //FScomplete, 
    FSerror,
    FSloading,
    FSprocess

} from 'utils/components/Form/FormSubmit';

import Messngr from 'utils/components/Process/Messngr';
import Readr from 'utils/components/Process/Readr';

import { SIform, SIresult } from './SceneInfo';

const Index = (props) => {


    // -- window dims -- //
    //const { width, height } = useWindowDimensions();



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    const currentFlyr = zoneObj.currentFlyr;
    var formArr = [];
    var problemArr = [];

    var runSubmit;
    var runComplete;
    var runError;
    var errorType;

    var runAccount;
    var runCalculate;
    var runOutput;
    var output;
    var postTo;
    // var currentKey;
    var completeMessage;


    formArr             = zoneObj[currentFlyr + "_formArr"];
    problemArr          = zoneObj[currentFlyr + "_problemArr"]; 

    runSubmit           = zoneObj[currentFlyr + "_runSubmit"];
    runComplete         = zoneObj[currentFlyr + "_runComplete"];
    runError            = zoneObj[currentFlyr + "_runError"];
    errorType           = zoneObj[currentFlyr + "_errorType"];

    runAccount          = zoneObj[currentFlyr + "_runAccount"];
    runCalculate        = zoneObj[currentFlyr + "_runCalculate"];
    runOutput           = zoneObj[currentFlyr + "_runOutput"];
    output              = zoneObj[currentFlyr + "_output"];

    postTo              = zoneObj[currentFlyr + "_postTo"];
    // currentKey          = zoneObj[currentFlyr + "_currentKey"];

    completeMessage     = zoneObj[currentFlyr + "_completeMessage"];



    if(Object.prototype.toString.call(problemArr).match(/undefined/gi))
    {
        problemArr = [];
    }
    //console.log("PROBLEM ARR: "+JSON.stringify(problemArr,null,2));

    if(Object.prototype.toString.call(postTo).match(/undefined/gi))
    {
        postTo = false;
    }
    //console.log("POST TO: "+postTo);


    if(Object.prototype.toString.call(completeMessage).match(/undefined/gi))
    {
        completeMessage = "Update Successfull!!";
    }
    //console.log("COMPLETE MESSAGE: "+completeMessage);




    //console.log("OUTPUT: "+JSON.stringify(output,null,2));



    var completeMsg;
    completeMsg = [

        {
            "call":"heading",
            "text":"Success" 
        },
        {
            "call":"span",
            //"text":"Update was Successfull!!" 
            "text":completeMessage
        }

    ];



    if(output !== undefined)
    {
        var scene;
        //var array;
        var object;
        //var item;

        var readr_data;
        var readr_value;

    
        var keyname;
        var html;
        var str;


        /*
        var ak;
        var bk;
        var ck;
        //var dk;

        var objA;
        var objB;
        var objC;
        var objD;
        */
        



        switch(output.call)
        {
        case'cards':

            readr_value = [];

            scene = SIform({
                form_items: output.data.formItems,
                result_items: output.data.resultItems
            });

            for(object of scene)
            {
                if(object.keyname !== undefined)
                {
                    readr_value.push({
                        
                        name:object.name,
                        value:object.value
                    
                    });

    
                    
                }
            }



            scene = SIresult({
                result_items: output.data.resultItems
            });

            for(object of scene)
            {
                if(object.keyname !== undefined)
                {
                    readr_value.push({
                        
                        name:object.name,
                        value:object.value
                    
                    });
                    
                }
            }






            /*

            for(keyname in output.data)
            {
                console.log("")
                console.log("===============")
                console.log(keyname);
                console.log("===============")

                objA = output.data[keyname];

                //sonstruct if string
                if(Object.prototype.toString.call(objA) === '[object String]')
                {

                    console.log("A...  "+objA)
        
                }
                else
                {


                    for(ak in objA)
                    {
                        objB = objA[ak];

                        //construct if string
                        if(Object.prototype.toString.call(objB) === '[object String]')
                        {
                            console.log("B... "+ak+": "+objB)


                            readr_value[ak] = objB;

                        }
                        else
                        {

                            console.log("")
                            console.log("--------------")
                            console.log(ak);
                            console.log("--------------")


                            for(bk in objB)
                            {

                                objC = objB[bk];
                             
                 
                        
                                //construct if string
                                if(Object.prototype.toString.call(objC) === '[object String]')
                                {
                                    console.log("C... "+bk+": "+objC)

                                    //readr_value[ck] = objC;


                
                                }
                                else
                                {

                                    for(ck in objC)
                                    {

                                        objD = objC[ck];

                                        //construct if string
                                        if(Object.prototype.toString.call(objD) === '[object String]')
                                        {
                                            console.log("D... "+ck+": "+objD);

                                            //readr_value[ck] = objD


                                        }
                                        else
                                        {

                                            console.log("D...");
                                            console.log(JSON.stringify(objD,null,2));

                                            //readr_value[ck] = objD;

                                        }





                                    }


                                }




                            }


                        }


                    }
     
                }
                

            }

            */

            

            readr_data = {
                data:{
                    name:"Output",
                    value:readr_value,
                    actions:{
                        add:false,
                        delete:false,        
                        edit:false,
                        chart:false
                    }
                },
                flyr:{
                    type:'cards',
                    styl:{
                        maxWidth:750
                    }
                }
            }


        break;
        default:


            //&nbsp; 
            //&thinsp; 
            //&ensp;
            //&emsp;


            //readr_value = JSON.stringify(output.data,null,2);
            //readr_value = readr_value.replace(/\n/gi,"<br/>&nbsp;");



            html = '';

            for(keyname in output.data)
            {
                console.log("")
                console.log("===============")
                console.log(keyname);
                console.log("===============")


                html += "<h2>"+keyname+"</h2>";

                str = output.data[keyname];

                if(Object.prototype.toString.call(str) === '[object Object]' 
                || Object.prototype.toString.call(str) === '[object Array]'
                )
                {
                    str = JSON.stringify(str,null,2);
                    str = str.replace(/\n/gi,"<br/>&nbsp;");
                }

                html += str;
    



                /*
                //sonstruct if string
                if(Object.prototype.toString.call(objA) === '[object String]')
                {

                    console.log("A...  "+objA)
        
                }
                else
                {


                    for(ak in objA)
                    {
                        objB = objA[ak];

                        //construct if string
                        if(Object.prototype.toString.call(objB) === '[object String]')
                        {
                            console.log("B... "+ak+": "+objB)


                            readr_value[ak] = objB;

                        }
                        else
                        {

                            console.log("")
                            console.log("--------------")
                            console.log(ak);
                            console.log("--------------")


                            for(bk in objB)
                            {

                                objC = objB[bk];
                             
                 
                        
                                //construct if string
                                if(Object.prototype.toString.call(objC) === '[object String]')
                                {
                                    console.log("C... "+bk+": "+objC)

                                    //readr_value[ck] = objC;


                
                                }
                                else
                                {

                                    for(ck in objC)
                                    {

                                        objD = objC[ck];

                                        //construct if string
                                        if(Object.prototype.toString.call(objD) === '[object String]')
                                        {
                                            console.log("D... "+ck+": "+objD);

                                            //readr_value[ck] = objD


                                        }
                                        else
                                        {

                                            console.log("D...");
                                            console.log(JSON.stringify(objD,null,2));

                                            //readr_value[ck] = objD;

                                        }





                                    }


                                }




                            }


                        }


                    }
     
                }

                */
                

            }

            
            readr_value = html;


            readr_data = {
                data:{
                    name:"Output",
                    value:readr_value
                },
                flyr:{
                    type:'print',
                    styl:{
                        maxWidth:750
                    }
                }
            }


        break;
        }

    }











    //---------------------------------------------
    // state/effect - view zoneOBj
    //---------------------------------------------


    // -- state -- //
    const [zoneWasViewed, setZoneWasViewed] = useState(false);

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(!zoneWasViewed)
        {
            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            // console.log("===== zoneObj =====");
            // console.log(JSON.stringify(zoneObj,null,2));

            setZoneWasViewed(true);




            // alert("components/Flyr/Comps/Form/FormFly/ variables...."
        
            //     +"\nrunAccount: "+runAccount
            //     +"\nrunCalculate: "+runCalculate
            //     +"\nrunSubmit: "+runSubmit
            //     +"\nrunComplete: "+runComplete
            //     +"\nrunError: "+runError
            //     +"\nerrorType: "+errorType
            //     +"\nrunOutput: "+runOutput
            //     +"\noutput: "+output
            
            // )


    
        }else{

            //Delay before starting animation
            // var timer;
            // timer = setTimeout(() => {

            //     setZoneWasViewed(false);

            // }, 500);
            // return () => clearTimeout(timer);

        } 




    },
    [

        zoneObj,
        zoneWasViewed,

        runSubmit,      
        runComplete,       
        runError,    
        errorType,  
    
        runAccount,
        runCalculate,      
        runOutput,    
        output,            
    

    ])










    return (

        <div>


        {runOutput
        ? 
            
            <Readr 

                handle={(v) => props.handle(v)}
                data={readr_data}
            
            />


        : null}


        {runSubmit
        ? 

            <div>

                <FSloading
                    formArr={formArr}
                    handle={(v) => props.handle(v)}   
                /> 

                <FSprocess
                    postTo={postTo}
                    formArr={formArr}
                    handle={(v) => props.handle(v)}   
                /> 

            </div>


        : null}


        {runError
        ? 

            <FSerror
                errorType={errorType}
                errorArr={problemArr}
                handle={(v) => props.handle(v)}   
            /> 

        : null}


        {runComplete
        ? 

            <Messngr 

                handle={(v) => props.handle(v)}
                data={{

                    isError         : false,
                    errorType       : '',
                    isTimed         : true,
                    timedDuration   : 1200,
                    items           : completeMsg

                }}
            
            />


        : null}


        </div>

                
    );



}

export default Index;



