import React, { 
    useState, 
    useEffect, 
    //useRef 
} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {
    MediaColumn,
    MediaHeading,
    MediaPgraph,
    MediaList,
    MediaNameVal,
    MediaLink,
    MediaButton,

    MediaHashList,
    MediaHashSection,

    MediaDynamicText,
    MediaMathLedger,
    MediaBreaker,
    MediaChecklist,
    MediaNotice,   
    MediaHeadline,
        MediaHeadlineText,


} from '../Styled/Media';


import '../StyleSheets/Anchor.css';
import '../StyleSheets/Checklist.css';
import '../StyleSheets/HashLinx.css';


import CreateMarkup from 'utils/components/Universal/CreateMarkup';
import CreateLink from 'utils/components/Universal/CreateLink';

import DefaultFetchResponse from 'utils/components/Pages/Default/FetchResponse';





const AllText = (props) => {


 
    const items = props.items;
    //const columnClass = props.columnClass;


    var headingStyl = false;
    var pgraphStyl = false;
    var buttonStyl = {

        mainWidth:false,
        position:false,

        backgroundColorOver:false,
        backgroundColorOut:false,

        borderRadius:false,
        borderColorOver:false,
        borderColorOut:false,

        textDecoration:false,
        textColorOver:false,
        textColorOut:false,

        fontSize:false,
        fontWeight:false


    };
    var linkStyl = {

        mainWidth:false,
        position:false,

        textDecoration:false,
        textColorOver:false,
        textColorOut:false,

        fontSize:false,
        fontWeight:false

    };
    if(props.info.compStyl)
    {
        if(props.info.compStyl.heading) headingStyl = props.info.compStyl.heading;
        if(props.info.compStyl.pgraph) pgraphStyl = props.info.compStyl.pgraph;
        if(props.info.compStyl.button)
        {
            buttonStyl = props.info.compStyl.button;
        }
        if(props.info.compStyl.link)
        {
            linkStyl = props.info.compStyl.link;
 
        }



    }




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");





    // -- ref -- //
    // const refA = useRef(null);
    // const refB = useRef(null);
    // const refC = useRef(null);



    //==========================================
    // state/effect - isLoaded, settings
    //==========================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [settings, setSettings] = useState(false);

    useEffect(() => {

        var resp;
        var sett;
        if(!isLoaded)
        {
            setIsLoaded(true);


            //----------------------------------
            // Default Settings 
            //----------------------------------
            
            if(zoneObj["settings"])
            {
                if(!settings)
                {
                    
                    resp = zoneObj["settings"];
                    if(resp )
                    {
                        sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
                        //console.log(JSON.stringify(sett));
                        setSettings(sett);
                    }

                }

            }else{

                //console.log("headerNav: "+JSON.stringify(DefaultFetchResponse.settings.headerNav));
                //alert('hold')

                setSettings(DefaultFetchResponse.settings);
            }


            // setSettings(DefaultFetchResponse.settings);


            // console.log("--------"+JSON.stringify(DefaultFetchResponse.settings,null,2));
          
        }


    },[

        props,
        zoneObj,
        isLoaded,
        settings
    ])







    //==========================================
    // state/effect - sizes
    //==========================================

    const [nvRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
    const setNVRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < nvRefs.length; i++)
            {
                if(nvRefs[i].id === ref.id) found = true;
            }
            if(!found) nvRefs.push(ref);
            //console.log(nvRefs.length);
        }

    };
    const [nvSizes, setNVSizes] = useState([]);


    useEffect(() => {


        if(nvRefs.length > 0)
        {
            //var indx;
            var ref;
            var maxWidth = 0;
            var array = [];
            var object;
            for(var i=0; i < nvRefs.length; i++)
            {
                object = {};
                if(nvRefs[i].id.match(/nvLeft/gi))
                {
                    //indx = nvRefs[i].id.split("-")[1];
                    ref = nvRefs[i];
                    //console.log(ref.offsetWidth);

                    if(maxWidth === 0) maxWidth = ref.clientWidth;
                    if(ref.clientWidth > maxWidth) maxWidth = ref.clientWidth;
                } 

                if(nvRefs[i].id.match(/nvRight/gi))
                {
                    //indx = nvRefs[i].id.split("-")[1];
                    ref = nvRefs[i];
                    //console.log(ref.clientHeight);
                    object['height'] = ref.clientHeight+"px";

                } 
                array.push(object);

            }
            // alert(maxWidth);
            // var sizes = [];
            // for(object of array)
            // {
            //     object.width = maxWidth+"px";
            //     sizes.push(object);
            // }
            //console.log(JSON.stringify(array,null,2));
            //alert(JSON.stringify(array));
            setNVSizes(array);

  
        }
    


    },[

        nvRefs,
        isLoaded
    ])










    //==========================================
    // state/effect - style
    //==========================================

    const [doStyle, setDoStyle] = useState(false);
    const [styl, setStyl] = useState({

        heading:{
            fontFamily:false,
            fontSize:false,
            fontWeight:false,
            textAlign:false,
            textDecoration:false,
            color:false
        },
        pgraph:{
            fontFamily:false,
            fontSize:false,
            fontWeight:false,
            textAlign:false,
            textDecoration:false,
            color:false
        },
        button:{},
        link:{}

    });

    useEffect(() => {


        // -- effect -- //
 
        const effectStyle = (eff) => {


            //console.log("Content: eff "+JSON.stringify(eff,null,2));

            // {
            //     "call": "style",
            //     "data": {
            //         "button": {
            //             "backgroundColorOver": "#fc0",
            //             "backgroundColorOut": "#0291cd",
            //             "borderOver": "1px solid black",
            //             "borderOut": "1px solid #0291cd",
            //             "textColorOVer:"black",
            //             "textColorOut": "white"
            //         },
            //         "link": {
            //             "textColorOver": "orange",
            //             "textColorOut": "#0291cd"
            //         }
            //     }
            // }


            var itm;
            var key;
            for(itm of eff.props.items)
            {
                if(itm.call === 'style')
                {
                    for(key in itm.data)
                    {
                        styl[key] = itm.data[key];
                    }
                }

            }
            setStyl(styl);




        }


        // -- cause -- //

        //console.log('location: '+JSON.stringify(location,null,2));

        if(isLoaded)
        {
            if(!doStyle)
            {
                effectStyle({

                    props:props,
                    styl:styl

                });
                setDoStyle(true);
            } 

        }

    },
    [
        props,
        isLoaded,
        doStyle,
        styl
    ])








    //==========================================
    // handle
    //==========================================

    const handle = (hndl) => {

        //alert(JSON.stringify(hndl));
        switch(hndl.call)
        {
        case'url':

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxHost = new RegExp(window.location.host,"gi");
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }//==
            else
            if(!url.match(regxHost))
            {
                externalUrl = true;
            }//==
            //alert(url);



            if(externalUrl)
            {
                props.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }else{

                props.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }




        break;
        // case'HashLink':

        //     //alert(hndl.data);
        //     history.push(hndl.data);
        //     //window.location.href = hndl.data;
        // break;
        case'handleHash':

            //HashButtonClick(hndl);
        break;
        default:
        }

    }



    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl));

    //     //console.log(JSON.stringify(hndl,null,2));

    //     if(hndl.id.match(/_arrowButton/gi))
    //     {

    //         //var btn;
    //         var arrowBg;
    //         var arrow1;
    //         //var arrow2;

    //         //btn = document.getElementById(hndl.id);
    //         //btn.style.borderColor = hndl.borderColor;

    //         arrowBg = document.getElementById(hndl.id+"_bg");
    //         arrowBg.style.backgroundColor = hndl.bgColor;


    //         if(hndl.id.match(/_arrowButtonDown/gi))
    //         {
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderTopColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderTopColor = hndl.arrowColor;

    //         }
    //         else
    //         if(hndl.id.match(/_arrowButtonUp/gi))
    //         {
    
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderBottomColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderBottomColor = hndl.arrowColor;
    //         }
    //         else
    //         {

    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderTopColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderTopColor = hndl.arrowColor;

    //         }



        
    //     }



    // }










    if(!isLoaded)
    {

     

        return (

            <MediaColumn>

                {items.map((item, i) => (

                    <div 
                    key={i}
                    className={"Row"}
                    >

                        {
                        item.call === 'cke'
                        ?
                    

                            item.data.map((cke, j) => (


                                <div
                                key={j}
                                >

                                    {
                                    cke.call === 'heading' 
                                    ?
                    
                                        headingStyl
                                        ?

                                            <MediaHeading
                                            fontFamily={headingStyl.fontFamily}
                                            fontSize={headingStyl.fontSize}
                                            fontWeight={headingStyl.fontWeight}
                                            textAlign={headingStyl.textAlign}
                                            textDecoration={headingStyl.textDecoration}
                                            color={headingStyl.color}
                                            >

                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />


                                            </MediaHeading>

                                        :

                                            <MediaHeading>

                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />

                                            </MediaHeading>


                                    :null
                                    }



                                    {
                                    cke.call === 'pgraph'
                                    ?
    
                                        pgraphStyl
                                        ?
                                            <MediaPgraph
                                            fontFamily={pgraphStyl.fontFamily}
                                            fontSize={pgraphStyl.fontSize}
                                            fontWeight={pgraphStyl.fontWeight}
                                            textAlign={pgraphStyl.textAlign}
                                            textDecoration={pgraphStyl.textDecoration}
                                            color={pgraphStyl.color}
                                            >

                                
                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />


                                            </MediaPgraph>

                                        :

                                            <MediaPgraph>

                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />

                                            </MediaPgraph>


                                    :null
                                    }





                                    {
                                    cke.call === 'bullet'
                                    ?



                                        <MediaList>
                                        {cke.data.map((dat, k) => (

                                            <div
                                            key={k}
                                            className={"Group"}
                                            >
    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div 
                                                    className={"Bullet"}
                                                    >
                                                        <div 
                                                        className={"Circle"}
                                                        >
                                                        </div>
                                                        
                                                    </div>

                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    <div 
                                                    className={"Value"}
                                                    >

                                                        {dat}

                                                    </div>

                                                </div>

            
                                            </div>
            
        
                                        ))}
                                        </MediaList>

            
                                    :null
                                    }



                                    {
                                    cke.call === 'numbrd'
                                    ?

                                        <MediaList>
                                        {cke.data.map((dat, k) => (

                                            <div
                                            key={k}
                                            className={"Group"}
                                            >
                    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div 
                                                    className={"Number"}
                                                    >
        
                                                        {(k+1)+")"}
        
                                                    </div>

                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    <div 
                                                    className={"Value"}
                                                    >

                                                        {dat}

                                                    </div>

                                                </div>


                                            </div>

                                    
                                        ))}
                                        </MediaList>
            


                                    :null
                                    }


                                </div>

   

                            ))


                        :null
                        }




                        {
                        item.call === 'namevals'
                        ?
                        
                            item.data.map((dat, j) => (

                                <div
                                key={j}
                                >
                                    <MediaNameVal
                                    //dynaWidth={nvSizes[j].width}
                                    //dynaHeight={nvSizes[j].height}
                                    >

                                        <div 
                                        ref={setNVRef}
                                        id={"nvLeft-"+j}
                                        className={"Left"}
                                        >

                                            <div 
                                            className={"Name"}
                                            >

                                                {dat.name}

                                            </div>

                                    
                                        </div>

                                        <div 
                                        ref={setNVRef}
                                        id={"nvRight-"+j}
                                        className={"Right"}
                                        >
                                            <div 
                                            className={"Value"}
                                            >

                                                {dat.value}

                                            </div>

                                        </div>


                                    </MediaNameVal>

                                </div>



                            ))




                            // <div className={StylColumn.Pgraph}>

                        
                            //     {"nameval"}


                            // </div>

                        
                        :null
                        }


                        {
                        item.call === 'links'
                        ?
                        
                            item.data.map((dat, j) => (

                                <div 
                                key={j}
                                >
                                    <MediaLink
                                    >
                                    {
                                    dat.position === 'left'
                                    ?
                                        <div 
                                        className={"LinkLeft"}
                                        >
        
                                            {dat.name}

                                        </div>

                                    :
                                    dat.position === 'center'
                                    ?          

                                        <div 
                                        className={"LinkCenter"}
                                        >
        
                                            {dat.name}

                                        </div>

                                    :
                                    dat.position === 'right'
                                    ?

                                        <div 
                                        className={"LinkRight"}
                                        >
        
                                            {dat.name}

                                        </div>


                                    :null
                                     }
                                    </MediaLink>
                                
                                </div>

                            ))



                            // <div className={StylColumn.Pgraph}>

                        
                            //     {"links"}


                            // </div>

                        
                        :null
                        }



                        {
                        item.call === 'buttons'
                        ?
                        
                            item.data.map((dat, j) => (

                                <div 
                                key={j}
                                >
                                    <MediaButton
                                    >
                                    {
                                    dat.position === 'left'
                                    ?
                                        <div 
                                        className={"BtnLeft"}
                                        >
        
                                            {dat.name}

                                        </div>

                                    :
                                    dat.position === 'center'
                                    ?          

                                        <div 
                                        className={"BtnCenter"}
                                        >
        
                                            {dat.name}

                                        </div>

                                    :
                                    dat.position === 'right'
                                    ?

                                        <div 
                                        className={"BtnRight"}
                                        >
        
                                            {dat.name}

                                        </div>


                                    :null
                                     }
                                    </MediaButton>
                                
                                </div>
                            ))


                            // <div className={StylColumn.Pgraph}>

                        
                            //     {"button"}


                            // </div>

                        
                        :null
                        }


                    </div>


                ))}

            </MediaColumn>
        

        )



    }else{




        return (



            <MediaColumn>

                {items.map((item, i) => (

                    <div 
                    key={i}
                    className={"Row"}
                    >

                        {
                        item.call === 'cke'
                        ?
                    

                            item.data.map((cke, j) => (


                                <div
                                key={j}
                                >

                                    {
                                    cke.call === 'heading' 
                                    ?
                    
                                        headingStyl
                                        ?

                                            <MediaHeading
                                            fontFamily={headingStyl.fontFamily}
                                            fontSize={headingStyl.fontSize}
                                            fontWeight={headingStyl.fontWeight}
                                            textAlign={headingStyl.textAlign}
                                            textDecoration={headingStyl.textDecoration}
                                            color={headingStyl.color}
                                            >

                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />


                                            </MediaHeading>

                                        :

                                            <MediaHeading>

                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />

                                            </MediaHeading>


                                    :null
                                    }



                                    {
                                    cke.call === 'pgraph'
                                    ?
    
                                        pgraphStyl
                                        ?
                                            <MediaPgraph
                                            fontFamily={pgraphStyl.fontFamily}
                                            fontSize={pgraphStyl.fontSize}
                                            fontWeight={pgraphStyl.fontWeight}
                                            textAlign={pgraphStyl.textAlign}
                                            textDecoration={pgraphStyl.textDecoration}
                                            color={pgraphStyl.color}
                                            >

                                
                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />


                                            </MediaPgraph>

                                        :

                                            <MediaPgraph>

                                                <div dangerouslySetInnerHTML={CreateMarkup(cke.data)} />

                                            </MediaPgraph>


                                    :null
                                    }






                                    {
                                    cke.call === 'bullet'
                                    ?



                                        <MediaList>
                                        {cke.data.map((dat, k) => (

                                            <div
                                            key={k}
                                            className={"Group"}
                                            >
    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div 
                                                    className={"Bullet"}
                                                    >
                                                        <div 
                                                        className={"Circle"}
                                                        >
                                                        </div>
                                                        
                                                    </div>

                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    <div 
                                                    className={"Value"}
                                                    >

                                                        {dat}

                                                    </div>

                                                </div>

            
                                            </div>
            
        
                                        ))}
                                        </MediaList>


                                    :null
                                    }



                                    {
                                    cke.call === 'numbrd'
                                    ?

                                        <MediaList>
                                        {cke.data.map((dat, k) => (

                                            <div
                                            key={k}
                                            className={"Group"}
                                            >
                    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div 
                                                    className={"Number"}
                                                    >
        
                                                        {(k+1)+")"}
        
                                                    </div>

                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    <div 
                                                    className={"Value"}
                                                    >

                                                        {dat}

                                                    </div>

                                                </div>


                                            </div>

                                    
                                        ))}
                                        </MediaList>
            

                                    :null
                                    }









                                    {
                                    cke.call === 'HashList'
                                    ?


                                        <MediaHashList
                                        id={"HashList"}
                                        
                                        backgroundColor={cke.data.box['background-color']}
                                        border={cke.data.box['border']}
                                        borderRadius={cke.data.box['border-radius']}
                                        
                                        width={cke.data.box['width']}
                                        height={cke.data.box['height']}
                                        padding={cke.data.box['padding']}
                                        margin={cke.data.box['margin']}
                                        float={cke.data.box['float']}

                                        titleTextColor={cke.data.title['textColor']}
                                        titleFontSize={cke.data.title['fontSize']}

                                        linkOverColor={cke.data.link['overColor']}
                                        linkOutColor={cke.data.link['outColor']}

                                        bulletType={cke.data.bullet['type']}
                                        bulletWidth={cke.data.bullet['width']}
                                        bulletHeight={cke.data.bullet['height']}
                              

                                        >

                                        <div 
                                        className="ListTitle" 
                                        style={{fontSize:"24px"}}>
                                            {cke.data.title}
                                        </div>

                                        {cke.data.list.map((dat, k) => (


                                            <div
                                            key={k}
                                            className={"ListItem"}
                                            onClick={(v) => handle({

                                                call      :"HashLink",
                                                data      :dat.value

                                            })}
                                            >
                    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div className="Bullet">
                                                        <div className="Square">&nbsp;</div>
                                                    </div>
                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    {/* <a className="ListAnchor" href={dat.value}>{dat.name}</a> */}
                                            
                                                    <div
                                                    className="ListItemName"
                                                    >
                                                        {dat.name}
                                                    </div>

                                                </div>


                                            </div>

                                    
                                        ))}
                                        </MediaHashList>


                                    :null
                                    }


                                    {
                                    cke.call === 'HashSection'
                                    ?

                                        <MediaHashSection
                                        id={cke.data.id}

                                        backgroundColor={cke.data.bar['background-color']}
                                        border={cke.data.bar['border']}
                                        borderRadius={cke.data.bar['border-radius']}
                                        width={cke.data.bar['width']}
                                        height={cke.data.bar['height']}
                                        margin={cke.data.bar['margin']}
                                        padding={cke.data.bar['padding']}
                                        //loat={cke.data.bar['float']}

                                        >
                                            <div className="Title">

                                                {cke.data.title}

                                            </div>

                                            <div className="Button"
                                            onClick={(v) => handle({

                                                call      :"HashLink",
                                                data      :cke.data.href

                                            })}
                                            style={{

                                                top:cke.data.btn['top'],
                                                right:cke.data.btn['right']
                                            }}
                                            >
                                                {/* <a className="ButtonClick" href={cke.data.href}>T</a> */}

                                                <div className="arrowbtn">
                                                    <div className="arrowbox">
                                                        <div className="arrow-up">&nbsp;</div>
                                                    </div>
                                                </div>

                                            </div>
                                          
                                        </MediaHashSection>
            

                                    :null
                                    }


                                    {
                                    cke.call === 'Breaker'
                                    ?

                                        <MediaBreaker
                                        backgroundColor={cke.data['background-color']}
                                        border={cke.data['border']}
                                        width={cke.data['width']}
                                        height={cke.data['height']}
                                        padding={cke.data['padding']}
                                        margin={cke.data['margin']}
                                        float={cke.data['float']}
                                        >
                                        </MediaBreaker>
            

                                    :null
                                    }



                                    {
                                    cke.call === 'DynamicText'
                                    ?

                                        <MediaDynamicText
                                    
                                        backgroundColor={cke.data.box['background-color']}

                                        border={cke.data.box['border']}

                                        borderTop={cke.data.box['border-top']}
                                        borderRight={cke.data.box['border-right']}
                                        borderBottom={cke.data.box['border-bottom']}
                                        borderLeft={cke.data.box['border-left']}

                                        borderRadius={cke.data.box['border-radius']}

                                        width={cke.data.box['width']}
                                        height={cke.data.box['height']}
                                        padding={cke.data.box['padding']}
                                        margin={cke.data.box['margin']}
                                        float={cke.data.box['float']}

                                        >

                                            {cke.data.lines.map((dat, k) => (


                                                dat.type === 'raw'
                                                ?

                                                    <div
                                                    key={k}
                                                    className={"Line"}
                                                    style={{

                                                        backgroundColor:    dat['background-color'],
                                                        border:             dat['border'],
                                                        borderRadius:       dat['border-radius'],

                                                        width:              dat['width'],
                                                        height:             dat['height'],
                                                        padding:            dat['padding'],
                                                        margin:             dat['margin'],

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color'],
                                                        lineHeight:         dat['line-height']
                                                    
                                                    }}
                                                    >
                                                        <div dangerouslySetInnerHTML={CreateMarkup(dat.text)} />

                                                    </div>

                                                :


                                                    <div
                                                    key={k}
                                                    className={"Line"}
                                                    style={{

                                                        backgroundColor:    dat['background-color'],
                                                        border:             dat['border'],
                                                        borderRadius:       dat['border-radius'],

                                                        width:              dat['width'],
                                                        height:             dat['height'],
                                                        padding:            dat['padding'],
                                                        margin:             dat['margin'],

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color'],
                                                        lineHeight:         dat['line-height']
                                                    
                                                    }}
                                                    >
                                                        {dat['text']}

                                                    </div>


                                            ))}

                                        </MediaDynamicText>
            

                                    :null
                                    }




                                    {
                                    cke.call === 'MathLedger'
                                    ?

                                        <MediaMathLedger
                                    
                                        backgroundColor={cke.data.box['background-color']}

                                        border={cke.data.box['border']}

                                        borderTop={cke.data.box['border-top']}
                                        borderRight={cke.data.box['border-right']}
                                        borderBottom={cke.data.box['border-bottom']}
                                        borderLeft={cke.data.box['border-left']}

                                        borderRadius={cke.data.box['border-radius']}

                                        width={cke.data.box['width']}
                                        height={cke.data.box['height']}
                                        padding={cke.data.box['padding']}
                                        margin={cke.data.box['margin']}
                                        float={cke.data.box['float']}

                                        >

                                            {cke.data.lines.map((dat, k) => (


                                                dat.type === 'raw'
                                                ?

                                                    <div
                                                    key={k}
                                                    className={"Line"}
                                                    style={{

                                                        backgroundColor:    dat['background-color'],

                                                        border:             dat['border'],

                                                        borderTop:          dat['border-top'],
                                                        borderRight:        dat['border-right'],
                                                        borderBottom:       dat['border-bottom'],
                                                        borderLeft:         dat['border-left'],

                                                        borderRadius:       dat['border-radius'],


                                                        width:              dat['width'],
                                                        height:             dat['height'],
                                                        padding:            dat['padding'],
                                                        margin:             dat['margin'],

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color'],
                                                        lineHeight:         dat['line-height']
                                                    
                                                    }}
                                                    >
                                                        <div dangerouslySetInnerHTML={CreateMarkup(dat.text)} />

                                                    </div>

                                                :
                                                dat.type === 'text'
                                                ?


                                                    <div
                                                    key={k}
                                                    className={"Line"}
                                                    style={{

                                                        backgroundColor:    dat['background-color'],

                                                        border:             dat['border'],

                                                        borderTop:          dat['border-top'],
                                                        borderRight:        dat['border-right'],
                                                        borderBottom:       dat['border-bottom'],
                                                        borderLeft:         dat['border-left'],
                                    
                                                        borderRadius:       dat['border-radius'],

                                                        width:              dat['width'],
                                                        height:             dat['height'],
                                                        padding:            dat['padding'],
                                                        margin:             dat['margin'],

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color'],
                                                        lineHeight:         dat['line-height']
                                                    
                                                    }}
                                                    >
                                                        {dat['text']}

                                                    </div>




                                                :
                                                dat.type === 'ledgerline'
                                                ?


                                                    <div
                                                    key={k}
                                                    className={"LedgerLine"}
                                                    style={{

                                                        backgroundColor:    dat['background-color'],

                                                        border:             dat['border'],

                                                        borderTop:          dat['border-top'],
                                                        borderRight:        dat['border-right'],
                                                        borderBottom:       dat['border-bottom'],
                                                        borderLeft:         dat['border-left'],
                                    
                                                        borderRadius:       dat['border-radius'],

                                                        width:              dat['width'],
                                                        height:             dat['height'],
                                                        padding:            dat['padding'],
                                                        margin:             dat['margin'],

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color'],
                                                        lineHeight:         dat['line-height']
                                                    
                                                    }}
                                                    >

                                                        {
                                                        dat.highlight
                                                        ?

                                                            <div className={"LeftHighlight"}>
                                                                {dat.text.name}
                                                            </div>

                                                        :

                                                            <div className={"Left"}>
                                                                {dat.text.name}
                                                            </div>

                
                                                        }




                                                        {
                                                        dat.highlight
                                                        ?

                                                            <div className={"RightHightlight"}>
                                                                {dat.text.value}
                                                            </div>

                                                        :

                                                            <div className={"Right"}>
                                                                {dat.text.value}
                                                            </div>

                                                        }
                                                        
                                                        


                                                    </div>




                                                :null


                                            ))}

                                        </MediaMathLedger>
            

                                    :null
                                    }




                                    {
                                    cke.call === 'Checklist'
                                    ?

                                        <MediaChecklist

                                        backgroundColor={cke.data.box['background-color']}
                                        border={cke.data.box['border']}
                                        width={cke.data.box['width']}
                                        height={cke.data.box['height']}
                                        padding={cke.data.box['padding']}
                                        margin={cke.data.box['margin']}
                                        float={cke.data.box['float']}

                                        >
                                            
                                            <div 
                                            className="Title" 
                                            style={{fontSize:"24px"}}>
                                                {cke.data.title}
                                            </div>

                                            {cke.data.list.map((dat, k) => (


                                                <div key={k}
                                                className="Item"
                                                >
                                                    <div className="Left">
                                                    <div className="Bullet">
                                                    <div className="Circle">&nbsp;</div>
                                                    </div>
                                                    </div>

                                                    <div className="Right">
                                                    <div className="Value">{dat}</div>
                                                    </div>
                                                </div>


                                            ))}

                                        </MediaChecklist>


                                    :null
                                    }







                                    {
                                    cke.call === 'Headline'
                                    ?

                                        <MediaHeadline>

                                            {cke.data.lines.map((dat, k) => (


                                                <div key={k}>
                                                {dat.type === 'raw'
                                                ?

        
                                                    <MediaHeadlineText
                                                    
                                                        backgroundColor={dat['background-color']}
                                                        width={dat['width']}
                                                        height={dat['height']}
                                                        margin={dat['margin']}
                                                        padding={dat['padding']}
                                        
                                                        fontFamily={dat['font-family']}
                                                        fontSize={dat['font-size']}
                                                        fontWeight={dat['font-weight']}
                                                        fontStyle={dat['font-style']}
                                                        textAlign={dat['text-align']}
                                                        textDecoration={dat['text-decoration']}
                                                        color={dat['color']}
                                                        lineHeight={dat['line-height']}

                                                    >

                                                        <div dangerouslySetInnerHTML={CreateMarkup(dat.text)} />

                                                    </MediaHeadlineText>




                                                :



                                                    <MediaHeadlineText
                                                    
                                                        backgroundColor={dat['background-color']}
                                                        width={dat['width']}
                                                        height={dat['height']}
                                                        margin={dat['margin']}
                                                        padding={dat['padding']}
                                        
                                                        fontFamily={dat['font-family']}
                                                        fontSize={dat['font-size']}
                                                        fontWeight={dat['font-weight']}
                                                        fontStyle={dat['font-style']}
                                                        textAlign={dat['text-align']}
                                                        textDecoration={dat['text-decoration']}
                                                        color={dat['color']}
                                                        lineHeight={dat['line-height']}

                                                    >

                                                        {dat.text}

                                                    </MediaHeadlineText>



                                                }
                                                </div>





                                            ))}

                                        </MediaHeadline>
            

                                    :null
                                    }


                                    {
                                    cke.call === 'Notice'
                                    ?

                                        <MediaNotice
                                    
                                        backgroundColor={cke.data.box['background-color']}
                                        border={cke.data.box['border']}
                                        width={cke.data.box['width']}
                                        height={cke.data.box['height']}
                                        padding={cke.data.box['padding']}
                                        margin={cke.data.box['margin']}
                                        float={cke.data.box['float']}

                                        >

                                            {cke.data.lines.map((dat, k) => (


                                                dat.type === 'raw'
                                                ?

                                                    <div
                                                    key={k}
                                                    className={"Line"}
                                                    style={{

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color']
                                                    
                                                    }}
                                                    >
                                                        <div dangerouslySetInnerHTML={CreateMarkup(dat.text)} />

                                                    </div>

                                                :


                                                    <div
                                                    key={k}
                                                    className={"Line"}
                                                    style={{

                                                        fontFamily:         dat['font-family'],
                                                        fontSize:           dat['font-size'],
                                                        fontWeight:         dat['font-weight'],
                                                        fontStyle:          dat['font-style'],
                                                        textAlign:          dat['text-align'],
                                                        textDecoration:     dat['text-decoration'],
                                                        color:              dat['color']
                                                    
                                                    }}
                                                    >
                                                        {dat.text}

                                                    </div>


                                            ))}

                                        </MediaNotice>
            

                                    :null
                                    }


   

                                </div>

   

                            ))


                        :null
                        }




                        {   
                        item.call === 'namevals'
                        ?
                        
                            item.data.map((dat, j) => (

                                <div
                                key={j}
                                >
                                    <MediaNameVal
                                    dynaWidth={nvSizes[j].width}
                                    dynaHeight={nvSizes[j].height}
                                    >

                                        <div 
                                        ref={setNVRef}
                                        id={"nvLeft-"+j}
                                        className={"Left"}
                                        >

                                            <div 
                                            ref={setNVRef}
                                            id={"nvName-"+j}
                                            className={"Name"}
                                            >

                                                {dat.name}

                                            </div>

                                    
                                        </div>

                                        <div 
                                        ref={setNVRef}
                                        id={"nvRight-"+j}
                                        className={"Right"}
                                        >
                                            <div 
                                            className={"Value"}
                                            >

                                                {dat.value}

                                            </div>

                                        </div>


                                    </MediaNameVal>

                                </div>



                            ))




                            // <div className={StylColumn.Pgraph}>

                        
                            //     {"nameval"}


                            // </div>

                        
                        :null
                        }


                        {
                        item.call === 'links'
                        ?
                        
                            item.data.map((dat, j) => (

                                <div 
                                key={j}
                                >
                            

                                    <MediaLink
                                    // mainWidth={dat.mainWidth}
                                    // textColorOver={styl.link.textColorOver}
                                    // textColorOut={styl.link.textColorOut}

                                    mainWidth={linkStyl.mainWidth}
                                    textDecoration={linkStyl.textDecoration}
                                    textColorOver={linkStyl.textColorOver}
                                    textColorOut={linkStyl.textColorOut}

                                    fontSize={linkStyl.fontSize}
                                    fontWeight={linkStyl.fontWeight}

                                    >
                                    {
                                    dat.value.match(/site.site/gi)
                                    ?

                                        dat.position === 'left'
                                        ?
                                            <div 
                                            className={"LinkLeft"}
                                            onClick={(v) => handle({

                                                call      :"url",
                                                data      :dat.value

                                            })}
                                            >
                                                {dat.name}

                                            </div>

                                        :
                                        dat.position === 'center'
                                        ?          

                                            <div 
                                            className={"LinkCenter"}
                                            onClick={(v) => handle({

                                                call      :"url",
                                                data      :dat.value

                                            })}
                                            >
            
                                                {dat.name}

                                            </div>

                                        :
                                        dat.position === 'right'
                                        ?

                                            <div 
                                            className={"LinkRight"}
                                            onClick={(v) => handle({

                                                call      :"url",
                                                data      :dat.value

                                            })}
                                            >
            
                                                {dat.name}

                                            </div>


                                        :null



                                    ://Anchor

                                        


                                        dat.position === 'left'
                                        ?

                                            <a 
                                            href={CreateLink({

                                                name:dat.name,
                                                value:dat.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                <div 
                                                className={"LinkLeft"}
                                                >
        
                                                    {dat.name}

                                                </div>
                                            </a>


                                        :
                                        dat.position === 'center'
                                        ?          

                                            <a 
                                            href={CreateLink({

                                                name:dat.name,
                                                value:dat.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                <div 
                                                className={"LinkCenter"}
                                                >
        
                                                    {dat.name}

                                                </div>
                                            </a>

                                        :
                                        dat.position === 'right'
                                        ?

                                            <a 
                                            href={CreateLink({

                                                name:dat.name,
                                                value:dat.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                <div 
                                                className={"LinkRight"}
                                                >
        
                                                    {dat.name}

                                                </div>
                                            </a>


                                        :null




                                     }
                                    </MediaLink>
                                
                                </div>

                            ))



                            // <div className={StylColumn.Pgraph}>

                        
                            //     {"links"}


                            // </div>

                        
                        :null
                        }



                        {
                        item.call === 'buttons'
                        ?
                        
                            item.data.map((dat, j) => (

                                <div 
                                key={j}
                                >

                                    <MediaButton

                                    mainWidth={buttonStyl.mainWidth}

                                    backgroundColorOver={buttonStyl.backgroundColorOver}
                                    backgroundColorOut={buttonStyl.backgroundColorOut}

                                    borderRadius={buttonStyl.borderRadius}
                                    borderOver={buttonStyl.borderOver}
                                    borderOut={buttonStyl.borderOut}

                                    textColorOver={buttonStyl.textColorOver}
                                    textColorOut={buttonStyl.textColorOut}

                                    fontSize={buttonStyl.fontSize}
                                    fontWeight={buttonStyl.fontWeight}


                                    >
                                    {
                                    dat.value.match(/site.site/gi)
                                    ?


                                        dat.position === 'left'
                                        ?
                                            <div 
                                            className={"BtnLeft"}
                                            onClick={(v) => handle({

                                                call      :"url",
                                                data      :dat.value

                                            })}
                                            >
                                                {dat.name}

                                            </div>

                                        :
                                        dat.position === 'center'
                                        ?          

                                            <div 
                                            className={"BtnCenter"}
                                            onClick={(v) => handle({

                                                call      :"url",
                                                data      :dat.value

                                            })}
                                    
                                            // onMouseEnter={() => handleOverOut({
            
                                            //     id          :props.refid+"_arrowButtonDown",
                                            //     call        :"over",
                                            //     bgColor     :"darkred",
                                            //     borderColor :"darkred",
                                            //     arrowColor  :"white"
                
                                            // })}
                                    
                                            // onMouseOut={() => handleOverOut({
            
                                            //     id          :props.refid+"_arrowButtonDown",
                                            //     call        :"out",
                                            //     bgColor     :"white",
                                            //     borderColor :"white",
                                            //     arrowColor  :"darkred"
                            
                                            // })}


                                            >
            
                                                {dat.name}

                                            </div>

                                        :
                                        dat.position === 'right'
                                        ?

                                            <div 
                                            className={"BtnRight"}
                                            onClick={(v) => handle({

                                                call      :"url",
                                                data      :dat.value

                                            })}
                                            >
            
                                                {dat.name}

                                            </div>


                                        :null



                                    ://ANCHOR
                          
                                   
                                            
                                        dat.position === 'left'
                                        ?

                                            <a 
                                            href={CreateLink({

                                                name:dat.name,
                                                value:dat.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                <div 
                                                className={"BtnLeft"}
                                                >
        
                                                    {dat.name}

                                                </div>
                                            </a>


                                        :
                                        dat.position === 'center'
                                        ?          

                                            <a 
                                            href={CreateLink({

                                                name:dat.name,
                                                value:dat.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                <div 
                                                className={"BtnCenter"}
                                                >
        
                                                    {dat.name}

                                                </div>
                                            </a>

                                        :
                                        dat.position === 'right'
                                        ?

                                            <a 
                                            href={CreateLink({

                                                name:dat.name,
                                                value:dat.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
                                                <div 
                                                className={"BtnRight"}
                                                >
        
                                                    {dat.name}

                                                </div>
                                            </a>


                                        :null



                                    }
                                    
                                    </MediaButton>

                                

                                
                                </div>
                            ))


                            // <div className={StylColumn.Pgraph}>

                        
                            //     {"button"}


                            // </div>

                        
                        :null
                        }


                    </div>


                ))}

            </MediaColumn>
        

        )
    
    }






}

export default AllText;



// hndl['handleRoute'] = (v) => {

            
//     switch(v.call)
//     {
//     case'goBack':

//         history.goBack();
//     break;
//     case'reload':

//         window.location.reload();//regular dom 
//     break;
//     case'redirect':

//         history.replace(v.value);
//     break;
//     case'push':

//         history.push(v.value);
//     break;
//     default:
//     }

// }