
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />



https://stackoverflow.com/questions/43702454/reactjs-fade-in-div-and-fade-out-div-based-on-state


        transition: opacity 0.5s;
        opacity:${props => (props.opacity ? '1' : '0')};



*/



    
export const NavFade = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    opacity:            props.opacity || '1'



}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    transition: opacity 1s;
    opacity:${props => props.opacity};


`;     



export const Nav = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;
`;





    export const NavTrack = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "100%",
        height:             props.height || "auto",//54px
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        overflow:visible;
    `;



        export const NavBar = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#454d5f",
            border:             props.border || "0",
            width:              props.width || "100%",
            height:             props.height || "40px",//54px
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            border-bottom:${props => props.border};

            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;
        `;


        export const NavBg = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
            width:              props.width || "100%",
            height:             props.height || "9px",//54px
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0"
    
    
        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};
    
            position:relative;   
            float:left;
        `;
    
    

        export const NavGrid = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0",

            left:               props.left  || "0px",

            opacity:            props.opacity || '1',
            numAcross:          props.numAcross || '5'

            // USAGE
            // transition: opacity 0.5s;
            // opacity:${props => props.opacity};

            // display:inline-grid;
            // grid-template-columns: repeat(${props => props.numAcross}, auto);


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

 
            position:absolute;   
            top:0px;
            left:${props => props.left};
            z-index:1;

            display:inline-grid;
            grid-template-columns: repeat(${props => props.numAcross}, auto);



        `;



        

        export const NavGridRight = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0",

            left:               props.left  || "0px",

            opacity:            props.opacity || '1',
            numAcross:          props.numAcross || '5'

            // USAGE
            // transition: opacity 0.5s;
            // opacity:${props => props.opacity};

            // display:inline-grid;
            // grid-template-columns: repeat(${props => props.numAcross}, auto);


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

 
            position:absolute;   
            top:0px;
            right:${props => props.right};
            z-index:1;

            display:inline-grid;
            grid-template-columns: repeat(${props => props.numAcross}, auto);



        `;







            export const Button = styled.div.attrs(props => ({

                backgroundColor:    props.backgroundColor || "transparent",
                width:              props.width || "auto",
                height:             props.height || "auto",
                padding:            props.padding || "0 0 0 0",
                margin:             props.margin  || "0 0 0 0"


            }))`

                background-color:${props => props.backgroundColor};
                width:${props => props.width};
                height:${props => props.height};
                padding:${props => props.padding};
                margin:${props => props.margin};

                position:relative;   
                float:left;


            `;




                export const ButtonText = styled.div.attrs(props => ({

                    //backgroundColor:    props.backgroundColor || "transparent",
                    width:              props.width || "auto",
                    height:             props.height || "auto",
                    padding:            props.padding || "11.5px 15px 11.5px 15px",
                    margin:             props.margin  || "auto",

                    backgroundColorOver:    props.backgroundColorOver || "#818692",
                    textColorOver:          props.textColorOver || "#fff",

                    backgroundColorOut:    props.backgroundColorOut || "transparent",
                    textColorOut:          props.textColorOut || "#fff"

                }))`

                    background-color:${props => props.backgroundColorOut};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;
        
                    display:block;
            
                    font-family:"Verdana", sans-serif;      
                    font-size: 14px;
                    font-weight: normal;
                    text-align: center;
                    text-decoration:none;
                    color:${props => props.textColorOut};


                    &:hover{

                        background-color:${props => props.backgroundColorOver};
                        color:${props => props.textColorOver};
                    }




                `;


                export const ButtonTextHold = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "lightseagreen",
                    color:              props.color || "#fff",
                    width:              props.width || "auto",
                    height:             props.height || "auto",
                    padding:            props.padding || "11.5px 15px 11.5px 15px",
                    margin:             props.margin  || "auto",

                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;
        
                    display:block;
            
                    font-family:"Verdana", sans-serif;      
                    font-size: 14px;
                    font-weight: normal;
                    text-align: center;
                    text-decoration:none;
                    color:${props => props.color};


                `;



                export const ArrowBox = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "transparent",
                    width:              props.width || "100%",
                    height:             props.height || "auto",
                    padding:            props.padding || "0 0 0 0",
                    margin:             props.margin  || "0 0 0 0"


                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};


                    position:relative;
                    float:left;
            
                    overflow:visible;
            

                `;


                    export const Arrow = styled.div.attrs(props => ({

                        //arrow
                        arrowSizeA:         props.arrowSizeA || 11,
                        arrowSizeB:         props.arrowSizeA || 9,
                        arrowColor:         props.arrowColor || "lightseagreen",

                    }))`

                        width: 0; 
                        height: 0; 
    
                        border-left: ${props => props.arrowSizeA}px solid transparent;
                        border-right: ${props => props.arrowSizeA}px solid transparent;
                        
                        border-top: ${props => props.arrowSizeB}px solid ${props => props.arrowColor};
    
    
                        margin: auto;
                        padding:0 0 0 0;  
                        position:relative;



                    `;







    
    



















//##########################################
//Button
//##########################################


/*

        export const Button = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "10px 15px 13px 15px",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;

            display:block;

            font-family:"Verdana", sans-serif;      
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;


            &:hover {

                background-color:transparent;
                color:lightseagreen;

            }

        `;



        export const ButtonHold = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#818692",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "10px 15px 13px 15px",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;

            display:block;
        
            font-family:"Verdana", sans-serif;      
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;

        `;



*/










