
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

*/


const Container = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"

  }))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:110px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;  
    overflow:hidden;

`;




const Graphic = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightblue",
    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    padding:            props.padding || "10px 0 10px 0",
    margin:             props.margin  || "0 0 0 0"

  }))`

    background-color:${props => props.backgroundColor};

    border-radius: 6px;
    border: none;

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;  
    float:left;
    overflow:hidden;

    font-family: monospace;
    font-weight: bold;
    font-size: 24px;   
    text-align: center;
    text-decoration: line-through;
    color: #1d1d1d;

    outline: none;

`;




const InputWrap = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    width:              props.width || "calc(60% - 15px)",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "10px 0 5px 0"

  }))`

    background-color:${props => props.backgroundColor};

    border:1px solid #000;
    border-radius:3px;

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;   
    overflow:visible;


    .InputTag{
    
        background-color:${props => props.backgroundColor};
    
        width:100%;
        height:auto;
        
        padding:13px 0 10px 0;
        margin:0 0 0 0;
        float:left;
    
        font-family:Arial, Helvetica, sans-serif;
        font-size:16px;
        font-weight:normal;
        color:#000;
        text-align:center;
    
        border:0px solid #FFF;
    
    
    }

    .InputTagEmpty{
    
        background-color:${props => props.backgroundColor};
    
        width:100%;
        height:auto;
        
        padding:13px 0 10px 0;
        margin:0 0 0 0;
        float:left;
    
        font-family:Arial, Helvetica, sans-serif;
        font-size:16px;
        font-weight:normal;
        color:#777;
        text-align:center;
        font-style:italic;
    
        border:0px solid #FFF;
    
    }

    

`;


const Button = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fc0",
    width:              props.width || "calc(40% - 0px)",
    height:             props.height || "auto",
    padding:            props.padding || "13px 0 10px 0",
    margin:             props.margin  || "10px 0 5px 0"

  }))`

    background-color:${props => props.backgroundColor};

    border:1px solid #000;
    border-radius:3px;

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:right;   
    overflow:visible;

    font-family:Arial, Helvetica, sans-serif;
    font-size:16px;
    font-weight:normal;
    color:#000;
    text-align:center;


    cursor:pointer;



    &:hover{

        background-color:greenyellow;

    }

  

`;



export {

    Container,
    Graphic,
    InputWrap,
    Button

}
