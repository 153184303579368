const ComposeFields = (props) => {


    var R = [];
    var fieldtype;
    var hint;
    var def;
    var val;
    var text;

    switch(Object.prototype.toString.call(props))
    {
    case'[object Array]':
    break;
    case'[object Object]':



        //console.log("PROPS: "+JSON.stringify(props,null,2))

        for(var keyname in props)
        {

            fieldtype = props[keyname].print;

       
            switch(fieldtype)
            {
            case'standard': fieldtype = 'text'; break;
            //case'price': fieldtype = 'text'; break;
            //'percent': fieldtype = 'text'; break;
            default:
            }


            switch(fieldtype)
            {
            case'headline':


                /*
                "section"   :"headline",
                "data"      :{
        
                    "text"  :'How did you hear about Power Digital Media'
        
                }
                */


                R.push({

                    section:"headline",
                    data:{

                        text:props[keyname].value

                    }

                });


            break;
            default:

                hint = props[keyname].name;

                def = props[keyname].default;
                val = props[keyname].value;

                if(isNaN(def)) def = 0;
                if(isNaN(val)) val = 0;

                switch(props[keyname].print)
                {
                case'price':
            
                    def = parseFloat(def);
                    val = parseFloat(val);

                    def = def.toFixed(2);
                    val = val.toFixed(2);
                break;
                case'percent':

                    def = parseFloat(def);
                    val = parseFloat(val);

                    def = def.toFixed(1);
                    val = val.toFixed(1);
                break;
                default:
                }

                if(isNaN(def)) def = 0;
                if(isNaN(val)) val = 0;


                if(val !== '') 
                    text = val;
                else
                    text = def;


                R.push({

                    section:"fields",
                    data:[{

                        //"fieldtype"               :"text",
                        "fieldtype"                 :fieldtype,
                        "required"					:props[keyname].required,

                        "class"                   	:'',
                        "id"                      	:keyname,
                        "hint"                    	:hint,

                        "text"                     	:text,

                        "keyboardType"            	:"",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",

                        //"secure"                  :true,
                        //"returnKeyType"           :"done"

                    }]

                });

            break;
            }



        }



    break;
    case'[object String]':
    break;
    default:
    }


    return R;


}
export default ComposeFields;