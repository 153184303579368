


const SceneInfo = [



    //##########################################################
    //##########################################################
    //##########################################################

    // Form TM:

    {
      "styl": {
        "call": "full",
        "class": "FullRow",
        "marginTop": "0px",
        "marginBottom": "0px"
      },
      call: "Form",
      info: {
        call: "default",//switchRoute, default
        gridType: "Form",
        gridBgColor: "transparent",
        gridCall: "grid_cell",
        cellCall: "default",
        modCall: "fill_div",
        thumb: {
            "call": "default",
            "diff": "0"
        },
        cellBorder: {
            "boolean": true,
            "thickness": 1,
            "color": "gray"
        },
        gutter: {
            "boolean": "0",
            "size": "0"
        },
        thresh: {
            "refWidth": false,
            "across1": "501",
            "across2": "801",
            "across3": "1051"
        }
      },
      "items": [
        {
          "choice": "email",
          "name": "Test Form",
          "email": "powerdigitalmedia.com@gmail.com",
          "price": "0.00",
          "felems": [
            {
              "element": "input",
              "call": "text",
              "value": "Name"
            },
            {
              "element": "inputspecial",
              "call": "email",
              "value": "email"
            },
            {
              "element": "tbar",
              "call": "normal",
              "value": "Favorite Color"
            },
            {
              "element": "clickselect",
              "call": "radio",
              "value": [
                "Red",
                "Yellow",
                "Green"
              ]
            },
            {
              "element": "tbar",
              "call": "normal",
              "value": "Favorite Code"
            },
            {
              "element": "clickselect",
              "call": "checkbox",
              "value": [
                "Javascript",
                "Php",
                "Python",
                "Java",
                "NodeJS"
              ]
            },
            {
              "element": "tbar",
              "call": "normal",
              "value": "Message"
            },
            {
              "element": "inputspecial",
              "call": "textarea",
              "value": "Message"
            }
          ]
        }
      ],
      "recid": "20211027121543",
      "reckey": "rec-blogsegment-entry-20211027121543",
      "name": "Form Test"
  }


];


export default SceneInfo;






/*

{

  "pageRows": [
    {
    "call": "information",
    "data": {
      "type": "media",
      "name": "Form - Test",
      "image": "20200515114057",
      "caption": "Used to set data for forms"
    },
    "rowInfo": false
    },
    {
    "call": "form",
    "data": [
      {
      "choice": "email",
      "name": "Test Form",
      "email": "comments@powerdigitalmedia.com",
      "price": "0.00",
      "felems": [
        {
        "element": "input",
        "call": "text",
        "value": "Name"
        },
        {
        "element": "inputspecial",
        "call": "email",
        "value": "email"
        },
        {
        "element": "tbar",
        "call": "normal",
        "value": "Favorite Color"
        },
        {
        "element": "clickselect",
        "call": "radio",
        "value": [
          "Red",
          "Yellow",
          "Green"
        ]
        },
        {
        "element": "tbar",
        "call": "normal",
        "value": "Favorite Code"
        },
        {
        "element": "clickselect",
        "call": "checkbox",
        "value": [
          "Javascript",
          "Php",
          "Python",
          "Java",
          "NodeJS"
        ]
        },
        {
        "element": "tbar",
        "call": "normal",
        "value": "Message"
        },
        {
        "element": "inputspecial",
        "call": "textarea",
        "value": "Message"
        }
      ]
      }
    ],
    "rowInfo": false
    }
  ]
}


*/

