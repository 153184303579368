import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';




import Vids from './Vids';



const Index = (props) => {


    const { width } = useWindowDimensions();



    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);





    // -- state -- //
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [hashData, setHashData] = useState(false);


    useEffect(() => {




        setHashData(false);
        
        //console.log(JSON.stringify(images,null,2));
        //alert("Flex Height");
    
    
        /*
        alert(""
            +"\n"
            +`PUBLIC: ${process.env.PUBLIC_URL}`
            +"\n"
            +"WINDOW LOCATION ORIGIN: "+window.location.origin
            +"\n"
            +"WINDOW LOCATION HREF: "+window.location.href 
        );
        */
        
        
        const posArr = [];
        const tempArr = [];

        const Complete = (array) => {

            //alert('images finished loading');
            console.log(":::"+location.pathname+":::");
            console.log("Images Finished Loading (Fluid)")

            if(array.length > 0)
            {
                setIsLoaded(true);
                setItems(array);

            }else{
                setIsLoaded(true);
                setError({message:"Process Image (FAILED)"});
            }
        
        }



        var skip;
        skip = false;
        if(props.info.call === 'vid') 
            skip = true;

  

        if(items.length === 0
        && !skip
        )
        {      
            
            var images = props.items;
            images.map(item => (
            
                updateData(item)
            
            ))
            
            function updateData(item) {
            
            
                //console.log("ENV PUBLIC URL: "+process.env.PUBLIC_URL);
            
                var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;
                //var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;
                //console.log(sourceurl);
            
                var img = new Image();
                img.error = function(){
                    console.log("IMAGE ERROR");
                };
                img.onload = function(){
            
                    //console.log("IMAGE IS LOADED");
            
                    //console.log('My width is: ', img.naturalWidth);
                    //console.log('My height is: ', img.naturalHeight);
            
                    var imgW = img.naturalWidth;
                    var imgH = img.naturalHeight;
            
                
                    posArr.push(item.position);
                    tempArr.push({
            
                        position    :item.position,
            
                        id          :item.id,
                        title       :item.title,
                        text        :item.text,
                        bgColor     :item.bgColor,
                        image:{
            
                            src:item.image.src,
                            w:imgW,
                            h:imgH
                        }
            
                    })
            
            
                    if(tempArr.length === images.length)
                    {
                        posArr.sort();
            
                        //console.log("POS: "+posArr.length)
                        //console.log("TEMP: "+tempArr.length)
                        //console.log("TEST LEN: "+testData.length)
                    
            
                        var array = [];
                        for(var i=0; i < posArr.length; i++)
                        {
                            for(var j=0; j < tempArr.length; j++)
                            {
                                //console.log(posArr[i]+" === "+tempArr[j]['position']);
            
                                if(posArr[i] === tempArr[j]['position'])
                                {
                                
                                    array.push(tempArr[j]);
                                    //console.log("break");
                                    break;
            
                                }//==
            
                            }//==
            
                        }//==
            
                        Complete(array);
            
                    }//==
            
            
            
                };
                img.setAttribute("crossOrigin","anonymous");
                img.setAttribute("src",sourceurl);
            
            }


        }
        else
        {


            setIsLoaded(true);
            if(props.info.call === 'vid') setItems(props.items);




        }// -- if items



    },
    [

        location, 
        hashData,     
        items,      
        props

    ])








    // -- vars -- //

    var ssg;
    var gridMaxWidth;  
    ssg = false;
    gridMaxWidth = "1920px";
    if(location.search.match(/\?/gi))
    {
        var qr = location.search;
        if(qr.charAt(0) === '?') 
            qr = qr.substring(1,qr.length);

        if(qr === 'ssg=yes') 
        {
            ssg = true;
            gridMaxWidth = "1680px";

        }
    }


    var call;
    call = props.info.call;
    if(ssg) call = 'fixed';

    if(props.info.call === 'vid') call = 'vid';


    const info = props.info;
    info.gridMaxWidth = gridMaxWidth;
    info.ssg = ssg;




    //alert(props.refid);

    if(props.refid 
    && props.refid !== undefined
    && props.refid !== null
    )
    {
        if(document.getElementById(props.refid))
        {
            const ref = document.getElementById(props.refid);

            var refDecimal = (parseInt(ref.offsetWidth)/width);
            var newWidth  = width * refDecimal;

            info.thresh.refWidth = newWidth;
        }
        //console.log(props.refid+"\n"+ref.offsetWidth+"\n"+refDecimal+"\n"+newWidth);

        info.gridClass = "B";

        if(call === 'fixed') 
        {
            info.cellClass = "A";
            if(info.thresh.across3 > 9999) 
            {
                info.cellClass = "B";
                if(props.refid.match(/pagerow/)) info.cellClass = "B2";

            }
            if(info.thresh.across2 > 9999) info.cellClass = "C";

        }

        if(items.length === 3) 
        {
            info.cellClass = "B";

        }
        if(items.length === 2) 
        {
            info.cellClass = "D";
            if(width < 550) 
            {
                info.cellClass = "C";
            }

        }
    
        if(items.length === 1) 
        {
            info.cellClass = "C";

        }


    }//==


 



    if(error) 
    {
        return (

            <div 
            style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

            }}
            >
                    Error: {error.message}

            </div>

        )



    } 
    else 
    if(!isLoaded) 
    {

        return (
        
             <div 
             style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

             }}
             >
                 Loading...

            </div>
        )


    } 
    else 
    {



        return (

            <div>

                {
                call === 'vid' ?

                    <Vids

                        handle  = {props.handle} 
                        items   = {items}
                        info    = {info}
                        refid   = {props.refid}
                    
                    />

                :null
                }


            </div>

        )


    }



}
export default Index


