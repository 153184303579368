import React, { 

    useState, 
    //useEffect, 
    //useRef 

} from 'react';

//import SVGLogo from 'grfx/logo.svg';
//import SVGLogoOver from 'grfx/logo_over.svg';

import SVGPencil from 'grfx/pencil.svg';
import SVGPencilOver from 'grfx/pencil_over.svg';


//import { fontSize } from 'pdfkit/js/mixins/fonts';

import CreateMarkup from './CreateMarkup';

import { LibString } from 'library';



//import StylColumn from './StylModules/Column.module.css';

import {

    Columns,

    Full,
    //Page,

    Box,
    Controls,

    Heading,
    Stripe,
    Pgraph,
    Info,
    List,
    Thmb,


    //Single,
    //SingleBox,
    //SingleControls,

    //SpecialBox,
    //SpecialControls,
    //SpecialHeading,
    //SpecialPgraph


} from './Styled';




const CmpPage = (props) => {




    // -- refs -- //

    const [boxRefs] = useState([]);
    const setBoxRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < boxRefs.length; i++)
            {
                if(boxRefs[i].id === ref.id) found = true;
            }
            if(!found) boxRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };




    // -- handle -- //

    const handleOverOut = (hndl) => {


        switch(hndl.from)
        {
        case'controls':


            //console.log("controls");

            var i;
            var found;
            found = false;
            if(boxRefs.length > 0)
            {
                for(i=0; i < boxRefs.length; i++)
                {
                    if(boxRefs[i].id === props.refid+"_box"+hndl.indx)
                    {
                        found = true;
                        break;
                    }
                }
            
            }

            if(found)
            {
                var box_id = props.refid+"_box"+hndl.indx;

                var btn;
                var img;

                //controls = document.getElementById(hndl.id+"_controls");
                btn = document.getElementById(box_id+"_controls_button");
                img = document.getElementById(box_id+"_controls_button_img");

                switch(hndl.call)
                {
                case'mouseEnter':
                case'mouseOver':

                    btn.style.backgroundColor = 'transparent';
                    img.src = SVGPencilOver;
                break;
                case'mouseOut':

                    btn.style.backgroundColor = 'transparent';
                    img.src = SVGPencil;
                break;
                default:
                }

            }//==



        break;
        default:
        }



    }




    return (


        <div>
        <Columns>

        {
        props.info.gridType === 'Graphic' 
        ? 


            <Full>

                {props.items.map((item, i) => (

                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}
                    >

                        {item.image
                        ?
                            <Thmb
                            id={"thmb_"+i}
                            >

                                <div 
                                className="Holder"
                                >
                                    <img
                                    className="Img"
                                    style={{


                                        height:"auto",

                                        minWidth:item.image.maxWidth,
                                        minHeight: "100%",

                                        maxWidth:"auto",
                                        maxHeight:item.image.maxHeight,

                                        marginTop:item.image.top,
                                        marginLeft:item.image.left

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='noimage'
                                    /> 


                                </div>  

                            </Thmb>

                        :null}


                    </Box>


                ))}


            </Full>






        :
        //#########################################################
        //#########################################################
        //#########################################################

        props.info.gridType === 'Info' 
        ? 

            <Full>

                {props.items.map((item, i) => (

                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}
                    >

                        <Controls    
                        id={props.refid+"_box"+i+"_controls"}
                        >

                            <div 
                            className="Button"
                            id={props.refid+"_box"+i+"_controls_button"}
                            >

                                <div 
                                className="ButtonMask"
                                id={props.refid+"_box"+i+"_controls_button_mask"}

                                    onClick={() => props.handle({

                                        call        :'showFlyr',
                                        data        :[item],
                                        gridType    :props.info.gridType,
                                        position    :i,
                                        id          :item.id
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx    :i,   
                                        call    :'mouseEnter',
                                        from    :'controls'

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx    :i,       
                                        call    :'mouseOut',
                                        from    :'controls'

                                    })}

                                >
                                </div>

                                <img
                                id={props.refid+"_box"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGPencil} 
                                alt="logo" />



                            </div>


                        </Controls>




                        {item.heading 
                        ?

                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </Heading>

                        :
                        
                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                            </Heading>

                        }


                        {item.stripe
                        ?

                            <Stripe></Stripe>

                        :null}



                        {item.pgraph
                        ?

                            <Info>

                      
                                {Object.keys(item.pgraph).map((key, j) => (

                                    <div
                                    key={j}
                                    className="Line"
                                    >
                                        <div
                                        className="Name"
                                        >
                                            <div dangerouslySetInnerHTML={CreateMarkup(`${LibString.CapitalizeWords(key)}`)} />     
                                        </div>

                                        <div
                                        className="Value"
                                        >
                                            <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph[key])} />     
                                        </div>
                    

                                    </div>

                                    

                                    /*

                                    <div
                                    key={j}
                                    className="SingleLine"
                                    >
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph[key])} />     
                                        
                                    </div>
                                    */


                                ))}


                            </Info>


                        :null}


                    </Box>


                ))}


            </Full>




        :
        //#########################################################
        //#########################################################
        //#########################################################

        props.info.gridType === 'NameValue' 
        ? 


            <Full>

                {props.items.map((item, i) => (

                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}
                    >

                        <Controls    
                        id={props.refid+"_box"+i+"_controls"}
                        >

                            <div 
                            className="Button"
                            id={props.refid+"_box"+i+"_controls_button"}
                            >

                                <div 
                                className="ButtonMask"
                                id={props.refid+"_box"+i+"_controls_button_mask"}

                                    onClick={() => props.handle({

                                        call        :'showFlyr',
                                        data        :[item],
                                        gridType    :props.info.gridType,
                                        position    :i,
                                        id          :item.id
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx    :i,   
                                        call    :'mouseEnter',
                                        from    :'controls'

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx    :i,       
                                        call    :'mouseOut',
                                        from    :'controls'

                                    })}

                                >
                                </div>

                                <img
                                id={props.refid+"_box"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGPencil} 
                                alt="logo" />



                            </div>


                        </Controls>





                        {item.heading 
                        ?

                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </Heading>

                        :
                        
                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                            </Heading>

                        
                        }


                        {item.stripe
                        ?

                            <Stripe></Stripe>

                        :null}



                        {item.pgraph
                        ?
                            <List>

                                {item.pgraph.map((line, j) => (

                                    <div
                                    key={j}
                                    className="Line"
                                    >
                                        <div
                                        className="Name"
                                        >
                                            <div dangerouslySetInnerHTML={CreateMarkup(line.name)} />     
                                        </div>

                                        <div
                                        className="Value"
                                        >
                                            <div dangerouslySetInnerHTML={CreateMarkup(line.value)} />     
                                        </div>
                    

                                    </div>

                                ))}
                    
                            </List>

                        :null}


                    </Box>


                ))}


            </Full>



        :
        //#########################################################
        //#########################################################
        //#########################################################


            <Full>

                {props.items.map((item, i) => (


                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}
                    >

                        <Controls    
                        id={props.refid+"_box"+i+"_controls"}
                        >

                            <div 
                            className="Button"
                            id={props.refid+"_box"+i+"_controls_button"}
                            >

                                <div 
                                className="ButtonMask"
                                id={props.refid+"_box"+i+"_controls_button_mask"}

                                    onClick={() => props.handle({

                                        call        :'showFlyr',
                                        data        :[item],
                                        gridType    :props.info.gridType,
                                        position    :i,
                                        id          :item.id
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx    :i,   
                                        call    :'mouseEnter',
                                        from    :'controls'

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx    :i,       
                                        call    :'mouseOut',
                                        from    :'controls'

                                    })}

                                >
                                </div>

                                <img
                                id={props.refid+"_box"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGPencil} 
                                alt="logo" />



                            </div>


                        </Controls>





                        {item.heading 
                        ?

                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </Heading>

                        :
                        
                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                            </Heading>

                        
                        }


                        {item.stripe
                        ?

                            <Stripe></Stripe>

                        :null}



                        {item.image
                        ?
                            <Thmb
                            id={"thmb_"+i}
                            >

                                <div 
                                className="Holder"
                                >
                                    <img
                                    className="Img"
                                    style={{


                                        height:"auto",

                                        minWidth:item.image.maxWidth,
                                        minHeight: "100%",

                                        maxWidth:"auto",
                                        maxHeight:item.image.maxHeight,

                                        marginTop:item.image.top,
                                        marginLeft:item.image.left

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='noimage'
                                    /> 


                                </div>  

                            </Thmb>

                        :null}


                        {item.pgraph
                        ?
                            <Pgraph>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />

                            </Pgraph>

                        :null}


                    </Box>


                ))}


            </Full>


        }


        </Columns>
        </div>


    )



}

export default CmpPage;



