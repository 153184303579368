
import styled from "styled-components";


    /*

    https://www.w3schools.com/howto/howto_css_aspect_ratio.asp

    padding-top: 100%       1:1 Aspect Ratio 
    padding-top: 56.25%;    16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 
    padding-top: 75%;       4:3 Aspect Ratio (divide 3 by 4 = 0.75) 
    padding-top: 66.66%;    3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  
    padding-top: 62.5%;     8:5 Aspect Ratio (divide 5 by 8 = 0.625) 

    */


    const CanvBox = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "0",
        width:              props.width || "100%",
        margin:             props.margin || "0 auto 0 auto",


        //holder
        holderHeight:       props.holderHeight || "100%"

    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};
    
        position: relative;
        width: ${props => props.width};
        padding-top: 64%; 
        margin:${props => props.margin};
        overflow:hidden;


        @media screen and (max-width: 800px) {

            width:99.5%;
        }




        .Message {

            background-color:transparent;
            border:0px solid white;
            border-radius:3px;

            width:180px;
            height:auto;

            padding:0 0 4px 0;
            margin:0 0 0 0;    
        
            position:absolute;
            top:calc(50% - 20px);
            left:calc(50% - 90px);
            z-index:5;

            overflow:hidden;

            font-size:30px;  
            color:white;
            text-align: center;
            text-decoration: none;


            cursor:default;

        }


            .SlicePerc {

                background-color:transparent;

                width:100%;
                height:auto;

                padding:0 0 0 0;
                margin:0 0 0 0;  
            
                position:relative;
                float:left;
        
                overflow:hidden;

                font-size:32px;  
                color:white;
                text-align: center;
                text-decoration: none;
                line-height:1.2;

            }

            .SliceName {

                background-color:transparent;

                width:100%;
                height:auto;

                padding:0 0 0 0;
                margin:0 0 0 0;  
            
                position:relative;
                float:left;
        
                overflow:hidden;

                font-size:27px;  
                color:white;
                text-align: center;
                text-decoration: none;
                line-height:1.2;
    
            }
    
    

        .Holder {

            background-color:transparent;
            width:100%;
            height:${props => props.holderHeight};
            margin:0 0 0 0;   

            position: absolute; 
            top:0px;
            left:0px;
            z-index:2;

            overflow:hidden;

        }

            .Canv{

                width:100%;
                height:100%;
                margin:0 0 0 0;
                position:relative;
                float:left;

            }




    `;



    const CanvBtn = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "rgb(34, 20, 95)",
        border:             props.border || "1px solid rgb(124, 110, 185)",
        borderRadius:       props.borderRadius || "7px"

    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};
    

        width:125px;
        margin:15px auto 20px auto;
        padding:7px 0 5px 0;

     
        cursor: pointer;

        transition-duration: 0.4s;
    
        font-size: 16px;  
        color: white;
        text-align: center;
        text-decoration: none;
    



        &:hover{
    
            background-color: rgb(104, 80, 212);
        
        }
    

    `;





    const CanvKey = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "0"

    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};


        width:150px;
        height:auto;
        min-height:100px;

        position:absolute;
        bottom:0px;
        left:0px;
        z-index:5;

        overflow:hidden;



        .Line {

            background-color:transparent;
   
            width:100%;
            height:auto;

            padding:0 0 4px 0;
            margin:0 0 0 0;    
        
            position:relative;
            float:left;

            overflow:visible;

            font-size:30px;  
            color:white;
            text-align: center;
            text-decoration: none;


        }

            .Line .ColorTile {

                background-color:yellow;
                border:1px solid black;
        
                width:18px;
                height:18px;
                margin:0 0 0 0;   

                position:relative; 
                float:left;

                overflow:hidden;

            }

            .Line .Text {


                width:auto;
                height:auto;
                margin:0 0 0 7px;   

                position:relative; 
                float:left;

                overflow:hidden;

                font-size:14px;  
                color:black;
                text-align:left;
                text-decoration: none;
    


            }


    `;



    const CanvMsg = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "0",


        //chartKey
        chartKeyMargin:     props.chartKeyMargin || "0 0 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:100%;
        height:auto;

        padding:0 0 0 0;
        margin:0 0 0 0;    
    
        position:relative;
        float:left;

        overflow:hidden;

        font-size:18px;  
        color:#1a1926;
        text-align: center;
        text-decoration: none;

        cursor:default;



        .ChartKey{

            background-color:#454d5f;            
            border-top:1px solid lightgray;     
            border-left:1px solid lightgray;
            border-right:1px solid lightgray;
  

            width:99.5%;
            height:auto;
            min-height:50px;

            padding:0 0 0 0;
            margin:${props => props.chartKeyMargin};

            position:relative;
            float:left;
            overflow:hidden;

        }



        .Line {

            background-color:transparent;
            border-bottom:1px solid lightgray;

            width:100%;
            height:auto;

            padding:5px 0 7px 0;
            margin:5px 0 0 0;    
        
            position:relative;
            float:left;

            overflow:visible;

            font-size:30px;  
            color:white;
            text-align: center;
            text-decoration: none;



        }

                .Line .ColorTile {

                    background-color:yellow;
                    border:1px solid lightgray;
            
                    width:18px;
                    height:18px;
                    margin:0 0 0 10px;   

                    position:relative; 
                    float:left;

                    overflow:hidden;

                }

                .Line .Text {


                    width:auto;
                    height:auto;
                    margin:0 0 0 7px;   

                    position:relative; 
                    float:left;

                    overflow:hidden;

                    font-size:14px;  
                    color:white;
                    text-align:left;
                    text-decoration: none;
        


                }





    `;





    const ChartKey = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "0",
        margin:             props.margin || "0 0 0 0",
        textColor:          props.textColor || "black",

        softTextColor:      props.softTextColor || "#454d5f"



    }))`

        background-color:transparent;

        width:100%;
        height:auto;
        min-height:50px;

        padding:0 0 0 0;
        margin:${props => props.margin};

        position:relative;
        float:left;
        overflow:hidden;

    
        .Line {

            background-color:${props => props.backgroundColor};
            border:${props => props.border};

            width:99.5%;
            height:auto;

            padding:0 0 0 0;
            margin:2.5px 0 2.5px 0;    
        
            position:relative;
            float:left;

            overflow:visible;

            font-size:30px;  
            color:white;
            text-align: center;
            text-decoration: none;



        }

                .Line .ColorTile {

                    background-color:yellow;
                    border:${props => props.border};
            
                    width:20px;
                    height:20px;
                    margin:12px 0 0 10px;   

                    position:relative; 
                    float:left;

                    overflow:hidden;

                }

                .Line .Text {


                    width:auto;
                    height:auto;
                    margin:14px 0 0 7px;   

                    position:relative; 
                    float:left;

                    overflow:hidden;

                    font-size:16px;  
                    color:${props => props.textColor};
                    text-align:left;
                    text-decoration: none;
        


                }


                .Line .Name {

                    width:auto;
                    height:auto;
                    margin:14px 0 0 7px; 
                    padding:0 0 0 0;  

                    position:relative; 
                    float:left;

                    overflow:hidden;

                    font-size:16px;  
                    color:${props => props.textColor};
                    text-align:left;
                    text-decoration: none;
        
                }


                .Line .Percent {

                    background-color:transparent;

                    width:60px;
                    height:auto;
                    margin:14px 0 0 10px;  
                    padding:0 0 0 0; 

                    position:relative; 
                    float:left;

                    overflow:hidden;

                    font-size:16px;  
                    color:${props => props.textColor};
                    text-align:left;
                    text-decoration: none;


                }

                .Line .Price {

                
                    width:auto;
                    height:auto;
                    margin:14px 0 0 0; 
                    padding:0 0 0 8px;  

                    position:relative; 
                    float:left;

                    overflow:hidden;

                    font-size:14px;  
                    color:${props => props.textColor};
                    text-align:left;
                    text-decoration: none;
        


                }





                .Line .Controls {

                    background-color:transparent;
                    width:auto;
                    height:30px;
                    margin:7px 7px 7px 7px;   

                    position:relative; 
                    float:right;

                    overflow:hidden;
    
                }


                .Line .Button {

                    background-color:transparent;
            
                    width:30px;
                    height:30px;
                    padding:0 0 0 0;
                    margin:0 0 0 0;
                    position:relative;
                    float:left;
    
                    cursor:pointer;
    
    
                }
    
                .Line .ButtonMask {
    
                    background-color:transparent;
        
                    width:30px;
                    height:30px;
                    padding:0 0 0 0;
                    margin:0 0 0 0;
                    position:absolute;
                    top:0px;
                    left:0px;
                    z-index:1;
    
                    cursor:pointer;
    
                }
    
                .Line .Img {
    
                    width:30px;
                    height:30px;
    
                }
    



    `;











export {

    CanvBox,
    CanvMsg,
    CanvBtn,
    CanvKey,


    ChartKey,



}
