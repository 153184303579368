import styled, { keyframes } from 'styled-components';


const fadeIn = keyframes`

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`

    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;





export const Fade = styled.div.attrs(props => ({


    out:                    props.out || false,
    animationTime:          props.animationTime || '0.5s',
    transitionTime:         props.transitionTime || '0.5s'


    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;


}))`
  
    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    animation: ${props => props.out ? fadeOut : fadeIn} ${props => props.animationTime} linear;
 
`;




// export const Fade = styled.div`

//     display: inline-block;
//     visibility: ${props => props.out ? 'hidden' : 'visible'};
//     animation: ${props => props.out ? fadeOut : fadeIn} 1s linear;
//     transition: visibility 1s linear;

// `;

