export const STATIC_HEADLIGHT_TAILLIGHT = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
        
    
    {/*Headlight Background 1*/}
    <polygon 
        fill="rgb(90,90,90)" 
        points="655.779,275.399 642.933,285.278 608.1,230.634 642.933,250.744 655.779,275.399 	"
    />
    {/*Headlight Background 2*/}
    <path 
        fill="rgb(50,50,50)" 
        d="M633.529,285.279h9.404L608.1,230.633l-7.053,3.961
		c-2.936,1.977-2.918,5.109-1.018,7.379l24.152,36.816C626.922,283.488,630.012,285.297,633.529,285.279L633.529,285.279z"
    />
        {/*Taillight Background*/}
    <path 
        fill="rgb(45,45,45)" 
        d="M76.176,263.825v-53.283l49.028,0.835	c4.006,0.094,6.792,3.85,4.037,8.271l-22.789,38.399c-1.728,2.942-5.784,5.329-10.173,5.497L76.176,263.825L76.176,263.825z"
    />
    
   
    
    {/*Headlight P1*/}
    <path 
        fill="rgb(130,130,130)" 
        d="M641.291,253.441l-5.821-4.806c-0.502,0.853-0.969,2.896-1.134,4.654l6.244,4.46 C640.703,256.749,640.838,255.016,641.291,253.441L641.291,253.441z M614.93,256.316c-2.638-2.31-5.244-4.557-7.646-6.573 c0.15-1.854,0.497-3.944,1.088-5.389l4.915,0.345C614.765,247.949,614.99,252.952,614.93,256.316L614.93,256.316z"
    />
    {/*Headlight P2*/}
    <path 
        fill="rgb(175,175,175)" 
        d="M614.499,236.418c-0.001-0.639,0.267-0.537,0.704,0.047 c0.438,0.582,1.466,2.282,1.416,3.939c-0.05,1.656-0.487,3.215-0.866,4.118s-0.614,0.337-0.75-0.13l-0.495-1.698L614.499,236.418 L614.499,236.418z M640.844,281.578c-0.187-6.263-0.447-15.271-0.197-21.977l-6.406-4.567c-0.604,9.717-0.151,19.174,0.928,29.024	L640.844,281.578L640.844,281.578z M612.473,260.941l-4.366-6.656c0.204-4.438,0.563-8.586,1.175-12.447 C611.721,247.199,612.427,254.411,612.473,260.941L612.473,260.941z"
    />
    {/*Headlight P3*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M614.295,236.826l-0.024,4.519 c-0.003,0.498-0.312,0.763-1.025,0.698l-4.266-0.392l-0.214,1.436l-5.752-2.995c-1.146-0.598-1.622-1.736-0.116-2.07l10.522-2.332	C614.441,235.748,614.295,236.826,614.295,236.826L614.295,236.826z M644.781,272.938c0,0-1.775-4.005-2.24-4.547 c-0.465-0.543-1.387-0.712-1.166,1.885s0.307,5.788,0.421,6.698c0.049,0.398,0.088,1.383-0.377,2.357	c-0.471,0.989,1.456,2.229,1.519,0.149C643,277.4,641.989,269.638,644.781,272.938L644.781,272.938z"
    />
    {/*Headlight P4*/}
    <path 
        fill="rgb(245,130,30)" 
        d="M618.187,269.65v-24.162l8.479,1.412l3.108,6.044v2.142 l-1.143,1.405l1.143,2.893v20.461l0.962,5.012c-2.391-0.759-4.555-2.639-6.553-6.067L618.187,269.65L618.187,269.65z"
    />
    {/*Headlight P4*/}
    <polygon 
        fill="rgb(130,130,130)" 
        points="640.238,273.519 634.646,273.563 634.556,268.58 640.148,268.536 640.238,273.519 	"
    />
    {/*Headlight P5*/}
    <polygon 
        fill="rgb(0,0,0)" 
        points="629.602,272.874 622.916,272.874 623.803,274.437 629.602,274.437 629.602,272.874"
    />
    {/*Headlight P6*/}
    <polygon 
        fill="rgb(0,0,0)" 
        points="627.332,258.732 618.291,257.289 618.277,252.526 627.338,253.97 627.332,258.732 	"
    />




    {/*Taillight Red*/}
    <polygon 
        fill="rgb(200,0,0)" 
        points="119.866,231.214 98.125,230.805 98.125,250.049 108.57,250.248 119.866,231.214 	"
    />
    {/*Taillight P1*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M124.835,219.545c0.132,1.177,0.001,2.502-0.407,3.982	l-0.656,1.107l-30.01-0.566l-0.004-5.107L124.835,219.545L124.835,219.545z"
    />
    {/*Taillight P2*/}
    <path 
        fill="rgb(130,130,130)" 
        d="M121.4,228.629l2.998-5.052l-26.273-0.495v5.108L121.4,228.629	L121.4,228.629z M83.089,227.928l9.403,0.167v2.778l-9.401-0.168L83.089,227.928L83.089,227.928z"
    />
    {/*Taillight P3*/}
    <path 
        fill="rgb(200,200,200)" 
        d="M79.475,222.764l2.844,0.055c0.417,0.008,0.778,0.39,0.778,0.846 v24.563c0,0.457-0.341,0.824-0.757,0.816l-2.863-0.055c-0.416-0.008-0.758-0.389-0.758-0.846V223.58 C78.719,223.123,79.059,222.756,79.475,222.764L79.475,222.764z M93.466,218.954l3.658,0.071c0.536,0.01,1.001,0.501,1.001,1.088	v31.599c0,0.586-0.439,1.061-0.975,1.05l-3.683-0.07c-0.536-0.01-0.976-0.5-0.976-1.088v-31.599 C92.492,219.417,92.93,218.944,93.466,218.954L93.466,218.954z"
    />
       


   
    

		</svg>
		</div>

	);//return
}