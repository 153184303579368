export const BUTTON_TIRE_PIRELLI_WET_BLUE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Black*/}

    <polygon 
        fill="rgb(0,0,0)" 
        points="108,90 612,90 612,414 108,414 108,90 	"
    />
{/*Brackets*/}
    <path 
        fill="rgb(0,114,188)" 
        d="M344.358,345.169c-44.636-7.491-78.834-46.46-78.834-93.169 c0-46.708,34.198-85.679,78.834-93.17v-22.777C287.267,143.738,243,192.861,243,252s44.267,108.262,101.358,115.947V345.169 L344.358,345.169z M375.642,158.83c44.636,7.491,78.834,46.462,78.834,93.17c0,46.709-34.198,85.678-78.834,93.169v22.778 C432.732,360.262,477,311.139,477,252s-44.268-108.262-101.358-115.947V158.83L375.642,158.83z"
    />  
{/*Letter*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M309.443,211.131l11.007,73.084 c0.085,0.854,0.341,1.578,0.768,2.176c0.427,0.597,1.067,0.896,1.92,0.896c0.768,0,1.344-0.277,1.728-0.832
		s0.619-1.216,0.704-1.983l8.191-48.509c1.365-7.936,4.16-14.313,8.384-19.135s10.389-7.231,18.494-7.231 c8.191,0,14.144,2.432,17.855,7.296c3.712,4.863,6.25,11.177,7.615,18.942l8.191,48.509c0.171,0.938,0.47,1.664,0.896,2.176 c0.427,0.512,0.981,0.768,1.664,0.768c1.109,0,1.856-0.299,2.24-0.896c0.384-0.598,0.618-1.365,0.704-2.305l10.751-72.955h25.598 l-13.311,74.235c-1.365,7.509-4.031,13.226-7.999,17.151c-3.968,3.925-9.621,5.887-16.959,5.887 c-8.191,0-14.548-2.175-19.071-6.527c-4.521-4.352-7.381-10.238-8.575-17.662l-8.319-50.429c-0.085-0.768-0.299-1.365-0.641-1.792 c-0.341-0.427-0.853-0.641-1.535-0.641s-1.194,0.214-1.536,0.641s-0.555,1.024-0.64,1.792l-8.575,50.685 c-1.28,7.423-3.947,13.268-8,17.535c-4.053,4.266-9.962,6.398-17.727,6.398c-8.191,0-14.356-2.324-18.495-6.976 c-4.139-4.649-6.848-10.559-8.127-17.726l-12.799-72.572H309.443L309.443,211.131z"
    />




    

		</svg>
		</div>

	);//return
}