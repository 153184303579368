import { 

    VPRoverhead,  
    VPRtotals,

    VPRownersST,
    VPRownersIRA,
    VPRownersWeekHours,
    VPRownersSalary,   
    VPRownersTaxes,
    VPRownersFlag,

    VPRidlFilter


} from 'app-components/Prime/VPdata/results.js';




import { LibArray } from "library";



const SIresult = (props) => {



    const result_items = props.result_items;



    var keyname;
    //var key;
    var kn;
    var item;
    var itemKeys;
    //var name;
    var value;
    var newValue;


    var _IDLS = {};
    var _OWNERS = [];
    var object;



    if(result_items 
    && result_items !== undefined
    )
    {


        /*

        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_overhead.form_items));

        for(keyname in zoneObj.calc_overhead.form_items)
        {
            if(keyname.match(/idl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_overhead.form_items[keyname];

                //order card item based on Prime VPdata
                item = zoneObj.calc_overhead.form_items[keyname];
          
                value = VPidlEmployee.value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                idl_employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }
            else
            {
                //console.log("KEYNAME: "+keyname);

                item = zoneObj.calc_overhead.form_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                value = VPoverhead[keyname].value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                VPoverhead[keyname] = {
                    name:item.name,
                    value:newValue
                };
 

            }

        }// -- for

        


        VPexpenses.value.monthly_expenses.value = result_items['totals'].total;
        VPincome.value.monthly_sales.value = zoneObj.calc_overhead.form_items['settings'].value.projected_monthly_sales.value;


        //overhead_percent = result_items['overhead'].overhead;
        //overhead_percent = parseFloat(overhead_percent) 
        //overhead_percent = overhead_percent.toFixed(1) + "%";



        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        */
        

        //console.log("RESULT ITEMS...... ");

        for(keyname in result_items)
        {
            //console.log("KEYNAME: "+keyname);

            //item = result_items[keyname];
            //console.log(JSON.stringify(item,null,2));


            if(keyname.match(/idl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_overhead.form_items[keyname];

                //order card item based on Prime VPdata
                //item = zoneObj.calc_overhead.form_items[keyname];
          

                item = result_items[keyname];
                console.log(JSON.stringify(item,null,2));

                //itemKeys = Object.keys(item);
                //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));



                value = VPRidlFilter.value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = {

                        print       :value[kn].print,
                        name        :value[kn].name,
                        value       :item.value[kn],
                        required    :value[kn].required,
                        default     :""
                    }
                  
                }
                _IDLS[keyname] = {

                    name    :keyname,
                    value   :newValue

                };
                console.log("IDLS: "+JSON.stringify(_IDLS,null,2));




            }
            else
            {
                //console.log("KEYNAME: "+keyname);


                item = result_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                itemKeys = Object.keys(item);
                //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));

    

                switch(keyname)
                {
                case'overhead':


                    //console.log(VPRoverhead.value);
      
            
                    value = VPRoverhead.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {

                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRoverhead.value = newValue;
                    //console.log("OVERHEAD: "+JSON.stringify(VPRoverhead,null,2));


                break;
                case'owners':

                    //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));


                    var flag;
                    var flagRegx

                    value = VPRownersFlag.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            flagRegx = new RegExp("<br/>","gi");
                            flag = item[kn].replace(flagRegx,"");

                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :flag,
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersFlag.value = newValue;
                    console.log("OWNERS FLAG: "+JSON.stringify(VPRownersFlag,null,2));



                    value = VPRownersST.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersST.value = newValue;
                    //console.log("OWNERS ST: "+JSON.stringify(VPRownersST,null,2));



              
                    value = VPRownersIRA.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersIRA.value = newValue;
                    //console.log("OWNERS IRA: "+JSON.stringify(VPRownersIRA,null,2));





                    value = VPRownersWeekHours.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersWeekHours.value = newValue;
                    //console.log("OWNERS WEEK HOURS: "+JSON.stringify(VPRownersWeekHours,null,2));
   


                    value = VPRownersSalary.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersSalary.value = newValue;
                    //console.log("OWNERS SALARY: "+JSON.stringify(VPRownersSalary,null,2));




                    value = VPRownersTaxes.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersTaxes.value = newValue;
                    //console.log("OWNERS TAXES: "+JSON.stringify(VPRownersTaxes,null,2));






                break;
                case'totals':

                    value = VPRtotals.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRtotals.value = newValue;
                    //console.log("TOTALS: "+JSON.stringify(VPRtotals,null,2));



                break;
                default:
                }//===



            }


        
        }// -- for
        



    /*

        "total_idl_rate_yearly": "31200.00",
        "total_idl_burden_tax_monthly": "520.00",
        "total_idl_health_insurance_monthly": "500.00",
        "total_idl_rate_monthly": "2600.00",
        "total_idl_burden_ira_monthly": "52.00"

      
    */


        
        //var total_idl_rate_yearly = result_items['totals'].total_idl_rate_yearly;
        //var total_idl_rate_monthly = result_items['totals'].total_idl_rate_monthly;
        //var total_idl_health_insurance_monthly = result_items['totals'].total_idl_health_insurance_monthly;
        //var total_idl_burden_ira_monthly = result_items['totals'].total_idl_burden_ira_monthly;
        //var total_idl_burden_tax_monthly = result_items['totals'].total_idl_burden_tax_monthly;  


        //VPindirectLabor.value.idl_rate_yearly.value = total_idl_rate_yearly;
        ///VPindirectLabor.value.idl_rate_monthly.value = total_idl_rate_monthly;
        ///VPindirectLabor.value.idl_health_insurance_monthly.value = total_idl_health_insurance_monthly;
        //VPindirectLabor.value.idl_burden_ira_monthly.value = total_idl_burden_ira_monthly;
        //VPindirectLabor.value.idl_burden_tax_monthly.value = total_idl_burden_tax_monthly;
        

        
    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));





    var R = [];
    //var index;
    //index = 0;



    //============================================
    //OVERHEAD
    //============================================

    R.push({

        keyname     :"overhead",
        name        :VPRoverhead.name,
        value       :VPRoverhead.value,

    });

        









    //============================================
    //TOTALS
    //============================================

    R.push({

        keyname     :"totals",
        name        :VPRtotals.name,
        value       :VPRtotals.value,

    });





    /*

    //============================================
    //FLAG
    //============================================

    R.push({

        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    margin: "40px 20px 40px 20px"

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "16px",
                    fontWeight:         "normal",
                    fontColor:          "#454d5f",
                    textAlign:          "left",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"<b>"+VPRownersFlag.name+" :</b> "+VPRownersFlag.value.owners_flag.value,
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });

    */



    /*

   R.push({


        component: "TextBox",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop: "25px",
            marginBottom: "25px"
        },

        info:{

            thumb:{

                diff: false
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Owners Flag",
                pgraph:VPRownersFlag.value.owners_flag.value,
                image:false,
                button:false
            
            }
        ]



    });


    */






    
    //============================================
    //OWNERS
    //============================================

    _OWNERS.push(VPRownersST);
    _OWNERS.push(VPRownersIRA);
    _OWNERS.push(VPRownersWeekHours);
    _OWNERS.push(VPRownersSalary);
    _OWNERS.push(VPRownersTaxes);


    for(object of _OWNERS)
    {

        R.push({

            keyname     :object.name,
            name        :object.name,
            value       :object.value,

        });



    }//for of






    for(keyname in _IDLS)
    {

        R.push({

            keyname     :keyname,
            name        :_IDLS[keyname].name,
            value       :_IDLS[keyname].value,
    
        });


    }//for in

    




    return R;


}



export default SIresult;
