import styled from "styled-components";


const SlideShow = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "black",
    width:              props.width || "100%",
    height:             props.height || "500px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    min-height:100px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;


`;


const Controls = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "100%",
    height:             props.height || "50px",

    // width:              "800px",
    // height:             "400px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    top:                props.top || "calc(50% - 25px)",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "3",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    left:${props => props.left}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;



    const Button = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "orangered",
        borderRadius:       props.borderRadius || "50px",

        width:              props.width || "50px",
        height:             props.height || "50px",
        // width:              "800px",
        // height:             "400px",

        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 0",

        float:              props.float  || "left",


        fontFamily:         props.fontFamily || '"Verdana", sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight || "normal",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "#fff",

    
    
    }))`
    
        background-color:${props => props.backgroundColor};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:${props => props.float};

        overflow:hidden;

        cursor:pointer;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};




        .Text{

            background-color:transparent;
            width:100%;
            height:auto;

            padding:0 0 4px 0;
            margin:16px 0 0 0;

            position:relative;
            float:left;

            font-family:${props => props.fontFamily};     
            font-size: ${props => props.fontSize};
            font-weight: ${props => props.fontWeight};
            text-align: ${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


        }



    `;





const Layer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#454d5f",

    width:              props.width || "90%",
    height:             props.height || "500px",


    // width:              "800px",
    // height:             "400px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "1",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    left:${props => props.left}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;







const SlideScroller = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "100%",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"

  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow-x: scroll;  
    overflow-y: hidden;


`;



const SlideHolder = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",
    width:              props.width || "9999px",
    height:             props.height || "97%",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    //pos:                props.pos || "-100px"
    pos:                "0px"
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:"0 0 0 ${props => props.pos};

    position:relative;   
    float:left;

    overflow:hidden;





`;








const Slide = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#454d5f",

    width:              props.width || "90%",
    height:             props.height || "500px",


    // width:              "800px",
    // height:             "400px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",



    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};



`;




 

export {

    SlideShow,
    SlideScroller,
    SlideHolder,
    Slide,

    Controls,Button,
    Layer


}
  


