import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


// import {
//     //BrowserRouter as Router,
//     //Switch,
//     //Route,
//     //Link,

//     //useRouteMatch,
//     useLocation,  
//     //useHistory,
//     //useParams


// } from "react-router-dom";



import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useDeviceDetect from 'utils/hooks/DeviceDetect';
import useScreenOrientation from 'utils/hooks/ScreenOrientation';



//import FGrid from 'utils/components/Grid/FGrid';
import { LibElem } from 'library';

import {

    //Sky,
    Board,
    // Container,
    MainContainer,
    ReflectContainer,
    Layer,
    Slide,
    SlideReflect

} from './Styled';

import {
    LayerInfo, 
    //SlideInfo
} from './Info';





import Controls from './Controls';
import { 
    CarSlide,
    List,
    //CarSlideCommons,
    //ListOfCommons
}from './Slides';


const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));

    /*

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    */


    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- device detect -- //
    const { isMobile } = useDeviceDetect();

    // -- screen orientation -- //
    const screenOrientation = useScreenOrientation()



    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    // console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refSky = useRef(null);    
    const refBoard = useRef(null);
    const refContainer = useRef(null);
    const refContainerReflect = useRef(null);


    // -- state -- //
    const [currentOrientation, setCurrentOrientation] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    // const [screenW, setScreenW] = useState(0);
    // const [screenH, setScreenH] = useState(0);

    const [boardHeight, setBoardHeight] = useState("500px");
    const [skyHeight, setSkyHeight] = useState("500px");
    const [skyZ, setSkyZ] = useState(50);


    const [containerStyl, setContainerStyl] = useState({
        width:"100%",
        height:"calc(100% - 145px)",
        reflectHeight:"175px",
        margin:"0 0 0 0",
        reflect:{
            top:"0px"
        }


    });

    const [layers, setLayers] = useState([]);
    const [layerStyl, setLayerStyl] = useState({
        width:"100vw",
        //height:"calc(100vh - 100px)"
        height:"100%",
        top:"0px",
        left:"0px"
    });
    const [topLayer, setTopLayer] = useState({});
    // const [controlPanelButtons, setControlPanelButtons] = useState([]);
    const [controlPanelStatus, setControlPanelStatus] = useState('opened');


    const [layerRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
    const setLayerRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < layerRefs.length; i++)
            {
                if(layerRefs[i].id === ref.id) found = true;
            }
            if(!found) layerRefs.push(ref);
            //console.log(layerRefs.length);

        }

    };


    const [slideSize, setSlideSize] = useState({
        width:"720px",
        height:"504px"
    });

    
    const [thresholdA] = useState(1350);
    const [thresholdB] = useState(1280);
    const [mobiThreshold] = useState(1280);
    //const [mobiThreshold] = useState(1025);
    const [reservedSpace] = useState({

        width:300, // match to the controls web width
        height:181 //match to the controls mobi height

    });
   



    //##############################################################
    //##############################################################
    //##############################################################

    const handle = (hndl) => {



        console.log(hndl.data);

        switch(hndl.call)
        {
        case'setLayers':

        var i, array, object;
        var layers = hndl.data;
        array=[];
        for(i=0; i < layers.length; i++)
        {
            object = layers[i];
            object['zIndex'] = i+1;
            array.push(object);  
        }
        array = JSON.parse(JSON.stringify(array));
        dispatch(ModifyZoneStore({

            type:"UPDATE_ZONE",
            item:{
                key:"selected_svg",
                value:{"name":"custom","layers":array}
            }

        })); 
        setLayers(hndl.data);

            setLayers(hndl.data);

        break;
        case'setTopLayer':

            setTopLayer(hndl.data);
        break;
        case'setReservedSpace':

            console.log("RESERVED: "+hndl.data.height);
            //setReservedSpace(hndl.data);
        break;
        default:
        }

        //HandleControlPanel({call:"showhide"});


    };



    //##############################################################
    //##############################################################
    //##############################################################

    useEffect(() => {


        
        const effectHandle = (hndl) => {

        
            if(screenOrientation !== currentOrientation
            )
            {
                // alert('window.location.reload');
                // window.location.reload(false)

                //console.log("SVGboard: "+currentOrientation+" TO "+screenOrientation);

                setCurrentOrientation(screenOrientation);

            }else{

                let screenWidth;
                let screenHeight;

                if(isMobile)
                {
                    // screenWidth = window.screen.availWidth;
                    // screenHeight = window.screen.availHeight;

                    // screenWidth = window.innerWidth;
                    // screenHeight = window.innerHeight;

                    screenWidth = width;
                    screenHeight = height;

                    //alert("width x height: "+screenWidth+" "+screenHeight);

                }else{
                    screenWidth = width;
                    screenHeight = height;
                }

          

                // -- Show Sizing -- //

                //let headerCall = props.databind.settings.appearance.header.headerCall
                //let headerW = props.databind.headerSizes.groupW;
                let headerH = props.databind.headerSizes.groupH;

                var boardH = props.databind.viewHeight;


                var vh;
                
                //if(headerCall !== 'C')
                //{
                    if(isNaN(boardH))
                    {
                        vh = boardH;
                        if(boardH.match(/px/gi)) vh = boardH.split("px")[0];
                    }else{ 
                        vh = boardH;
                    }
                    if(vh > screenHeight) vh = screenHeight;
                    boardH = parseInt(vh) - headerH - (0);

                    //console.log(boardH);
                    //console.log("yeah!!!");
                //}

                //alert("boardH: "+boardH);
                setBoardHeight(boardH+"px");
                setSkyHeight(boardH+"px");



                //console.log("handle controlPanelStatus");


                var overallHeight;
                overallHeight = boardH;

                var containerHeight,containerDif,containerMargin; 
                var reflectHeight,reflectTop;
                //var runHeight;

                //containerDif = (21.96/100) * overallHeight;
                containerDif = (21.96/100) * overallHeight;
                containerDif = Math.ceil(containerDif);
                containerHeight = overallHeight - containerDif;
                containerHeight = Math.ceil(containerHeight);

                // containerHeight = overallHeight - 150;


                // alert(""
                // +"\nboardH: "+boardH
                // +"\noverallheight: "+overallHeight
                // +"\ncontainerDif: "+containerDif
                // +"\ncontainerHeight: "+containerHeight
                // )



                if(isMobile) containerMargin = "-2px 0 0 0";
            

                // setContainerStyl({
                //     width:"100%",
                //     height:containerHeight + "px",
                //     reflectHeight:containerDif + "px",
                //     margin:containerMargin
                // })


                reflectHeight = containerHeight;
                reflectTop = containerHeight;




                var layerWInt;
                var layerHInt;
                var layerLeftInt;
                var layerTopInt;
    

                // -- Adjustments w/ Bottom Menu -- //

                if(screenWidth===screenHeight || screenWidth < screenHeight)
                {

                    // console.log("equal or less");
                              
                    layerWInt = refBoard.current.offsetWidth;
                    layerHInt = (54.8/100) * layerWInt;
                    layerLeftInt = 0;

                    //layerTopInt = (refBoard.current.offsetHeight - layerHInt)/4;
                    //layerTopInt = (overallHeight - layerHInt)/2 - (reservedSpace.height);

                    
                    layerTopInt = 0

                    containerHeight = layerHInt;
                    reflectTop = containerHeight;

                    //console.log(screenWidth);


                    if(screenWidth < 376
                    //&& containerHeight*2 < refBoard.current.offsetHeight - (reservedSpace.height + 50)
                    //&& refBoard.current.offsetHeight - layerHInt < (refBoard.current.offsetHeight/2)
                    )
                    {

                        //console.log("Smallest");
                        layerWInt = refBoard.current.offsetWidth;
                        layerHInt = (100/100) * layerWInt;
                        layerLeftInt = 0;
    
                        //layerTopInt = (refBoard.current.offsetHeight - layerHInt)/4;
                        //layerTopInt = (overallHeight - layerHInt)/2 - (reservedSpace.height);
                        layerTopInt = (110/100) * layerWInt - (64.9/100) * layerWInt;
                    
                        if(screenHeight-reservedSpace.height < layerHInt*1.5)
                        {
                            layerHInt = (64.8/100) * layerWInt;
                            layerTopInt = (64.8/100) * layerWInt - (54.8/100) * layerWInt;
                        }

                        if(layerHInt > reservedSpace.height*1.2)
                        {
                            layerHInt = (90/100) * layerWInt;
                            layerTopInt = (90/100) * layerWInt - (54.8/100) * layerWInt;
                        }



                        // if(screenHeight > 650)
                        // {
                        //     if(layerHInt > reservedSpace.height*1.2)
                        //     {
                        //         layerHInt = (90/100) * layerWInt;
                        //         layerTopInt = (90/100) * layerWInt - (54.8/100) * layerWInt;
                        //     }

                        //     if(screenWidth < 350)
                        //     {
                        //         layerHInt = (100/100) * layerWInt;
                        //         layerTopInt = (100/100) * layerWInt - (54.8/100) * layerWInt;
                        //     }

                        // }

    
                        containerHeight = layerHInt;

                        reflectTop = containerHeight;
                        if(refBoard.current.offsetHeight/1.5 > layerHInt)
                        {
                            reflectTop = reflectTop + 0;
                        }
                 

    
                    }
                    else
                    if(screenWidth < 475
                    //&& containerHeight*2 < refBoard.current.offsetHeight - (reservedSpace.height + 50)
                    //&& refBoard.current.offsetHeight - layerHInt < (refBoard.current.offsetHeight/2)
                    )
                    {

                        //console.log("Smallest");
                        layerWInt = refBoard.current.offsetWidth;
                        layerHInt = (90/100) * layerWInt;
                        layerLeftInt = 0;
    
                        //layerTopInt = (refBoard.current.offsetHeight - layerHInt)/4;
                        //layerTopInt = (overallHeight - layerHInt)/2 - (reservedSpace.height);
                        layerTopInt = (90/100) * layerWInt - (54.8/100) * layerWInt;

                        if(screenHeight-reservedSpace.height < layerHInt*1.5)
                        {
                            layerHInt = (64.8/100) * layerWInt;
                            layerTopInt = (64.8/100) * layerWInt - (54.8/100) * layerWInt;
                        }

                        //alert(layerHInt+" "+reservedSpace.height*1.2);





                        if(screenHeight > 650)
                        {
                            if(layerHInt > reservedSpace.height*1.2)
                            {
                                layerHInt = (80/100) * layerWInt;
                                layerTopInt = (80/100) * layerWInt - (54.8/100) * layerWInt;
                            }
    
                        //     // if(screenWidth < 400)
                        //     // {
                        //     //     if(layerHInt > reservedSpace.height*1.2)
                        //     //     {
                        //     //         layerHInt = (100/100) * layerWInt;
                        //     //         layerTopInt = (100/100) * layerWInt - (54.8/100) * layerWInt;
                        //     //     }

                        //     // }
                        //     // else
                        //     // if(screenWidth < 425)
                        //     // {
                        //     //     if(layerHInt > reservedSpace.height*1.2)
                        //     //     {
                        //     //         layerHInt = (90/100) * layerWInt;
                        //     //         layerTopInt = (90/100) * layerWInt - (54.8/100) * layerWInt;
                        //     //     }

                        //     // }
                        //     // else
                        //     // if(screenWidth < 450)
                        //     // {
                        //     //     if(layerHInt > reservedSpace.height*1.2)
                        //     //     {
                        //     //         layerHInt = (85/100) * layerWInt;
                        //     //         layerTopInt = (85/100) * layerWInt - (54.8/100) * layerWInt;
                        //     //     }

                        //     // }

                        }

                        containerHeight = layerHInt;
    
                        reflectTop = containerHeight;
                        if(refBoard.current.offsetHeight/1.5 > layerHInt)
                        {
                            reflectTop = reflectTop + 0;
                        }
                 
    
                    }
                    // else
                    // if(screenWidth < 550 
                    // //&& containerHeight*2 < refBoard.current.offsetHeight - (reservedSpace.height + 50)
                    // //&& refBoard.current.offsetHeight - layerHInt < (refBoard.current.offsetHeight/2)
                    // )
                    // {

                    //     //console.log("Smallest");
                    //     layerWInt = refBoard.current.offsetWidth;
                    //     layerHInt = (90/100) * layerWInt;
                    //     layerLeftInt = 0;
    
                    //     //layerTopInt = (refBoard.current.offsetHeight - layerHInt)/4;
                    //     //layerTopInt = (overallHeight - layerHInt)/2 - (reservedSpace.height);
                    //     layerTopInt = (90/100) * layerWInt - (54.8/100) * layerWInt;

                    //     if(screenHeight-reservedSpace.height < layerHInt*1.5)
                    //     {
                    //         if(screenHeight > 625)
                    //         {


                    //             alert('change')
                    //             layerHInt = (64.8/100) * layerWInt;
                    //             layerTopInt = (64.8/100) * layerWInt - (54.8/100) * layerWInt;


                    //         }


                    //     }


                    //     // if(layerHInt > reservedSpace.height*1.2)
                    //     // {
                    //     //     layerHInt = (85/100) * layerWInt;
                    //     //     layerTopInt = (85/100) * layerWInt - (54.8/100) * layerWInt;
                    //     // }


                    //     // if(screenWidth < 501)
                    //     // {
                    //     //     if(layerHInt > reservedSpace.height*1.2)
                    //     //     {
                    //     //         layerHInt = (90/100) * layerWInt;
                    //     //         layerTopInt = (90/100) * layerWInt - (54.8/100) * layerWInt;
                    //     //     }

                    //     // }


                    //     containerHeight = layerHInt;
                    //     reflectTop = containerHeight;
                    //     if(refBoard.current.offsetHeight/1.5 > layerHInt)
                    //     {
                    //         reflectTop = reflectTop + 0;
                    //     }
               

                    // }
                    else
                    if(screenWidth < 675 
                    //&& containerHeight*2 < refBoard.current.offsetHeight - (reservedSpace.height + 50)
                    //&& refBoard.current.offsetHeight - layerHInt < (refBoard.current.offsetHeight/2)
                    )
                    {

                        //console.log("Smallest");
                        layerWInt = refBoard.current.offsetWidth;
                        layerHInt = (64.9/100) * layerWInt;
                        layerLeftInt = 0;
    
                        //layerTopInt = (refBoard.current.offsetHeight - layerHInt)/4;
                        //layerTopInt = (overallHeight - layerHInt)/2 - (reservedSpace.height);
                        layerTopInt = (64.8/100) * layerWInt - (54.8/100) * layerWInt;

                        if(screenHeight-reservedSpace.height < layerHInt*1.5)
                        {
                            layerHInt = (54.8/100) * layerWInt;
                            layerTopInt = (54.8/100) * layerWInt - (54.8/100) * layerWInt;
                        }


                    
                        containerHeight = layerHInt;
                        reflectTop = containerHeight;
                        if(refBoard.current.offsetHeight/1.5 > layerHInt)
                        {
                            reflectTop = reflectTop + 0;
                        }

                    }



                }else{

       
                    // console.log("greater than");

                    layerHInt = overallHeight;
                    layerWInt = (142/100) * layerHInt;

                    layerTopInt = 0 + "px";
                    //layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2;
        
                    if(hndl.data === 'opened') 
                    {
                        layerLeftInt = 0;
                    }else{
                        layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2;
                    }

                }


                //console.log(screenW+"w hold"+mobiThreshold);




                // -- Adjustments w/ Side Menu -- //

                if(screenWidth > screenHeight)
                {
               
                    layerHInt = overallHeight - (reservedSpace.height);
                    layerWInt = (142/100) * layerHInt;


                    if(screenWidth < mobiThreshold)
                    {

                        //console.log("mobiThreshold: "+mobiThreshold);

                        //containerDif = (21.96/100) * overallHeight;
                        containerDif = (40/100) * overallHeight;
                        containerDif = Math.ceil(containerDif);
                        containerHeight = overallHeight - containerDif;
                        containerHeight = Math.ceil(containerHeight);

      
                        layerHInt = containerHeight - 40; 
                        //layerHInt = containerHeight - (reservedSpace.height/2);                  
                        //layerHInt = overallHeight - (reservedSpace.height/2);
                        //layerHInt = overallHeight - (reservedSpace.height);
                        //layerWInt = (142/100) * layerHInt;
                        layerWInt = (180/100) * layerHInt;

                        layerTopInt = 0
                        layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2;
                        //layerLeftInt = 0;
            
            
                        // if(hndl.data === 'opened') 
                        // {
                        //     layerLeftInt = 0;
                        // }else{
                        //     layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2;
                        // }


                        //containerDif = (21.96/100) * overallHeight;
                        containerDif = (40/100) * overallHeight;
                        containerDif = Math.ceil(containerDif);
                        containerHeight = overallHeight - containerDif;
                        containerHeight = Math.ceil(containerHeight);

                        containerHeight = containerHeight - 45;
                        reflectTop = containerHeight;




                    }
                    else
                    {

                        //layerHInt = screenHeight;
                        layerHInt = overallHeight;
                        layerWInt = (142/100) * layerHInt;
                        layerTopInt = 0;
                        //layerLeftInt = 0;
                        layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2 - (reservedSpace.width/2);

                        if(screenWidth - reservedSpace.width < layerWInt + 100)
                        {
                            layerWInt = (135/100) * layerHInt;
                            layerTopInt = (137.8/100) * layerWInt - (135/100) * layerWInt;
                            layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2 - (reservedSpace.width/2);


                            if(screenWidth < thresholdA)
                            {
                                layerWInt = (125/100) * layerHInt;
                                layerTopInt = (125/100) * layerWInt - (117.55/100) * layerWInt;
                                layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2 - (reservedSpace.width/2);
    
                            }

                            if(screenWidth < thresholdB)
                            {
                                layerWInt = (90/100) * layerHInt;
                                layerTopInt = (90/100) * layerWInt - (58.3/100) * layerWInt;
                                layerLeftInt = (refBoard.current.offsetWidth - layerWInt)/2 - (reservedSpace.width/2);
    
                            }


                        }

                    }
                    


                }







                // if(screenWidth < screenHeight)
                // {

                //     layerTopInt = (overallHeight - layerHInt)/2 - (reservedSpace.height/2);
                //     if(layerTopInt < -20) 
                //     {
                //         layerTopInt = 50;
                //     }
                //     if(screenHeight - screenWidth < (reservedSpace.height/2)) layerTopInt = 0;
                


                // }
                // layerTopInt = (refBoard.current.offsetHeight - layerHInt)/2 - 100;




                // console.log(""
            
                //     +"\nwidth..."+width
                //     +"\nheight..."+height

                //     +"\nisMobile..."+isMobile
                //     +"\ncurrentOrientation..."+currentOrientation
                //     +"\nscreenOrientation..."+screenOrientation

                //     +"\nscreen.width..."+window.screen.width
                //     +"\nscreen.height..."+window.screen.height

                //     +"\nscreenAvailW..."+window.screen.availWidth
                //     +"\nscreenAvailH..."+window.screen.availHeight

                //     +"\nboardW : "+refBoard.current.offsetWidth
                //     +"\nboardH : "+refBoard.current.offsetHeight

                //     +"\nlayerWInt : "+layerWInt
                //     +"\nlayerHInt: "+layerHInt

                //     +"\nlayerTopInt : "+layerTopInt
                //     +"\nlayerLeftInt : "+layerLeftInt

                // ); 



            

                //reflectHeight = (refBoard.current.offsetHeight - containerHeight);
                reflectHeight = containerHeight;
                if(screenWidth < mobiThreshold) 
                {
                    reflectHeight = (boardH - containerHeight) - reservedSpace.height + 20;
                    //if(reflectHeight < 180) reflectHeight = 180;
                    //alert(reflectHeight);      
                }else{
                    reflectHeight = (boardH - containerHeight);
                    //if(reflectHeight < 180) reflectHeight = 180;
                    //alert(reflectHeight);     
                }

       


                if(isMobile) reflectTop = reflectTop - 2;


                setContainerStyl({
                    width:"100%",
                    height:containerHeight + "px",
                    reflectHeight:reflectHeight + "px",
                    margin:containerMargin,
                    reflect:{top:reflectTop + 0 + "px"}
                })

                setLayerStyl({
                    width:layerWInt + "px",
                    //width:"100%",
                    height:layerHInt + "px",
                    top:layerTopInt + "px",
                    left:layerLeftInt + "px"
                });



                // var slideHInt
                // slideHInt = refBoard.current.offsetHeight

                // var slideWInt
                // slideWInt = (142/100) * slideHInt;

                var slideW = layerWInt + "px";
                var slideH = layerHInt + 'px';

                setSlideSize({
                    width:slideW,
                    //width:"100%",
                    height: slideH
                });
                setControlPanelStatus(hndl.data);

            }


        }






        if(!isLoaded)
        {

            setIsLoaded(true);
    
            var i;
            var object;
            var array = [];

            // for(i=0; i < LayerInfo.length; i++)
            // {
            //     object = LayerInfo[i];
            //     object['zIndex'] = i+1;
            //     array.push(object);  
            // }
            if(zoneObj['selected_svg'] && zoneObj['selected_svg'] !== undefined)
            {

                var layers = JSON.parse(JSON.stringify(zoneObj['selected_svg'].layers));
                for(i=0; i < layers.length; i++)
                {
                    object = layers[i];
                    object['zIndex'] = i+1;
                    array.push(object);  
                }

                // dispatch(ModifyZoneStore({

                //     type:"DELETE_ZONE",
                //     item:{
                //         key:"selected_svg",
                //         value:false
                //     }
        
                // })); 
        
            }else{

                for(i=0; i < LayerInfo.length; i++)
                {
                    object = LayerInfo[i];
                    object['zIndex'] = i+1;
                    array.push(object);  
                }
          
            }
            setLayers(array);


            // switch(level)
            // {
            // case 'mains':

            //     for(i=0; i < Categories.mains.length; i++)
            //     {
            //         object = Categories.mains[i];
            //         object['zIndex'] = i+1;
            //         array.push(object);  
            //     }
            //     //setLayers(array);
            // break;
            // case 'subs': 

            //     var subCats;
            //     subCats = Categories.subs[subCall];
            //     for(i=0; i < subCats.length; i++)
            //     {
            //         object = subCats[i];
            //         object['zIndex'] = i+1;
            //         array.push(object);  
            //     }
            //     //setLayers(array)
            // break;
            // case 'choices':

            //     var choices;
            //     choices = Categories.choices[choicesCall];
            //     for(i=0; i < choices.length; i++)
            //     {
            //         object = choices[i];
            //         object['zIndex'] = i+1;
            //         array.push(object);  
            //     }
            //     //setLayers(array);
            // break;
            // }




           



            var highZindex;
            var skyZindex;
            highZindex = LibElem.HighestZ();  
            skyZindex = array.length + 10;
            if(skyZindex > highZindex)
            {
                skyZindex = highZindex - 1;
            }
            setSkyZ(array.length + 10);

        }//isLoaded



        /**
         * 
         * 
         * slideWidth - choice
         * 
         * viewableSlideCount
         * 
         * ActionLayer
         * DisplayLayer
         * 
         *
         * 
         */


 
        // console.log(JSON.stringify(props,null,2));
        //alert('props');




        //---------------------------------------
        // -- Layer and Slide Sizing -- //
        //---------------------------------------

        // -- check layer size matches board size -- //
   
        // var layerIsntMaxed;
        // layerIsntMaxed = false;
        // if(layerRefs.length > 0)
        // {
        //     if(document.getElementById(layerRefs[0].id).offsetHeight < refBoard.current.offsetHeight-25
        //     )
        //     {
        //         layerIsntMaxed = true;
        //         //console.log("layerIsntMaxed: "+ layerIsntMaxed);
            
        //     }

        // }


        // -- adjust positioning and sizing of items on screen changes -- //
   
        // if(width !== screenW 
        // || height !== screenH
        // )
        // {
        //     effectHandle({

        //         call:"controlPanelStatus",
        //         data:"opened"

        //     });
        //     setScreenW(width);
        //     setScreenH(height);


        // }



        // -- resposition if layerIsntMaxed run once layerWasMaxed -- //

        // if(screenW > screenH)
        // {

        //     if(layerIsntMaxed && !layerWasMaxed)
        //     {
        //         setLayerWasMaxed(true);
        //         effectHandle({

        //             call:"controlPanelStatus",
        //             data:"opened"
    
        //         });
        
        //     }

        // }



        effectHandle({

            call:"controlPanelStatus",
            data:"opened"

        });


    },
    [
        props,
        dispatch,zoneObj,
        width,
        height,
        isMobile,
        screenOrientation,
        currentOrientation,
        isLoaded,
        refBoard,
        refContainer,
        controlPanelStatus,
        layerRefs,
        mobiThreshold,thresholdA,thresholdB,
        reservedSpace

   
    ])














    //##############################################################
    //##############################################################
    //##############################################################

    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (<div>

            <Board
            ref={refBoard}
            height={boardHeight}
            >

                <div 
                ref={refSky}
                style={{
        
                    width:"100%",
                    height:skyHeight,
                    position:"absolute",
                    top:"0px",
                    left:"0px",
                    zIndex:skyZ

                }}>

                    <Controls
                        handle={handle}
                        list={List}               
                        info={LayerInfo}
                        layers={layers}
                        topLayer={topLayer}
                        mobiThreshold={mobiThreshold}
      
                    />

                </div>


                {layers.map((item, i) => (
                <div key={i}>

                    <Layer
                        ref={setLayerRef}
                        id={"layer_"+i}
                        //backgroundColor={item.backgroundColor}
                        zIndex={item.zIndex}
                        style={{

                            width:layerStyl.width,
                            height:layerStyl.height,
                            top:layerStyl.top,
                            left:layerStyl.left,

                            //transform: "scaleY(-1)"

                        }}
                    >
                    </Layer>

                </div>
                ))}


            </Board>

        </div>)

    } 
    else 
    {



        return (<div>



            <Board
            ref={refBoard}
            height={boardHeight}
            >

                <div 
                ref={refSky}
                style={{
        
                    width:"100%",
                    height:skyHeight,
                    position:"absolute",
                    top:"0px",
                    left:"0px",
                    zIndex:skyZ

                }}>

                    <Controls
                        handle={handle}
                        list={List}               
                        info={LayerInfo}
                        layers={layers}
                        topLayer={topLayer}
                        mobiThreshold={mobiThreshold}
                    />

                </div>




                <MainContainer
                ref={refContainer}
                //backgroundColor={"yellow"}
                //height={"calc(100% - 145px)"}
                width={containerStyl.width}
                height={containerStyl.height}
                margin={containerStyl.margin}
                >

                    {layers.map((item, i) => (
                    <div key={i}>

                        <Layer
                            ref={setLayerRef}
                            id={"layer_"+i}
                            //backgroundColor={item.backgroundColor}
                            zIndex={item.zIndex}
                            style={{

                                width:layerStyl.width,
                                height:layerStyl.height,
                                top:layerStyl.top,
                                left:layerStyl.left,

                                //transform: "scaleY(-1)"


                                //opacity:0.1

                            }}
                        >

        

                            <Slide
                            //backgroundColor={"orange"}
                            //width={slideSize.width}
                            //height={slideSize.height}
                            style={{

                                width:slideSize.width,
                                height:slideSize.height,

            
                            }}
                            >
                                <CarSlide 


                                    //svgViewBox={"0 -108 720 504"}

                                    // svgStyle={{
                                    //     backgroundColor:"blue",
                                    //     opacity:"0.1"
                                    // }}
                
                                    width={slideSize.width}
                                    height={slideSize.height}
                                    call={item.slide.component}
                                
                                />


                            </Slide>

                        </Layer>


                    </div>
                    ))}


                </MainContainer>




                <ReflectContainer
                ref={refContainerReflect}
                //backgroundColor={"aqua"}
                //height={"calc(100% - 145px)"}
                width={containerStyl.width}
                height={containerStyl.height}

                top={containerStyl.reflect.top}

                reflectHeight={containerStyl.reflectHeight}
                style={{

                    transform: "scaleY(-1)"

                }}
                >
                    <div
                    className={'ReflectOverlay'}
                    >
                    </div>

                    <div
                    className={'ReflectGradient'}
                    >
                    </div>



                    {layers.map((item, i) => (
                    <div key={i}>

                        <Layer
                            ref={setLayerRef}
                            id={"layer_"+i}
                            //backgroundColor={item.backgroundColor}
                            zIndex={item.zIndex-1}
                            style={{

                                width:layerStyl.width,
                                height:layerStyl.height,
                                top:layerStyl.top,
                                left:layerStyl.left,

                                //transform: "scaleY(-1)"

                            }}
                        >

        

                            <SlideReflect
                            //backgroundColor={"orange"}
                            //width={slideSize.width}
                            //height={slideSize.height}
                            style={{

                                width:slideSize.width,
                                height:slideSize.height,
            
                            }}
                            >
                                <CarSlide 
                
                                    width={slideSize.width}
                                    height={slideSize.height}
                                    call={item.slide.component}
                                
                                />


                            </SlideReflect>

                        </Layer>


                    </div>
                    ))}


                </ReflectContainer>




            </Board>

                   
        </div>)


    }


}

export default Index;



