const DefaultFetchResponse = {


    "scene":{
        
        "name": "Home",
        "path": "/Users/tm/Work/PROJEX/reaxBuilds/reax1/src/app-components/Zone/Home/",
        "sceneName": "home",
        "sceneInfo": [
            {

                styl:{

                    call:"indent",
                    class:"IndentRow",
        
                    marginTop:"50px",
                    marginBottom:"0"
        
        
                },
                call:'TxtComponent',
                info:{
        
                    call:'flex', //fixed, flex
                    gridType: "TextBox", //Newspaper
                    gridBgColor:'transparent',
                    gridCall: "grid_cell",  //grid_cell or grid_column_cell
                    cellCall: "default",  //container or default 
                    modCall: 'fill_div',
                    thumb: {
        
                        call    : "default", //flex_height or default
                        diff    : 0
        
                    },    
                    cellBorder: {
                        "boolean"       :true,
                        "thickness"     :1,//px
                        "color"         :"gray"  
                    },
                    gutter: {
                        "boolean"   :true,
                        "size"      :20
                    },
                    thresh: {
                        "refWidth"    :false,   //number or false = default screen width
                        "across1"     :501,
                        "across2"     :801,
                        "across3"     :1050
                    }
        
        
        
                },
        
                items:[{
        
                    heading : "Default Page",
                    pgraph  : "This is a default page"
                }]


            }
        
        
        ],
        "sceneSettings": {
            "call": "settings",
            "data": {
                "duration": 8000,
                "fadetime": 4000
            },
            "multi": false
        }

    },



//settings-start
"settings":{
  "menu": [
    {
      "name": "Home",
      "descr": "This link will go to Home",
      "to": "",
      "category": "main"
    },
    {
      "name": "Cars",
      "descr": "This link will go to Cars",
      "to": "Cars",
      "category": "main"
    },
    {
      "name": "Configurator",
      "descr": "This link will go to Configurator",
      "to": "Configurator",
      "category": "main"
    },
    {
      "name": "McLaren-Honda Mp4/4",
      "descr": "McLaren-Honda Mp4/4",
      "to": "McLarenHonda",
      "category": "Honda Racing"
    },
    {
      "name": "Williams-Honda FW11/B",
      "descr": "Williams-Honda FW11/B",
      "to": "WilliamsHonda",
      "category": "Honda Racing"
    },
    {
      "name": "Spoon Sports AP1",
      "descr": "Spoon Sports AP1",
      "to": "SpoonSportsAP1",
      "category": "Honda Racing"
    },
    {
      "name": "S2000 CR",
      "descr": "S2000 CR",
      "to": "S2000CR",
      "category": "Honda OEM"
    },
    {
      "name": "S2000 AP1 / AP2",
      "descr": "S2000 AP1 / AP2",
      "to": "S2000",
      "category": "Honda OEM"
    },
    {
      "name": "Contact Form",
      "descr": "This link will go to Contact Form",
      "to": "ContactForm",
      "category": "main"
    },
    {
      "name": "Page 1",
      "descr": "This link will go to Page 1",
      "to": "Page1",
      "category": "Samples"
    },
    {
      "name": "Page 2",
      "descr": "This link will go to Page 2",
      "to": "Page2",
      "category": "Samples"
    },
    {
      "name": "Page 3",
      "descr": "This link will go to Page 3",
      "to": "Page3",
      "category": "Samples"
    }
  ],
  "routes": [
    {
      "name": "Home",
      "descr": "Home",
      "to": "",
      "category": "main"
    },
    {
      "name": "Cars",
      "descr": "This link will go to Cars",
      "to": "/Cars",
      "category": "main"
    },
    {
      "name": "Configurator",
      "descr": "This link will go to Configurator",
      "to": "/Configurator",
      "category": "main"
    },
    {
      "name": "McLaren-Honda Mp4/4",
      "descr": "McLaren-Honda Mp4/4",
      "to": "/McLarenHonda",
      "category": "Honda Racing"
    },
    {
      "name": "Williams-Honda FW11/B",
      "descr": "Williams-Honda FW11/B",
      "to": "/WilliamsHonda",
      "category": "Honda Racing"
    },
    {
      "name": "Spoon Sports AP1",
      "descr": "Spoon Sports AP1",
      "to": "/SpoonSportsAP1",
      "category": "Honda Racing"
    },
    {
      "name": "S2000 CR",
      "descr": "S2000 CR",
      "to": "/S2000CR",
      "category": "Honda OEM"
    },
    {
      "name": "S2000 AP1 / AP2",
      "descr": "S2000 AP1 / AP2",
      "to": "/S2000",
      "category": "Honda OEM"
    },
    {
      "name": "Contact Form",
      "descr": "This link will go to Contact Form",
      "to": "ContactForm",
      "category": "main"
    },
    {
      "name": "Page 1",
      "descr": "This link will go to Page 1",
      "to": "/Page1",
      "category": "Samples"
    },
    {
      "name": "Page 2",
      "descr": "This link will go to Page 2",
      "to": "/Page2",
      "category": "Samples"
    },
    {
      "name": "Page 3",
      "descr": "This link will go to Page 3",
      "to": "/Page3",
      "category": "Samples"
    }
  ],
  "header": [
    {
      "name": "Home",
      "descr": "This link will go to Home",
      "to": "",
      "category": "main"
    },
    {
      "name": "Cars",
      "descr": "This link will go to Cars",
      "to": "/Cars",
      "category": "main"
    }
  ],
  "appearance": {
    "menu": {
      "menuButtonBackgroundOverColor": "#00cc66",
      "menuColor": "white",
      "menuButtonTextOverColor": "black",
      "menuTabTextColor": "white",
      "menuBorder": "2px solid #c00",
      "menuTabColor": "#00cc66",
      "menuButtonTextOutColor": "black",
      "menuButtonBackgroundOutColor": "transparent"
    },
    "header": {
      "logoHeight": "40px",
      "headerOpacity": "0.9",
      "buttonTextOutColor": "white",
      "buttonBackgroundOverColor": "#00cc66",
      "navbarHeight": "35px",
      "headerMainHeight": "54px",
      "buttonBackgroundOutColor": "yellow",
      "logoColor": "black",
      "lineColor": "white",
      "buttonTextOverColor": "white",
      "logoMinWidth": "40px",
      "hamburgerColorOver": "red",
      "navbarColor": "#191e28",
      "logoWidth": "260px",
      "headerMainColor": "black",
      "buttonTextColorHold": "black",
      "lineHeight": "2px",
      "hamburgerColorOut": "#fcfcfc",
      "headerCall": "C",
      "buttonBackgroundColorHold": "orange"
    },
    "general": {
      "buttonTextOverColor": "black",
      "buttonTextOutColor": "white",
      "buttonBackgroundOverColor": "red",
      "linkOver": "orange",
      "buttonBorderOutColor": "blue",
      "buttonBackgroundOutColor": "blue",
      "linkOut": "#00cc66",
      "buttonBorderOverColor": "red"
    },
    "headings": {
      "h1": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "50px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h2": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "40px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h3": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "32px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h4": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "24px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "h5": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "20px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      }
    },
    "pgraphs": {
      "p1": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "20px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p2": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "18px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p3": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "16px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p4": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "14px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },
      "p5": {
        "fontFamily": "Arial, Helvetica, sans-serif",
        "fontSize": "12px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      }
    }
  },
  "graphic": {
    "icon": {
      "image": {
        "h": "200",
        "src": "admin/inventory/img/Icons/H--Logo--PNG--64x64_sys20211112144972id_.png",
        "w": "200"
      },
      "id": "20211112144972",
      "text": "",
      "title": "H Logo PNG 64x64",
      "bgColor": "black"
    },
    "logo": {
      "image": {
        "h": "60",
        "src": "admin/inventory/img/logos/s2rlogo-260x60_sys20210405143726id_.png",
        "w": "260"
      },
      "id": "20210405143726",
      "text": "",
      "title": "s2rlogo-260x60",
      "bgColor": "black"
    },
    "header": {
      "image": {
        "h": "40",
        "src": "admin/inventory/img/logos/s2rlogo_white_sys20210407101318id_.png",
        "w": "173"
      },
      "id": "20210407101318",
      "text": "",
      "title": "s2rlogo_white",
      "bgColor": "black"
    },
    "menu": {
      "image": {
        "h": "60",
        "src": "admin/inventory/img/logos/s2rlogo-260x60_sys20210405143726id_.png",
        "w": "260"
      },
      "id": "20210405143726",
      "text": "",
      "title": "s2rlogo-260x60",
      "bgColor": "black"
    }
  }
}
//settings-end














}

export default DefaultFetchResponse;





/*

admin/inventory/img/PowerDigitalMedia/


*/

