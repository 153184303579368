
 
function drawLine(ctx, startX, startY, endX, endY,color){
    ctx.save();
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(startX,startY);
    ctx.lineTo(endX,endY);
    ctx.stroke();
    ctx.restore();
}
 
function drawBar(ctx, upperLeftCornerX, upperLeftCornerY, width, height,color){
    ctx.save();
    ctx.fillStyle=color;
    ctx.fillRect(upperLeftCornerX,upperLeftCornerY,width,height);
    ctx.restore();
}
 

export const BarChart = function(options){

    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.data = options.data;
    this.colors = options.colors;
  
    this.draw = function(){

        var object;
        var maxValue = 0;

        //console.log(JSON.stringify(this.options.data,null,2))
        if(Object.prototype.toString.call(this.options.data) === '[object Array]'
        )
        {
            for(object of this.options.data)
            {
                maxValue = Math.max(maxValue,object.value);
            }
        }
        else
        {
            for (var categ in this.options.data)
            {
                maxValue = Math.max(maxValue,this.options.data[categ]);
            }
        }


        var canvasActualHeight = this.canvas.height - this.options.padding * 2;
        var canvasActualWidth = this.canvas.width - this.options.padding * 2;
 
        //drawing the grid lines
        var gridValue = 0;
        while (gridValue <= maxValue){
            var gridY = canvasActualHeight * (1 - gridValue/maxValue) + this.options.padding;
            drawLine(
                this.ctx,
                0,
                gridY,
                this.canvas.width,
                gridY,
                this.options.gridColor
            );
             
            //writing grid markers
            this.ctx.save();
            this.ctx.fillStyle = this.options.gridColor;
            this.ctx.textBaseline="bottom"; 
            this.ctx.font = "normal 14px Arial";
            this.ctx.fillText(gridValue, 10, gridY - 2);
            this.ctx.restore();
 
            gridValue+=this.options.gridScale;
        }      
  
        //drawing the bars
        var barIndex = 0;
        var numberOfBars = Object.keys(this.options.data).length;
        var barSize = (canvasActualWidth)/numberOfBars-1;



        var val;
        var barHeight;
        var minusNum = barSize/2;
 

        //console.log(JSON.stringify(this.options.data,null,2))
        if(Object.prototype.toString.call(this.options.data) === '[object Array]'
        )
        {
            for(object of this.options.data)
            {
                val = object.value;
                //console.log(JSON.stringify(val,null,2));
                barHeight = Math.round( canvasActualHeight * val/maxValue);

                if(barIndex === 0 
                //|| barIndex === Object.keys(this.options.data).length - 1
                )
                {
                    drawBar(
                        this.ctx,
                        0,
                        this.canvas.height - barHeight - this.options.padding,
                        1,
                        barHeight,
                        this.colors[barIndex%this.colors.length]
                    );

                }
                else
                if(barIndex > 0
                )
                {
                    drawBar(
                        this.ctx,
                        this.options.padding + (barIndex) * barSize - minusNum,
                        //this.options.padding + barIndex * barSize - minusNum,
                        this.canvas.height - barHeight - this.options.padding,
                        barSize-10,
                        barHeight,
                        this.colors[barIndex%this.colors.length]
                    );
        
                }
                else
                {
                    drawBar(
                        this.ctx,
                        this.options.padding + barIndex * barSize,
                        this.canvas.height - barHeight - this.options.padding,
                        barSize,
                        barHeight,
                        this.colors[barIndex%this.colors.length]
                    );

                }
                barIndex++;

            }


        }
        else
        {// basic object


            for (categ in this.options.data)
            {

                val = this.options.data[categ];
                barHeight = Math.round( canvasActualHeight * val/maxValue);

                if(barIndex === 0 
                //|| barIndex === Object.keys(this.options.data).length - 1
                )
                {
                    drawBar(
                        this.ctx,
                        0,
                        this.canvas.height - barHeight - this.options.padding,
                        30,
                        barHeight,
                        this.colors[barIndex%this.colors.length]
                    );

                }
                else
                if(barIndex > 0
                )
                {
                    drawBar(
                        this.ctx,
                        this.options.padding + barIndex * barSize - minusNum,
                        this.canvas.height - barHeight - this.options.padding,
                        barSize-10,
                        barHeight,
                        this.colors[barIndex%this.colors.length]
                    );
        
                }
                else
                {
                    drawBar(
                        this.ctx,
                        this.options.padding + barIndex * barSize,
                        this.canvas.height - barHeight - this.options.padding,
                        barSize,
                        barHeight,
                        this.colors[barIndex%this.colors.length]
                    );

                }
                barIndex++;


            }


        }//--
    



        /*
        //drawing series name
        this.ctx.save();
        this.ctx.textBaseline="bottom";
        this.ctx.textAlign="center";
        this.ctx.fillStyle = "#454d5f";
        this.ctx.font = "bold 14px Arial";
        this.ctx.fillText(this.options.seriesName, this.canvas.width/2,this.canvas.height);
        this.ctx.restore();  
        */
         

        /*
        //draw legend
        barIndex = 0;
        var legend = document.querySelector("legend[for='myCanvas']");
        var ul = document.createElement("ul");
        legend.append(ul);
        for (categ in this.options.data){
            var li = document.createElement("li");
            li.style.listStyle = "none";
            li.style.borderLeft = "20px solid "+this.colors[barIndex%this.colors.length];
            li.style.padding = "5px";
            li.textContent = categ;
            ul.append(li);
            barIndex++;
        }
        */



    }
}
 
 

/*

var myCanvas = document.getElementById("myCanvas");
myCanvas.width = 300;
myCanvas.height = 300;
   
var ctx = myCanvas.getContext("2d");


var myVinyls = {
    "Classical music": 10,
    "Alternative rock": 14,
    "Pop": 2,
    "Jazz": 12
};
 
var myBarChart = new BarChart(
    {
        canvas:myCanvas,
        seriesName:"Vinyl records",
        padding:20,
        gridScale:5,
        gridColor:"#eeeeee",
        data:myVinyls,
        colors:["#a55ca5","#67b6c7", "#bccd7a","#eb9743"]
    }
);


*/


