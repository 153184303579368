import React, { 

    useState, 
    useEffect, 
    useRef

} from 'react';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation, 
    useHistory,  
    //useParams

} from "react-router-dom";





import Canv from './Cmp/Canv';
import { CanvBox, CanvMsg, ChartKey } from './Styled';



import SVGChart from 'grfx/chart.svg';
import SVGChartOver from 'grfx/chart_over.svg';

import SVGPencil from 'grfx/pencil.svg';
import SVGPencilOver from 'grfx/pencil_over.svg';

import SVGTrash from 'grfx/trash.svg';
import SVGTrashOver from 'grfx/trash_over.svg';



import Handler from './Handler';



const Index = (props) => {


    //const { width, height } = useWindowDimensions();
    //console.log("WIDTH: "+width+"\nHEIGHT: "+height);


    // -- router -- //
    const location = useLocation(); 
    const history = useHistory();
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);





    // -- refs -- //

    const boxRefs = [];
    const setBoxRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < boxRefs.length; i++)
            {
                if(boxRefs[i].id === ref.id) found = true;
            }
            if(!found) boxRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };





    // -- ref -- //
    const refCanvBox = useRef(null);
    const refCanvMsg = useRef(null);  
    const refCanvas = useRef(null);
    const refDoughnutMsg = useRef(null);


    // -- state -- //
    const [canvasW, setCanvasW] = useState(null);
    const [canvasH, setCanvasH] = useState(null);

    const [chartInfo, setChartInfo] = useState(props.chart);
    const [doughnutMsgY, setDoughnutMsgY] = useState("calc(50% - 20px)");
    const [currentSlice, setCurrentSlice] = useState(false)


    useEffect(()=>{

        var canvW; 
        var canvH;
   
        canvW = refCanvas.current.offsetWidth;
        canvH = refCanvas.current.offsetHeight;
        //console.log("CANVAS: "+canvW+" X "+canvH);

        setCanvasW(canvW);
        setCanvasH(canvH);

        //console.log("CANVAS: "+canvW+" X "+canvH);
    
        var dMsgY;
        var revChartInfo;
    
        switch(props.chart.call)
        {
        case'bar':
        case'pie':

            canvH = refCanvBox.current.offsetHeight - refCanvMsg.current.offsetHeight;
            setCanvasH(canvH);
        break;
        case'doughnut':

 
            dMsgY = "calc(50% - "+refDoughnutMsg.current.offsetHeight/2+"px)";
            if(currentSlice)
            {
                dMsgY = "calc(50% - "+refDoughnutMsg.current.offsetHeight/2+"px)";

                revChartInfo = props.chart;
                //console.log(currentSlice.holeOutlineColor);
                revChartInfo.data.hole.outlineColor = currentSlice.holeOutlineColor;
                setChartInfo(revChartInfo);   
            
            }
            //console.log("DOUGHNUT MSG Y: "+dMsgY);

            setDoughnutMsgY(dMsgY);


            canvH = refCanvBox.current.offsetHeight;
            setCanvasH(canvH);
        break;
        default:
            canvH = refCanvBox.current.offsetHeight;
            setCanvasH(canvH);
        }//==
  


    },[
        props,
        refCanvBox,
        refCanvMsg,     
        refCanvas, 
        refDoughnutMsg,
        currentSlice
    ])



    // -- canv -- //

    const [ setCoordinates ] = Canv({

        canvasRef           :refCanvas,
        canvasWidth         :canvasW,
        canvasHeight        :canvasH,
        drawCheckerBoard    :props.drawCheckerBoard,
        draw                :props.draw,   
        render              :props.render,
        chart               :chartInfo

    });

    const updateCoords = (value) => {

        // update state within the Canv component
        //setCoordinates([...coordinates, value]);

        //console.log(coordinates);
        setCoordinates(value);

    }



    // -- handle -- //

    const handle = (hndl) => {

        hndl['canvas'] = refCanvas;
        hndl['chart'] = props.chart;
        hndl['handleState'] = (v) => {

            if(v.call === 'currentSlice') setCurrentSlice(v.value);   
            if(v.call === 'updateCoords') updateCoords(v.value);

        };

        Handler(hndl);

    }






    // -- handle -- //

    const handleUrl = (hndl) => {

        //alert(pathname + hndl.url);

        //console.log("LOCATION: "+JSON.stringify(location,null,2));

        var url = "/Dashboard/" + hndl.url;
        //window.location.pathname = url;
        //history.replace(url)
        history.push(url);

    }



    // -- handle -- //

    const handleOverOut = (hndl) => {


        switch(hndl.from)
        {
        case'controls':


            //console.log("controls");

            var box_id;
            var buttonName;
            var btn;
            var img;



            var i;
            var found;
            found = false;
            if(boxRefs.length > 0)
            {
                for(i=0; i < boxRefs.length; i++)
                {
                    if(boxRefs[i].id === "chartKeyLine"+hndl.indx)
                    {
                        found = true;
                        break;
                    }
                }
            
            }

            if(found)
            {
                box_id = "chartKeyLine"+hndl.indx;
                buttonName = hndl.buttonName;

                //controls = document.getElementById(hndl.id+"_controls");
                btn = document.getElementById(box_id+"_controls_"+buttonName);
                img = document.getElementById(box_id+"_controls_"+buttonName+"_img");

                switch(hndl.call)
                {
                case'mouseEnter':
                case'mouseOver':

                    btn.style.backgroundColor = 'transparent';


                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChartOver;
                    } 
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrashOver;
                    } 
                    else
                    {
                        img.src = SVGPencilOver;
                    }
  

                break;
                case'mouseOut':

                    btn.style.backgroundColor = 'transparent';

                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChart;
                    }
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrash;
                    } 
                    else
                    {
                        img.src = SVGPencil;
                    }
                break;
                default:
                }

            }//==



        break;
        case'button':


            //console.log(JSON.stringify(hndl,null,2));

            box_id = "chartKeyLine"+hndl.indx;
            buttonName = hndl.buttonName;

            btn = document.getElementById(box_id+"_"+buttonName);
            btn.style.backgroundColor = hndl.backgroundColor;
            btn.style.border = hndl.border;
            btn.style.color = hndl.fontColor;
            btn.style.cursor = 'pointer';


            /*
        
            switch(hndl.call)
            {
            case'mouseEnter':
            case'mouseOver':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            case'mouseOut':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            default:
            }

            */


        break;
        default:
        }



    }





    // if(!props.chart.data.keyStyle 
    // || props.chart.data.keyStyle === undefined)
    // {
    //     props.chart.data.keyStyle = {

    //         backgroundColor :"white",
    //         border          :"1px solid #999",
    //         margin          :"0 0 0 0",
    //         textColor       :"black"

    //     }
    // }



    
    return (


    
        <CanvBox
        ref={refCanvBox}
        backgroundColor={props.backgroundColor}
        border={props.border}
        borderRadius={props.borderRadius}
        width={props.width}
        margin={props.margin}

        holderHeight={canvasH+"px"}
        >

            {
            props.chart
            ?
                props.chart.call === 'doughnut'
                ?

                    <div className="Message"
                    ref={refDoughnutMsg}
                    style={{

                        backgroundColor:'transparent',
                        top:doughnutMsgY

                    }}
                    onClick={() => handle({

                        call       :'chartReset'  

                    })}
                    >

                        {
                        currentSlice
                        ?

                            /*
                            <div>

                                <div className="SlicePerc">{currentSlice.percent}</div>
                                <div className="SliceName">{currentSlice.name}</div>

                            </div>
                            */

                            <div>

                                <div className="SlicePerc">{currentSlice.percent}</div>
                                <div className="SliceName">{currentSlice.name}</div>

                            </div>



                        :

                            <div>


                                <div className="SlicePerc">{props.chart.data.hole.percent + "%"}</div>
                                <div className="SliceName">{props.chart.data.hole.name}</div>

                            </div>

                        }


                    </div>


                

                :
                props.chart.call === 'pie'
                ?

                    /*
                    <CanvKey
                    ref={refCanvMsg}
                    > 
                        {props.chart.data.items.map((item, i) => (
                            
                            <div 
                            key={"barline"+i}
                            className="Line">
                                <div 
                                style={{backgroundColor:props.chart.data.colors[i]}}
                                className="ColorTile"></div>
                                <div className="Text"> {item.value+"%"} - {item.name} </div>
                            </div>

                        ))}

                    </CanvKey>
                    */
                    

                    
                    <div>

                        <CanvMsg 
                        ref={refCanvMsg}        
                        chartKeyMargin="25px 0 0 0"
                        > 
                            <div className={'ChartKey'}>

                                {props.chart.data.items.map((item, i) => (
                            
                                    <div 
                                    key={"barline"+i}
                                    className="Line">
                                        <div 
                                        style={{backgroundColor:props.chart.data.colors[i]}}
                                        className="ColorTile"></div>
                                        <div className="Text"> {item.value+"%"} - {item.name} </div>
                                    </div>

                                ))}

                            </div>

                        </CanvMsg>

                    </div>
                    



                :
                props.chart.call === 'bar'
                ?

                    <div>

                        <CanvMsg 
                        ref={refCanvMsg}
                        > 

                            {/*
                            <div className={'ChartKey'}>

                                {props.chart.data.items.map((item, i) => (
                               
                                    <div 
                                    key={"barline"+i}
                                    className="Line">
                                        <div 
                                        style={{backgroundColor:props.chart.data.colors[i]}}
                                        className="ColorTile"></div>
                                        <div className="Text"> {item.value+"%"} - {item.name} </div>
                                    </div>

                                ))}

                            </div>
                            */}




                            {props.chart.data.keyStyle
                            ?

                                
                                <ChartKey
                                backgroundColor={props.chart.data.keyStyle.backgroundColor}
                                border={props.chart.data.keyStyle.border}
                                margin={props.chart.data.keyStyle.margin}
                                textColor={props.chart.data.keyStyle.textColor}
                                >

                                    {props.chart.data.items.map((item, i) => (
                                
                                        <div 
                                        ref={setBoxRef}
                                        id={"chartKeyLine"+i}
                                        key={"barline"+i}
                                        className="Line">
                                            <div 
                                            style={{backgroundColor:props.chart.data.colors[i]}}
                                            className="ColorTile"></div>

                                            {
                                            item.data && item.data !== undefined
                                            ?
                                                <div>

                                                    <div className="Percent"> {item.data.percent+"%"} </div>
                                                    <div className="Name"> {item.name} </div>          
                                                    <div className="Price"> {"($"+item.data.price+" Mo.)"} </div>
                            
                                                </div>

                                            :
                                                
                                                <div>

                                                    <div className="Text"> {item.value+"%"} </div>          
                                            
                                                </div>
                                            }
                                                                        


                                            <div className="Controls">

                                                {item.controls
                                                ?



                                                    <div>


                                                    {item.controls.chart 
                                                    //&& item.controls.chart.data.value.length > 1
                                                    ?

                                                        <div 
                                                        className="Button"
                                                        id={"chartKeyLine"+i+"_controls_chartButton"}
                                                        >

                                                            <div 
                                                            className="ButtonMask"
                                                            id={"chartKeyLine"+i+"_controls_chartButton_mask"}

                                                                onClick={() => props.primeHandle({

                                                                    //primeHandle : (v) => props.handle(v),

                                                                    call        :'showFlyr',
                                                                    //type        :'chart',
                                                                    data        :item.controls.chart,
                                
                                                                    gridType    :"",
                                                                    position    :i,
                                                                    id          :item.name
                                                
                                                                })}
                                                                onMouseEnter={() => handleOverOut({

                                                                    indx        :i,   
                                                                    call        :'mouseEnter',
                                                                    from        :'controls',
                                                                    buttonName  :"chartButton"
                            
                                                                })}
                                                                onMouseOut={() => handleOverOut({
                            
                                                                    indx        :i,       
                                                                    call        :'mouseOut',
                                                                    from        :'controls',
                                                                    buttonName  :'chartButton'
                            
                                                                })}

                                                            >
                                                            </div>

                                                            <img
                                                            id={"chartKeyLine"+i+"_controls_chartButton_img"}
                                                            className="Img"
                                                            src={SVGChart} 
                                                            alt="logo" />


                                                        </div>


                                                    :null}
                                                




                                                    {item.controls.form
                                                    ?
                                                        item.controls.form.url
                                                        ?
                                                            <div 
                                                            className="Button"
                                                            id={"chartKeyLine"+i+"_controls_button"}
                                                            >

                                                                <div 
                                                                className="ButtonMask"
                                                                id={"chartKeyLine"+i+"_controls_button_mask"}

                                                                    onClick={() => handleUrl({

                                                                        url:item.controls.form.url
                                                    
                                                                    })}
                                                                    onMouseEnter={() => handleOverOut({

                                                                        indx        :i,   
                                                                        call        :'mouseEnter',
                                                                        from        :'controls',
                                                                        buttonName  :"button"
                                
                                                                    })}
                                                                    onMouseOut={() => handleOverOut({
                                
                                                                        indx        :i,       
                                                                        call        :'mouseOut',
                                                                        from        :'controls',
                                                                        buttonName  :'button'
                                
                                                                    })}

                                                                >
                                                                </div>

                                                                <img
                                                                id={"chartKeyLine"+i+"_controls_button_img"}
                                                                className="Img"
                                                                src={SVGPencil} 
                                                                alt="logo" />



                                                            </div>



                                                        :


                                                            <div 
                                                            className="Button"
                                                            id={"chartKeyLine"+i+"_controls_button"}
                                                            >

                                                                <div 
                                                                className="ButtonMask"
                                                                id={"chartKeyLine"+i+"_controls_button_mask"}

                                                                    onClick={() => props.primeHandle({

                                                                        //primeHandle : (v) => props.handle(v),

                                                                        call        :'showFlyr',
                                                                        //type        :'form',
                                                                        data        :item.controls.form,
                                        
                                                                        gridType    :"",
                                                                        position    :i,
                                                                        id          :item.name
                                                    
                                                                    })}
                                                                    onMouseEnter={() => handleOverOut({

                                                                        indx        :i,   
                                                                        call        :'mouseEnter',
                                                                        from        :'controls',
                                                                        buttonName  :"button"
                                
                                                                    })}
                                                                    onMouseOut={() => handleOverOut({
                                
                                                                        indx        :i,       
                                                                        call        :'mouseOut',
                                                                        from        :'controls',
                                                                        buttonName  :'button'
                                
                                                                    })}

                                                                >
                                                                </div>

                                                                <img
                                                                id={"chartKeyLine"+i+"_controls_button_img"}
                                                                className="Img"
                                                                src={SVGPencil} 
                                                                alt="logo" />

                                                            </div>


                                                    :null}




                                                    </div>

                                                :null}

                                            </div>
                                        
                                        </div>

                                    ))}

                                </ChartKey>
                                

                            :null}



                        </CanvMsg>

                    </div>


                :null





            :null
            }




            <div className="Holder">

                <canvas 

                    ref={refCanvas}
                    id={props.id}
                    width={canvasW}
                    height={canvasH}
                    className="Canv"
                    //onClick={handleCanvasClick} 

                    onClick={event => {

                        handle({

                            call       :'click',  
                            event      :event
                
                        })

                    }}
                    onMouseMove={event => {

                        handle({

                            call       :'mouseMove',
                            event      :event,
            
                        })

                    }}


                />

            </div>

            

        </CanvBox>



        
    );

};

export default Index;


/*

    <main 
    className="main" 
    >

        <canvas 
        ref={refCanvas}
        className="canvas"
        onClick={handleCanvasClick} 
        />

        <div className="button" >

            <button onClick={handleClearCanvas} > CLEAR </button>

        </div>


    </main>

        
*/





/*



    //=========================================================
    // Event Listeners
    //=========================================================

    //------------------------------------
    // addEventListener ( mousemove )
    //------------------------------------

    _canvas.addEventListener("mousemove",function(e){
    
        var eventLocation = Canvas_GetEventLocation(this,e);
        var coord = "x=" + eventLocation.x + ", y=" + eventLocation.y;
        
        // Get the data of the pixel according to the location generate by the getEventLocation function
        var context = this.getContext('2d');

        console.log("COORD: "+coord);

        var pixelData = context.getImageData(eventLocation.x, eventLocation.y, 1, 1).data; 
        // If transparency on the image
        if((pixelData[0] == 0) && (pixelData[1] == 0) && (pixelData[2] == 0) && (pixelData[3] == 0))
        {
            coord += " (Transparent color detected, cannot be converted to HEX)";
        }

        var rgb = pixelData[0]+","+pixelData[1]+","+pixelData[2];
        var hex = "#" + ("000000" + Canvas_RgbToHex(pixelData[0], pixelData[1], pixelData[2])).slice(-6);

        console.log("COLOR: "+rgb+" "+hex);

        zoneObj['pie_chart_color'] = hex;





        //console.log(JSON.stringify(canvas_colors_array,null,2));

        //----------------------------------------------------------

        var data_index = 100;
        for(var i=0; i < canvas_colors_array.length; i++)
        {
            console.log(canvas_colors_array[i]+" == "+hex);

            var a = canvas_colors_array[i].toLowerCase();
            var b = hex.toLowerCase();
            if(a == b)
            {
                var data_index = i;
                break;
            }//===

        }//for i
        //=======


        //console.log("DATA INDEX: "+data_index);
        //console.log(JSON.stringify(canvas_data_object,null,2));



        var name = '%';
        var value = '';
        var font_size = "35px";

        var count = -1;
        for(var kn in canvas_data_object)
        {
        
            var count = count + 1;
            //console.log(data_index+" "+kn+" "+count);

            if(data_index == count)
            {
                var name = kn;

                var value = canvas_data_object[kn];
                var value = parseFloat(value);
                var value = value.toFixed(1);
                var value = value+"%";

                var font_size = "20px";
                break;
            };

        }//for i
        //=======



        zoneObj['pie_chart_click_object'] = {

                "color"		:hex,
                "name"		:name,
                "value"		:value
        };


        //-----------------------------------------------------------

        //var canvas_w_int = 250;
        //var canvas_h_int = 250;
    
        var piemsg_w_int = (canvas_w_int)/2.7;

        //------------------------------------------------------------
        var MESSAGE = name+"<br/>"+value;



        var suffix = '_canvas_piechart_output';

        var section_id = "section" + suffix;
        var section = document.getElementById(section_id);

        var piemsg_id = section_id + "msg";

            if(document.getElementById(piemsg_id))
            {
                var e = document.getElementById(piemsg_id);
                section.removeChild(e);

            }//==

            var piemsg = document.createElement('div');
            piemsg.setAttribute("id",piemsg_id);

        section.appendChild(piemsg);


        piemsg.innerHTML = MESSAGE;


        var piemsg = document.getElementById(piemsg_id);


            var css = '';
            css += "background-color : transparent;";
            //css += "background-image : url("+logo_src+");";
            css += "width       	 : "+piemsg_w_int+"px;";
            css += "height      	 : auto;";

            css += "padding     	 : 0 0 0 0;";
            css += "margin      	 : 0 0 0 0;";

            //css += "position         : relative;";
            //css += "float     	   : left;";

            css += "position       	: absolute;";
            css += "top     		: 0px;";
            css += "left     		: 0px;";
            css += "z-index 		: 3;";


            css += "display			: inline;";

                
            css += "font-family  	 : Arial, Helvetica, sans-serif;";
            css += "font-size    	 : "+font_size+";";
            css += "font-weight  	 : normal;";
            css += "color       	 : #FFF;";
            css += "text-align   	 : center;";

            //css += "border 		 : 1px solid black;";
            //css += "border-radius  : 5px;";

        piemsg.style.cssText = css;



        var piemsg_tmar_int = (section.offsetHeight - piemsg.offsetHeight)/2;
        var piemsg_lmar_int = (section.offsetWidth - piemsg.offsetWidth)/2;
    
        piemsg.style.top = piemsg_tmar_int + 'px';
        piemsg.style.left = piemsg_lmar_int + (2) + 'px';

        //-----------------------------------------------------------


    
    },false);



    //------------------------------------
    // addEventListener ( click )
    //------------------------------------

    _canvas.addEventListener("click",function(e){
    
        //alert("CHART COLOR: "+zoneObj['pie_chart_color']);


        var object = zoneObj['pie_chart_click_object'];
        

        var banner_color = object['color'];
        var banner_title = object['name'];

        var message = object['value'];



        var lumina = Luminance(banner_color);
        //alert(lumina);
        if(lumina < 100
        ){
            //tab.style.color = navbar_button_text_over;
            var title_color = "white";
        }else{
            var title_color = "black";	
        }//====





        Zone_ShowMessenger({

            'title'		:banner_title,
            'message'	:message,	
            'type'		:'timed',
            'duration'	:1500,
            'ok'		:'',
            'cancel'	:'',
            'banner':{
                'background-color':banner_color,
                'text-color':title_color,
            }

        });



    
    },false);

    //=========================================================
    //=========================================================







*/








