
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0


    backgroundColor :"lightyellow",
    width           :"100%",
    height          :"500px",
    margin          : "10px 0 0 0"



*/





const Print = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 100px 0",
    margin:             props.margin || "10px auto 0 auto"


}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    min-height: 400px;

    padding :${props => props.padding}; 
    margin  :${props => props.margin};


    position:relative;   
   


`;




export {

    Print

}
