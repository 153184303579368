
//import Composer from '../ConstructComposer/Composer';

const FormBuild = (props) => {


    let Composer = props.Composer;

    let R = [];
  
    var call
    var data; 
    var array;
    var title;   
    var group; 
    var def;
    var val;

    
    for(var cmp of Composer)
    {

        call = cmp['call'];
        data = cmp['data'];


        switch(call)
        {
        case'labelfields':


            for(var keyname in data)
            {

                title = data[keyname]['title'];

                R.push({

                    section:"titleline",
                    data:{

                        text    :title
                    }

                });

                group = data[keyname]['group'];
                array = [];
                for(var id in group)
                {

                    def = group[id].default;
                    val = group[id].value;

                    switch(group[id].print)
                    {
                    case'price':
                
                        def = parseFloat(def);
                        val = parseFloat(val);

                        def = def.toFixed(2);
                        val = val.toFixed(2);
                    break;
                    case'percent':

                        def = parseFloat(def);
                        val = parseFloat(val);

                        def = def.toFixed(1);
                        val = val.toFixed(1);
                    break;
                    default:
                    }



                    array.push({

                        label:{

                            text    :group[id].title

                        },
                        field:{

                            //"fieldtype"                 :"text",
                            "fieldtype"                 :group[id].print,
                            "required"					:group[id].required,

                            "class"                   	:'',
                            "id"                      	:id,
                            "hint"                    	:"",

                            "text"                     	:def,

                            "keyboardType"            	:"",
                            "autocorrect"             	:"false",
                            "autocapitalizationType"  	:"none",

                            //"secure"                  :true,
                            //"returnKeyType"           :"done"

                        }



                    });

                    


                }


                R.push({

                    section :call,
                    data    :array

                });

                R.push({

                    section :"spacer",
                    data    :{

                        "name"		:"after-"+call,
                        "color"   	:'transparent',
                        "height"  	:50

                    }

                });


            }


        break;
        default:

            R.push({

                section :call,
                data    :data

            });

        break;
        }




    }

    return R;


};

export default FormBuild;


