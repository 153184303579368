//import styled, { keyframes } from 'styled-components';
import styled from 'styled-components';




export const Grid = styled.div.attrs(props => ({ 
    
    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 50px 0",
    margin:             props.margin || "auto",
    justifyContent:     props.justifyContent || "center"

}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    max-width:600px;
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    display: flex;
    flex-wrap: wrap;
    justify-content:${props => props.justifyContent};



    @media (max-width: 1050px) {

        width:100%;/*85%*/
  
    }

    @media (max-width: 500px) {
    
        width:100%;/*90%*/
    
    }


`;

    export const Cell = styled.div.attrs(props => ({ 
        
        backgroundColor:    props.backgroundColor || "transparent",
        // border:             props.border || "0",
        width:              props.width || "100%",
        height:             props.height || "auto",
        margin:             props.margin || "0px",

        // flex:               props.flex || "1 1 99%",
        // flex4:              props.flex4 || "0 1 25%",
        // flex3:              props.flex3 || "0 1 33.3333%",
        // flex2:              props.flex2 || "0 1 50%",
        // flex1:              props.flex1 || "0 1 100%"


        //flex: ${props => props.flex};      

    }))`

        background-color: ${props => props.backgroundColor};

        width: ${props => props.width}; 
        height: ${props => props.height}; 
 
        margin: ${props => props.margin}; 
        overflow: visible;

        position:relative;
        float:left;

    `;





        export const Container = styled.div.attrs(props => ({ 
                
            backgroundColor:    props.backgroundColor || "transparent",
            border:             props.border || "1px solid lightgray",
            borderRadius:       props.borderRadius || "7px",
            width:              props.width || "100%",
            height:             props.height || "auto",
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin || "0 0 0 0",
            position:           props.position || "relative",  
            float:              props.float || "left",


            // border-radius: ${props => props.borderRadius}; 


            //-------------------
            //graphic 
            //-------------------

            graphicBackgroundColor:    props.graphicBackgroundColor || "transparent",
            graphicWidth:              props.graphicWidth || "30px",
            graphicHeight:             props.graphicHeight || "30px",
            graphicPadding:            props.graphicPadding || "0 0 0 0",  
            graphicMargin:             props.graphicMargin || "4px 0 0 10px",

            graphicPosition:           props.graphicPosition || "relative",  
            graphicFloat:              props.graphicFloat || "left",


            //--------------------
            //name
            //--------------------

            nameBackgroundColor:    props.nameBackgroundColor || "transparent",
            nameWidth:              props.nameWidth || "calc(100% - 50px)",
            nameHeight:             props.nameHeight || "auto",

            namePadding:            props.namePadding || "11px 0 9px 10px",
            nameMargin:             props.nameMargin || "0 0 0 0",

            namePosition:           props.namePosition || "relative",  
            nameFloat:              props.nameFloat || "left",

            nameTextColor:          props.nameTextColor || "black",
            nameFontWeight:         props.nameFontWeight || "500",

            nameTextAlign:          props.nameTextAlign || "left",




            //---------------------------
            //animation variables
            //---------------------------

            hover:                  props.hover || false,

            
            animationTime:          props.animationTime || '0.2s',
            //transitionTime:         props.transitionTime || '0.2s',

            //opacity:                props.opacity || "1.0",
            //marginLeft:             props.marginLeft || "200px"

            backgroundColorOver:    props.backgroundColorOver || "#EDEEF1",
            colorOver:              props.colorOver || "black",

            // border-radius: ${props => props.borderRadius}; 






        }))`

            background-color: ${props => props.backgroundColor}; 
        
            width: ${props => props.width}; 
            height: ${props => props.height}; 
            padding: ${props => props.padding};
            margin: ${props => props.margin};
            position: ${props => props.position}; 
            float: ${props => props.float};  
            
            cursor:pointer;

            ${props => props.hover
            ?  

            `
                &:hover{

                    background-color:lightgray;
                    color:black;
                }

            `
            : null};



            .Graphic {

                background-color:${props => props.graphicBackgroundColor};
            
                width   :${props => props.graphicWidth};
                height  :${props => props.graphicHeight};
            
                padding :0 0 0 0;
                margin  :${props => props.graphicMargin};

                position:relative;
                float:left;
            
                overflow:visible;

            }
    

            .Name {

                width   :${props => props.nameWidth};
                height  :${props => props.nameHeight};
    
                padding:${props => props.namePadding};
                margin  :${props => props.nameMargin};
    
                position:relative;
                float:left;
                overflow:hidden;
    
                font-family:Arial, Helvetica, sans-serif;
                font-size:16px;
                font-weight:${props => props.nameFontWeight};
                color:${props => props.nameTextColor};
                text-align:${props => props.nameTextAlign};


                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */
    
            }



        `;


        export const Graphic = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "lightyellow",
            width:              props.width || "20px",
            height:             props.height || "20px",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 0",
            position:           props.position || "relative",  
            float:              props.float || "left"

        }))`

            background-color:${props => props.backgroundColor};
            border:1px solid #1A1926;

            width   :${props => props.width};
            height  :${props => props.height};
        
            padding :0 0 0 0;
            margin  :${props => props.margin};
        
            overflow:visible;
        
        
        `;






        export const Name = styled.div.attrs(props => ({ 
            

            backgroundColor:    props.backgroundColor || "#EDEEF1",
            // border:             props.border || "1px solid #EDEEF1",
            // borderRadius:       props.borderRadius || "7px",

            width:              props.width || "50%",
            height:             props.height || "auto",

            padding:            props.padding || "11px 0 9px 10%",
            margin:             props.margin || "0 0 0 0",

            position:           props.position || "relative",  
            float:              props.float || "left",

            color:              props.color || "black",
            fontWeight:         props.fontWeight || "500",

            textAlign:          props.textAlign || "left",


            //animation variables

            hover:                  props.hover || false,

            
            animationTime:          props.animationTime || '0.2s',
            //transitionTime:         props.transitionTime || '0.2s',

            //opacity:                props.opacity || "1.0",
            //marginLeft:             props.marginLeft || "200px"

            backgroundColorOver:    props.backgroundColorOver || "#EDEEF1",
            colorOver:              props.colorOver || "black",

            // border-radius: ${props => props.borderRadius}; 
        
        }))`


            background-color: ${props => props.backgroundColor}; 

            width   :${props => props.width};
            height  :${props => props.height};

            padding:${props => props.padding};
            margin  :${props => props.margin};

            position:relative;
            float:right;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:14px;
            font-weight:${props => props.fontWeight};
            color:${props => props.color};
            text-align:${props => props.textAlign};


            ${props => props.hover
            ?  

            `
                &:hover{

                    background-color:lightgray;
                    color:black;
                }

            `
            : null};




            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */



        `;







        export const SubName = styled.div.attrs(props => ({ 
            

            backgroundColor:    props.backgroundColor || "#EDEEF1",
            border:             props.border || "1px solid lightgray",
            // borderRadius:       props.borderRadius || "7px",

            width:              props.width || "80%",
            height:             props.height || "auto",

            padding:            props.padding || "10px 0 10px 20%",
            margin:             props.margin || "0 0 0 0",

            position:           props.position || "relative",  
            float:              props.float || "left",

            color:              props.color || "black",
            fontWeight:         props.fontWeight || "500",

            textAlign:          props.textAlign || "left",


            //animation variables

            hover:                  props.hover || false,

            
            animationTime:          props.animationTime || '0.2s',
            //transitionTime:         props.transitionTime || '0.2s',

            //opacity:                props.opacity || "1.0",
            //marginLeft:             props.marginLeft || "200px"

            backgroundColorOver:    props.backgroundColorOver || "#EDEEF1",
            colorOver:              props.colorOver || "black",

            // border-radius: ${props => props.borderRadius}; 
        
        }))`


            background-color: ${props => props.backgroundColor}; 
           
            width   :${props => props.width};
            height  :${props => props.height};

            padding:${props => props.padding};
            margin  :${props => props.margin};

            position:relative;
            float:left;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:14px;
            font-weight:${props => props.fontWeight};
            color:${props => props.color};
            text-align:${props => props.textAlign};


            ${props => props.hover
            ?  

            `
                &:hover{

                    background-color:lightgray;
                    color:black;
                }

            `
            : null};




            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */



    
        `;





        export const Sub2Name = styled.div.attrs(props => ({ 
            

            backgroundColor:    props.backgroundColor || "#EDEEF1",
            border:             props.border || "1px solid #EDEEF1",
            borderRadius:       props.borderRadius || "7px",

            width:              props.width || "86%",
            height:             props.height || "auto",

            padding:            props.padding || "10px 0 10px 14%",
            margin:             props.margin || "0 0 0 0",

            position:           props.position || "relative",  
            float:              props.float || "left",

            color:              props.color || "black",

            textAlign:          props.textAlign || "left",


            //animation variables

            hover:                  props.hover || false,

            
            animationTime:          props.animationTime || '0.2s',
            //transitionTime:         props.transitionTime || '0.2s',

            //opacity:                props.opacity || "1.0",
            //marginLeft:             props.marginLeft || "200px"

            backgroundColorOver:    props.backgroundColorOver || "#EDEEF1",
            colorOver:              props.colorOver || "black",

            // border-radius: ${props => props.borderRadius}; 
        
        }))`


            background-color: ${props => props.backgroundColor}; 
            border-bottom: ${props => props.border}; 


            width   :${props => props.width};
            height  :${props => props.height};

            padding:${props => props.padding};
            margin  :${props => props.margin};

            position:relative;
            float:left;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:14px;
            font-weight:500;
            color:${props => props.color};
            text-align:${props => props.textAlign};


            ${props => props.hover
            ?  

            `
                &:hover{

                    background-color:lightgray;
                    color:black;
                }

            `
            : null};



            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */



    
        `;




        

        export const Descr = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "90%",
            height:             props.height || "auto",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 15px",
            position:           props.position || "relative",  
            float:              props.float || "left",
            textAlign:          props.textAlign || "left"

        
        }))`

            background-color:${props => props.backgroundColor};
        
            width   :${props => props.width};
            height  :${props => props.height};

            padding:4px 0 4px 0;
            margin  :${props => props.margin};

            position:relative;
            float:left;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:18px;
            font-weight:normal;
            color:#000;
            text-align:${props => props.textAlign};
        
        `;







