export const WING_RACING_THEME_CANON_5 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

    
    {/*Wing Backgroung*/}
     <path 
        fill="rgb(150,150,150)" 
        d="M76.175,164.732v26.066c0,4.445,3.33,7.995,8.097,8.632 l37.004,3.821h8.348c2.311,0,4.487-0.903,6.126-2.543c1.641-1.641,2.544-3.816,2.544-6.127l-0.002-29.851 c-0.001-4.78-3.89-8.67-8.67-8.67H84.846C80.064,156.062,76.175,159.951,76.175,164.732L76.175,164.732z"
    />
    {/*Center*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M78.335,164.787v25.961c0.177,3.737,2.663,5.918,6.234,6.542 l36.818,3.802h8.21c3.675-0.092,6.451-2.854,6.537-6.534l-0.002-29.771c-0.191-3.798-2.766-6.374-6.564-6.565H84.9 C81.099,158.412,78.529,160.988,78.335,164.787L78.335,164.787z"
    />
    {/*Filler*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M76.175,203.256v-12.457c0,2.741,1.266,5.141,3.354,6.729L76.175,203.256 L76.175,203.256z"
    />
    {/*Rivets*/}
     <path 
        fill="rgb(150,150,150)" 
        d="M89.536,186.91c0.574,0,1.042,0.468,1.042,1.041 c0,0.574-0.468,1.043-1.042,1.043s-1.042-0.469-1.042-1.043C88.495,187.378,88.962,186.91,89.536,186.91L89.536,186.91z M89.536,176.628c0.574,0,1.042,0.468,1.042,1.042s-0.468,1.041-1.042,1.041s-1.042-0.467-1.042-1.041	S88.962,176.628,89.536,176.628L89.536,176.628z M124.935,176.628c0.574,0,1.042,0.468,1.042,1.042s-0.468,1.041-1.042,1.041 s-1.042-0.467-1.042-1.041S124.361,176.628,124.935,176.628L124.935,176.628z M124.935,186.91c0.574,0,1.042,0.468,1.042,1.041	c0,0.574-0.468,1.043-1.042,1.043s-1.042-0.469-1.042-1.043C123.893,187.378,124.361,186.91,124.935,186.91L124.935,186.91z"
    />
    {/*Canon*/}
    <path 
        fill="rgb(190,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.284,163.09l-4.228,2.496l2.233-3.623 c-0.646-0.395-1.361-0.604-2.238-0.605c-2.347-0.001-4.262,1.864-4.253,4.235c-0.009,2.362,1.91,4.259,4.263,4.259 c1.532,0,2.878-0.807,3.63-2.021l0.199,0.622c-1.037,1.372-2.99,2.442-5.571,2.44c-4.239-0.001-6.54-2.574-6.539-5.3 c-0.001-2.734,2.414-5.282,6.539-5.284C91.211,160.309,93.061,161.379,94.284,163.09L94.284,163.09z M113.311,170.627h-3.11v-6.188 c0-0.428-0.347-0.777-0.773-0.777c-0.431,0-0.777,0.35-0.777,0.777l0.003,6.188h-3.101v-6.965h-2.069l2.98-1.414 c0.205-0.079,0.407-0.133,0.64-0.133c0.775,0,1.417,0.568,1.532,1.31l2.471-1.169c0.199-0.078,0.415-0.141,0.646-0.141 c0.855,0,1.56,0.693,1.56,1.545V170.627L113.311,170.627z M102.717,164.021l1.845,6.609h-3.102l-0.402-1.461 c-0.639,0.914-1.927,1.653-3.513,1.65c-1.421,0.003-3.211-1.111-3.216-2.787c0.005-1.67,1.794-2.762,3.221-2.768
		c1.012,0.006,1.915,0.266,2.654,0.852l-0.693-2.467h-4.025l3.643-1.348C100.965,161.619,102.414,162.892,102.717,164.021 L102.717,164.021z M99.087,169.635c0.876,0,1.587-0.711,1.587-1.588s-0.711-1.587-1.587-1.587c-0.878,0-1.592,0.71-1.592,1.587	S98.209,169.635,99.087,169.635L99.087,169.635z M114.341,166.502c0-2.422,1.965-4.387,4.391-4.387	c2.427,0,4.393,1.965,4.393,4.387c0,2.424-1.966,4.387-4.393,4.387C116.307,170.889,114.341,168.926,114.341,166.502 L114.341,166.502z M119.76,170.229c0.521-0.15,0.801-0.648,0.663-1.161l-1.541-5.645c-0.134-0.499-0.637-0.8-1.167-0.651 c-0.497,0.139-0.793,0.643-0.648,1.16l1.547,5.643C118.745,170.082,119.255,170.363,119.76,170.229L119.76,170.229z M132.69,170.631h-3.114l0.001-6.189c0-0.428-0.346-0.775-0.774-0.775c-0.428,0-0.776,0.348-0.776,0.775l0.001,6.189h-3.1v-6.965 h-2.067l2.978-1.414c0.204-0.081,0.411-0.133,0.64-0.133c0.774,0,1.418,0.566,1.532,1.309l2.471-1.17	c0.199-0.076,0.415-0.139,0.646-0.139c0.858,0,1.563,0.691,1.563,1.543V170.631L132.69,170.631z"
    />
    {/*5*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M115.009,174.328v4.572h-8.481l-0.444,2.877c0.46-0.105,0.919-0.184,1.383-0.23	c0.464-0.052,0.918-0.078,1.368-0.078c2.27,0,4.065,0.678,5.381,2.041c1.315,1.361,1.973,3.209,1.973,5.543	c0,2.488-0.819,4.451-2.453,5.887c-1.639,1.436-3.878,2.155-6.723,2.155c-1.352,0-2.62-0.151-3.789-0.459 c-1.174-0.308-2.203-0.752-3.09-1.331l0.397-5.412l0.475,0.094c0.548,0.824,1.284,1.472,2.203,1.947 c0.919,0.475,1.905,0.709,2.964,0.709c1.42,0,2.516-0.339,3.288-1.018c0.778-0.678,1.164-1.633,1.164-2.865 c0-1.18-0.417-2.15-1.252-2.902c-0.835-0.756-1.926-1.132-3.257-1.132c-0.898,0-1.712,0.151-2.432,0.448	c-0.726,0.303-1.378,0.758-1.957,1.373l-0.752-0.443l1.853-11.775H115.009L115.009,174.328z"
    />


    

		</svg>
		</div>

	);//return
}