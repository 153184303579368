export const STEERING_WHEEL_RED_RED = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M394.874,181.133l-4.797,22.566l-13.008-0.91l5.188-24.406	C383.904,170.635,396.672,172.677,394.874,181.133L394.874,181.133z"
    />
    {/*Base*/}
    <path 
        fill="rgb(200,0,0)" 
        d="M393.813,180.936l-4.823,22.688l-10.832-0.757l5.151-24.231 C384.676,172.199,395.215,174.335,393.813,180.936L393.813,180.936z"
    />
    {/*Highlight*/}
    <path 
        fill="rgb(255,0,0)" 
        d="M388.306,182.922l-4.326,20.352l-5.822-0.407l5.151-24.231	c0.982-4.626,6.704-4.822,9.323-2.117C389.656,177.885,388.688,181.127,388.306,182.922L388.306,182.922z"
    />
    


    

		</svg>
		</div>

	);//return
}