import styled, { keyframes } from 'styled-components';


// const fadeIn = keyframes`

//     from {
//         opacity: 0;
//     }

//     to {
//         opacity: 1;
//     }
// `;

// const fadeOut = keyframes`

//     from {
//         opacity: 1;
//     }

//     to {
//         opacity: 0;
//     }
// `;





const fadeAnimation = (props) => keyframes`

    from {
        opacity: ${props.opacityFrom};
    }

    to {
        opacity: ${props.opacityTo};
    }

`;



const fadeScaleAnimation = (props) => keyframes`

    from {
        transform: scale(1.03);
        opacity: ${props.opacityTo};
    }

    to {
        transform: scale(1);
        opacity: ${props.opacityTo};
    }


`;




export const FixedImgAnimation = styled.img.attrs(props => ({


    minWidth:       props.minWidth || "100%",
    maxHeight:      props.maxHeight || "auto",

    marginTop:      props.marginTop || "0px",
    marginLeft:     props.marginLeft || "0px",

    opacity:        props.opacity || "1",
    filter:         props.filter || "grayscale(0%)",


    out:                    props.out || false,
    animationTime:          props.animationTime || '0.25s',
    transitionTime:         props.transitionTime || '0.25s'


    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;

    /* transition: opacity .5s ease; */
    /* animation:ImageOnly_FadeIn .5s infinite; */





    // display: inline-block;
    // visibility: ${props => props.out ? 'hidden' : 'visible'};
    // animation: ${props => props.out ? fadeOut : fadeIn} 1s linear;
    // transition: visibility 1s linear;




   //TM: - difference between Flex and Fixed is the minWidth and maxWidth


    // minWidth={box.image.maxWidth}
    // maxHeight={box.image.maxHeight}

    // marginTop={box.image.top}
    // marginLeft={box.image.left}

    // opacity={props.info.opacity}
    // filter={props.info.filter}


}))`


  
    flex-shrink: 0;

    min-width: ${props => props.minWidth};
    max-width:auto;

    min-height: 100%;   
    max-height:${props => props.maxHeight};


    position:relative;
    float:left;

    margin:${props => props.marginTop} 0 0 ${props => props.marginLeft};

    opacity:${props => props.opacity};

    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    transition: visibility ${props => props.transitionTime} linear;
    animation: ${props => props.out 
        ? 
            fadeScaleAnimation({

                opacityFrom:props.opacity,
                opacityTo:"0"

            }) 
        :     
            fadeScaleAnimation({

                opacityFrom:"0",
                opacityTo:props.opacity

            })

        } ${props => props.animationTime} linear;


    display: block;
    width: 100%;
    height: auto;


    backface-visibility: hidden;

`;







export const FlexImgAnimation = styled.img.attrs(props => ({


    minWidth:       props.minWidth || "100%",
    maxHeight:      props.maxHeight || "auto",

    marginTop:      props.marginTop || "0px",
    marginLeft:     props.marginLeft || "0px",

    opacity:        props.opacity || "1",
    filter:         props.filter || "grayscale(0%)",


    out:                    props.out || false,
    animationTime:          props.animationTime || '0.5s',
    transitionTime:         props.transitionTime || '0.5s'


    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;

    /* transition: opacity .5s ease; */
    /* animation:ImageOnly_FadeIn .5s infinite; */




    //TM: - difference between Flex and Fixed is the minWidth and maxWidth

    // maxWidth:box.image.maxWidth,
    // maxHeight:box.image.maxHeight,

    // marginTop:box.image.top,
    // marginLeft:box.image.left,

    // opacity:props.info.opacity,
    // filter: props.info.filter





}))`



  
    flex-shrink: 0;


    min-width: 100%;
    max-width:${props => props.maxWidth};

    min-height: 100%;   
    max-height:${props => props.maxHeight};

    position:relative;
    float:left;

    margin:${props => props.marginTop} auto auto ${props => props.marginLeft};

    opacity:${props => props.opacity};
    filter:${props => props.filter};

    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    transition: visibility 1s linear;

    animation: ${props => props.out 
        ? 
            fadeAnimation({

                opacityFrom:props.opacity,
                opacityTo:"0"

            }) 
        :     
            fadeAnimation({

                opacityFrom:"0",
                opacityTo:props.opacity

            })

        } ${props => props.animationTime} linear;





    display: block;
    width: 100%;
    height: auto;


    backface-visibility: hidden;


`;








// export const Fade = styled.div`

//     display: inline-block;
//     visibility: ${props => props.out ? 'hidden' : 'visible'};
//     animation: ${props => props.out ? fadeOut : fadeIn} 1s linear;
//     transition: visibility 1s linear;

// `;





// import styled, { css, keyframes } from 'styled-components';

// const perc = 30

// <AnimatedBar perc={perc} />


// const AnimatedBar = styled.div`
//   height: 10px;
//   background-color: #831640;
//   width: ${({ perc }) => !perc ? '0px' : `${perc}%`};
//   animation: ${({ perc }) => css`${animation(perc)} 1s ease-in-out`};
//   // in new version css` ` is required
// `

// const animation = (perc) => keyframes`
//   from {
//     width: 0px;
//   }
//   to {
//     width: ${perc}%;
//   }
// `;

