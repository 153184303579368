import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import CreateMarkup from '../../CreateMarkup';
import { Print, Grid, Cell, Row } from './Styled';

import CardComponent from 'utils/components/Card';


const Index = (props) => {



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- ref -- //
    const refA = useRef(null);



    

    



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [printOut, setPrintOut] = useState(false);
    const [items, setItems] = useState([]);
    const [contentH, setContentH] = useState(0);


    const contentRefs = useState([]);
    const setContentRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < contentRefs.length; i++)
            {
                if(contentRefs[i].id === ref.id) found = true;
            }
            if(!found) contentRefs.push(ref);
            //console.log(contentRefs.length);

        }

    };



 
    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);
            setPrintOut(false);


            //console.log("PROPS: "+JSON.stringify(props,null,2));


            //------------------------------------------------
            var array = [];

            var object = props.data.value;
            var actions = props.data.actions;      
          
            var heading = "Card";
            var pgraph = false;
            var form = false;
            var chart = false;


            for(var keyname in object)
            {
 
                heading = object[keyname].name;  
                pgraph = object[keyname].value;


                if(actions.edit)
                {
                    form = {

                        data:object[keyname],
                        flyr:{
                            type:"form",
                            styl:{
                                maxWidth:550,
                                fieldType:'labelfield'
                            }
                        }
                    }

                }

                if(actions.chart)
                {
                    chart = {

                        data:object[keyname],
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:900
                            }
                        }

                    }

                }


                array.push({

                    info:{
                        call:'fixed', //fixed, flex
                        gridType: "NameValue", //Info, Graphic, NameValue
                        gridBgColor:'transparent',
                        gridCall: "grid_cell",  //grid_cell or grid_column_cell
                        cellCall: "default",  //container or default 
                        modCall: 'fill_div',
                        thumb: {
            
                            call    : "default", //flex_height or default
                            diff    : 0
            
                        },    
                        cellBorder: {
                            "boolean"       :true,
                            "thickness"     :1,//px
                            "color"         :"gray"  
                        },
                        gutter: {
                            "boolean"   :true,
                            "size"      :20
                        },
                        thresh: {
                            "refWidth"    :false,   //number or false = default screen width
                            "across1"     :10000,
                            "across2"     :10000,
                            "across3"     :10000
                        }
        
                    },
                    items:[{  
                        heading:heading,
                        bullet:{

                            styl:{
        
                                color:"red"
                            }
                            
                        },
                        stripe:true,
                        pgraph:pgraph,
                        image:false,
                        button:false,
                        controls:{
        
                            delete:actions.delete,
                            form:form,
                            chart:chart
        
                        }
                    
                    }]
            
                });
 
            }

            setItems(array);
            //------------------------------------------------



            

            /*
            let printOut;
            //printOut = JSON.stringify(props.data,null,2);

            if(array.length > 0) 
                printOut = JSON.stringify(array,null,2);

            printOut = printOut.replace(/\n/gi,"<br/>");
            setPrintOut(printOut);
            */
            


            //-------------------------------------------------

            /*
            console.log("REF A - Height: "+refA.current.offsetHeight);
            props.handle({

                call:"adjustContentsHeight",
                data:{

                    ref :refA,
                    h   :refA.current.offsetHeight

                }

            })
            */






        }
        else
        {


            
            var newContentH = 0;
            for(var ref of contentRefs)
            {
                if(Object.prototype.toString.call(ref) !== '[object Undefined]')
                {
                    //console.log(document.getElementById(ref.id).offsetHeight); 
                    newContentH = newContentH + ref.offsetHeight;  
                }

            }
            newContentH = newContentH + 100;
            //alert("NEW CONTENT H: "+newContentH);

            if(contentH !== newContentH)
            {
                console.log(newContentH+" "+contentH);
                if(refA.current.offsetHeight < newContentH)
                {
                    props.handle({

                        call:"adjustContentsHeight",
                        data:{
        
                            ref :refA,
                            h   :newContentH
        
                        }
        
                    })

                }

                setContentH(newContentH);

            }

            


        }




    },[

        props,
        isLoaded, 
        zoneObj,
        refA,       
        contentRefs,
        contentH    


    ])




    // -- colors -- //

    //var white_color = '#EEF3F9';//'#EDEEF1' /* #EBECF1  #F9F9F9  #EEF3F9   */

    //var yellow_color = '#FF9';
    var green_color = '#D9FFD9';
    //var blue_color = '#D5E6FF';//'#B0D0FF';//
    

    //var mask_green_color = '#00FF00';
    //var mask_blue_color = '#0060BF';
    //var mask_trans_color = 'transparent';



    // -- handle -- //

    /*
    const handle = (hndl) => {

        alert(hndl);


        

    }
    */


    const handleOverOut= (hndl) => {

        switch(hndl.call)
        {
        case'over':


        break;
        case'out':



        break;
        default:
        }


    }




    if(printOut)
    {
        return (

            <Print
            ref={refA}
            backgrounColor={"lightyellow"}
            >
    
                <div dangerouslySetInnerHTML={CreateMarkup(printOut)} />
    
            </Print>
    
        );

    }
    else
    {

        return (


            <Grid
            ref={refA}
            >

                {props.data.actions.add
                ?

                    <Cell
                    //backgroundColor={'lightseagreen'}
                    backgroundColor={green_color}
                    border={"1px solid gray"}
                    fontColor={"black"}


                    onMouseEnter={() => handleOverOut({

                        call        :"over",
                        bgColor     :"orangered",
                        txtColor    :"#FFF",
                        brColor     :"orangered"

                    })}

                    onMouseOut={() => handleOverOut({

                        call        :"out",
                        bgColor     :"#07a2e3",
                        txtColor    :"#FFF",
                        brColor     :"#07a2e3"


                    })}
                    >

                        + Add Employee +

                    </Cell>
          

                :null}


                {items.map((itm, i) => (


                    <Row
                    key={i}
                    id={"smallrow"+i}
                    ref={setContentRef}
                    //backgroundColor={'lightyellow'}
                    >

                        <CardComponent
                        
                            handle = {(v) => props.primeHandle(v)}  
                            info   = {itm.info}         
                            items  = {itm.items}
                            refid  = {"smallrow"+i}

                        />     


                    </Row>


                ))} 

            </Grid>



        );

    

    }



}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/