export const HARDTOP_OEM_THEME_GRAY_MARTINI = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
    <defs>
        <linearGradient id="Gradient1" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(7)">
            <stop offset="0%" style={{"stopColor":"rgb(45,60,65)","stopOpacity":"1"}} />
            <stop offset="100%" style={{"stopColor":"rgb(90,105,115)","stopOpacity":"1"}} />
        </linearGradient>
    </defs> 


    
    {/*Hardtop Background*/}
    <path 
        fill="rgb(235,235,235)" 
        d="M388.614,143.384l-84.655-5.178 c-18.273-1.117-27.085,5.95-31.021,20.143l-10.821,39.02l-110.999-7.762l70.952-59.536c25.756-21.611,57.809-19.651,90.203-17.67 l58.368,3.57L388.614,143.384L388.614,143.384z"
    />
    {/*Hardtop Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M314.215,116.748l51.524,3.151 c2.567,0.157,4.228,1.456,4.986,2.614l3.368,5.136c1.175,1.792,0.282,2.982-1.493,2.874l-69.825-4.271
		c-18.301-1.119-31.311,5.039-39.139,21.044c-1.647,3.368-3.912,4.024-7.766,3.928h-24.02l-28.012,36.505l-10.797-0.754 c5.654-2.935,8.23-6.293,9.328-7.723l27.365-35.663c3.301-4.303,8.141-9.425,6.455-15.212 C254.469,113.571,288.219,115.158,314.215,116.748L314.215,116.748z"
    />

{/*Livery*/}

    {/*Background Navy*/}
    <path 
        fill="rgb(33,29,112)" 
        d="M151.118,189.606l36.06-30.257l1.989,0.123 c24.946,1.463,48.317,8.772,68.798,20.541l-4.641,16.74L151.118,189.606L151.118,189.606z"
    />
    {/*Background Red*/}
    <path 
        fill="rgb(185,0,0)" 
        d="M246.526,196.279c-17.688-9.848-37.769-15.918-59.159-17.074 l-22.243-1.352l-14.006,11.753l0,0.001L246.526,196.279L246.526,196.279z"
    />
    {/*Blue Lines*/}
    <path 
        fill="rgb(0,166,233)" 
        d="M253.324,196.754l1.658-5.982	c-19.494-11.693-41.928-18.992-65.936-20.485l-13.9-0.841l-8.014,6.724l20.7,1.259c22.85,1.297,44.218,8.124,62.81,19.139 L253.324,196.754L253.324,196.754z M255.48,188.975c-19.773-11.771-42.51-19.086-66.83-20.516l-11.492-0.703l8.009-6.72l3.37,0.204
		c25.019,1.384,48.446,8.715,68.931,20.565L255.48,188.975L255.48,188.975z"
    />
    {/*Blue HL*/}
    <path 
        fill="rgb(0,185,242)" 
        d="M214.299,174.098c-2.259-0.547-4.538-1.039-6.837-1.48	l-4.939,6.438c2.339,0.385,4.657,0.828,6.954,1.327L214.299,174.098L214.299,174.098z M215.494,172.541	c-2.251-0.557-4.521-1.062-6.811-1.516l4.863-6.338c2.256,0.5,4.492,1.05,6.709,1.646L215.494,172.541L215.494,172.541z"
    />
    {/*Red HL*/}
    <path 
        fill="rgb(255,0,0)" 
        d="M208.27,181.956c-2.324-0.49-4.67-0.922-7.035-1.294 c-1.454,1.686-3.961,4.117-8.191,6.313l10.797,0.754L208.27,181.956L208.27,181.956z"
    />

    {/*Glass Background*/}
    <path 
        fill="rgb(60,70,80)" 
        d="M223.531,143.823l-25.127,32.747 c-3.97,5.173-16.656,12.561-28.822,11.711l-15.585-1.09l68.073-57.121C233.545,130.772,227.795,138.584,223.531,143.823 L223.531,143.823z"
    />
    {/*Glass Highlight*/}
    <path 
        fill="rgb(90,100,120)" 
        d="M223.531,143.823l-5.679,7.4c-7.452,0-16.197,1.489-24.78,3.18	l28.998-24.333C233.545,130.772,227.795,138.584,223.531,143.823L223.531,143.823z"
    />
    {/*Defroster Lines*/}
    <path 
        fill="rgb(245,130,30)" 
        d="M220.121,140.949c0.41,0.037,0.713,0.401,0.676,0.813c-0.037,0.41-0.4,0.713-0.812,0.676l-11.408-1.045 l1.612-1.353L220.121,140.949L220.121,140.949z M212.323,148.831c0.411,0.038,0.714,0.401,0.677,0.813s-0.4,0.714-0.812,0.676 l-12.847-1.177l1.612-1.354L212.323,148.831L212.323,148.831z M204.756,156.822c0.41,0.037,0.713,0.4,0.676,0.812 s-0.4,0.714-0.811,0.676l-14.608-1.339l1.612-1.354L204.756,156.822L204.756,156.822z M197.876,165.117 c0.411,0.037,0.714,0.401,0.677,0.813c-0.037,0.41-0.4,0.713-0.812,0.676l-17.314-1.592l1.612-1.353L197.876,165.117	L197.876,165.117z M189.621,173.586c0.41,0.037,0.713,0.401,0.676,0.813c-0.037,0.41-0.4,0.713-0.812,0.676l-18.968-1.745 l1.611-1.353L189.621,173.586L189.621,173.586z"
    />
    {/*Trim*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M253.324,196.754l11.305-40.774c5.502-19.843,20.23-27.599,39.799-26.401 l78.272,4.785l5.914,9.021l-84.655-5.178c-18.272-1.117-27.086,5.951-31.021,20.143l-10.109,36.452l132.196,9.244l-0.548,2.579 L253.324,196.754L253.324,196.754z"
    />


    

		</svg>
		</div>

	);//return
}