import styled from "styled-components";

    /***********************************
     ThreeBoxGrid
     
     http://geniuscarrier.com/2-columns-layout-with-flexbox/
     
     ***********************************/


    // Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
    // Consider using the attrs method, together with a style object for frequently changed styles.
    // Example:
    
    // export const Component = styled.div.attrs({
    //     style: ({ background }) => {
    //         background,
    //     },
    // })`width: 100%;`
    
    // <Component />



    //if else examples

    // position:     props.position === 'right' ? "right" : props.position === "center" ? "center" : "left",
    // float:        props.position === 'right' ? "right" : props.position === "center" ? "left" : "left",
    // marLeft:      props.position === 'right' ? "calc(100% - 250px)" : props.position === "left" ? "0px" : "auto",
    // width:${props => props.width};
    // height:${props => props.height};








export const MediaColumn = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 0 0",


    flex:               props.flex || "1 1 100%"



}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};

    display:flex;
    flex-wrap: wrap;
    justify-content: center;


    .Row {

        background-color:transparent;

        flex: ${props => props.flex};
        margin: 0 0 0 0;

        min-height:80px;

    }




`;



export const MediaHeading = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"

}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


    line-height: 1.0;

`;

export const MediaPgraph = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "normal",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};

    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    line-height: 1.5;



`;



export const MediaNameVal = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding  || "0 0 0 0",
    margin:             props.margin  || "0 0 15px 0",

    dynaWidth:          props.dynaWidth || "180px",
    dynaHeight:         props.dynaHeight || "auto",



    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    // fontWeight:         props.fontWeight  || "500",
    // textAlign:          props.textAlign || "left",
    // textDecoration:     props.textDecoration  || "none",
    // color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
    color:white;

    display:flex;
    align-items: left;

    .Left {

        background-color:transparent;
        height:${props => props.dynaHeight};

    }
    .Right {

        background-color:transparent;
        height:auto;

        flex-grow:1

    }
    .Name {

        background-color:transparent;
        width:${props => props.dynaWidth};
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: bold;
        text-align: left;
        text-decoration:none;
        color:black;

        word-wrap: break-word;

    }
    .Value {

        background-color:transparent;
        width:95%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 15px;

        position:relative;
        float:left;
    

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;


        word-wrap: break-word;

    }





`;








export const MediaList = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid black",
    borderRadius:       props.borderRadius || "10px",

    width:              props.width || "calc(100%-2px)",
    height:             props.height || "auto",
    padding:            props.padding  || "0 0 0 0",
    margin:             props.margin  || "0 0 15px 0",

    dynaWidth:          props.dynaWidth || "30px",
    dynaHeight:         props.dynaHeight || "auto",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    // fontWeight:         props.fontWeight  || "500",
    // textAlign:          props.textAlign || "left",
    // textDecoration:     props.textDecoration  || "none",
    // color:              props.color || "black"





    // border-left:${props => props.border};
    // border-right:${props => props.border};
    // border-bottom:${props => props.border};

    // border-bottom-left-radius:${props => props.borderRadius};
    // border-bottom-right-radius:${props => props.borderRadius};



}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
    color:white;

    .Group {

        background-color:transparent;
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};
        color:white;
    
        display:flex;
        align-items: left;
    
    }

    .Group .Left {

        background-color:transparent;
        height:${props => props.dynaHeight};
        min-height:20px;

    }
    .Group .Right {

        background-color:transparent;
        height:auto;

        flex-grow:1

    }
    .Group .Bullet {

        background-color:transparent;
        width:auto;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;

    }


        .Group .Bullet .Circle {

            background-color:#0291cd;
            border-radius:100%;
    
            width:14px;
            height:14px;
            padding:0 0 0 0;
            margin:5% 0 0 5%;
            position:relative;
            float:left;
            cursor:pointer;
        }
    



    .Group .Number {

        background-color:transparent;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: bold;
        text-align: center;
        text-decoration:none;
        color:black;

        word-wrap: break-word;

    }
    .Group .Value {

        background-color:transparent;
        width:95%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;
    

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;


        word-wrap: break-word;

    }





`;





// export const MediaLink = styled.div.attrs(props => ({

//     backgroundColor:    props.backgroundColor || "pink",
//     width:              props.width || "100%",
//     height:             props.height || "auto",
//     padding:            props.padding  || "10px 0 10px 0",
//     margin:             props.margin  || "0 0 0 0",


//     position:           props.position || "left",





// }))`


//     background-color:${props => props.backgroundColor};
//     width:${props => props.width};
//     height:${props => props.height};
//     padding:${props => props.padding};
//     margin:${props => props.margin};

//     font-size: 16px;
//     font-weight: bold;
//     text-align: ${props => props.position};
//     text-decoration:underline;
//     color:blue;


// `;



export const MediaLink = styled.div.attrs(props => ({

    //Main
    mainWidth:          props.mainWidth || "100%",

    //Btn
    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "auto",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 7px 0",


    //Text
    textDecoration:     props.textDecoration || "none",
    textColorOver:      props.textColorOver || "orange",
    textColorOut:       props.textColorOut || "purple",


    //margin:0 auto 0 calc(50% - 2%);


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "600",
    // textAlign:          props.textAlign || "left",
    // textDecoration:     props.textDecoration  || "underline",
    // color:              props.color || "black"




}))`


    background-color:transparent;
    width:${props => props.mainWidth};
    height:auto;
    margin:${props => props.margin};
    position:relative;
    float:left;


    .LinkLeft {

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

     
        width:${props => props.width};
        height:auto;
        padding:2px 0 2px 0;
        margin:0 auto 0 0px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{


            text-decoration:underline;      
            color:${props => props.textColorOver};


        }




    }

    .LinkRight {

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:auto;
        padding:2px 0 2px 0;
        margin:0 auto 0 0px;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{


            text-decoration:underline;      
            color:${props => props.textColorOver};


        }




    }



    .LinkCenter {

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};
    

        width:${props => props.width};
        height:auto;
        padding:2px 0 2px 0;
        margin:0 auto 0 calc(50% - 5%);

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{


            text-decoration:underline;      
            color:${props => props.textColorOver};


        }




    }


    a.Anchor{

        width:100%;
        height:100%;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColorOut};

        cursor:pointer;
        

        &:hover{

            text-decoration:underline;      
            color:${props => props.textColorOver};

        }

    }





`;






export const MediaButton = styled.div.attrs(props => ({

    //Main
    mainWidth:          props.mainWidth || "100%",
    

    //Btn
    // backgroundColor:    props.backgroundColor || "#0291cd",
    // border:             props.border || "1px solid #0291cd",

    backgroundColorOver:    props.backgroundColorOver || "orange",
    backgroundColorOut:     props.backgroundColorOut || "#0291cd",

    borderOver:             props.borderOver || "transparent",
    borderOut:              props.borderOut || "transparent",

    textColorOver:          props.textColorOver || "black",
    textColorOut:           props.textColorOut || "black",

    borderRadius:           props.borderRadius|| "14px",


    
    width:              props.width || "250px",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 5px 0",
    padding:            props.padding  || "14px 0 11px 0",



    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "600",
    // textAlign:          props.textAlign || "left",
    // textDecoration:     props.textDecoration  || "underline",
    // color:              props.color || "black"



    // position:           props.position === 'right' ? "right" : props.position === "center" ? "center" : "left",
    // float:              props.position === 'right' ? "right" : props.position === "center" ? "left" : "left",
    // marLeft:            props.position === 'right' ? "calc(100% - 250px)" : props.position === "left" ? "0px" : "auto",


    // background-color:#818692;
    // color:white;






}))`


    background-color:transparent;
    width:${props => props.mainWidth};
    height:auto;
    margin:${props => props.margin};
    position:relative;
    float:left;


    .BtnLeft {

        background-color:${props => props.backgroundColorOut};
        border:${props => props.borderOut};

        border-radius:${props => props.borderRadius};

        max-width:250px;
        width:100%;
        height:auto;
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:none;
        color:${props => props.textColorOut};

        cursor:pointer;

        &:hover{

            background-color:${props => props.backgroundColorOver};
            border:${props => props.borderOver};
            color:${props => props.textColorOver};

        }



    }

    .BtnRight {


        background-color:${props => props.backgroundColorOut};
        border:${props => props.borderOut};
        
        border-radius:${props => props.borderRadius};

        max-width:250px;
        width:100%;
        height:auto;
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:none;
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{

            background-color:${props => props.backgroundColorOver};
            border:${props => props.borderOver};
            color:${props => props.textColorOver};
        }



    }



    .BtnCenter {

        background-color:${props => props.backgroundColorOut};
        border:${props => props.borderOut};

        border-radius:${props => props.borderRadius};

        max-width:250px;
        width:100%;
        height:auto;
        padding:${props => props.padding};
        margin:0 auto 0 auto;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:none;
        color:${props => props.textColorOut};


        cursor:pointer;

        &:hover{

            background-color:${props => props.backgroundColorOver};
            border:${props => props.borderOver};
            color:${props => props.textColorOver};
        }


    }





`;







export const MediaBoxButton = styled.div.attrs(props => ({

    //Main
    mainWidth:          props.mainWidth || "100%",
    

    //Btn
    // backgroundColor:    props.backgroundColor || "#0291cd",
    // border:             props.border || "1px solid #0291cd",

    backgroundColorOver:    props.backgroundColorOver || "orange",
    backgroundColorOut:     props.backgroundColorOut || "#0291cd",

    borderOver:             props.borderOver || "transparent",
    borderOut:              props.borderOut || "transparent",

    textColorOver:          props.textColorOver || "black",
    textColorOut:           props.textColorOut || "black",

    borderRadius:           props.borderRadius|| "20px",


    
    width:              props.width || "150px",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 5px 0",
    padding:            props.padding  || "14px 0 10px 0",




    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    // textAlign:          props.textAlign || "left",
    // textDecoration:     props.textDecoration  || "underline",
    // color:              props.color || "black"



    // position:           props.position === 'right' ? "right" : props.position === "center" ? "center" : "left",
    // float:              props.position === 'right' ? "right" : props.position === "center" ? "left" : "left",
    // marLeft:            props.position === 'right' ? "calc(100% - 250px)" : props.position === "left" ? "0px" : "auto",


    // background-color:#818692;
    // color:white;




}))`


    background-color:transparent;
    width:${props => props.mainWidth};
    height:auto;
    margin:${props => props.margin};
    position:relative;
    float:left;


    .BtnLeft {

        background-color:${props => props.backgroundColorOut};
        border:${props => props.borderOut};

        border-radius:${props => props.borderRadius};

        max-width:225px;
        width:100%;
        height:auto;
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:none;
        color:${props => props.textColorOut};

        cursor:pointer;

        &:hover{

            background-color:${props => props.backgroundColorOver};
            border:${props => props.borderOver};
            color:${props => props.textColorOver};

        }



    }

    .BtnRight {


        background-color:${props => props.backgroundColorOut};
        border:${props => props.borderOut};
        
        border-radius:${props => props.borderRadius};

        max-width:225px;
        width:100%;
        height:auto;
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:none;
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{

            background-color:${props => props.backgroundColorOver};
            border:${props => props.borderOver};
            color:${props => props.textColorOver};
        }



    }



    .BtnCenter {

        background-color:${props => props.backgroundColorOut};
        border:${props => props.borderOut};

        border-radius:${props => props.borderRadius};

        max-width:225px;
        width:100%;
        height:auto;
        padding:${props => props.padding};
        margin:0 auto 0 auto;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:none;
        color:${props => props.textColorOut};


        cursor:pointer;

        &:hover{

            background-color:${props => props.backgroundColorOver};
            border:${props => props.borderOver};
            color:${props => props.textColorOver};
        }


    }





`;




export const MediaBoxLink = styled.div.attrs(props => ({

    //Main
    mainWidth:          props.mainWidth || "100%",

    //Btn
    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "150px",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 7px 0",


    //Text
    position:           props.position || "left",
    textColorOver:      props.textColorOver || "orange",
    textColorOut:       props.textColorOut || "purple",




    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "550",
    // textAlign:          props.textAlign || "left",
    // textDecoration:     props.textDecoration  || "underline",
    // color:              props.color || "black"



    //margin:0 auto 0 calc(50% - 2%);



}))`


    background-color:transparent;
    width:${props => props.mainWidth};
    height:auto;
    margin:${props => props.margin};
    position:relative;
    float:left;


    .LinkLeft {

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};


        max-width:150px;

        width:${props => props.width};
        height:auto;
        padding:2px 0 2px 0;
        margin:0 auto 0 0px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:none;
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{


            text-decoration:underline;      
            color:${props => props.textColorOver};


        }




    }

    .LinkRight {

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};


        max-width:150px;

        width:${props => props.width};
        height:auto;
        padding:2px 0 2px 0;
        margin:0 auto 0 0px;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:underline;
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{


            text-decoration:underline;      
            color:${props => props.textColorOver};


        }




    }



    .LinkCenter {

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};
    


        max-width:150px;

        width:150px;
        height:auto;
        padding:2px 0 2px 0;
        margin:0 auto 0 calc(50% - 75px);

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: center;
        text-decoration:underline;
        color:${props => props.textColorOut};

        cursor:pointer;


        &:hover{


            text-decoration:underline;      
            color:${props => props.textColorOver};


        }




    }


    a.Anchor{

        width:100%;
        height:100%;

        font-family:${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.position};
        text-decoration:none;
        color:${props => props.textColorOut};

        cursor:pointer;
        

        &:hover{

            text-decoration:underline;      
            color:${props => props.textColorOver};

        }

    }





`;







export const MediaWrapText = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 0 0"


}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};

    display:flex;
    flex-wrap: wrap;
    justify-content: center;


    .Wrapper {

        background-color:transparent;

        flex: 1 1 100%;
        margin: 0 0 0 0;

    }



`;

export const MediaWrapTextHeading = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 15px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "30px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


    line-height: 1.0;

`;


export const MediaWrapTextPgraph = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};

    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    line-height: 1.5;

`;








export const MediaBreaker = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0px solid lightgray",
    borderRadius:       props.borderRadius || "5px",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};

    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    line-height: 1.5;

`;








export const MediaHeadline = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0px solid lightgray",
    borderRadius:       props.borderRadius || "0px",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",

    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f",

    lineHeight:         props.lineHeight || "1.4"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};
    padding:${props => props.padding};

    position:relative;
    float:left;


`;


    export const MediaHeadlineText = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "lavender",
        border:             props.border || "0px solid lightgray",
        borderRadius:       props.borderRadius || "0px",

        width:              props.width || "100%",
        height:             props.height || "auto",
        margin:             props.margin || "0 auto 0 auto",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "24px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "#454d5f",

        lineHeight:         props.lineHeight || "1.4"


    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};
        margin:${props => props.margin};
        padding:${props => props.padding};

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        font-style:${props => props.fontStyle};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};
        line-height:${props => props.lineHeight};



    `;







export const MediaDynamicText = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightgreen",

    border:             props.border || "1px solid black",

    borderTop:          props.borderTop || "0",
    borderRight:        props.borderRight || "0",
    borderBottom:       props.borderBottom || "0",
    borderLeft:         props.borderLeft || "0",

    borderRadius:       props.borderRadius || "5px",


    width:              props.width || "calc(100%-30px); ",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",
    padding:            props.padding || "30px 15px 30px 30px",



    lineBackgroundColor:    props.lineBackgroundColor || "lightgreen",

    lineWidth:              props.lineWidth || "100%; ",
    lineHeight:             props.lineHeight || "auto",
    lineMargin:             props.lineMargin  || "0 0 15px 0",
    linePadding:            props.linePadding || "0 0 0 0",

    lineFontFamily:         props.lineFontFamily || 'Times',
    lineFontSize:           props.lineFontSize || "18px",
    lineFontWeight:         props.lineFontWeight  || "500",
    lineFontStyle:          props.lineFontStyle || "normal",
    lineTextAlign:          props.lineTextAlign || "left",
    lineTextDecoration:     props.lineTextDecoration  || "none",
    lineTextolor:           props.lineTextColor || "black",

    linelineHeight:         props.linelineHeight || "1.4"

}))`

    background-color:${props => props.backgroundColor};

    border:${props => props.border};

    border-top:${props => props.borderTop};
    border-right:${props => props.borderRight};
    border-bottom:${props => props.borderBottom};
    border-left:${props => props.borderLeft};

    border-radius:${props => props.borderRadius};



    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};
    padding:${props => props.padding};

    position:relative;
    float:left;


    .Line{

        background-color:${props => props.lineBackgroundColor};

        width:${props => props.lineWidth};
        height:${props => props.lineHeight};
        margin:${props => props.lineMargin};
        padding:${props => props.linePadding};

        font-family:${props => props.lineFontFamily};
        font-size:${props => props.lineFontSize};
        font-weight:${props => props.lineFontWeight};
        font-style:${props => props.lineFontStyle};
        text-align:${props => props.lineTextAlign};
        text-decoration:${props => props.lineTextDecoration};
        color:${props => props.lineTextColor};

        line-height:${props => props.linelineHeight};

    }


`;







export const MediaMathLedger = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightgreen",

    border:             props.border || "1px solid black",

    borderTop:          props.borderTop || "0",
    borderRight:        props.borderRight || "0",
    borderBottom:       props.borderBottom || "0",
    borderLeft:         props.borderLeft || "0",

    borderRadius:       props.borderRadius || "5px",


    width:              props.width || "calc(100%-30px); ",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",
    padding:            props.padding || "30px 15px 30px 30px",



    lineBackgroundColor:    props.lineBackgroundColor || "lightgreen",

    lineWidth:              props.lineWidth || "100%; ",
    lineHeight:             props.lineHeight || "auto",
    lineMargin:             props.lineMargin  || "0 0 15px 0",
    linePadding:            props.linePadding || "0 0 0 0",

    lineFontFamily:         props.lineFontFamily || 'Times',
    lineFontSize:           props.lineFontSize || "18px",
    lineFontWeight:         props.lineFontWeight  || "500",
    lineFontStyle:          props.lineFontStyle || "normal",
    lineTextAlign:          props.lineTextAlign || "left",
    lineTextDecoration:     props.lineTextDecoration  || "none",
    lineTextolor:           props.lineTextColor || "black",

    linelineHeight:         props.linelineHeight || "1.4"

}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    border-top:${props => props.borderTop};
    border-right:${props => props.borderRight};
    border-bottom:${props => props.borderBottom};
    border-left:${props => props.borderLeft};

    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};
    padding:${props => props.padding};

    position:relative;
    float:left;


    @media (max-width: 650px) {


        width:100%;
        padding:0 0 0 0;
        margin-left:0px;


    }



    .Line{

        background-color:${props => props.lineBackgroundColor};

        width:${props => props.lineWidth};
        height:${props => props.lineHeight};
        margin:${props => props.lineMargin};
        padding:${props => props.linePadding};

        font-family:${props => props.lineFontFamily};
        font-size:${props => props.lineFontSize};
        font-weight:${props => props.lineFontWeight};
        font-style:${props => props.lineFontStyle};
        text-align:${props => props.lineTextAlign};
        text-decoration:${props => props.lineTextDecoration};
        color:${props => props.lineTextColor};

        line-height:${props => props.linelineHeight};

    }





    .LedgerLine{

        background-color:${props => props.lineBackgroundColor};

        width:${props => props.lineWidth};
        height:${props => props.lineHeight};
        margin:${props => props.lineMargin};
        padding:${props => props.linePadding};

        position:relative;
        float:left;

    }


    @media (max-width: 650px) {

        .LedgerLine{

            width:98%;

        }
    

    }
        




        .LedgerLine .Left{

            background-color:transparent;

            width:calc(100% - 205px);
            height:auto;
            min-height:20px;
            margin:0 0 0 0;
            padding:5px 0 3px 10px;

            position:relative;
            float:left;

            font-family:Arial;
            font-size:${props => props.lineFontSize};
            font-weight:${props => props.lineFontWeight};
            font-style:${props => props.lineFontStyle};
            text-align:${props => props.lineTextAlign};
            text-decoration:${props => props.lineTextDecoration};
            color:${props => props.lineTextColor};

            line-height:${props => props.linelineHeight};

        }



        .LedgerLine .Right{

            background-color:transparent;
            border-left:1px solid black;

            width:175px;
            height:auto;
            min-height:20px;
            margin:0 0 0 0;
            padding:5px 5px 3px 0;

            position:relative;
            float:right;

            font-family:Arial;
            font-size:${props => props.lineFontSize};
            font-weight:${props => props.lineFontWeight};
            font-style:${props => props.lineFontStyle};
            text-align:right;
            text-decoration:${props => props.lineTextDecoration};
            color:${props => props.lineTextColor};

            line-height:${props => props.linelineHeight};

        }





        .LedgerLine .LeftHighlight{

            background-color:transparent;

            width:calc(100% - 230px);
            height:auto;
            min-height:20px;
            margin:0 0 0 0;
            padding:5px 0 3px 10px;

            position:relative;
            float:left;

            font-family:Arial;
            font-size:${props => props.lineFontSize};
            font-weight:${props => props.lineFontWeight};
            font-style:${props => props.lineFontStyle};
            text-align:${props => props.lineTextAlign};
            text-decoration:${props => props.lineTextDecoration};
            color:${props => props.lineTextColor};

            line-height:${props => props.linelineHeight};

        }



        .LedgerLine .RightHighlight{

            background-color:#caffca;
            border-left:1px solid black;

            width:200px;
            height:auto;
            min-height:20px;
            margin:0 0 0 0;
            padding:5px 5px 3px 0;

            position:relative;
            float:right;

            font-family:Arial;
            font-size:${props => props.lineFontSize};
            font-weight:${props => props.lineFontWeight};
            font-style:${props => props.lineFontStyle};
            text-align:right;
            text-decoration:${props => props.lineTextDecoration};
            color:${props => props.lineTextColor};

            line-height:${props => props.linelineHeight};

        }








`;









export const MediaNotice = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",
    border:             props.border || "1px solid black",
    borderRadius:       props.borderRadius || "5px",


    width:              props.width || "calc(100%-30px); ",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",
    padding:            props.padding || "30px 15px 30px 30px",


    fontFamily:         props.fontFamily || 'Times',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "500",
    fontStyle:          props.fontStyle || "italic",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.border};


    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};
    padding:${props => props.padding};

    position:relative;
    float:left;

    .Line{

        background-color:transparent;        
        width:100%; 
        height:auto; 
        margin:0 0 15px 0; 
        padding:0 0 0 0;    

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        font-style:${props => props.fontStyle};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        line-height:1.4; 

    }


`;







export const MediaChecklist = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",
    padding:            props.padding || "0 10px 10px 10px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`


    background-color:${props => props.backgroundColor};
    border:1px solid black;
    border-radius:2px;

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;



    .Title {

        background-color:transparent;

        width:calc(100% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 15px 0;
        color:black;

        font-family:Arial, Helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;

    }


    .Item {

        background-color:transparent;

        width:calc(100% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 10px 0px;

        position:relative;
        float:left;

        display:flex;
        align-items: left;
    
    }

    .Item .Left {

        background-color:transparent;
        height:auto;
        min-height:20px;

    }
    .Item .Right {

        background-color:transparent;
        height:auto;

        flex-grow:1;

    }
    .Item .Bullet {

        background-color:transparent;
        width:auto;
        height:auto;
        padding:0 15px 0 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

    }


        .Item .Bullet .Circle {

            background-color:#0291cd;
            border-radius:100%;
    
            width:13px;
            height:13px;
            padding:0 0 0 0;
            margin:4px 0 0 0;
            position:relative;
            float:left;
            cursor:pointer;
        }
    



    .Item .Number {

        background-color:transparent;
        height:auto;
        padding:0 15px 0 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;


        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration:none;
        color:black;

        word-wrap: break-word;

    }


    .Item .Value {

        background-color:transparent;
        width:90%;
        height:auto;
        padding:0 0 0 0;
        margin:2px 0 0 0;

        position:relative;
        float:left;
    

        font-family:Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;


        word-wrap: break-word;

    }



`;









export const MediaHashList = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#f9f9f9",
    border:             props.border || "2px solid #0291cd",
    borderRadius:       props.borderRadius || "5px",

    width:              props.width || "calc(100% - 26px)",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 15px 0",
    padding:            props.padding || "0 10px 10px 10px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black",


    titleTextColor:     props.titleTextColor  || "black",
    titleFontSize:      props.titleFontSize  || "27px",


    linkOverColor:      props.linkOverColor  || "#07a2e3",
    linkOutColor:       props.linkOutColor || "#00cc66",


    bulletType:         props.bulletType  || "square",
    bulletWidth:        props.bulletWidth || "16px",
    bulletHeight:       props.bulletHeight || "16px",




    // &:hover > .Left .Bullet .Square{
            
    //     background-color:${props => props.overColor}; 
    // }

    // &:hover > .Right .ListAnchor{
    
    //     color:${props => props.overColor}; 
    //     text-decoration:underline;
    // }


}))`



    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;




    
        .ListTitle {
    
            /* background-color:#edeef1;
            border:1px solid lightgray;
            border-radius:7px;  */
    
            width:calc(100%-10px);
            height:auto;
            padding:0 0 0 10px;
            margin:10px 0 15px 0;
    
    
            font-family:Arial, Helvetica, sans-serif;
            font-size:${props => props.titleFontSize};
            font-weight:550;
            text-align: left;
            text-decoration:none;
            color:${props => props.titleTextColor};

    
        }
    
    
        .ListItem {
    
            background-color:transparent;
    
            width:calc(100% - 0px);
            height:auto;
            padding:0 0 0 0;
            margin:0 0 10px 0px;
    
            position:relative;
            float:left;
    
            display:flex;
            align-items: left;



            &:hover > .Left .Bullet .Square{
            
                background-color:${props => props.linkOverColor}; 
            }
        
            &:hover > .Right .ListItemName{
            
                color:${props => props.linkOverColor}; 
                text-decoration:underline;
            }


        }




    
        .ListItem .Left {
    
            background-color:transparent;
            height:auto;
            min-height:20px;

            margin:0 0 0 0;
            padding:0 0 0 0;

    
        }
        .ListItem .Right {
    
            background-color:transparent;
            height:auto;

            margin:0 0 0 0;
            padding:0 0 0 0;
    
            flex-grow:1;
    
        }
        .ListItem .Bullet {
    
            background-color:transparent;
            width:auto;
            height:auto;
            padding:0 15px 0 10px;
            margin:0 0 0 0;
    
            position:relative;
            float:left;
    
        }
    
    
            .ListItem .Bullet .Circle {
    
                background-color:${props => props.linkOutColor};
                border-radius:100%;
        
                width:13px;
                height:13px;
                padding:0 0 0 0;
                margin:4px 0 0 10px;
                position:relative;
                float:left;
                cursor:pointer;
            }
    
            .ListItem .Bullet .Square {
    
                background-color:${props => props.linkOutColor};
            
                width:17px;
                height:17px;
                padding:0 0 0 0;
                margin:1px 0 0 10px;
                position:relative;
                float:left;
                cursor:pointer;
            }
        
    
        
    
    
    
        .ListItem .Value {
    
            background-color:transparent;
            width:auto;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
    
            position:relative;
            float:left;
        
    
            font-family:Arial, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:black;
    
    
            word-wrap: break-word;
    
        }




        .ListItemName{
    
            background-color:transparent;
            width:auto;
            height:auto;

            margin:0 0 0 0;
            padding:0 0 0 0;
    
            font-family:Arial, Helvetica, sans-serif;
            font-size:18px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:${props => props.linkOutColor};
    
            cursor:pointer; 

    
        }



        a.ListAnchor{
    
            background-color:transparent;
            width:auto;
            height:auto;

            margin:0 0 0 0;
            padding:0 0 0 0;
    
            font-family:Arial, Helvetica, sans-serif;
            font-size:18px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:${props => props.linkOutColor};
    
            cursor:pointer; 

    
        }
        a.ListAnchor:link { color: ${props => props.linkOutColor}; text-decoration: none}
        a.ListAnchor:active { color: ${props => props.linkOutColor}; text-decoration: none}
        a.ListAnchor:visited { color: ${props => props.linkOutColor}; text-decoration: none}
        a.ListAnchor:hover { color:${props => props.linkOverColor}; text-decoration: underline }  
    
    






`;







 


export const MediaHashSection = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0px solid #454d5f",
    borderRadius:       props.borderRadius || "0px",

    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    margin:             props.margin  || "50px 0 15px 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`



    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;




    .Title {

        background-color:transparent;

        width:calc(100% - 55px);
        height:auto;
        padding:0 0 4px 0;
        margin:5px 0 0 0;

        position:relative;
        float:left;

        font-family:Arial, Helvetica, sans-serif;
        font-size: 32px;
        font-weight: 550;
        text-align: left;
        text-decoration:none;
        color:black;


        line-height:1.0;


    }



    .Button {

        background-color:#edeef1;

        width:40px;
        height:40px;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:absolute;
        top:0px;
        right:0px;
        z-index:2;

        font-family:Arial, Helvetica, sans-serif;
        font-size:20px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#edeef1;

        cursor:pointer;

    }


        .Button .ButtonClick {

            background-color:transparent;

            width:100%;
            height:100%;
            padding:0 0 0 0;
            margin:0 0 0 0;

            position:absolute;
            top:0;
            right:0;
            z-index:3;


            font-family:Arial, Helvetica, sans-serif;
            font-size:2px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:transparent;
    



        }






    .arrowbtn{
        

        background-color    : transparent;
    
        width       		: 100%;
        height      		: 100%;
    
        margin      		: 0 0 0 0;
        padding     		: 0 0 0 0;
    
        position      		: relative;
        float     			: left;
    
    }
    
    
    
    
    
    .arrowbox{
                

        background-color    : transparent;
    
        width:29px;
        height:21px;
        padding:0 0 0 0;
        margin:14px 0 0 0;
        float:left;
    
    }
    
    
        .arrowbox .arrow-left {
        
            width: 0; 
            height: 0; 
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent; 
            
            border-right:9px solid white; 
        
        
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
        
        }
    
    
    
        .arrowbox .arrow-right {
    
            width: 0; 
            height: 0; 
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            
            border-left: 9px solid white;
        
        
            float:right;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
        
        }
            
    
    
    
        .arrowbox .arrow-down {
    
            width: 0; 
            height: 0; 
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            
            border-top: 9px solid white;
    
    
            float:right;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
    
        }
    
    
    
        .arrowbox .arrow-up {
    
            width: 0; 
            height: 0; 
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            
            border-bottom: 11px solid #454d5f;
    
    
            float:right;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
    
        }
    
        







`;







 