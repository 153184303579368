
import React from 'react';


import Messngr from 'utils/components/Process/Messngr';


export const FSerror = (props) => {


    //console.log(JSON.stringify(props.errorArr,null,2));
    //alert('FSerror')



    var items = [];
    for(var obj of props.errorArr)
    {

        items.push({

            call:'span',
            text:obj['message']
        })


    }



    let data = {

        isError     : true,
        errorType   : props.errorType,
        isTimed     : false,
        items       : items

    };

    return ( 

        <div>

            <Messngr 

                handle={(v) => props.handle(v)}
                data={data}
            
            />
           
        </div>

    );


}


