import React, { 

    //useState, 
    useEffect,
    //useRef

} from 'react';


const Timeout = (props) => {

    useEffect(() => {

        const timer = setTimeout(() => {

            console.log("duration: "+props.duration);
            props.handle({

                call        :'timeout',
                message     :"Hello from timeout",
                isError     :props.isError

            });

        }, props.duration);

        return () => clearTimeout(timer);

    }, [props]);

    return <div></div>

}


export default Timeout;

/* 

https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks


*/