export const BUTTON_TIRE_PIRELLI_SOFT_PINK = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Black*/}

    <polygon 
        fill="rgb(0,0,0)" 
        points="108,90 612,90 612,414 108,414 108,90 	"
    />
{/*Brackets*/}
    <path 
        fill="rgb(244,154,193)" 
        d="M344.358,345.169c-44.636-7.491-78.834-46.46-78.834-93.169 c0-46.708,34.198-85.679,78.834-93.17v-22.777C287.267,143.738,243,192.861,243,252s44.267,108.262,101.358,115.947V345.169 L344.358,345.169z M375.642,158.83c44.636,7.491,78.834,46.462,78.834,93.17c0,46.709-34.198,85.678-78.834,93.169v22.778 C432.732,360.262,477,311.139,477,252s-44.268-108.262-101.358-115.947V158.83L375.642,158.83z"
    />  
{/*Letter*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M367.462,265.516l-25.342-7.462 c-9.761-2.909-16.989-6.898-21.682-11.967c-4.693-5.068-7.04-11.122-7.04-18.162c0-8.823,3.098-15.815,9.292-20.978 c6.195-5.163,14.924-7.744,26.188-7.744h54.204v23.23h-54.345c-3.003,0-5.116,0.54-6.335,1.619c-1.22,1.08-1.83,2.276-1.83,3.59
		c0,1.69,0.586,3.074,1.76,4.154c1.173,1.079,3.074,1.994,5.702,2.745l25.765,7.462c10.888,3.191,19.077,7.109,24.567,11.756	c5.491,4.646,8.236,11.146,8.236,19.5c0,4.317-0.821,8.4-2.464,12.248c-1.642,3.849-4.13,7.181-7.462,9.996	c-3.332,2.816-7.556,5.068-12.671,6.758s-11.099,2.535-17.95,2.535h-49.841v-23.23h54.063c3.567,0,6.055-0.705,7.462-2.112 c1.408-1.408,2.112-3.05,2.112-4.928c0-2.253-0.868-3.966-2.604-5.139C375.511,268.214,372.249,266.924,367.462,265.516 L367.462,265.516z"
    />




    

		</svg>
		</div>

	);//return
}