export const BODY_THEME_YELLOW_SPOON = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    {/*Two*/}
    <polygon 
        fill="rgb(255,195,15)" 
        points="60.552,329.4 92.127,396 655.779,396 655.779,275.4 642.933,250.745 608.1,230.634 436.5,209.563 86.8,185.109 76.176,203.256 76.193,275.4 60.568,275.4 60.552,329.4"
    />
    {/*One*/}
    <path 
        fill="rgb(255,215,0)" 
        d="M86.8,185.109l349.7,24.454l171.6,21.07l34.833,20.111v15.957 H612.34c-15.189-20.885-39.639-34.682-67.349-35.165c-23.848-0.417-45.626,9.108-61.376,24.737 c-26.417-13.027-57.538-16.255-83.05-17.921l-147.315-9.01c-18.387-1.125-5.965,30.828-22.414,18.791 c-13.847-10.339-30.925-16.596-49.456-16.92c-32.641-0.569-61.421,17.41-76.258,44.186H76.176v-72.144L86.8,185.109L86.8,185.109z"
    />
    
    {/*Three*/}
    <polygon 
        fill="rgb(255,175,25)" 
        points="92.127,396 642.933,396 642.933,298.921 227.025,275.4 60.568,275.4 60.552,329.4 92.127,396"
    />
    {/*Rear Bumper*/}
    <polygon 
        fill="rgb(250,165,25)" 
        points="76.176,329.4 92.127,396 179.855,396 179.855,329.4 76.176,329.4"
    />
    {/*Rear Bumper Highlight*/}
    <polygon 
        fill="rgb(255,245,0)" 
        points="76.176,285.279 60.552,285.279 60.568,275.4 76.176,275.4 76.176,285.279"
    />
    {/*Rear Bumper Shadow*/}
    <polygon 
        fill="rgb(245,130,30)" 
        points="60.552,329.4 76.176,329.4 92.127,396 60.552,329.4"
    />
    {/*Rocker*/}
    <path 
        fill="rgb(245,130,30)" 
        d="M642.933,379.3V396H92.127l-0.001-0.002L92.127,396l0,0l-4-16.7 C283.727,379.3,447.309,379.3,642.933,379.3L642.933,379.3z"
    />
    {/*Inset Highlight*/}
    <path 
        fill="rgb(255,245,0)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	L179.855,318.02v41.717l280.313-0.024C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />
    {/*Inset Shadow*/}
    <path 
        fill="rgb(245,115,35)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	L179.855,318.02v12.312l251.604,10.985c10.855,0.475,22.532,9.438,26.881,16.582c0.765,1.256,1.308,1.813,1.828,1.813 C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />  
    {/*Door Handle Highlight*/}
    <path 
        fill="rgb(255,245,0)" 
        d="M302.899,232.38c-0.903-1.849-2.974-4.587-8.14-6.69 c-9.912-4.039-26.723-4.895-28.843-5.051c-2.937-0.219-9.122-0.149-8.186,5.914l0.478,3.094L302.899,232.38L302.899,232.38z"
    />
    {/*Door Handle Shadow*/}
    <path 
        fill="rgb(245,115,35)" 
        d="M266.162,231.082c1.954,0,3.548,1.594,3.548,3.548	s-1.594,3.548-3.548,3.548c-1.953,0-3.547-1.594-3.547-3.548S264.209,231.082,266.162,231.082L266.162,231.082z M300.152,232.212 l-29.457-1.802c0.471,5.016,2.687,6.109,4.926,6.132l20.105,1.186C300.893,237.779,300.152,232.212,300.152,232.212 L300.152,232.212z"
    />
    {/*One Highlight*/}
    <polygon 
        fill="rgb(255,245,0)" 
        points="436.5,209.563 86.8,185.109 76.176,203.256 77.452,203.257 436.5,209.563"
    />
    {/*Mirror Body Shadow*/}
    <path 
        fill="rgb(255,175,0)" 
        d="M420.018,215.521l1.227,1.87 c-8.865-0.307-14.482,2.165-16.687,3.683c-2.327,1.602-2.546,5.234,3.085,4.68l25.139-2.47c1.26,0.041,2.572-0.003,3.807-0.202 c1.378-0.223,4.542-1.097,3.238-3.086l-2.383-3.635c-1.073,0.17-2.324,0.216-3.747,0.117 C429.137,216.16,424.577,215.84,420.018,215.521L420.018,215.521z"
    />
    {/*Mirror Background*/}
    <path 
        fill="rgb(245,195,0)" 
        d="M437.443,216.361c4.697-0.74,6.028-3.823,3.786-7.244l-3.907-5.96 c-0.822-1.253-3.626-4.647-5.669-6.215c-6.813-5.228-12.349-6.552-21.103-6.552c-4.264,0-5.566,0.952-7.2,4.458 c-0.749,1.604-1.124,3.95-1.124,5.72v5.09c0,1.447,0.091,3.408,1.29,4.729l2.525,2.78c0.802,0.882,2.007,1.519,3.195,1.601 l10.78,0.754L423.61,221c0.935,1.426,3.336,1.578,4.617,1.802c2.035,0.354,5.423,0.755,8.36,0.28	c1.378-0.223,4.542-1.097,3.238-3.086L437.443,216.361L437.443,216.361z"
    />
    {/*Mirror Base*/}
    <path 
        fill="rgb(235,135,25)" 
        d="M437.443,216.361c-1.073,0.17-2.324,0.217-3.747,0.117	l-13.679-0.957L423.61,221c0.935,1.426,3.336,1.578,4.617,1.802c2.035,0.354,5.423,0.755,8.36,0.28	c1.378-0.223,4.542-1.097,3.238-3.086L437.443,216.361L437.443,216.361z"
    />
    {/*Mirror_Base_Highlight*/}
    <path 
        fill="rgb(245,175,0)" 
        d="M438.299,219.592l-1.062-1.619
		c-0.336-0.513-1.093-0.805-1.733-0.85l-14.267-0.997l0.845,1.288l7.073,0.523c2.369,0.174,3.686,0.219,5.114,2.258
		c0.733,1.045,2.132,1.713,3.148,1.344C438.434,221.169,438.88,220.408,438.299,219.592L438.299,219.592z"
    />
    {/*"Mirror_Shadow"*/}
    <path 
        fill="rgb(240,155,15)" 
        d="M437.443,216.361c-1.073,0.17-2.324,0.217-3.747,0.117	l-24.459-1.711c-1.188-0.082-2.394-0.719-3.195-1.601l-2.525-2.78c-0.374-0.412-0.64-0.886-0.829-1.388	c6.532,0.457,33.813,2.364,38.542,0.118C443.472,212.538,442.141,215.621,437.443,216.361L437.443,216.361z"
    />
    {/*"Mirror_Highlight"*/}
    <path 
        fill="rgb(255,245,0)" 
        d="M402.947,203.555c10.225,2.229,20.418-6.53,32.119-2.197 c-1.167-1.412-2.479-2.742-3.855-3.847c-3.146-2.373-6.389-4.21-10.196-5.287c-3.183-0.815-6.384-1.06-9.66-1.109	c-3.919-0.018-5.603,0.411-7.351,4.036c-0.744,1.691-1.035,3.584-1.057,5.423V203.555L402.947,203.555z"
    />


{/*Blue Front Graphics*/}

    {/*Two*/}
    <polygon 
        fill="rgb(0,150,220)" 
        points="349.278,282.313 362.202,289.162 329.682,298.921 340.928,308.361 304.466,313.438 316.623,323.991 278.287,334.629 296.071,340.418 274.334,345.556 282.525,350.448 259.947,355.308 266.612,365.473 249.644,372.699 275.678,379.3 227.025,396 642.933,396 642.933,285.278 608.099,230.634 482.704,215.236 488.533,222.822 447.173,222.492 462.33,230.625 421.183,230.625 422.936,240.283 372.547,244.713 380.923,256.527 354.934,263.179 378.805,275.4 349.278,282.313 	"
    />
    {/*One*/}
    <path 
        fill="rgb(0,175,240)" 
        d="M608.099,230.634l34.834,20.11v15.958h-30.592	c-15.19-20.885-39.64-34.682-67.35-35.165c-23.848-0.417-45.626,9.109-61.376,24.737c-19.068-9.403-40.588-13.701-60.68-15.991 l-1.753-9.658h41.147l-15.157-8.133l41.36,0.33l-5.829-7.586L608.099,230.634L608.099,230.634z"
    />
    {/*One Front Bumper*/}   
    <polygon 
        fill="rgb(0,175,240)" 
        points="655.779,275.399 642.933,250.744 608.099,230.634 642.933,285.278 642.933,298.921 655.779,298.921 655.779,275.399 	"
    />
    {/*Three*/}
    <polygon 
        fill="rgb(0,125,200)" 
        points="227.025,396 642.933,396 642.933,298.921 349.279,282.313 362.202,289.162 329.682,298.921 340.928,308.361 304.466,313.438 316.623,323.991 278.287,334.629 296.071,340.418 274.334,345.556 282.525,350.448 259.947,355.308 266.612,365.473 249.644,372.699 275.678,379.3 227.025,396 	"
    />
    {/*Three Front Bumper*/}
    <polygon 
        fill="rgb(0,150,220)" 
        points="655.779,379.3 655.779,298.921 642.933,298.921 642.933,379.3 655.779,379.3 	"
    />
    {/*Vent Background*/}
    <path 
        fill="rgb(0,175,240)" 
        d="M629.269,348.577l18.681,4.628l2.489-0.004 c1.779-0.004,3.422-0.325,3.422-2.821l-0.001-29.823c0-1.021-0.404-1.261-0.984-1.281l-21.414-0.759 c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603C625.877,344.809,626.697,347.94,629.269,348.577L629.269,348.577z"
    />
    {/*Vent*/}
    <path 
        fill="rgb(0,125,200)" 
        d="M648.527,353.204l-9.437-5.126c-1.72-0.934-2.519-2.618-2.52-4.77 l-0.005-11.837c0-2.773,0.814-4.013,2.066-4.716l13.386-7.511l-20.558-0.729c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603 c0,2.714,0.82,5.846,3.392,6.482l18.681,4.628L648.527,353.204L648.527,353.204z"
    />
    {/*Vent Shadow*/}
    <path 
        fill="rgb(0,100,180)" 
        d="M628.042,324.644c-0.001-2.038,1.858-2.907,3.285-3.068 l20.691-2.33l-20.558-0.729c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603c0,2.714,0.82,5.846,3.392,6.482 c-0.957-1.553-1.22-4.483-1.221-5.71L628.042,324.644L628.042,324.644z"
    />
    {/*Vent Highlight*/}
    <path 
        fill="rgb(0,175,240)" 
        d="M632.043,348.424l12.192,3.385l-7.12-4.247 c-6.029-3.596-8.149-15.236-8.107-8.605C629.051,345.587,629.347,347.676,632.043,348.424L632.043,348.424z"
    />
    {/*Rocker*/}
    <path 
        fill="rgb(0,115,190)" 
        d="M642.933,379.3V396H227.025l48.652-16.7 C396.502,379.3,512.607,379.3,642.933,379.3L642.933,379.3z"
    />
    {/*Rocker Front*/}
    <polygon 
        fill="rgb(0,130,200)" 
        points="642.933,379.3 642.933,396 655.779,396 655.779,379.3 642.933,379.3 	"
    />
    {/*Inset Highlight*/}
    <path 
        fill="rgb(0,175,240)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	l-119.877-5.233l-38.336,10.638l17.784,5.789l-21.737,5.138l8.191,4.893l-22.578,4.859l2.898,4.421l197.323-0.017 C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />
    {/*Inset Shadow*/}
    <path 
        fill="rgb(0,90,170)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	l-119.877-5.233l-38.336,10.638l153.173,6.688c10.855,0.475,22.532,9.438,26.881,16.582c0.765,1.256,1.308,1.813,1.828,1.813 C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />  
    {/*Front Lip Highlight*/}
    <polygon 
        fill="rgb(0,175,240)" 
        points="543.528,365.473 655.779,365.473 655.779,364.026 642.933,362.38 633.41,364.026 543.528,364.026 543.528,365.473 	"
    />


{/*95*/}

    {/*White Background*/}
    <path 
        fill="rgb(255,255,255)"
        d="M398.103,246.269l53.402,2.331 c3.034,0.133,5.41,2.692,5.279,5.688l-2.945,67.435c-0.13,2.995-2.72,5.338-5.753,5.206l-53.403-2.331 c-3.034-0.134-5.41-2.693-5.278-5.688l2.944-67.434C392.479,248.479,395.068,246.136,398.103,246.269L398.103,246.269z"
    />
    
    {/*Number*/}
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.558,288.46c-1.956,2.253-4.116,4.066-7.246,3.93 c-8.316-0.362-7.738-7.6-7.476-13.586l0.604-13.831c0.435-9.964,1.177-14.272,13.234-13.746	c12.063,0.526,12.483,4.861,12.047,14.85l-1.775,40.655c-0.434,9.936-2.057,14.512-13.283,14.021
		c-11.293-0.492-12.511-5.109-12.073-15.128l0.311-7.11l9.751,0.427l-0.272,6.23c-0.09,2.073-0.245,3.925,2.419,4.041
		s2.892-1.785,2.985-3.813L411.558,288.46L411.558,288.46z M412.093,277.16l0.417-9.549c0.083-1.919-0.021-3.206-0.317-3.843
		c-0.308-0.64-0.95-0.98-1.961-1.024c-1.03-0.045-1.722,0.237-2.081,0.832c-0.359,0.593-0.588,1.865-0.673,3.815l-0.417,9.549
		c-0.083,1.92,0.032,3.206,0.348,3.846c0.317,0.638,0.98,0.979,1.99,1.023c1.012,0.045,1.682-0.238,2.043-0.848 C411.791,280.352,412.009,279.079,412.093,277.16L412.093,277.16z M448.781,308.072c-0.431,9.851-2.052,14.387-13.278,13.896	c-11.293-0.493-12.513-5.07-12.079-15.002l0.5-11.455l9.751,0.426l-0.462,10.583c-0.09,2.056-0.243,3.891,2.421,4.008 c2.664,0.116,2.736-1.769,2.824-3.778l0.817-18.755c0.096-2.177-0.297-3.868-2.611-3.97c-2.313-0.101-2.927,1.323-3.028,3.657	l-8.889-0.388l1.52-34.786l24.143,1.055l-0.418,9.589l-14.792-0.646l-0.641,14.669c1.782-2.597,4.058-4.183,7.317-4.04 c9.64,0.421,8.036,9.035,7.746,15.659L448.781,308.072L448.781,308.072z"
    />
    


    

		</svg>
		</div>

	);//return
}