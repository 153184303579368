export const TIRES_DUNLOP_REVERSED = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
          

    
    {/*Dunlop*/}
    <path 
        fill="rgb(255,242,0)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.364,282.533l0.951,8.656l-0.941-3.059
		c-0.026,0.075-0.057,0.151-0.092,0.226c-0.549,1.178-1.952,1.687-3.134,1.136c-1.181-0.551-1.693-1.952-1.145-3.13
		c0.013-0.027,0.025-0.055,0.039-0.081l-2.81,1.218L482.364,282.533L482.364,282.533z M118.74,282.506l-7.131,4.966l2.81-1.218
		c-0.014,0.026-0.027,0.054-0.04,0.08c-0.549,1.178-0.036,2.58,1.145,3.131c1.182,0.551,2.585,0.042,3.134-1.136
		c0.035-0.074,0.065-0.15,0.092-0.226l0.941,3.059L118.74,282.506L118.74,282.506z M241.06,350.681l-0.951-8.656l0.941,3.059
		c0.026-0.075,0.057-0.151,0.091-0.226c0.549-1.178,1.953-1.687,3.134-1.136s1.694,1.952,1.145,3.13
		c-0.012,0.027-0.025,0.055-0.039,0.081l2.81-1.218L241.06,350.681L241.06,350.681z M108.471,294.854
		c4.989-16.447,15.614-31.268,31.172-41.216c17.56-11.229,38.117-14.149,57.013-9.801l-2.314,10.049
		c-11.676-2.714-24.23-2.215-36.304,2.144c-19.79,7.144-33.957,23.081-39.694,41.829L108.471,294.854L108.471,294.854z
		 M140.279,263.643l3.717-2.227l-0.172-1.515l-1.099,0.659l-0.415-3.225l4.146,2.605l2.583-1.547l-0.775-6.242l1.082-0.648
		L149.155,250l-3.717,2.227l0.189,1.504l1.099-0.658l0.408,3.252l-4.122-2.644l-3.716,2.227l0.207,1.493l1.082-0.648l0.604,4.729
		l-1.1,0.659L140.279,263.643L140.279,263.643z M133.864,261.9l0.963-0.845l1.106,3.48c0.145,0.442,0.388,1.151-0.712,2.118
		c-1.101,0.966-1.601-0.034-1.719-0.447l-1-3.112l0.948-0.832l-0.457-1.445l-4.693,4.12l0.457,1.445l0.963-0.846l1.18,3.688
		c0.625,1.977,2.451,0.943,3.109,0.366l1.926-1.691c1.865-1.637,1.823-3.011,1.571-3.822l-1.35-4.189l0.963-0.846l-0.457-1.445
		l-3.256,2.858L133.864,261.9L133.864,261.9z M152.193,250.389l1.204-0.438l-0.303,4.757l-1.204,0.438l-0.08,1.506l8.755-3.188
		l0.252-3.827l-1.185,0.432l-1.167,2.684l-1.893,0.688l0.265-4.743l1.224-0.445l0.099-1.512l-5.849,2.129L152.193,250.389
		L152.193,250.389z M170.196,244.085c3.727-0.655,4.007,0.382,3.27,3.444c-0.775,3.223-2.755,3.943-4.719,4.289l-2.925,0.514
		c-0.84,0.148-4.304,1.13-2.892-3.758c0.229-0.932,0.53-3.326,4.261-3.961L170.196,244.085L170.196,244.085z M169.988,247.125
		c0.157-0.628,0.245-1.658-0.957-1.447l-1.022,0.18c-0.641,0.113-1.156,0.825-1.275,1.322l-0.5,2.097
		c-0.273,1.146,0.465,1.472,0.946,1.388l0.541-0.096l0.28-0.049c1.142-0.201,1.419-0.975,1.548-1.536L169.988,247.125
		L169.988,247.125z M186.964,243.457c0.549-0.004,1.508,0.355,0.871,1.891l-0.279,0.676c-0.24,0.553-0.636,1.78-2.853,1.799
		l-3.905,0.033l-0.579,1.413l1.261-0.011l-0.598,1.393l-6.224,0.053l0.578-1.392l1.281-0.011l1.854-4.383l-1.261,0.011l0.598-1.392
		L186.964,243.457L186.964,243.457z M184.009,245.694c0.219-0.532-0.088-0.794-0.658-0.81l-1.282,0.01l-0.658,1.577l1.586-0.014
		C183.609,246.453,183.79,246.227,184.009,245.694L184.009,245.694z M123.433,269.995c2.176-3.096,3.4-2.035,4.675,0.054
		c1.727,2.831,1.506,3.57-0.401,6.283l-4.153,5.907l-0.784-1.297l0.726-1.032l-2.472-4.075l-0.737,1.049l-0.784-1.297
		L123.433,269.995L123.433,269.995z M125.358,272.223c-0.332-0.557-0.897-1.277-1.6-0.279l-0.608,0.865l2.472,4.075l0.831-1.181
		c0.667-0.949,0.213-1.367-0.092-1.855L125.358,272.223L125.358,272.223z M251.328,338.332l-9.873-3.004
		c-5.737,18.748-19.904,34.686-39.694,41.829c-12.074,4.358-24.627,4.857-36.304,2.144l-2.314,10.049
		c18.896,4.35,39.453,1.428,57.013-9.802C235.714,369.601,246.339,354.78,251.328,338.332L251.328,338.332z M219.52,369.543
		l-3.717,2.228l0.172,1.515l1.099-0.659l0.414,3.225l-4.146-2.606l-2.583,1.548l0.776,6.242l-1.082,0.648l0.19,1.503l3.717-2.227
		l-0.189-1.504l-1.099,0.659l-0.408-3.252l4.122,2.644l3.717-2.227l-0.207-1.493l-1.082,0.648l-0.604-4.729l1.1-0.659
		L219.52,369.543L219.52,369.543z M225.935,371.286l-0.963,0.845l-1.106-3.48c-0.145-0.442-0.389-1.152,0.712-2.118
		c1.101-0.966,1.601,0.034,1.719,0.446l1,3.113l-0.948,0.831l0.457,1.446l4.693-4.12l-0.457-1.445l-0.963,0.846l-1.18-3.688
		c-0.625-1.976-2.452-0.942-3.109-0.365l-1.926,1.69c-1.866,1.638-1.823,3.012-1.572,3.822l1.351,4.19l-0.963,0.845l0.457,1.446
		l3.256-2.858L225.935,371.286L225.935,371.286z M207.606,382.798l-1.204,0.438l0.303-4.757l1.204-0.438l0.08-1.506l-8.755,3.188
		l-0.252,3.826l1.185-0.432l1.167-2.683l1.892-0.688l-0.265,4.742l-1.223,0.445l-0.099,1.513l5.849-2.129L207.606,382.798
		L207.606,382.798z M189.603,389.102c-3.727,0.655-4.007-0.382-3.27-3.443c0.776-3.224,2.755-3.944,4.719-4.29l2.924-0.515
		c0.84-0.147,4.304-1.13,2.892,3.759c-0.229,0.932-0.53,3.325-4.261,3.961L189.603,389.102L189.603,389.102z M189.812,386.061
		c-0.158,0.629-0.246,1.659,0.957,1.447l1.022-0.18c0.641-0.112,1.156-0.824,1.275-1.321l0.499-2.097
		c0.273-1.146-0.465-1.473-0.946-1.388l-0.541,0.095l-0.28,0.05c-1.142,0.2-1.42,0.975-1.548,1.536L189.812,386.061L189.812,386.061
		z M172.835,389.729c-0.549,0.005-1.508-0.354-0.871-1.89l0.279-0.676c0.239-0.553,0.636-1.78,2.853-1.799l3.905-0.033l0.579-1.413
		l-1.261,0.011l0.598-1.393l6.224-0.054l-0.578,1.393l-1.282,0.011l-1.854,4.383l1.261-0.011l-0.598,1.392L172.835,389.729
		L172.835,389.729z M175.79,387.492c-0.219,0.532,0.088,0.795,0.658,0.811l1.281-0.012l0.658-1.576l-1.586,0.014
		C176.19,386.733,176.009,386.959,175.79,387.492L175.79,387.492z M236.367,363.191c-2.176,3.096-3.401,2.035-4.675-0.055
		c-1.727-2.83-1.506-3.569,0.401-6.282l4.153-5.907l0.784,1.297l-0.726,1.032l2.472,4.075l0.737-1.049l0.784,1.297L236.367,363.191
		L236.367,363.191z M234.441,360.964c0.332,0.557,0.897,1.277,1.6,0.279l0.608-0.865l-2.472-4.075l-0.831,1.181
		c-0.667,0.949-0.213,1.367,0.092,1.855L234.441,360.964L234.441,360.964z M472.096,294.882l9.873,3.004
		c5.737-18.748,19.904-34.686,39.693-41.829c12.074-4.357,24.628-4.857,36.304-2.144l2.314-10.048
		c-18.896-4.35-39.453-1.429-57.013,9.801C487.71,263.613,477.085,278.434,472.096,294.882L472.096,294.882z M503.903,263.671
		l3.717-2.228l-0.172-1.515l-1.1,0.659l-0.414-3.225l4.146,2.606l2.582-1.548l-0.775-6.242l1.081-0.648l-0.189-1.504l-3.717,2.227
		l0.189,1.504l1.1-0.658l0.407,3.252l-4.122-2.644l-3.716,2.227l0.207,1.493l1.081-0.648l0.604,4.729l-1.1,0.659L503.903,263.671
		L503.903,263.671z M497.489,261.928l0.963-0.845l1.106,3.48c0.145,0.442,0.388,1.152-0.712,2.118
		c-1.102,0.966-1.601-0.034-1.719-0.446l-1-3.113l0.948-0.831l-0.457-1.446l-4.693,4.12l0.457,1.445l0.963-0.846l1.18,3.688
		c0.625,1.976,2.452,0.942,3.109,0.365l1.926-1.69c1.865-1.638,1.823-3.012,1.571-3.822l-1.35-4.19l0.963-0.845l-0.456-1.446
		l-3.257,2.858L497.489,261.928L497.489,261.928z M515.817,250.416l1.204-0.438l-0.304,4.757l-1.204,0.438l-0.079,1.506l8.754-3.188
		l0.253-3.826l-1.186,0.431l-1.166,2.684l-1.893,0.688l0.265-4.743l1.224-0.445l0.099-1.512l-5.849,2.129L515.817,250.416
		L515.817,250.416z M533.82,244.113c3.727-0.656,4.008,0.381,3.27,3.443c-0.775,3.223-2.755,3.943-4.718,4.289l-2.926,0.514
		c-0.84,0.148-4.304,1.131-2.892-3.758c0.229-0.932,0.53-3.325,4.261-3.961L533.82,244.113L533.82,244.113z M533.612,247.152
		c0.157-0.628,0.245-1.658-0.957-1.447l-1.021,0.18c-0.642,0.113-1.156,0.825-1.275,1.322l-0.499,2.097
		c-0.273,1.147,0.466,1.472,0.946,1.388l0.541-0.096l0.28-0.049c1.143-0.201,1.419-0.975,1.548-1.535L533.612,247.152
		L533.612,247.152z M550.589,243.484c0.55-0.004,1.508,0.355,0.87,1.891l-0.278,0.676c-0.24,0.553-0.637,1.78-2.854,1.799
		l-3.905,0.033l-0.578,1.413l1.261-0.011l-0.598,1.393l-6.225,0.054l0.578-1.393l1.281-0.011l1.854-4.383l-1.261,0.012l0.598-1.393
		L550.589,243.484L550.589,243.484z M547.634,245.723c0.219-0.533-0.089-0.795-0.658-0.811l-1.281,0.01l-0.658,1.577l1.587-0.013
		C547.233,246.48,547.414,246.254,547.634,245.723L547.634,245.723z M487.057,270.022c2.176-3.095,3.4-2.035,4.675,0.055
		c1.727,2.83,1.506,3.569-0.401,6.282l-4.152,5.907l-0.784-1.297l0.726-1.032l-2.473-4.075l-0.736,1.049l-0.784-1.297
		L487.057,270.022L487.057,270.022z M488.982,272.25c-0.332-0.557-0.897-1.277-1.6-0.279l-0.608,0.865l2.472,4.075l0.831-1.181
		c0.667-0.949,0.213-1.367-0.093-1.855L488.982,272.25L488.982,272.25z M614.952,338.36l-9.873-3.005
		c-5.737,18.748-19.904,34.686-39.693,41.829c-12.074,4.358-24.628,4.857-36.304,2.144l-2.314,10.049
		c18.896,4.35,39.453,1.428,57.013-9.801C599.338,369.628,609.963,354.808,614.952,338.36L614.952,338.36z M583.145,369.571
		l-3.717,2.227l0.172,1.515l1.1-0.659l0.414,3.225l-4.146-2.606l-2.583,1.548l0.775,6.242l-1.081,0.648l0.189,1.504l3.717-2.227
		l-0.189-1.505l-1.1,0.659l-0.407-3.252l4.122,2.644l3.716-2.227l-0.207-1.493l-1.082,0.648l-0.604-4.729l1.1-0.659L583.145,369.571
		L583.145,369.571z M589.559,371.313l-0.963,0.845l-1.106-3.48c-0.145-0.442-0.389-1.151,0.713-2.118
		c1.101-0.966,1.6,0.034,1.718,0.447l1,3.112l-0.948,0.832l0.457,1.445l4.693-4.119l-0.457-1.446l-0.963,0.846l-1.18-3.688
		c-0.625-1.977-2.451-0.943-3.108-0.366l-1.927,1.69c-1.865,1.638-1.823,3.012-1.571,3.823l1.35,4.189l-0.963,0.846l0.457,1.445
		l3.256-2.858L589.559,371.313L589.559,371.313z M571.23,382.825l-1.204,0.438l0.304-4.757l1.203-0.438l0.08-1.506l-8.755,3.188
		l-0.252,3.826l1.186-0.431l1.167-2.683l1.892-0.689l-0.265,4.743l-1.224,0.445l-0.099,1.512l5.85-2.129L571.23,382.825
		L571.23,382.825z M553.228,389.129c-3.727,0.655-4.007-0.382-3.27-3.443c0.775-3.224,2.755-3.944,4.719-4.29l2.925-0.514
		c0.84-0.148,4.304-1.13,2.892,3.759c-0.229,0.931-0.53,3.324-4.261,3.96L553.228,389.129L553.228,389.129z M553.436,386.088
		c-0.157,0.629-0.245,1.659,0.957,1.448l1.021-0.18c0.642-0.113,1.156-0.825,1.275-1.322l0.499-2.097
		c0.273-1.146-0.465-1.472-0.946-1.388l-0.541,0.096l-0.28,0.049c-1.142,0.201-1.419,0.975-1.547,1.536L553.436,386.088
		L553.436,386.088z M536.46,389.757c-0.55,0.004-1.509-0.355-0.871-1.891l0.278-0.676c0.239-0.553,0.637-1.78,2.854-1.799
		l3.905-0.033l0.578-1.413l-1.261,0.011l0.598-1.393l6.225-0.054l-0.578,1.393l-1.281,0.012l-1.854,4.382l1.261-0.011l-0.598,1.393
		L536.46,389.757L536.46,389.757z M539.415,387.52c-0.22,0.532,0.088,0.795,0.657,0.811l1.281-0.011l0.658-1.577l-1.587,0.014
		C539.814,386.761,539.634,386.987,539.415,387.52L539.415,387.52z M599.991,363.219c-2.176,3.096-3.401,2.035-4.675-0.054
		c-1.727-2.831-1.506-3.57,0.4-6.283l4.153-5.907l0.784,1.297l-0.725,1.032l2.472,4.075l0.737-1.049l0.783,1.297L599.991,363.219
		L599.991,363.219z M598.065,360.991c0.332,0.557,0.898,1.277,1.6,0.279l0.608-0.865l-2.472-4.075l-0.831,1.181
		c-0.666,0.949-0.213,1.367,0.093,1.856L598.065,360.991L598.065,360.991z M604.684,350.708l7.131-4.966l-2.81,1.218
		c0.014-0.026,0.026-0.054,0.039-0.08c0.55-1.178,0.037-2.58-1.145-3.13c-1.182-0.551-2.585-0.043-3.134,1.135
		c-0.035,0.074-0.065,0.15-0.092,0.226l-0.941-3.059L604.684,350.708L604.684,350.708z"
    /> 
    {/*Circle*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M115.779,288.92c-1.1-0.514-1.577-1.817-1.066-2.913 c0.511-1.097,1.817-1.57,2.917-1.058s1.577,1.816,1.066,2.914C118.184,288.959,116.878,289.433,115.779,288.92L115.779,288.92z M244.021,344.267c-1.099-0.513-2.405-0.039-2.917,1.057c-0.511,1.097-0.034,2.401,1.066,2.914s2.405,0.039,2.917-1.058	C245.597,346.084,245.12,344.779,244.021,344.267L244.021,344.267z M479.403,288.947c1.1,0.513,2.405,0.039,2.916-1.057	c0.512-1.097,0.034-2.401-1.065-2.914s-2.405-0.039-2.917,1.058C477.826,287.13,478.304,288.435,479.403,288.947L479.403,288.947z M607.645,344.294c-1.099-0.513-2.405-0.039-2.916,1.058c-0.512,1.097-0.034,2.4,1.065,2.913s2.405,0.04,2.917-1.058 C609.222,346.111,608.745,344.808,607.645,344.294L607.645,344.294z"
    />
    {/*D*/}
    <path 
        fill="rgb(190,0,0)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.227,285.869l-0.861,1.848l0.416,0.37l0.159-0.341	l0.621,0.563l-0.103,0.219l0.406,0.395l0.967-2.074c0.231-0.494,0.365-1.152-0.206-1.576
		C117.223,284.974,116.679,284.898,116.227,285.869L116.227,285.869z M117.152,286.336c0.109,0.121,0.138,0.407,0.04,0.617 l-0.162,0.348l-0.615-0.573l0.202-0.434C116.716,286.122,116.956,286.142,117.152,286.336L117.152,286.336z M243.573,347.317 l0.861-1.848l-0.416-0.37l-0.159,0.34l-0.621-0.562l0.102-0.219l-0.406-0.395l-0.966,2.074c-0.231,0.494-0.365,1.151,0.207,1.576	C242.576,348.213,243.12,348.288,243.573,347.317L243.573,347.317z M242.647,346.851c-0.109-0.121-0.139-0.407-0.041-0.617 l0.162-0.348l0.615,0.573l-0.202,0.434C243.083,347.064,242.843,347.045,242.647,346.851L242.647,346.851z M479.851,285.896 l-0.86,1.848l0.416,0.37l0.158-0.34l0.621,0.562l-0.103,0.219l0.406,0.395l0.966-2.074c0.231-0.494,0.365-1.151-0.206-1.576 C480.848,285.001,480.304,284.926,479.851,285.896L479.851,285.896z M480.776,286.363c0.109,0.121,0.139,0.407,0.04,0.617
		l-0.161,0.348l-0.615-0.573l0.202-0.434C480.341,286.149,480.58,286.169,480.776,286.363L480.776,286.363z M607.197,347.345	l0.861-1.848l-0.417-0.37l-0.158,0.34l-0.62-0.562l0.102-0.219l-0.405-0.395l-0.967,2.074c-0.231,0.495-0.365,1.152,0.206,1.576	C606.2,348.24,606.744,348.315,607.197,347.345L607.197,347.345z M606.271,346.878c-0.109-0.12-0.139-0.407-0.041-0.617	l0.162-0.348l0.615,0.573l-0.202,0.434C606.707,347.092,606.468,347.072,606.271,346.878L606.271,346.878z"
    />


    

		</svg>
		</div>

	);//return
}