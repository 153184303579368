import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



import {

    Container,
    Graphic,
    InputWrap,
    Button

} from './Styled';


export const FFcaptcha = (props) => {


    // -- ref -- //
    //const refInput = useRef(null);


    //---------------------------------------------
    // state/effect - currentGroup
    //---------------------------------------------

    const [isLoaded, setIsLoaded] = useState(false);
    const [captcha, setCaptcha] = useState(false);
    const [fieldVal, setFieldVal] = useState("Type Code");
    const [alphabets] = useState({

        upperlower: "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz"

    });
    const [inputClass, setInputClass] = useState("InputTagEmpty");
    // const [inputStyl, setInputStyl] = useState({

    //     backgroundColor:"white"

    // });



    useEffect(() => {

        // -- effect -- //

        const effectCaptcha = (eff) => {

            //let props = eff.props;
            let alphabets = eff.alphabets.upperlower;
            let genCaptcha;
     
                let first = alphabets[Math.floor(Math.random() * alphabets.length)];
                let second = Math.floor(Math.random() * 10);
                let third = Math.floor(Math.random() * 10);
                let fourth = alphabets[Math.floor(Math.random() * alphabets.length)];
                let fifth = alphabets[Math.floor(Math.random() * alphabets.length)];
                let sixth = Math.floor(Math.random() * 10);

            genCaptcha = first.toString()+second.toString()+third.toString()+fourth.toString()+fifth.toString()+sixth.toString();
            console.log(genCaptcha);

            setCaptcha(genCaptcha);
            //setFieldVal(genCaptcha); // FOR TESTING

            if(!isLoaded) setIsLoaded(true);
        };


        // -- cause -- //

        var cause = false;
        if(!isLoaded) cause = true;
        if(!captcha) cause = true;
        if(cause)
        {
            //console.log('cause - effect');
            effectCaptcha({
        
                props:props,
                isLoaded:isLoaded,
                captcha:captcha,
                alphabets:alphabets

            });

        }
    },
    [

        props, 
        isLoaded,
        captcha,
        alphabets
    ])





    // //---------------------------------------------
    // // state/effect - isProblem
    // //---------------------------------------------

    // const [hasProblem, setHasProblem] = useState(false);
    // const [inputStyl, setInputStyl] = useState({

    //     backgroundColor:"white"

    // });


    // useEffect(() => {

    //     // -- effect -- //

    //     const effectError = (eff) => {

    //         //let props = eff.props;
    //         setHasProblem(true);
    //         setInputStyl({

    //             backgroundColor:"lightyellow"

    //         });

    //     };

    //     const effectNoError = (eff) => {

    //         //let props = eff.props;
    //         setHasProblem(false);
    //         setInputStyl({

    //             backgroundColor:"white"

    //         });

    //     };


    //     // -- cause -- //

    //     var cause = false;
    //     var problemArr = props.problemArr;
    //     var object;
    //     for(object of problemArr)
    //     {
    //         if(object.section === 'captcha')
    //         {
    //             cause = true;
    //             break;
    //         }

    //     }//for

    //     if(cause && !hasProblem)
    //     {
    //         //console.log('cause - effect');
    //         effectError();

    //     }

    //     if(!cause && hasProblem)
    //     {
    //         //console.log('cause - effect');
    //         effectNoError();

    //     }


    // },
    // [

    //     props, 
    //     isLoaded,
    //     hasProblem

    // ])






    const handleGenerateCaptcha = (hndl) => {

        //alert(hndl);
        setCaptcha(false);
        
        props.handle({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :"problemArr",
            value   :[]

        }});

    }

    // const handleCheckCaptcha = () => {

    //     let userValue = document.getElementById("entered-captcha").value;
    //     //let status = document.getElementById('status');

    //     if(userValue === captcha){
    //         // navigate him to next page or do anything
    //         //status.innerText = "Correct!!"
    //     }else{
    //         // tell user to enter the code again
    //         //status.innerText = "Try Again!!"
    //         document.getElementById("entered-captcha").value = '';
    //     }
    // }



    // -- handle -- //

    function handleFocus(target) {

        target.select();
        setInputClass("InputTag");
        document.getElementById('entered-captcha').style.backgroundColor = "white";

    }

    function handleBlur(e) {


        //console.log(e.target.value);
        //e.target.select();

        if(e.target.value === '')
        {
            setFieldVal("TypeCode");
        }   


        // if(e.target.value !== "Type Code")
        // {
        //     setInputClass("InputTag");
        // }
        // else
        // {
        //     setInputClass("InputTagEmpty");
        // }


        // if(e.target.value === captcha)
        // {
        //     setInputStyl({backgroundColor:"white"});
        // }

    


        props.handle({

            call:"captcha",
            data:{

                generated : captcha,
                entered : e.target.value
            }

        });



        // var newVal;

        // if(e.fieldType === 'price' 
        // || e.fieldType === 'percent'
        // )
        // {

        //     newVal = e.target.value;

        //     switch(e.fieldType)
        //     {
        //     case'price':

        //         if(isNaN(newVal)) newVal = 1;

        //         newVal = parseFloat(newVal);
        //         newVal = newVal.toFixed(2);
        //     break;
        //     case'percent':

        //         if(isNaN(newVal)) newVal = 1;

        //         newVal = parseFloat(newVal);
        //         newVal = newVal.toFixed(1);
        //     break;
        //     default:
        //     }//==


        //     setFieldVal(newVal);

        //     props.handle({

        //         call        :'capture',
        //         fieldCall   :"field",
        //         key         :props.ij,
        //         id          :props.data.id,
        //         data        :props.data,
        //         newValue    :newVal
        
        //     })



        // }


    }
    

    function handleChange(e) {



        //console.log("TARGET INDX: ", e.indx);
        //console.log("TARGET VALUE: ", e.target.value);
        //console.log("PLACEHOLDER: ", e.target.placeholder);
        //console.log("TARGET ID: ", e.target.id);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.hint);

        //var newStyl;
        //var newArr;
        //var i;
        //var phoneStyl;


        //newStyl = fieldStyl;

        // if(e.target.value !== props.data.hint && e.target.value !== '')
        // {
        //     newStyl['fontStyle'] = 'normal';
        //     newStyl['fontColor'] = '#000';
        // }else{
        //     newStyl['fontStyle'] = 'italic';
        //     newStyl['fontColor'] = '#999';
        // }

        // setFieldStyl(newStyl);


        setFieldVal(e.target.value);



        // props.handle({

        //     call:"captcha",
        //     errors:e.target.value

        // });


        // if(props.problemArr.length > 0)
        // {
        //     if(props.ij !== undefined)
        //     {
        //         newArr = [];
        //         for(i=0; i < props.problemArr.length; i++)
        //         {

        //             //console.log(props.problemArr[i]['key'] +" "+ props.ij);
        //             if(props.problemArr[i]['key'] !== props.ij)
        //             {
        //                 newArr.push(props.problemArr[i]);
        //             }
        //         }

        //         //console.log(JSON.stringify(newArr,null,2));
        //         //alert('new')
        //         props.handle({

        //             call:"resetProblemArr",
        //             errors:newArr

        //         });

        //     }
        // }


    


    }









    return (<div>

        <Container>

            <Graphic
            id="generated-captcha"
            >
                {captcha}

            </Graphic>

            <InputWrap
            //backgroundColor={inputStyl.backgroundColor}
            >

                <input
                    className={inputClass}
                    // style={{

                    //     backgroundColor :fieldStyl.phoneStyl['phx'+j].bgColor,
                    //     fontStyle:fieldStyl.phoneStyl['phx'+j].fontStyle,
                    //     color:fieldStyl.phoneStyl['phx'+j].fontColor
                    // }}
                    id={"entered-captcha"}
                    type="text"
                    value={fieldVal}
                    //readonly={"readonly"}
                    // placeholder={phData.safeInitVal}
                    // maxLength={phData.inputMaxLength}
                    onFocus = {event => {
                        // event properties must be copied to use async
                        const target = event.target;
                        setTimeout(() => handleFocus(target), 0);
                    }}
                    onBlur={handleBlur}  
                    // //onChange={handleChange}
                    onChange={event => {

                        handleChange({

                            target:event.target,
                            indx:props.ij

                        })

                    }}

                />

            </InputWrap>


            <Button
            onClick={(v) => handleGenerateCaptcha()}
            >

                {"Generate Code"}

            </Button>


        </Container>

    </div>)

}
