import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,


} from "react-router-dom";


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
// import { useFetch }  from 'utils/hooks/Fetch';
// import { useMatomo } from '@datapunt/matomo-tracker-react';


import {

    //Stage,
    //ScrollBox,
    Area,
    //AreaTitle
    //Tray

} from 'utils/components/Pages/Styled';

import Content from "utils/components/Pages/Content";

import Loader from 'utils/components/Process/Loader';


const Index = (props) => {


    //console.log("Area --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));


    //-------------------------------
    // window dims
    //-------------------------------

    // const { 
    //     //width, 
    //     height 
    // } = useWindowDimensions();

    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    //-------------------------------
    // redux
    //-------------------------------

    // //const dispatch = useDispatch();
    // const zoneObj = useSelector(state => state.zone);
    // //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    let currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    //let zoneKey = currentZone + "_fetch";



    //------------------------------------------------------------------------------

    const refArea = useRef(null);


    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    //const [databind, setDatabind] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }

        // if(!databind)
        // {
        //     setDatabind(props.databind);
        // }


        // if(props.databind.sceneArr.length > 0)
        // {


        //     console.log("AreaEffect --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));
  

        // }

  


    },
    [
        isLoaded,
        //databind,
        props,
        // height,
        // zoneObj,
        // zoneKey,
        // pathname,
        // fetchd,

        // refScroll,
        // refHeader,
        // refArea,

        // headerCall,
        // headerPlaceholder,
        // scrollAtTop,

        // settings,
        // sceneArr


    ])

    





    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

            <Area
            ref={refArea}
            maxWidth={1680+"px"}
            width={"96%"}
            height={"calc(100vh - 100px)"}
            //backgroundColor={"powderblue"}
            >

                <div id={"LoadingNPreset"}>

                    {/* <div 
                    style={{

                        width:"100%",
                        height:"auto",
                        padding:"15px 0 15px 0",
                        margin:"50px auto",
                        textAlign:"center",
                        color:"black"
                    }}
                    >

                        Loading...
                    
                    </div> */}

                    <Loader 
                        call={"pageLoader"}
                    />

                </div>

            </Area>


        )



    } 
    else 
    {



        return (


            <Area
            ref={refArea}
            //backgroundColor={'lightgreen'}
            //height={"500px"}
            >
                <Content

                    databind={props.databind}
            
                    // primeProps = {props.primeProps}
                    // handle = {(v) => handle(v)}                 
                    // databind = {{
    
                    //     primeProps  :props.primeProps,
                    //     handle: (v) => handle(v),
    
                    //     headerCall  :headerCall,               
                    //     scrollAtTop :scrollAtTop, 
                    //     width       :viewWidth,
                    //     top         :headerBarTop,
                    //     placeholder :headerPlaceholder,
                    //     settings    :settings,

                    //     headerSizes: headerSizes,
                    //     viewHeight  :viewHeight,
                    //     sceneArr    :sceneArr


                    // }}

                
                />

            </Area>


        )


    }


}

export default Index;


