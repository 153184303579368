export const STATIC_BACKGROUND_GLASS = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
    <defs>
        <linearGradient id="Window" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(7)">
            <stop offset="0%" style={{"stopColor":"rgb(45,60,65)","stopOpacity":"1"}} />
            <stop offset="100%" style={{"stopColor":"rgb(90,105,115)","stopOpacity":"1"}} />
        </linearGradient>
    </defs>  



{/*Static Base*/}

    
    {/*Side Glass*/}
    <polygon 
        fill="url(#Window)" 
        points="431.787,209.232 383.633,135.785 286.229,129.828 241.086,143.629 183.587,191.877 431.787,209.232 "
    />
    
    
    

    

		</svg>
		</div>

	);//return
}