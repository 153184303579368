export const BUTTON_LOGO_HONDA = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.879,258.77v11.982h12.249v13.34H72.001v-13.34h11.366V233.2H72.001v-13.356
		h52.127V233.2h-12.249v11.938h38.355V233.2h-12.249v-13.356h52.128V233.2h-11.367v37.552h11.367v13.34h-52.128v-13.34h12.249
		V258.77H111.879L111.879,258.77z M604.08,219.844h-55.76V233.2h15.181l-21.681,37.552h-12.553v13.34h43.07v-13.34h-11.596
		l4.896-7.68h33.749l3.834,7.68h-11.709v13.34h56.489v-13.34h-12.724L604.08,219.844L604.08,219.844z M581.981,233.964l9.374,16.235
		h-18.748L581.981,233.964L581.981,233.964z M424.212,233.2h13.325v37.552h-13.325v13.34l67.678,0.001
		c21.12-0.001,36.263-8.241,36.263-32.512c0-20.28-15.981-31.737-34.887-31.737h-69.054V233.2L424.212,233.2z M465.025,270.752
		V233.2h14.777c9.693,0,19.211,4.464,19.211,17.823c0,13.361-5.596,19.729-15.458,19.729H465.025L465.025,270.752z M302.254,284.092
		h41.215v-13.34h-13.018v-34.575l36.628,47.915h38.938V233.2h12.471v-13.356h-41.714V233.2h12.764v32.587l-35.779-45.943h-51.504
		V233.2h12.656v37.552h-12.656V284.092L302.254,284.092z M245.9,272.332c11.006,0,20.915-8.366,20.915-20.332
		s-9.909-20.332-20.915-20.332c-11.008,0-20.918,8.366-20.918,20.332S234.893,272.332,245.9,272.332L245.9,272.332z M245.9,286.037
		c21.683,1.052,50.231-6.526,50.231-34.037s-28.549-35.089-50.231-34.037c-21.684-1.052-50.233,6.526-50.233,34.037
		S224.217,287.089,245.9,286.037L245.9,286.037z"
    />
    





    

		</svg>
		</div>

	);//return
}