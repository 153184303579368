export const WING_RACING_THEME_CAMEL_12 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

    
    {/*Wing Backgroung*/}
     <path 
        fill="rgb(253,185,19)" 
        d="M76.175,164.732v26.066c0,4.445,3.33,7.995,8.097,8.632 l37.004,3.821h8.348c2.311,0,4.487-0.903,6.126-2.543c1.641-1.641,2.544-3.816,2.544-6.127l-0.002-29.851 c-0.001-4.78-3.89-8.67-8.67-8.67H84.846C80.064,156.062,76.175,159.951,76.175,164.732L76.175,164.732z"
    />
    {/*Center*/}
    <path 
        fill="rgb(255,221,0)" 
        d="M78.335,164.787v25.961c0.177,3.737,2.663,5.918,6.234,6.542 l36.818,3.802h8.21c3.675-0.092,6.451-2.854,6.537-6.534l-0.002-29.771c-0.191-3.798-2.766-6.374-6.564-6.565H84.9 C81.099,158.412,78.529,160.988,78.335,164.787L78.335,164.787z"
    />
    {/*Filler*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M76.175,203.256v-12.457c0,2.741,1.266,5.141,3.354,6.729L76.175,203.256 L76.175,203.256z"
    />
    {/*12*/}
     <path 
        fill="rgb(42,42,134)" 
        d="M94.083,169.745l-2.607,1.036l0.656-5.256	c2.396,0.338,4.877-0.096,7.45-1.039v22.471c0,2.677,1.75,5.506,1.75,5.506h-9.379c0,0,2.13-2.829,2.13-5.506V169.745 L94.083,169.745z M109.011,187.617h13.983v4.846H103.54v-4.902c0-6.082,7.909-8.869,8.98-9.27s5.741-2.008,5.741-5.491
		s-3.12-4.713-5.272-4.713s-5.325,1.853-4.554,5.989c-3.402,0.128-4.894-1.818-4.894-3.923c0-3.349,4.608-6.489,9.449-6.489 c4.841,0,10.006,3.482,10.006,9.739c0,4.259-2.242,5.96-5.258,7.855C114.721,183.154,108.988,184.157,109.011,187.617 L109.011,187.617z"
    />


    

		</svg>
		</div>

	);//return
}