export const LOGOUT = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M250.001,79C344.184,79,421,155.816,421,250.001C421,344.184,344.184,421,250.001,421C155.816,421,79,344.184,79,250.001 C79,155.816,155.816,79,250.001,79L250.001,79z M250.001,113.478c75.194,0,136.522,61.328,136.522,136.523	c0,75.194-61.328,136.522-136.522,136.522c-75.195,0-136.523-61.328-136.523-136.522 C113.478,174.806,174.806,113.478,250.001,113.478L250.001,113.478z M332.951,172.298c9.994,9.994,9.016,27.329-2.176,38.52 l-44.121,44.122l44.105,44.105c11.141,11.141,12.125,28.57,2.174,38.521c-9.994,9.994-27.328,9.016-38.52-2.176l-44.105-44.105 l-44.084,44.084c-11.14,11.141-28.57,12.123-38.52,2.174c-9.995-9.994-9.017-27.328,2.174-38.52l44.084-44.084l-44.1-44.1 c-11.141-11.141-12.124-28.57-2.174-38.521c9.995-9.995,27.328-9.017,38.52,2.175l44.1,44.1l44.121-44.122 C305.57,163.331,323,162.348,332.951,172.298L332.951,172.298z"
			/>

		</svg>
		</div>

	);//return



	// let darklite = props.darklite || "lite";
	// if(darklite === 'dark')
	// {
	// 	return(<div></div>)
	// }
	// else // lite
	// {
	
	// }




}