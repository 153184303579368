export const BUTTON_LOGO_DUNLOP = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.861,280.39l4.871-11.075h10.23l15.262-34.848h-10.068l4.71-11.072h34.579 l17.373,35.011l10.553-23.938h-10.228l4.873-11.072h34.579l-4.868,11.072h-10.065l-20.298,45.923h-24.029l-17.694-34.849	l-10.392,23.773h10.229l-5.031,11.075H262.861L262.861,280.39z M240.078,234.467l4.871-11.076h34.583l-4.871,11.076h-10.229	l-13.962,32.24c-2.762,6.188-9.741,14.655-29.552,14.655h-20.458c-6.981,0-23.377-3.418-16.722-18.563l12.34-28.333h-10.229	l4.871-11.076h49.843l-4.87,11.076h-10.066l-10.393,23.935c-1.46,3.094-3.732,11.724,7.958,11.724 c11.688,0,13.963-5.536,15.423-8.954l11.69-26.704H240.078L240.078,234.467z M384.609,234.467l5.031-11.072h49.682l-4.869,11.072	H424.06l-14.936,34.848h16.073l16.073-16.935h10.065l-12.338,28.01h-74.363l4.712-11.075h10.228l15.263-34.848H384.609 L384.609,234.467z M523.143,222.58H498.79c-30.202-0.163-35.881,18.239-38.968,25.241c-17.859,36.477,10.729,33.544,17.536,33.544 h23.704c15.91,0,32.47-2.932,43.021-27.195C554.113,231.121,553.341,222.58,523.143,222.58L523.143,222.58z M517.302,246.193 l-6.012,14.002c-1.782,4.235-5.031,9.933-14.286,9.933h-2.273h-4.383c-3.896,0-9.256-3.579-5.521-12.213l6.822-15.794	c1.621-3.744,6.655-8.629,11.852-8.629h8.281C521.521,233.492,519.407,241.471,517.302,246.193L517.302,246.193z M639.437,223.395 h-73.872l-4.869,11.072h10.066l-15.102,34.848h-10.228l-4.706,11.075h49.681l4.869-11.075H585.21l4.712-11.237h31.17 c17.697,0,20.945-9.769,22.894-14.164l2.273-5.374C651.451,226.326,643.824,223.395,639.437,223.395L639.437,223.395z M615.699,241.048c-1.786,4.233-3.244,6.025-8.117,6.025H594.92l5.357-12.54h10.229 C615.051,234.698,617.486,236.814,615.699,241.048L615.699,241.048z M151.396,223.395H96.841l-4.871,11.072h10.23l-15.263,34.848 H76.871L72,280.39h57.637c26.465,0,32.308-1.956,42.862-26.22C180.295,236.26,181.593,223.395,151.396,223.395L151.396,223.395z M145.688,246.193l-6.007,14.002c-1.787,4.235-2.436,9.119-11.69,9.119h-11.526l15.26-34.848h8.444 C149.91,234.467,147.801,241.471,145.688,246.193L145.688,246.193z"
    />
    





    

		</svg>
		</div>

	);//return
}