
import React, { 

    useState, 
    useEffect, 
    useRef,
    // //useMemo

} from 'react';


import StylRows from './StylModules/Rows.module.css';


import FGrid from 'utils/components/Grid/FGrid';
import VidGrid from 'utils/components/Grid/VidGrid';

import TxtComponent from 'utils/components/Content/Txt';
import Billboard from 'utils/components/Content/Billboard';
import Navigator from 'utils/components/Content/Navigator';
import SlideShow from 'utils/components/Content/SlideShow';
import WideScreen from 'utils/components/Content/WideScreen';
import Band from 'utils/components/Content/Band';
import DynamicForm from 'utils/components/Content/DynamicForm';
import SVGboard from 'utils/components/Content/SVGboard';
import SVGgrid from 'utils/components/Content/SVGgrid';


// import Loader from 'utils/components/Process/Loader';
// import Fetch from 'utils/components/Process/Fetch';



const Index = (props) => {



    // console.log("Content --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));

    // -- ref -- //
    const refContent = useRef(null);

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [contentH, setContentH] = useState(false);  
    //const [sceneArr] = useState(props.databind.sceneArr);
    //const [sceneArr, setSceneArr] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            //console.log("isnt loaded - "+refContent.current.offsetHeight);

        }else{

            if(!contentH) 
            {

                //console.log("is loaded - setContentH - " + refContent.current.offsetHeight);

                setContentH(refContent.current.offsetHeight);
                props.databind.handle({

                    call:'contentHeight',
                    data:{

                        contentHeight:refContent.current.offsetHeight

                    }

                });

            }
            //console.log("sceneArr: "+JSON.stringify(sceneArr,null,2));


            // if(Object.prototype.toString.call(props.databind.sceneArr) === '[object Array]')
            // {
            //     if(sceneArr.length === 0)
            //     {
            //         setSceneArr(props.databind.sceneArr);
            //     }
            // }


        }

    },
    [
        isLoaded,
        props,
        refContent,
        contentH,
        //sceneArr
 
    ])












    return (


        <div
        ref={refContent}
        className={StylRows.Rows}
        >
            {props.databind.sceneArr.map((row, i) => (

                <div
                key={i}
                >

                    {
                    row.info.compData && row.info.compData !== undefined
                    ?
                        row.info.compData.id
                        ?
                            row.styl.call === 'indent' 
                            ? 

                                row.styl.class === 'IndentRowCenter' 
                                ? 
                                    <div
                                    id={row.info.compData.id}
                                    className={StylRows.IndentRowCenter}
                                    style={{

                                        //backgroundColor:"red",
                                        marginTop:row.styl.marginTop,
                                        marginBottom:row.styl.marginBottom,
                                        
                                        
                                    }}
                                    >

                                        <div className={StylRows.IndentRowCenterPiece}>
                                        {
                                        row.call === 'TxtComponent' 
                                        ? 

                                            <TxtComponent
                                                handle = {(v) => props.databind.handle(v)} 
                                                info   = {row.info}         
                                                items  = {row.items}
                                                refid  = {row.info.compData.id}

                                                viewHeight  = {props.databind.viewHeight}
                                                databind    = {props.databind}

                                                handlePage = {(v) => props.databind.handle(v)}

                                            />

                    
                                        :null
                                        } 
                                        </div>


                                    </div>

                                :
                                row.styl.class === 'IndentRowCompact' 
                                ? 

                                    <div
                                    id={row.info.compData.id}
                                    className={StylRows.IndentRowCompact}
                                    style={{

                                        //backgroundColor:"red",
                                        marginTop:row.styl.marginTop,
                                        marginBottom:row.styl.marginBottom,
                                        
                                        
                                    }}
                                    >
                                        {
                                        row.call === 'TxtComponent' 
                                        ? 

                                            <TxtComponent
                                                handle = {(v) => props.databind.handle(v)} 
                                                info   = {row.info}         
                                                items  = {row.items}
                                                refid  = {row.info.compData.id}

                                                viewHeight  = {props.databind.viewHeight}
                                                databind    = {props.databind}

                                                handlePage = {(v) => props.databind.handle(v)}

                                            />

                    
                                        :null
                                        }  

                                    </div>

                                :
                                row.styl.class === 'IndentRowWide' 
                                ? 
                                    <div
                                    id={row.info.compData.id}
                                    className={StylRows.IndentRowWide}
                                    style={{

                                        //backgroundColor:"red",
                                        marginTop:row.styl.marginTop,
                                        marginBottom:row.styl.marginBottom,
                                        
                                        
                                    }}
                                    >

                                        {
                                        
                                        row.call === 'TxtComponent' 
                                        ? 

                                            <TxtComponent
                                                handle = {(v) => props.databind.handle(v)} 
                                                info   = {row.info}         
                                                items  = {row.items}
                                                refid  = {row.info.compData.id}

                                                viewHeight  = {props.databind.viewHeight}
                                                databind    = {props.databind}

                                                handlePage = {(v) => props.databind.handle(v)}

                                            />

                    
                                        :null
                                        }  

                                    </div>


                                ://========== Indent Row B




                                row.styl.class === 'IndentRowB' 
                                ? 
                                    <div
                                    id={row.info.compData.id}
                                    className={StylRows.IndentRowB}
                                    style={{

                                        //backgroundColor:"pink",
                                        marginTop:row.styl.marginTop,
                                        marginBottom:row.styl.marginBottom,
                                        
                                        
                                    }}
                                    >

                                        {
                                        
                                        row.call === 'TxtComponent' 
                                        ? 

                                            <TxtComponent
                                                handle = {(v) => props.databind.handle(v)} 
                                                info   = {row.info}         
                                                items  = {row.items}
                                                refid  = {row.info.compData.id}

                                                viewHeight  = {props.databind.viewHeight}
                                                databind    = {props.databind}

                                                handlePage = {(v) => props.databind.handle(v)}

                                            />

                    
                                        :null
                                        }  

                                    </div>



                                ://========== Indent Row
                                

                                    <div
                                    id={row.info.compData.id}
                                    className={StylRows.IndentRow}
                                    style={{

                                        //backgroundColor:"lightblue",
                                        marginTop:row.styl.marginTop,
                                        marginBottom:row.styl.marginBottom,
                                        
                                        
                                    }}
                                    >

                                        {
                                        
                                        row.call === 'TxtComponent' 
                                        ? 
        
                                            <TxtComponent
                                                handle = {(v) => props.databind.handle(v)} 
                                                info   = {row.info}         
                                                items  = {row.items}
                                                refid  = {row.info.compData.idi}

                                                viewHeight  = {props.databind.viewHeight}
                                                databind    = {props.databind}

                                                handlePage = {(v) => props.databind.handle(v)}

                                            />


                                        :null

                                        }  



                                    </div>


                            :


                                <div
                                id={row.info.compData.id}
                                className={StylRows.FullRow}
                                style={{

                                    //backgroundColor:"lightgreen",
                                    marginTop:row.styl.marginTop,
                                    marginBottom:row.styl.marginBottom,
                                    
                    
                                }}
                                >

                                    {
                                    
                                    row.call === 'TxtComponent' 
                                    ? 
                                        <TxtComponent
                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}         
                                            items  = {row.items}
                                            refid  = {row.info.compData.id}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                            handlePage = {(v) => props.databind.handle(v)}

                                        />

                                    :null
                                    }


                                </div>


                        :null

                    
                    : 
                
                    //===============================================
                    // No CompData
                    //===============================================


                        row.styl.call === 'indent' 
                        ? 


                            row.styl.class === 'IndentRowCenter' 
                            ? 

                                <div
                                id={"row_"+i}
                                className={StylRows.IndentRowCenter}
                                style={{

                                    //backgroundColor:"red",
                                    marginTop:row.styl.marginTop,
                                    marginBottom:row.styl.marginBottom,
                                    
                                    
                                }}
                                >

                                    {/* <div className={StylRows.IndentRowCenterPiece}> */}
                                    {
                                    row.call === 'TxtComponent' 
                                    ? 

                                        <TxtComponent
                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}         
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                            handlePage = {(v) => props.databind.handle(v)}

                                        />

                                    :
                                    row.call === 'FGrid' 
                                    ? 
                                        <FGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'VidGrid' 
                                    ? 
                                        <VidGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}


                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}


                                        />

                                    :
                                    row.call === 'Billboard' 
                                    ? 
                                        <Billboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}


                                        />

                                    :
                                    row.call === 'Navigator' 
                                    ? 
                                        <Navigator

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

            
                                    :
                                    row.call === 'SlideShow' 
                                    ? 
                                        <SlideShow

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />
                                    :
                                    row.call === 'WideScreen' 
                                    ? 
                                        <WideScreen
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Band' 
                                    ? 
                                        <Band
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Form' || row.call === 'DynamicForm'
                                    ? 
                                        <DynamicForm
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'SVGboard' 
                                    ? 
                                        <SVGboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'SVGgrid' 
                                    ? 
                                        <SVGgrid

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :


                                        <div>No Row</div>

                                    }  
                                    {/* </div> */}




                                </div>



                            ://========== Indent Row Compact


                            row.styl.class === 'IndentRowCompact' 
                            ? 

                                <div
                                id={"row_"+i}
                                className={StylRows.IndentRowCompact}
                                style={{

                                    //backgroundColor:"red",
                                    marginTop:row.styl.marginTop,
                                    marginBottom:row.styl.marginBottom,
                                    
                                    
                                }}
                                >

                                    {
                                
                                    row.call === 'TxtComponent' 
                                    ? 

                                        <TxtComponent
                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}         
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                            handlePage = {(v) => props.databind.handle(v)}

                                        />

                                    :
                                    row.call === 'FGrid' 
                                    ? 
                                        <FGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'VidGrid' 
                                    ? 
                                        <VidGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}


                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}


                                        />

                                    :
                                    row.call === 'Billboard' 
                                    ? 
                                        <Billboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}


                                        />

                                    :
                                    row.call === 'Navigator' 
                                    ? 
                                        <Navigator

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

            
                                    :
                                    row.call === 'SlideShow' 
                                    ? 
                                        <SlideShow

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />
                                    :
                                    row.call === 'WideScreen' 
                                    ? 
                                        <WideScreen
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Band' 
                                    ? 
                                        <Band
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Form' || row.call === 'DynamicForm'
                                    ? 
                                        <DynamicForm
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'SVGboard' 
                                    ? 
                                        <SVGboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'SVGgrid' 
                                    ? 
                                        <SVGgrid

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :


                                        <div>No Row</div>

                                    }  






                                </div>



                            ://========== Indent Row Wide




                            row.styl.class === 'IndentRowWide' 
                            ? 
                                <div
                                id={"row_"+i}
                                className={StylRows.IndentRowWide}
                                style={{

                                    //backgroundColor:"red",
                                    marginTop:row.styl.marginTop,
                                    marginBottom:row.styl.marginBottom,
                                    
                                    
                                }}
                                >

                                    {
                                
                                    row.call === 'TxtComponent' 
                                    ? 
        
                                        <TxtComponent
                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}         
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                            handlePage = {(v) => props.databind.handle(v)}

                                        />


                                    :
                                    row.call === 'FGrid' 
                                    ? 
                                        <FGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'VidGrid' 
                                    ? 
                                        <VidGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}


                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}


                                        />

                                    :
                                    row.call === 'Billboard' 
                                    ? 
                                        <Billboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}


                                        />

                                    :
                                    row.call === 'Navigator' 
                                    ? 
                                        <Navigator

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

            
                                    :
                                    row.call === 'SlideShow' 
                                    ? 
                                        <SlideShow

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />
                                    :
                                    row.call === 'WideScreen' 
                                    ? 
                                        <WideScreen
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Band' 
                                    ? 
                                        <Band
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Form' || row.call === 'DynamicForm'
                                    ? 
                                        <DynamicForm
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'SVGboard' 
                                    ? 
                                        <SVGboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'SVGgrid' 
                                    ? 
                                        <SVGgrid

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :



                                        <div>No Row</div>

                                    }  






                                </div>



                            ://========== Indent Row B




                            row.styl.class === 'IndentRowB' 
                            ? 
                                <div
                                id={"row_"+i}
                                className={StylRows.IndentRowB}
                                style={{

                                    //backgroundColor:"pink",
                                    marginTop:row.styl.marginTop,
                                    marginBottom:row.styl.marginBottom,
                                    
                                    
                                }}
                                >

                                    {
                                
                                    row.call === 'TxtComponent' 
                                    ? 
    

                                        <TxtComponent
                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}         
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                            handlePage = {(v) => props.databind.handle(v)}

                                        />



                                    :
                                    row.call === 'FGrid' 
                                    ? 
                                        <FGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
                                        />

                                    :
                                    row.call === 'VidGrid' 
                                    ? 
                                        <VidGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}


                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'Billboard' 
                                    ? 
                                        <Billboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
                                        


                                        />

                                    :
                                    row.call === 'Navigator' 
                                    ? 
                                        <Navigator

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'SlideShow' 
                                    ? 
                                        <SlideShow

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'WideScreen' 
                                    ? 
                                        <WideScreen
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Band' 
                                    ? 
                                        <Band
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Form' || row.call === 'DynamicForm'
                                    ? 
                                        <DynamicForm
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'SVGboard' 
                                    ? 
                                        <SVGboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'SVGgrid' 
                                    ? 
                                        <SVGgrid

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :


                                        <div>No Row</div>

                                    }  






                                </div>



                            ://========== Indent Row
                            

                                <div
                                id={"row_"+i}
                                className={StylRows.IndentRow}
                                style={{

                                    //backgroundColor:"lightblue",
                                    marginTop:row.styl.marginTop,
                                    marginBottom:row.styl.marginBottom,
                                    
                                    
                                }}
                                >

                                    {
                                    
                                    row.call === 'TxtComponent' 
                                    ? 
    
                                        <TxtComponent
                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}         
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                            handlePage = {(v) => props.databind.handle(v)}

                                        />


                                    :
                                    row.call === 'FGrid' 
                                    ? 
                                        <FGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'VidGrid' 
                                    ? 
                                        <VidGrid

                                            handle = {(v) => props.databind.handle(v)} 
                                            info   = {row.info}          
                                            items  = {row.items}
                                            refid  = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'Billboard' 
                                    ? 
                                        <Billboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'Navigator' 
                                    ? 
                                        <Navigator

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}

                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}

                                        />

                                    :
                                    row.call === 'SlideShow' 
                                    ? 
                                        <SlideShow

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'WideScreen' 
                                    ? 
                                        <WideScreen
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Band' 
                                    ? 
                                        <Band
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'Form' || row.call === 'DynamicForm'
                                    ? 
                                        <DynamicForm
    
                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
    
                                        />
    
                                    :
                                    row.call === 'SVGboard' 
                                    ? 
                                        <SVGboard

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :
                                    row.call === 'SVGgrid' 
                                    ? 
                                        <SVGgrid

                                            handle      = {(v) => props.databind.handle(v)} 
                                            data        = {row}
                                            refid       = {"row_"+i}
            
                                            viewHeight  = {props.databind.viewHeight}
                                            databind    = {props.databind}
            
                                        />

                                    :



                                        <div>No Row</div>

                    
                                    }  







                                </div>




                        ://========= FULL ROW



                            <div
                            id={"row_"+i}
                            className={StylRows.FullRow}
                            style={{

                                //backgroundColor:"lightgreen",
                                marginTop:row.styl.marginTop,
                                marginBottom:row.styl.marginBottom,
                                
                
                            }}
                            >

                                {
                                
                                row.call === 'TxtComponent' 
                                ? 
                                    <TxtComponent
                                        handle = {(v) => props.databind.handle(v)} 
                                        info   = {row.info}         
                                        items  = {row.items}
                                        refid  = {"row_"+i}

                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}

                                        handlePage = {(v) => props.databind.handle(v)}

                                    />

                                :
                                row.call === 'FGrid' 
                                ? 
                                    <FGrid

                                        handle = {(v) => props.databind.handle(v)} 
                                        info   = {row.info}          
                                        items  = {row.items}
                                        refid  = {"row_"+i}

                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}

                                    />

                                :
                                row.call === 'VidGrid' 
                                ? 
                                    <VidGrid

                                        handle = {(v) => props.databind.handle(v)} 
                                        info   = {row.info}          
                                        items  = {row.items}
                                        refid  = {"row_"+i}

                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}


                                    />

                                :
                                row.call === 'Billboard' 
                                ? 
                                    <Billboard

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}

                                    />

                                :
                                row.call === 'Navigator' 
                                ? 
                                    <Navigator

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}

                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}
                                    />

                                :
                                row.call === 'SlideShow' 
                                ? 
                                    <SlideShow

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}
        
                                    />

                                :
                                row.call === 'WideScreen' 
                                ? 
                                    <WideScreen

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}

                                    />

                                :
                                row.call === 'Band' 
                                ? 
                                    <Band

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}

                                    />

                                :
                                row.call === 'Form' || row.call === 'DynamicForm'
                                ? 
                                    <DynamicForm

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}

                                    />

                                :
                                row.call === 'SVGboard' 
                                ? 
                                    <SVGboard

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}
        
                                    />

                                :
                                row.call === 'SVGgrid' 
                                ? 
                                    <SVGgrid

                                        handle      = {(v) => props.databind.handle(v)} 
                                        data        = {row}
                                        refid       = {"row_"+i}
        
                                        viewHeight  = {props.databind.viewHeight}
                                        databind    = {props.databind}
        
                                    />

                                :


                                    <div>No Row</div>

                
                                }  



                            </div>


                    }



                </div>

            ))}

        </div>



    );

}

export default Index;



