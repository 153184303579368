

import styled from "styled-components";




/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;


*/

const Box = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "darkgray",//#fcfcfc | #1a1926
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "10px",

    width:              props.width || "350px", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "0 0 0 0",

    top:                props.top || "calc(50% - 200px)",
    left:               props.left || "calc(50% - 175px)"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:12px;

    max-width: 400px;
    min-height: 200px;
    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:3;
    top:${props => props.top};
    left:${props => props.left};
      

`;



export {

    Box,

}
