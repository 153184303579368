export const PRICING = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	let darklite = props.darklite || "lite";

	let outerColor;
	let innerColor;
	let signColor;

	if(darklite === 'dark')
	{
		outerColor = props.iconColor || "rgb(255,255,255)";
		innerColor = props.innerColor || "rgb(30,35,45)";
		signColor = props.iconColor || "rgb(255, 255, 255)";
	}
	else // lite
	{
		outerColor = props.outerColor || "rgb(255,255,255)";
		innerColor = props.innerColor || "rgb(30,35,45)";
		signColor = props.signColor || "rgb(255, 255, 255)";
	}


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			{
				//<!--White Background-->
			}
			<path 
				fill={outerColor}
				d="M156.662,475c-24.601,0-44.609-18.128-44.609-40.411V196.354 c0-16.716,6.482-37.603,16.135-51.965c1.91-2.843,4.871-7.175,8.633-12.689c11.098-16.269,29.654-43.469,48.834-72.766 C198.521,37.991,223.15,25,249.997,25c26.782,0,51.381,12.948,64.263,33.816c19.277,29.438,37.901,56.729,49.029,73.048 c3.712,5.437,6.629,9.712,8.521,12.525c9.648,14.362,16.137,35.248,16.137,51.965v238.234c0,22.283-20.014,40.411-44.609,40.411
				H156.662z"
			/>

			{
				//<!--Navy-->
			}
			<path 
				fill={innerColor} 
				fillRule="evenodd"
				clipRule="evenodd"
				d="M249.997,71.264c14.115,0,25.626,11.508,25.626,25.62 c0,14.114-11.512,25.624-25.626,25.624c-14.108,0-25.618-11.51-25.618-25.624C224.378,82.771,235.889,71.264,249.997,71.264 L249.997,71.264z M249.997,39.819c22.424,0,41.965,10.95,51.758,26.938c25.728,39.305,50.124,74.545,57.756,85.9	c7.737,11.516,13.614,29.623,13.614,43.696v238.234c0,14.075-13.402,25.595-29.787,25.595H250h-93.338 c-16.385,0-29.792-11.52-29.792-25.595V196.354c0-14.073,5.878-32.181,13.624-43.696c7.616-11.347,31.98-46.528,57.683-85.801 C208.205,50.391,228.29,39.819,249.997,39.819L249.997,39.819z"
			/>

			{
				//<!--$-->


				// hex #00cc66
				// rgb(0, 204, 99)
				// cmyk(100%, 0%, 51%, 20%)

			}
			<path 
				fill={signColor} 
				fillRule="evenodd"
				clipRule="evenodd"
				d="M257.783,188.905v13.285c9.528,0.922,17.686,2.729,24.517,5.418 c6.793,2.733,12.71,6.813,17.821,12.319c4.01,4.203,7.108,8.491,9.25,12.942c2.188,4.419,3.276,8.497,3.276,12.196	c0,4.078-1.593,7.65-4.869,10.635c-3.239,2.986-7.159,4.496-11.804,4.496c-8.75,0-14.397-4.368-16.953-13.072
				c-2.964-10.257-10.025-17.064-21.238-20.428v51.241c11.03,2.774,19.825,5.338,26.384,7.652c6.521,2.27,12.398,5.589,17.547,9.958 c5.516,4.499,9.754,9.922,12.758,16.187c2.964,6.309,4.469,13.16,4.469,20.64c0,9.379-2.371,18.124-7.155,26.274 c-4.739,8.196-11.756,14.877-21.005,20.054c-9.252,5.166-20.285,8.235-32.997,9.203v30.692c0,4.827-0.5,8.359-1.547,10.591 c-1.004,2.228-3.235,3.315-6.746,3.315c-3.186,0-5.419-0.88-6.739-2.688c-1.279-1.808-1.915-4.623-1.915-8.408v-33.252	c-10.434-1.046-19.503-3.318-27.296-6.77c-7.791-3.443-14.312-7.732-19.502-12.858c-5.198-5.133-9.064-10.473-11.532-16.017 c-2.505-5.511-3.735-10.975-3.735-16.31c0-3.954,1.642-7.484,5.015-10.636c3.325-3.156,7.473-4.751,12.484-4.751
				c4.009,0,7.384,0.884,10.118,2.605c2.777,1.725,4.694,4.122,5.786,7.227c2.372,6.729,4.465,11.903,6.242,15.473	c1.776,3.57,4.465,6.852,8.07,9.797c3.597,2.94,8.377,5.212,14.351,6.766V305.43c-11.942-3.065-21.873-6.431-29.893-10.17 c-8.019-3.742-14.535-9.04-19.502-15.934c-4.966-6.894-7.475-15.767-7.475-26.609c0-14.125,4.875-25.726,14.629-34.722 c9.75-9.035,23.828-14.294,42.242-15.766v-13.07c0-6.896,2.825-10.342,8.431-10.342 C254.957,178.817,257.783,182.178,257.783,188.905L257.783,188.905z M241.978,273.401v-47.166 c-7.474,2.061-13.301,4.748-17.45,8.116c-4.193,3.358-6.291,8.487-6.291,15.298c0,6.471,1.964,11.392,5.881,14.713	C228.038,267.677,233.958,270.711,241.978,273.401L241.978,273.401z M258.931,309.971v53.934c8.978-1.638,15.945-4.96,20.824-9.96 c4.873-5.007,7.337-10.849,7.337-17.493c0-7.144-2.369-12.649-7.153-16.56C275.197,315.985,268.18,312.662,258.931,309.971
				L258.931,309.971z"
			/>
		
		</svg>
		</div>

	);//return







}