export const BUTTON_TIRE_DUNLOP_REVERSED = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


    {/*Back*/}
    <polygon 
        fill="rgb(255,245,0)" 
        points="90,72 630,72 630,432 90,432 90,72 	"
    />
    {/*Arrow*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M506.412,249.342L247.611,99l78.923,69.994c-1.025-0.041-2.055-0.059-3.092-0.059 c-44.76,0-81.054,36.398-81.054,81.293c0,44.896,36.294,81.301,81.054,81.301c2.825,0,5.624-0.15,8.372-0.43L250.038,405 L506.412,249.342L506.412,249.342z"
    />  
    {/*Circle*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M263.099,250.24c0-41.791,33.774-75.67,75.439-75.67 c41.673,0,75.449,33.879,75.449,75.67c0,41.784-33.775,75.669-75.449,75.669C296.873,325.909,263.099,292.024,263.099,250.24 L263.099,250.24z"
    />
    {/*D*/}
    <path 
        fill="rgb(190,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.834,219.328h-70.191l-5.51,18.391h12.936l-8.506,27.542 h-8.332l-6.396,18.402h78.807c18.806,0,41.28-5.382,46.206-29.385C407.31,237.408,401.735,219.328,364.834,219.328L364.834,219.328 z M363.755,255.01c-2.181,5.17-10.692,10.251-18.693,10.251h-13.206l8.95-27.542h16.486
		C364.083,238.293,366.947,246.074,363.755,255.01L363.755,255.01z"
    />



    

		</svg>
		</div>

	);//return
}