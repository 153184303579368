import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,  
    useHistory,
    //useParams


} from "react-router-dom";



import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useDeviceDetect from 'utils/hooks/DeviceDetect';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';



// import FGrid from 'utils/components/Grid/FGrid';
// import { LibElem } from 'library';

import {

    Grid,
    Cell,
    Layer,
    Slide

} from './Styled';


import { 
    CarSlide,
    //List,
    //CarSlideCommons,
    //ListOfCommons
}from 'utils/components/Content/SVGboard/Slides';


// import { 
//     LayerInfo
// }from 'utils/components/Content/SVGboard/Info';



import { 
    GridArray
}from './GridData';


import { 
    LibArray
}from 'library';


const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));

    

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    


    // -- window dims -- //
    const { 
        width, 
        //height 
    } = useWindowDimensions();


    // -- device detect -- //
    const { isMobile } = useDeviceDetect();

    // -- screen orientation -- //
    // const screenOrientation = useScreenOrientation()



    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //   
    const refGrid = useRef(null);

    // const [layerRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
    // const setLayerRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < layerRefs.length; i++)
    //         {
    //             if(layerRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) layerRefs.push(ref);
    //         //console.log(layerRefs.length);

    //     }

    // };





    //##############################################################
    //##############################################################
    //##############################################################

    // -- state -- //
    //const [currentOrientation, setCurrentOrientation] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [cellStyl, setCellStyl] = useState({

        width:false,
        height:false,
        margin:false

    });

    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);
    
            // var i;
            // var object;
            // var array = [];

            // for(i=0; i < LayerInfo.length; i++)
            // {
            //     object = LayerInfo[i];
            //     object['zIndex'] = i+1;
            //     array.push(object);  
            // }
            // setLayers(array);



            // var highZindex;
            // var skyZindex;
            // highZindex = LibElem.HighestZ();  
            // skyZindex = array.length + 10;
            // if(skyZindex > highZindex)
            // {
            //     skyZindex = highZindex - 1;
            // }
            // setSkyZ(array.length + 10);

        }//isLoaded




        var cellWidth,cellHeight,cellMargin;
        var cellW,cellH;

        if(width < 900)
        {

            cellW = refGrid.current.offsetWidth - 50;
            cellH = (62/100) * cellW;
            
            cellWidth = "calc(100% - 50px)";
            cellHeight = cellH + "px";
            cellMargin = "15px 0 0 25px";

        }
        else
        if(width < 1401)
        {
            cellW = refGrid.current.offsetWidth/2 - 40;
            cellH = (62/100) * cellW;

            cellWidth = "calc(50% - 30px)";
            cellHeight = cellH + "px";
            cellMargin = "15px 0 0 20px";
        }
        else
        {

            cellW = refGrid.current.offsetWidth/3 - 80;
            cellH = (70/100) * cellW;

            cellWidth = "calc(33% - 15px)";
            cellHeight = cellH + "px";
            cellMargin = "15px 0 0 15px";
       
        }

        setCellStyl({

            width   :cellWidth,
            height  :cellHeight,
            margin  :cellMargin
        });



    },
    [
        props,
        width,
        isMobile,
        isLoaded,
        refGrid
   
    ])





    //##############################################################
    //##############################################################
    //##############################################################

    // -- state -- //
    const [cells, setCells] = useState([]);

    useEffect(() => {

        //console.log("LAYER INFO: "+JSON.stringify(LayerInfo,null,2));

        if(cells.length === 0)
        {
            //var defLayers = JSON.parse(JSON.stringify(LayerInfo));

            var newCells = [];
            var newLayers;            
            var item;
            // var layer;
            // var keyname;

            var captured = [];

            for(item of GridArray)
            {
                //console.log("ITEM: "+JSON.stringify(item,null,2));
                // console.log("");
                // console.log("########################");
                // console.log("NAME: "+item.name);
                // console.log("########################");

                if(!LibArray.InArray(item.name,captured))
                {

                    captured.push(item.name);

                    // newLayers = [];
                    // for(layer of defLayers)
                    // {
                    //     if(layer.slide.call.toUpperCase() === 'BODY')
                    //     {
                    //         layer.slide.component = item.layerComponent;
                    //     }
                    //     else
                    //     if(layer.slide.call.toUpperCase() === 'WHEELS')
                    //     {
                    //         layer.slide.component = item.matchingComponents['wheels'];
                    //         newLayers.push(layer);
                    //         break;
        
                    //     }
                    //     else
                    //     {

                    //         for(keyname in item.matchingComponents)
                    //         {
                    //             if(layer.slide.call.toUpperCase() === keyname.toUpperCase())
                    //             {
                    //                 layer.slide.component = item.matchingComponents[keyname];
                    //                 break;               
                    //             }
                    //         }

                
                    //     }
                    //     console.log("LAYER: "+JSON.stringify(layer,null,2));
                    //     newLayers.push(layer);

                    // }//for


                    newLayers = [

                        //==============================================
                        // STATIC
                        //==============================================
                        {
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Static_Background_Glass',
                                component:'STATIC_BACKGROUND_GLASS'
    
                            }
    
    
                        },
    
                        {
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Static_Background',
                                component:'STATIC_BACKGROUND'
    
                            }
    
    
                        },
    
    
    
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Static_Muffler',
                                component:'STATIC_MUFFLER'
    
                            }
    
    
                        },
    
    
    
    
    
    
                        //====================================
                        //Driver
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                    
                                call:"Steering_Wheel",
                                component:item.matchingComponents.steering_wheel
                            }
    
                        },
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
    
                                call:"Glove",
                                component:"GLOVE_GRAY_WHITE"
    
                            }
    
                        },
    
    
    
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
    
                                call:"Helmet",
                                component:item.matchingComponents.helmet
    
                            }
    
                        },  
    
                        // {
    
                        //     backgroundColor:"lightblue",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
    
                        //         call:"Helmet_Visor",
                        //         component:"HELMET_VISOR_BLUE"
    
                        //     }
    
                        // },
    
    
    
    
                        // {
    
                        //     backgroundColor:"lightblue",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
    
                        //         call:"Suit",
                        //         component:"SUIT_YELLOW"
    
                        //     }
    
                        // },
    
    
    
    
    
    
    
    
    
    
    
                        //====================================
                        //HardTop
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'HardTop',
                                component:item.matchingComponents.hardtop
                    
    
                            }
    
                        },  
    
    
                        //====================================
                        //SoftTop
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'SoftTop',
                                component:item.matchingComponents.softtop
                    
    
                            }
    
                        },  
    
    
    
                        //====================================
                        //Rollbar
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Rollbar',
                                component:item.matchingComponents.rollbar
                    
    
                            }
    
                        },  
    
    
    
                        //====================================
                        //CarBody
                        //====================================
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Body',
                                component:item.layerComponent
                            }
    
    
                        },
    
    
    
    
                        //====================================
                        //Ground
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Ground',
                                component:'STATIC_GROUND'
    
                            }
    
                        },
    
    
                        //====================================
                        //Mirror
                        //====================================
                        // {
    
                        //     backgroundColor:"lightgreen",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Mirror',
                        //         component:'MIRROR_BLUE'
                        //     }
    
    
                        // },
    
    
                        //====================================
                        //Wing
                        //====================================
    
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Wing',
                                component:item.matchingComponents.wing
                            }
    
    
                        },
    
    
    
    
                        //====================================
                        //Tires
                        //====================================
    
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Static_Tires',
                                component:'STATIC_TIRES'
    
                            }
    
    
                        },
    
    
    
    
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:"Tires",
                                component:item.matchingComponents.tires
    
                            }
    
                        },
    
    
    
    
    
                        //====================================
                        //LIGHTS
                        //====================================
    
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Headlight_Taillight',
                                component:'STATIC_HEADLIGHT_TAILLIGHT'
    
                            }
    
    
                        },
    
    
    
                        // {
    
                        //     backgroundColor:"lightgreen",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Check_Marker_Lights',
                        //         component:'CHECK_MARKER_LIGHTS'
    
                        //     }
    
    
                        // },
    
    
    

                        //====================================
                        //Brakes
                        //====================================

                        // {
    
                        //     backgroundColor:"lightgreen",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Static_Brake_Rotors',
                        //         component:'STATIC_BRAKE_ROTORS'
    
                        //     }
    
    
                        // },
    
    
    
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Static_Brake_Rear',
                                component:'STATIC_BRAKES_REAR'
    
                            }
    
    
                        },
    
    
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
    
                                call:'Caliper',
                                component:item.matchingComponents.caliper
    
                            }
    
                        },
    
    
    
                        {
    
                            backgroundColor:"lightgreen",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:'Static_Caliper_Rear_Gray',
                                component:'STATIC_CALIPER_REAR_GRAY'
    
                            }
    
    
                        },
    
    
    
    
    

    
                        //====================================
                        //Windshield
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:"Banner",
                                component:item.matchingComponents.banner
    
                            }
    
                        },
    
    
    
    
    
                        // //====================================
                        // //Lights
                        // //====================================
    
    
                        // {
    
                        //     backgroundColor:"lightblue",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Lights',
                        //         component:"HeadTailLights"
    
                        //     }
    
                        // },
                        // {
    
                        //     backgroundColor:"lightblue",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Lights',
                        //         component:"MarkerLights"
    
                        //     }
    
                        // },  
    
    
    
                        // //====================================
                        // //Muffler
                        // //====================================
    
                        // {
    
                        //     backgroundColor:"lightblue",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Muffler'
    
                        //     }
    
                        // },
    
    
                        // //====================================
                        // //Mirror
                        // //====================================
    
                        // {
    
                        //     backgroundColor:"lightblue",
                        //     width:"100%",
                        //     height:"450px",
                        //     slide:{
    
                        //         call:'Mirror'
    
                        //     }
    
                        // },
    
    
    

                        //====================================
                        //Wheels
                        //====================================
                        {
    
                            backgroundColor:"lightblue",
                            width:"100%",
                            height:"450px",
                            slide:{
    
                                call:"Wheels",
                                component:item.matchingComponents.wheels
    
                            }
    
                        },
    
    
                    ];
    
    

                    //console.log("newLayers: "+JSON.stringify(newLayers,null,2));
                    newCells.push({

                        name:item.name,
                        layers:newLayers
                    });

                }//captured

            }//for


            newCells = LibArray.ShuffleArray(newCells);

            setCells(newCells);
            //console.log("newCells: "+JSON.stringify(newCells,null,2));

        }



    },
    [
        props,
        width,
        isMobile,
        isLoaded,
        cells
   
    ])





    //##############################################################
    //##############################################################
    //##############################################################

    // const handle = (hndl) => {



    //     //console.log(hndl.data);

    //     switch(hndl.call)
    //     {
    //     case'setLayers':

    //         setLayers(hndl.data);
    //     break;
    //     case'setTopLayer':

    //         setTopLayer(hndl.data);
    //     break;
    //     case'setReservedSpace':

    //         console.log("RESERVED: "+hndl.data.height);
    //         //setReservedSpace(hndl.data);
    //     break;
    //     default:
    //     }



    // };





    //##############################################################
    //##############################################################
    //##############################################################

    const handleClick = (hndl) => {


        //alert(JSON.stringify(hndl.item,null,2));


        dispatch(ModifyZoneStore({

            type:"UPDATE_ZONE",
            item:{
                key:"selected_svg",
                value:hndl.item
            }

        })); 

        history.push("/Configurator");



    };









    //##############################################################
    //##############################################################
    //##############################################################

    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (<div>


            <Grid
            ref={refGrid}
            >
            </Grid>


        </div>)

    } 
    else 
    {



        return (<div>



            <Grid
            ref={refGrid}
            >

                {cells.map((item, i) => (

                    <Cell
                    key={i}
                    width={cellStyl.width}
                    height={cellStyl.height}
                    margin={cellStyl.margin}
                    onClick={(v) => handleClick({

                        item:item

                    })}

                    >

                        <div 
                        className="Top"
                        >

                            <div 
                            className="Name"
                            >
                                {item.name}

                            </div>

                            {/* <div 
                            className="Ctrls"
                            >
                                <div 
                                className="Btn"
                                >
                                    VIEW
                                </div>

                                <div 
                                className="Btn"
                                >
                                    EDIT
                                </div>

                            </div> */}

                        </div>


          
                        {item.layers.map((layr, j) => (

                            <Layer
                                key={i+"-"+j}
                                //ref={setLayerRef}
                                id={"layer_"+i+"-"+j}
                                //backgroundColor={item.backgroundColor}
                                zIndex={(10+j)}
                                // style={{

                                //     width:layerStyl.width,
                                //     height:layerStyl.height,
                                //     top:layerStyl.top,
                                //     left:layerStyl.left

                                // }}
                            >


                                <Slide
                                //backgroundColor={item.backgroundColor}
                                //width={slideSize.width}
                                //height={slideSize.height}
                                // style={{

                                //     width:slideSize.width,
                                //     height:slideSize.height,
                
                                // }}
                                >
                                    <CarSlide 
                                    
                                        width={cellStyl.width}
                                        height={cellStyl.height}
                                        call={layr.slide.component}
                                    
                                    />


                                </Slide>

                            </Layer>


                        ))}

                    </Cell>

                ))}

            </Grid>

                   
        </div>)


    }


}

export default Index;



