

const BuildData = (props) => {


    var info = props.info;
    var items = props.items;
    var thumbWInt = props.thumbWInt;
    var thumbHInt = props.thumbHInt;
    var imgW = props.imgW;
    var imgH = props.imgH;



    const ret = [];

    var i;
    for(i=0; i < items.length; i++)
    {

        var box = items[i];

        imgW = box.image.w;
        imgH = box.image.h;



        //#####################################################

        //var mod_call = 'fill_div';

        var divWidth = thumbWInt;
        var divHeight = thumbHInt;

        var thumbWidth;
        var thumbHeight;
    
        //var decimal;
        //var h;
    

        var thumb_height;

        var img_width;
        var img_height;
    
        //var mod;
    
        var top_margin;
        var left_margin;
    
    
        var modHeight;
        var diff;                                           
        var modWidth; 
                        
        var remodWidth;
        var remodHeight;
    
        var h_diff;


       //########################################################

        modHeight = divHeight;
        diff = imgH / modHeight;                                           
        modWidth = imgW / diff; 
    
        remodWidth = divWidth;
        remodHeight = divHeight;

        //---------------------------------------

 
        switch(info.modCall)
        {
        case'fill_div':



            //################
            //GREATER THAN		
            //################

            if(modWidth > divWidth)
            {
                //alert('modWidth > divWidth');

                remodWidth = modWidth;
                diff = modWidth / remodWidth;                                           
                remodHeight = modHeight / diff; 


                //LESS THAN HEIGHT
                if(remodHeight < divHeight)
                {
                    //alert('remodHeight < divHeight');

                    h_diff = (divHeight - remodHeight);
                    remodHeight = remodHeight + h_diff;
                    remodWidth = remodWidth + h_diff;

                }//###

            }//############


            //###############
            //LESS THAN
            //###############

            if(modWidth < divWidth)
            {
                //alert('modWidth < divWidth');

                top_margin = 0;
                left_margin = 0;


                remodWidth = divWidth;
                diff = modWidth / remodWidth;                                           
                remodHeight = modHeight / diff;

            
                //LESS THAN HEIGHT
                if(remodHeight < divHeight)
                {
                    //alert('remodHeight < divHeight');

                    h_diff = (divHeight - remodHeight);
                    remodHeight = remodHeight + h_diff;
                }//###


            }//###########



            thumbWidth = remodWidth;
            thumbHeight = remodHeight;




            //##################
            //ADJUST VARS
            //##################

            //TOP MARGIN
            if(remodHeight > divHeight)
            {
                top_margin = (remodHeight - divHeight)/2;
                top_margin = -top_margin;

            }else{

                //var top_margin = 0;
                top_margin = (divHeight - thumbHeight)/2;

            }//###
                                
            //LEFT MARGIN
            if(remodWidth > divWidth)
            {
                left_margin = (remodWidth - divWidth)/2;
                left_margin = -left_margin;
            }else{
                left_margin = 0;
            }//###



        break;
        case'readjust':


            if(modWidth > divWidth)
            {
                //READJUST MOD SIZE
                remodWidth = divWidth;
                diff = modWidth / remodWidth;                                           
                remodHeight = modHeight / diff; 

                //TO SCALE
                thumbWidth = remodWidth;
                thumbHeight = remodHeight;


            }else{

                thumbWidth = modWidth;
                thumbHeight = modHeight;
            }//###




            top_margin = (divHeight - thumbHeight)/2;
            left_margin = 0;
            

        break;
        case "fitTo":

            thumbWidth = remodWidth;
            thumbHeight = remodHeight;


            top_margin = -50;
            left_margin = -150;

        break;
        default:
        break;
        }//==


        //-------------------------------------------

        img_width = Math.round(thumbWidth);
        img_height = Math.round(thumbHeight);


        top_margin = Math.round(top_margin);
        left_margin = Math.round(left_margin)

        //adjust_vars = mod_call+'|'+top_margin+'|'+left_margin;


        //mod = mod_call;
        //########################################################


    






        //########################################################

        if(info.thumb.call === "flex_height")
        {
            thumb_height = img_height;

            top_margin = 0;
            left_margin = 0;

        }else{

            thumb_height = thumbHInt;
        }

        //########################################################


        var img_width_perc;
        var img_height_perc;

        img_width_perc = (img_width / thumbWInt);
        img_height_perc = (img_height / thumbHInt);

        img_width_perc = (img_width_perc * 100);
        img_height_perc = (img_height_perc * 100);

        if(img_height_perc < 100) img_height_perc = 100;


        if(info.gridCall === 'flyr' || info.call === 'flex'
        )
        {
            if(imgH > imgW)
            {//tall
                img_height_perc = (imgH / imgW) * 100;
            }else{
                img_height_perc = (imgH / imgW) * 100;
            }
            
        }

        img_width_perc = img_width_perc.toFixed(2) + "%";
        img_height_perc = img_height_perc.toFixed(2) + "%";





        var top_margin_perc;
        var left_margin_perc;

        top_margin_perc = (top_margin / img_height);
        left_margin_perc = (left_margin / img_width);


        if(info.call === 'vid') 
        {
    
            top_margin_perc = top_margin_perc/2;
            //alert(top_margin_perc+"\n"+top_margin+"\n"+imgW+"\n"+imgH);
        }



        //console.log("ONE....\ntop perc: "+top_margin_perc+"\nleft perc: "+left_margin_perc);

        top_margin_perc = (top_margin_perc*100);
        left_margin_perc = (left_margin_perc*100);



    
        //console.log("TWO....\ntop perc: "+top_margin_perc+"\nleft perc: "+left_margin_perc);


        if(top_margin_perc < -5) top_margin_perc = top_margin_perc.toFixed(2) + "%";
        else top_margin_perc = "0%";

        if(left_margin_perc < -5) left_margin_perc = left_margin_perc.toFixed(2) + "%";
        else left_margin_perc = "0%";

        //console.log("THREE....\ntop perc: "+top_margin_perc+"\nleft perc: "+left_margin_perc);


        
        /*
        console.log("IMG AND THUMB...."

            +"\nACROSS: "+across

            +"\ngridWInt: "+gridWInt
        
            +"\ncellWPerc: "+cellWPerc
            +"\ncellWInt: "+cellWInt
        
            +"\nthumbWPerc: "+thumbWPerc
            +"\nthumbWInt: "+thumbWInt
            +"\nthummbHInt: "+thumbHInt

            +"\n imgW: "+imgW
            +"\n imgH: "+imgH
            +"\n img_height_perc: "+img_height_perc
            +"\n img_height: "+img_height
            +"\n top_margin_perc: "+top_margin_perc
            +"\n left_margin_perc: "+left_margin_perc
        );
        */
        

        var video;
        video = false;

        if(info.call === 'vid' 
        && box.video 
        && box.video !== undefined) video = box.video;


        //########################################################

        ret.push({

            position    :box.position,
    
            id          :box.id,
            title       :box.title,
            text        :box.text,
            bgColor     :box.bgColor,

            thumb:{

                w       :img_width+"px",
                h       :thumb_height+"px",

                //w       :img_width_perc,
                //h       :img_height_perc

            },

            image:{
        
                src     :box.image.src,
                w       :img_width,
                h       :img_height,
                top     :top_margin_perc,
                left    :left_margin_perc,

                maxWidth   :img_width_perc,
                maxHeight  :img_height_perc

            },

            video:video


        });


    }//== i


    return ret;



}


export default BuildData;







