export const BUTTON_MAIN_SOFTTOP = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Body*/}

   
    <path 
        fill="rgb(150,150,150)" 
        d="M142.451,185.705c0.828,0.058,1.455,0.776,1.4,1.604c-0.055,0.827-0.771,1.453-1.599,1.396l-52.279-3.656
		l-8.729,16.945l-1.279,73.277H64.34L63.43,327.44l31.931,65.431l561.149-0.09l0.066-81.089v-37.557L644.105,250.2l-34.2-19.745 l-171.207-21.02l-161.202-11.273c-0.828-0.058-1.455-0.777-1.4-1.604c0.055-0.828,0.771-1.454,1.599-1.396l153.484,10.732 l-36.248-55.278c-0.454-0.691-0.26-1.62,0.433-2.073c0.691-0.452,1.621-0.259,2.074,0.433l37.497,57.183l3.962,0.276
		l171.682,21.082l0.568,0.188l34.834,20.111l0.58,0.609l12.846,24.654l0.17,0.691v37.922l-0.068,84.089l-566.019,0.09
		l-32.914-67.443l-0.152-0.68l0.968-55.477h15.625l1.234-70.667l0.17-0.662l9.802-19.03L142.451,185.705L142.451,185.705z"
    />
    
{/*Body Lines*/}

    <path 
        fill="rgb(150,150,150)" 
        d="M647.054,379.248c0.828,0,1.5,0.672,1.5,1.5c0,0.829-0.672,1.501-1.5,1.501h-44.947	c-0.828,0-1.5-0.672-1.5-1.501c0-0.828,0.672-1.5,1.5-1.5H647.054L647.054,379.248z M127.408,379.248c0.828,0,1.5,0.672,1.5,1.5	c0,0.829-0.672,1.501-1.5,1.501H93.94c-0.829,0-1.5-0.672-1.5-1.501c0-0.828,0.672-1.5,1.5-1.5H127.408L127.408,379.248z M481.482,379.248c0.828,0,1.5,0.672,1.5,1.5c0,0.829-0.672,1.501-1.5,1.501H244.361c-0.829,0-1.5-0.672-1.5-1.501 c0-0.828,0.671-1.5,1.5-1.5H481.482L481.482,379.248z M270.375,334.059c-0.828-0.036-1.471-0.737-1.435-1.565 c0.035-0.828,0.735-1.471,1.564-1.435l163.296,7.129l0.039,0.002c1.599,0.073,3.231,0.325,4.871,0.725	c1.617,0.395,3.214,0.93,4.768,1.574c7.961,3.306,15.152,9.64,18.416,15.003c0.43,0.705,0.206,1.627-0.499,2.057 c-0.705,0.431-1.626,0.207-2.056-0.498c-2.987-4.909-9.625-10.729-17.01-13.796c-1.434-0.595-2.883-1.083-4.322-1.434 c-1.418-0.346-2.857-0.565-4.297-0.631l-0.039-0.001L270.375,334.059L270.375,334.059z M653.754,289.42c0.828,0,1.5,0.672,1.5,1.5 s-0.672,1.5-1.5,1.5h-25.993c-0.829,0-1.501-0.672-1.501-1.5s0.672-1.5,1.501-1.5H653.754L653.754,289.42z M95.214,285.979	c0.828,0,1.5,0.672,1.5,1.5c0,0.829-0.672,1.501-1.5,1.501H69.221c-0.828,0-1.5-0.672-1.5-1.501c0-0.828,0.672-1.5,1.5-1.5H95.214 L95.214,285.979z M476.328,248.809c0.77,0.301,1.15,1.169,0.85,1.939c-0.301,0.77-1.169,1.15-1.939,0.85 c-11.897-4.644-24.447-7.623-36.791-9.623c-12.319-1.996-24.535-3.021-35.7-3.75l0,0l-147.31-9.01 c-0.828-0.051-1.46-0.764-1.412-1.592c0.049-0.828,0.76-1.459,1.588-1.408l147.316,9.01h0.006
		c11.157,0.729,23.413,1.76,35.982,3.797C451.463,241.054,464.224,244.085,476.328,248.809L476.328,248.809z"
/>

{/*Wheels*/}

    <path 
        fill="rgb(150,150,150)" 
        d="M183.594,234.183l0.098,0.001c22.212,0.415,42.218,9.838,56.581,24.712	c14.361,14.87,23.078,35.19,22.718,57.399l-0.001,0.098l-0.002,0.098c-0.415,22.212-9.838,42.219-24.711,56.581	c-14.871,14.36-35.19,23.078-57.4,22.718l-0.098-0.002l-0.097-0.002c-22.212-0.414-42.219-9.838-56.582-24.711 c-14.36-14.871-23.078-35.19-22.718-57.399l0.002-0.098l0.001-0.098c0.415-22.212,9.838-42.219,24.711-56.581 c14.871-14.361,35.19-23.078,57.4-22.718L183.594,234.183L183.594,234.183z M183.645,237.185l-0.098-0.002l-0.098-0.001 c-21.392-0.348-40.96,8.045-55.278,21.873c-14.316,13.824-23.386,33.084-23.785,54.472l-0.002,0.098l-0.001,0.098 c-0.347,21.392,8.046,40.96,21.874,55.278c13.825,14.316,33.085,23.387,54.472,23.785l0.098,0.002l0.097,0.002 c21.392,0.347,40.96-8.047,55.279-21.874c14.315-13.824,23.386-33.085,23.785-54.472l0.001-0.098l0.002-0.098	c0.347-21.392-8.046-40.96-21.874-55.278C224.292,246.653,205.032,237.583,183.645,237.185L183.645,237.185z M547.211,234.183 l0.098,0.001c22.213,0.415,42.219,9.838,56.581,24.712c14.361,14.87,23.079,35.19,22.718,57.399l-0.001,0.098l-0.002,0.098 c-0.415,22.212-9.838,42.219-24.711,56.581c-14.871,14.36-35.19,23.078-57.4,22.718l-0.098-0.002l-0.097-0.002
		c-22.213-0.414-42.219-9.838-56.582-24.711c-14.36-14.871-23.077-35.19-22.717-57.399l0.001-0.098l0.002-0.098 c0.415-22.212,9.838-42.219,24.711-56.581c14.871-14.361,35.19-23.078,57.4-22.718L547.211,234.183L547.211,234.183z M547.262,237.185l-0.098-0.002l-0.098-0.001c-21.392-0.348-40.96,8.045-55.278,21.873c-14.315,13.824-23.386,33.084-23.785,54.472 l-0.002,0.098L468,313.722c-0.348,21.392,8.046,40.96,21.873,55.278c13.825,14.316,33.085,23.387,54.473,23.785l0.098,0.002
		l0.098,0.002c21.392,0.347,40.96-8.047,55.278-21.874c14.315-13.824,23.386-33.085,23.785-54.472l0.001-0.098l0.002-0.098
		c0.348-21.392-8.046-40.96-21.873-55.278C587.909,246.653,568.649,237.583,547.262,237.185L547.262,237.185z"
/>

{/*Windshield*/}

    <path 
        fill="rgb(150,150,150)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M453.126,208.721c0.453,0.691,0.259,1.62-0.433,2.072c-0.692,0.453-1.622,0.26-2.075-0.432l-31.893-48.645
		c0,0,0,0,0-0.001l-20.453-31.195c-0.405-1.312-0.162-2.465,0.45-3.696l-9.259-9.967l-7.016-0.429
		c-0.828-0.05-1.461-0.764-1.412-1.592c0.049-0.827,0.76-1.459,1.588-1.408l7.611,0.466l1.007,0.48
		c30.259,32.57,60.519,65.143,90.776,97.713c0.563,0.605,0.527,1.553-0.077,2.115s-1.552,0.529-2.114-0.076l-56.098-60.385
		c-1.384,1.955-2.169,4.326-2.242,6.721L453.126,208.721L453.126,208.721z M401.135,129.42l17.906,27.313
		c0.53-1.893,1.433-3.658,2.615-5.223L401.135,129.42L401.135,129.42z"
/>


{/*Softtop*/}

    <path 
        fill="rgb(130,130,130)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.416,188.469l76.258-63.988l50.179-15.342l93.086,5.693
		l11.042,16.843l-77.169-4.735c-14.571-0.895-26.586,3.06-34.36,13.075c-1.51,1.945-2.854,4.116-4.021,6.521
		c-1.153,2.378-2.133,4.989-2.928,7.845l-0.004-0.002c-0.007,0.023-0.013,0.047-0.02,0.069l0.004,0.001l-10.591,38.2
		c-0.221,0.796,0.246,1.62,1.042,1.84c0.795,0.22,1.62-0.247,1.841-1.042l10.591-38.202l0.004,0.001l-0.004-0.001l0.002-0.006
		l0.004-0.004l0-0.003l0.001,0.001c0.001-0.005-0.005,0.017-0.004,0.013l0.002-0.011l0.002-0.002l-0.001-0.001l0.003-0.021
		c0.746-2.684,1.667-5.14,2.75-7.374c1.075-2.217,2.31-4.212,3.692-5.993c7.094-9.141,18.229-12.738,31.818-11.904l79.401,4.873 l4.874,7.434l-84.656-5.178c-18.271-1.118-27.085,5.951-31.021,20.142l-10.821,39.021L153.416,188.469L153.416,188.469z
		M252.833,185.92c0.195,0.535-0.091,1.124-0.637,1.313c-0.546,0.189-1.147-0.092-1.342-0.627l-19.817-54.557
		c-0.195-0.535,0.091-1.123,0.637-1.313s1.147,0.092,1.342,0.628L252.833,185.92L252.833,185.92z"
/>

   

    

		</svg>
		</div>

	);//return
}