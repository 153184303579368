
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';


//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Alfa, Base } from 'app-styled/AlfaBase';
import { Confirm, Message, Controls } from './Styled';


import { LibElem } from 'library';


const Index = (props) => {


    //props.isError
    //props.isTimed
    //props.items



    // -- window dims -- //
    //const { width, height } = useWindowDimensions();


    // -- ref -- //

    //const baseRef = useRef(null)
    //const tabRef = useRef(null)
    const flyrRef = useRef(null) 
    const controlsRef = useRef(null)   



    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])



    // -- state -- //
    //const [flyrHeight, setFlyrHeight] = useState(0)
    const [buttonStyle, setButtonStyle] = useState({});
 
    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        setButtonStyle({

            bgColor     :"#0291cd",
            txtColor    :"#FFF",
            brColor     :"#0291cd"

        });
      
    }, [])



    


    // -- handle -- //

    const handleOverOut = (hndl) => {



        /*

        //console.log(v);
        setButtonStyle({

            bgColor     :v.bgColor,
            txtColor    :v.txtColor,
            brColor     :v.brColor

        });

        */


        var button = document.getElementById(hndl.id);
        button.style.backgroundColor = hndl.bgColor;
        button.style.color = hndl.txtColor;
        button.style.border = "1px solid "+hndl.borderColor;


    }


    const handleConfirm = (hndl) => {

        switch(hndl.call)
        {
        case'yes':

            props.handle({

                call:'runConfirm',
                data:hndl.data

            });

        break;
        default:

            props.handle({

                call:'redirect'

            });
    
        }


    }






    // --  size and position -- //


    /*

    var maxWidth;
    maxWidth = 100; 
    if(width < maxWidth + 50)
    {
        maxWidth = width;

    }//==


    //var flyrPadBottomInt = 80;
    //var flyrPadBottom = flyrPadBottomInt + "px";

    var flyrWInt;
    var flyrW;

    //var flyrHInt;
    var flyrH;

    var flyrMarl;

    var flyrMartInt;
    var flyrMart;


    

    //------------------------------------------

    flyrWInt = maxWidth;
    flyrMarl = (width - flyrWInt)/2 + "px";

    if(width < flyrWInt+50)
    {
        flyrWInt = width;
        flyrMarl = 0 + "px";
    }//==

    flyrW = flyrWInt + "px";


    //flyrHInt = 0;
    flyrH = 'auto';
    

    //--------------------------------------------

    


    flyrMartInt = 0;
    if(flyrHeight !== 0 && flyrHeight < height)
    {  
        flyrMartInt = (height - flyrHeight)/2 - 50;
    }

    flyrMart = flyrMartInt + 'px';

    //----------------------------------------------


    



    // -- button and message -- //

    var buttonWInt;
    var buttonW;
    var buttonMarlInt;
    var buttonMarl;

    buttonWInt = 100;
    buttonW = buttonWInt + "px";

    buttonMarlInt = (flyrWInt - buttonWInt)/2;
    buttonMarl = buttonMarlInt + "px";


    

    var msgWInt;
    var msgW;
    var msgMarl;


    msgWInt = flyrWInt - 90;
    msgW = msgWInt + "px";

    msgMarl = (flyrWInt - msgWInt)/2 - 20 + "px";

    //msgMarl = buttonMarlInt - ((msgWInt - buttonWInt)/2) + "px";

    */



    // -- vars -- //

    let array           = props.data.message;




    return ( 

        <div>


            <Alfa
            zIndex={highZ+1}
            ></Alfa>

            <Base 
            //ref={baseRef}
            zIndex={highZ+2}
            >


                <Confirm 

                    id="flyr"
                    ref={flyrRef}  

                    //backgroundColor={'#1a1926'}
                    //width={flyrW}
                    //height={flyrH}
                    //margin={flyrMart+" 0 0 "+flyrMarl}
                    //top={flyrMart}
                    //left={flyrMarl}

                >


                    <Message
                
                        backgroundColor={'transparent'}
                        border={"0px"}
                        maxHeight={"auto"}
                
                    >

                        <div>
                        {array.map((line,i) => (


                            <div
                            key={"line"+i}
                            
                            >
                            {
                            //------------------------------------
                            //success message
                            //------------------------------------


                                line.call === "heading" ? 


                                    <div 
                                    style={{

                                        backgroundColor     : "#fc0",
                                        width               : "100%",
                                        height              : "auto",
                                        margin              : "0 0 10px 0",
                                        padding             : "10px 0 10px 0",

                                        fontSize            : "16px",
                                        textAlign           : "center",
                                        color               : "#000"

                                    }}
                                    >
                                        {line.text}

                                    </div>
    

                                : line.call === "span" ? 

                
                                    <div 
                                    style={{

                                        backgroundColor     : "transparent",
                                        width               : "100%",
                                        height              : "auto",
                                        margin              : "5px 0 5px 0",
                                        padding             : "3px 0 3px 0",

                                        fontSize            : "14px",
                                        textAlign           : "center",
                                        color               : "#000"

                                    }}
                                    >
                                        {line.text}

                                    </div>

                        
                                : null



                            //--------------------------------------
                            }
                            </div>
                            


                        ))}
                        </div>

                        
                    </Message>





                    <Controls
                    ref={controlsRef}
                    id={'confirmControls'}

                    buttonBackgroundColor={buttonStyle.bgColor} 
                    //buttonWidth={buttonW}
                
                    //buttonMargin={"35px 0 0 "+buttonMarl}

                    buttonFontColor={buttonStyle.txtColor}
                    buttonBorder={"1px solid "+buttonStyle.brColor}

                    >

                        <div 
                        id={'confirmControls_YES'}    
                        className={"Button"}


                            onClick={() => handleConfirm({

                                call:"yes",
                                data:props.data

                            })}

                            onMouseEnter={() => handleOverOut({

                                id          :"confirmControls_YES",
                                call        :"over",
                                bgColor     :"orangered",
                                txtColor    :"#FFF",
                                brColor     :"orangered"
                            
                    
                            })}
                    
                            onMouseOut={() => handleOverOut({
                    
                                id          :"confirmControls_YES",
                                call        :"out",
                                bgColor     :"#0291cd",
                                txtColor    :"#FFF",
                                brColor     :"#0291cd"
                    
                    
                            })}


                        >

                            Yes

                        </div>




                        <div 
                        id={'confirmControls_NO'}    
                        className={"Button"}

                            onClick={() => handleConfirm({

                                call:"no"
                
                            })}

                            onMouseEnter={() => handleOverOut({

                                id          :"confirmControls_NO",
                                call        :"over",
                                bgColor     :"orangered",
                                txtColor    :"#FFF",
                                brColor     :"orangered"
                    
                            })}
                    
                            onMouseOut={() => handleOverOut({
                    
                                id          :"confirmControls_NO",
                                call        :"out",
                                bgColor     :"#0291cd",
                                txtColor    :"#FFF",
                                brColor     :"#0291cd"
                    
                    
                            })}

                        >

                            No

                        </div>



                    </Controls>

                    
                
                </Confirm>


                

            </Base>

        </div>



    );



}





export default Index;


