import { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    Canvas_DrawCheckeredBackground,
    //Canvas_ColorImage,
    //Canvas_GetElementPosition,
    //Canvas_GetEventLocation,
    //Canvas_RgbToHex,
    //Canvas_DrawImageFromWebUrl

} from './Funx';


import {

    Canvas_RenderImage

} from './Render';


import { BarChart, DoughnutChart, PieChart } from './Charts';


const Canv = (props) => {


    const [coordinates, setCoordinates] = useState({});
  
    useEffect(()=>{

        const canvasObj = props.canvasRef.current;
        const ctx = canvasObj.getContext('2d');


        if(props.drawCheckerBoard 
        && props.drawCheckerBoard !== undefined
        )
        {
            Canvas_DrawCheckeredBackground({

                canvas  : canvasObj,
                ctx     : ctx,
                w       : 10,
                h       : 10

            });
            
        }

        if(props.render 
        && props.render !== undefined
        )
        {
            Canvas_RenderImage({

                canvas          : canvasObj,
                ctx             : ctx,
                init            : true

            });
            
        }


        if(props.chart 
        && props.chart !== undefined
        )
        {

            switch(props.chart.call)
            {
            case'bar':


                var chartData;
                chartData = JSON.parse(JSON.stringify(props.chart.data));
                if(Object.prototype.toString.call(chartData.items) === '[object Array]')
                {
                    if(chartData.items.length > 0)
                    {
                        if(chartData.items[0].name !== 'hidden_max')
                        {
                            chartData.items.unshift({
                                name    :'hidden_max',
                                value   :props.chart.data.gridScaleMax
                            });
                            chartData.colors.unshift('transparent');
                        }
                    }
    
                }
                var barChart = new BarChart({

                    canvas          :canvasObj,
                    seriesName      :props.chart.data.name,
                    padding         :props.chart.data.barPadding,
                    gridScale       :props.chart.data.gridScaleIncr,
                    gridColor       :props.chart.data.gridColor,
                    data            :chartData.items,
                    colors          :chartData.colors
                    
                });
                barChart.draw();



            break;
            case'doughnut':

                var doughnutChart = new DoughnutChart({

                    canvas              :canvasObj,
                    data                :props.chart.data.items,
                    colors              :props.chart.data.colors,
                    holeSize            :0.6,
                    holeColor           :props.chart.data.hole.color,
                    holeOutlineColor    :props.chart.data.hole.outlineColor

                });
                doughnutChart.draw();

            break;
            case'pie':

            
                var pieChart = new PieChart({

                    canvas  :canvasObj,
                    data    :props.chart.data.items,
                    colors  :props.chart.data.colors

                });
                pieChart.draw();
            
            break;
            default:
            }//==
        }
  

    },[
        props,
        coordinates
    ]); 

    return [ setCoordinates ];

}



export default Canv;


