export const STATIC_BRAKES_REAR = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        


    <defs>
        <linearGradient id="BrakeRotor" x1="0%" y1="50%" x2="70%" y2="0%" gradientTransform="rotate(0)">
            <stop offset="0%" style={{"stopColor":"rgb(10,10,10)","stopOpacity":"1"}} />
            <stop offset="50%" style={{"stopColor":"rgb(100,100,100)","stopOpacity":"1"}} />
            <stop offset="100%" style={{"stopColor":"rgb(10,10,10)","stopOpacity":"1"}} />
        </linearGradient>
    </defs>


    

    {/*Brake Rotor Rear*/}
    <path 
        fill="url(#BrakeRotor)" 
        d="M176.251,279.676c20.348-2.006,38.579,12.955,40.585,33.303 c2.005,20.352-12.956,38.582-33.306,40.586c-20.349,2.008-38.581-12.957-40.585-33.305 C140.94,299.912,155.901,281.678,176.251,279.676L176.251,279.676z"
    />
    {/*Rear Brake Pad*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M195.624,295.609c-0.348-0.775-0.617-1.512-0.8-2.186 c-0.144-0.67,0.075-1.236,0.793-1.807l8.763-6.943l12.21,15.412l-8.763,6.939c-0.718,0.57-1.319,0.654-1.941,0.359 c-0.759-0.41-1.576-0.967-2.418-1.652c-0.845-1.832-1.916-3.592-3.222-5.24c-1.184-1.496-2.503-2.824-3.924-3.984L195.624,295.609 L195.624,295.609z"
    />
    {/*Caliper Middle*/}
    <path 
        fill="rgb(100,100,100)" 
        d="M209.495,293.162c2.009,2.535,1.357,5.945-0.86,8.053l-3.306,3.18 c-0.245,0.229-0.266,0.615-0.039,0.904l0.578,0.729c0.393,0.498,1.248,0.727,2.349,0.059l7.921-4.922l3.428-2.719 c-0.323-0.684-0.666-1.363-1.029-2.037c-0.422,0.068-0.986-0.209-1.399-0.732c-0.524-0.664-0.614-1.467-0.2-1.797 c0.027-0.023,0.055-0.041,0.085-0.057c-0.44-0.699-0.904-1.383-1.39-2.064l-1.8,0.445l-1.196-1.512l-1.212-1.531l0.842-1.652 c-0.551-0.625-1.113-1.234-1.691-1.82c-0.022,0.021-0.047,0.045-0.074,0.066c-0.414,0.33-1.18,0.057-1.703-0.602 c-0.414-0.523-0.557-1.139-0.395-1.535c-0.572-0.504-1.154-0.994-1.746-1.467l-3.431,2.717l-6.602,6.586 c-0.903,0.918-0.876,1.803-0.482,2.297l0.578,0.732c0.228,0.285,0.609,0.354,0.891,0.172l3.853-2.496
        C204.02,290.484,207.485,290.629,209.495,293.162L209.495,293.162z"
    />
    {/*Caliper Middle*/}
    <path 
        fill="rgb(80,80,80)" 
        d="M210.841,304.887c-4.151-5.236-8.301-10.475-12.451-15.713 c-1.282-1.619-0.455-2.275,0.837-3.297c0.524-0.416,2.467-1.955,3.552-2.814c1.821-1.441-3.155-5.051-3.954-4.42 c-1.554,1.232-2.994,2.375-4.149,3.287c-1.962,1.559-2.853,3.461-0.952,5.861c5.799,7.32,11.597,14.637,17.396,21.957 c1.9,2.396,3.96,1.967,5.922,0.41c1.155-0.914,2.596-2.055,4.149-3.287c0.799-0.633-1.575-6.301-3.397-4.859 c-1.084,0.861-3.027,2.398-3.551,2.814C212.95,305.848,212.123,306.504,210.841,304.887L210.841,304.887z"
    />


    

		</svg>
		</div>

	);//return
}