import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


import '../../StyleSheets/dynamic-form.css';



export const FFclicker = (props) => {


    const [fieldArr, setFieldArr] = useState([]);
    const [fieldStyl, setFieldStyl] = useState({});
    
    useEffect(() => {

        var call = props['data']['call'];
        //var clickername = props['data']['name'];
        var _array = props['data']['array'];
        var choice = props['data']['choice'];
    
        //var grid_id = clickername+"_grid";

        var clkArr = [];
        var found;
        var inner_color;
        // myText;
    
        for(var j=0; j < _array.length; j++)
        {
    
            var clickerval = _array[j];
    
            found = false;
    
            switch(call)
            {
            case'rad':

                //console.log(baselib.ObjectType(choice)+" === "+clickerval.toLowerCase());

                if(choice.length > 0)
                {
                    if(choice[0].toLowerCase() === clickerval.toLowerCase()
                    ) 
                    {
                        //console.log("::::::::::"+choice[0]+" "+clickerval);
                        found = true;
        
                    }//==

                }


            break;
            case'chk':
    
                for(var k=0; k < choice.length; k++)
                {

                    if(choice[k].toLowerCase() === clickerval.toLowerCase()
                    )
                    {
                        //console.log("::::::::::"+choice[k]+" "+clickerval);
                        found = true;
                       
                    }//==
    
                }//==
    
            break;
            default:
            break;
            }//===
    
            
            inner_color = 'white'
            if(found) inner_color = "orangered";
    

            //myText = stringlib.CapitalizeWords(clickerval);
    
            clkArr.push({
    
                "clickerVal"    :clickerval,
                "innerColor"    :inner_color,
                "myText"        :clickerval
    
            });
    
    
        }
    

        setFieldArr(clkArr);
        setFieldStyl({"bgColor":"transparent"});

        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.indx === problemArr[i]['key'])
            {
                setFieldStyl({"bgColor":"lightyellow"});
                break;
            }
    
        }
 

     },[props])








    // -- handle -- //


    function handleClick(indx) {



        /*

        console.log(JSON.stringify(props,null,2))


        {
        "indx": 8,
        "data": {

                "call": "rad",
                "required": true,
                "name": "typeofwork",
                "array": [
                    "Consulting",
                    "Full Stack",
                    "Frontend ",
                    "Backend",
                    "Not Sure"
                ],
                "choice": [
                    "Full Stack"
                ]
            }
        }

    
        alert("handleClick");

        */
   

   


        var newArr = [];
        var i;



        if(props.problemArr.length > 0)
        {
            props.handle({

                call        :'clear',
                fieldCall   :"clicker",
                key         :props.indx,
                data        :props.data,
                newValue    :newArr
            
            });

        }


        for(i=0; i < fieldArr.length; i++)
        {

            var object = fieldArr[i];


            switch(props.data.call)
            {
            case'chk':


      
                if(indx === i)
                {

                    var newColor = 'orangered';
                    if(object['innerColor'] === 'orangered') newColor = 'white';
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :newColor,
                        "myText"        :object['myText']
                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :object['innerColor'],
                        "myText"        :object['myText']

                    });

                }//==



            break;
            case'rad':


                if(indx === i)
                {
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :'orangered',
                        "myText"        :object['myText']


                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :"white",
                        "myText"        :object['myText']

                    });

                }//==



            break;
            default:
            break;
            }//==

    
        }//==
        
        setFieldArr(newArr);


        props.handle({

            call        :'capture',
            fieldCall   :"clicker",
            key         :props.indx,
            data        :props.data,
            newValue    :newArr

        })




    }









    // -- display -- //

    return (

        <div
        key={props.indx+"_ffclicker"} 
        id={props.data.name+"_grid"}      
        className="grid"
        style={{

            backgroundColor: fieldStyl.bgColor

        }}

        >

            {

            fieldArr.map((clkData, j) => (


                <div 
                key={j}
                className="clicker"
                id={props.data.name+"_grid_clicker"+j}
                >

                    <div 
                    className={props.data.call}
                    onClick={() => handleClick(j)}
                    >

                        <div 
                        className={props.data.call+"-inner"}
                        id={props.data.name+"_"+j}
                        style={{

                            backgroundColor : clkData.innerColor 
  
                        }}
                        >
                        </div>

                    </div>



                    <input
                        type="hidden" 
                        id={props.data.name+"_"+j+"_color"} 
                        name={props.data.name+"_"+j+"_color"}        
                        value={clkData.innerColor}
                        //onChange={e => handleChange(idx, e)}
                    />


                    <div 
                    className="click-txt"
                    onClick={() => handleClick(j)}
                    >
                        {clkData.myText}

                    </div>


                    <input
                        type="hidden"
                        id={props.data.name+"_"+j+"_txt"} 
                        name={props.data.name+"_"+j+"_txt"}        
                        value={clkData.myText}
                    />


                </div>


            ))

            }

    
        </div>

    )

}





