import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
//import useAddScript from 'utils/myHooks/AddScript';
// import { useFetch }  from 'utils/hooks/Fetch';
// import { useMatomo } from '@datapunt/matomo-tracker-react';


//import Controls from "./Controls";



import { Settings, Lorem, TestImages, NavigationData } from './Data';

//import Styl from './Styl.module.css';

import { 
    PrimeStage, 
    PrimeBar,
    PrimeGroup,
    PrimeMember

} from './Styled';




// - pages -- //

import {

    Stage,
    ScrollBox,
    //Area,
    //AreaTitle
    Tray

} from 'utils/components/Pages/Styled';

import DefaultFetchResponse from 'utils/components/Pages/Default/FetchResponse';
// import SceneInfo from 'utils/components/Pages/SceneInfo/Default/SceneInfo';

// import ApiResources from 'utils/components/Pages/ApiResources';



// -- commons -- //

import Header from "utils/components/Common/Header";
import HeaderBar from "utils/components/Common/HeaderBar";
import Menu from 'utils/components/Common/Menu';
// import Footer from "utils/components/Common/Footer";
// import InfoBar from "utils/components/Common/InfoBar";


import Login from 'utils/components/Login';

import Confirm from 'utils/components/Process/Confirm';
import Loader from 'utils/components/Process/Loader';
import Messngr from 'utils/components/Process/Messngr';
import Readr from 'utils/components/Process/Readr';
//import Fetch from 'utils/components//Process/Fetch';

import Flyr from 'utils/components/Flyr';
import FGridFlyr from 'utils/components/Grid/FGrid/Flyr';
import VidFlyr from 'utils/components/Grid/VidGrid/Flyr';



import {
    FetchAll, 
    Fetch, 
    Track
} from './Page';


//import Redirect from './Redirect';

import Handler from './Handler';

import Zone from "../Zone";



// -- library -- //

import { 
    LibArray,
    LibElem,
    //LibString
} from 'library';




// -- placeholder data -- //

import SVGpage from 'utils/components/Pages/SceneInfo/Trials/SVGpage/SceneInfo';
import SVGgridpage from 'utils/components/Pages/SceneInfo/Trials/SVGgridpage/SceneInfo';



import SIerrorPage from 'utils/components/Pages/SceneInfo/ErrorPage/SceneInfo';




const Prime = (props) => {



    //-------------------------------
    // add scripts
    //-------------------------------

    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    //-------------------------------
    // window dims
    //-------------------------------

    const { 
        width, 
        height 
    } = useWindowDimensions();

    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }




    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-------------------------------
    // redux
    //-------------------------------

    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    let currentZone = pathname;
    //currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    let zoneKey = currentZone + "_fetch";
    
    // -- Fetch -- // 
    // let fetchd = Fetch({

    //     key      : currentZone+"_fetch",
    //     route    : pathname

    // }); 





    //------------------------------------------------------------------------------

    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    const refHeader = useRef(null);
    const refScroll = useRef(null);
    const refZone = useRef(null);


    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    
    const [runFetchAll, setRunFetchAll] = useState(false);
    const [wasFetchAll, setWasFetchAll] = useState(false);

    const [runFetch, setRunFetch] = useState(false);
    const [fetchKey, setFetchKey] = useState(false);

    const [runTrack, setRunTrack] = useState(false);

 
    const [groupHeight] = useState("auto");
    const [barHInt, setBarHInt] = useState(0);
    const [primeDif, setPrimeDif] = useState(1);  

    const [blueprint, setBlueprint] = useState(false);

    const [hashData, setHashData] = useState(false);


    //---------------------------------------------
    // state/effect --- stage - scrollbox
    //---------------------------------------------

    const [headerCall, setHeaderCall] = useState(DefaultFetchResponse.settings.appearance.header.headerCall);//A,B,C
    //const [headerCall, setHeaderCall] = useState("A");//A,B,C

    const [scrollAtTop, setScrollAtTop] = useState(true);

    const [scrollboxDif, setScrollboxDif] = useState(50); 
    const [scrollboxOverflow, setScrollboxOverflow] = useState("scroll"); 

    const [offsetClientDif, setOffsetClientDif] = useState(15);
  
    const [viewWidth, setViewWidth] = useState(100);
    const [viewHeight, setViewHeight] = useState(100);
    //const [contentHeight, setContentHeight] = useState(height);

    const [headerSizes, setHeaderSizes] = useState(false);
    const [headerPlaceholder, setHeaderPlaceholder] = useState(true);
   
    const [headerBarTop, setHeaderBarTop] = useState(0);   

    // const [settings, setSettings] = useState(DefaultFetchResponse.settings);
    // const [sceneArr, setSceneArr] = useState(SceneInfo);

    const [settings, setSettings] = useState(false);
    const [sceneArr, setSceneArr] = useState([]);

    const [highZ, setHighZ] = useState(99);



    useEffect(() => {


        var dif;
        dif = 0;
        if(primeDif)
        {
            if(primeDif > 0) 
            {
                dif = primeDif + 0;
            
            }     

        }

        //alert(dif);
        setScrollboxDif(dif);

        var vh = height - dif;
        setViewHeight(vh);

        // offsetWidth includes width of scroll bar and clientWidth doesn't
        var vw = refScroll.current.clientWidth - 0 + "px";
        setViewWidth(vw);

        var ocDif = refScroll.current.offsetWidth - refScroll.current.clientWidth;
        setOffsetClientDif(ocDif);


        if(!isLoaded)
        {
            // alert(zoneKey);

            //console.log("isn't loaded");
            setIsLoaded(true);

            var highZindex = LibElem.HighestZ();
            setHighZ(highZindex);



            if(location.search.match(/\?ssg=yes/gi))
            { 
                console.log('running ssg');
            }else{

                if(localStorage.editMode)
                {
                    setEditMode(true);
                }

                if(!localStorage.editMode)
                {
                    if(!wasFetchAll)
                    {
                        //alert('fetchall');
                        setRunFetchAll(true);
                    }
                }

            }


        }//isLoaded



        var zoneKeys;
        zoneKeys = Object.keys(zoneObj);
        if(!LibArray.InArray(zoneKey,zoneKeys))
        {
            //console.log('missing key - '+zoneKey);
            if(!runFetch)
            { 
                //console.log("isFetching...");
                setRunFetch(true); 

            }

        }else{


            //alert('has key - '+zoneKey);
            if(fetchKey !== zoneKey)
            {
                if(!runFetch) 
                {
                    //alert('!runFetch')
                    setRunFetch(true);
                }
            }

        
        }

        if(headerCall === 'C')
        {
            let siOne;
            if(sceneArr.length > 0 && !runFetch)
            {
                siOne = sceneArr[0];
                switch(siOne.call.toLowerCase())
                {
                case'billboard':

                    if(headerPlaceholder)
                    {
                        //alert("is ---- "+siOne.call);
                        setHeaderPlaceholder(false);
                    }

                break;
                default:

                    if(!headerPlaceholder)
                    {
                        //alert("is ---- "+siOne.call);
                        setHeaderPlaceholder(true);
                    }
                }

                //console.log('headerCall C')
            }else{

                setHeaderPlaceholder(true);
            }

        }


    },
    [


        props,

        width,
        height,

        location,
        
        //dispatch,
        zoneObj,
        zoneKey,

        //pathname,
        //currentZone, 

        wasFetchAll,
        
        runFetch,fetchKey,
        runTrack,
        
        isLoaded,
        primeDif,

        refScroll,
        //refHeader,

        headerCall,
        headerPlaceholder,
        //scrollAtTop,

        // settings,
        sceneArr

    ])







    //--------------------------------------------
    // state/effect --- show - data
    //--------------------------------------------

    const [showConfirm, setShowConfirm] = useState(false); 
    const [confirmData, setConfirmData] = useState(false);

    const [showLoader, setShowLoader] = useState(false); 
    const [loaderData, setLoaderData] = useState(false);

    const [showLogin, setShowLogin] = useState(false); 
    const [loginData, setLoginData] = useState(false);

    const [showMenu, setShowMenu] = useState(false); 
    const [menuData, setMenuData] = useState(false);

    const [showMessngr, setShowMessngr] = useState(false); 
    const [messngrData, setMessngrData] = useState(false);

    const [showReadr, setShowReadr] = useState(false); 
    const [readrData, setReadrData] = useState(false);

    const [showFlyr, setShowFlyr] = useState(false); 
    const [flyrData, setFlyrData] = useState(false);

    const [showFGridFlyr, setShowFGridFlyr] = useState(false); 
    const [fgridFlyrData, setFGridFlyrData] = useState(false);

    const [showVidFlyr, setShowVidFlyr] = useState(false); 
    const [vidFlyrData, setVidFlyrData] = useState(false);



    useEffect(() => {


        if(isLoaded)
        {
 
            if(barHInt === 0 && refB.current.offsetHeight > 0) 
            {
                setBarHInt(refB.current.offsetHeight);
            }

            var revPrimeDif = 1;
            revPrimeDif = refA.current.offsetHeight + refB.current.offsetHeight;
            if(revPrimeDif === 0) revPrimeDif = 1;
            if(primeDif === 1 && revPrimeDif > 1)
            {
                setPrimeDif(revPrimeDif);  

            }


            if(hashData && location.hash === "")
            {
                if(hashData.match(/#!/gi)
                )
                {
                    console.log("Hash Check");

                    setHashData(false);
        
                    setShowConfirm(false);
                    setShowLoader(false);  
                    setShowLogin(false);
                    setShowMessngr(false); 
                    setShowMenu(false); 
                    setShowReadr(false); 
                    setShowFlyr(false);          
                    setShowFGridFlyr(false); 
                    setShowVidFlyr(false); 
        
                } 

            } 




    
            if(!zoneObj['basename'] || zoneObj['basename'] === undefined 
            )
            {
                if(zoneObj['basename'] !== props.basename)
                {

                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",
                        item:{
                            key:"basename",
                            value:props.basename
                        }

                    })); 

                }

            }
            //console.log('zone basename - '+zoneObj['basename']);




            // if(!zoneObj['pathname'] 
            // || zoneObj['pathname'] === undefined 
            // )
            // {
            //     console.log('pathname: '+pathname);

            //     dispatch(ModifyZoneStore({

            //         type:"UPDATE_ZONE",
            //         item:{
            //             key:"pathname",
            //             value:pathname
            //         }

            //     })); 
                
            // }



            if(!zoneObj['primeDif'] 
            || zoneObj['primeDif'] === undefined 
            )
            {
        

                //console.log('DISPATCH PRIME DIF: '+revPrimeDif);


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"primeDif",
                        value:revPrimeDif
                    }

                })); 
                
            }


            if(!zoneObj['lorem'] || zoneObj['lorem'] === undefined 
            )
            {

                //console.log("lorem - "+Lorem);

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"lorem",
                        value:Lorem
                    }

                })); 
                
            }




            if(!zoneObj['images'] || zoneObj['images'] === undefined 
            )
            {
            
                //console.log("images - "+TestImages);

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"images",
                        value:TestImages
                    }

                })); 
                
            }



            if(!zoneObj['navigation'] || zoneObj['navigation'] === undefined 
            )
            {
        
               
                //console.log("ssg - "+NavigationData);

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key:"navigation",
                        value:NavigationData
                    }

                })); 
                
            }



            if(Settings['ssg'])
            {

                if(!zoneObj['ssg'] || zoneObj['ssg'] === undefined 
                )
                {
        
                    //console.log("ssg - "+Settings['ssg']);

                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",
                        item:{
                            key:"ssg",
                            value:Settings['ssg']
                        }

                    })); 
                    
                }

            }


        }//isLoaded




    },[

        dispatch,
        props,
        height,      
        pathname,
        zoneObj,
        location,
        hashData,
        isLoaded,
        refA,
        refB,

        barHInt,
        primeDif

    ])












    //------------------------------------------
    // state/effect - view zoneObj
    //------------------------------------------


    // -- state -- //
    const [zoneWasViewed, setZoneWasViewed] = useState(false);

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(isLoaded && !zoneWasViewed)
        {
            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            // console.log("===== zoneObj =====");
            // console.log(JSON.stringify(zoneObj,null,2));


            setZoneWasViewed(true);
    
        }else{

            //Delay before starting animation
            // var timer;
            // timer = setTimeout(() => {

            //     setZoneWasViewed(false);

            // }, 500);
            // return () => clearTimeout(timer);

        } 

    },
    [
        props,
        isLoaded,
        zoneObj,
        zoneWasViewed

    ])










    //-------------------------------
    // state/effect - redirect
    //-------------------------------

    // useEffect(() => {


    //     var doit;
    //     doit = false;

    //     //alert(currentZone);
    //     if(currentZone !== 'ErrorPage' && currentZone !== 'X')
    //     {
    //         if(props.basename !== '/source/')
    //         {
    //             if(isLoaded && !runFetch)
    //             {
    //                 if(!zoneObj['settings'] || zoneObj['settings'] === undefined)
    //                 {
    //                     if(location.search === '')
    //                     {
    //                         if(sceneArr.length === 0 && !doRedirect)
    //                         {
    //                             doit = true;
    //                         }
        
    //                     }
    //                 }
    //             }
    //         }

    //     }


    //     if(doit) setDoRedirect(true);
    //     //console.log(JSON.stringify("PRIME ---- sceneArr: "+sceneArr,null,2));


    // },
    // [
    //     props,
    //     location,
    //     pathname,
    //     isLoaded,
    //     sceneArr,
    //     doRedirect,
    //     currentZone
    // ])








    //-------------------------------
    // state/effect - hashScroll
    //-------------------------------

    const [currentHash, setCurrentHash] = useState(0);
    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);


    useEffect(() => {


        // -- effect -- //
 
        const effectHashScroll = (eff) => {


            // console.log("Content: eff "+JSON.stringify(eff,null,2));

            var newHash = eff.newHash;
            var adjustForHeader = eff.adjustForHeader;
            var headerHInt = eff.headerHInt;
            // console.log(""

            //     +"\nadjustForHeader: "+adjustForHeader
            //     +"\nheaderHInt: "+headerHInt
  
            // )


            var pos;
            var scrollbox;
            var row;

            var rowNum; 
            rowNum = newHash.split("#row")[1];
            
            if(document.getElementById("row_"+rowNum)
            && document.getElementById("row_"+rowNum) !== undefined
            )
            {
                scrollbox = document.getElementById("scrollbox");
                row = document.getElementById("row_"+rowNum);  
                
                pos = row.offsetTop;
                if(adjustForHeader) pos = pos - parseInt(headerHInt);


                console.log(""

                    +"\nscrollbox.scrollHeight: "+parseInt(scrollbox.scrollHeight)


                    +"\nrow.offsestHeight: "+parseInt(row.offsetHeight)
                    +"\nrow.offsetTop: "+parseInt(row.offsetTop)

                    +"\nscrollTop: "+scrollbox.scrollTop
                    // +"\nscrollMax: "+scrollMax
                    // +"\nscrollDif: "+scrollDif 
                    
                    +"\npos: "+pos
                    
                );



                // alert(""
                //     +"\nROW SCROLL H: "+parseInt(scrollbox.scrollHeight)
                //     +"\nROW OFFSET H: "+parseInt(row.offsetHeight)

                //     +"\nscrollTop: "+scrollbox.scrollTop
                //     +"\nscrollMax: "+scrollMax
                //     +"\nscrollDif: "+scrollDif

                //     +"\npos: "+pos
                    
                // );
        

                if(row !== undefined)
                {
                    setCurrentHash(newHash)
                    setCurrentScrollPosition(pos);
                    scrollbox.scrollTo({
                        top: pos, 
                        left: 0, 
                        behavior: 'smooth' 
                    });

                }

            }


        }






        // -- adjust for header -- //

        var adjustForHeader = false;
        var headerHInt = 75;
        if(settings)
        {

            // console.log(""
            //     +"\nheaderCall: "+headerCall
            //     +"\nsceneArr.length"+sceneArr.length
            //     +"\nheaderHeight: "+settings.appearance.header.headerMainHeight
            // )

            if(headerCall === 'C')
            {
                if(sceneArr.length > 0)
                {

                    //console.log("CALL: "+sceneArr[0].call);

                    if(sceneArr[0].call.toLowerCase() === 'billboard')
                    {
                        adjustForHeader = true;
                        headerHInt = settings.appearance.header.headerMainHeight;
                        if(headerHInt.match(/px/gi)) 
                            headerHInt = headerHInt.replace(/px/gi,"");

                    }

                }
            }

        }





        // -- cause -- //

        //console.log('location: '+JSON.stringify(location,null,2));

        var causeHashScroll = false;
        if(contentHeight > 0)
        {
            if(location.hash && location.hash !== undefined)
            {
                if(currentHash !== location.hash)
                {
                    if(location.hash.match(/#row/gi))
                    {
                        causeHashScroll = true;

                    } 
                } 
                else
                if(currentScrollPosition !== document.getElementById("scrollbox").scrollTop)
                {
                    if(location.hash.match(/#row/gi))
                    {
                        causeHashScroll = true;

                    } 
                }  

            }      
        }
        //console.log("causeHashScroll: "+causeHashScroll);
        if(causeHashScroll)
        {
            effectHashScroll({

                newHash:location.hash,
                adjustForHeader:adjustForHeader,
                headerHInt:headerHInt

            });
        
        }


    },
    [
        location,
        contentHeight,
        currentHash,
        currentScrollPosition,

        settings,
        headerCall,
        sceneArr

    ])








    //=============================================
    // handles
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'FetchAll':

            //console.log(JSON.stringify(hndl.data,null,2));
            //alert("FetchAll");


            // VERSION 1

            // if(!localStorage.editMode)
            // {
            //     var object;
            //     var key,value;
            //     for(object of hndl.data.scene)
            //     {
            //         key = object.currentScene.sceneRoute+'_fetch';
            //         value = object;

            //         if(key !== currentZone+"_fetch")
            //         {
            //             dispatch(ModifyZoneStore({

            //                 type:"UPDATE_ZONE",
            //                 item:{
            //                     key:key,
            //                     value:value
            //                 }

            //             })); 

            //         }//if

            //     }//for 

            // }//==



            // REVISED VERSION

            if(!localStorage.editMode)
            {
                var object;
                var scn,key,value;
                for(object of hndl.data.scene)
                {
                    scn = object.currentScene;
                    key = false;
                    value = object;

                    //alert("currentZone: "+currentZone);
                    // alert('currentZone: '+currentZone
                    // +"\nscn.routeName: "+scn.routeName
                    // +"\nscn.compName: "+scn.compName
                    // +"\nscn.sceneRoute: "+scn.sceneRoute
                    // +"\nscn.sceneName: "+scn.sceneName
                    // );
                    
                    if(scn.routeName+"_fetch" !== currentZone+"_fetch"
                    && scn.compName+"_fetch" !== currentZone+"_fetch"
                    && scn.sceneRoute+"_fetch" !== currentZone+"_fetch"
                    && scn.routeName+"/"+scn.sceneCompName+"_fetch" !== currentZone+"_fetch"
                    && scn.compName+"/"+scn.sceneCompName+"_fetch" !== currentZone+"_fetch"
                    && scn.routeName+"/"+scn.sceneName+"_fetch" !== currentZone+"_fetch"
                    )
                    {
                        key = scn.compName+'_fetch';
                        if(scn.sceneRoute.match(/\//gi))
                        {
                            key = scn.compName+"/"+scn.sceneCompName+"_fetch";
                        }
                        //alert("key: "+key);

                        dispatch(ModifyZoneStore({

                            type:"UPDATE_ZONE",
                            item:{
                                key:key,
                                value:value
                            }

                        }));


                    }//if

                }//for 

            }//==





            setRunFetchAll(false);
            setWasFetchAll(true);

            console.log("FetchAll compplete");

        break;
        case'Redirect':

            //alert("handle - Redirect");

            setFetchKey(zoneKey);
            setRunFetch(false);

            let redirectUrl;
            if(hndl.data.tree === undefined)
                redirectUrl = "/ErrorPage?message=No Connection No Settings";
            else
                redirectUrl = "/X/?tree="+hndl.data.tree+"-scene-"+hndl.data.route;

            history.replace(redirectUrl);

        break;
        case'Fetch':

            // console.log("handle - Fetch");
            // console.log(JSON.stringify(hndl.data,null,2));
            // alert("handle - Fetch");

            var resp;
            var si;
            var sett;
            //var options;
            var hdrCall        
            var hdrBarOut;


            //alert("Prime Index - Current Zone ("+currentZone+")");



            // -- sceneArr -- //

            if(currentZone.match(/errorpage/gi))
            {
                //alert("Page ("+currentZone+")");
                si = SIerrorPage;
                //si.items[0].pgraph = "ERROR: No Connection/Settings";

            }
            else
            if(currentZone.length === 1 && currentZone.match(/x/g))
            {
                //alert("Page ("+currentZone+")");
                si = SIerrorPage;
                //si.items[0].pgraph = "Try to retrieve page based on tree and route";

            }
            if(currentZone.match(/cars/gi))
            {
                //alert("Add placeholder for ("+currentZone+") here");
                si = SVGgridpage;

            }
            else
            if(currentZone.match(/configurator/gi))
            {
                //alert("Add placeholder for ("+currentZone+") here");
                si = SVGpage;

            }
            else
            {

                //alert(props.basename);

                if(props.basename !== '/source/')
                {

                    resp = zoneObj[zoneKey];
                    // console.log("HAS: "+resp);
                    // alert("HAS ZONE FETCH::: "+Object.prototype.toString.call(resp));

                    //console.log(JSON.stringify(resp,null,2));
                    //alert(resp.name+"/"+resp.sceneName + " === " + pathname);


                    var hassi;
                    hassi = false;
   
                    // if(currentZone === 'ErrorPage' || currentZone === 'X')
                    // {
                    //     hassi = true;
                    // }
                    // else
                    if(resp && resp !== undefined)
                    {
                        if(resp.sceneInfo && resp.sceneInfo !== undefined)
                        {

                            hassi = true;

                        }
                    }
                    if(!hassi)
                    {
                        //setDoRedirect(true);
                        //return false;

                    }else{


                        var isMulti = false;
                        if(resp.currentScene.routeName+"/"+resp.currentScene.sceneCompName === pathname)
                        {//Mixed

                            isMulti = true;
                        }
                        else
                        if(resp.currentScene.compName+"/"+resp.currentScene.sceneCompName === pathname)
                        {//No Spaces

                            isMulti = true;
                        }
                        else
                        if(resp.currentScene.routeName+"/"+resp.currentScene.sceneName === pathname)
                        {//Both Spaces
                            
                            isMulti = true;
           
                        }
                        if(isMulti
                        )
                        {//msz

                            si = JSON.parse(JSON.stringify(resp.sceneInfo));//avoids the react-redux mutation error
                        
                        }else{

                            if(resp.sceneName === pathname 
                            || resp.sceneName === 'Home'
                            || pathname === ''
                            || pathname === 'SinglePage'
                            || pathname === resp.currentScene.compName
                            )
                            {
                                si = JSON.parse(JSON.stringify(resp.sceneInfo));//avoids the react-redux mutation error
                            
                            }

                        } 


                    }


                }else{


                    si = zoneObj[zoneKey];
                    si = JSON.parse(JSON.stringify(si));//avoids the react-redux mutation error

                }
                
 
            }
            setSceneArr(si);




            // -- settings -- //

            if(!settings && zoneObj["settings"])
            {
                resp = zoneObj["settings"];
                //console.log("HAS: "+resp);
                //alert(Object.prototype.toString.call(resp));
                if(resp)
                {
                    sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
                    //setSettings(sett);
                }
                setSettings(sett);
                if(sett.appearance.header.headerCall)
                {
                    hdrCall = sett.appearance.header.headerCall;
                    setHeaderCall(hdrCall);

                    switch(hdrCall)
                    {
                    case'A':
            
                        hdrBarOut = "0px";
                    break;
                    case'B':
            
                        hdrBarOut = "0px";
                    break;
                    case'C':
            
                        hdrBarOut = "0px";
                    break;
                    default:
                        hdrBarOut = "0px";
                    }
            
                    if(!scrollAtTop) setHeaderBarTop("0px");
                    else setHeaderBarTop(hdrBarOut);

                }

                if(sett.isAdmin)
                {
                    setIsAdmin(true);
                }
                // else{
                //     alert(sett.ipAddress);
                // }

            }

            // if(zoneObj["redirectOptions"])
            // {
            //     options = zoneObj['redirectOptions'];
            //     setRedirectOptions(options);

            // }
            setFetchKey(zoneKey);
            setRunFetch(false);
        break;
        case'Track':

            console.log("This path ("+hndl.data+") was tracked");
            alert("handle - Track");

            setRunTrack(false);
        break;
        case'headerSizes':

            setHeaderSizes(hndl.data);
            //setHeaderW(hndl.data.groupW);
            //setHeaderH(hndl.data.groupH);

        break;
        case'contentHeight':

            // console.log("contentHeight Check"
            
            //     +"\nScroll OffetHeight: "+refScroll.current.offsetHeight
            //     +"\nScroll ScrollHeight: "+refScroll.current.scrollHeight
            //     +"\ncontentHeight: "+hndl.data.contentHeight
            //     +"\nArea OffetHeight: "+refArea.current.offsetHeight
            // );

            //reset viewWidth 
            var vw = refScroll.current.clientWidth + "px";// offsetWidth includes width of scroll bar and clientWidth doesn't
            //console.log("handleScroll - VW: "+vw);
            setViewWidth(vw);

            if(hndl.data.contentHeight > 0)
            {
                if(hndl.data.contentHeight < refScroll.current.offsetHeight
                || hndl.data.contentHeight === refScroll.current.offsetHeight
                )
                {
                    //setScrollboxOverflow("auto");
                    setScrollboxOverflow("scroll");
                }else{
                    setScrollboxOverflow("scroll");
                }

            }//==


            if(hndl.data.contentHeight !== contentHeight)
            {
                setContentHeight(hndl.data.contentHeight);
            }



        break;
        case'scrollup':
        case'scrolldown':


            var pos;
            var scrollbox;
            var row;
            var dif = 0;
            var backToZero = false;

            var scrollMax;
            var scrollDif;



            console.log("HNDL: "+JSON.stringify(hndl,null,2));

            scrollbox = document.getElementById("scrollbox");
            row = document.getElementById(hndl.refid);


            switch(hndl.call)
            {
            case'scrollup':

                pos = row.offsetTop - hndl.scrollTop - dif;
                if(scrollbox.scrollTop === 0)
                {
                    pos = scrollbox.scrollHeight;
                }

            break;
            default:



                scrollMax = (parseInt(scrollbox.scrollHeight) - parseInt(row.offsetHeight));
                scrollDif = scrollMax - scrollbox.scrollTop;

                // alert(""
                //     +"\nROW SCROLL H: "+parseInt(scrollbox.scrollHeight)
                //     +"\nROW OFFSET H: "+parseInt(row.offsetHeight)

                //     +"\nscrollTop: "+scrollbox.scrollTop
                //     +"\nscrollMax: "+scrollMax
                //     +"\nscrollDif: "+scrollDif
                    
                // );

                pos = row.offsetTop + hndl.scrollTop + dif; 
                
                if(scrollbox.scrollTop === scrollMax)
                {
                    backToZero = true;
                }
                if(scrollbox.scrollTop < scrollMax
                && scrollDif < parseInt(row.offsetHeight)
                )
                {
                    backToZero = true; 
                }


                if(backToZero)
                {
                    pos = 0;
                }
    
            }


            //scrollbox.style.scrollBehavior = 'smooth'; //CSS ; scroll-behavior: smooth;
            //scrollbox.scrollTop = hndl.scrollTop;
            scrollbox.scrollTo({
                top: pos, 
                left: 0, 
                behavior: 'smooth' 
            });
            //refA.current.scrollIntoView({behavior: 'smooth'});

            /*
            if(!'scrollBehavior' in document.documentElement.style) 
            {
                //var scrollup_onclick = "javascript:elemlib.ScrollToTop('scrollbox','no','0');";
            }else{
                //var scrollup_onclick = "javascript:elemlib.ScrollToTop('scrollbox','supported','0');";
            }//==
            */




        break;
        case'HashLink':


            //alert("HashLink..."+JSON.stringify(hndl,null,2));

            var elemId = hndl.data.replace(/#/gi,"");
            //alert(elemId);
            var elem = document.getElementById(elemId);
            if(elem !== null)
            {
                //alert("handle Page - "+hndl.data+" "+elem.offsetTop);
                //history.push(hndl.data);
                //window.location.href = hndl.data;
                if(elemId !== "HashList") 
                {
                    history.push(hndl.data);
                    refScroll.current.scrollTop = elem.offsetTop - 10;
                }else{
                    history.push(pathname);
                    refScroll.current.scrollTop = elem.offsetTop - 10;
                }
            }

        break;
        default:



            hndl['zoneObj'] = zoneObj;
            hndl['location'] = location;
            hndl['pathname'] = pathname;
            hndl['handleState'] = (v) => {


                if(v.call === 'handleDispatch') handleDispatch(v.value);
                if(v.call === 'handleBlueprint') handleBlueprint(v.value);
                if(v.call === 'handleUrl') handleUrl(v.value);

                if(v.call === 'hashData') setHashData(v.value);

                if(v.call === 'showConfirm') setShowConfirm(v.value);
                if(v.call === 'confirmData') setConfirmData(v.value);

                if(v.call === 'showLoader') setShowLoader(v.value);
                if(v.call === 'loaderData') setLoaderData(v.value);

                if(v.call === 'showLogin') setShowLogin(v.value);
                if(v.call === 'loginData') setLoginData(v.value);

                if(v.call === 'showMenu') setShowMenu(v.value);
                if(v.call === 'menuData') setMenuData(v.value);

                if(v.call === 'showMessngr') setShowMessngr(v.value);
                if(v.call === 'messngrData') setMessngrData(v.value);

                if(v.call === 'showReadr') setShowReadr(v.value);
                if(v.call === 'readrData') setReadrData(v.value);

                if(v.call === 'showFlyr') setShowFlyr(v.value);
                if(v.call === 'flyrData') setFlyrData(v.value);
            
                if(v.call === 'showFGridFlyr') setShowFGridFlyr(v.value);
                if(v.call === 'fgridFlyrData') setFGridFlyrData(v.value);
            
                if(v.call === 'showVidFlyr') setShowVidFlyr(v.value);
                if(v.call === 'vidFlyrData') setVidFlyrData(v.value);



            
            };
            hndl['handleRoute'] = (v) => {

                
                switch(v.call)
                {
                case'goBack':
        
                    history.goBack();
                break;
                case'reload':
        
                    window.location.reload();//regular dom 
                break;
                case'redirect':
        
                    history.replace(v.value);
                break;
                case'push':

                    history.push(v.value);
                break;
                default:
                }
        
            }

            Handler(hndl);



        }//switch




    }



    const handleBlueprint = (hndl) => {


       
        setBlueprint(hndl);

        var revPrimeDif = 1;
        revPrimeDif = refA.current.offsetHeight + refB.current.offsetHeight;
        if(revPrimeDif === 0) revPrimeDif = 1;

        alert(revPrimeDif);
        setPrimeDif(revPrimeDif);  


 
    }

    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')
    
        //alert(JSON.stringify(hndl,null,2));

        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }

    const handleUrl = (hndl) => {

        switch(hndl.action)
        {
        case'reload':

            window.location.reload();//regular dom 
        break;
        case'replace':

            window.location.replace(hndl.url);//regular dom 
        break;
        case'target':

            // window.location.replace(hndl.url);//regular dom 
        break;
        case'redirect':
    
            history.replace(hndl.url);
        break;
        case'push':

            history.push(hndl.url);
        break;
        default:
        }

    }



    const handleScroll = () => {


        //console.log("handleScroll... ");

        if(headerSizes)
        {
            var scrollDif;
        
            switch(headerCall)
            {
            case'A':
    
                scrollDif = 0;
                //scrollDif = headerSizes.navH;
            break;
            case'B':

                scrollDif = 0;
            break;
            case'C':

                scrollDif = 0;
            break;
            default:
                scrollDif = 10;
            }



            // console.log("Scroll Stuff"
                    
            //     +"\nscrollDif: "+scrollDif
            //     +"\nscrollTop: "+refScroll.current.scrollTop
            //     +"\neaderSizes: "+JSON.stringify(headerSizes,null,2)
                
            // );



            if(refScroll.current.scrollTop > (scrollDif))
            {
                if(scrollAtTop)
                {
                    
                    // console.log("Show Mini Header"
                    
                    //     +"\nScroll: "+refScroll.current.offsetTop
                    //     +"\nheaderSizes: "+JSON.stringify(headerSizes,null,2)
                        
                    // );

                    //make sure viewWidth is set to clientWidth
                    var vw = refScroll.current.clientWidth + "px";// offsetWidth includes width of scroll bar and clientWidth doesn't
                    //console.log("handleScroll - VW: "+vw);
                    setViewWidth(vw);

                    setScrollAtTop(false);
                }





            }else{

                // console.log("No Mini Header"

                //     +"\nScroll: "+refScroll.current.offsetTop
                //     +"\nheaderHeight: "+headerHeight
                
                // );

                setScrollAtTop(true);

            }


        }


    }




    const handleEditMode = (hndl) => {


        if(editMode === 'edit')
        {
            localStorage.removeItem('editMode');
            setEditMode(false);
        }
        else
        {
            localStorage.setItem("editMode",true);
            setEditMode("edit");
        }


 
    };








    return (

        <PrimeStage>


            {/* {
            doRedirect ? 

                <Redirect
                    
                    basename={props.basename}
                    handle={(v) => handle(v)}    
                    //data={runFetch}     
        
                /> 

            : null
            } */}




            {
            runFetchAll ? 


                <div>

                    <div style={{

                        backgroundColor:"#c00",
                        width:"100%",            
                        height:"3px",
                        margin:"0 0 0 0",
                        padding:"0 0 0 0",
                        
                        position:"absolute",
                        zIndex:"505",
                        bottom:"0px",
                        right:"0px",
     
                        textAlign:"center"

                    }}>
                        
                    </div>


                    <FetchAll
                        
                        basename={props.basename}
                        handle={(v) => handle(v)}    
                        //data={runFetch} 
                        editMode={editMode}    
            
                    />    

                </div>


            : null
            }



            {
            runFetch ? 

                <Fetch
                    
                    basename={props.basename}
                    handle={(v) => handle(v)}    
                    //data={runFetch}  
                    editMode={editMode}   
        
                /> 

            : null
            }


            {
            runTrack ? 

                <Track
                    
                    basename={props.basename}
                    handle={(v) => handle(v)}    
                    data={runTrack}     
        
                /> 

            : null
            }



            {
            showMenu ? 

                <Menu
                    
                    handle={(v) => handle(v)}    
                    data={menuData}     
        
                /> 

            : null
            }


            {
            showConfirm ? 

                <Confirm
                    
                    handle={(v) => handle(v)}    
                    data={confirmData}     
        
                /> 

            : null
            }

            {
            showLoader ? 

                <Loader
                    
                    handle={(v) => handle(v)}    
                    data={loaderData}     
        
                /> 

            : null
            }


            {
            showLogin ? 

                <Login
                    
                    handle={(v) => handle(v)}    
                    data={loginData}     
        
                /> 

            : null
            }



            {
            showMessngr ? 

                <Messngr
                    
                    handle={(v) => handle(v)}    
                    data={messngrData}     
        
                /> 

            : null
            }


            {
            showReadr ? 

                <Readr
                    
                    handle={(v) => handle(v)}    
                    data={readrData}     
        
                /> 

            : null
            }



            {
            showFlyr ? 

                <Flyr

                    
                    handle={(v) => handle(v)}    
                    data={flyrData}     
        
                /> 

            : null
            }

            {
            showFGridFlyr ? 

                <FGridFlyr

                    handle={(v) => handle(v)}    
                    data={fgridFlyrData}     
        
                /> 

            : null
            }

            {
            showVidFlyr ? 

                <VidFlyr

                    
                    handle={(v) => handle(v)}    
                    data={vidFlyrData}     
        
                /> 

            : null
            }




            <PrimeGroup
            height={groupHeight}
            >
                <PrimeMember ref={refA} >

                    {/* <Header />  */}

                </PrimeMember>

                <PrimeMember ref={refZone}>


                    <Stage>

                        <ScrollBox
                        ref={refScroll}
                        id={"scrollbox"}
                        height={"calc(100% - "+(scrollboxDif)+"px)"}
                        overflow={scrollboxOverflow}
                        onScroll={handleScroll}
                        >

                            <HeaderBar

                                databind={{

                                    handle          :(v) => handle(v),
                                    primeDif        :primeDif,
                                    blueprint       :blueprint,

                                    headerCall      :headerCall,               
                                    scrollAtTop     :scrollAtTop, 
                                    width           :viewWidth,
                                    top             :headerBarTop,
                                    headerSizes     :headerSizes,
                                    viewHeight      :viewHeight,

                                    offsetClientDif :offsetClientDif,

                                    placeholder     :headerPlaceholder,
                        
                                    settings        :settings,
                                    //sceneArr        :sceneArr

                                }}


                            />

                            <div
                            ref={refHeader}
                            id={'hdr'}
                            style={{
                                backgroundColor:"darkblue",
                                width:"100%",            
                                //height:headerHeight,
                                margin:"0 0 0 0",
                                padding:"0 0 0 0",         
                                //position:"relative",
                                //floar:"left",

                            }}
                            >
                                <Header 

                                    databind={{

                                        handle          :(v) => handle(v),
                                        primeDif        :primeDif,
                                        blueprint       :blueprint,
    
                                        headerCall      :headerCall,               
                                        scrollAtTop     :scrollAtTop, 
                                        width           :viewWidth,
                                        top             :headerBarTop,
                                        headerSizes     :headerSizes,
                                        viewHeight      :viewHeight,
    
                                        offsetClientDif :offsetClientDif,
    
                                        placeholder     :headerPlaceholder,
                            
                                        settings        :settings,
                                        //sceneArr        :sceneArr
    
    
                                    }}


                                />

                            </div>



                            {/* <Zone
                            
                                handle = {(v) => handle(v)} 
                                //headerRef={refA}
                                //bottomRef={refB}
                                primeDif={primeDif}
                                blueprint={blueprint}

                            />  */}


                            {
                            runFetch
                            ?

                                <Loader 
                                    call={"pageLoader"}
                                />

                            :

                                <Zone
                                
                                    databind={{

                                        handle          :(v) => handle(v),
                                        primeDif        :primeDif,
                                        blueprint       :blueprint,

                                        headerCall      :headerCall,               
                                        scrollAtTop     :scrollAtTop, 
                                        width           :viewWidth,
                                        top             :headerBarTop,
                                        headerSizes     :headerSizes,
                                        viewHeight      :viewHeight,

                                        offsetClientDif :offsetClientDif,

                                        placeholder     :headerPlaceholder,
                            
                                        settings        :settings,
                                        sceneArr        :sceneArr


                                    }}

                                /> 

                            }



                        </ScrollBox>





                        {
                        isAdmin ?

                            <Tray
                            zIndex={highZ}
                            >

                                {/* <div className={"Button"}
                                onClick={(v) => handleMatomoEvent({

                                    call:"trackThis"

                                })}
                                >
                                    Click To Track
                                
                                </div> */}

                                <div className={"Button"}
                                onClick={(v) => handleEditMode({

                                    call:"change"

                                })}
                                >
                                    {
                                    editMode ?

                                        "MODE: Edit"
                                    :
                                        "MODE: Read"
                                    }
                                
                                </div>

                            </Tray>

                        :null
                        }



                    </Stage>


                </PrimeMember>


                <PrimeMember ref={refB} >

                    <PrimeBar
                    // backgroundColor={"red"}
                    // width={"100%"}
                    // height={"50px"}
                    top={"calc(100% - "+barHInt+"px)"}
                    left={"0px"}
                    zIndex={"401"}
                    >
            
                        {/* 
                        <InfoBar
                            
                            navigation={NavigationData.controls}
                        />       
                        */}
                        
                    </PrimeBar>


                </PrimeMember>

            </PrimeGroup>


        </PrimeStage>



    );



}

export default Prime
