import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';

//import { useSelector, useDispatch } from 'react-redux';

//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



// import StylColumn from './StylModules/Column.module.css';
// import CreateMarkup from './CreateMarkup';

import HashList from './Comps/HashList.js';
import HashSection from './Comps/HashSection.js';

import AllText from './Comps/AllText.js';
import TextBox from './Comps/TextBox.js';
import SingleTextBox from './Comps/SingleTextBox.js';
import ImageTextBox from './Comps/ImageTextBox.js';
import Newspaper from './Comps/Newspaper.js';
import ImageWrapText from './Comps/ImageWrapText.js';
import ImageTextColumn from './Comps/ImageTextColumn.js';
import HeadlineText from './Comps/HeadlineText.js';



const TxtComponent = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    // -- window dims -- //
    // const { 
    //     width, 
    //     height 
    // } = useWindowDimensions();


    const width = props.width;
    const height = props.height;


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    


    // -- refs -- //

    /*

    const thmbRefs = [];
    const setThmbRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < thmbRefs.length; i++)
            {
                if(thmbRefs[i].id === ref.id) found = true;
            }
            if(!found) thmbRefs.push(ref);
            //console.log(thmbRefs.length);

        }

    };

    */





    /*

    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    const refC = useRef(null);

    */


    // -- state -- //

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);


    //const [thmbWidth, setThmbWidth] = useState(300);
    //const [thmbHeight, setThmbHeight] = useState(225);



    useEffect(() => {


        //alert('check Txt');

        /*

        if(thmbRefs.length > 0)
        {
            if(thmbRefs[0] && thmbRefs[0] !== undefined)
            {
                var thmbRef = thmbRefs[0];
                //console.log("THMB REF: "+thmbRef);
                var thmbW = thmbRef.offsetWidth;
                var thmbH;
           
                if(thmbRefs.length === 1)
                    thmbH = (62/100) * thmbW;
                else
                    thmbH = (64/100) * thmbW;

                //console.log(thmbH);
                //setThmbWidth(thmbW);
                //setThmbHeight(thmbH); 


            }
        }

        */


        /*

        alert(""
            +"\n"
            +`PUBLIC: ${process.env.PUBLIC_URL}`
            +"\n"
            +"WINDOW LOCATION ORIGIN: "+window.location.origin
            +"\n"
            +"WINDOW LOCATION HREF: "+window.location.href 
        );
        */
        



        
        const posArr = [];
        const tempArr = [];

        const Complete = (array) => {

            //alert('images finished loading');
            //console.log(":::"+location.pathname+":::");
            //console.log("Images Finished Loading (Fluid)")

            if(array.length > 0)
            {
                    //console.log("Image Loaded Successfully!!");


                    //console.log("ARRAY: "+JSON.stringify(array,null,2));
                    //alert('check the complete');

                    setIsLoaded(true);
                    setItems(array);

            }else{

                    console.log("Images Loaded Failed");

                    setIsLoaded(true);
                    setError({message:"Process Image (FAILED)"});
            }
        
        }





        var skip = false;
        if(props.info.gridType.match(/image/gi)
        && !skip
        )
        {
            var initItems;
            initItems = props.items;
            if(props.items && Object.prototype.toString.call(props.items) === '[object Array]')
            {
                initItems = JSON.parse(JSON.stringify(props.items));
            }

            if(items.length === 0)
            {

                //console.log("myComponents Txt --- "+JSON.stringify(items,null,2));

                initItems.map(item => (

                    updateData(item)

                ))

                function updateData(item) {

            
                    if(item.image.src.match(/http/g))
                    {
                         
                        //if(item.button) alert(item.button.value);


                        posArr.push(item.id);
                        tempArr.push(item);
                        if(tempArr.length === initItems.length) Complete(tempArr);

                        

                        // ORIGINAL

                        // if(tempArr.length === props.items.length)
                        // {
                        //     posArr.sort();
                
                        //     //console.log("POS: "+posArr.length)
                        //     //console.log("TEMP: "+tempArr.length)
                        //     //console.log("TEST LEN: "+testData.length)
                        
                
                        //     var array = [];
                        //     for(var i=0; i < posArr.length; i++)
                        //     {
                        //         for(var j=0; j < tempArr.length; j++)
                        //         {
                        //             //console.log(posArr[i]+" === "+tempArr[j]['id']);
                
                        //             if(posArr[i] === tempArr[j]['id'])
                        //             {
                                    
                        //                 array.push(item);
                        //                 //array.push(tempArr[j]);
                        //                 //console.log("break");
                        //                 break;
                
                        //             }//==
                
                        //         }//==
                
                        //     }//==


                            // Complete(array);
                
                        // }//==
                
    
    
                    }
                    else
                    {
                        
                        //console.log("ENV PUBLIC URL: "+process.env.PUBLIC_URL);
                    
                        var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;
                        //var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;



                        //console.log(sourceurl);
                    
                        var img = new Image();
                        img.error = function(){
                            console.log("IMAGE ERROR");
                        };
                        img.onload = function(){
                    
                            //console.log("IMAGE IS LOADED");
                    
                            //console.log('My width is: ', img.naturalWidth);
                            //console.log('My height is: ', img.naturalHeight);
                    
                            var imgW = img.naturalWidth;
                            var imgH = img.naturalHeight;
                    
                        

                            /* Original
                            //---------------------------------------

                            posArr.push(item.position);
                            tempArr.push({

                                position    :item.position,
                    
                                id          :item.id,
                                title       :item.title,
                                text        :item.text,
                                bgColor     :item.bgColor,
                                image:{
                    
                                    src :item.image.src,
                                    w   :imgW,
                                    h   :imgH
                                }
                    
                            })

                            //----------------------------------------
                            */


                            //----------------------------------------

                            /* Sample ImageText

                            {
                                "heading": "Image Text Sample 3",
                                "pgraph": "Lorem, ipsum dolor sit",
                                "image": {
                                    "src": "/_resources/images/raceaway.jpg",
                                    "w": "150",
                                    "h": "150"
                                },
                                "button": false
                            }

                            */

                            posArr.push(item.image.src);
                            // tempArr.push({

                            //     heading: item.heading,
                            //     pgraph: item.pgraph,
                            //     image:{
                    
                            //         src :item.image.src,
                            //         w   :imgW,
                            //         h   :imgH
                            //     },
                            //     button: item.button


                            // })
                            item.image.w = imgW;
                            item.image.h = imgH;
                            tempArr.push(item);

                            //----------------------------------------




                            if(tempArr.length === props.items.length)
                            {
                                posArr.sort();

                                //console.log("POS: "+posArr.length)
                                //console.log("TEMP: "+tempArr.length)
                                //console.log("TEST LEN: "+testData.length)
                            

                                var array = [];




                                /* ORIGINAL
                                //--------------------------------------------

                                for(var i=0; i < posArr.length; i++)
                                {
                                    for(var j=0; j < tempArr.length; j++)
                                    {
                                        //console.log(posArr[i]+" === "+tempArr[j]['position']);

                                        if(posArr[i] === tempArr[j]['position'])
                                        {
                                        
                                            array.push(tempArr[j]);
                                            //console.log("break");
                                            break;

                                        }//==
                    
                                    }//==

                                }//==

                                //---------------------------------------------
                                */



                                
                                //--------------------------------------------

                                for(var i=0; i < posArr.length; i++)
                                {
                                    for(var j=0; j < tempArr.length; j++)
                                    {
                                        //console.log(posArr[i]+" === "+tempArr[j]['position']);

                                        if(posArr[i] === tempArr[j].image.src)
                                        {
                                                       
                                            array.push(item);
                                            //array.push(tempArr[j]);
                                            //console.log("break");
                                            break;

                                        }//==
                    
                                    }//==

                                }//==

                                //---------------------------------------------
                                



                                Complete(array);

                            }//==

                    
                    
                        };
                        img.setAttribute("crossOrigin","anonymous");
                        img.setAttribute("src",sourceurl);
                    
                    }

                }



            }// -- if items



        }
        else
        {


            // console.log("ITEMS: "+JSON.stringify(props.items,null,2));
            // alert("Items");

            setError(false);
            setIsLoaded(true);
            setItems(props.items);

        }
 



    },[


        height,
        //thmbRefs,
        location,
        items,
        props
    ])


    






    // -- vars -- //

    /*

    var ssg;
    var gridMaxWidth;  
    ssg = false;
    gridMaxWidth = "1920px";
    if(location.search.match(/\?/gi))
    {
        var qr = location.search;
        if(qr.charAt(0) === '?') 
            qr = qr.substring(1,qr.length);

        if(qr === 'ssg=yes') 
        {
            ssg = true;
            gridMaxWidth = "1680px";

        }
    }

    */


    //var call;
    // = props.info.call;
    //(ssg) call = 'fixed';



    const info = JSON.parse(JSON.stringify(props.info));
    //info.gridMaxWidth = gridMaxWidth;
    //info.ssg = ssg;




    //alert(props.refid);




    //var columnClass = StylColumn.Full;
    var columnClass = 'full';

    
    if(props.refid 
    && props.refid !== undefined
    && props.refid !== null
    )
    {
        if(document.getElementById(props.refid))
        {
            const ref = document.getElementById(props.refid);

            var refDecimal = (parseInt(ref.offsetWidth)/width);
            var newWidth  = width * refDecimal;

            info.thresh.refWidth = newWidth;
        }

        //console.log(props.refid+"\n"+ref.offsetWidth+"\n"+refDecimal+"\n"+newWidth);

        if(props.refid.match(/pagerow/))
        {
            //columnClass = StylColumn.Page;
            columnClass = 'page';
        }

    }//==









    if(error) 
    {
        return (

            <div 
            style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

            }}
            >
                    Error: {error.message}

            </div>

        )


    } 
    else 
    if(!isLoaded) 
    {

        return (
        
             <div 
             style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

             }}
             >
                 Loading...

            </div>
        )


    } 
    else 
    {



        return (


            <div>


                {

                props.info.gridType === 'HashList' 
                ? 
                    <HashList 
                    
                        handle = {(v) => props.handlePage(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}


                    />

                :null

                }


                {

                props.info.gridType === 'HashSection' 
                ? 
                    <HashSection 
                    
                        handle = {(v) => props.handlePage(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}

                    />

                :null

                }




                {

                props.info.gridType === 'HeadlineText' 
                ? 

                    <HeadlineText
                    
                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}
                        
                    />

                :null

                }



                {

                props.info.gridType === 'AllText' 
                ? 
                    <AllText 
                    
                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}

                    />

                :null

                }


                {

                props.info.gridType === 'SingleTextBox' 
                ? 

                     <SingleTextBox 
                     
                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}
                        
                     />

                :null

                }

                {

                props.info.gridType === 'TextBox' 
                ? 

                    <TextBox 
                        
                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}

                        databind={props.databind}

                        
                    />

                :null

                }

                {

                props.info.gridType === 'ImageTextBox' 
                ? 


                    <ImageTextBox 
                    
                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}

                        databind={props.databind}
                
                    />


                :null

                }


                {

                props.info.gridType === 'ImageTextColumn' 
                ? 

                    <ImageTextColumn
                    
                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}
                        
                    />

                :null

                }


                {
                
                props.info.gridType === 'Newspaper' 
                ? 
                    <Newspaper 

                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}
                        
                    />

                :null

                }

                {
                
                props.info.gridType === 'ImageWrapText' 
                ? 
                    <ImageWrapText

                        handle = {(v) => props.handle(v)} 
                        info={info}
                        items={items}
                        columnClass={columnClass}

                    />

                :null

                }




            </div>


                        


        )


    }


}

export default TxtComponent;











/*


                    {
                    columnClass === 'full' ?

                        items.length === 1 ?

                

                        :// === FULL MORE THAN ONE ===


                    :// === PAGE ===



                    }




*/