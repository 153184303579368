import React, { 
    useState, 
    useEffect, 
    useRef 
} from 'react';


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'




import { 

    MainSide, 
    SideMain,

    Heading, 
    Pgraph,

} from '../Styled/TwoColumns';

import CreateMarkup from 'utils/components/Universal/CreateMarkup';
//import CreateLink from 'utils/components/Universal/CreateLink';




const ImageWrapText = (props) => {



    // -- window dims -- //
    const { 
        width, 
        //height 
    } = useWindowDimensions();



    //const items = props.items;
    //const columnClass = props.columnClass;


    // -- ref -- //
    const refSide = useRef(null);
    const refMain = useRef(null);
    const refContainer = useRef(null);

    
    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);
    const [items] = useState(props.items);
    const [columnsStyl, setColumnsStyl] = useState(false);

    useEffect(() => {


        if(!isLoaded)
        {
            setIsLoaded(true); 

        }else{



            var sideWidth, imageWidth, textFromTop;

            if(!columnsStyl)
            {

                imageWidth = props.info.imageWidth;
                imageWidth = imageWidth.replace(/%/gi,"");
                imageWidth = parseFloat(imageWidth);

                switch(props.info.imageAlign)
                {
                case'right':


                    sideWidth = imageWidth + "%";
                break;
                default:

                    sideWidth = (imageWidth) + "%";
                }


                switch(props.info.textPosition)
                {
                case'top':
                    textFromTop = "0px";
                break;
                case'center':
                    textFromTop = (refMain.current.offsetHeight - refContainer.current.offsetHeight)/2 + "px";
                break;
                default:
                    textFromTop = props.info.textPosition;
                }


                setColumnsStyl({

                    sideWidth:sideWidth,
                    textFromTop:textFromTop

                });



            }else{


                if(currentWidth !== width)
                {

                    setCurrentWidth(width);

                    switch(props.info.textPosition)
                    {
                    case'top':
                        textFromTop = "0px";
                    break;
                    case'center':
                        textFromTop = (refMain.current.offsetHeight - refContainer.current.offsetHeight)/2 + "px";
                    break;
                    default:
                        textFromTop = props.info.textPosition;
                    }

                    setColumnsStyl({

                        sideWidth:columnsStyl.sideWidth,
                        textFromTop:textFromTop

                    });

                
                }


            }

        }






    },[

        
        props,
        isLoaded,
        width,currentWidth,
        columnsStyl,
        refSide,refMain,refContainer
 
    ])




    // -- handle -- //

    // const handle = (hndl) => {

    //     //alert(JSON.stringify(hndl));
    //     switch(hndl.call)
    //     {
    //     case'url':

    //         // check url

    //         var externalUrl = false;

    //         if(externalUrl)
    //         {
    //             props.handle({

    //                 call:'url',
    //                 data:{

    //                     action:'target',
    //                     url:hndl.data

    //                 }

    //             });

    //         }else{

    //             props.handle({

    //                 call:'url',
    //                 data:{

    //                     action:'replace',
    //                     url:hndl.data

    //                 }

    //             });

    //         }

    //     break;
    //     default:
    //     }

    // }


    




    if(!isLoaded)
    {
        return (

            <div></div>

        )

    }else{

        return (

            <div>
                {
                props.info.imageAlign === 'left'
                ?

                    <SideMain
                    sideWidth={columnsStyl.sideWidth}
                    textFromTop={columnsStyl.textFromTop}
                    >


                        <div
                        ref={refSide}
                        className={"Side"}
                        >
                            <img
                            id={"image"}
                            className="Img"
                            src={items[0].image.src} 
                            alt="I" />

                        </div>


                        <div 
                        ref={refMain}
                        className={"Main"}
                        >

                            <div
                            ref={refContainer}
                            className={'Container'}
                            >

                                {items[0].text.map((obj, i) => (

                                                                
                                    <div key={i}>
                                        <Heading>

                                            {/* {obj.heading} */}
                                            <div dangerouslySetInnerHTML={CreateMarkup(obj.heading)} />

                                        </Heading>

                                        <Pgraph>

                                            {/* {obj.pgraph} */}
                                            <div dangerouslySetInnerHTML={CreateMarkup(obj.pgraph)} />


                                        </Pgraph>

                                    </div>

                                ))}


                            </div>


                        </div>


                    </SideMain>


                :


                    <MainSide
                    sideWidth={columnsStyl.sideWidth}
                    textFromTop={columnsStyl.textFromTop}
                    >

                        <div 
                        ref={refMain}
                        className={"Main"}
                        >

                            <div
                            ref={refContainer}
                            className={'Container'}
                            >

                                {items[0].text.map((obj, i) => (

                                                                
                                    <div key={i}>

                                        <Heading>

                                            {/* {obj.heading} */}
                                            <div dangerouslySetInnerHTML={CreateMarkup(obj.heading)} />

                                        </Heading>

                                        <Pgraph>

                                            {/* {obj.pgraph} */}
                                            <div dangerouslySetInnerHTML={CreateMarkup(obj.pgraph)} />


                                        </Pgraph>

                                    </div>

                                ))}


                            </div>


                        </div>

                        <div
                        ref={refSide}
                        className={"Side"}
                        >
                            <img
                            id={"image"}
                            className="Img"
                            src={items[0].image.src} 
                            alt="I" />

                        </div>

                    </MainSide>
            
                }
            </div>
        )

        

    }








}

export default ImageWrapText;


