import React, { 

    useState, 
    //useEffect, 
    //useRef 

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";

//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';

//import { useLiquidImageGrid } from 'utils/myHooks/LiquidFormula/ImageGrid';
//import { useLiquidGrid } from 'utils/myHooks/LiquidFormula/LiquidGrid';
import { useLiqGrid } from 'utils/hooks/LiquidFormula/LiqGrid';

import Styl from './Styl.module.css';



const FilmStrip = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();


    //console.log(JSON.stringify(location,null,2));
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- window dims -- //
    //const { height } = useWindowDimensions();





     // -- multi refs -- //

     const [cellRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
     const setCellRef = (ref) => {
 
         if(ref && ref !== null && ref !== undefined)
         {
             //console.log("ref::::"+ref.id);
             var found;
             found = false;
             for(var i=0; i < cellRefs.length; i++)
             {
                 if(cellRefs[i].id === ref.id) found = true;
             }
             if(!found) cellRefs.push(ref);
             //console.log(cellRefs.length);
 
         }
 
     };

    //  const maskRefs = useState([]);
    //  const setMaskRef = (ref) => {
 
    //      if(ref && ref !== null && ref !== undefined)
    //      {
    //          //console.log("ref::::"+ref.id);
    //          var found;
    //          found = false;
    //          for(var i=0; i < maskRefs.length; i++)
    //          {
    //              if(maskRefs[i].id === ref.id) found = true;
    //          }
    //          if(!found) maskRefs.push(ref);
    //          //console.log(cellRefs.length);
 
    //      }
 
    //  };

     


 


/*

    // -- handle -- //

    const handleOverOut = (hndl) => {


        //hndl.bgColor;
        //hndl.txtColor;
        //hndl.brColor;


        
        var cell = false;
        var mask = false;

        if(cellRefs.length > 0)
        {
            var cellRegex = new RegExp(hndl.id.toString(),"gi");
            for(var cellRef of cellRefs)
            {
                if(cellRef.id.match(cellRegex) 
                )
                {
                    cell = cellRef;
                    break;
                }
            }
        }



        if(maskRefs.length > 0)
        {
            var maskRegex = new RegExp(hndl.id.toString(),"gi");
            for(var maskRef of maskRefs)
            {
                if(maskRef.id.match(maskRegex) 
                )
                {
                    mask = maskRef;
                    break;
                }
            }
        }




        //console.log(hndl.gridType);

        switch(hndl.call)
        {
        case'over':
    
            switch(hndl.gridType)
            {
            case"ImageText":
           
                if(cell) cell.style.borderColor = "red";
            break;
            default:
    
                if(mask) mask.style.backgroundColor = 'magenta';
            break;
            }//==

        break;
        default:

            switch(hndl.gridType)
            {
            case"ImageText":
    
                if(cell) cell.style.borderColor = "lightgray";
            break;
            default:
    
                if(mask) mask.style.backgroundColor = 'transparent';
            break;
            }//==

        break;
        }   



    };

*/



    var gridObj;
    gridObj = useLiqGrid(props.items, props.info);
    //console.log("myBuilds/FGrid/FilmStrip - vars..."+JSON.stringify(gridObj['vars'],null,2));
    //console.log("myBuilds/FGrid/FilmStrip - items..."+JSON.stringify(gridObj['items'],null,2));

    const items = gridObj['items'];



    //console.log(JSON.stringify(items,null,2));
    //alert('Check FilmStrip')


    var cellMargin, thumbDif;    

    thumbDif = props.info.thumb.diff.toString();
    if(thumbDif.match(/px/)) 
        thumbDif = thumbDif.replace(/px/gi,"");

    cellMargin = "0 0 "+thumbDif+"px 0";

    //if(props.info.gutter.boolean)
        //cellMargin = "0 0 "+props.info.thumb.diff+"px "+(props.info.gutter.size)+"px";


    



    return (


        <div 
        id="filmstrip"
        className={Styl.FilmStrip} 
        >


            <div 
            className={Styl.Grid}
            style={{

                //backgroundColor : "lightyellow", 
                //width           : items.grid.width,
                //height          : items.grid.height, 

            }}

            >

                {items.data.map((box, i) => (

                    <div 
                    ref={setCellRef}
                    key={i}   
                    id={box.id}
                    className={Styl.Cell} 
                    style={{

                        //backgroundColor : box.bgColor, 
                        width           : items.cell.width,
                        height          : items.cell.height,
                        //height          : "300px",

                        //margin          : items.cell.margin,
                        margin          : cellMargin,

                        //marginBottom    : 50 + "px",
                        //border          : items.cell.border
                
            
                    }}
            
                    >


                        <div 
                        className={Styl.Thmb}  
                        style={{

                            //backgroundColor:"darkmagenta",
                            width:"100%",
                            //height:"62.5%",

                            //width:items.cell.thumb.width,
                            //height:items.cell.thumb.height,
                            //height:box.thumb.h,
                            height:box.image.maxHeight,


                            //margin:items.cell.thumb.margin,
                            overflow:"hidden"

                
                        }}
                
                        >



                            {


                            box.image.src.match(/http/gi) 
                            ?

                                <img
                                style={{
    
    
                                    flexShrink: 0,
    
                                    height:"auto",
    
                                    minWidth:box.image.maxWidth,
                                    //minHeight: "100%",
    
                                    maxWidth:"100%",
                                    maxHeight:box.image.maxHeight,
    
                                    marginTop:box.image.top,
                                    marginLeft:box.image.left
    
    
                                }}
    
                                    src={box.image.src} 
                                    //src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                    //src={window.location.origin + box.image.src} 
                                    //width={box.image.w}
                                    //height={box.image.h} 
                                    alt='(!)'
    
                                 />






                            :

                                <img
                                style={{
    
    
                                    flexShrink: 0,
    
                                    height:"auto",
    
                                    minWidth:box.image.maxWidth,
                                    //minHeight: "100%",
    
                                    maxWidth:"100%",
                                    maxHeight:box.image.maxHeight,
    
                                    marginTop:box.image.top,
                                    marginLeft:box.image.left
    
    
                                }}
    
                                    src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                    //src={window.location.origin + box.image.src} 
                                    //width={box.image.w}
                                    //height={box.image.h} 
                                    alt='(!)'
    
                                 />



                            }


                    
                        </div>



                        {/*
                        props.info.gridType === 'ImageText' ? 


                            <div>
                                <div className={Styl.Title} >{box.title}</div>
                                <div className={Styl.Text} >{box.text}</div>
                            </div>

                        : null
                        */}


                    </div>
                ))}


            </div>

        </div>



    )




}

export default FilmStrip


