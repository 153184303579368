import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
// import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
//import useAddScript from 'utils/myHooks/AddScript';
//import { useFetch }  from 'utils/hooks/Fetch';
//import { useMatomo } from '@datapunt/matomo-tracker-react';

//import Controls from "./Controls";





import DefaultFetchResponse from 'utils/components/Pages/Default/FetchResponse';



// -- placeholder data -- //

import SVGpage from 'utils/components/Pages/SceneInfo/Trials/SVGpage/SceneInfo';
import SVGgridpage from 'utils/components/Pages/SceneInfo/Trials/SVGgridpage/SceneInfo';


// -- Trials -- //

import SItrialPage from 'utils/components/Pages/SceneInfo/Trials/Page/SceneInfo';
import SItrialPageBillboard from 'utils/components/Pages/SceneInfo/Trials/Billboard/SceneInfo';
import SItrialPageCompat from 'utils/components/Pages/SceneInfo/Trials/CompatPage/SceneInfo';
import SItrialPageFetch from 'utils/components/Pages/SceneInfo/Trials/FetchPage/SceneInfo';
import SItrialPageLandingPage from 'utils/components/Pages/SceneInfo/Trials/LandingPage/SceneInfo';
import SItrialPageNav from 'utils/components/Pages/SceneInfo/Trials/NavPage/SceneInfo';
import SItrialPageSlideshow from 'utils/components/Pages/SceneInfo/Trials/SlideShowPage/SceneInfo';
import SItrialPageDynamicForm from 'utils/components/Pages/SceneInfo/Trials/DynamicForm/SceneInfo';





const Index = (props) => {



    //-------------------------------
    // add scripts
    //-------------------------------

    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    //-------------------------------
    // window dims
    //-------------------------------

    // const { 
    //     width, 
    //     height 
    // } = useWindowDimensions();

    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }




    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-------------------------------
    // redux
    //-------------------------------

    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    let currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");
    if(currentZone === '') currentZone = 'Home';

    let zoneKey = currentZone + "_fetch";
    

    //------------------------------------------
    // state/effect
    //------------------------------------------

    const [isLoaded, setIsLoaded] = useState(false);
    const [wasFetchd, setWasFetchd] = useState(false);

    useEffect(() => {


        //--------------------------
        // No fetchd
        //--------------------------

        var si;
        var sett;
        if(!isLoaded)
        {

            setIsLoaded(true);


            //================================================
            //sceneInfo
            //================================================

            if(currentZone.match(/home/gi))
            {
                si = SItrialPageNav;
            }
            else
            if(currentZone.match(/trial/gi))
            {

                if(currentZone.match(/trialpagecompat/gi))
                {

                    si = SItrialPageCompat;

                }
                else
                if(currentZone.match(/trialpagefetch/gi))
                {

                    si = SItrialPageFetch;

                }
                else
                if(currentZone.match(/trialpagenav/gi))
                {

                    si = SItrialPageNav;

                }
                else
                if(currentZone.match(/trialpagebillboard/gi))
                {

                    si = SItrialPageBillboard;
                }
                else
                if(currentZone.match(/trialpageslideshow/gi))
                {

                    si = SItrialPageSlideshow;
                }
                else
                if(currentZone.match(/trialpagelandingpage/gi))
                {

                    si = SItrialPageLandingPage;

                }
                else
                if(currentZone.match(/trialpagedynamicform/gi))
                {

                    si = SItrialPageDynamicForm;

                }
                else
                if(currentZone.match(/trialpagesvg/gi))
                {

                    si = SVGpage;

                }
                else
                if(currentZone.match(/trialpagegridsvg/gi))
                {

                    si = SVGgridpage;

                }
                else
                {

                    si = SItrialPage;

                }
    

            }else{

                si = SItrialPageNav;

            }

            //setSceneArr(si);

            si = JSON.parse(JSON.stringify(si));//avoids the react-redux mutation error
                        


            props.handle({

                call:'dispatch',
                data:{

                    action      :'UPDATE_ZONE',
                    key         :'fetch',
                    value       :si

                },
                usePrefix:true // will add currentZone to the zoneObj

            });



            //================================================
            //settings
            //================================================

            //setSettings(DefaultFetchResponse.settings);

            sett = DefaultFetchResponse.settings;
            sett = JSON.parse(JSON.stringify(sett));//avoids the react-redux mutation error
              
            props.handle({

                call:'dispatch',
                data:{

                    action      :'UPDATE_ZONE',
                    key         :'settings',
                    value       :sett
                    
                },
                usePrefix:false // will add currentZone to the zoneObj

            });


        }else{


            if(!wasFetchd)
            {

                setWasFetchd(true);

                // props.handle({

                //     call:'dispatch',
                //     data:{

                //         action      :'UPDATE_ZONE',
                //         key         :'redirectOptions',
                //         value       :fetchdOptions,
             
                //     },
                //     usePrefix:false // will add currentZone to the zoneObj

                // });


                props.handle({

                    call:'Fetch'

                });
            }
    
        }//isLoaded


    },
    [

        props,

        location,
        
        //dispatch,
        zoneObj,
        zoneKey,

        pathname,
        currentZone,        

        isLoaded,
        wasFetchd
      
        // settings,
        // sceneArr

    ])


    return(<div></div>);


}

export default Index;
