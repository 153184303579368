import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


// import {
//     HandleShowHide, 
//     HandleChoice,
//     HandleChangeLayer
// } from "./Handles";

import {

    ArrowButton,

    Controls,
    ControlsButton,

    ControlPanel,
    // ControlPanelTop, 
    // ControlPanelBottom,
    ControlPanelWing,
    ControlPanelScroller,
    ControlPanelGroup,  
    ControlPanelTile,  



    MobiControls,
    MobiControlsButton,

    MobiControlPanel,
    MobiControlPanelTop, 
    MobiControlPanelBottom,
    MobiControlPanelScroller,
    MobiControlPanelTile


} from './Styled';




import { 
    LibElem, 
    LibString 
} from 'library';

import ControlData from './ControlData';

//import GRFX from '../Slides/CarSlide/GRFX';
import GRFX_BUTTONS from '../Slides/CarSlide/GRFX_BUTTONS';


const Index = (props) => {



    // -- window dims -- //
    const { 
        width, 
        //height 
    } = useWindowDimensions();




    // -- props -- //
    const list = props.list;    
    const info = props.info;
    const layers = props.layers;
    // const topLayer = props.topLayer;

    // -- refs -- //
    const refControls = useRef(null);   
    const refControlPanel = useRef(null);  
    const refControlPanelTop = useRef(null); 
    const refControlPanelBottom = useRef(null);
    const refControlPanelScroller = useRef(null); 





    //##############################################################
    //##############################################################
    //##############################################################
    //ShowHide

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [controlsZ] = useState(50);
    const [controlPanelZ] = useState(51);
    const [controlPanelStatus, setControlPanelStatus] = useState('opened');
    const [controlPanelSection, setControlPanelSection] = useState(false);

    const [scrollerHeight, setScrollerHeight] = useState("100%");


    const [tileRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
    const setTileRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < tileRefs.length; i++)
            {
                if(tileRefs[i].id === ref.id) found = true;
            }
            if(!found) tileRefs.push(ref);
            //console.log(tileRefs.length);

        }

    };

    const [mobiThreshold] = useState(props.mobiThreshold);
    
    // const [grfxSize, setGrfxSize] = useState({

    //     width:"200px",
    //     height:"125px"

    // });




    useEffect(() => {


        const effectShowHide = (eff) => {

            //------------------------------------------
            // ShowHide
            //------------------------------------------

            var openclose = 'open';
            if(controlPanelStatus === 'opened' && eff.section === controlPanelSection)
            {
                openclose = 'close';
            }
    
            // console.log("SECTION: "+hndl.section);
            // console.log("controlPanelStatus: "+controlPanelStatus);
            // console.log("controlPanelSection: "+controlPanelSection);
            // console.log("openclose: "+openclose);
    
            setControlPanelSection(eff.section);

    
            //alert('showhide');
    
            var cpStatus;
            var xy;
            //if(controlPanelXY.right !== "0px")
    
            // if(controlPanelStatus === 'closed')
            // {
            //     xy = {
            //         top:"0px",
            //         right:"50px"
            //     };
            //     cpStatus = 'opened';
    
            // }else{
    
            //     xy = {
            //         top:"0px",
            //         right:"-1000px"
            //     };
            //     cpStatus = 'closed';
            // }
    
    
            if(openclose === 'open')
            {
    
                xy = {
                    top:"0px",
                    right:"0px"
                };
                cpStatus = 'opened';
    
            }
            else
            if(openclose === 'close')
            {
    
                xy = {
                    top:"0px",
                    right:"0px"
                    //right:"50px"
                };
                cpStatus = 'closed';
    
            }
    
            


            // TM - this a reservedSpace handle if needed - bypassing for now - hardset on SVGboard index

            // var reservedWidth = refControls.current.offsetWidth + refControlPanel.current.offsetWidth;
            // var reservedHeight = refControls.current.offsetHeight + refControlPanel.current.offsetHeight;
            // //console.log("reserved space")
            // props.handle({

            //     call:"setReservedSpace",
            //     data:{
    
            //         width:reservedWidth,
            //         height:reservedHeight
            
            //     }


            // });

            if(cpStatus !== controlPanelStatus)
            {
    
                //alert(JSON.stringify(xy,null,2));
                // setControlPanelXY(xy);
    
                var cp = document.getElementById(refControlPanel.current.id);
                cp.style.right = xy.right;
    
    
                //console.log(":::::"+cpStatus);
    
                setControlPanelStatus(cpStatus);
                props.handle({
                    call:'controlPanelStatus',
                    data:cpStatus
                });
            
    
            }
    

    
        }




        if(!isLoaded)
        {

            setIsLoaded(true);
            effectShowHide({

                call:'showhide',
                section:'body'
            
            });

            //setMobiThreshold(props.mobiThreshold);

        }//isLoaded





        //console.log(JSON.stringify(props,null,2));
        //alert('props');

        var initScrollerHeight = refControlPanel.current.offsetHeight - (refControlPanelTop.current.offsetHeight + refControlPanelBottom.current.offsetHeight) + "px";
        setScrollerHeight(initScrollerHeight);

        //console.log(controlPanelStatus);


    },
    [

        props,
        isLoaded,
        list,
        info,
        layers,
        refControlPanelTop,
        refControlPanelScroller,
        controlPanelStatus,
        controlPanelSection


    ])








    //##############################################################
    //##############################################################
    //##############################################################
    //categories


    const [categories, setCategories] = useState({});
    const [subCategoryChoice, setSubCategoryChoice] = useState({

        call:false,
        category:false,
        name:''

    });
    const [resetCategories, setResetCategories] = useState(false);


    useEffect(() => {


        const effectCategories = (eff,scroller) => {

            var i;
            var object;
            var array;

            var catsObject = {};

            //------------------------------------------
            // ControlData - convert to categories
            //------------------------------------------
            //var catsOrder = ControlData.order;
            var main = ControlData.main;
            var subA = ControlData.subA;
            var subB = ControlData.subB;
            var subC = ControlData.subC;


            var newCall;
            var newCategory;
            var newName;
            var newItem;

            var prevCall;
            var prevCategory;
            var prevName;
            var prevItem;
            var prevCats;


            var choiceCall;



            //alert("ITEM: "+JSON.stringify(eff.data,null,2));


            switch(eff.data.call)
            {
            case'main':

                // set the array
                for(i=0; i < main.length; i++)
                {
                    object = main[i];
                    if(catsObject[object.category] 
                    && Object.prototype.toString.call(catsObject[object.category]) === '[object Array]'
                    )
                    {
                    }else{
                        catsObject[object.category] = [];
                    }
                
                }

                // fill the array
                for(object of main)
                { 
                    catsObject[object.category].push(object)
                }

                newCall = false;
                newCategory = false;
                newName = '';
                newItem = false;

                prevCall = false;
                prevCategory = false;
                prevName = '';
                prevItem = false;
                prevCats = false;





            break;
            case'sub':


       


                console.log('sub');


                //console.log(JSON.stringify(eff,null,2));
                //console.log(subCategoryChoice.call);
                console.log(JSON.stringify(subCategoryChoice,null,2))

                // prevCategory = eff.data.item.category;
                // //if(prevCategory === undefined) prevCategory = subCategoryChoice.prevCategory;
    
                // prevItem = eff.data.item;
                // //if(prevItem === undefined) prevItem = subCategoryChoice.prevItem;
    
                // prevName = eff.data.item.name;
                // //if(prevName === undefined) prevName = subCategoryChoice.prevName;
    

                prevCategory = subCategoryChoice.prevCategory;
                prevItem = subCategoryChoice.prevItem;
                prevName = subCategoryChoice.prevName;



            
                choiceCall = subCategoryChoice.call;
                if(eff.data.goback)
                {
                    //alert(subCategoryChoice.prevCall);
                    if(subCategoryChoice.prevCall === 'A')
                    {
                        choiceCall = false;
                    }
                }
                if(choiceCall 
                && subCategoryChoice.category
                )
                {

                    if(eff.data.item.subCategory)
                    {

                        scroller.current.scrollTop = 0;

                        //set the array
                        if(catsObject[subCategoryChoice.category] 
                        && Object.prototype.toString.call(catsObject[subCategoryChoice.category]) === '[object Array]'
                        )
                        {
                        }else{
                            catsObject[subCategoryChoice.category] = [];
                        }



                        // fill the array
                        switch(choiceCall)
                        {
                        case'A': // to B

                            console.log("A to B");
                            //console.log("subB:" + JSON.stringify(subB,null,2));
                            //console.log(subCategoryChoice.category+ " ===== " +hndl.data.item.subCategory);


                            prevCall = 'A';
                            prevCategory = subCategoryChoice.category;
                            prevItem = subCategoryChoice.item;
                            prevName = subCategoryChoice.name;
                            prevCats = catsObject;



                            catsObject[eff.data.item.subCategory] = subB[eff.data.item.subCategory];

                            newCall = 'B';
                            newCategory = eff.data.item.subCategory;

                            newName = newCategory.replace(/_/gi," ");
                            newName = LibString.CapitalizeWords(newName);

                            newItem = eff.data.item;


                        break;
                        case'B': // to C

                            console.log("B to C");

        
                            catsObject[eff.data.item.subCategory] = subC[eff.data.item.subCategory];

                            newCall = 'C';
                            newCategory = eff.data.item.subCategory;

                            newName = newCategory.replace(/_/gi," ");
                            newName = LibString.CapitalizeWords(newName);


                        break;
                        default:
                        }




                    }else{

                        //console.log("Handle Choice")


                        // handle the choice

                        //console.log('make choice and go back to main');
                        //console.log("Item: "+JSON.stringify(hndl.data.item,null,2));


                        //-----------------------
                        // stay on current menu
                        //-----------------------

                        catsObject = JSON.parse(JSON.stringify(categories));//avoids the react-redux mutation error

                        newCall = subCategoryChoice.call;
                        newCategory = subCategoryChoice.category;
                        newName = subCategoryChoice.name;

                        prevCall = subCategoryChoice.prevCall;
                        prevCategory = subCategoryChoice.prevCategory;
                        prevItem = subCategoryChoice.prevItem;
                        prevName = subCategoryChoice.prevName;
                        prevCats = subCategoryChoice.prevCats;





                        // //-----------------------
                        // // go back to main
                        // //-----------------------
                    
                        // // set the array
                        // for(i=0; i < main.length; i++)
                        // {
                        //     object = main[i];
                        //     if(catsObject[object.category] 
                        //     && Object.prototype.toString.call(catsObject[object.category]) === '[object Array]'
                        //     )
                        //     {
                        //     }else{
                        //         catsObject[object.category] = [];
                        //     }
                        
                        // }

                        // // fill the array
                        // for(object of main)
                        // { 
                        //     catsObject[object.category].push(object)
                        // }

                        // newCall = false;
                        // newCategory = false;
                        // newName = '';



                        //console.log("eff.data------"+JSON.stringify(eff.data,null,2));
                        // eff.data------{
                        //     "call": "sub",
                        //     "indx": 15,
                        //     "item": {
                        //       "subCategory": false,
                        //       "name": "Gulf",
                        //       "thumbComponent": "BUTTON_LOGO_GULF",
                        //       "layerComponent": "BODY_THEME_BLUE_GULF",
                        //       "matchingComponents": {
                        //         "hardtop": "HARDTOP_OEM_SOLID_GULF_BLUE"
                        //       }
                        //     }
                        //   }





                        //-----------------------------------
                        // Change Layer Component
                        //-----------------------------------

                        var keyname;
                        var itemId;
                        var itemId2;
                        array = [];
                        for(i=0; i < layers.length; i++)
                        {
                            object = layers[i];

                            itemId = eff.data.item.layerComponent.split("_")[0];
                            itemId2 = eff.data.item.layerComponent.split("_")[0]+"_"+eff.data.item.layerComponent.split("_")[1];

                            //console.log("itemId::: "+itemId);
                            //console.log("itemId2::: "+itemId2);

                            if(object.slide.call.toUpperCase()===itemId 
                            || object.slide.call.toUpperCase()===itemId2
                            )
                            {
                                object.slide.component = eff.data.item.layerComponent;
                                array.push(object);
                            }else{
                                array.push(object);
                            }


                        }



                        
                        //-----------------------------------
                        // Additional Edits
                        //-----------------------------------

                        switch(itemId)
                        {
                        case'BODY':


                            if(eff.data.item.matchingComponents 
                            && eff.data.item.matchingComponents !== undefined
                            )
                            {
                                for(keyname in eff.data.item.matchingComponents)
                                {
                                    // if(object.slide.call.toUpperCase()===keyname.toUpperCase() 
                                    // )
                                    // {
                                    //     object.slide.component = eff.data.item.matchingComponents[keyname];
                                    //     array.push(object);
                
                                    // }


                              
                                    for(i=0; i < array.length; i++)
                                    {
                                       
    

                                        if(array[i].slide.call.toUpperCase()===keyname.toUpperCase())
                                        {

                                            console.log("BODY ADJ..... "+keyname.toUpperCase()+" "+array[i].slide.call.toUpperCase());

                                            console.log(JSON.stringify(array[i].slide.component,null,2));
                                            console.log(JSON.stringify(eff.data.item.matchingComponents[keyname],null,2));

                                            array[i].slide.component = eff.data.item.matchingComponents[keyname];


                                        }

                                        // if(array[i].slide.call.toUpperCase()==='ROLLBAR')
                                        // {
                                        //     array[i].slide.component = '';
                                        // }

                       


                           
                                    }
    
                                }

                            }

                        break;
                        case'HARDTOP':
                        case'SOFTTOP':
                        case'ROLLBAR':

                            switch(itemId)
                            {
                            case'HARDTOP':

                                for(i=0; i < array.length; i++)
                                {
                                    
                                    if(array[i].slide.call.toUpperCase()==='SOFTTOP')
                                    {
                                        array[i].slide.component = '';
                                    }
                                    if(array[i].slide.call.toUpperCase()==='ROLLBAR')
                                    {
                                        array[i].slide.component = '';
                                    }
        
                                }

                            break;
                            case'SOFTTOP':


                                for(i=0; i < array.length; i++)
                                {
                                    
                                    if(array[i].slide.call.toUpperCase()==='HARDTOP')
                                    {
                                        array[i].slide.component = '';
                                    }
                                    if(array[i].slide.call.toUpperCase()==='ROLLBAR')
                                    {
                                        array[i].slide.component = '';
                                    }
        
                                }

                            break;
                            case'ROLLBAR':


                                for(i=0; i < array.length; i++)
                                {
                                    
                                    if(array[i].slide.call.toUpperCase()==='HARDTOP')
                                    {
                                        array[i].slide.component = '';
                                    }
                                    if(array[i].slide.call.toUpperCase()==='SOFTTOP')
                                    {
                                        array[i].slide.component = '';
                                    }
        
                                }

                            break;
                            default:
                            }


                        break;
                        // case'TIRES':

                        //     for(i=0; i < array.length; i++)
                        //     {
                        
                        //         if(array[i].slide.call.toUpperCase()===keyname.toUpperCase())
                        //         {
                        //             array[i].slide.component = eff.data.item.matchingComponents[keyname];
                        //         }

                        //     }

                        // break;
                        default:
                        }








                        //console.log(JSON.stringify(array,null,2));
                        props.handle({

                            call:"setLayers",
                            data:array

                        });



                    }//====


                }
                else
                {



                    catsObject[eff.data.item.subCategory] = subA[eff.data.item.subCategory];


                    newCall = 'A';
                    newCategory = eff.data.item.subCategory;

                    newName = newCategory.replace(/_/gi," ");
                    newName = LibString.CapitalizeWords(newName);

                    newItem = eff.data.item;


                    //console.log(JSON.stringify(hndl.data.item,null,2));
                    //console.log(JSON.stringify(catsObject,null,2));
                    // alert('checking catsObject')


                    prevCall = false;
                    prevCategory = false;
                    prevItem = false;
                    prevName = false;



                    scroller.current.scrollTop = 0;



                }


            break;
            default:
            }

            console.log("" 

                +"\nnewCall: "+newCall
                +"\nnewCategory: "+newCategory
                +"\nnewName: "+newName
                +"\nnewItem: "+newItem

                +"\nprevCall: "+prevCall
                +"\nprevCategory: "+prevCategory
                +"\nprevName: "+prevName
                +"\nprevItem: "+JSON.stringify(prevItem)

            );

            setSubCategoryChoice({

                call        :newCall,
                category    :newCategory,
                name        :newName,
                item        :newItem,

                prevCall    :prevCall,
                prevCategory:prevCategory,
                prevName    :prevName,
                prevItem    :prevItem,
                prevCats    :prevCats

            });
            //alert(JSON.stringify(catsObject,null,2));
            setCategories(catsObject);
            setResetCategories(false);

    



            // var catsObject = {};

            // //------------------------------------------
            // // ControlData - convert to categories
            // //------------------------------------------
            // //var catsOrder = ControlData.order;
            // var main = ControlData.main;
            // var subA = ControlData.subA;
            // var subB = ControlData.subB;
            // var subC = ControlData.subC;

            // if(subCategoryChoice.call 
            // && subCategoryChoice.category
            // )
            // {

            //     //set the array
            //     if(catsObject[subCategoryChoice.category] 
            //     && Object.prototype.toString.call(catsObject[subCategoryChoice.category]) === '[object Array]'
            //     )
            //     {
            //     }else{
            //         catsObject[subCategoryChoice.category] = [];
            //     }
                

            //     // fill the array
            //     switch(subCategoryChoice.call)
            //     {
            //     case'A':

            //         catsObject[subCategoryChoice.category] = subA[subCategoryChoice.category];
            //     break;
            //     case'B':

            //         catsObject[subCategoryChoice.category] = subB[subCategoryChoice.category];
            //     break;
            //     case'C':

            //         catsObject[subCategoryChoice.category] = subC[subCategoryChoice.category];
            //     break;
            //     default:
            //     }



            // }
            // else// main categories
            // {

            //     // set the array
            //     for(i=0; i < main.length; i++)
            //     {
            //         object = main[i];
            //         if(catsObject[object.category] 
            //         && Object.prototype.toString.call(catsObject[object.category]) === '[object Array]'
            //         )
            //         {
            //         }else{
            //             catsObject[object.category] = [];
            //         }
                
            //     }

            //     // fill the array
            //     for(object of main)
            //     { 
            //         catsObject[object.category].push(object)
            //     }


            //     setSubCategoryChoice({

            //         subCall:false,
            //         subCategory:false
            
            //     });

            // }


            //console.log("CONTROL DATA: "+JSON.stringify(catsObject,null,2));
            //setCategories(catsObject);




            //------------------------------------------
            // Layer Info - convert to categories
            //------------------------------------------

            // var comp;
            // var cpButtons = [];
            // var listKeys = Object.keys(list);

            // var regxCall;
            // var compArr;
            // var compStr;
            // var compCat;

            // var regxCat;



            // var found;
            // for(i=0; i < info.length; i++)
            // {
            //     object = info[i];
            //     object['zIndex'] = i+1;
            //     array.push(object);

            //     regxCall = new RegExp(object.slide.call,"gi");

            //     found = false;
            //     for(comp of listKeys)
            //     {
            //         compArr = comp.split("_");
            //         compCat = compArr[0];
            //         compStr = compArr.join(' ');

            //         regxCat = new RegExp(compCat,"gi");
            //         compStr = compStr.replace(regxCat,"");
            //         compStr = LibString.CapitalizeWords(compStr);

            //         if(compCat.match(regxCall))
            //         {
            //             cpButtons.push({

            //                 call        :object.slide.call,
            //                 name        :compStr,
            //                 component   :comp

            //             });

            //             found = true;
            //             //break;

            //         }

            //     } 
            //     if(!found)
            //     {

            //         compArr = object.slide.component.split("_");
            //         compCat = compArr[0];
            //         compStr = compArr.join(' ');

            //         regxCat = new RegExp(compCat,"gi");
            //         compStr = compStr.replace(regxCat,"");
            //         compStr = LibString.CapitalizeWords(compStr);

            //         cpButtons.push({

            //             call        :object.slide.call,
            //             name        :compStr,
            //             component   :object.slide.component

            //         });

            //     }
                
            // }
            // //setControlPanelButtons(cpButtons);




            // for(i=0; i < info.length; i++)
            // {
            //     object = info[i];
            //     if(catsObject[object.slide.call] 
            //     && Object.prototype.toString.call(catsObject[object.slide.call]) === '[object Array]'
            //     )
            //     {
            //     }else{
            //         catsObject[object.slide.call] = [];
            //     }
            
            // }
            // //console.log(JSON.stringify(catsObject,null,2));

            // var catKey;
            // var regxCatKey; 
            // var catKeys = Object.keys(catsObject);
            // for(catKey of catKeys)
            // { 
            //     regxCatKey = new RegExp(catKey.toUpperCase()+"_","gi");
            //     for(object of cpButtons)
            //     {
            //         if(object.component.match(regxCatKey))
            //         {
            //             catsObject[catKey].push(object)
            //         }
            //     }
            // }
            // //console.log(JSON.stringify(catsObject,null,2));




            // var orderCatsObject = {};
            // for(i=0; i < catsOrder.length; i++)
            // {
            //     for(catKey in catsObject)
            //     {
            //         if(catsOrder[i].toUpperCase() === catKey.toUpperCase())
            //         {

            //             orderCatsObject[catKey] = catsObject[catKey];

            //         }
            //     }

            // }
            // console.log(JSON.stringify(orderCatsObject,null,2));
            //setCategories(orderCatsObject);
            //setCategories(catsObject);




            //---------------------------
            // MAP ARRAY EXAMPLES
            //---------------------------

            // Object.keys(categories).map(function(keyName, keyIndex) {
            //     // use keyName to get current key's name
            //     // and a[keyName] to get its value

            //     console.log(keyName+"-"+keyIndex);

            //     categories[keyName].map((item, i) => (
                    
            //         console.log(item.name)
                    
            //      ))

            // })



            // Object.keys(cats).map(keyName => (
            //     //<option value={key}>{tifs[key]}</option>

            //     categories[keyName].map((item, i) => (
                    
            //         console.log(item.name)
                    
            //      ))

            // ))




        }


        if(!isLoaded)
        {

            effectCategories({

                call: 'setCategories',
                data: {
                    call:'main'
                }
                
            },refControlPanelScroller);

            //setMobiThreshold(props.mobiThreshold);

        }// isLoaded


        if(resetCategories)
        {
            effectCategories(resetCategories,refControlPanelScroller);
        }




    },
    [

        props,
        isLoaded,
        layers,

        width,mobiThreshold,

        categories,
        subCategoryChoice,
        resetCategories,

        refControlPanelScroller


    ])









    //##############################################################
    //##############################################################
    //##############################################################

    const handle = (hndl) => {

        var i;
        var num;
        var object;
        var array;
        var iso;

        switch(hndl.call)
        {
        case'setLayers':

            //setLayers(hndl.data);
            props.handle({
                call:'setLayers',
                data:hndl.data
            });
        break;
        case'setTopLayer':

            //setTopLayer(hndl.data);
            props.handle({
                call:'setTopLayer',
                data:hndl.data
            });
        break;
        // case'choice':

        //     array = [];
        //     for(i=0; i < layers.length; i++)
        //     {
        //         object = layers[i];
        //         if(object.slide.call===hndl.item.call)
        //         {
        //             object.slide.component = hndl.item.component;
        //             array.push(object);
        //         }else{
        //             array.push(object);
        //         }
        //     }
        //     console.log(JSON.stringify(array,null,2));
        //     props.handle({

        //         call:"setLayers",
        //         data:array

        //     });
        // break;
        case'changeLayer':

            iso = [];
            array = [];
            for(i=0; i < layers.length; i++)
            {
                object = layers[i];
                if(i===0)
                {
                    num = parseInt(layers.length) + 1;
                    object['zIndex'] = num;
                    iso.push(object);
                    props.handle({
    
                        call:"setTopLayer",
                        data:object
            
                    });
                }else{
                    num = i;
                    object['zIndex'] = num;
                    array.push(object);
                }
                
    
            }
            array = array.concat(iso);
            //console.log(JSON.stringify(array,null,2));
            props.handle({
    
                call:"setLayers",
                data:array
    
            });
        break;
        case'scrollMove':

      
            var scrollerId = refControlPanelScroller.current.id;
            var scroller = document.getElementById(scrollerId);


            if(scroller)
            {

                // var scrollerPos = refControlPanelScroller.current.scrollLeft;
                // console.log("scrollMove: "+hndl.direction

                //     +"\nscrollerId: "+scrollerId
                //     +"\nscrollerPos: "+scrollerPos
        
                // );


                var okay=false;
                var pos;
                var scrollDistance;
                var currentScrollPos;

                var tileWInt = 10;
                var tileHInt = 10;
                var offsetDif = 20;

                //console.log("tileRefs.length: "+tileRefs.length);
                if(tileRefs.length > 0)
                {
                    //console.log("tileRef ID: "+tileRefs[0].id);
                    //console.log("tileRefs document: "+document.getElementById(tileRefs[0].id));
                    if(document.getElementById(tileRefs[0].id)
                    )
                    {
                        //console.log("tileRef ID: "+tileRefs[0].id);
                        tileWInt = document.getElementById(tileRefs[0].id).offsetWidth - offsetDif;
                        tileHInt = document.getElementById(tileRefs[0].id).offsetHeight - offsetDif;

                        //okay=true;
                        if(LibElem.IsScrollable(scroller).horizontal) okay=true;
                    }

                }
                //console.log("ID: "+tileRefs[0].id+"\nTILE W: "+tileWInt+"\nTILE:H:"+tileHInt);

                //alert(JSON.stringify(hndl,null,2) + " ---- " + okay);

                if(okay)
                {

                    switch(hndl.direction)
                    {
                    case'left':
                    case'right':

                        //alert(hndl.direction);

                        currentScrollPos = refControlPanelScroller.current.scrollLeft;
                        scrollDistance = refControlPanelScroller.current.offsetWidth;
                    
                        //if(hndl.direction === 'left') pos = -pos; returns to o position

                        if(hndl.direction === 'left')
                        {
                            pos = (currentScrollPos - scrollDistance) + tileWInt;
                        }else{
                            pos = (currentScrollPos + scrollDistance) - tileWInt;
                        }

                        //alert(pos);

                        scroller.scrollTo({
                            top: 0, 
                            left: pos, 
                            behavior: 'smooth' 
                        });

                    break;
                    case'up':
                    case'down':


                        currentScrollPos = refControlPanelScroller.current.scrollTop;
                        scrollDistance = refControlPanelScroller.current.offsetHeight;

                        //if(hndl.direction === 'up') pos = -pos; returns to o position
                    
                        if(hndl.direction === 'up')
                        {
                            pos = (currentScrollPos - scrollDistance) + tileHInt;
                        }else{
                            pos = (currentScrollPos + scrollDistance) - tileHInt;
                        }

                        scroller.scrollTo({
                            top: pos, 
                            left: 0, 
                            behavior: 'smooth' 
                        });

                    break;
                    default:
                    }



                }//===



            }





            //FOR REFERENCE
 
            //scrollbox.style.scrollBehavior = 'smooth'; //CSS ; scroll-behavior: smooth;
            //scrollbox.scrollTop = hndl.scrollTop;
            // scroller.scrollTo({
            //     top: 0, 
            //     left: pos, 
            //     behavior: 'smooth' 
            // });
            //refA.current.scrollIntoView({behavior: 'smooth'});

            /*
            if(!'scrollBehavior' in document.documentElement.style) 
            {
                //var scrollup_onclick = "javascript:elemlib.ScrollToTop('scrollbox','no','0');";
            }else{
                //var scrollup_onclick = "javascript:elemlib.ScrollToTop('scrollbox','supported','0');";
            }//==
            */




        break;
        case'setCategories':

            setResetCategories(hndl);
        break;
        default:
        }

        //HandleControlPanel({call:"showhide"});

    };








    const handleOverOut = (hndl) => {

        //alert(JSON.stringify(hndl));

        //console.log(JSON.stringify(hndl,null,2));

        if(hndl.id.match(/arrowButton/gi))
        {

            //var btn;
            var arrowBg;
            var arrow1;
            //var arrow2;

            //btn = document.getElementById(hndl.id);
            //btn.style.borderColor = hndl.borderColor;

            arrowBg = document.getElementById(hndl.id+"_bg");
            arrowBg.style.backgroundColor = hndl.bgColor;


            if(hndl.id.match(/arrowButtonDown/gi))
            {
                arrow1 = document.getElementById(hndl.id+"_arrow1");
                arrow1.style.borderTopColor = hndl.arrowColor;

                //arrow2 = document.getElementById(hndl.id+"_arrow2");
                //arrow2.style.borderTopColor = hndl.arrowColor;

            }
            else
            if(hndl.id.match(/arrowButtonUp/gi))
            {
    
                arrow1 = document.getElementById(hndl.id+"_arrow1");
                arrow1.style.borderBottomColor = hndl.arrowColor;

                //arrow2 = document.getElementById(hndl.id+"_arrow2");
                //arrow2.style.borderBottomColor = hndl.arrowColor;
            }
            else
            if(hndl.id.match(/arrowButtonLeft/gi))
            {
    
                arrow1 = document.getElementById(hndl.id+"_arrow1");
                arrow1.style.borderRightColor = hndl.arrowColor;

                //arrow2 = document.getElementById(hndl.id+"_arrow2");
                //arrow2.style.borderBottomColor = hndl.arrowColor;
            }
            else
            if(hndl.id.match(/arrowButtonRight/gi))
            {
    
                arrow1 = document.getElementById(hndl.id+"_arrow1");
                arrow1.style.borderLeftColor = hndl.arrowColor;

                //arrow2 = document.getElementById(hndl.id+"_arrow2");
                //arrow2.style.borderBottomColor = hndl.arrowColor;
            }
            else
            {

                arrow1 = document.getElementById(hndl.id+"_arrow1");
                arrow1.style.borderTopColor = hndl.arrowColor;

                //arrow2 = document.getElementById(hndl.id+"_arrow2");
                //arrow2.style.borderTopColor = hndl.arrowColor;

            }



        
        }



    }












    //##############################################################
    //##############################################################
    //##############################################################

	return(


		<div
		style={{

            backgroundColor:"transparent",
			width:"100%",
			height:"100%",
            position:"position",
            float:"left",
            overflow:"hidden"

		}}
		>



            {width < mobiThreshold
            ?

                <div>

                    <MobiControlPanel
                    ref={refControlPanel}
                    id={"controlPanel"}
                    //backgroundColor={"darkblue"}
                    zIndex={controlPanelZ}
                    >
                        <MobiControlPanelTop
                        ref={refControlPanelTop}
                        id={"controlPanelTop"}
                        >
                            <ArrowButton
                            id={"arrowButtonLeft"}
                            > 

                                <div className={"arrowbox-alfa"}></div>
                                <div id={"arrowButtonLeft_bg"} className={"arrowbox-bg"}></div>
                                <div className={"arrowbox-mask"}

                                    onClick={() => handle({

                                        call        :"scrollMove",
                                        direction   :"left"
                                        
                                    })}
                            
                                    onMouseEnter={() => handleOverOut({

                                        id          :"arrowButtonLeft",
                                        call        :"over",
                                        bgColor     :"white",
                                        borderColor :"white",
                                        arrowColor  :"#07a2e3"

                                    })}
                            
                                    onMouseOut={() => handleOverOut({

                                        id          :"arrowButtonLeft",
                                        call        :"out",
                                        bgColor     :"white",
                                        borderColor :"white",
                                        arrowColor  :"#1a1926"
                    
                                    })}

                                ></div>

                                <div className={"arrowbox-base"}>

                                    <div id={"arrowButtonLeft_arrow1"} className={"arrow-left"}></div>
                                
                                </div>


                            </ArrowButton>

                        </ MobiControlPanelTop>



                        <MobiControlPanelScroller
                        ref={refControlPanelScroller}
                        id={'controlPanelScroller'}
                        >
                            {Object.keys(categories).map(keyName => (
                                
                                categories[keyName].map((item, i) => (
                            
                                    <MobiControlPanelTile
                                    ref={setTileRef}         
                                    key={keyName+"-"+i}
                                    //id={"controlPanelTile-"+keyName+"-"+i}
                                    id={"controlPanelTile-"+i}
                                    //backgroundColor={item.backgroundColor}
                                    //float={"left"}
                                    onClick={(v) => handle({
                                        call:'setCategories',
                                        data:{
                                            call:'sub',
                                            indx:i,
                                            item:item
                                        }
                                 
                                    })}
                                    >
                                        <div className="Grfx">

                                            <GRFX_BUTTONS 
                                    
                                                call={item.thumbComponent}
                                            
                                            />
                                    
                                        </div>

                                        <div className="Text">

                                            {item.name}
                                        
                                        </div>
                                    
                                    </ MobiControlPanelTile>

                                ))

                            ))}
                        

                        </ MobiControlPanelScroller>



                        <MobiControlPanelBottom
                        ref={refControlPanelBottom}
                        id={"controlPanelBottom"}
                        >


                            <ArrowButton
                            id={"arrowButtonRight"}
                            > 

                                <div className={"arrowbox-alfa"}></div>
                                <div id={"arrowButtonRight_bg"} className={"arrowbox-bg"}></div>
                                <div className={"arrowbox-mask"}

                                    onClick={() => handle({

                                        call        :"scrollMove",
                                        direction   :"right"
                                        
                                    })}
                            
                                    onMouseEnter={() => handleOverOut({

                                        id          :"arrowButtonRight",
                                        call        :"over",
                                        bgColor     :"white",
                                        borderColor :"white",
                                        arrowColor  :"#07a2e3"

                                    })}
                            
                                    onMouseOut={() => handleOverOut({

                                        id          :"arrowButtonRight",
                                        call        :"out",
                                        bgColor     :"white",
                                        borderColor :"white",
                                        arrowColor  :"#1a1926"
                    
                                    })}

                                ></div>

                                <div className={"arrowbox-base"}>

                                    <div id={"arrowButtonRight_arrow1"} className={"arrow-right"}></div>
                                
                                </div>


                            </ArrowButton>


                        </ MobiControlPanelBottom>


                    </ MobiControlPanel>





                    <MobiControls
                    ref={refControls}
                    id={"controls"}
                    backgroundColor={"#1a1926"}
                    zIndex={controlsZ}
                    >


                        {/* <MobiControlsButton
                        id={"changeMobiControlsButton"}
                        //backgroundColor={"gray"}
                        float={"left"}
                        onClick={(v) => handle({

                            call:'showhide',
                            section:'body'

                        })}
                        >
                            <div className="Text">

                                CTRLS

                            </div>
                        
                        </MobiControlsButton> */}





                        {
                        subCategoryChoice.call 
                        ?

                            <div>
                            {
                            subCategoryChoice.prevItem
                            ?

                                <MobiControlsButton
                                id={"changeMobiControlsButton"}
                                //backgroundColor={"gray"}
                                float={"left"}
                                onClick={(v) => handle({
                                    call:'setCategories',
                                    data:{
                                        call:'sub',
                                        indx:0,
                                        item:subCategoryChoice.prevItem,
                                        goback:true
                                    
                                    }
                            
                                })}
                                >
                                    <div className="Text">

                                        {"< Back  | "+subCategoryChoice.prevCategory}

                                    </div>

                                </MobiControlsButton> 

                            :

                                <MobiControlsButton
                                id={"changeMobiControlsButton"}
                                //backgroundColor={"gray"}
                                float={"left"}
                                onClick={(v) => handle({
                                    call:'setCategories',
                                    data:{
                                        call:'main'

                                    }
                            
                                })}
                                >

                                    {
                                    subCategoryChoice.prevCategory
                                    ?
                                    
                                        <div className="Text">

                                            {"< Back  | "+subCategoryChoice.prevCategory}

                                        </div>


                                    :

                                        <div className="Text">

                                            {"< Back"}

                                        </div>

                                    }

                                
                                </MobiControlsButton> 

                            }
                            </div>

                            
                        :null
                        }

                    </ MobiControls>


                </div>



            :

            

                <div>


                    <ControlPanel
                    ref={refControlPanel}
                    id={"controlPanel"}
                    //backgroundColor={"darkblue"}
                    zIndex={controlPanelZ}
                    >
                        <ControlPanelWing
                        ref={refControlPanelTop}
                        id={"controlPanelTop"}
                        >
                            {subCategoryChoice.call 
                            ?

                                <div>

                                    <div>
                                    {
                                    subCategoryChoice.prevItem
                                    ?

                                        <div 
                                        className="XButton"
                                        onClick={(v) => handle({
                                            call:'setCategories',
                                            data:{
                                                call:'sub',
                                                indx:0,
                                                item:subCategoryChoice.prevItem,
                                                goback:true
                                           
                                            }
                                    
                                        })}
                                        >

                                            {"< BACK"}

                                        </div>

                                    :


                                        <div 
                                        className="XButton"
                                        onClick={(v) => handle({
                                            call:'setCategories',
                                            data:{
                                                call:'main'
                                        
                                            }
                                    
                                        })}
                                        >

                                            {"< BACK"}

                                        </div>


                                    }
                                    </div>




                                    <div 
                                    className="Title"
                                    >

                                        {subCategoryChoice.name}

                                    </div>



                                </div>


                            :null
                            }

                        </ControlPanelWing>



                        <ControlPanelScroller
                        ref={refControlPanelScroller}
                        id={'controlPanelScroller'}
                        height={scrollerHeight}
                        >

                            {Object.keys(categories).map(keyName => (
                                //<option value={key}>{tifs[key]}</option>
                                <div key={keyName}>


                                    <ControlPanelGroup
                                    id={"controlPanelGroup-"+keyName}
                                    >

                                        {categories[keyName].map((item, i) => (
                                        <div key={keyName+"-"+i}>

                                            <ControlPanelTile
                                            ref={setTileRef}  
                                            //id={"controlPanelTile-"+keyName+"-"+i}
                                            id={"controlPanelTile-"+i}
                                            //backgroundColor={item.backgroundColor}
                                            //float={"left"}
                                            onClick={(v) => handle({
                                                call:'setCategories',
                                                data:{
                                                    call:'sub',
                                                    indx:i,
                                                    item:item
                                                }
                                         
                                            })}
                                            >
                                                <div className="Grfx">

                                                    {/* {item.component} */}

                                                    <GRFX_BUTTONS
                                
                                                        call={item.thumbComponent}
                                                    
                                                    />

                                                </div>

                                                <div className="Text">

                                                    {/* {keyName+ " " +item.name} */}
                                                    {item.name}

                                                </div>
                                            
                                            </ControlPanelTile>

                                        </div>
                                        ))}

                                    </ControlPanelGroup>

                                </div>

                            ))}


                        </ControlPanelScroller>



                        <ControlPanelWing
                        ref={refControlPanelBottom}
                        id={"controlPanelBottom"}
                        >
                            {subCategoryChoice.call 
                            ?

                                <div>

                                    <div>
                                    {
                                    subCategoryChoice.prevItem
                                    ?

                                        <div 
                                        className="XButton"
                                        onClick={(v) => handle({
                                            call:'setCategories',
                                            data:{
                                                call:'sub',
                                                indx:0,
                                                item:subCategoryChoice.prevItem,
                                                goback:true
                                           
                                            }
                                    
                                        })}
                                        >

                                            {"< BACK"}

                                        </div>

                                    :


                                        <div 
                                        className="XButton"
                                        onClick={(v) => handle({
                                            call:'setCategories',
                                            data:{
                                                call:'main'
                                        
                                            }
                                    
                                        })}
                                        >

                                            {"< BACK"}

                                        </div>


                                    }
                                    </div>



                                    <div 
                                    className="Title"
                                    >

                                        {subCategoryChoice.name}

                                    </div>

                                </div>


                            :null
                            }
                       


                        </ControlPanelWing>


                    </ControlPanel>





                    <Controls
                    ref={refControls}
                    id={"controls"}
                    backgroundColor={"blue"}
                    zIndex={controlsZ}
                    >

                        <ControlsButton
                        id={"changeButton"}
                        //backgroundColor={"gray"}
                        float={"left"}
                        onClick={(v) => handle({

                            call:'showhide',
                            section:'body'

                        })}
                        >
                            <div className="Text">

                                CTRLS

                            </div>
                        
                        </ControlsButton>





                    </Controls>

                </div>



            }




		</div>

	);//return
}
export default Index;