
import ComposeFields from './Compose/Fields';
import ComposeLabelFields from './Compose/LabelFields';

const FormComposer = (props) => {


    let R = [];

    //console.log("PROPS DATA: "+JSON.stringify(props.data,null,2));
  

    var keyname;

    //var key;
    //var value;

    var data; 
    var f;

    var object;
    var obj;

    var array;
    //var title;   
    //var group; 
    //var def;
    var val;


    console.log("COMPOSER DATA: "+JSON.stringify(props.data,null,2));
    console.log("COMPOSER STYL: "+JSON.stringify(props.styl,null,2));


    
    switch(Object.prototype.toString.call(props.data))
    {
    case'[object Array]':

        //console.log("Flyr/Form/FormComposer - Array");


        for(object of props.data)
        {
            //console.log(object.data);
            //R.push(object);


            switch(object['section'])
            {
            case'fields':




                /*
                //==========================================
                //fields
                //==========================================
                {

                    "section"   :"fields",
                    "data"      :[


                        //--------------------------------
                        //
                        //--------------------------------
                        {

                            "fieldtype"               	:"text",
                            "required"					:required_name,

                            "class"                   	:'',
                            "id"                      	:'name',
                            "hint"                    	:"Name",

                            "text"                     	:def_name,

                            "keyboardType"            	:"",
                            "autocorrect"             	:"false",
                            "autocapitalizationType"  	:"none",


                            //"secure"                  :true,
                            //"returnKeyType"           :"done"


                        },


                    ]

                    

                }

                */


                array = [];
                for(obj of object.data)
                {
                    switch(obj.fieldtype)
                    {
                    case'percent':

                        val = obj.text;
                        if(val !== '' 
                        && val !== undefined 
                        && !isNaN(val))
                        {
                            val = parseFloat(val).toFixed(1);
                        }else{
                            val = 0.0;
                        }
                        obj.text = val;

                    break;
                    case'price':

                        val = obj.text;
                        if(val !== '' 
                        && val !== undefined 
                        && !isNaN(val))
                        {
                            val = parseFloat(val).toFixed(2);
                        }else{
                            val = 0.0;
                        }
                        obj.text = val;

                    break;
                    default:
                    }

                    array.push(obj);

                }//==

            
                R.push({

                    section :object.section,
                    data    :array

                });


            break;
            case'labelfields':



                /*
                //--------------------------------
                //
                //--------------------------------

                {

                    label: {

                        "text"               	    :"Name"
                    },

                    field: {


                        "fieldtype"               	:"standard",
                        "required"					:true,

                        "class"                   	:'',
                        "id"                      	:'name',
                        "hint"                    	:"",

                        "text"                     	:"",



                        "keyboardType"            	:"",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",


                        //"secure"                  :true,
                        //"returnKeyType"           :"done"


                    },

                },
                */

                array = [];
                for(obj of object.data)
                {
                    switch(obj.field.fieldtype)
                    {
                    case'percent':

                        val = obj.field.text;
                        if(val !== '' 
                        && val !== undefined 
                        && !isNaN(val))
                        {
                            val = parseFloat(val).toFixed(1);
                        }else{
                            val = 0.0;
                        }
                        obj.field.text = val;

                    break;
                    case'price':

                        val = obj.field.text;
                        if(val !== '' 
                        && val !== undefined 
                        && !isNaN(val))
                        {
                            val = parseFloat(val).toFixed(2);
                        }else{
                            val = 0.0;
                        }
                        obj.field.text = val;

                    break;
                    default:
                    }

                    array.push(obj);

                }//==

                
                R.push({

                    section :object.section,
                    data    :array

                });



            break;
            //case'checkboxes':
            //case'radiobuttons':
            //break;
            //case'slider':
            //case'dualslider':
            default:

                R.push(object);
            }


        }// -- for 

    break;
    case'[object Object]':

        console.log("Flyr/Form/FormComposer - Object");


        switch(props.styl.fieldType)
        {
        case'field':


            for(keyname in props.data)
            {
                if(props.data[keyname].print === undefined 
                )
                {
                    switch(keyname)
                    {
                    case'name':

            
                        R.push({

                            section:"titleline",
                            data:{
        
                                text : props.data[keyname]
                            }
        
                        });
        

                    break;
                    case'value':
            
                        switch(Object.prototype.toString.call(props.data[keyname]))
                        {
                        case'[object Array]':
                        break;
                        case'[object Object]':

                            data = ComposeFields(props.data[keyname]);
                            //console.log(JSON.stringify(data,null,2));
                            for(f of data)
                            {
                                console.log(JSON.stringify(f,null,2));
                                R.push(f);
                            }
                            

                        break;
                        case'[object String]':
                        break;
                        default:
                        }
            
                    break;
                    default:
                    break;
                    }//==


                }else{


                    console.log("utils/Flyr/Form/FormComposer - has print");

                }


            }//for



        break;
        case'labelfield':


            for(keyname in props.data)
            {
                if(props.data[keyname].print === undefined 
                )
                {
                    switch(keyname)
                    {
                    case'name':

            
                        R.push({

                            section:"titleline",
                            data:{
        
                                text : props.data[keyname]
                            }
        
                        });
        

                    break;
                    case'value':
            
                        switch(Object.prototype.toString.call(props.data[keyname]))
                        {
                        case'[object Array]':
                        break;
                        case'[object Object]':

                            var labfields = ComposeLabelFields(props.data[keyname]);

                            R.push({

                                section :'labelfields',
                                data    :labfields

                            });
            
                        break;
                        case'[object String]':
                        break;
                        default:
                        }
            
                    break;
                    default:
                    break;
                    }//==


                }else{


                    console.log("utils/Flyr/Form/FormComposer - has print");

                }


            }//for

        break;
        default:

            console.log(":::::::::::\n"+JSON.stringify(props.data,null,2));

            R = props.data;

        }




    break;
    case'[object String]':

        console.log("Flyr/Form/FormComposer - String");
   
    break;
    default:
    }


    //console.log(JSON.stringify(R,null,2));
    return R;


};

export default FormComposer;
