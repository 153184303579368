export const BUTTON_LOGO_95 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    {/*Yellow*/}
    <polygon 
        fill="rgb(255,242,0)" 
        points="90,72 630,72 630,432 90,432 90,72 	"
    />
    {/*Blue*/}
    <polygon 
        fill="rgb(0,149,218)" 
        points="472.816,171.276 495.678,187.951 395.665,207.395 411.612,230.38 319.445,242.743 336.291,268.438 206.313,302.848 241.895,316.943 165.181,348.559 191.358,365.578 124.908,384.462 176.541,403.111 90,429.328 90,432 630,432 630,72 597.961,72 557.329,83.715 565.166,104.168 496.034,120.363 538.748,150.121 472.816,171.276 	"
    />
    {/*Panel*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M274.3,126.301h171.4c9.737,0,17.705,7.868,17.705,17.481v216.437 c0,9.613-7.969,17.48-17.705,17.48H274.3c-9.736,0-17.705-7.866-17.705-17.48V143.782 C256.595,134.168,264.563,126.301,274.3,126.301L274.3,126.301z"
    />
    {/*95*/}
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M323.305,259.578c-5.951,7.49-12.617,13.605-22.666,13.605	c-26.688,0-25.848-23.266-25.848-42.477v-44.395c0-31.979,1.773-45.885,40.475-45.885c38.721,0,40.669,13.828,40.669,45.885	v130.484c0,31.893-4.56,46.775-40.591,46.775c-36.247,0-40.794-14.617-40.794-46.775v-22.819h31.296v19.999	c0,6.654-0.236,12.607,8.315,12.607c8.551,0,9.013-6.125,9.026-12.632L323.305,259.578L323.305,259.578z M323.438,223.307v-30.648
		c0-6.159-0.52-10.267-1.558-12.269c-1.07-2.003-3.178-3.005-6.422-3.005c-3.307,0-5.48,1.002-6.55,2.955 c-1.07,1.952-1.622,6.059-1.622,12.318v30.648c0,6.158,0.552,10.265,1.653,12.268c1.104,2.004,3.276,3.006,6.519,3.006	c3.244,0,5.352-1.002,6.422-3.006C322.919,233.571,323.438,229.465,323.438,223.307L323.438,223.307z M445.291,317.201 c0,31.616-4.559,46.371-40.591,46.371c-36.247,0-40.794-14.492-40.794-46.371v-36.765h31.297v33.968	c0,6.598-0.236,12.499,8.314,12.499s8.517-6.048,8.517-12.499v-60.195c0-6.984-1.497-12.35-8.923-12.35	c-7.425,0-9.191,4.647-9.191,12.139h-28.526V142.35h77.49v30.78h-47.478v47.081c5.349-8.568,12.417-13.966,22.881-13.966 c30.938,0,27.004,27.82,27.004,49.079V317.201L445.291,317.201z"
    />

    

    

		</svg>
		</div>

	);//return
}