
import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



const Index = (props) => {
   

    // -- ref -- //
    //const vidRef = useRef(null)


    // -- state -- //
  
    const [temp, setTemp] = useState(0)

    useEffect(() => {


        console.log(temp);
        setTemp(100);


    },[

        props,
        temp

    ])




    return (


        <div
        style={{

            backgroundColor :"lightyellow",
            width           :"100%",
            height          :"500px",
            margin          : "10px 0 0 0"

        }}
        >


            Canvas


        </div>

    
    );



}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/