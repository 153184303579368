export const BUTTON_LOGO_PIRELLI = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.278,224.748h347.415c2.852-0.368,4.874-2.655,4.878-5.533	c0.009-2.934-2.123-5.295-5.042-5.585H175.278V224.748L175.278,224.748z M175.354,302.422v-64.814h402.296 c9.415,0,16.167-8.35,16.349-17.765c0.186-9.626-6.74-18.472-16.365-18.472H125.998v101.051H175.354L175.354,302.422z M272.543,261.688v12.86h17.203c2.594-1.47,3.84-3.647,3.8-6.626c-0.036-2.78-1.129-4.739-3.478-6.234H272.543L272.543,261.688z M243.725,302.629v-52.534h65.951c7.037,3.802,10.525,9.448,10.788,17.436c0.253,7.911-2.771,13.687-9.423,17.971l11.435,16.913 h-33.729l-7.951-16.72h-8.63v16.935H243.725L243.725,302.629z M422.432,249.934h27.461v41.92h29.188v10.645h-56.649V249.934 L422.432,249.934z M490.426,249.934h27.457v41.92h29.192v10.645h-56.649V249.934L490.426,249.934z M195.511,250.047h27.519v52.405 h-27.519V250.047L195.511,250.047z M339.154,250.221h61.161v10.944h-33.675v9.39h33.608v11.146H366.84v9.748h36.033v10.986h-63.719	V250.221L339.154,250.221z M557.576,250.047h27.517v52.405h-27.517V250.047L557.576,250.047z"
    />
    





    

		</svg>
		</div>

	);//return
}