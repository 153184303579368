export const BUTTON_LOGO_CAMEL = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    {/*Yellow*/}
    <polygon 
        fill="rgb(253,185,19)" 
        points="90,72 630,72 630,432 90,432 90,72 	"
    />
    {/*Yellow HL*/}
    <polygon 
        fill="rgb(255,203,5)" 
        points="90,72 630,72 90,432 90,72 	"
    />
    {/*Camel*/}
    <path 
        fill="rgb(42,42,134)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540.535,272.234v-48.514c0-4.85,2.219-8.077,5.127-10.336	c-56.351,0,22.047,0-32.906,0c2.308,2.392,3.428,5.486,3.428,10.336v54.884c0,4.841-2.223,8.071-5.13,10.328h62.248	c32.749,0,21.962-40.323,0.053-28.663c1.952,3.209,1.241,9.473-0.97,11.965H540.535L540.535,272.234z M448.077,259.473 c0,11.635,13.227,17.23,23.056,17.23c6.676,0,14.784-2.447,24.151-11.643l7.882,17.538c-12.678,6.335-24.838,8.522-38.077,8.523
		c-18.08,0-44.602-8.892-44.602-38.57c0-26.946,21.343-40.699,42.319-40.699c14.007,0,37.22,2.86,43.009,2.861l-13.736,22.684 c-10.56-10.551-37.998-14.386-44.002,8.01l24.215,0.001c3.396-0.001,7.461-0.879,9.228-2.075v18.214 c-1.767-1.197-5.832-2.074-9.228-2.076L448.077,259.473L448.077,259.473z M203.603,212.617c-2.63,0.699-4.993,2.096-7.889,2.096 c-4.728,0-12.182-2.86-24.597-2.86c-59.177,0-59.302,80.552-5.5,80.552c17.547,0,42.16-5.453,42.16-24.545 c0-9.699-9.605-18.713-25.423-9.893c4.007,4.518,4.912,16.289-12.892,16.289c-28.148,0-25.571-73.043,39.352-31.17L203.603,212.617	L203.603,212.617z M250.815,267.859c-9.147,21.074-22.848,22.416-37.036,22.336v-21.158c20.86-1.178,23.715-23.71,38.966-39.91
		c-12.19,0-18.047,0-24.778,1.688v-19.22c0,0,6.754,1.789,15.117,1.789h65.028c-2.905,2.26-5.128,5.488-5.128,10.332v54.887 c0,4.842,2.223,8.068,5.128,10.33h-31.928c2.905-2.262,3.565-5.488,3.565-10.33v-10.744H250.815L250.815,267.859z M263.309,249.146 h16.441v-17.185c0-1.688-1.288-2.835-3.766-0.1C272.075,236.176,265.938,242.379,263.309,249.146L263.309,249.146z M363.783,275.616l23.179-28.104v31.092c0,4.842-0.198,8.068-3.102,10.33h32.987c-2.905-2.262-5.128-4.475-5.128-9.317v-55.899 c0-4.844,2.223-8.072,5.128-10.332h-38.231c2.327,3.404,1.114,8.376,0.146,9.989l-12.641,17.218l-13.942-17.218 c-0.968-1.613-2.182-6.585,0.146-9.989h-37.219c2.905,2.26,5.128,5.488,5.128,10.332v54.887c0,4.842-2.223,8.068-5.128,10.33	h27.404c-2.904-2.262-3.103-5.488-3.103-10.33v-33.09L363.783,275.616L363.783,275.616z"
    />

    

    

		</svg>
		</div>

	);//return
}