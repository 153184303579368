export const BUTTON_LOGO_HONDA_RA300 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.199,360.481V236.118h24.368c13.474,0,23.361,2.514,29.665,7.538c6.302,5.025,9.453,12.864,9.453,23.512	c0,7.933-1.521,14.384-4.564,19.355c-3.042,4.971-7.715,8.652-14.017,11.042c5.542,1.631,9.547,4.401,12.02,8.313 c2.472,3.912,3.979,10.268,4.523,19.069c0.163,2.608,0.326,6.249,0.489,10.921c0.489,13.039,2.445,21.244,5.868,24.612h-12.061 c-2.01-2.391-3.26-9.996-3.749-22.819c-0.163-3.531-0.3-6.276-0.408-8.231c-0.543-11.137-2.31-18.404-5.297-21.8 c-2.989-3.395-8.394-5.094-16.217-5.094h-19.559v57.944H168.199z M178.713,293.41h10.676c11.572,0,19.789-1.942,24.652-5.828 c4.862-3.883,7.294-10.28,7.294-19.191c0-8.04-2.31-13.896-6.927-17.563c-4.618-3.668-12.008-5.502-22.167-5.502h-13.528V293.41z M244.48,360.481l33.494-124.363h12.225l33.169,124.363h-10.514l-10.513-39.038h-37.488l-10.676,39.038H244.48z M266.646,311.909	h33.576l-16.707-62.1L266.646,311.909z M334.37,327.638h10.024c0.162,8.042,2.009,14.331,5.542,18.867 c3.531,4.536,8.366,6.805,14.506,6.805c6.357,0,11.451-2.391,15.281-7.172c3.83-4.78,5.745-11.192,5.745-19.232 c0-9.019-2.133-15.661-6.397-19.927c-4.266-4.265-10.907-6.397-19.926-6.397c-1.413,0-2.485,0.015-3.218,0.041 c-0.734,0.028-1.4,0.041-1.997,0.041v-9.129h0.652c9.67,0,16.76-2.063,21.271-6.192c4.509-4.128,6.764-10.621,6.764-19.478 c0-6.683-1.576-11.885-4.726-15.606c-3.152-3.721-7.526-5.583-13.121-5.583c-5.542,0-9.849,2.011-12.918,6.03 c-3.07,4.021-4.604,9.672-4.604,16.951v0.489h-10.024c0.272-10.377,2.784-18.417,7.539-24.123 c4.754-5.704,11.314-8.557,19.681-8.557c8.53,0,15.362,2.771,20.497,8.313c5.135,5.542,7.702,12.904,7.702,22.086	c0,6.899-1.4,12.836-4.197,17.807c-2.799,4.972-6.915,8.761-12.347,11.369c6.846,2.336,11.898,6.085,15.158,11.246 c3.261,5.162,4.89,11.927,4.89,20.293c0,11.245-2.839,20.103-8.516,26.567c-5.678,6.466-13.461,9.697-23.349,9.697
		c-9.291,0-16.572-3.109-21.841-9.331C337.167,347.292,334.478,338.668,334.37,327.638z M412.116,299.196 c0-12.551,0.462-22.506,1.387-29.868c0.923-7.362,2.362-13.025,4.319-16.992c2.771-5.542,6.261-9.711,10.472-12.51	c4.21-2.798,9.114-4.197,14.71-4.197c5.595,0,10.499,1.414,14.711,4.238c4.209,2.825,7.646,6.981,10.309,12.469	c2.01,4.129,3.49,9.997,4.44,17.604c0.951,7.606,1.428,17.357,1.428,29.257c0,12.334-0.463,22.262-1.387,29.786	c-0.924,7.525-2.417,13.298-4.481,17.318c-2.663,5.488-6.1,9.644-10.309,12.468c-4.212,2.825-9.116,4.238-14.711,4.238 c-5.596,0-10.5-1.413-14.71-4.238c-4.211-2.824-7.701-6.979-10.472-12.468c-1.957-3.966-3.396-9.643-4.319-17.033 C412.578,321.88,412.116,311.855,412.116,299.196z M422.793,299.358c0,20.43,1.534,34.609,4.604,42.541 c3.069,7.934,8.272,11.898,15.606,11.898c7.279,0,12.44-3.938,15.484-11.817c3.042-7.876,4.563-22.085,4.563-42.622 c0-20.481-1.521-34.662-4.563-42.54c-3.044-7.878-8.205-11.817-15.484-11.817c-7.334,0-12.537,3.967-15.606,11.898	C424.327,264.832,422.793,278.984,422.793,299.358z M490.026,299.196c0-12.551,0.462-22.506,1.386-29.868
		c0.923-7.362,2.363-13.025,4.319-16.992c2.771-5.542,6.261-9.711,10.473-12.51c4.21-2.798,9.113-4.197,14.71-4.197 c5.595,0,10.499,1.414,14.71,4.238c4.21,2.825,7.646,6.981,10.31,12.469c2.009,4.129,3.489,9.997,4.44,17.604 c0.95,7.606,1.427,17.357,1.427,29.257c0,12.334-0.462,22.262-1.386,29.786c-0.924,7.525-2.417,13.298-4.481,17.318 c-2.663,5.488-6.1,9.644-10.31,12.468c-4.211,2.825-9.115,4.238-14.71,4.238c-5.597,0-10.5-1.413-14.71-4.238 c-4.212-2.824-7.702-6.979-10.473-12.468c-1.956-3.966-3.396-9.643-4.319-17.033C490.488,321.88,490.026,311.855,490.026,299.196z M500.702,299.358c0,20.43,1.535,34.609,4.605,42.541c3.068,7.934,8.271,11.898,15.606,11.898c7.279,0,12.44-3.938,15.483-11.817 c3.042-7.876,4.563-22.085,4.563-42.622c0-20.481-1.521-34.662-4.563-42.54c-3.043-7.878-8.204-11.817-15.483-11.817 c-7.335,0-12.538,3.967-15.606,11.898C502.237,264.832,500.702,278.984,500.702,299.358z"
    />
    
{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.39,179.34v11.237h11.479v12.504H90v-12.504h10.657v-35.209H90v-12.521h48.869v12.521H127.39v11.191h35.958v-11.191h-11.486 v-12.521h48.87v12.521h-10.658v35.209h10.658v12.504h-48.87v-12.504h11.486V179.34H127.39L127.39,179.34z M588.826,142.847h-52.273 v12.521h14.229l-20.324,35.209h-11.768v12.504h40.379v-12.504h-10.869l4.59-7.2h31.637l3.596,7.2h-10.979v12.504H630v-12.504 h-11.928L588.826,142.847L588.826,142.847z M568.109,156.087l8.789,15.218h-17.576L568.109,156.087L568.109,156.087z M420.203,155.368h12.492v35.209h-12.492v12.504h63.449c19.795,0,33.99-7.725,33.99-30.482c0-19.008-14.982-29.752-32.703-29.752	h-64.736V155.368L420.203,155.368z M458.461,190.577v-35.209h13.855c9.086,0,18.012,4.187,18.012,16.713 s-5.25,18.496-14.492,18.496H458.461L458.461,190.577z M305.865,203.081h38.638v-12.504h-12.204v-32.414l34.335,44.918h36.511 v-47.713h11.686v-12.521h-39.104v12.521h11.969v30.551l-33.547-43.072h-48.283v12.521h11.865v35.209h-11.865V203.081	L305.865,203.081z M253.033,192.056c10.318,0,19.606-7.839,19.606-19.06c0-11.222-9.288-19.066-19.606-19.066 c-10.317,0-19.611,7.845-19.611,19.066C233.422,184.217,242.716,192.056,253.033,192.056L253.033,192.056z M253.033,204.904 c20.325,0.983,47.093-6.119,47.093-31.908c0-25.795-26.768-32.898-47.093-31.915c-20.331-0.983-47.092,6.12-47.092,31.915	C205.941,198.785,232.703,205.888,253.033,204.904L253.033,204.904z"
    />




    

		</svg>
		</div>

	);//return
}