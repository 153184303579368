import styled from "styled-components";


export const Board = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "black",
    width:              props.width || "100%",
    height:             props.height || "500px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    min-height:100px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;


`;



export const BoardAlfa = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",

    zIndex:            props.zIndex || "10",

    width:            props.width || "80%", 
    height:           props.height || "80%",

    top:              props.top || "10%", 
    left:             props.left || "10%"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;


export const BoardBase = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:            props.width || "80%", 
    height:           props.height || "80%",

    zIndex:           props.zIndex || "11", 
    top:              props.top || "10%", 
    left:             props.left || "10%"



}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;


    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    overflow-x:hidden;
    overflow-y:auto;


`;



export const BoardSection = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"
  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;

`;




 





/*




.controller .button{
			
    background-color    : transparent;
    width       		: 40px;
    height      		: 50px;

    margin      		: 0 0 0 0;
    padding     		: 0 0 0 0;

    position      		: relative;
    float     			: left;

}




.controller .arrowbox{
			
    background-color:transparent;
    width:30px;
    height:41px;
    padding:9px 0 0 0;
    margin:0 0 0 0;
    float:left;

}



    .controller .arrowbox .arrow-left {
    
        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent; 
        
        border-right:16px solid #454D5F; 
    
    
        float:left;
        margin: 0 0 0 9px;
        padding:0 0 0 0;	
    
    }



    .controller .arrowbox .arrow-right {

        width: 0; 
        height: 0; 
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        
        border-left: 16px solid #454D5F;
    
    
        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	
    
    }
      



    .controller .arrowbox .arrow-down {

        width: 0; 
        height: 0; 
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        
        border-top: 25px solid #454D5F;


        float:right;
        margin: 0 0 0 0;
        padding:0 0 0 0;	

    }
  



/******************************************************************
ARROW REFERENCES



.controller .arrowbox .arrow-up {

    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    
    border-bottom: 5px solid black;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
  
.controller .arrowbox .arrow-down {

    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid #f00;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
  
.controller .arrowbox .arrow-right {

    width: 0; 
    height: 0; 
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    
    border-left: 25px solid white;


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}
  
.controller .arrowbox .arrow-left {

    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid blue; 


    float:right;
    margin: 0 0 0 0;
    padding:0 0 0 0;	

}





*/







