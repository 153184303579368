
const CreateMarkup = (txt) => {


    var html = '';
    //var txtColor = 'black';

    html += '<span ';
        html += 'style="';
        //html += 'color:'+txtColor+';';
    html += '">'
        
        html += txt;
    
    html += '</span>';

    return {__html: html};

}


export default CreateMarkup;