

/*

"idl_name": "Jane Doe",

"idl_working_weeks": "50.00",
"idl_vacation_weeks": "2.00",

"idl_burden_hours_yearly": "1560.00",
"idl_burden_weekly_hours": "30.00",

"idl_rate_monthly": "2600.00",
"idl_rate_yearly": "31200.00"
"idl_hourly_rate": "20.00",

"idl_yearly_salary_w_tax_ira": "38064.00",
"idl_monthly_salary_w_tax_ira": "3172.00",
"idl_weekly_salary_w_tax_ira": "732.00",
"idl_hourly_rate_w_tax_ira": "24.40",

"idl_burden_rate_w_tax_ira_hi_yearly": "44064.00",
"idl_burden_rate_w_tax_ira_hi_monthly": "3672.00",
"idl_burden_rate_w_tax_ira_hi_hourly": "28.25",

"idl_burden_tax_yearly": "6240.00",
"idl_burden_tax_monthly": "520.00",
"idl_burden_tax_hourly": "4.00",
"idl_tax_comp_percent": "20.00",
"idl_tax_comp_decimal": "0.20",

"idl_ira_percent": "2.00",
"idl_ira_decimal": "0.02",
"idl_burden_ira_yearly": "624.00",
"idl_burden_ira_monthly": "52.00",
"idl_burden_ira_hourly": "0.40",

"idl_health_insurance_yearly": "6000.00",
"idl_health_insurance_monthly": "500.00",
"idl_health_insurance_weekly": "115.38",
"idl_health_insurance_hourly": "3.85",

*/







const VPRidlFilter = {

    "name"     : "IDL Filter",
    "value"    :{

        
        "idl_name": {

            "print"		:'standard',
            "name"		:'Name',
            "value"		:"TEMP NAME",
            "required"	:true,
            "default"	:""
        },



        "idl_working_weeks": {

            "print"		:'standard',
            "name"		:'Working Weeks',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },
        "idl_vacation_weeks": {

            "print"		:'standard',
            "name"		:'Vacation Weeks',
            "value"		:"2",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_hours_yearly": {

            "print"		:'standard',
            "name"		:'Burden Hours Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_weekly_hours": {

            "print"		:'standard',
            "name"		:'Burden Weekly Hours',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        




        "idl_rate_yearly": {

            "print"		:'price',
            "name"		:'Rate Yearly',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "idl_rate_monthly": {

            "print"		:'price',
            "name"		:'Rate Montly',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "idl_hourly_rate": {

            "print"		:'price',
            "name"		:'Hourly Rate',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },
        




        "idl_yearly_salary_w_tax_ira": {

            "print"		:'price',
            "name"		:'Yearly Salary w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_monthly_salary_w_tax_ira": {

            "print"		:'price',
            "name"		:'Monthly Salary w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_weekly_salary_w_tax_ira": {

            "print"		:'price',
            "name"		:'Weekly Salary w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_hourly_rate_w_tax_ira": {

            "print"		:'price',
            "name"		:'Hourly Salary w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        
        "idl_burden_rate_w_tax_ira_hi_yearly": {

            "print"		:'price',
            "name"		:'Hi-Yearly Burden Rate w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_rate_w_tax_ira_hi_monthly": {

            "print"		:'price',
            "name"		:'Hi-Monthly Burden Rate w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_rate_w_tax_ira_hi_hourly": {

            "print"		:'price',
            "name"		:'Hi-Hourly Burden Rate w/Tax & IRA',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        




        "idl_burden_tax_yearly": {

            "print"		:'price',
            "name"		:'Burden Tax Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_tax_monthly": {

            "print"		:'price',
            "name"		:'Burden Tax Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_tax_hourly": {

            "print"		:'price',
            "name"		:'Burden Tax Hourly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_tax_comp_percent": {

            "print"		:'standard',
            "name"		:'Tax Comp Percent',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_tax_comp_decimal": {

            "print"		:'standard',
            "name"		:'Tax Comp Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        



        "idl_ira_percent": {

            "print"		:'standard',
            "name"		:'IRA Percent',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_ira_decimal": {

            "print"		:'standard',
            "name"		:'IRA Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_ira_yearly": {

            "print"		:'standard',
            "name"		:'Burden IRA Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_ira_monthly": {

            "print"		:'standard',
            "name"		:'Burden IRA Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_burden_ira_hourly": {

            "print"		:'standard',
            "name"		:'Burden IRA Hourly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        
        "idl_health_insurance_yearly": {

            "print"		:'price',
            "name"		:'Health Ins. Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_health_insurance_monthly": {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_health_insurance_weekly": {

            "print"		:'price',
            "name"		:'Health Ins. Weekly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "idl_health_insurance_hourly": {

            "print"		:'price',
            "name"		:'Health Ins. Hourly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


    }


    
};




export {


    VPRidlFilter


};


