
// //import slides from '../slides';


const handleAnimate = ({timing, draw, duration, props}) => {

    let start = performance.now();
    requestAnimationFrame(function animate(time) {
        // timeFraction goes from 0 to 1
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;
    
        // calculate the current animation state
        let progress = timing(timeFraction) * 100;

        draw(progress); // draw it
    
        if(timeFraction < 1) {
            requestAnimationFrame(animate);
        }else{
            handleAnimateComplete(props);
        }

    });

}


const handleAnimateComplete = (props) => {

    //console.log(JSON.stringify(hndl));

    const handle = props.handle;
    const call = props.call;
    const newSlideNumber = props.newSlideNumber;



    switch(call)
    {
    case'slideMove':

        // console.log(JSON.stringify(props));
        // alert(call);

        handle({
            
            call:'setActionZ',
            data:1

        });
        handle({

            call:'setSlideNumber',
            data:newSlideNumber

        });

    break;
    default:
    }


}





// -- handlePrevNext -- //

export const HandlePrevNext = (props) => {

    //alert(JSON.stringify(hndl));

    const handle = props.handle;
    const call = props.call;
    const slideNumber = props.slideNumber;
    const slides = props.slides;
    const refA = props.refA;
    const refActionLayer = props.refActionLayer;

    // console.log(slides.length);
    // console.log(slideNumber);


    var actionId = refActionLayer.current.id;
    var actionLayer = document.getElementById(actionId);

    var currentSlides = [];

    var currIndex;
    var currSlide;

    var newIndex;
    var newSlide;

    var duration = 400;

    switch(call)
    {
    case'prev':

            var prevSlideNumber;
            if(slideNumber > 0 && slideNumber < slides.length)
            {
                prevSlideNumber = slideNumber-1;
            }else{
                if(slideNumber === 0)
                {
                    prevSlideNumber = slides.length-1;
                }
            }
            //console.log("prevSlideNumber: "+prevSlideNumber);


            actionId = refActionLayer.current.id;
            actionLayer = document.getElementById(actionId);
        

            // -- load action layer -- //

            // Note - load new first for previous 

            newIndex = prevSlideNumber;
            newSlide = slides[newIndex];
            if(newSlide !== null) currentSlides.push(newSlide);   

            currIndex = slideNumber;
            currSlide = slides[currIndex];
            if(currSlide !== null) currentSlides.push(currSlide);


            //console.log(JSON.stringify(currentSlides,null,2));

            // console.log("PrevNext");
            // console.log("CURR: "+currIndex+"\nNEW: "+newIndex)

            // console.log("ACTION  SLIDES: "+JSON.stringify(currentSlides,null,2));
            // console.log("DISPLAY SLIDE: "+JSON.stringify([newSlide],null,2));
    
    
    
    
            handle({

                call:'setActionSlides',
                data:currentSlides

            });
            handle({

                call:'setDisplaySlides',
                data:[newSlide]

            });

            // -- size and position action layer -- //

            actionLayer.style.width = (refA.current.offsetWidth * currentSlides.length) + 'px';
            actionLayer.style.left = -(refA.current.offsetWidth) + 'px';


            // -- set action z -- // 

            handle({

                call:'setActionZ',
                data:5
    
            });
            handleAnimate({

                props:{
                    call:"slideMove",
                    newSlideNumber:prevSlideNumber,  
                    handle:handle
                },
                duration:duration,
                timing(timeFraction) {
                    return timeFraction;
                },
                draw(progress) {

                    //console.log("PROGRESS: "+(progress-100));
                    document.getElementById(actionId).style.left = (progress - 100) + '%';//prev goes in reverse less -100
                    //actionLayer.style.left = - progress + '%';//next
                }

            });



    break;
    case'next':

        var nextSlideNumber;
        if(slideNumber > 0 && slideNumber < slides.length-1)
        {
            nextSlideNumber = slideNumber+1;
        }else{

            if(slideNumber === slides.length-1)
            {
                nextSlideNumber = 0;
            }
            else
            if(slideNumber === 0)
            {
                nextSlideNumber = slideNumber+1;
            }
        }
        //console.log("nextSlideNumber: "+nextSlideNumber);


        // -- load action layer -- //

        currIndex = slideNumber;
        currSlide = slides[currIndex];
        if(currSlide !== null) currentSlides.push(currSlide);

        newIndex = nextSlideNumber;
        newSlide = slides[newIndex];
        if(newSlide !== null) currentSlides.push(newSlide);

        //console.log(JSON.stringify(currentSlides,null,2));

        // console.log("PrevNext");
        // console.log("CURR: "+currIndex+"\nNEW: "+newIndex)

        // console.log("ACTION  SLIDES: "+JSON.stringify(currentSlides,null,2));
        // console.log("DISPLAY SLIDE: "+JSON.stringify([newSlide],null,2));

        // setActionSlides(currentSlides);
        // setDisplaySlides([newSlide]);

        handle({

            call:'setActionSlides',
            data:currentSlides

        });
        handle({

            call:'setDisplaySlides',
            data:[newSlide]

        });



        // -- size and position action layer -- //

        actionLayer.style.width = (refA.current.offsetWidth * currentSlides.length) + 'px';
        actionLayer.style.left = (0) + 'px';


        // -- set action z -- // 

        //setActionZ(5); 
        
        // handleAnimate({

        //     props:{
            
        //         call:"slideMove",
        //         newSlideNumber:nextSlideNumber
        //     },
        //     duration:duration,
        //     complete: function(){alert("is complete")},
        //     timing(timeFraction) {
        //         return timeFraction;
        //     },
        //     draw(progress) {
        //         //actionLayer.style.width = progress * 100 + '%';
        //         actionLayer.style.left = - progress + '%';
        //     }
    
        // });

        handle({

            call:'setActionZ',
            data:5

        });
        handleAnimate({

            props:{
            
                call:"slideMove",
                newSlideNumber:nextSlideNumber,
                handle:handle
            },
            duration:duration,
            complete: function(){alert("is complete")},
            timing(timeFraction) {
                return timeFraction;
            },
            draw(progress) {
                //actionLayer.style.width = progress * 100 + '%';
                document.getElementById(actionId).style.left = - progress + '%';
            }
 
        });




    break;
    default:
    }










}








