export const BUTTON_LOGO_AP1V1 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.437,191.285h-42.553l-4.259,10.676h4.259c4.229,0,7.233,0.816,9.012,2.449
		c1.808,1.634,2.712,3.733,2.712,6.358c0,2.566-0.904,4.667-2.712,6.271c-1.779,1.634-4.783,2.45-9.012,2.45h-23.566
		c-4.229,0-7.233-0.816-9.013-2.45c-1.808-1.604-2.712-3.732-2.712-6.357s0.934-4.754,2.83-6.388
		c1.866-1.662,4.987-2.45,9.304-2.333l27.533-68.512h-11.463c-4.199,0-7.233-0.816-9.012-2.45c-1.808-1.604-2.683-3.733-2.683-6.358
		s0.875-4.754,2.683-6.357c1.779-1.634,4.813-2.45,9.012-2.45l37.858,0.087l34.854,86.041c4.112,0,6.825,0.467,8.137,1.371
		c2.596,1.895,3.908,4.374,3.908,7.437c0,2.566-0.875,4.667-2.654,6.271c-1.75,1.634-4.754,2.45-8.955,2.45H285.35
		c-4.2,0-7.204-0.816-9.013-2.45c-1.779-1.604-2.683-3.732-2.683-6.357c0-2.566,0.904-4.638,2.683-6.271
		c1.809-1.633,4.813-2.449,9.013-2.449h4.287L285.437,191.285L285.437,191.285z M278.175,173.786l-14.087-34.708l-14.204,34.708
		H278.175L278.175,173.786z M358.936,183.936v18.025h17.704c4.2,0,7.204,0.816,9.013,2.449c1.779,1.634,2.684,3.733,2.684,6.358
		c0,2.566-0.904,4.667-2.684,6.271c-1.809,1.634-4.813,2.45-9.013,2.45h-37.945c-4.229,0-7.233-0.816-9.012-2.45
		c-1.808-1.604-2.712-3.732-2.712-6.357c0-2.566,0.933-4.638,2.741-6.271c1.838-1.633,4.813-2.449,8.983-2.449h2.742v-68.512h-2.742
		c-4.229,0-7.233-0.816-9.012-2.45c-1.808-1.604-2.712-3.733-2.712-6.358s0.904-4.754,2.712-6.357
		c1.779-1.634,4.783-2.45,9.012-2.45l40.337,0.087c11.842,0,21.232,3.238,28.146,9.713c6.912,6.445,10.383,14.32,10.383,23.624
		c0,5.134-1.167,9.976-3.5,14.524c-1.779,3.413-4.725,6.797-8.837,10.121c-4.142,3.325-8.371,5.834-12.658,7.525
		c-4.316,1.662-9.975,2.508-17.063,2.508H358.936L358.936,183.936z M358.936,166.436h16.246c7.611,0,13.679-1.721,18.141-5.191
		c4.492-3.441,6.709-7.583,6.709-12.424c0-4.084-1.809-7.7-5.426-10.763c-3.616-3.063-8.778-4.608-15.486-4.608h-20.184V166.436
		L358.936,166.436z M483.009,106.092l-37.683,9.741c-3.792,1.021-6.301,2.216-7.496,3.558s-1.809,3.18-1.809,5.513
		c0,2.567,0.816,4.726,2.45,6.476c1.604,1.778,3.529,2.653,5.745,2.653c1.255,0,3.15-0.321,5.659-1.021l15.633-4.083v73.032h-17.704
		c-4.199,0-7.203,0.816-9.012,2.449c-1.779,1.634-2.684,3.705-2.684,6.271c0,2.625,0.904,4.754,2.684,6.357
		c1.809,1.634,4.813,2.45,9.012,2.45h52.908c4.199,0,7.203-0.816,9.012-2.45c1.809-1.604,2.684-3.704,2.684-6.271
		c0-2.625-0.875-4.725-2.684-6.358c-1.809-1.633-4.813-2.449-9.012-2.449h-17.704V106.092L483.009,106.092z M316.514,372.357
		l-25.2-60.49h4.083c4.229,0,7.204-0.816,8.983-2.45c1.75-1.604,2.654-3.732,2.654-6.358c0-2.624-0.904-4.725-2.712-6.328
		c-1.779-1.575-4.754-2.392-8.925-2.392l-23.654-0.088c-4.229,0-7.233,0.816-9.012,2.45c-1.809,1.604-2.712,3.733-2.712,6.357
		c0,3.18,1.371,5.659,4.112,7.438c1.313,0.904,4.025,1.371,8.108,1.371l35.904,86.04h16.829l35.961-86.04
		c4.055,0,6.738-0.467,8.051-1.371c2.712-1.837,4.082-4.316,4.082-7.438c0-2.624-0.875-4.725-2.683-6.328
		c-1.809-1.575-4.813-2.392-9.013-2.392l-23.508-0.088c-4.2,0-7.204,0.816-9.013,2.45c-1.779,1.604-2.683,3.733-2.683,6.357
		c0,2.626,0.904,4.755,2.683,6.358c1.809,1.634,4.813,2.45,9.013,2.45h4.025L316.514,372.357L316.514,372.357z M430.495,284.51
		l-37.683,9.741c-3.792,1.021-6.301,2.217-7.496,3.559s-1.809,3.179-1.809,5.512c0,2.567,0.816,4.726,2.45,6.476
		c1.604,1.778,3.529,2.654,5.746,2.654c1.254,0,3.149-0.321,5.658-1.021l15.633-4.083v73.032h-17.704
		c-4.199,0-7.203,0.816-9.012,2.449c-1.779,1.634-2.684,3.705-2.684,6.271c0,2.625,0.904,4.754,2.684,6.358
		c1.809,1.633,4.813,2.449,9.012,2.449h52.908c4.199,0,7.204-0.816,9.012-2.449c1.809-1.604,2.684-3.704,2.684-6.271
		c0-2.626-0.875-4.726-2.684-6.359c-1.808-1.633-4.813-2.449-9.012-2.449h-17.704V284.51L430.495,284.51z M123.97,244.447
		c-4.154,0-7.553,3.398-7.553,7.553c0,4.135,3.418,7.553,7.553,7.553H596.03c4.154,0,7.553-3.398,7.553-7.553
		c0-4.135-3.417-7.553-7.553-7.553H123.97L123.97,244.447z"
    />
    





    

		</svg>
		</div>

	);//return
}