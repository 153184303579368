
import React from 'react';


import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';


import Messngr from 'utils/components/Process/Messngr';


export const FSerror = (props) => {


    //console.log(JSON.stringify(props.errorArr,null,2));
    //alert('FSerror')

    const portalTarget = usePortal("stage");

    var items = [];
    for(var obj of props.errorArr)
    {

        items.push({

            call:'span',
            text:obj['message']
        })


    }


    let data = {

        isError         : true,
        errorType       : props.errorType,
        isTimed         : true,
        timedDuration   : 2000,
        items           : items

    };

    return createPortal(<div>

        <Messngr 

            handle={(v) => props.handle(v)}
            data={data}

        />

    </div>, portalTarget);


}


