const LayerInfo = [


    //==============================================
    // STATIC
    //==============================================

    {
        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Static_Background_Glass',
            component:'STATIC_BACKGROUND_GLASS'

        }


    },

    {
        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Static_Background',
            component:'STATIC_BACKGROUND'

        }


    },



    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Static_Muffler',
            component:'STATIC_MUFFLER'

        }


    },






    //====================================
    //Driver
    //====================================


    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

  
            call:"Steering_Wheel",
            component:'STEERING_WHEEL_GRAY_BLACK'
        }

    },



    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{


            call:"Glove",
            component:"GLOVE_GRAY_WHITE"

        }

    },



    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{


            call:"Helmet",
            component:"HELMET_RED_DRIVER_VAN_HALEN"

        }

    },  

    // {

    //     backgroundColor:"lightblue",
    //     width:"100%",
    //     height:"450px",
    //     slide:{


    //         call:"Helmet_Visor",
    //         component:"HELMET_VISOR_BLUE"

    //     }

    // },




    // {

    //     backgroundColor:"lightblue",
    //     width:"100%",
    //     height:"450px",
    //     slide:{


    //         call:"Suit",
    //         component:"SUIT_YELLOW"

    //     }

    // },











    //====================================
    //HardTop
    //====================================

    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:'HardTop',
            component:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE',
   

        }

    },  


    //====================================
    //SoftTop
    //====================================

    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:'SoftTop',
            component:'',
   

        }

    },  




    //====================================
    //Rollbar
    //====================================

    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:'Rollbar',
            component:'',
   

        }

    },  












    //====================================
    //CarBody
    //====================================

    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Body',
            component:'BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE'
        }


    },




    //====================================
    //Ground
    //====================================

    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:'Ground',
            component:'STATIC_GROUND'

        }

    },


    //====================================
    //Mirror
    //====================================

    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Mirror',
            component:'MIRROR_BLUE'
        }


    },


    //====================================
    //Wing
    //====================================

    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Wing',
            component:'WING_RACING_SOLID_CARBON_FIBER_WHITE_1'
        }


    },




    //====================================
    //Tires
    //====================================

    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Static_Tires',
            component:'STATIC_TIRES'

        }


    },




    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:"Tires",
            component:'TIRES_GOODYEAR'

        }

    },





    //====================================
    //LIGHTS
    //====================================

    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Headlight_Taillight',
            component:'STATIC_HEADLIGHT_TAILLIGHT'

        }


    },



    // {

    //     backgroundColor:"lightgreen",
    //     width:"100%",
    //     height:"450px",
    //     slide:{

    //         call:'Check_Marker_Lights',
    //         component:'CHECK_MARKER_LIGHTS'

    //     }


    // },




    //====================================
    //Brakes
    //====================================


    // {

    //     backgroundColor:"lightgreen",
    //     width:"100%",
    //     height:"450px",
    //     slide:{

    //         call:'Static_Brake_Rotors',
    //         component:'STATIC_BRAKE_ROTORS'

    //     }


    // },



    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Static_Brake_Rear',
            component:'STATIC_BRAKES_REAR'

        }


    },


    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{


            call:'Caliper',
            component:"CALIPER_4P_FRONT_RED_RED"

        }

    },



    {

        backgroundColor:"lightgreen",
        width:"100%",
        height:"450px",
        slide:{

            call:'Static_Caliper_Rear_Gray',
            component:'STATIC_CALIPER_REAR_GRAY'

        }


    },











    //====================================
    //Windshield
    //====================================

    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:"Banner",
            component:"BANNER_RED_RED"

        }

    },





    // //====================================
    // //Lights
    // //====================================


    // {

    //     backgroundColor:"lightblue",
    //     width:"100%",
    //     height:"450px",
    //     slide:{

    //         call:'Lights',
    //         component:"HeadTailLights"

    //     }

    // },
    // {

    //     backgroundColor:"lightblue",
    //     width:"100%",
    //     height:"450px",
    //     slide:{

    //         call:'Lights',
    //         component:"MarkerLights"

    //     }

    // },  



    // //====================================
    // //Muffler
    // //====================================

    // {

    //     backgroundColor:"lightblue",
    //     width:"100%",
    //     height:"450px",
    //     slide:{

    //         call:'Muffler'

    //     }

    // },


    // //====================================
    // //Mirror
    // //====================================

    // {

    //     backgroundColor:"lightblue",
    //     width:"100%",
    //     height:"450px",
    //     slide:{

    //         call:'Mirror'

    //     }

    // },






    //====================================
    //Wheels
    //====================================

    {

        backgroundColor:"lightblue",
        width:"100%",
        height:"450px",
        slide:{

            call:"Wheels",
            component:'WHEELS_TRS9_GRAY_BLACK'

        }

    },






];



export default LayerInfo;