
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';

import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    //useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";




import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

//import { useLiqGrid } from 'utils/myHooks/LiquidFormula/LiqGrid';

//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank
import { Alfa, Base, Tab, Flank} from 'app-styled/AlfaBase.js';
import { Flyr } from './Styled';

import Canvas from './Comps/Canvas';
import Chart from './Comps/Chart';
import Chooser from './Comps/Chooser';
import Cards from './Comps/Cards';

import Form from './Comps/Form';
import FormFly from './Comps/Form/FormFly';

import Handler from './Handler';

import { LibElem } from 'library';


const Index = (props) => {
   

    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- router -- //
    //let match = useRouteMatch();
    //let location = useLocation();
    let history = useHistory();


    // -- redux -- //

    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    //if(zoneObj['handle'] && zoneObj['handle'] !== undefined) alert(zoneObj['handle']);





    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])






    // -- vars -- //
    var title;
    //var call;
    var type = 'default';
    var data;
    var styl;
    var currentKey;
    var submitCall = false;
    var postTo = false;
    //call = props.data.call;

    var level;
    if(props.data.data.data !== undefined) level = 2;
    else if(props.data.data !== undefined) level = 1;
    else level = 0;




    
    switch(level)
    {
    case 1:

        type = props.data.flyr.type;
        data = props.data.data;
        styl = props.data.flyr.styl;
    break;
    case 2:

        type = props.data.data.flyr.type;
        data = props.data.data.data;
        styl = props.data.data.flyr.styl;
    break;
    default:

        type = props.flyr.type;
        data = props.data;
        styl = props.flyr.styl;
    break;
    }//===



    switch(type.toLowerCase())
    {
    case'canvas':   
        title = 'Canvas'; 

    break;
    case'chart':    
        title = 'Chart'; 
        if(data.name !== '') title = data.name;
    break;
    case'chooser':  

        title = 'Choose'; 
        if(data.name !== '') title = data.name;

    break;
    case'cards':

        title = 'Cards'; 
        if(data.name !== '') title = data.name;
    break;
    case'form':     
        title = 'Edit'; 


        switch(level)
        {
        case 1:
            currentKey=props.data.keyname
            submitCall = props.data.submitCall;
            postTo = props.data.postTo;
        break;
        case 2:
            currentKey=props.data.data.keyname;
            submitCall = props.data.data.submitCall;
            postTo = props.data.data.postTo;
        break;
        default:
            currentKey = props.keyname;
            submitCall = props.submitCall;
            postTo = props.postTo;
        break;
        }//===


        // console.log("form");

    break;
    default:
        title = '';      
    }



    // console.log("currentKey: "+currentKey)
    // console.log("LEVEL: "+level)
    // console.log("props.data: "+JSON.stringify(props.data,null,2));
    // //console.log("props.data.data: "+JSON.stringify(props.data.data,null,2));
    // //console.log("props.data.data.data: "+JSON.stringify(props.data.data.data,null,2))










    if(width < 400) title = '';


    var maxWidth;
    maxWidth = 900;

    if(styl
    && styl !== undefined) 
    {
        maxWidth = styl.maxWidth;
    }

    if(width < maxWidth + 50)
    {
       maxWidth = width;

    }//==


    

    //#########################################################
    //#########################################################


    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    useEffect(() => {


        var currentFlyr;
        if(data.name && data.name !== undefined)
        {
            currentFlyr = data.name.toLowerCase();
        }else{
            currentFlyr = 'tempflyr';
        }
        currentFlyr = currentFlyr.replace(/\//gi,"-");
        currentFlyr = currentFlyr.replace(/ /gi,"-");



        if(zoneObj['currentFlyr'] !== currentFlyr)
        {

            //alert('dispatch');
            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",
                item:{
                    key     :"currentFlyr",
                    value   :currentFlyr
                }

            })); 

            //console.log(zoneObj,null,2);
            //alert('initialize flyr');

            if(type === 'form')
            {


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_formArr",
                        value   :[]
                    }
    
                })); 


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_problemArr",
                        value   :[]
                    }
    
                })); 



                // -- submit - complete -- //

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runSubmit",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runComplete",
                        value   :false
                    }
    
                })); 



                // -- error - errorType -- /

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runError",
                        value   :false
                    }
    
                })); 


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_errorType",
                        value   :''
                    }
    
                })); 





                // -- account - calculate - output - submitCall - postTo -- //

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runAccount",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runCalculate",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",
                    item:{
                        key     :currentFlyr + "_runOutput",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_output",
                        value   :false
                    }
    
                })); 


                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_postTo",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_currentKey",
                        value   :false
                    }
    
                })); 

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",
                    item:{
                        key     :currentFlyr + "_completeMessage",
                        value   :false
                    }
    
                })); 



            }



        }//==



    },[

        dispatch,
        type,
        data,
        zoneObj

    ])






    //#########################################################
    //#########################################################


    // -- ref -- //
    const baseRef = useRef(null)
    const tabRef = useRef(null)
    const flankRef = useRef(null)
    const flyrRef = useRef(null)
    const binRef = useRef(null)

    
    /*
    const cellRefs = [];
    const setRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };
    */

    // -- state -- //
    const [baseScrollHeight, setBaseScrollHeight] = useState(0)
    const [tabHeight, setTabHeight] = useState(10)
    const [flyrHeight, setFlyrHeight] = useState(height)
    const [flyrType, setFlyrType] = useState(false)
    const [flyrData, setFlyrData] = useState(false)
    const [flyrStyl, setFlyrStyl] = useState(false)
    const [contentsHeight, setContentsHeight] = useState(200)


    useEffect(() => {

        setFlyrType(type.toLowerCase());
        setFlyrStyl(styl);
        setFlyrData(data);


        //console.log("VPowners "+JSON.stringify(VPowners,null,2));
        //console.log("VPowner "+JSON.stringify(VPowner,null,2));
        
        //console.log("DATA:::::::::::"+JSON.stringify(data,null,2));
        //alert("DATA");
        

        if(tabHeight < 50)
        {
            setTabHeight(tabRef.current.offsetHeight);
        }


        var flyrH;
        if(flyrHeight < 50)
        {
            setBaseScrollHeight(baseRef.current.scrollHeight);
            flyrH = flyrRef.current.offsetHeight;
            
        }
        else
        if(flyrHeight < contentsHeight+50)
        {
            if(contentsHeight < height) 
                flyrH = baseRef.current.scrollHeight; 
            else
                flyrH = contentsHeight+50; 

        }//==


        if(flyrHeight < binRef.current.offsetHeight)
        {
            flyrH = baseRef.current.scrollHeight;
        }

        
        /*
        if(flyrHeight < baseRef.current.scrollHeight-200
        )
        {
            console.log(baseRef.current.scrollHeight);
            setFlyrHeight(baseRef.current.scrollHeight);
        }
        */

        if(flyrH !== undefined) setFlyrHeight(flyrH);    


        /*
        console.log(""

            +   "\n FLYR HEIGHT: " +flyrHeight

            +   "\n CONTENTS: " +contentsHeight 
            +   "\n BIN: "+binRef.current.offsetHeight
            +   "\n FLYR: " +flyrRef.current.offsetHeight
            +   "\n TAB: " +tabRef.current.offsetHeight
            +   "\n BASE SCROLL: " +baseRef.current.scrollHeight
            +   "\n HEIGHT: " +height
   
        );
        */



        if(type === 'cards')
        {

            console.log(""

                //+"\nCALL: "+call
                +"\nTYPE: "+type
                +"\nDATA: "+JSON.stringify(data,null,2)
                +"\nSTYL: "+JSON.stringify(styl,null,2)
            );
            

            //console.log("PROPS DATA :::::::::"+JSON.stringify(props.data,null,2));
            alert('props');


        }
    



    },[

        props,
        height,
        baseScrollHeight,
        tabHeight,     
        flyrHeight,
        contentsHeight,
        type,
        data,
        styl

    ])












    //---------------------------------------------
    // state/effect - view zoneObj
    //---------------------------------------------


    // -- state -- //
    const [zoneWasViewed, setZoneWasViewed] = useState(false);

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(!zoneWasViewed)
        {
            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            // console.log("===== zoneObj =====");
            // console.log(JSON.stringify(zoneObj,null,2));


            setZoneWasViewed(true);
    
        }else{

            //Delay before starting animation
            // var timer;
            // timer = setTimeout(() => {

            //     setZoneWasViewed(false);

            // }, 500);
            // return () => clearTimeout(timer);

        } 

    },
    [
        props,
        //isLoaded,
        zoneObj,
        zoneWasViewed

    ])





















    // -- handle -- //


    const handle = (hndl) => {

        //console.log(hndl);


        hndl['zoneObj'] = zoneObj;
        hndl['handleState'] = (v) => {

            if(v.call === 'contentsHeight') setContentsHeight(v.value);
            if(v.call === 'handleDispatch') handleDispatch(v.value);
            if(v.call === 'handleCloseFlyr') handleCloseFlyr(v.value); 
            if(v.call === 'primeHandle') primeHandle(v.value); 

        };
        hndl['handleRoute'] = (v) => {

            switch(v.call)
            {
            case'goBack':
    
                history.goBack();
            break;
            case'reload':
    
                window.location.reload();//regular dom 
            break;
            case'redirect':
    
                history.replace(v.value);
            break;
            case'push':

                history.push(v.value);
            break;
            default:
            }
    
        }
        Handler(hndl);


    }


    const primeHandle = (hndl) => {

        //alert(hndl.handle);
        switch(hndl.handle)
        {
        case'messngr':

            props.handle({

                call:hndl.action

            })

        break;
        case'flyr':


            props.handle({

                call:hndl.action

            })

        break;
        case'removeHash':


            props.handle({

                call:hndl.action

            })

        break;
        default:
        }



    }

    const handleDispatch = (hndl) => {

        // if(hndl.action === 'DELETE_ZONE') alert('delete zone')
        // console.log('handleDispatch -- '+hndl.key+" "+hndl.value);
    
        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }

    const handleCloseFlyr = (hndl) => {

        props.handle({

            call:'hide'

        })

    }











    //###########################################################
    //###########################################################

    var flyrPadBottomInt = 0;
    var flyrPadBottom = flyrPadBottomInt + "px";

     var flyrWInt;
     var flyrW;

     var flyrH;

     var flyrMarl;

     var flyrMartInt;
     var flyrMart;

     var tabMartInt;
     var tabMart;



     flyrWInt = maxWidth;
     flyrMarl = (width - flyrWInt)/2 + "px";

     if(width < flyrWInt+50)
     {
        flyrWInt = width;
        flyrMarl = 0 + "px";
     }//==

     flyrW = flyrWInt + "px";


    //-------------------------------------------

    flyrMartInt = tabHeight;
    tabMartInt = 0;

    flyrMart = flyrMartInt + 'px';
    tabMart = tabMartInt + "px";

    //-------------------------------------------

    var flankLeftWidthInt;
    var flankLeftWidth;
    var flankLeftMarl;
       
    var flankRightWidthInt;
    var flankRightWidth;
    var flankRightMarl;

    var flankVertHeight;


    flankLeftWidthInt = (width - flyrWInt)/2;
    flankLeftWidth = flankLeftWidthInt + "px";
    flankLeftMarl = "0px";
       
    flankRightWidthInt = (width - flyrWInt)/2;
    flankRightWidth = flankRightWidthInt + "px";
    flankRightMarl = flankLeftWidthInt + flyrWInt + "px";


    //flyrH = baseScrollHeight - (tabHeight+flyrPadBottomInt) + "px";
    flyrH = flyrHeight + (0) + "px";
    flankVertHeight = flyrHeight + (flyrMartInt) + "px";




/*



    const VideoEnded = () => {


        console.log('video ended');


    }


*/






    return (


        <div>

            {flyrType === 'form'
            ?

                <FormFly 

                   handle={(v) => handle(v)}  

                    formArr={zoneObj[zoneObj.currentFlyr + "_formArr"]}
                    propblemArr={zoneObj[zoneObj.currentFlyr + "_problemArr"]} 

                    processSubmit={zoneObj[zoneObj.currentFlyr + "_processSubmit"]}
                    processComplete={zoneObj[zoneObj.currentFlyr + "_processComplete"]}
                    processError={zoneObj[zoneObj.currentFlyr + "_processError"]}
                    errorType={zoneObj[zoneObj.currentFlyr + "_errorType"]}

                />


            :null}


            <Alfa
             zIndex={highZ+1}
            ></Alfa>


            <Tab
            ref={tabRef}
            //backgroundColor={'white'}
            width={flyrW}
            top={tabMart}
            left={flyrMarl}
            zIndex={highZ+3}
            >

                {
                flyrType === 'form'
                ?

                    <div 
                    className="Button" 
                    onClick={() => handle({

                        call        :'formSubmit',
                        currentKey  :currentKey,
                        submitCall  :submitCall,
                        postTo      :postTo
    
                    })}
                    >  
                        Save

                    </div>


                :
                
                    <div 
                    className="Title" 
                    >  

                        {title}

                    </div>
                
                }


                <div 
                className="X" 
                onClick={() => handle({

                    call:'closeFlyr'

                })}
                >  
                    X
                </div>


            </Tab>




            <Base 
            id="flyrBase"
            ref={baseRef}
            zIndex={highZ+2}
            >


                <Flank
                id="flankleft"
                ref={flankRef}
                //backgroundColor={'blue'}
                width={flankLeftWidth}
                height={flankVertHeight}
                left={flankLeftMarl}
                onClick={() => handle({

                    call:'closeFlyr'

                })}
                >
                </Flank>


                <Flank
                id="flankright"
                //backgroundColor={'red'}
                width={flankRightWidth}
                height={flankVertHeight}
                left={flankRightMarl}
                onClick={() => handle({

                    call:'closeFlyr'

                })}
                >
                </Flank>



                <Flyr 
                id="flyr"
                ref={flyrRef}
                backgroundColor={'white'}
                width={flyrW}
                height={flyrH}
                margin={flyrMart+" 0 0 "+flyrMarl}
                padding={"0 0 "+flyrPadBottom+" 0"}
                >


                    <div 
                    ref={binRef}
                    className="Bin"
                    >

                        {
                
                            flyrType === 'canvas'
                            ?
                            
                                <Canvas 
                                
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}
                                    
                                />

                            :
                            flyrType === 'chart'
                            ?

                                <Chart 
                                
                                    primeHandle={(v) => props.handle(v)}
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}

                                    
                                
                                />

                            :
                            flyrType === 'chooser'
                            ?

                                <Chooser 
                                
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}
  
                                
                                />

                            :
                            flyrType === 'cards'
                            ?

                                <Cards 
                                
                                    primeHandle={(v) => props.handle(v)}
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}
  
                                
                                />

                            :
                            flyrType === 'form'
                            ?
                            
                                <Form 

                                    primeHandle={(v) => props.handle(v)}
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}
                                
                                />

                            :null


                        }

                    </div>


                </Flyr>

            </Base>


        </div>

    );




}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/