export const STATIC_MUFFLER = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background*/}
    <path 
        fill="rgb(200,200,200)" 
        d="M74.727,375.033c0.613,2.91,4.862,4.267,7.836,4.267h1.414	l-0.008-0.019l0.232,0.002l-14.272-30.105h-0.651L74.727,375.033L74.727,375.033z"
    />
    {/*Shadow*/}
    <path 
        fill="rgb(90,90,90)" 
        d="M83.271,377.321h-7.042c-0.758-0.613-1.31-1.374-1.503-2.288 l-1.338-6.353h5.786L83.271,377.321L83.271,377.321z"
    />
    {/*Midlight*/}   
    <polygon 
        fill="rgb(130,130,130)" 
        points="73.022,366.947 78.354,366.947 76.922,363.93 72.387,363.93 73.022,366.947"
    />
    {/*Highlight*/}
    <polygon 
        fill="rgb(255,255,255)" 
        points="72.237,363.219 76.586,363.219 75.83,361.627 71.901,361.627 72.237,363.219"
    />
    
    


    

		</svg>
		</div>

	);//return
}