
const ControlData = {





    order : [

        'Body',
        'Top',
    
        'Tires',           
        'Wheels',
        'Rotors',
        'Caliper',

        'Windshield',

        'Background',
        'Ground'

    ],







    //######################################################
    //######################################################


    main : [


        {

            category:'Body',
            subCategory:'car_colors',
            name:'Body',   
            thumbComponent:'BUTTON_MAIN_BODY',
            layerComponent:false

        },


        {
            category:'Top',
            subCategory:'tops',
            name:'Top',         
            thumbComponent:'BUTTON_MAIN_HARDTOP',
            layerComponent:false

        },

        // {
        //     category:'SoftTop',
        //     subCategory:'softtop_colors',
        //     name:'Soft Top',          
        //     thumbComponent:'BUTTON_SOFTTOP',
        //     layerComponent:false

        // },

        // {
        //     category:'RollBars',
        //     subCategory:'rollbar_colors',
        //     name:'Roll Bars',    
        //     thumbComponent:'BUTTON_ROLLBAR',
        //     layerComponent:false
    

        // },


        {
            category:'Wheels',
            subCategory:'wheels',
            name:'Wheels',
            thumbComponent:'BUTTON_MAIN_WHEELS',
            layerComponent:false

        },


        {
            category:'Tires',
            subCategory:'tires',
            name:'Tires',
            thumbComponent:'BUTTON_MAIN_TIRES',
            layerComponent:false
        },



        {
            category:'Calipers',
            subCategory:'caliper_colors',
            name:'Calipers',
            thumbComponent:'BUTTON_MAIN_CALIPERS',
            layerComponent:false

        },



        {
            category:'Helmet',
            subCategory:'helmet_choices',
            name:'Helmet',
            thumbComponent:'BUTTON_MAIN_HELMET',
            layerComponent:false

        },


        {
            category:'Steering_Wheel',
            subCategory:'steering_wheel',
            name:'Steering Wheel',
            thumbComponent:'BUTTON_MAIN_STEERING_WHEEL',
            layerComponent:false


        },


        {
            category:'Wing',
            subCategory:'wing_choices',
            name:'Wing',
            thumbComponent:'BUTTON_MAIN_RACING_WING',
            layerComponent:false

        },


        {
            category:'Accessories',
            subCategory:'accessories',
            name:'Accessories',
            thumbComponent:'BUTTON_MAIN_BANNER',
            layerComponent:false


        }




    ],









    //######################################################
    //######################################################


    subA : {


        pills: [


            //----------------
            //red
            //----------------
            {
                subCategory:false,  
                name:'Red', 
                thumbComponent:'BUTTON_PILL_RED_RED',
                layerComponent:'BODY_SOLID_RED'
           
            },
            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BUTTON_PILL_RED_NEW_FORMULA_RED',
                layerComponent:'BODY_SOLID_RED_NEW_FORMULA_RED'
           
            },



            //----------------
            //yellow
            //----------------
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_RIO_YELLOW',
                layerComponent:'BODY_SOLID_YELLOW_RIO_YELLOW'
            },

            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
                layerComponent:'BODY_SOLID_YELLOW_SPA_YELLOW'
            },




            //----------------
            //blue
            //----------------
            {
                subCategory:false,  
                name:'Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_BLUE',
                layerComponent:'BODY_SOLID_BLUE'
            },
            {
                subCategory:false,  
                name:'Apex Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_APEX_BLUE',
                layerComponent:'BODY_SOLID_BLUE_APEX_BLUE'
            },
            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
                layerComponent:'BODY_SOLID_BLUE_LAGUNA_BLUE'
            },
            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
                layerComponent:'BODY_SOLID_BLUE_MONTE_CARLO_BLUE'
            },
            {
                subCategory:false,  
                name:'Suzuka Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_SUZUKA_BLUE',
                layerComponent:'BODY_SOLID_BLUE_SUZUKA_BLUE'
            },





            //----------------
            //green
            //----------------
            {
                subCategory:false,  
                name:'Tahitian Green', 
                thumbComponent:'BUTTON_PILL_GREEN_TAHITIAN_GREEN',
                layerComponent:'BODY_SOLID_GREEN_TAHITIAN_GREEN'
            },
            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'BODY_SOLID_GREEN_LIME_GREEN'
            },




            //----------------
            //purple
            //----------------
            {
                subCategory:false,  
                name:'Purple', 
                thumbComponent:'BUTTON_PILL_PURPLE_PURPLE',
                layerComponent:'BODY_SOLID_PURPLE'
            },



            //----------------
            //gray
            //----------------
            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_PILL_GRAY_GRAND_PRIX_WHITE',
                layerComponent:'BODY_SOLID_GRAY_GRAND_PRIX_WHITE'
            },

            {
                subCategory:false,  
                name:'Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BLACK',
                layerComponent:'BODY_SOLID_GRAY_BLACK'
            },
            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
                layerComponent:'BODY_SOLID_GRAY_BERLINA_BLACK'
            },
            {
                subCategory:false,  
                name:'Gold', 
                thumbComponent:'BUTTON_PILL_GRAY_GOLD',
                layerComponent:'BODY_SOLID_GRAY_GOLD'
            },
            {
                subCategory:false,  
                name:'Gunmetal', 
                thumbComponent:'BUTTON_PILL_GRAY_GUNMETAL',
                layerComponent:'BODY_SOLID_GRAY_GUNMETAL'
            },
            {
                subCategory:false,  
                name:'Silver', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVER',
                layerComponent:'BODY_SOLID_GRAY_SILVER'
            },
            {
                subCategory:false,  
                name:'Silverstone', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVERSTONE',
                layerComponent:'BODY_SOLID_GRAY_SILVERSTONE'
            },
            {
                subCategory:false,  
                name:'Sunset Mauve', 
                thumbComponent:'BUTTON_PILL_GRAY_SUNSET_MAUVE',
                layerComponent:'BODY_SOLID_GRAY_SUNSET_MAUVE'
            }


        ],





        rect: [


            //----------------
            //red
            //----------------
            {
                subCategory:false,  
                name:'Black/Red', 
                thumbComponent:'BUTTON_RECT_RED_BLACK_RED',
                layerComponent:'BODY_SOLID_BLUE_APEX_BLUE'
            },



            //----------------
            //yellow
            //----------------
            {
                subCategory:false,  
                name:'Black/Yellow', 
                thumbComponent:'BUTTON_RECT_YELLOW_BLACK_YELLOW',
                layerComponent:'BODY_SOLID_BLUE_LAGUNA_BLUE'
            },



            //----------------
            //orange
            //----------------
            {
                subCategory:false,  
                name:'Black/Orange', 
                thumbComponent:'BUTTON_RECT_ORANGE_BLACK_ORANGE',
                layerComponent:'BODY_SOLID_YELLOW_SPA_YELLOW'
            },




            //----------------
            //blue
            //----------------
            {
                subCategory:false,  
                name:'Black/Blue', 
                thumbComponent:'BUTTON_RECT_BLUE_BLACK_BLUE',
                layerComponent:'BODY_SOLID_RED'
           
            },




            //----------------
            //green
            //----------------
            {
                subCategory:false,  
                name:'Black/Green', 
                thumbComponent:'BUTTON_RECT_GREEN_BLACK_GREEN',
                layerComponent:'BODY_SOLID_YELLOW_RIO_YELLOW'
            },



            //----------------
            //purple
            //----------------
            {
                subCategory:false,  
                name:'Black/Purple', 
                thumbComponent:'BUTTON_RECT_PURPLE_BLACK_PURPLE',
                layerComponent:'BODY_SOLID_BLUE'
            },



            //----------------
            //gray
            //----------------
            {
                subCategory:false,  
                name:'Black/Gray', 
                thumbComponent:'BUTTON_RECT_GRAY_BLACK_GRAY',
                layerComponent:'BODY_SOLID_RED_NEW_FORMULA_RED'
           
            }

        ],




        banner_buttons: [


            //----------------
            //red
            //----------------
            {
                subCategory:false,  
                name:'Black/Red', 
                thumbComponent:'BUTTON_RECT_RED_BLACK_RED',
                layerComponent:'BODY_SOLID_BLUE_APEX_BLUE'
            },



            //----------------
            //yellow
            //----------------
            {
                subCategory:false,  
                name:'Black/Yellow', 
                thumbComponent:'BUTTON_RECT_YELLOW_BLACK_YELLOW',
                layerComponent:'BODY_SOLID_BLUE_LAGUNA_BLUE'
            },



            //----------------
            //orange
            //----------------
            {
                subCategory:false,  
                name:'Black/Orange', 
                thumbComponent:'BUTTON_RECT_ORANGE_BLACK_ORANGE',
                layerComponent:'BODY_SOLID_YELLOW_SPA_YELLOW'
            },




            //----------------
            //blue
            //----------------
            {
                subCategory:false,  
                name:'Black/Blue', 
                thumbComponent:'BUTTON_RECT_BLUE_BLACK_BLUE',
                layerComponent:'BODY_SOLID_RED'
           
            },




            //----------------
            //green
            //----------------
            {
                subCategory:false,  
                name:'Black/Green', 
                thumbComponent:'BUTTON_RECT_GREEN_BLACK_GREEN',
                layerComponent:'BODY_SOLID_YELLOW_RIO_YELLOW'
            },



            //----------------
            //purple
            //----------------
            {
                subCategory:false,  
                name:'Black/Purple', 
                thumbComponent:'BUTTON_RECT_PURPLE_BLACK_PURPLE',
                layerComponent:'BODY_SOLID_BLUE'
            },



            //----------------
            //gray
            //----------------
            {
                subCategory:false,  
                name:'Black/Gray', 
                thumbComponent:'BUTTON_RECT_GRAY_BLACK_GRAY',
                layerComponent:'BODY_SOLID_RED_NEW_FORMULA_RED'
           
            }

        ],




        
 
        car_colors : [


            //----------------
            //red
            //----------------
            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BUTTON_PILL_RED_NEW_FORMULA_RED',
                layerComponent:'BODY_SOLID_RED_NEW_FORMULA_RED',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
           
            },

            //----------------
            //yellow
            //----------------
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_ELEMENT_CR_RIO_YELLOW',
                layerComponent:'BODY_SOLID_YELLOW_CR_RIO_YELLOW',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_CR_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_YELLOW_RIO_YELLOW'

                }
            },

            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
                layerComponent:'BODY_SOLID_YELLOW_SPA_YELLOW',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },


            //----------------
            //blue
            //----------------

            {
                subCategory:false,  
                name:'Royal Navy', 
                thumbComponent:'BUTTON_PILL_BLUE_ROYAL_NAVY',
                layerComponent:'BODY_SOLID_BLUE_ROYAL_NAVY',
                matchingComponents:{

                    hardtop:'',
                    softtop:'',
                    rollbar:'ROLLBAR_YELLOW_RIO_YELLOW',
                    tires:'TIRES_DUNLOP',         
                    wheels:'WHEELS_OEM_AP2V3_GRAY_SILVER',
                    caliper:'CALIPER_4P_FRONT_YELLOW_YELLOW',
                    banner:'BANNER_YELLOW_YELLOW',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_SOLID_YELLOW_RIO_YELLOW_1'

                }
            },

            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
                layerComponent:'BODY_SOLID_BLUE_MONTE_CARLO_BLUE',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },

            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
                layerComponent:'BODY_SOLID_BLUE_LAGUNA_BLUE',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP2V3_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },

            {
                subCategory:false,  
                name:'Apex Blue', 
                thumbComponent:'BUTTON_ELEMENT_CR_APEX_BLUE',
                layerComponent:'BODY_SOLID_BLUE_CR_APEX_BLUE',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_CR_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_BLUE_APEX_BLUE'

                }
            },


            {
                subCategory:false,  
                name:'Suzuka Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_SUZUKA_BLUE',
                layerComponent:'BODY_SOLID_BLUE_SUZUKA_BLUE',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },







            //----------------
            //green
            //----------------
            {
                subCategory:false,  
                name:'Tahitian Green', 
                thumbComponent:'BUTTON_PILL_GREEN_TAHITIAN_GREEN',
                layerComponent:'BODY_SOLID_GREEN_TAHITIAN_GREEN',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },
            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'BODY_SOLID_GREEN_LIME_GREEN',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP2V1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },





            //----------------
            //gray
            //----------------
            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_ELEMENT_CR_GRAND_PRIX_WHITE',
                layerComponent:'BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_CR_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE'

                }
            },

            // {
            //     subCategory:false,  
            //     name:'Black', 
            //     thumbComponent:'BUTTON_PILL_GRAY_BLACK',
            //     layerComponent:'BODY_SOLID_GRAY_BLACK'
            // },

            {
                subCategory:false,  
                name:'Berlina Black CR', 
                thumbComponent:'BUTTON_ELEMENT_CR_BERLINA_BLACK',
                layerComponent:'BODY_SOLID_GRAY_CR_BERLINA_BLACK',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_CR_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_GRAY_BERLINA_BLACK'

                }
            },

            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
                layerComponent:'BODY_SOLID_GRAY_BERLINA_BLACK',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },


            // {
            //     subCategory:false,  
            //     name:'Gold', 
            //     thumbComponent:'BUTTON_PILL_GRAY_GOLD',
            //     layerComponent:'BODY_SOLID_GRAY_GOLD'
            // },
            // {
            //     subCategory:false,  
            //     name:'Gunmetal', 
            //     thumbComponent:'BUTTON_PILL_GRAY_GUNMETAL',
            //     layerComponent:'BODY_SOLID_GRAY_GUNMETAL'
            // },
            // {
            //     subCategory:false,  
            //     name:'Silver', 
            //     thumbComponent:'BUTTON_PILL_GRAY_SILVER',
            //     layerComponent:'BODY_SOLID_GRAY_SILVER'
            // },

            {
                subCategory:false,  
                name:'Silverstone', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVERSTONE',
                layerComponent:'BODY_SOLID_GRAY_SILVERSTONE',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP2V1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },


            {
                subCategory:false,  
                name:'Moonrock', 
                thumbComponent:'BUTTON_PILL_GRAY_MOONROCK',
                layerComponent:'BODY_SOLID_GRAY_MOONROCK',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_MOONROCK',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_BRIDGESTONE',         
                    wheels:'WHEELS_BBS_GRAY_GOLD',
                    caliper:'CALIPER_4P_FRONT_ORANGE_ORANGE',
                    banner:'BANNER_ORANGE_ORANGE',
                    helmet:'HELMET_YELLOW_SOLID_RIO_YELLOW',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1'

                }
            },


            {
                subCategory:false,  
                name:'Sunset Mauve', 
                thumbComponent:'BUTTON_PILL_GRAY_SUNSET_MAUVE',
                layerComponent:'BODY_SOLID_GRAY_SUNSET_MAUVE',
                matchingComponents:{

                    hardtop:'',
                    softtop:'SOFTTOP_GRAY_BLACK',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP2V3_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },









            //##########################
            //THEMES
            //##########################

            {
                subCategory:false,  
                name:'Gulf', 
                thumbComponent:'BUTTON_LOGO_GULF',
                layerComponent:'BODY_THEME_BLUE_GULF',
                matchingComponents:{

                    //hardtop:'HARDTOP_OEM_SOLID_BLUE_GULF_BLUE',
                    hardtop:'',
                    softtop:'',
                    rollbar:'ROLLBAR_ORANGE_IMOLA_ORANGE',
                    tires:'TIRES_GOODYEAR',         
                    wheels:'WHEELS_RA300_SILVER',
                    caliper:'CALIPER_4P_FRONT_ORANGE_ORANGE',
                    banner:'BANNER_GRAY_SILVER',
                    helmet:'HELMET_GRAY_DRIVER_STEVE_MCQUEEN',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_CARBON_FIBER'


                }
           
            },
            {
                subCategory:false,  
                name:'Red Bull', 
                thumbComponent:'BUTTON_LOGO_REDBULL',
                layerComponent:'BODY_THEME_BLUE_REDBULL',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_PIRELLI_MEDIUM_YELLOW',         
                    wheels:'WHEELS_SW388_BLUE_BLACK_BLUE',
                    caliper:'CALIPER_4P_FRONT_RED_RED',
                    banner:'BANNER_YELLOW_YELLOW',
                    helmet:'HELMET_RED_SOLID_NEW_FORMULA_RED',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_SOLID_YELLOW_SPA_YELLOW_1'


                }
           
            },
            {
                subCategory:false,  
                name:'Marlboro', 
                thumbComponent:'BUTTON_LOGO_MARLBORO',
                layerComponent:'BODY_THEME_RED_MARLBORO',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_GOODYEAR_XL',         
                    wheels:'WHEELS_SW388_RED_BLACK_RED',
                    caliper:'CALIPER_4P_FRONT_RED_RED',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_YELLOW_DRIVER_AYRTON_SENNA',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_THEME_MARLBORO_12'

                             
                }
            },
            {
                subCategory:false,  
                name:'Canon', 
                thumbComponent:'BUTTON_LOGO_CANON',
                layerComponent:'BODY_THEME_YELLOW_CANON',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_GOODYEAR',         
                    wheels:'WHEELS_FONDMETAL',
                    caliper:'CALIPER_4P_FRONT_RED_RED',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_GRAY_DRIVER_NIGEL_MANSELL',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_THEME_CANON_5'


                }
            },
            {
                subCategory:false,  
                name:'Camel', 
                thumbComponent:'BUTTON_LOGO_CAMEL',
                layerComponent:'BODY_THEME_YELLOW_CAMEL',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_GOODYEAR_XL',         
                    wheels:'WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW',
                    caliper:'CALIPER_4P_FRONT_GRAY_SILVER',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_YELLOW_SOLID_SPA_YELLOW',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_THEME_CAMEL_12'


                }
            },
            {
                subCategory:false,  
                name:'Spoon', 
                thumbComponent:'BUTTON_LOGO_95',
                layerComponent:'BODY_THEME_YELLOW_SPOON',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_ADVAN',         
                    wheels:'WHEELS_SW388_BLUE_BLACK_BLUE',
                    caliper:'CALIPER_4P_FRONT_BLUE_BLUE',
                    banner:'BANNER_BLUE_BLUE',
                    helmet:'HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_CARBON_FIBER'


                }
            },
            {
                subCategory:false,  
                name:'Lucky Strike', 
                thumbComponent:'BUTTON_LOGO_LUCKY_STRIKE',
                layerComponent:'BODY_THEME_RED_LUCKY_STRIKE',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_THEME_RED_LUCKY_STRIKE',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_MICHELIN_FULL_COLORS',         
                    wheels:'WHEELS_BBS_GRAY_SILVER',
                    caliper:'CALIPER_4P_FRONT_GRAY_SILVER',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_THEME_LUCKY_STRIKE_1'


                }
            },
            {
                subCategory:false,  
                name:'Rothmans', 
                thumbComponent:'BUTTON_LOGO_ROTHMANS',
                layerComponent:'BODY_THEME_BLUE_ROTHMANS',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_MICHELIN_FULL_COLOR',         
                    wheels:'WHEELS_RA300_GOLD',
                    caliper:'CALIPER_4P_FRONT_RED_RED',
                    banner:'BANNER_YELLOW_YELLOW',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_THEME_ROTHMANS_1'


                }
            },
            {
                subCategory:false,  
                name:'Martini', 
                thumbComponent:'BUTTON_LOGO_MARTINI',
                layerComponent:'BODY_THEME_GRAY_MARTINI',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_THEME_GRAY_MARTINI',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_PIRELLI_SOFT_RED',         
                    wheels:'WHEELS_OZ_RACING_GRAY_BLACK_BLACK',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_SOLID_GRAY_BERLINA_BLACK_1'


                }
            },
            {
                subCategory:false,  
                name:'Benson & Hedges', 
                thumbComponent:'BUTTON_LOGO_BENSON_AND_HEDGES',
                layerComponent:'BODY_THEME_YELLOW_BENSON_AND_HEDGES',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_BRIDGESTONE',         
                    wheels:'WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW',
                    caliper:'CALIPER_4P_FRONT_RED_RED',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_SOLID_CARBON_FIBER'


                }
            },


            {
                subCategory:false,  
                name:'Honda RA300', 
                thumbComponent:'BUTTON_LOGO_HONDA_RA300',
                layerComponent:'BODY_THEME_GRAY_RA300',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES_FIRESTONE',         
                    wheels:'WHEELS_RA300_GOLD',
                    caliper:'CALIPER_4P_FRONT_GRAY_SILVER',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING_RACING_SOLID_CARBON_FIBER'


                }
           
            },





            //----------------
            //S2-R
            //----------------
            
            {
                subCategory:false,  
                name:'S2-R Berlina Black', 
                thumbComponent:'BUTTON_THEME_GRAY_S2R_BERLINA_BLACK',
                layerComponent:'BODY_THEME_GRAY_S2R_BERLINA_BLACK',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_TRS9_GRAY_BLACK',
                    caliper:'CALIPER_4P_FRONT_RED_RED',
                    banner:'BANNER_RED_RED',
                    helmet:'HELMET_RED_DRIVER_VAN_HALEN',
                    steering_wheel:'STEERING_WHEEL_RED_RED',
                    wing:'WING_RACING_SOLID_RED_NEW_FORMULA_RED_1'

                }
            },
            {
                subCategory:false,  
                name:'S2-R Grand Prix White', 
                thumbComponent:'BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE',
                layerComponent:'BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'


                }
            },
            {
                subCategory:false,  
                name:'S2-R Sebring Silver', 
                thumbComponent:'BUTTON_THEME_GRAY_S2R_SEBRING_SILVER',
                layerComponent:'BODY_THEME_GRAY_S2R_SEBRING_SILVER',
                matchingComponents:{

                    hardtop:'HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER',
                    softtop:'',
                    rollbar:'',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'


                }
            },
            {
                subCategory:false,  
                name:'S2-R New Formula Red', 
                thumbComponent:'BUTTON_THEME_RED_S2R_NEW_FORMULA_RED',
                layerComponent:'BODY_THEME_RED_S2R_NEW_FORMULA_RED',
                matchingComponents:{

                    hardtop:'',
                    softtop:'',
                    rollbar:'ROLLBAR_GRAY_BATTLESHIP_GRAY',
                    tires:'TIRES',         
                    wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
                    caliper:'CALIPER_OEM_FRONT_FACTORY',
                    banner:'BANNER_GRAY_BLACK',
                    helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                    steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
                    wing:'WING'

                }
            },






        ],

        





        tops: [



            {
                subCategory:"hardtop_colors",  
                name:'Hard Top', 
                thumbComponent:'BUTTON_MAIN_HARDTOP',
                layerComponent:'HARDTOP_NEW_FORMULA_RED'
           
            },
            {
                subCategory:"softtop_colors",  
                name:'Soft Top', 
                thumbComponent:'BUTTON_MAIN_SOFTTOP',
                layerComponent:'HARDTOP_RIO_YELLOW'
            },
            {
                subCategory:"rollbar_colors",  
                name:'Roll Bars', 
                thumbComponent:'BUTTON_MAIN_ROLLBAR',
                layerComponent:'HARDTOP_BLUE'
            }


        ],








        body_themes: [



            {
                subCategory:false,  
                name:'Gulf', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_BLUE_GULF'
           
            },
            {
                subCategory:false,  
                name:'Red Bull', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_BLUE_REDBULL'
           
            },
            {
                subCategory:false,  
                name:'Marlboro', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_RED_MARLBORO'
            },
            {
                subCategory:false,  
                name:'Camel', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_YELLOW_CAMEL'
            },
            {
                subCategory:false,  
                name:'Spoon', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_YELLOW_SPOON'
            },
            {
                subCategory:false,  
                name:'Lucky Strike', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_RED_LUCKY_STRIKE'
            },
            {
                subCategory:false,  
                name:'Rothmans', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_BLUE_ROTHMANS'
            },
            {
                subCategory:false,  
                name:'Martini', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_GRAY_MARTINI'
            },
            {
                subCategory:false,  
                name:'Benson & Hedges', 
                thumbComponent:'BODY_SOLID',
                layerComponent:'BODY_THEME_YELLOW_BENSON_AND_HEDGES'
            },




            //----------------
            //S2-R
            //----------------
            {
                subCategory:false,  
                name:'S2-R Blue/Red', 
                thumbComponent:'BUTTON_THEME_BLUE_S2R_BLUE_AND_RED',
                layerComponent:'BODY_THEME_BLUE_S2R_BLUE_AND_RED'
            },
            {
                subCategory:false,  
                name:'S2-R Blue/Yellow', 
                thumbComponent:'BUTTON_THEME_BLUE_S2R_BLUE_AND_YELLOW',
                layerComponent:'BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW'
            },
            {
                subCategory:false,  
                name:'S2-R Berlina Black', 
                thumbComponent:'BUTTON_THEME_GRAY_S2R_BERLINA_BLACK',
                layerComponent:'BODY_THEME_GRAY_S2R_BERLINA_BLACK'
            },
            {
                subCategory:false,  
                name:'S2-R Grand Prix White', 
                thumbComponent:'BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE',
                layerComponent:'BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE'
            },
            {
                subCategory:false,  
                name:'S2-R Sebring Silver', 
                thumbComponent:'BUTTON_THEME_GRAY_S2R_SEBRING_SILVER',
                layerComponent:'BODY_THEME_GRAY_S2R_SEBRING_SILVER'
            },
            {
                subCategory:false,  
                name:'S2-R New Formula Red', 
                thumbComponent:'BUTTON_THEME_RED_S2R_NEW_FORMULA_RED',
                layerComponent:'BODY_THEME_RED_S2R_NEW_FORMULA_RED'
            },



        ],






        wheels: [

            {
                subCategory:'wheels_oem',  
                name:'Honda OEM', 
                thumbComponent:'BUTTON_LOGO_HONDA',
                layerComponent:''
            },
            {
                subCategory:'wheels_ra300',  
                name:'Honda RA300', 
                thumbComponent:'BUTTON_LOGO_HONDA_RA300',
                layerComponent:''
            },
            {
                subCategory:'wheels_bbs',  
                name:'BBS Silver', 
                thumbComponent:'BUTTON_LOGO_BBS',
                layerComponent:''
            },       
            {
                subCategory:'wheels_oz',  
                name:'OZ Racing', 
                thumbComponent:'BUTTON_LOGO_OZ_RACING',
                layerComponent:''
            },
            {
                subCategory:'wheels_sw388',  
                name:'SW388', 
                thumbComponent:'BUTTON_LOGO_SW388',
                layerComponent:''
            },
            {
                subCategory:false,  
                name:'Fondmetal', 
                thumbComponent:'BUTTON_LOGO_FONDMETAL',
                layerComponent:'WHEELS_FONDMETAL'
            },
            {
                subCategory:false,  
                name:'TRS9', 
                thumbComponent:'BUTTON_LOGO_TRS9',
                layerComponent:'WHEELS_TRS9_GRAY_BLACK'
            }




        ],




        tires: [



            {
                subCategory:"",  
                name:'No Branding', 
                thumbComponent:'BUTTON_OFF',
                layerComponent:'TIRES'
            },


            {
                subCategory:false,  
                name:'Bridgestone', 
                thumbComponent:'BUTTON_LOGO_BRIDGESTONE',
                layerComponent:'TIRES_BRIDGESTONE'
            },


            {
                subCategory:"tires_dunlop",  
                name:'Dunlop', 
                thumbComponent:'BUTTON_LOGO_DUNLOP',
                layerComponent:'TIRES_DUNLOP'
            },

            {
                subCategory:false,  
                name:'Firestone', 
                thumbComponent:'BUTTON_LOGO_FIRESTONE',
                layerComponent:'TIRES_FIRESTONE'
            },


            {
                subCategory:"tires_goodyear",  
                name:'Goodyear', 
                thumbComponent:'BUTTON_LOGO_GOODYEAR',
                layerComponent:'TIRES_GOODYEAR'
            },


            {
                subCategory:false,  
                name:'Michelin', 
                thumbComponent:'BUTTON_LOGO_MICHELIN',
                layerComponent:'TIRES_MICHELIN_FULL_COLOR'
            },



            {
                subCategory:"tires_pirelli",  
                name:'Pirelli', 
                thumbComponent:'BUTTON_LOGO_PIRELLI',
                layerComponent:'TIRES_PIRELLI'
            },


            {
                subCategory:false,  
                name:'Yokohama Advan', 
                thumbComponent:'BUTTON_LOGO_ADVAN',
                layerComponent:'TIRES_ADVAN'
            },


        ],



        caliper_colors: [



            {
                subCategory:false,  
                name:'OEM', 
                thumbComponent:'BUTTON_LOGO_HONDA',
                layerComponent:'CALIPER_OEM_FRONT_FACTORY'
            },


            {
                subCategory:false,  
                name:'Red', 
                thumbComponent:'BUTTON_PILL_RED_RED',
                layerComponent:'CALIPER_4P_FRONT_RED_RED'
            },
            {
                subCategory:false,  
                name:'Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_YELLOW',
                layerComponent:'CALIPER_4P_FRONT_YELLOW_YELLOW'
            },
            {
                subCategory:false,  
                name:'Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_BLUE',
                layerComponent:'CALIPER_4P_FRONT_BLUE_BLUE'
            },
            {
                subCategory:false,  
                name:'Dark Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_DARK_BLUE',
                layerComponent:'CALIPER_4P_FRONT_BLUE_DARK_BLUE'
            },
            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'CALIPER_4P_FRONT_GREEN_LIME_GREEN'
            },
            {
                subCategory:false,  
                name:'Orange', 
                thumbComponent:'BUTTON_PILL_ORANGE_ORANGE',
                layerComponent:'CALIPER_4P_FRONT_ORANGE_ORANGE'
            },
            {
                subCategory:false,  
                name:'Purple', 
                thumbComponent:'BUTTON_PILL_PURPLE_PURPLE',
                layerComponent:'CALIPER_4P_FRONT_PURPLE_PURPLE'
            },
            {
                subCategory:false,  
                name:'Silver', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVER',
                layerComponent:'CALIPER_4P_FRONT_GRAY_SILVER'
            },


        ],




        helmet_choices: [



            // Drivers
            {
                subCategory:false,  
                name:'James Hunt', 
                thumbComponent:'BUTTON_HELMET_BLACK_DRIVER_JAMES_HUNT',
                layerComponent:'HELMET_BLACK_DRIVER_JAMES_HUNT'
            },

        
            {
                subCategory:false,  
                name:'Keiichi Tsuchiya', 
                thumbComponent:'BUTTON_HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA',
                layerComponent:'HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA'
            },

            {
                subCategory:false,  
                name:'Nelson Piquet', 
                thumbComponent:'BUTTON_HELMET_RED_DRIVER_NELSON_PIQUET',
                layerComponent:'HELMET_RED_DRIVER_NELSON_PIQUET'
            },

            {
                subCategory:false,  
                name:'Ayrton Senna', 
                thumbComponent:'BUTTON_HELMET_YELLOW_DRIVER_AYRTON_SENNA',
                layerComponent:'HELMET_YELLOW_DRIVER_AYRTON_SENNA'
           
            },
      
            {
                subCategory:false,  
                name:'Nigel Mansell', 
                thumbComponent:'BUTTON_HELMET_GRAY_DRIVER_NIGEL_MANSELL',
                layerComponent:'HELMET_GRAY_DRIVER_NIGEL_MANSELL'
            },

            {
                subCategory:false,  
                name:'The Stig', 
                thumbComponent:'BUTTON_HELMET_GRAY_DRIVER_THE_STIG',
                layerComponent:'HELMET_GRAY_DRIVER_THE_STIG'
            },

            {
                subCategory:false,  
                name:'Van Halen', 
                thumbComponent:'BUTTON_HELMET_RED_DRIVER_VAN_HALEN',
                layerComponent:'HELMET_RED_DRIVER_VAN_HALEN'
            },





            //Colors
            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_HELMET_BLACK_SOLID_BERLINA_BLACK',
                layerComponent:'HELMET_BLACK_SOLID_BERLINA_BLACK'
           
            },
            {
                subCategory:false,  
                name:'Apex Blue', 
                thumbComponent:'BUTTON_HELMET_BLUE_SOLID_APEX_BLUE',
                layerComponent:'HELMET_BLUE_SOLID_APEX_BLUE'
            },
            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_HELMET_BLUE_SOLID_LAGUNA_BLUE',
                layerComponent:'HELMET_BLUE_SOLID_LAGUNA_BLUE'
            },
  

            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
                layerComponent:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE'
           
            },
            {
                subCategory:false,  
                name:'Sunset Mauve', 
                thumbComponent:'BUTTON_HELMET_GRAY_SOLID_SUNSET_MAUVE',
                layerComponent:'HELMET_GRAY_SOLID_SUNSET_MAUVE'
            },

            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_HELMET_GREEN_SOLID_LIME_GREEN',
                layerComponent:'HELMET_GREEN_SOLID_LIME_GREEN'
           
            },

            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BUTTON_HELMET_RED_SOLID_NEW_FORMULA_RED',
                layerComponent:'HELMET_RED_SOLID_NEW_FORMULA_RED'
            },

            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_HELMET_YELLOW_SOLID_RIO_YELLOW',
                layerComponent:'HELMET_YELLOW_SOLID_RIO_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_HELMET_YELLOW_SOLID_SPA_YELLOW',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            }





        ],





        wing_choices: [


            //----------------
            //NONE
            //----------------

            {
                subCategory:"",  
                name:'No Wing', 
                thumbComponent:'BUTTON_OFF',
                layerComponent:'WING'
            },



            //----------------
            //carbon fiber
            //----------------
            {
                subCategory:false,  
                name:'Carbon Fiber', 
                thumbComponent:'BUTTON_PILL_GRAY_CARBON_FIBER',
                layerComponent:'WING_RACING_SOLID_CARBON_FIBER'
           
            },




            //----------------
            //red
            //----------------
            {
                subCategory:false,  
                name:'Red', 
                thumbComponent:'BUTTON_PILL_RED_RED',
                layerComponent:'WING_RACING_SOLID_RED_NEW_FORMULA_RED_1'
           
            },



            //----------------
            //yellow
            //----------------
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_RIO_YELLOW',
                layerComponent:'WING_RACING_SOLID_YELLOW_RIO_YELLOW_1'
            },

            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
                layerComponent:'WING_RACING_SOLID_YELLOW_SPA_YELLOW_1'
            },




            //----------------
            //blue
            //----------------
            {
                subCategory:false,  
                name:'Apex Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_APEX_BLUE',
                layerComponent:'WING_RACING_SOLID_BLUE_APEX_BLUE_1'
            },
            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
                layerComponent:'WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1'
            },
            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
                layerComponent:'WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1'
            },
            {
                subCategory:false,  
                name:'Suzuka Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_SUZUKA_BLUE',
                layerComponent:'WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1'
            },





            //----------------
            //green
            //----------------
            {
                subCategory:false,  
                name:'Tahitian Green', 
                thumbComponent:'BUTTON_PILL_GREEN_TAHITIAN_GREEN',
                layerComponent:'WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1'
            },
            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'WING_RACING_SOLID_GREEN_LIME_GREEN_1'
            },




            //----------------
            //purple
            //----------------
            // {
            //     subCategory:false,  
            //     name:'Purple', 
            //     thumbComponent:'BUTTON_PILL_PURPLE_PURPLE',
            //     layerComponent:'WING_RACING_SOLID_PURPLE_1'
            // },



            //----------------
            //gray
            //----------------
            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_PILL_GRAY_GRAND_PRIX_WHITE',
                layerComponent:'WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1'
            },

            // {
            //     subCategory:false,  
            //     name:'Black', 
            //     thumbComponent:'BUTTON_PILL_GRAY_BLACK',
            //     layerComponent:'WING_RACING_SOLID_GRAY_BLACK_1'
            // },
            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
                layerComponent:'WING_RACING_SOLID_GRAY_BERLINA_BLACK_1'
            },
            // {
            //     subCategory:false,  
            //     name:'Gold', 
            //     thumbComponent:'BUTTON_PILL_GRAY_GOLD',
            //     layerComponent:'WING_RACING_SOLID_GRAY_GOLD_1'
            // },
            // {
            //     subCategory:false,  
            //     name:'Gunmetal', 
            //     thumbComponent:'BUTTON_PILL_GRAY_GUNMETAL',
            //     layerComponent:'WING_RACING_SOLID_GRAY_GUNMETAL_1'
            // },
            // {
            //     subCategory:false,  
            //     name:'Silver', 
            //     thumbComponent:'BUTTON_PILL_GRAY_SILVER',
            //     layerComponent:'WING_RACING_SOLID_GRAY_SILVER_1'
            // },
            {
                subCategory:false,  
                name:'Silverstone', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVERSTONE',
                layerComponent:'WING_RACING_SOLID_GRAY_SILVERSTONE_1'
            },
            // {
            //     subCategory:false,  
            //     name:'Sunset Mauve', 
            //     thumbComponent:'BUTTON_PILL_GRAY_SUNSET_MAUVE',
            //     layerComponent:'WING_RACING_SOLID_GRAY_SUNSET_MAUVE_1'
            // },





            //----------------
            //themes
            //----------------
            {
                subCategory:false,  
                name:'Camel', 
                thumbComponent:'BUTTON_LOGO_CAMEL',
                layerComponent:'WING_RACING_THEME_CAMEL_12'
            },

            {
                subCategory:false,  
                name:'Canon', 
                thumbComponent:'BUTTON_LOGO_CANON',
                layerComponent:'WING_RACING_THEME_CANON_5'
            },
            {
                subCategory:false,  
                name:'Lucky Strike', 
                thumbComponent:'BUTTON_LOGO_LUCKY_STRIKE',
                layerComponent:'WING_RACING_THEME_LUCKY_STRIKE_1'
            },
            {
                subCategory:false,  
                name:'Marlboro', 
                thumbComponent:'BUTTON_LOGO_MARLBORO',
                layerComponent:'WING_RACING_THEME_MARLBORO_12'
            },
            {
                subCategory:false,  
                name:'Rothmans', 
                thumbComponent:'BUTTON_LOGO_ROTHMANS',
                layerComponent:'WING_RACING_THEME_ROTHMANS_1'
            }



        ],








        logo: [


            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_LOGO_ADVAN',
                layerComponent:'HELMET_YELLOW_SOLID_RIO_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_AP1V1',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_AP2V1',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_AP2V3',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_BBS',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_CR',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_GOODYEAR',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_OZ_RACING',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_PIRELLI',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_SW338',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_LOGO_TR59',
                layerComponent:'HELMET_YELLOW_SOLID_SPA_YELLOW'
            }



        ],





        steering_wheel: [

            {
                subCategory:false,  
                name:'Steering Wheel Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BLACK',
                layerComponent:'STEERING_WHEEL_GRAY_BLACK'
            },
            {
                subCategory:false,  
                name:'Steering Wheel Red', 
                thumbComponent:'BUTTON_PILL_RED_RED',
                layerComponent:'STEERING_WHEEL_RED_RED'
            }


        ],





        accessories: [

            {
                subCategory:false,  
                name:'Banner Blue', 
                thumbComponent:'BUTTON_BANNER_BLUE_BLUE',
                layerComponent:'BANNER_BLUE_BLUE'
            },
            {
                subCategory:false,  
                name:'Banner Red', 
                thumbComponent:'BUTTON_BANNER_RED_RED',
                layerComponent:'BANNER_RED_RED'
            },
            {
                subCategory:false,  
                name:'Banner Yellow', 
                thumbComponent:'BUTTON_BANNER_YELLOW_YELLOW',
                layerComponent:'BANNER_YELLOW_YELLOW'
            },
            {
                subCategory:false,  
                name:'Banner Lime Green', 
                thumbComponent:'BUTTON_BANNER_GREEN_LIME_GREEN',
                layerComponent:'BANNER_GREEN_LIME_GREEN'
            },
            {
                subCategory:false,  
                name:'Banner Purple', 
                thumbComponent:'BUTTON_BANNER_PURPLE_PURPLE',
                layerComponent:'BANNER_PURPLE_PURPLE'
            },
            {
                subCategory:false,  
                name:'Banner Orange', 
                thumbComponent:'BUTTON_BANNER_ORANGE_ORANGE',
                layerComponent:'BANNER_ORANGE_ORANGE'
            },
            {
                subCategory:false,  
                name:'Banner Silver', 
                thumbComponent:'BUTTON_BANNER_GRAY_SILVER',
                layerComponent:'BANNER_GRAY_SILVER'
            },
            {
                subCategory:false,  
                name:'Banner Black', 
                thumbComponent:'BUTTON_BANNER_GRAY_BLACK',
                layerComponent:'BANNER_GRAY_BLACK'
            }



        ]






    },











    //######################################################
    //######################################################


    subB : {

        

        car_colors: [


            //----------------
            //red
            //----------------
            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BUTTON_PILL_RED_NEW_FORMULA_RED',
                layerComponent:'BODY_RED_NEW_FORMULA'
           
            },


            //----------------
            //yellow
            //----------------
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_RIO_YELLOW',
                layerComponent:'BODY_YELLOW_RIO'
            },

            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
                layerComponent:'BODY_YELLOW_SPA'
            },


            //----------------
            //blue
            //----------------
            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Suzuka Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_SUZUKA_BLUE',
                layerComponent:'BODY_BLUE'
            },




            //----------------
            //green
            //----------------
            {
                subCategory:false,  
                name:'Tahitian Green', 
                thumbComponent:'BUTTON_PILL_GREEN_TAHITIAN_GREEN',
                layerComponent:'BODY_GREEN_LIME'
            },
            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'BODY_GREEN_LIME'
            },





            //----------------
            //gray
            //----------------
            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_PILL_GRAY_GRAND_PRIX_WHITE',
                layerComponent:'BODY_BLUE'
            },

            {
                subCategory:false,  
                name:'Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BLACK',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Gold', 
                thumbComponent:'BUTTON_PILL_GRAY_GOLD',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Gunmetal', 
                thumbComponent:'BUTTON_PILL_GRAY_GUNMETAL',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Silver', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVER',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Silverstone', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVERSTONE',
                layerComponent:'BODY_BLUE'
            },
            {
                subCategory:false,  
                name:'Moonrock', 
                thumbComponent:'BUTTON_PILL_GRAY_MOONROCK',
                layerComponent:'BODY_SOLID_GRAY_MOONROCK'
            },
            {
                subCategory:false,  
                name:'Sunset Mauve', 
                thumbComponent:'BUTTON_PILL_GRAY_SUNSET_MAUVE',
                layerComponent:'BODY_BLUE'
            }


        ],






        hardtop_colors: [



            {
                subCategory:false,  
                name:'Apex Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_APEX_BLUE',
                layerComponent:'HARDTOP_OEM_SOLID_BLUE_APEX_BLUE'
            },
            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
                layerComponent:'HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE'
            },
            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
                layerComponent:'HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE'
            },
            {
                subCategory:false,  
                name:'Royal Navy', 
                thumbComponent:'BUTTON_PILL_BLUE_ROYAL_NAVY',
                layerComponent:'HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY'
            },

            {
                subCategory:false,  
                name:'Suzuka Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_SUZUKA_BLUE',
                layerComponent:'HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE'
            },




            //----------------------------

            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
                layerComponent:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK'
            },
            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_PILL_GRAY_GRAND_PRIX_WHITE',
                layerComponent:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE'
            },
            {
                subCategory:false,  
                name:'Sebring Silver', 
                thumbComponent:'BUTTON_PILL_GRAY_SEBRING_SILVER',
                layerComponent:'HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER'
            },
            {
                subCategory:false,  
                name:'Silverstone', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVERSTONE',
                layerComponent:'HARDTOP_OEM_SOLID_GRAY_SILVERSTONE'
            },
            {
                subCategory:false,  
                name:'Moonrock', 
                thumbComponent:'BUTTON_PILL_GRAY_MOONROCK',
                layerComponent:'HARDTOP_OEM_SOLID_GRAY_MOONROCK'
            },
            {
                subCategory:false,  
                name:'Sunset Mauve', 
                thumbComponent:'BUTTON_PILL_GRAY_SUNSET_MAUVE',
                layerComponent:'HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE'
            },







            //--------------------------------
            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'HARDTOP_OEM_SOLID_GREEN_LIME_GREEN'
            },
            {
                subCategory:false,  
                name:'Tahitian Green', 
                thumbComponent:'BUTTON_PILL_GREEN_TAHITIAN_GREEN',
                layerComponent:'HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN'
            },





            //---------------------------------
            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BUTTON_PILL_RED_NEW_FORMULA_RED',
                layerComponent:'HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED'
            },





            //---------------------------------
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_RIO_YELLOW',
                layerComponent:'HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW'
            },
            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
                layerComponent:'HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW'
            },





            //-----------------------------------
            // Themes
            //-----------------------------------
            // {
            //     subCategory:false,  
            //     name:'Martini', 
            //     thumbComponent:'BUTTON_LOGO_MARTINI',
            //     layerComponent:'HARDTOP_OEM_THEME_GRAY_MARTINI'
            // },
            // {
            //     subCategory:false,  
            //     name:'Lucky Strike', 
            //     thumbComponent:'BUTTON_LOGO_LUCKY_STRIKE',
            //     layerComponent:'HARDTOP_OEM_THEME_RED_LUCKY_STRIKE'
            // },
            // {
            //     subCategory:false,  
            //     name:'Benson & Hedges', 
            //     thumbComponent:'BUTTON_LOGO_BENSON_AND_HEDGES',
            //     layerComponent:'HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES'
            // },








        ],



        softtop_colors: [


            {
                subCategory:false,  
                name:'Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BLACK',
                layerComponent:'SOFTTOP_GRAY_BLACK'
            },



        ],


        rollbar_colors: [


            {
                subCategory:false,  
                name:'Grand Prix White', 
                thumbComponent:'BUTTON_PILL_GRAY_GRAND_PRIX_WHITE',
                layerComponent:'ROLLBAR_GRAY_GRAND_PRIX_WHITE'
            },
            {
                subCategory:false,  
                name:'Berlina Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
                layerComponent:'ROLLBAR_GRAY_BERLINA_BLACK'
            },
            {
                subCategory:false,  
                name:'Apex Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_BLUE',
                layerComponent:'ROLLBAR_BLUE_APEX_BLUE'
            },
            {
                subCategory:false,  
                name:'Laguna Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
                layerComponent:'ROLLBAR_BLUE_LAGUNA_BLUE'
            },
            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
                layerComponent:'ROLLBAR_BLUE_MONTE_CARLO_BLUE'
            },
            {
                subCategory:false,  
                name:'Monte Carlo Blue', 
                thumbComponent:'BUTTON_PILL_BLUE_ROYAL_NAVY',
                layerComponent:'ROLLBAR_BLUE_ROYAL_NAVY'
            },

            {
                subCategory:false,  
                name:'Lime Green', 
                thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
                layerComponent:'ROLLBAR_GREEN_LIME_GREEN'
            },
            {
                subCategory:false,  
                name:'Imola Orange', 
                thumbComponent:'BUTTON_PILL_ORANGE_IMOLA_ORANGE',
                layerComponent:'ROLLBAR_ORANGE_IMOLA_ORANGE'
            },
            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BUTTON_PILL_RED_NEW_FORMULA_RED',
                layerComponent:'ROLLBAR_RED_NEW_FORMULA_RED'
            },
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_RIO_YELLOW',
                layerComponent:'ROLLBAR_YELLOW_RIO_YELLOW'
            },

            {
                subCategory:false,  
                name:'Spa Yellow', 
                thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
                layerComponent:'ROLLBAR_YELLOW_SPA_YELLOW'
            }




        ],










        //=========================
        //tires
        //=========================



        tires_dunlop: [


            {
                subCategory:false,  
                name:'Dunlop', 
                thumbComponent:'BUTTON_TIRE_DUNLOP',
                layerComponent:'TIRES_DUNLOP'
            },


            {
                subCategory:false,  
                name:'Dunlop Reversed', 
                thumbComponent:'BUTTON_TIRE_DUNLOP_REVERSED',
                layerComponent:'TIRES_DUNLOP_REVERSED'
            }


        ],


        tires_goodyear: [


            {
                subCategory:false,  
                name:'Goodyear', 
                thumbComponent:'BUTTON_LOGO_GOODYEAR',
                layerComponent:'TIRES_GOODYEAR'
            },


            {
                subCategory:false,  
                name:'Goodyear XL', 
                thumbComponent:'BUTTON_LOGO_GOODYEAR',
                layerComponent:'TIRES_GOODYEAR_XL'
            }


        ],



        tires_michelin: [


            {
                subCategory:false,  
                name:'Michelin', 
                thumbComponent:'BUTTON_LOGO_MICHELIN',
                layerComponent:'TIRES_MICHELIN_FULL_COLOR'
            }

        ],




        tires_pirelli: [



            //-----------------
            // HARD
            //-----------------

            {
                subCategory:false,  
                name:'Pirelli Blue (Hard)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_HARD_BLUE',
                layerComponent:'TIRES_PIRELLI_HARD_BLUE'
            },

            {
                subCategory:false,  
                name:'Pirelli Blue (Wet)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_WET_BLUE',
                layerComponent:'TIRES_PIRELLI_WET_BLUE'
            },


            {
                subCategory:false,  
                name:'Pirelli Green (Inter.)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_INTER_GREEN',
                layerComponent:'TIRES_PIRELLI_INTER_GREEN'
            },






            //-----------------
            // SOFT
            //-----------------
            {
                subCategory:false,  
                name:'Pirelli Magenta (Soft)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_SOFT_MAGENTA',
                layerComponent:'TIRES_PIRELLI_SOFT_MAGENTA'
            },

            {
                subCategory:false,  
                name:'Pirelli Pink (Soft)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_SOFT_PINK',
                layerComponent:'TIRES_PIRELLI_SOFT_PINK'
            },


            {
                subCategory:false,  
                name:'Pirelli Red (Soft)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_SOFT_RED',
                layerComponent:'TIRES_PIRELLI_SOFT_RED'
            },



            {
                subCategory:false,  
                name:'Pirelli Orange (Hard)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_HARD_ORANGE',
                layerComponent:'TIRES_PIRELLI_HARD_ORANGE'
            },


            //-----------------
            // MEDIUM
            //-----------------

            {
                subCategory:false,  
                name:'Pirelli White (Med.)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_MEDIUM_WHITE',
                layerComponent:'TIRES_PIRELLI_MEDIUM_WHITE'
            },


            {
                subCategory:false,  
                name:'Pirelli Yellow (Med.)', 
                thumbComponent:'BUTTON_TIRE_PIRELLI_MEDIUM_YELLOW',
                layerComponent:'TIRES_PIRELLI_MEDIUM_YELLOW'
            },




        ],











        //===========================
        //wheels
        //===========================


        wheels_oem: [

            {
                subCategory:false,  
                name:'AP1', 
                thumbComponent:'BUTTON_LOGO_AP1V1',
                layerComponent:'WHEELS_OEM_AP1_GRAY_SILVER'
            },
            {
                subCategory:false,  
                name:'AP2', 
                thumbComponent:'BUTTON_LOGO_AP2V1',
                layerComponent:'WHEELS_OEM_AP2V1_GRAY_SILVER'
            },

            {
                subCategory:false,  
                name:'AP2', 
                thumbComponent:'BUTTON_LOGO_AP2V3',
                layerComponent:'WHEELS_OEM_AP2V3_GRAY_SILVER'
            },


            {
                subCategory:false,  
                name:'CR', 
                thumbComponent:'BUTTON_LOGO_CR',
                layerComponent:'WHEELS_OEM_CR_GRAY_SILVER'
            },



        ],



        //-----------------
        //BBS
        //-----------------

        wheels_bbs: [



            {
                subCategory:false,  
                name:'BBS Silver', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVER',
                layerComponent:'WHEELS_BBS_GRAY_SILVER'
            },       
            {
                subCategory:false,  
                name:'BBS Gold', 
                thumbComponent:'BUTTON_PILL_GRAY_GOLD',
                layerComponent:'WHEELS_BBS_GRAY_GOLD'
            },

            {
                subCategory:false,  
                name:'BBS Gunmetal', 
                thumbComponent:'BUTTON_PILL_GRAY_GUNMETAL',
                layerComponent:'WHEELS_BBS_GRAY_GUNMETAL'
            },

            {
                subCategory:false,  
                name:'BBS Black', 
                thumbComponent:'BUTTON_PILL_GRAY_BLACK',
                layerComponent:'WHEELS_BBS_GRAY_BLACK'
            },

        ],




        //-----------------
        //OZ
        //-----------------

        wheels_oz:[


            {
                subCategory:false,  
                name:'OZ Racing Black/Blue', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_BLUE_BLUE',
                layerComponent:'WHEELS_OZ_RACING_BLUE_BLACK_BLUE'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black/Dark Blue', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_BLUE_DARK_BLUE',
                layerComponent:'WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black', 
                thumbComponent:'BUTTON_LOGO_OZ_RACING',
                layerComponent:'WHEELS_OZ_RACING_GRAY_BLACK_BLACK'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black/White', 
                thumbComponent:'BUTTON_LOGO_OZ_RACING',
                layerComponent:'WHEELS_OZ_RACING_GRAY_BLACK_WHITE'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black/Green', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_GREEN_BLACK_GREEN',
                layerComponent:'WHEELS_OZ_RACING_GREEN_BLACK_GREEN'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black/Orange', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_ORANGE_BLACK_ORANGE',
                layerComponent:'WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE'
            },

            {
                subCategory:false,  
                name:'OZ Racing Black/Purple', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_PURPLE_BLACK_PURPLE',
                layerComponent:'WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black/Red', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_RED_BLACK_RED',
                layerComponent:'WHEELS_OZ_RACING_RED_BLACK_RED'
            },
            {
                subCategory:false,  
                name:'OZ Racing Black/Yellow', 
                thumbComponent:'BUTTON_WHEEL_OZ_RACING_YELLOW_BLACK_YELLOW',
                layerComponent:'WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW'
            },



        ],





        //-----------------
        //sw388
        //-----------------

        wheels_sw388:[


            {
                subCategory:false,  
                name:'SW388 Black/Blue', 
                thumbComponent:'BUTTON_RECT_BLUE_BLACK_BLUE',
                layerComponent:'WHEELS_SW388_BLUE_BLACK_BLUE'
            },
            {
                subCategory:false,  
                name:'SW388 Black/Gray', 
                thumbComponent:'BUTTON_RECT_GRAY_BLACK_GRAY',
                layerComponent:'WHEELS_SW388_GRAY_BLACK_GRAY'
            },
            {
                subCategory:false,  
                name:'SW388 Black/Green', 
                thumbComponent:'BUTTON_RECT_GREEN_BLACK_GREEN',
                layerComponent:'WHEELS_SW388_GREEN_BLACK_GREEN'
            },
            {
                subCategory:false,  
                name:'SW388 Black/Orange', 
                thumbComponent:'BUTTON_RECT_ORANGE_BLACK_ORANGE',
                layerComponent:'WHEELS_SW388_ORANGE_BLACK_ORANGE'
            },
            {
                subCategory:false,  
                name:'SW388 Black/Purple', 
                thumbComponent:'BUTTON_RECT_PURPLE_BLACK_PURPLE',
                layerComponent:'WHEELS_SW388_PURPLE_BLACK_PURPLE'
            },
            {
                subCategory:false,  
                name:'SW388 Black/Red', 
                thumbComponent:'BUTTON_RECT_RED_BLACK_RED',
                layerComponent:'WHEELS_SW388_RED_BLACK_RED'
            },
            {
                subCategory:false,  
                name:'SW388 Black/Yellow', 
                thumbComponent:'BUTTON_RECT_YELLOW_BLACK_YELLOW',
                layerComponent:'WHEELS_SW388_YELLOW_BLACK_YELLOW'
            }



        ],




        //-----------------
        //ra300
        //-----------------

        wheels_ra300:[


            {
                subCategory:false,  
                name:'RA300 Gold', 
                thumbComponent:'BUTTON_PILL_GRAY_GOLD',
                layerComponent:'WHEELS_RA300_GOLD'
            },
            {
                subCategory:false,  
                name:'RA300 Silver', 
                thumbComponent:'BUTTON_PILL_GRAY_SILVER',
                layerComponent:'WHEELS_RA300_SILVER'
            },


        ],





    },









    //######################################################
    //######################################################


    subC : {

        
        carColors: [

            {
                subCategory:false,  
                name:'New Formula Red', 
                thumbComponent:'BODY_COLOR_RED_NEW_FORMULA_RED',
                layerComponent:''
           
            },
            {
                subCategory:false,  
                name:'Rio Yellow', 
                thumbComponent:'BODY_COLOR_YELLOW_RIO_YELLOW',
                layerComponent:''
            },
            {
                subCategory:false,  
                name:'Blue', 
                thumbComponent:'BODY_COLOR_BLUE',
                layerComponent:''
            }
        ]


    },





}
export default ControlData;
