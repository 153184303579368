
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />


*/



export const Header = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#1a1926",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;

    position:relative;
    float:left;

`;






export const HeaderMain = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;
`;



    export const HeaderLogo = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "auto",
        height:             props.height || "54px",
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 10px"

        //pointer-events:none;

    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        cursor:pointer;

    `;



    export const HeaderTitle = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "auto",
        height:             props.height || "30px",
        padding:            props.padding || "5px 5px 0 5px",
        margin:             props.margin  || "8px 15px 10px 10px"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        font-family:"Verdana", sans-serif;      
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#fff;

    `;











    export const HeaderArrowButton = styled.div.attrs(props => ({


        backgroundColor:    props.backgroundColor || "transparent",// #fcfcfc  -  #07a2e3
        border:             props.border || "1px solid white",
        borderRadius:       props.borderRadius || "5px",
    
        width:              props.width || "24px", 
        height:             props.height || "24px",
    
        margin:             props.margin || "5px 1px 0 15px", 
        padding:            props.padding || "7px 7px 7px 7px",
    
        position:           props.position || "relative", 
        float:              props.float || "left",
    
        fontFamily:         props.fontFamily || '"Verdana", sans-serif',
        fontSize:           props.fontSize || "40px",
        fontWeight:         props.fontWeight || "normal",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "#fff",
    
    
    
    
        // alfa
    
        alfaPercent:         props.alfaPercent || '40',
        alfaDecimal:         props.alfaDecimal || "0.4",
    
    
    
    
    
    
    }))`
      
    
    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};
    
    width:${props => props.width};
    height:${props => props.height};
    
    margin:${props => props.margin};
    padding:${props => props.padding};
    
    position:${props => props.position};
    float:${props => props.float};
    
    overflow:hidden;
    
    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};
    
    
    
    .arrowbox-alfa{
                
        background-color:black;
    
        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:relative;   
        float:left;
    
        -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.alfaPercent})";
        filter : alpha(opacity=${props => props.alfaPercent});
        -moz-opacity : ${props => props.alfaDecimal};
        -khtml-opacity : ${props => props.alfaDecimal};
        opacity : ${props => props.alfaDecimal};
    
    }
    
    .arrowbox-bg{
                
        background-color:white;
        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:absolute;   
        z-index:1;
        top:0;
        left:0;
    
    }
    
    
    
    .arrowbox-mask{
                
        background-color:transparent;
        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:absolute;   
        z-index:5;
        top:0;
        left:0;
    
    }
    
    
    
    .arrowbox-base{
                
        background-color:transparent;
        width:25px;
        height:20px;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:absolute;   
        z-index:3;
        top:6px;
        left:9px;
    
    }
    
    .arrowbox{
                
        background-color:transparent;
        width:25px;
        height:20px;
        padding:0 0 0 0;
        margin:2px 0 0 12px;
    
        position:relative;   
        float:left;
    
    
    }
    
    
    
        .arrow-left {
        
            width: 0; 
            height: 0; 
            border-top: 13px solid transparent;
            border-bottom: 13px solid transparent; 
            
            border-right:16px solid #1a1926; 
        
        
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
        
        }
    
        .arrow-right {
    
            width: 0; 
            height: 0; 
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            
            border-left: 14px solid #454D5F;
        
        
            float:right;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
        
        }
          
    
    
    
        .arrow-up {
    
        
            width: 0; 
            height: 0; 
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            
            border-bottom: 12px solid darkred;
    
    
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;
    
        }
      
    
        .arrow-down {
    
            width: 0; 
            height: 0; 
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            
            border-top: 12px solid darkred;
    
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
    
        }
      
    
    
    
    
    `;
    
    
    
    
    
    
    
    
    export const Hamburger = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "30px",
        height:             props.height || "30px",
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "10px 15px 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:right;

        cursor:pointer;


        .bun {

            background-color:lightgray;
            border-radius:4px;

            width:100%;
            height:4px;
            margin: 4px 0 2px 0;
            padding: 0 0 0 0;

            position:relative;
            float:left;

    
        }

    `;
    
    
    

    


