import styled from 'styled-components';
 



const SliderGroup = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",//#fcfcfc
    border:             props.border || "0",

    width:              props.width || "99.5%",

  
}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    
    width:${props => props.width};
    height: auto;
    max-width:450px;
    padding:0 0 0 0;
    margin:0 auto 0 auto;
    position: relative;
    overflow: visible;

`;





const SliderContainer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
  
}))`

    background-color:${props => props.backgroundColor};
        
    width:100%;
    height: auto;
    max-width:350px;
    padding:0 0 0 0;
    margin:0 auto 0 auto;
    position: relative;
    overflow: visible;

`;




    const SliderMessage = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc


        //title
        titleBackgroundColor:       props.titleBackgroundColor || "transparent",
        titleWidth:                 props.titleWidth || "44%",

        titlePadding:               props.titlePadding || "0 0 0 0",
        titleMargin:                props.titleMargin || "10px 0 0 0",

        titleFloat:                 props.titleFloat || "left",

        titleFontSize:              props.titleFontSize || "16px",
        titleFontWeight:            props.titleFontWeight || "bold",
        titleFontColor:             props.titleFontColor || "black",
        titleTextAlign:             props.titleTextAlign || "left",
        titleTextDecoration:        props.titleTextDecoration || "none",


        //readout
        readoutBackgroundColor:     props.readoutBackgroundColor || "transparent",
        readoutWidth:               props.readoutWidth || "44%",

        readoutPadding:             props.readoutPadding || "0 0 0 0",
        readoutMargin:              props.readoutMargin || "0 0 0 0",

        readoutFloat:               props.readoutFloat || "right",

        readoutFontSize:            props.readoutFontSize || "30px",
        readoutFontWeight:          props.readoutFontWeight || "bold",
        readoutFontColor:           props.readoutFontColor || "black",
        readoutTextAlign:           props.readoutTextAlign || "right",
        readoutTextDecoration:      props.readoutTextDecoration || "none",
        


    }))`

        background-color:${props => props.backgroundColor};
            
        width:98%;
        height:40px;
        min-height:30px
        padding:0 0 0 0;
        margin:0 auto 0 auto;
        position: relative;
        overflow: visible;



        .Title{

            background-color:${props => props.titleBackgroundColor};
            width:${props => props.titleWidth};
            height:auto;

            padding:${props => props.titlePadding};
            margin:${props => props.titleMargin};

            position:relative;
            float:${props => props.titleFloat};

            font-size: ${props => props.titleFontSize};
            font-weight: ${props => props.titleFontWeight};
            text-align: ${props => props.titleTextAlign};
            text-decoration:${props => props.titleTextDecoration};
            color:${props => props.titleFontColor};
            line-height: 1.3;


            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;


        }

        .Readout{

            background-color:${props => props.readoutBackgroundColor};
            width:${props => props.readoutWidth};
            height:auto;

            padding:${props => props.readoutPadding};
            margin:${props => props.readoutMargin};

            position:relative;
            float:${props => props.readoutFloat};

            font-size: ${props => props.readoutFontSize};
            font-weight: ${props => props.readoutFontWeight};
            text-align: ${props => props.readoutTextAlign};
            text-decoration:${props => props.readoutTextDecoration};
            color:${props => props.readoutFontColor};
            line-height: 1.3;


            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;



    
        }


    `;



    const SliderBox = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    
    }))`

        background-color:${props => props.backgroundColor};
            
        width:100%;
        height: auto;
        padding:7px 0 7px 0;
        margin:0 auto 0 auto;
        position: relative;
        overflow: visible;

    `;






const Ranger = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",

    width:              props.width || "100%",


    //colour
    bandColourBgColor:  props.bandColourBgColor || "#00ff00",
    bandColourWidth:  props.bandColourWidth || "50%",
    bandColourOpacity:  props.bandColourOpacity || "0.6"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    
	width:${props => props.width};
 	height:36px;
    max-width:350px;
    padding:0 0 0 0;
    margin:0 auto 0 auto;
    position: relative;
    overflow: visible;



    .RangeBand{

        background:transparent;
    
        width:100%;
        height:100%;
        
        position:relative;
        float:left;

        margin:0 0 0 0;
        padding:0 0 0 0;

    }
        .RangeBand .Bg{

            background:#f9f9f9;
            border:0;
            border-radius: 10px;
        
            width:99.5%;
            height:15px;
            
            position:relative;
            float:left;

            margin:10px 0 0 1px;
            padding:0 0 0 0;

            overflow:hidden;

        }

        .RangeBand .Bg .Colour{

            background:${props => props.bandColourBgColor};
            width:${props => props.bandColourWidth};
            height:100%;

            opacity:${props => props.bandColourOpacity};
            
        }


    .RangeWrap{

        background:transparent;

        width:100%;
        height:100%;
        
        position:absolute;
        top:0px:
        left:0px;
        z-index:10;
    
    }

        .RangeWrap .RangeInp{

            background:transparent;
            border:1px solid black;
            border-radius: 10px;
        
            width:99.5%;
            height:15px;
            
            position:relative;
            float:left;
        
            margin:9px 0 0 0;
            padding:0 0 0 0;
        
            outline: none;
        
            -webkit-appearance: none;
            -webkit-transition: .2s;
            transition: opacity .2s;
        
        }

        
        .RangeWrap .RangeInp::-webkit-slider-thumb {
        
            background:#f9f9f9;
            border:2px solid black;
            border-radius: 50%;

            width: 32px;
            height: 32px;
            margin-top:0px;
        
            cursor: pointer;
            
            -webkit-appearance: none;
            appearance: none;

        }
        
        .RangeWrap .RangeInp::-moz-range-thumb {
        
            background: white;
            border:2px solid black;  
            border-radius: 50%;
        
            width: 32px;
            height: 32px;
            margin-top:0px;

            cursor: pointer;
        }
        


`;






export {

    SliderGroup,
    SliderContainer,
    SliderMessage,
    SliderBox,
    Ranger

}




/*

var inp_id = ranger_id + "_inp";
var inp_cls = 'range-inp';
var inp_value = 50;

    var inp = document.createElement("input");
    inp.setAttribute("type","range");
    inp.setAttribute("class",inp_cls)
    inp.setAttribute("id", inp_id);
    inp.setAttribute("value",inp_value);
    //inp.setAttribute("border", "0");
                            
ranger.appendChild(inp);


*/






/*

const RangerFadeIn = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",//#fcfcfc
    border:             props.border || "1px solid lightgray",

    width:              props.width || "99.5%",

  
}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    
	width:${props => props.width};
 	height:auto;
    max-width:450px;
    padding:0 0 0 0;
    margin:0 auto 0 auto;
    position: relative;
    overflow: visible;



    .RangeInp{

        background:#f9f9f9;
        border:1px solid black;
        border-radius: 10px;
    
        width:98%;
        height:15px;
        
        position:relative;
        float:left;
    
        margin:0 0 0 0;
        padding:0 0 0 0;
    
        outline: none;
        opacity: 0.7;
    
        -webkit-appearance: none;
        -webkit-transition: .2s;
        transition: opacity .2s;
    
    }
    
    
    .RangeInp:hover {
        opacity: 1;
    }
    
    .RangeInp::-webkit-slider-thumb {
    
        background: #0060BF;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border:2px solid black;
    
        cursor: pointer;
        
        -webkit-appearance: none;
        appearance: none;
    
    }
    
    .RangeInp::-moz-range-thumb {
    
        background: #0060BF;	
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border:2px solid black;
    
        cursor: pointer;
    }
    


`;



*/

