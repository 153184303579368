export const BUTTON_HELMET_YELLOW_DRIVER_AYRTON_SENNA = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M349.306,75.195c81.227,0,145.155,48.81,158.675,121.745l19.111,102.742 c23.525,6.317,9.076,41.796,1.963,58.75l-10.82,25.488c-4.961,10.244-14.554,27.163-25.429,26.88l-33.152-1.739 c-16.75-0.975-34.45-3.137-50.977-5.984l-199.213-35.126C194.35,328.565,180,284.35,180,234.298 C180,136.64,251.643,75.195,349.306,75.195L349.306,75.195z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(255,200,0)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c132.609,0,152.695,114.836,152.695,114.836l19.797,106.813c19.057,0,11.521,27.184,1.473,50.857l-10.768,25.363 c-3.305,7.789-11.814,10.408-19.673,9.996l-33.445-1.75c-12.987-0.686-37.557-4.046-49.46-6.144l-194.665-34.325 C198.848,306.577,186.258,274.046,186.258,234.292L186.258,234.292z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(255,220,0)" 
        d="M349.291,89.858c70.36,1.903,130.402,42.188,146.149,108.603 l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154l-10.701,25.205 c-2.614,5.388-8.206,6.501-13.748,6.352l-33.347-1.749c-9.329-0.557-19.007-1.621-28.326-2.898l10.309-24.235 c3.187-7.6,25.016-56.836,8.127-59.809l-3.534-0.626l-18.067-97.489c-1.342-7.252-12.938-78.463-83.552-108.409 C346.149,89.968,347.66,89.897,349.291,89.858L349.291,89.858z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(255,245,0)" 
        d="M349.291,89.858c70.36,1.903,130.402,42.188,146.149,108.603 l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154l-10.701,25.205 c-2.614,5.388-8.206,6.501-13.748,6.352l-24.782-1.302l9.976-23.49c3.34-7.958,26.428-54.231,8.732-57.349l-0.695-0.124 L466.93,198.461c-4.776-25.767-23.648-90.202-118.822-108.573C348.495,89.878,348.883,89.868,349.291,89.858L349.291,89.858z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(250,175,25)" 
        d="M379.645,371.495l-155.17-27.362 c-13.698-35.747-31.597-70.917-31.984-109.801c0.939-40.369,15.274-72.831,38.178-96.688
		c-5.095,39.256-26.716,85.729,32.983,205.759l119.016,20.975L379.645,371.495L379.645,371.495z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(250,175,25)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c17.078,0,32.282,1.919,45.827,5.239c-15.503-0.944-30.235-1.839-45.648-0.463c-52.1,6.795-73.859,38.118-89.005,84.621	l-47.581,170.116C197.654,303.405,186.258,272.122,186.258,234.292L186.258,234.292z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(245,140,30)" 
        d="M217.517,326.627c-12.123-29.842-24.703-59.773-25.026-92.295 c0.939-40.369,15.274-72.831,38.178-96.688c-3.882,29.911-17.352,64.028,2.873,131.686L217.517,326.627L217.517,326.627z"
    />
    {/*White Stripes*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M186.258,234.292c0-6.332,0.512-13.027,1.565-19.936 l327.837,57.806l6.133,33.112c12.248,0,13.506,11.224,10.18,25.533l-342.201-60.341 C187.52,258.762,186.258,246.893,186.258,234.292L186.258,234.292z M201.432,170.478c8.157-17.033,19.793-33.54,35.399-47.512 l254.652,44.902c8.196,17.421,10.513,30.593,10.513,30.593l4.796,25.856L201.432,170.478L201.432,170.478z"
    />
    {/*Blue Stripes Background*/}
    <path 
        fill="rgb(30,50,130)" 
        d="M186.258,234.292c0-4.875,0.303-9.97,0.924-15.214l329.402,58.084 l5.209,28.112c10.875,0,13.082,8.857,11.115,20.855l-344.031-60.658C187.187,255.377,186.258,245.104,186.258,234.292 L186.258,234.292z"
    />
    {/*Blue Stripes Highlight*/}
    <path 
        fill="rgb(0,80,160)" 
        d="M440.328,263.722l69.477,12.247l5.975,32.229l6.779,1.197 l0.398,2.162c4.727,0.706,5.277,6.457,4.061,13.54l-71.172-12.556c1.034-8.42-0.184-14.94-6.009-15.965l-3.534-0.626	L440.328,263.722L440.328,263.722z"
    />
    {/*Blue Stripes Shadow*/}
    <path 
        fill="rgb(25,30,100)" 
        d="M186.258,234.292c0-4.875,0.303-9.97,0.924-15.214l57.572,10.154 l-12.287,43.924l-43.59-7.685C187.187,255.377,186.258,245.104,186.258,234.292L186.258,234.292z"
    />
    {/*Blue Stripes Shadow1*/}
    <path 
        fill="rgb(0,15,80)" 
        d="M193.395,220.177l30.747,5.423 c1.69,13.977,4.995,29.792,10.691,47.974l-38.232-6.739c-2.465-10.603-4.001-21.397-4.11-32.502 C192.604,229.496,192.903,224.784,193.395,220.177L193.395,220.177z"
    />
    {/*Green Stripes Background*/}
    <path 
        fill="rgb(0,168,89)" 
        d="M203.648,166.034c7.168-13.838,16.691-27.198,28.829-38.998 l261.362,46.086c6.292,14.747,8.156,25.339,8.156,25.339l3.862,20.855L203.648,166.034L203.648,166.034z"
    />
    {/*Green Stripes Highlight*/}
    <path 
        fill="rgb(0,135,75)" 
        d="M415.715,159.344l70.421,12.421 c3.927,8.346,7.067,17.263,9.305,26.696l3.647,19.663l-69.476-12.247l-1.377-7.416
		C427.634,195.195,424.936,178.952,415.715,159.344L415.715,159.344z"
    />
    {/*Green Stripes Highlight1*/}
    <path 
        fill="rgb(15,175,75)" 
        d="M456.904,166.61l29.231,5.154	c3.927,8.346,7.067,17.263,9.305,26.696l3.647,19.663l-29.479-5.199l-2.68-14.464C465.479,190.628,462.71,179.216,456.904,166.61 L456.904,166.61z"
    />
    {/*Green Stripes Shadow*/}
    <path 
        fill="rgb(0,100,65)" 
        d="M203.648,166.034c7.168-13.838,16.691-27.198,28.829-38.998 l44.946,7.928c-6.541,10.647-12.133,23.276-16.949,38.059l-0.805,2.888L203.648,166.034L203.648,166.034z"
    />
    {/*Green Stripes Shadow1*/}
    <path 
        fill="rgb(40,80,65)" 
        d="M225.221,169.837l-15.896-2.809 c5.825-10.905,13.022-20.717,21.343-29.385C229.381,147.555,227.039,157.933,225.221,169.837L225.221,169.837z"
    />
    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M352.859,173.311c6.859-6.71,14.255-9.394,23.356-9.553 c7.043,0.442,12.689,2.276,18.932,5.82l24.777,15.185c7.689,4.448,16.89,7.257,25.797,7.471h53.247 c11.924,0.869,17.482,5.949,19.932,17.471l16.164,87.21c1.252,7.61-0.268,13.843-9.125,14.594h-19.743 c-6.362-0.08-12.596-0.93-18.854-1.998l-77.563-13.674c-21.8-14.643-36.775-34.216-49.196-57.085
		c-3.788-7.167-7.282-14.464-10.637-21.84C343.326,202.318,341.756,186.273,352.859,173.311L352.859,173.311z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(90,90,100)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.679,212.269c-14.255-31.616,12.992-52.254,37.298-37.327 l24.763,15.174c8.519,5.22,21.06,8.346,28.907,8.346h53.094c10.597,0,12.813,5.815,14.047,12.456l16.139,87.071 c0.924,4.985,0.637,7.286-3.246,7.286l-26.373,0.02l-87.146-15.368C381.081,269.881,364.426,233.87,354.679,212.269 L354.679,212.269z M380.426,177.307c-11.537,0-20.945,9.409-20.945,20.945s9.408,20.945,20.945,20.945 c11.541,0,20.944-9.409,20.944-20.945S391.967,177.307,380.426,177.307L380.426,177.307z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(160,160,170)" 
        d="M498.74,198.461h-53.094h-0.517l18.639,100.57l35.538,6.263 l22.486-0.015l-19.757-106.6C501.037,198.535,499.943,198.461,498.74,198.461L498.74,198.461z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(210,210,220)" 
        points="466.93,198.461 495.44,198.461 515.242,305.274 499.208,305.274 486.305,303.003 466.93,198.461 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M380.426,179.753c10.188,0,18.504,8.31,18.504,18.499 s-8.315,18.5-18.504,18.5c-10.189,0-18.5-8.311-18.5-18.5S370.236,179.753,380.426,179.753L380.426,179.753z M380.426,186.582 c-6.427,0-11.671,5.243-11.671,11.67c0,6.432,5.244,11.676,11.671,11.676c6.432,0,11.675-5.244,11.675-11.676 C392.101,191.825,386.857,186.582,380.426,186.582L380.426,186.582z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M423.31,206.692c0-3.808-3.111-6.919-6.924-6.919 c-3.812,0-6.924,3.111-6.924,6.919c0,1.595,0.563,3.047,1.472,4.22c-2.913,7.187-8.177,13.221-15.07,16.536 c-7.704,3.459-3.568,10.885,2.396,8.042c9.414-4.533,16.8-12.56,20.612-22.352C421.461,212.135,423.31,209.63,423.31,206.692z"
    />
    {/*Blue Visor Stripe Background*/}
    <path 
        fill="rgb(0,75,160)" 
        d="M428.653,200.708l-7.58,23.058h94.101l-2.387-12.849 c-0.76-4.115-1.909-7.913-5.189-10.209H428.653L428.653,200.708z"
    />
    {/*Blue Visor Stripe Highlight*/}
    <polygon 
        fill="rgb(0,125,200)" 
        points="445.547,200.708 449.822,223.766 506.683,223.766 502.413,200.708 445.547,200.708 	"
    />
    {/*Blue Visor Stripe Highlight1*/}
    <polygon 
        fill="rgb(0,175,240)" 
        points="467.348,200.708 495.857,200.708 500.132,223.766 471.646,223.766 467.348,200.708 	"
    />
    
    

    

		</svg>
		</div>

	);//return
}