export const BUTTON_LOGO_MARTINI = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    {/*Navy Stripe*/}
    <polygon 
        fill="rgb(33,29,112)" 
        points="198,72 522,72 522,432 198,432 198,72 	"
    />
    {/*Blue Stripe*/}
    <path 
        fill="rgb(0,174,239)" 
        d="M431.262,432L431.24,72h35.957l0.043,360H431.262L431.262,432z M477.3,432h35.98V72h-36L477.3,432L477.3,432z M206.741,432h35.979l-0.043-360H206.72L206.741,432L206.741,432z M252.778,432 h35.981V72h-36L252.778,432L252.778,432z"
    />
    {/*Red Stripe*/}
    <polygon 
        fill="rgb(200,0,0)" 
        points="298.8,432 298.8,72 421.2,72 421.2,432 298.8,432 	"
    />
    {/*Martini Navy*/}
    <path 
        fill="rgb(33,29,112)" 
        d="M472.254,177.36H648v149.28H472.253 C448.045,362.978,406.738,387,360,387c-46.738,0-88.045-24.022-112.253-60.359H72V177.36h175.747
		C271.954,141.022,313.262,117,360,117S448.047,141.022,472.254,177.36L472.254,177.36z"
    />
    {/*Martini Gold*/}
    <path 
        fill="rgb(210,171,103)" 
        d="M468.059,184.109H641.25v135.781H468.059 C445.425,355.833,405.422,379.8,360,379.8s-85.424-23.967-108.058-59.909H78.75V184.109h173.192
		c22.634-35.942,62.636-59.908,108.059-59.908C405.422,124.201,445.426,148.167,468.059,184.109L468.059,184.109z"
    />
    {/*Martini Red*/}
    <path 
        fill="rgb(200,0,0)" 
        d="M360,138.667c62.35,0,113.201,50.911,113.201,113.333 S422.35,365.334,360,365.334c-62.349,0-113.201-50.912-113.201-113.334S297.651,138.667,360,138.667L360,138.667z"
    />
    {/*Martini Navy Stripe*/}
    <polygon 
        fill="rgb(33,29,112)" 
        points="89.986,195.359 630.014,195.359 630.014,308.641 89.986,308.641 89.986,195.359 	"
    />
    {/*White*/}
    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M575.646,286.987h19.111v-70.042h-19.111V286.987L575.646,286.987 z M452.743,286.987h19.11v-70.042h-19.11V286.987L452.743,286.987z M491.541,287.056h18.076v-43.663h0.201l25.617,43.663h19.726 v-70.053h-18.066v43.169h-0.203l-25.625-43.169h-19.726V287.056L491.541,287.056z M435.625,217.003h-61.02v15.7h21.234v54.353 h18.551v-54.353h21.234V217.003L435.625,217.003z M300.934,287.056h18.552v-25.515h14.061c8.679,0,9.874,7.266,10.377,14.324 c0.206,1.961,0.494,8.823,2.097,11.19h18.343c-2.492-2.558-2.792-12.855-2.995-15.789c-0.594-6.683-2.784-15.019-10.37-16.778
		v-0.202c7.479-2.839,11.17-10.015,11.17-17.565c0-14.131-12.372-19.718-24.927-19.718h-36.309V287.056L300.934,287.056z M319.486,231.624h14.756c5.189,0,9.381,2.36,9.381,7.554c0,5.293-2.998,8.232-8.573,8.232h-15.565V231.624L319.486,231.624z M252.074,236.434h0.205l7.873,24.526h-16.156L252.074,236.434L252.074,236.434z M216.177,287.056h19.056l4.48-12.56h24.822 l4.393,12.56h19.354l-26.635-70.053h-18.842L216.177,287.056L216.177,287.056z M125.243,287.051h17.441v-53.169h0.213 l14.953,53.169h14.157l14.962-53.169h0.205v53.169h17.445V217h-27.326l-12.267,46.696h-0.192L152.568,217h-27.326V287.051 L125.243,287.051z"
    />
        

    

		</svg>
		</div>

	);//return
}