import React, { 
    useState, 
    useEffect, 
    //useRef 
} from 'react';


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'




import { 
    MediaColumn, 
    MediaWrapText, MediaWrapTextHeading, MediaWrapTextPgraph

} from '../Styled/Media';

import CreateMarkup from 'utils/components/Universal/CreateMarkup';
//import CreateLink from 'utils/components/Universal/CreateLink';



const ImageWrapText = (props) => {


    //const items = props.items;
    //const columnClass = props.columnClass;


    // -- ref -- //
    // const refA = useRef(null);
    // const refB = useRef(null);
    // const refC = useRef(null);

    
    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState(props.items);


    useEffect(() => {


        if(!isLoaded)
        {
            setIsLoaded(true); 


            //console.log(JSON.stringify(props.items,null,2));

            var filteredItems = [];
            var item;
            var relief;
            for(item of props.items)
            {

                relief = item.image.relief;
                if(relief.match(/px/gi)) relief = relief.replace(/px/gi,"");

                // -- margin -- //

                switch(item.image.align)
                {
                case'right':

                    item.image.margin = "0 0 12px "+relief+"px";
                break;
                default:
                    item.image.margin = "0 "+relief+"px 12px 0";
                }


                // -- Max Image Size -- //


                var maxImageWidth = item.image.maxWidth;
                var maxImageHeight = item.image.maxHeight;

                var newImageWidth;
                var newImageHeight;

                if(maxImageWidth !== 'full' 
                && maxImageHeight !== 'full'
                )
                {
                    if(parseInt(item.image.w) > parseInt(item.image.h))
                    {
                        // landscape
                        newImageWidth = maxImageWidth;
                        newImageHeight = (parseInt(item.image.h) / parseInt(item.image.w)) * maxImageWidth;
                
                        //alert(newImageWidth+" "+newImageHeight);

                    }
                    else
                    if(parseInt(item.image.w) < parseInt(item.image.h))
                    {
                        //portrait

                        //var perc = (parseInt(item.image.w) / parseInt(item.image.h)) * 100;
                        //var newImageWidth = (perc/100) * maxImageHeight;

                        newImageWidth = (parseInt(item.image.w) / parseInt(item.image.h)) * maxImageHeight;
                        newImageHeight = maxImageHeight;
            

                        //alert(newImageWidth+" "+newImageHeight);



                    }
                    else
                    if(parseInt(item.image.w) === parseInt(item.image.h))
                    {

                        //square
                        newImageWidth = maxImageWidth;
                        newImageHeight = maxImageHeight

    
                    }

                    item.image.w = newImageWidth;
                    item.image.h = newImageHeight;

                }

                filteredItems.push(item);
            }
            setItems(filteredItems);


        }






    },[
        props,
        isLoaded
    ])




    // -- handle -- //

    // const handle = (hndl) => {

    //     //alert(JSON.stringify(hndl));
    //     switch(hndl.call)
    //     {
    //     case'url':

    //         // check url

    //         var externalUrl = false;

    //         if(externalUrl)
    //         {
    //             props.handle({

    //                 call:'url',
    //                 data:{

    //                     action:'target',
    //                     url:hndl.data

    //                 }

    //             });

    //         }else{

    //             props.handle({

    //                 call:'url',
    //                 data:{

    //                     action:'replace',
    //                     url:hndl.data

    //                 }

    //             });

    //         }

    //     break;
    //     default:
    //     }

    // }






    return (


        <MediaColumn>

            {items.map((item, i) => (

                <div 
                key={i}
                className={"Row"}
                >

                    <MediaWrapText> 

                        <div className={"Wrapper"}> 

                            <img 
                            style={{
                                margin:item.image.margin
                            }} 
                            //align="left"
                            // src="https://Media.geeksforgeeks.org/wp-content/uploads/20190808143838/logsm.png"
                            // width="150"
                            // height="200"

                            align={item.image.align}
                            src={item.image.src}
                            width={item.image.w}
                            height={item.image.h}

                            alt="" 
                            /> 


                            <div style={{

                                color:'black',
                                fontSize:"16px"

                            }}>


                                {item.heading 
                                ?

                                    <MediaWrapTextHeading>

                        
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />


                                    </MediaWrapTextHeading>

                                :null}

                                {item.pgraph
                                ?

                                    <MediaWrapTextPgraph>

                        
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />


                                    </MediaWrapTextPgraph>

                                :null}


                                {/* {item.heading+" "+item.pgraph} */}


                            </div>



                        </div> 


                    </MediaWrapText> 


                </div>


            ))}

        </MediaColumn>
    

    )



}

export default ImageWrapText;


