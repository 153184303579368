
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs



Over 200 classes were generated for component styled.div with the id of "sc-iIEYCM".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:
  const Component = styled.div.attrs(props => ({
    style: {
      background: props.background,
    },
  }))`width: 100%;`

  <Component />



*/



export const Header = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fff",
    border:             props.border || "1px solid gray",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    font-size: calc(10px + 2vmin);
    color: white;


    position:relative;
    float:left;

`;






export const HeaderMain = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;
`;



    export const HeaderLogo = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "auto",
        height:             props.height || "54px",
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 10px"

        //pointer-events:none;

    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        cursor:pointer;

    `;




    export const HeaderTitle = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "auto",
        height:             props.height || "30px",
        padding:            props.padding || "5px",
        margin:             props.margin  || "7.5px 15px 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        font-family:"Verdana", sans-serif;      
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

    `;











    export const HeaderArrowButton = styled.div.attrs(props => ({


        backgroundColor:    props.backgroundColor || "transparent",// #fcfcfc  -  #07a2e3
        border:             props.border || "1px solid white",
        borderRadius:       props.borderRadius || "20px",
    
        width:              props.width || "28px", 
        height:             props.height || "28px",
    
        margin:             props.margin || "6px 1px 0 15px", 
        padding:            props.padding || "4px",
    
        position:           props.position || "relative", 
        float:              props.float || "left",
    
        fontFamily:         props.fontFamily || '"Verdana", sans-serif',
        fontSize:           props.fontSize || "24px",
        fontWeight:         props.fontWeight || "normal",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",
        color:              props.color || "#fff",
    
    
    
    
        // alfa
    
        alfaPercent:         props.alfaPercent || '40',
        alfaDecimal:         props.alfaDecimal || "0.4",
    
    
    
    
    
    
    }))`
      
    
    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};
    
    width:${props => props.width};
    height:${props => props.height};
    
    margin:${props => props.margin};
    padding:${props => props.padding};
    
    position:${props => props.position};
    float:${props => props.float};
    
    overflow:hidden;
    
    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};
    
    
    
    .arrowbox-alfa{
                
        background-color:black;
    
        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:relative;   
        float:left;
    
        -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.alfaPercent})";
        filter : alpha(opacity=${props => props.alfaPercent});
        -moz-opacity : ${props => props.alfaDecimal};
        -khtml-opacity : ${props => props.alfaDecimal};
        opacity : ${props => props.alfaDecimal};
    
    }
    
    .arrowbox-bg{
                
        background-color:#454d5f;
     
        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:absolute;   
        z-index:1;
        top:0;
        left:0;

    
    }
    
    
    
    .arrowbox-mask{
                
        background-color:transparent;
        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:absolute;   
        z-index:5;
        top:0;
        left:0;
    
    }
    
    
    
    .arrowbox-base{
                
        background-color:transparent;
        width:25px;
        height:20px;
        padding:0 0 0 0;
        margin:0 0 0 0;
    
        position:absolute;   
        z-index:3;
        top:10px;
        left:11px;
    
    }
    
    .arrowbox{
                
        background-color:yellow;
        width:25px;
        height:20px;
        padding:0 0 0 0;
        margin:7px 0 0 14px;
    
        position:relative;   
        float:left;
    
    
    }
    
    
    
        .arrow-left {
        
            width: 0; 
            height: 0; 
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent; 
            
            border-right:11px solid #fff;
        
        
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
        
        }
    
        .arrow-right {
    
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            
            border-left: 10px solid #fff;
        
        
            float:right;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
        
        }
          
    
    
    
        .arrow-up {
    
        
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            
            border-bottom: 10px solid darkred;
    
    
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;
    
        }
      
    
        .arrow-down {
    
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            
            border-top: 10px solid darkred;
    
            float:left;
            margin: 0 0 0 0;
            padding:0 0 0 0;	
    
        }
      
    
    
    
    
    `;
    
    
    
    
    
    
    
    
    
    export const Hamburger = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "30px",
        height:             props.height || "30px",
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "10px 15px 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:right;

        cursor:pointer;


        .bun {

            background-color:#454d5f;
            border-radius:4px;

            width:100%;
            height:4px;
            margin: 4px 0 2px 0;
            padding: 0 0 0 0;

            position:relative;
            float:left;

    
        }

    `;
    
    
    








/*



//##########################################
// NAV
//##########################################


export const Nav = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;
`;



    export const NavTrack = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "100%",
        height:             props.height || "auto",//54px
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        overflow:visible;
    `;



        export const NavBar = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#454d5f",
            border:             props.border || "1px solid $454d5f",
            width:              props.width || "100%",
            height:             props.height || "40px",//54px
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            border:${props => props.border};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;
        `;


        export const NavBg = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#fcfcfc",
            width:              props.width || "100%",
            height:             props.height || "9px",//54px
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0"
    
    
        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};
    
            position:relative;   
            float:left;
        `;
    
    

        export const NavGrid = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

 
            position:absolute;   
            top:0px;
            left:10px;
            z-index:1;

            display:inline-grid;
            grid-template-columns: repeat(12,auto);

        `;





            export const Button = styled.div.attrs(props => ({

                backgroundColor:    props.backgroundColor || "transparent",
                width:              props.width || "auto",
                height:             props.height || "auto",
                padding:            props.padding || "0 0 0 0",
                margin:             props.margin  || "0 0 0 0"


            }))`

                background-color:${props => props.backgroundColor};
                width:${props => props.width};
                height:${props => props.height};
                padding:${props => props.padding};
                margin:${props => props.margin};

                position:relative;   
                float:left;


            `;




                export const ButtonText = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "transparent",
                    width:              props.width || "auto",
                    height:             props.height || "auto",
                    padding:            props.padding || "10px 15px 13px 15px",
                    margin:             props.margin  || "auto"


                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;
        
                    display:block;
            
                    font-family:"Verdana", sans-serif;      
                    font-size: 14px;
                    font-weight: normal;
                    text-align: center;
                    text-decoration:none;
                    color:#fff;


                    &:hover{

                        background-color:#818692;
                        color:white;
                    }




                `;


                export const ButtonTextHold = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "lightseagreen",
                    width:              props.width || "auto",
                    height:             props.height || "auto",
                    padding:            props.padding || "10px 15px 13px 15px",
                    margin:             props.margin  || "auto"


                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;
        
                    display:block;
            
                    font-family:"Verdana", sans-serif;      
                    font-size: 14px;
                    font-weight: normal;
                    text-align: center;
                    text-decoration:none;
                    color:#fff;


                `;



                export const ArrowBox = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "transparent",
                    width:              props.width || "100%",
                    height:             props.height || "auto",
                    padding:            props.padding || "0 0 0 0",
                    margin:             props.margin  || "0 0 0 0"


                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};


                    position:relative;
                    float:left;
            
                    overflow:visible;
            

                `;


                    export const Arrow = styled.div.attrs(props => ({

                        //arrow
                        arrowSizeA:         props.arrowSizeA || 11,
                        arrowSizeB:         props.arrowSizeA || 9,
                        arrowColor:         props.arrowColor || "lightseagreen",

                    }))`

                        width: 0; 
                        height: 0; 
    
                        border-left: ${props => props.arrowSizeA}px solid transparent;
                        border-right: ${props => props.arrowSizeA}px solid transparent;
                        
                        border-top: ${props => props.arrowSizeB}px solid ${props => props.arrowColor};
    
    
                        margin: auto;
                        padding:0 0 0 0;  
                        position:relative;



                    `;







    
    
*/
        
        
        





















//##########################################
//Button
//##########################################


/*

        export const Button = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "10px 15px 13px 15px",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;

            display:block;

            font-family:"Verdana", sans-serif;      
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;


            &:hover {

                background-color:transparent;
                color:lightseagreen;

            }

        `;



        export const ButtonHold = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#818692",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "10px 15px 13px 15px",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;

            display:block;
        
            font-family:"Verdana", sans-serif;      
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;

        `;



*/










