export const CALCULATOR = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M132.597,62.715h234.808 c12.299,0,22.361,9.235,22.361,20.523v333.523c0,11.289-10.063,20.523-22.361,20.523H132.597c-12.299,0-22.362-9.234-22.362-20.523 V83.238C110.234,71.95,120.298,62.715,132.597,62.715L132.597,62.715z M148.59,112.405c67.601,0,135.234,0,202.836,0v48.897
				c-67.602,0-135.235,0-202.836,0V112.405L148.59,112.405z M155.706,187.916c-3.948,0-7.183,2.947-7.183,6.547v35.725	c0,3.603,3.234,6.546,7.183,6.546h40.584c3.949,0,7.18-2.943,7.18-6.546v-35.725c0-3.6-3.23-6.547-7.18-6.547H155.706 L155.706,187.916z M229.717,187.452c-3.952,0-7.182,2.947-7.182,6.548v35.725c0,3.602,3.229,6.546,7.182,6.546h40.58 c3.951,0,7.188-2.944,7.188-6.546V194c0-3.601-3.236-6.548-7.188-6.548H229.717L229.717,187.452z M303.709,187.452 c-3.947,0-7.18,2.947-7.18,6.548v35.725c0,3.602,3.232,6.546,7.18,6.546h40.586c3.949,0,7.182-2.944,7.182-6.546V194 c0-3.601-3.232-6.548-7.182-6.548H303.709L303.709,187.452z M229.717,263.348c-3.952,0-7.182,2.945-7.182,6.547v35.723
				c0,3.604,3.229,6.547,7.182,6.547h40.58c3.951,0,7.188-2.943,7.188-6.547v-35.723c0-3.602-3.236-6.547-7.188-6.547H229.717 L229.717,263.348z M229.717,338.777c-3.952,0-7.182,2.947-7.182,6.547v35.725c0,3.602,3.229,6.547,7.182,6.547h40.58 c3.951,0,7.188-2.945,7.188-6.547v-35.725c0-3.6-3.236-6.547-7.188-6.547H229.717L229.717,338.777z M155.706,263.348 c-3.948,0-7.183,2.945-7.183,6.547v35.723c0,3.604,3.234,6.547,7.183,6.547h40.584c3.949,0,7.18-2.943,7.18-6.547v-35.723 c0-3.602-3.23-6.547-7.18-6.547H155.706L155.706,263.348z M155.706,338.777c-3.948,0-7.183,2.947-7.183,6.547v35.725	c0,3.602,3.234,6.547,7.183,6.547h40.584c3.949,0,7.18-2.945,7.18-6.547v-35.725c0-3.6-3.23-6.547-7.18-6.547H155.706
				L155.706,338.777z M303.711,263.348c-3.953,0-7.182,2.945-7.182,6.547v35.723c0,3.604,3.229,6.547,7.182,6.547h40.58 c3.949,0,7.186-2.943,7.186-6.547v-35.723c0-3.602-3.236-6.547-7.186-6.547H303.711L303.711,263.348z M303.711,338.777	c-3.953,0-7.182,2.947-7.182,6.547v35.725c0,3.602,3.229,6.547,7.182,6.547h40.58c3.949,0,7.186-2.945,7.186-6.547v-35.725 c0-3.6-3.236-6.547-7.186-6.547H303.711L303.711,338.777z"
			/>


		</svg>
		</div>

	);//return

	




}