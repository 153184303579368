export const WING_RACING_SOLID_CARBON_FIBER_WHITE_1 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

    
    {/*Wing Backgroung*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M76.175,164.732v26.066c0,4.445,3.33,7.995,8.097,8.632l37.004,3.821h8.348	c2.311,0,4.487-0.903,6.126-2.543c1.641-1.641,2.544-3.816,2.544-6.127l-0.002-29.851c-0.001-4.78-3.89-8.67-8.67-8.67H84.846 C80.064,156.062,76.175,159.951,76.175,164.732L76.175,164.732z"
    />
    {/*Filler*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M76.175,203.256v-12.457c0,2.741,1.266,5.141,3.354,6.729L76.175,203.256 L76.175,203.256z"
    />
    {/*CF Lines 95*/}
    <path 
        fill="rgb(25,25,25)" 
        d="M118.321,188.473l8.053,8.053l4.027-4.025l-8.054-8.054 L118.321,188.473L118.321,188.473z M101.006,188.875l8.054,8.053l4.026-4.025l-8.053-8.053L101.006,188.875L101.006,188.875z M92.55,189.278l8.054,8.053l4.026-4.026l-8.053-8.053L92.55,189.278L92.55,189.278z M109.865,188.875l8.053,8.054l4.027-4.026	l-8.053-8.053L109.865,188.875L109.865,188.875z M83.691,189.278l8.053,8.053l4.026-4.026l-8.053-8.053L83.691,189.278
		L83.691,189.278z M127.18,188.473l8.053,8.053l0.774-0.773c0.074-0.385,0.118-0.783,0.127-1.194l0-5.185l-4.927-4.927 L127.18,188.473L127.18,188.473z M78.935,193.38l3.123,3.123c0.559,0.271,1.168,0.484,1.82,0.643l3.438-3.438l-8.054-8.053 l-0.926,0.926v4.168C78.382,191.734,78.589,192.611,78.935,193.38L78.935,193.38z M123.504,158.221l3.675,3.676l3.538-3.537 c-0.369-0.07-0.752-0.118-1.149-0.139H123.504L123.504,158.221z M105.787,158.221l4.078,4.078l4.026-4.026l-0.051-0.052H105.787 L105.787,158.221z M96.929,158.221l4.48,4.48l4.027-4.025l-0.454-0.455H96.929L96.929,158.221z M88.069,158.221l4.48,4.48 l4.027-4.025l-0.454-0.455H88.069L88.069,158.221z M114.645,158.221l4.078,4.079l4.027-4.027l-0.051-0.052H114.645L114.645,158.221 z M80.725,159.736l3.369,3.369l4.026-4.027l-0.856-0.857H84.9C83.243,158.305,81.819,158.842,80.725,159.736L80.725,159.736z M118.724,171.158l8.053,8.053l4.027-4.027l-8.054-8.053L118.724,171.158L118.724,171.158z M101.409,171.561l8.053,8.053 l4.026-4.026l-8.053-8.053L101.409,171.561L101.409,171.561z M92.953,171.963l8.053,8.054l4.026-4.026l-8.053-8.054L92.953,171.963 L92.953,171.963z M84.094,171.963l8.053,8.054l4.027-4.026l-8.054-8.054L84.094,171.963L84.094,171.963z M110.267,171.561	l8.053,8.053l4.028-4.025l-8.054-8.054L110.267,171.561L110.267,171.561z M127.582,171.158l8.054,8.053l0.498-0.497l0-7.06 l-4.524-4.523L127.582,171.158L127.582,171.158z M78.335,175.063l5.356,5.357l4.027-4.027l-8.054-8.054l-1.329,1.329V175.063	L78.335,175.063z"
    />
    {/*CF Lines 90*/}
    <path 
        fill="rgb(35,35,35)" 
        d="M119.126,162.701l8.053,8.055l4.027-4.027l-8.054-8.053 L119.126,162.701L119.126,162.701z M101.812,163.105l8.053,8.053l4.026-4.027l-8.053-8.053L101.812,163.105L101.812,163.105z M92.953,163.105l8.053,8.053l4.027-4.027l-8.053-8.053L92.953,163.105L92.953,163.105z M84.497,163.508l8.053,8.053l4.027-4.026 l-8.053-8.054L84.497,163.508L84.497,163.508z M110.267,162.701l8.053,8.053l4.028-4.025l-8.054-8.053L110.267,162.701
		L110.267,162.701z M127.582,162.299l8.054,8.054l0.497-0.497v-5.069c-0.046-0.924-0.235-1.774-0.546-2.536l-3.482-3.482	c-0.237-0.098-0.482-0.182-0.736-0.254L127.582,162.299L127.582,162.299z M78.335,166.205l5.356,5.355l4.027-4.026l-7.417-7.417	c-1.164,1.145-1.868,2.751-1.966,4.67V166.205L78.335,166.205z M118.724,180.017l8.053,8.054l4.027-4.027l-8.054-8.053 L118.724,180.017L118.724,180.017z M109.865,180.017l8.053,8.053l4.027-4.026l-8.053-8.054L109.865,180.017L109.865,180.017z M101.409,180.42l8.053,8.053l4.026-4.026l-8.053-8.054L101.409,180.42L101.409,180.42z M92.55,180.42l8.054,8.053l4.026-4.026 l-8.053-8.054L92.55,180.42L92.55,180.42z M84.094,180.822l8.053,8.053l4.027-4.025l-8.054-8.055L84.094,180.822L84.094,180.822z M127.18,179.613l8.053,8.054l0.901-0.9l0-6.253l-4.927-4.927L127.18,179.613L127.18,179.613z M78.335,183.922l4.954,4.953 l4.027-4.025l-8.054-8.055l-0.926,0.927V183.922L78.335,183.922z M118.321,197.331l3.761,3.761h7.516 c0.171-0.004,0.34-0.015,0.507-0.031l-7.756-7.756L118.321,197.331L118.321,197.331z M101.006,197.734l1.398,1.397l8.98,0.927 l-6.352-6.352L101.006,197.734L101.006,197.734z M92.147,197.734l0.378,0.377l8.98,0.928l-5.332-5.332L92.147,197.734 L92.147,197.734z M91.626,198.02l-3.908-3.909l-3.175,3.175c0.009,0.002,0.018,0.004,0.026,0.005L91.626,198.02L91.626,198.02z M126.777,196.929l4.031,4.031c2.048-0.4,3.707-1.673,4.603-3.45l-4.606-4.607L126.777,196.929L126.777,196.929z M109.461,197.33 l2.821,2.822l8.981,0.927l-7.774-7.774L109.461,197.33L109.461,197.33z"
    />
    {/*1 Back*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M97.217,195.274v-10.613h4.61V172.85c-0.251,0.058-0.499,0.108-0.744,0.154	c-0.806,0.156-1.653,0.256-2.505,0.294l-1.505,0.067v-8.871l1.197-0.204c1.378-0.236,2.542-0.604,3.462-1.093 c0.875-0.464,1.576-1.054,2.088-1.754l0.431-0.591h9.152v23.809h3.992v10.613H97.217z"
    />
    {/*1*/}
     <path 
        fill="rgb(255,255,255)" 
        d="M103.268,186.102v-15.141c-0.846,0.272-1.664,0.481-2.447,0.627 c-0.776,0.15-1.548,0.237-2.307,0.271v-6.15c1.531-0.262,2.829-0.673,3.896-1.24c1.073-0.569,1.931-1.295,2.574-2.176h6.981v23.809	h3.992v7.732H98.657v-7.732H103.268L103.268,186.102z"
    />


    

		</svg>
		</div>

	);//return
}