
import React, { 

    useState, 
    useEffect, 
    useRef,
    //useMemo

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";
  

//import { useLiquidNavBar } from 'utils/myHooks/LiquidFormula/NavBar';
import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


//import logo from 'grfx/logo.svg';
//import { fontSize } from 'pdfkit/js/mixins/fonts';


import 'App.css';
//import './Styl.css';
import { 
    Header, 
    HeaderMain, 
    HeaderLogo,
    // HeaderTitle,
    // HeaderArrowButton,

    Hamburger

} from './StylAttrs';





const Index = (props) => {



    const primeHandle = props.databind.primeProps.handle;



    //.log("HEADER PROPS zoneObj:" + props.zoneObj);

    const { width } = useWindowDimensions()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // -- ref -- //
    const refMain = useRef(null);
    const refLogo = useRef(null);
    const refBurger = useRef(null);
    const refBun = useRef(null);



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false)  
    const [winThresh, setWinThresh] = useState(10000)
    const [thresh] = useState({

        a: 700,
        b: 500,
        c: 350

    });
    const [headerGrfx, setHeaderGrfx] = useState(false);
    const [headerStyl, setHeaderStyl] = useState({

        backgroundColor:false,
        height:false

    });
    const [logoStyl, setLogoStyl] = useState({

        backgroundColor:false,
        margin:false

    });
    const [hamburgerStyl, setHamburgerStyl] = useState({

        backgroundColor:false,
        borderColor:false,
        margin:false

    });


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            var newThresh;

            if(width < thresh.c
            )
            {
                newThresh = thresh.c;
            }
            else
            if(width < thresh.b
            && width > thresh.c
            )
            {
                newThresh = thresh.b;
            }
            else
            if(width < thresh.a
            && width > thresh.b
            )
            {
            
                newThresh = thresh.a
            }
            else
            if(width > thresh.a)
            {
                newThresh = 10000;
            }



            if(winThresh !== newThresh)
            {

                //alert(newThresh);
                setWinThresh(newThresh);
            
            }


            // props.handle({

            //     call:'dispatch',
            //     data:{

            //         action:'UPDATE_ZONE',
            //         key:'headerHInt',
            //         value:200

            //     }

            // });


        }





        //---------------------------------------
        // setHeaderGrfx
        //---------------------------------------

        if(!headerGrfx)
        {
            if(zoneObj.settings 
            && zoneObj.settings !== undefined)
            {



                // "header": {
                //     "headerCall": "B",
                //     "headerMainColor": "red",
                //     "headerMainHeight": "54px",
                //     "navbarColor": "gray",
                //     "navbarHeight": "35px",
                //     "lineColor": "white",
                //     "lineHeight": "2px",
                //     "buttonBackgroundOverColor": "red",
                //     "buttonBackgroundOutColor": "yellow",
                //     "buttonTextOutColor": "black",
                //     "logoColor": "black",
                //     "logoWidth": "260px",
                //     "logoHeight": "40px",
                //     "logoMinWidth": "40px"
                //   },


                 
                          
                
                var burgerTop = 10;

                if(zoneObj.settings.graphic.header)
                {
                    setHeaderGrfx({

                        logo:{

                            src:zoneObj.settings.graphic.header.image.src,
                            w:zoneObj.settings.graphic.header.image.w,
                            h:zoneObj.settings.graphic.header.image.h
                        }

                    });

                    var logoTop = ( refMain.current.offsetHeight - parseInt(zoneObj.settings.graphic.header.image.h) )/2;
                    setLogoStyl({

                        margin: logoTop + "px 0 0 15px"

                    });

                    burgerTop = (refMain.current.offsetHeight - refBurger.current.offsetHeight)/2;
                    //var burgerTop = (refMain.current.offsetHeight - refBurger.current.offsetHeight)/2 - refBun.current.offsetTop;
                }


                var headerHInt;
                var headerMainColor = false;

                if(zoneObj.settings.appearance.header)
                {

                    if(zoneObj.settings.appearance.header.headerMainColor)
                    {
                        headerMainColor = zoneObj.settings.appearance.header.headerMainColor;
                    }

                    headerHInt = zoneObj.settings.appearance.header.headerMainHeight;
                    if(headerHInt.match(/px/gi))
                    {
                        headerHInt = headerHInt.replace(/px/gi,"");
                    }
                    //headerHInt = headerHInt + 20;

                    setHeaderStyl({

                        backgroundColor:headerMainColor,
                        height:headerHInt + "px"

                    });

                    setHamburgerStyl({

                        bunColor:zoneObj.settings.appearance.header.hamburgerColorOut,
                        borderColor:zoneObj.settings.appearance.header.hamburgerColorOut,
                        margin: burgerTop + "px 15px 0 0"

                    });

            
                }




            }

        }








    },[
        

        props,
        zoneObj,
        width,
        isLoaded,
        winThresh,
        thresh,

        refMain,
        refLogo,
        refBurger,
        refBun,

        headerGrfx


    ])

    
    



    // // -- handle -- //

    // const handleBack = (hndl) => {


    //     // alert(""
    //     //     +"\nWINDOOW HREF: "+window.location.href
    //     //     +"\nLOCATION: "+pathname
    //     //     +"\n"+JSON.stringify(hndl,null,2)
    //     // );
        

    //     //var basename = zoneObj.basename;
    //     var url;

    //     if(pathname === '')
    //     {
    //         //url = basename + '';
    //         url = '';
    //         history.replace(url);
    //     }else{

    //         url = '';
    //         if(pathname.match(/\//g))
    //         {
    //             history.goBack();
    //         }else{
    //             history.replace(url);
    //         }
    //     }

    //     //history.replace(url);
    //     //history.push(url);
    //     //history.goBack();
    //     //window.location.replace(url);//regular dom

    // }





    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl));

    //     //console.log(JSON.stringify(hndl,null,2));

    //     if(hndl.id.match(/_arrowButton/gi))
    //     {

    //         //var btn;
    //         var arrowBg;
    //         var arrow1;
    //         //var arrow2;

    //         //btn = document.getElementById(hndl.id);
    //         //btn.style.borderColor = hndl.borderColor;

    //         arrowBg = document.getElementById(hndl.id+"_bg");
    //         arrowBg.style.backgroundColor = hndl.bgColor;


    //         if(hndl.id.match(/_arrowButtonDown/gi))
    //         {
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderTopColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderTopColor = hndl.arrowColor;

    //         }
    //         else
    //         if(hndl.id.match(/_arrowButtonUp/gi))
    //         {
    
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderBottomColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderBottomColor = hndl.arrowColor;
    //         }
    //         else
    //         if(hndl.id.match(/_arrowButtonLeft/gi))
    //         {
    
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderRightColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderBottomColor = hndl.arrowColor;
    //         }
    //         else
    //         if(hndl.id.match(/_arrowButtonRight/gi))
    //         {
    
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderLeftColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderBottomColor = hndl.arrowColor;
    //         }
    //         else
    //         {

    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderTopColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderTopColor = hndl.arrowColor;

    //         }



        
    //     }



    // }






    if(!isLoaded)
    {


        return (


            <Header>

                <HeaderMain
                ref={refMain}
                backgroundColor={headerStyl.backgroundColor}
                height={headerStyl.height}
                >
  
                    <Link 
                    to={"/"}
                    >
                        {
                        headerGrfx
                        ?

                            <HeaderLogo
                            ref={refLogo}
                            width={headerGrfx.logo.w + "px"}
                            height={headerGrfx.logo.h + "px"}
                            margin={logoStyl.margin}
                            >

                                <div>

                                    <img 
                                        src={headerGrfx.logo.src}
                                        w={headerGrfx.logo.w} 
                                        h={headerGrfx.logo.h}
                                        alt="logo" 
                                        // style={{
                                        //     width:headerGrfx.logo.w + "px",
                                        //     height:headerGrfx.logo.h + "px"
                                        // }} 
                                            
                                    />

                                </div>

                            </HeaderLogo>

                        :
                    
                            <HeaderLogo
                            ref={refLogo}
                            >
                            </HeaderLogo>
                        }
                        

                    </Link>


                
                    <Hamburger
                    ref={refBurger}

                        margin={hamburgerStyl.margin}
                        onClick={() => primeHandle({

                            call:"showMenu",
                            data:[]

                        })}

                    >

                        <div ref={refBun} className='bun'></div>
                        <div className='bun'></div>
                        <div className='bun'></div>

                    </Hamburger>


                </HeaderMain>


            </Header>


        );




    }else{



        return (


            <Header>

                <HeaderMain
                ref={refMain}
                backgroundColor={headerStyl.backgroundColor}
                height={headerStyl.height}
                >

                    <Link 
                    to={""}
                    >
                        {
                        headerGrfx
                        ?

                            <HeaderLogo
                            ref={refLogo}
                            width={headerGrfx.logo.w + "px"}
                            height={headerGrfx.logo.h + "px"}
                            margin={logoStyl.margin}
                            >

                                <div>

                                    <img 
                                        src={headerGrfx.logo.src}
                                        w={headerGrfx.logo.w} 
                                        h={headerGrfx.logo.h}
                                        alt="logo" 
                                        // style={{
                                        //     width:headerGrfx.logo.w + "px",
                                        //     height:headerGrfx.logo.h + "px"
                                        // }} 
                                            
                                    />

                                </div>

                            </HeaderLogo>

                        :
                    
                            <HeaderLogo
                            ref={refLogo}
                            >
                            </HeaderLogo>
                        }
                        

                    </Link>



                    {/* <Link 
                    to={""}
                    >
                        
                        <HeaderTitle>

                            PowerDigitalMedia
                        
                        </HeaderTitle>


                    </Link> */}




                    <Hamburger
                    ref={refBurger}

                        margin={hamburgerStyl.margin}
                        onClick={() => primeHandle({

                            call:"showMenu",
                            data:[]

                        })}

                    >

                        <div ref={refBun} className='bun'></div>
                        <div className='bun'></div>
                        <div className='bun'></div>

                    </Hamburger>


                </HeaderMain>


            </Header>



        );


    }






}

export default Index;



