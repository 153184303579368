
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0

*/


const Single = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "550px", 
    height:             props.height || "auto",

    margin:            props.margin || "auto",
    padding:           props.padding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    max-width:900px;
    min-height:10px;

    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    display:flex;
    align-items:center;


    @media (max-width: 900px) {

        background-color:lightyellow;
        width: 84%;
    }
    @media (max-width: 600px) {

        width: 90%;

    }

`;



    const SingleBox = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

        width:              props.width || "100%", 
        height:             props.height || "auto",

        margin:            props.margin || "0 20px 0 20px",
        padding:           props.padding || "0 0 0 0",


    }))`

        background-color:${props => props.backgroundColor};

        min-height:10px;
        width   :${props => props.width};
        height  :${props => props.height};
        margin  :${props => props.margin};

        flex-grow:0;
        margin:0 20px 0 20px;

        position:relative;

        @media (max-width: 800px) {

            width:100%;
            flex-grow:0;
            margin:0 0 40px 0;
        }


    `;




        const SingleControls = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent"

        }))`


            background-color:${props => props.backgroundColor};
        
            width:auto;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;

            position:absolute;
            top:5px;
            right:7px;
            z-index:3;



            .Button {

                background-color:transparent;
        
                width:40px;
                height:40px;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:relative;
                float:left;

                cursor:pointer;

            }

            .ButtonMask {

                background-color:transparent;
        
                width:40px;
                height:40px;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:absolute;
                top:0px;
                left:0px;
                z-index:1;

                cursor:pointer;

            }



            .Img {

                width:40px;
                height:40px;

            }



        `;








export {

    Single,
    SingleBox,
    SingleControls

}
