

/*
const drawLine = (ctx, startX, startY, endX, endY) => {

    ctx.beginPath();
    ctx.moveTo(startX,startY);
    ctx.lineTo(endX,endY);
    ctx.stroke();

}

const drawArc = (ctx, centerX, centerY, radius, startAngle, endAngle) => {

    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.stroke();
}

*/



const drawPieSlice = (

    ctx,
    centerX, 
    centerY, 
    radius, 
    startAngle, 
    endAngle, 
    color 

    ) => {

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(centerX,centerY);
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.closePath();
    ctx.fill();

}




export const DoughnutChart = function(options) {


    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.data = options.data;
    this.colors = options.colors;


    var chartW = this.canvas.width - 0;
    var chartH = this.canvas.height - 0;

 
    this.draw = function(){

        var total_value = 0;
        var color_index = 0;

        var categ;
        var val;
        var start_angle;
        var slice_angle;


        //console.log(JSON.stringify(this.options.data,null,2))
        if(Object.prototype.toString.call(this.options.data) === '[object Array]'
        )
        {
            var object;
            for(object of this.data)
            {
                val = object.value;
                total_value += val;
            }
            start_angle = 0;
            for(object of this.data)
            {
                val = object.value;
                slice_angle = 2 * Math.PI * val / total_value;
    
                drawPieSlice(
                    this.ctx,
                    chartW/2,
                    chartH/2,
                    Math.min(chartW/2,chartH/2),
                    start_angle,
                    start_angle+slice_angle,
                    this.colors[color_index%this.colors.length]
                );
    
                start_angle += slice_angle;
                color_index++;
            }


        }
        else
        {

            for(categ in this.data)
            {
                val = this.data[categ];
                total_value += val;
            }
    
            start_angle = 0;
            for(categ in this.data)
            {
                val = this.data[categ];
                slice_angle = 2 * Math.PI * val / total_value;
    
                drawPieSlice(
                    this.ctx,
                    chartW/2,
                    chartH/2,
                    Math.min(chartW/2,chartH/2),
                    start_angle,
                    start_angle+slice_angle,
                    this.colors[color_index%this.colors.length]
                );
    
                start_angle += slice_angle;
                color_index++;
            }    

        }


        //drawing a white circle over the chart
        //to create the doughnut chart
        if(this.options.holeSize 
        && this.options.holeOutlineColor
        )
        {
            drawPieSlice(
                this.ctx,
                chartW/2,
                chartH/2,
                this.options.holeSize * Math.min(chartW/2,chartH/2),
                0,
                2 * Math.PI,
                this.options.holeOutlineColor
            );
        }


        if(this.options.holeSize)
        {
            drawPieSlice(
                this.ctx,
                chartW/2,
                chartH/2,
                this.options.holeSize * Math.min(chartW/2.175,chartH/2.175),
                0,
                2 * Math.PI,
                this.options.holeColor
            );
        }


 
    }
}








/* ORIGINAL
export const DoughnutChart = function(options) {


    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.data = options.data;
    this.colors = options.colors;


    var chartW = this.canvas.width - 0;
    var chartH = this.canvas.height - 0;

 
    this.draw = function(){

        var total_value = 0;
        var color_index = 0;

        for(var categ in this.data)
        {
            var val = this.data[categ];
            total_value += val;
        }
 
        var start_angle = 0;
        for(categ in this.data)
        {
            val = this.data[categ];
            var slice_angle = 2 * Math.PI * val / total_value;
 
            drawPieSlice(
                this.ctx,
                chartW/2,
                chartH/2,
                Math.min(chartW/2,chartH/2),
                start_angle,
                start_angle+slice_angle,
                this.colors[color_index%this.colors.length]
            );
 
            start_angle += slice_angle;
            color_index++;
        }
 
        //drawing a white circle over the chart
        //to create the doughnut chart
        if(this.options.holeSize)
        {
            drawPieSlice(
                this.ctx,
                chartW/2,
                chartH/2,
                this.options.holeSize * Math.min(chartW/2,chartH/2),
                0,
                2 * Math.PI,
                this.options.holeOutlineColor
            );
        }


        if(this.options.holeSize)
        {
            drawPieSlice(
                this.ctx,
                chartW/2,
                chartH/2,
                this.options.holeSize * Math.min(chartW/2.175,chartH/2.175),
                0,
                2 * Math.PI,
                this.options.holeColor
            );
        }


 
    }
}


*/







/*


//--------------------------------------
// doughnut chart
//--------------------------------------

var doughnut_chart = new DoughnutChart({
    canvas            :_canvas,
    data              :data_object,
    colors            :colors_array,
    doughnutHoleSize  :0.5,
    doughnutHoleColor :"blue"
});
doughnut_chart.draw();


*/








export const PieChart = function(options){

    this.options = options;
    this.canvas = options.canvas;
    this.ctx = this.canvas.getContext("2d");
    this.data = options.data;
    this.colors = options.colors;

    var chartW = this.canvas.width - 0;
    var chartH = this.canvas.height - 0;
    

    this.draw = function(){

        var total_value = 0;
        var color_index = 0;

        var categ;
        var val;
        var start_angle;
        var slice_angle;




        //console.log(JSON.stringify(this.options.data,null,2))
        if(Object.prototype.toString.call(this.options.data) === '[object Array]'
        )
        {
            var object;
            for(object of this.data)
            {
                val = object.value;
                total_value += val;
            }
            start_angle = 0;
            for(object of this.data)
            {
                val = object.value;
                slice_angle = 2 * Math.PI * val / total_value;
     
                drawPieSlice(
                    this.ctx,
                    chartW/2,
                    chartH/2,
                    Math.min(chartW/2,chartH/2),
                    start_angle,
                    start_angle+slice_angle,
                    this.colors[color_index%this.colors.length]
                );
     
                start_angle += slice_angle;
                color_index++;
            }



        }
        else
        {


            for (categ in this.options.data)
            {
                val = this.options.data[categ];
                total_value += val;
            }
     
            start_angle = 0;
            for (categ in this.options.data)
            {
                val = this.options.data[categ];
                slice_angle = 2 * Math.PI * val / total_value;
     
                drawPieSlice(
                    this.ctx,
                    chartW/2,
                    chartH/2,
                    Math.min(chartW/2,chartH/2),
                    start_angle,
                    start_angle+slice_angle,
                    this.colors[color_index%this.colors.length]
                );
     
                start_angle += slice_angle;
                color_index++;
            }

        }






 
    }

}







/*
EXAMPLE USAGE


var canvas_id = 'myCanvas';

var data_object = {

    "Classical music": 10,
    "Alternative rock": 14,
    "Pop": 2,
    "Jazz": 12
};

var colors_array = ["#fde23e","#f16e23", "#57d9ff","#937e88"];

var pie_chart = new PieChart({
    canvas  :_canvas,
    data    :data_object,
    colors  :colors_array
});
pie_chart.draw();

*/



