import React, { 
    // useState, 
    // useEffect, 
    // //useRef 
} from 'react';

//import { useSelector, useDispatch } from 'react-redux';

//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

// import {
//     //BrowserRouter as Router,
//     //Switch,
//     //Route,
//     //Link,

//     //useRouteMatch,
//     useLocation,   
//     //useParams


// } from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import StylColumn from '../StylModules/Column.module.css';


import { 
    //MediaLink,
    //MediaButton,
    MediaBoxButton,
    MediaBoxLink
} from '../Styled/Media';

import CreateMarkup from 'utils/components/Universal/CreateMarkup';
import CreateLink from 'utils/components/Universal/CreateLink';





const TextBox = (props) => {

    const items = props.items;
    const columnClass = props.columnClass;


    var headingStyl = false;
    var pgraphStyl = false;
    var buttonStyl = {

        buttonOrLink:"none",
        mainWidth:false,
        position:false,

        backgroundColorOver:false,
        backgroundColorOut:false,

        borderRadius:"0px",
        borderColorOver:false,
        borderColorOut:false,

        textDecoration:'none',
        textColorOver:false,
        textColorOut:false,

        fontSize:false,
        fontWeight:false


    };
    if(props.info.compStyl)
    {
        if(props.info.compStyl.heading) headingStyl = props.info.compStyl.heading;
        if(props.info.compStyl.pgraph) pgraphStyl = props.info.compStyl.pgraph;
        if(props.info.compStyl.button)
        {
            if(props.info.compStyl.button.buttonOrLink !== 'none')
            {
                buttonStyl = props.info.compStyl.button;

            }
        }
    }



    // -- handle -- //

    const handle = (hndl) => {
        

        //alert(JSON.stringify(hndl,null,2));


        switch(hndl.call)
        {
        case'url':

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxHost = new RegExp(window.location.host,"gi");
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }
            else
            if(!url.match(regxHost) && url.match(/http/gi)
            )
            {
                externalUrl = true;
            }
            else
            if(!url.match(regxHost) && url.match(/\/\//gi)
            )
            {
                externalUrl = true;
            }
            //alert(url);



            if(externalUrl)
            {
                props.databind.primeProps.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }else{

                props.databind.primeProps.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }




        break;
        default:
        }

    }






    return (


        <div
        className={StylColumn.FluidColumns}
        >

            {
            columnClass === 'full' ?



                <div
                className={StylColumn.Full}
                >
                    {items.map((item, i) => (


                        <div
                        key={i}
                        className={StylColumn.Box}
                        >



                            {
                            item.heading 
                            ?
                
                                headingStyl
                                ?
                                    <div 
                                    className={StylColumn.Heading}
                                    style={{

                                        "fontFamily"        : headingStyl.fontFamily,
                                        "fontSize"          : headingStyl.fontSize,
                                        "fontWeight"        : headingStyl.fontWeight,
                                        "textAlign"         : headingStyl.textAlign,
                                        "textDecoration"    : headingStyl.textDecoration,
                                        "color"             : headingStyl.color

                                    }}
                                    >
                                        {/* {box.title} */}
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />


                                    </div>

                                :

                                    <div 
                                    className={StylColumn.Heading}
                                    >
                                        
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                                    </div>


                            :null}



                            {
                            pgraphStyl
                            ?

                                <div 
                                className={StylColumn.Pgraph}
                                style={{

                                    "fontFamily"        : pgraphStyl.fontFamily,
                                    "fontSize"          : pgraphStyl.fontSize,
                                    "fontWeight"        : pgraphStyl.fontWeight,
                                    "textAlign"         : pgraphStyl.textAlign,
                                    "textDecoration"    : pgraphStyl.textDecoration,
                                    "color"             : pgraphStyl.color

                                }}
                                >
                                    {/* {box.text} */}
                                    <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />


                                </div>

                            :


                                <div 
                                className={StylColumn.Pgraph}
                                >

                                    <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />

                                </div>


                            }



                            {
                            item.button
                            ?

                                // <div 
                                // className={StylColumn.Button}
                                // >
                                //     {item.button.name}

                                // </div>

                                <div>
                                {

                                

                                buttonStyl.buttonOrLink === 'link'
                                ?

                                    //ANCHOR
                                    item.button.value.match(/http/gi) || item.button.value.match(/\/\//gi)
                                    ?
        
                                        <a 
                                        href={CreateLink({
        
                                            name:item.button.name,
                                            value:item.button.value

                                        })}
                                        target="_blank"
                                        rel="noreferrer"
                                        >
        
                                            <MediaBoxLink
                                            mainWidth={buttonStyl.mainWidth}
                                            position={buttonStyl.position}
                                            textDecoration={buttonStyl.textDecoration}
                                            textColorOver={buttonStyl.textColorOver}
                                            textColorOut={buttonStyl.textColorOut}

                                            fontSize={buttonStyl.fontSize}
                                            fontWeight={buttonStyl.fontWeight}

                                            >
            
                                                {
                                                buttonStyl.position === 'left'
                                                ?
                                                    <div 
                                                    className={"LinkLeft"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'center'
                                                ?          

                                                    <div 
                                                    className={"LinkCenter"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'right'
                                                ?

                                                    <div 
                                                    className={"LinkRight"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>


                                                :null
                                                }


                                            </ MediaBoxLink>
        
                                        </a>
        
        
                        
                                    :
    
                                        <MediaBoxLink
                                        mainWidth={buttonStyl.mainWidth}
                                        position={buttonStyl.position}
                                        textDecoration={buttonStyl.textDecoration}
                                        textColorOver={buttonStyl.textColorOver}
                                        textColorOut={buttonStyl.textColorOut}

                                        fontSize={buttonStyl.fontSize}
                                        fontWeight={buttonStyl.fontWeight}

                                        onClick={() => handle({

                                            call:'url',
                                            data:item.button.value

                                        })}

                                        >
            
                                            {
                                            buttonStyl.position === 'left'
                                            ?
                                                <div 
                                                className={"LinkLeft"}
                                                >
                
                                                    {item.button.name}

                                                </div>

                                            :
                                            buttonStyl.position === 'center'
                                            ?          

                                                <div 
                                                className={"LinkCenter"}
                                                >
                
                                                    {item.button.name}

                                                </div>

                                            :
                                            buttonStyl.position === 'right'
                                            ?

                                                <div 
                                                className={"LinkRight"}
                                                >
                
                                                    {item.button.name}

                                                </div>


                                            :null
                                            }


                                        </MediaBoxLink>





                                    : // BUTTON




                                        //ANCHOR
                                        item.button.value.match(/http/gi) || item.button.value.match(/\/\//gi)
                                        ?
            
                                            <a 
                                            href={CreateLink({
            
                                                name:item.button.name,
                                                value:item.button.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
            
                                                <MediaBoxButton
                                                mainWidth={buttonStyl.mainWidth}

                                                backgroundColorOver={buttonStyl.backgroundColorOver}
                                                backgroundColorOut={buttonStyl.backgroundColorOut}

                                                borderRadius={buttonStyl.borderRadius}
                                                borderOver={buttonStyl.borderOver}
                                                borderOut={buttonStyl.borderOut}

                                                textColorOver={buttonStyl.textColorOver}
                                                textColorOut={buttonStyl.textColorOut}

                                                fontSize={buttonStyl.fontSize}
                                                fontWeight={buttonStyl.fontWeight}

                                                >
                
                                                    {
                                                    buttonStyl.position === 'left'
                                                    ?
                                                        <div 
                                                        className={"BtnLeft"}
                                                        >
                        
                                                            {item.button.name}
    
                                                        </div>
    
                                                    :
                                                    buttonStyl.position === 'center'
                                                    ?          
    
                                                        <div 
                                                        className={"BtnCenter"}
                                                        >
                        
                                                            {item.button.name}
    
                                                        </div>
    
                                                    :
                                                    buttonStyl.position === 'right'
                                                    ?
    
                                                        <div 
                                                        className={"BtnRight"}
                                                        >
                        
                                                            {item.button.name}
    
                                                        </div>
    
    
                                                    :null
                                                    }
    

                                                </ MediaBoxButton>
            
                                            </a>
            
            
                            
                                        :
        
                                            <MediaBoxButton
                                            mainWidth={buttonStyl.mainWidth}

                                            backgroundColorOver={buttonStyl.backgroundColorOver}
                                            backgroundColorOut={buttonStyl.backgroundColorOut}
        
                                            borderRadius={buttonStyl.borderRadius}
                                            borderOver={buttonStyl.borderOver}
                                            borderOut={buttonStyl.borderOut}
        
                                            textColorOver={buttonStyl.textColorOver}
                                            textColorOut={buttonStyl.textColorOut}

                                            fontSize={buttonStyl.fontSize}
                                            fontWeight={buttonStyl.fontWeight}


                                            onClick={() => handle({

                                                call:'url',
                                                data:item.button.value

                                            })}

                                            >
                
                                                {
                                                buttonStyl.position === 'left'
                                                ?
                                                    <div 
                                                    className={"BtnLeft"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'center'
                                                ?          

                                                    <div 
                                                    className={"BtnCenter"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'right'
                                                ?

                                                    <div 
                                                    className={"BtnRight"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>


                                                :null
                                                }


                                            </MediaBoxButton>

    
                                }


                                </div>

                            :null}


                        </div>


                
                    ))}


                </div>





            :// === PAGE ===




                <div
                className={StylColumn.Page}
                >
                    {items.map((item, i) => (


                        <div
                        key={i}
                        className={StylColumn.Box}
                        >
                            {/* {item.heading 
                            ?

                                <div className={StylColumn.Heading}>

                    
                                    <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />


                                </div>

                            :null}


                            <div className={StylColumn.Pgraph}>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />

                            </div> */}



                            {
                            item.heading 
                            ?
                
                                headingStyl
                                ?
                                    <div 
                                    className={StylColumn.Heading}
                                    style={{

                                        "fontFamily"        : headingStyl.fontFamily,
                                        "fontSize"          : headingStyl.fontSize,
                                        "fontWeight"        : headingStyl.fontWeight,
                                        "textAlign"         : headingStyl.textAlign,
                                        "textDecoration"    : headingStyl.textDecoration,
                                        "color"             : headingStyl.color

                                    }}
                                    >
                                      
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />


                                    </div>

                                :

                                    <div 
                                    className={StylColumn.Heading}
                                    >
                                        
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                                    </div>


                            :null}



                            {
                            pgraphStyl
                            ?

                                <div 
                                className={StylColumn.Pgraph}
                                style={{

                                    "fontFamily"        : pgraphStyl.fontFamily,
                                    "fontSize"          : pgraphStyl.fontSize,
                                    "fontWeight"        : pgraphStyl.fontWeight,
                                    "textAlign"         : pgraphStyl.textAlign,
                                    "textDecoration"    : pgraphStyl.textDecoration,
                                    "color"             : pgraphStyl.color

                                }}
                                >
                            
                                    <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />


                                </div>

                            :


                                <div 
                                className={StylColumn.Pgraph}
                                >

                                    <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />

                                </div>


                            }



                            {
                            item.button
                            ?

                                // <div 
                                // className={StylColumn.Button}
                                // >
                                //     {item.button.name}

                                // </div>

                                <div>
                                {

                                

                                buttonStyl.buttonOrLink === 'link'
                                ?

                                    //ANCHOR
                                    item.button.value.match(/http/gi) || item.button.value.match(/\/\//gi)
                                    ?
        
                                        <a 
                                        href={CreateLink({
        
                                            name:item.button.name,
                                            value:item.button.value

                                        })}
                                        target="_blank"
                                        rel="noreferrer"
                                        >
        
                                            <MediaBoxLink
                                            mainWidth={buttonStyl.mainWidth}
                                            position={buttonStyl.position}

                                            textDecoration={buttonStyl.textDecoration}
                                            textColorOver={buttonStyl.textColorOver}
                                            textColorOut={buttonStyl.textColorOut}

                                            fontSize={buttonStyl.fontSize}
                                            fontWeight={buttonStyl.fontWeight}

                                            >
            
                                                {
                                                buttonStyl.position === 'left'
                                                ?
                                                    <div 
                                                    className={"LinkLeft"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'center'
                                                ?          

                                                    <div 
                                                    className={"LinkCenter"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'right'
                                                ?

                                                    <div 
                                                    className={"LinkRight"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>


                                                :null
                                                }


                                            </ MediaBoxLink>
        
                                        </a>
        
        
                        
                                    :
    
                                        <MediaBoxLink
                                        mainWidth={buttonStyl.mainWidth}
                                        position={buttonStyl.position}

                                        textDecoration={buttonStyl.textDecoration}
                                        textColorOver={buttonStyl.textColorOver}
                                        textColorOut={buttonStyl.textColorOut}

                                        fontSize={buttonStyl.fontSize}
                                        fontWeight={buttonStyl.fontWeight}

                                        onClick={() => handle({

                                            call:'url',
                                            data:item.button.value

                                        })}

                                        >
            
                                            {
                                            buttonStyl.position === 'left'
                                            ?
                                                <div 
                                                className={"LinkLeft"}
                                                >
                
                                                    {item.button.name}

                                                </div>

                                            :
                                            buttonStyl.position === 'center'
                                            ?          

                                                <div 
                                                className={"LinkCenter"}
                                                >
                
                                                    {item.button.name}

                                                </div>

                                            :
                                            buttonStyl.position === 'right'
                                            ?

                                                <div 
                                                className={"LinkRight"}
                                                >
                
                                                    {item.button.name}

                                                </div>


                                            :null
                                            }


                                        </MediaBoxLink>





                                    : // BUTTON




                                        //ANCHOR
                                        item.button.value.match(/http/gi) || item.button.value.match(/\/\//gi)
                                        ?
            
                                            <a 
                                            href={CreateLink({
            
                                                name:item.button.name,
                                                value:item.button.value

                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            >
            
                                                <MediaBoxButton
                                                mainWidth={buttonStyl.mainWidth}

                                                backgroundColorOver={buttonStyl.backgroundColorOver}
                                                backgroundColorOut={buttonStyl.backgroundColorOut}

                                                borderRadius={buttonStyl.borderRadius}
                                                borderOver={buttonStyl.borderOver}
                                                borderOut={buttonStyl.borderOut}

                                                textColorOver={buttonStyl.textColorOver}
                                                textColorOut={buttonStyl.textColorOut}

                                                fontSize={buttonStyl.fontSize}
                                                fontWeight={buttonStyl.fontWeight}

                                                >
                
                                                    {
                                                    buttonStyl.position === 'left'
                                                    ?
                                                        <div 
                                                        className={"BtnLeft"}
                                                        >
                        
                                                            {item.button.name}
    
                                                        </div>
    
                                                    :
                                                    buttonStyl.position === 'center'
                                                    ?          
    
                                                        <div 
                                                        className={"BtnCenter"}
                                                        >
                        
                                                            {item.button.name}
    
                                                        </div>
    
                                                    :
                                                    buttonStyl.position === 'right'
                                                    ?
    
                                                        <div 
                                                        className={"BtnRight"}
                                                        >
                        
                                                            {item.button.name}
    
                                                        </div>
    
    
                                                    :null
                                                    }
    

                                                </ MediaBoxButton>
            
                                            </a>
            
            
                            
                                        :
        
                                            <MediaBoxButton
                                            mainWidth={buttonStyl.mainWidth}

                                            backgroundColorOver={buttonStyl.backgroundColorOver}
                                            backgroundColorOut={buttonStyl.backgroundColorOut}

                                            borderRadius={buttonStyl.borderRadius}
                                            borderOver={buttonStyl.borderOver}
                                            borderOut={buttonStyl.borderOut}

                                            textColorOver={buttonStyl.textColorOver}
                                            textColorOut={buttonStyl.textColorOut}

                                            fontSize={buttonStyl.fontSize}
                                            fontWeight={buttonStyl.fontWeight}

                                            onClick={() => handle({

                                                call:'url',
                                                data:item.button.value

                                            })}

                                            >
                
                                                {
                                                buttonStyl.position === 'left'
                                                ?
                                                    <div 
                                                    className={"BtnLeft"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'center'
                                                ?          

                                                    <div 
                                                    className={"BtnCenter"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>

                                                :
                                                buttonStyl.position === 'right'
                                                ?

                                                    <div 
                                                    className={"BtnRight"}
                                                    >
                    
                                                        {item.button.name}

                                                    </div>


                                                :null
                                                }


                                            </MediaBoxButton>

    
                                }


                                </div>

                            :null}


                        </div>


                
                    ))}


                </div>



            }



        </div>

           

    )


}

export default TextBox;
