import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


import '../../StyleSheets/dynamic-form.css';


export const FFbutton = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    const [buttonStyle, setButtonStyle] = useState({});

    useEffect(() => {


        setButtonStyle({

            bgColor     :"#07a2e3",
            txtColor    :"#FFF",
            brColor     :"#07a2e3"

        });
      

     },[])



    const handleSetStyle = (v) => {

        //console.log(v);
        setButtonStyle({

            bgColor     :v.bgColor,
            txtColor    :v.txtColor,
            brColor     :v.brColor

        });

    }


    return (

        <div 
        key={props.indx+"_ffbutton"} 
        className = "btn-submit"
        style={{

            backgroundColor     :buttonStyle.bgColor,
            width               :"80%",
            margin              :"0 0 0 8%",
            color               :buttonStyle.txtColor,
            border              :"1px solid "+buttonStyle.brColor

        }}
        onClick={() => props.handle({

            call        :props.data.action,
            data        :props.data,
            funcname    :props.data.funct.name

        })}

        onMouseEnter={() => handleSetStyle({

            call        :"over",
            bgColor     :"orangered",
            txtColor    :"#FFF",
            brColor     :"orangered"

        })}

        onMouseOut={() => handleSetStyle({

            call        :"out",
            bgColor     :"#07a2e3",
            txtColor    :"#FFF",
            brColor     :"#07a2e3"


        })}

        >

            {props.data.text}
            
        </div>

    )

}





