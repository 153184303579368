


const SceneInfo = [




    //##########################################################
    //##########################################################
    //##########################################################

    // Newspaper TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "25px"
        },
        "call": "TxtComponent",
        "info": {
            "call": "fixed",
            "gridType": "TextBox",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": [
            {
            "heading": "Error",
            "pgraph": "No Message"
            }
        ]

    },







]

export default SceneInfo;