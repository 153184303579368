
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';

import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';


//import CreateMarkup from 'utils/components/Universal/CreateMarkup';
import CreatePrintable from 'utils/components/Universal/CreatePrintable';


import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Print } from './Styled';

// import { PrintSamples } from './Samples';


import { LibElem } from 'library';


const Index = (props) => {



    const portalTarget = usePortal("stage");



    // -- ref -- //
    const printRef = useRef(null)


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);    
    const [printOut, setPrintOut] = useState(false);
    const [printType, setPrintType] = useState(false);
    const [highZ, setHighZ] = useState(false);

    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);

            //let printOut;
            //printOut = JSON.stringify(props.data,null,2);
            //printOut = printOut.replace(/\n/gi,"<br/>");
            //setPrintOut("Chart goes here!!");
            setPrintOut(false);



            //console.log("PRINT H: "+printRef.current.offsetHeight);
            props.handle({

                call:"adjustContentsHeight",
                data:{

                    ref:printRef,
                    h:printRef.current.offsetHeight

                }

            })




            console.log("PRINT PROPS: "+JSON.stringify(props,null,2));


            var printData;
            printData = 'Print - Default';
            if(props.data && props.data !== undefined)
            {
                if(Object.prototype.toString.call(props.data) === '[object Array]'
                || Object.prototype.toString.call(props.data) === '[object Object]'
                )
                {
                    printData = JSON.stringify(props.data,null,2);
                }
  
            }

            setPrintOut(printData);
            setPrintType('test');



            var highZindex;
            highZindex = LibElem.HighestZ();  
            setHighZ(highZindex);

        }


        // console.log("FLYR CHART INFO: "+JSON.stringify(chartInfo,null,2));
        // alert('Flyr Chart Info')

   
    
    },[

        props,
        isLoaded,
        printRef

    ])





    return createPortal(<div>


        <div 
        className="alfa"
        style={{
            backgroundColor:'#000015',
            zIndex:(highZ+1)
        }}>
        </div>

        <div 
        className="base"
        style={{
            //backgroundColor:'blue',
            zIndex:(highZ+2)
        }}
        onClick={(v) => props.handle({

            call:"printout",
            data:false

        })}
        >
        </div>

        <div 
        //ref={baseRef}
        className="base"
        style={{
            backgroundColor:'lightyellow',
            zIndex:(highZ+3),
            width: "550px", 
            height: "100vh",
            padding :"0 0 0 0",
            margin:"0 0 0 calc(50% - 225px)"
         
        }}
        >
        
            <Print
            ref={printRef}
            backgroundColor={'lightyellow'}
            >


                {
                printType === 'test'
                ?
                    <div>

                        <pre>

                            {printOut}

                        </pre>

                    </div>

                :


                <div dangerouslySetInnerHTML={CreatePrintable(printOut)} />
        
                }

        
            </Print>


        </div>


    </div>, portalTarget);



}


export default Index;



