
import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';




import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";
  

//import { useLiquidNavBar } from 'utils/myHooks/LiquidFormula/NavBar';
import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


//import logo from 'grfx/logo.svg';
//import { fontSize } from 'pdfkit/js/mixins/fonts';


// import { 
//     FadeIn, 
//     FadeOut 
// } from 'utils/myComponents/Fades';


import 'App.css';
//import './Styl.css';
import { 

    Nav,
    NavTrack,
    NavBar,
    NavBg,
    NavGrid,
    NavGridRight,

    Button,
    ButtonText,
    ButtonTextHold,
    
    ArrowBox,
    Arrow

} from './StylAttrs';







const Index = (props) => {


    //.log("HEADER PROPS zoneObj:" + props.zoneObj);

    const { width } = useWindowDimensions()

    
    // -- router-dom -- //
    //let match = useRouteMatch();
    let location = useLocation();
    //let params = useParams();
 
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // let zoneId;
    // zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    var initAcross = 6;
    var len;
    if(zoneObj.settings 
    && zoneObj.settings !== undefined)
    {
        if(zoneObj.settings.headerNav
        && zoneObj.settings.headerNav !== undefined)
        {
            if(len < initAcross || len === initAcross) 
            {
                initAcross = zoneObj.settings.headerNav.length;
            }
         
        } 
    
    } 

  
    

    // -- ref -- //
    const refNav = useRef(null)
    const refNavTrack = useRef(null)
    
    // -- state -- //
    const [winThresh, setWinThresh] = useState(10000)
    const [thresh] = useState({

        a: 700,
        b: 500,
        c: 350

    });
    // const [navbbtnnObj] = useState({

    //     height      : 'auto',
    //     padding     : "10px 15px 13px 15px",
    //     fontSize    : "14px"

    // });


    const [fadeOpacity, setFadeOpacity] = useState("0")
    const [numAcross] = useState(initAcross);


    // No Spread
    const [gridSett] = useState({

        gridCall            :"left",//left or right
        gridWidth           :"auto",
        gridLR              :"10px",
        buttonWidth         :"auto",
        arrowBoxWidth       :"100%",
        buttonTextPadding   :"11.5px 15px 11.5px 15px"

    });


    // Spread across max width
    // const [gridSett] = useState({

    //     gridCall           :"left",//left or right
    //     gridWidth           :"100%",
    //     gridLR              :"0px",
    //     buttonWidth         :"100%",
    //     arrowBoxWidth       :"100%",
    //     buttonTextPadding   :"11.5px 0px 11.5px 0px"

    // });



    const [navStyl, setNavStyl] = useState({

        navbarColor: false,
        navbarHeight: false,

        lineColor: false,
        lineHeight: false,

        buttonBackgroundColorOver: false,
        buttonBackgroundColorOut: false,

        buttonTextColorOver: false,
        buttonTextColorOut: false,

        buttonBackgroundColorHold :false,
        buttonTextColorHold   : false

    });




    useEffect(() => {


        var newThresh;

        if(width < thresh.c
        )
        {
            newThresh = thresh.c;
        }
        else
        if(width < thresh.b
        && width > thresh.c
        )
        {
            newThresh = thresh.b;
        }
        else
        if(width < thresh.a
        && width > thresh.b
        )
        {
        
            newThresh = thresh.a
        }
        else
        if(width > thresh.a)
        {
            newThresh = 10000;
        }



        if(winThresh !== newThresh)
        {

            //alert(newThresh);
            setWinThresh(newThresh);
        
        }



        var fo;
        fo = "0";
        if(props.databind.scrollAtTop) fo = false;
        //alert(fo);
        setFadeOpacity(fo);




        // if(zoneObj.settings 
        // && zoneObj.settings !== undefined)
        // {
        //     if(zoneObj.settings.headerNav
        //     && zoneObj.settings.headerNav !== undefined)
        //     {

        //         var across = zoneObj.settings.headerNav.length;
    
        //         if(across > 5) across = 5;
        //         setNumAcross(across);

        //     } 
        
        // } 



        //---------------------------------------
        // setHeaderGrfx
        //---------------------------------------

        if(zoneObj.settings 
        && zoneObj.settings !== undefined)
        {

            // "header": {
            //     "headerCall": "B",
            //     "headerMainColor": "red",
            //     "headerMainHeight": "54px",
            //     "navbarColor": "gray",
            //     "navbarHeight": "35px",
            //     "lineColor": "white",
            //     "lineHeight": "2px",
            //     "buttonBackgroundOverColor": "red",
            //     "buttonBackgroundOutColor": "yellow",
            //     "buttonTextOutColor": "black",
            //     "logoColor": "black",
            //     "logoWidth": "260px",
            //     "logoHeight": "40px",
            //     "logoMinWidth": "40px"
            //   },

        
            // var navbarColor;
            // var navbarHeight = false;

            if(zoneObj.settings.appearance.header)
            {

                // if(zoneObj.settings.appearance.header.navbarColor)
                // {
                //     navbarColor = zoneObj.settings.appearance.header.navbarColor;
                // }

                // navbarHInt = zoneObj.settings.appearance.header.navbarHeight;
                // if(navbarHInt.match(/px/gi))
                // {
                //     navbarHInt = navbarHInt.replace(/px/gi,"");
                // }
                // //headerHInt = headerHInt + 20;

                setNavStyl({

                    navbarColor : zoneObj.settings.appearance.header.navbarColor,
                    navbarHeight : zoneObj.settings.appearance.header.navbarHeight,
            
                    lineColor : zoneObj.settings.appearance.header.lineColor,
                    lineHeight : zoneObj.settings.appearance.header.lineHeight,
            
                    buttonBackgroundColorOver: zoneObj.settings.appearance.header.buttonBackgroundColorOver,
                    buttonBackgroundColorOut: zoneObj.settings.appearance.header.buttonBackgroundColorOut,

                    buttonTextColorOver: zoneObj.settings.appearance.header.buttonTextColorOver,
                    buttonTextColorOut: zoneObj.settings.appearance.header.buttonTextColorOut,

                    buttonBackgroundColorHold :zoneObj.settings.appearance.header.buttonBackgroundColorHold,
                    buttonTextColorHold    : zoneObj.settings.appearance.header.buttonTextColorHold
        

                });

            }



        }

    


    },[
        
        props,
        zoneObj,
        width,
        winThresh,
        thresh
    ])

    





    //###################################################
    //###################################################

    let array = [];
    //let array = Data[''];
    //var host = window.location.host;
    //var key1 = pathname.split("/")[0];
    //var key2 = pathname.split("/")[1];

    var navobj;
    var ar;
    ar = [];


    // if(zoneObj[zoneId + '_navigation'] 
    // && zoneObj[zoneId + '_navigation'] !== undefined) ar = zoneObj[zoneId + '_navigation'][key1];
    
    // if(ar === undefined) ar = [];

    // if(props.navArr !== undefined 
    // && Object.prototype.toString.call(props.navArr) === '[object Array]') ar = props.navArr;


    // console.log("databind.settings: "+JSON.stringify(props.databind.settings));
    // console.log("databind.settings.headerNav: "+JSON.stringify(props.databind.settings.headerNav));


    // if(props.databind.settings 
    // && props.databind.settings !== undefined)
    // {
    //     if(props.databind.settings.headerNav
    //     && props.databind.settings.headerNav !== undefined)
    //     {

    //         ar = props.databind.settings.headerNav;

    //     } 
    
    // } 


    if(zoneObj.settings 
    && zoneObj.settings !== undefined)
    {
        if(zoneObj.settings.headerNav
        && zoneObj.settings.headerNav !== undefined)
        {

            ar = zoneObj.settings.headerNav;

        } 
    
    } 
    

    var loop = ar.length;

    //console.log("SHUTTLE WIDTH: "+shuttleWidth+"\nWINTHRESH"+winThresh)

    if(width < thresh.b
    )
    {
        loop = 1;
        
    }//==



    var to;
    for(var i=0; i < loop; i++)
    {

        navobj = ar[i];
        if(navobj && navobj !== undefined)
        {
            to = navobj['to'];

            // if(zoneObj[zoneId + '_settings']['ssg']) to = navobj['to']+"?ssg=yes";

            //if(to.charAt(to.length) !== '/') 
                //to = to+"/";

            array.push({

                "name"  :navobj['name'],
                "to"    :to
            });

        }

    }
    //console.log(JSON.stringify(array,null,2));


    return (

        
        <Nav
        ref={refNav}
        //height={navbarH}
        >


            <NavTrack
            ref={refNavTrack}
            >

                <NavBar
                    
                    backgroundColor={navStyl.navbarColor}
                    border={0}
                    height={navStyl.navbarHeight}
        
                ></NavBar>
                <NavBg></NavBg>


   
              
  
                {
                gridSett.gridCall === "right" 
                ?

                    
                    <NavGridRight
                    
                        width={gridSett.gridWidth}
                        right={gridSett.gridLR}
                        opacity={fadeOpacity}
                        numAcross={numAcross}
                    
                    >


                    
                        {

                        //-----------------------------------------------

                        //stuff.data.map((object, k) => (
                        array.map((object, k) => (

                            <div 
                            key={k}
                            >


                                <Link 
                                to={"/"+object['to']}
                                >

                                    {
                                    //------------------------------------
                                    // home button (current)
                                    //------------------------------------
                                    object['to'] === '' 
                                    && pathname === ''
                                    ?

                                        <Button
                                        width={gridSett.buttonWidth}
                                        >
        
                                            <ButtonTextHold

                                                width={gridSett.buttonWidth}
                                                padding={gridSett.buttonTextPadding}

                                                backgroundColor={navStyl.buttonBackgroundColorHold}
                                                color={navStyl.buttonTextColorHold}

                                            >

                                                {object['name']}

                                            </ButtonTextHold>

                                            <ArrowBox
                                            width={gridSett.arrowBoxWidth}
                                            >

                                                <Arrow
                                                arrowColor={navStyl.buttonBackgroundColorHold}
                                                ></Arrow>

                                            </ArrowBox>

                        
                                        </Button>


                                    :
                                    //------------------------------------
                                    // other buttons (current)
                                    //------------------------------------
                                    object['to'] !== ''
                                    && pathname.match(RegExp(object['to'],'gi'))
                                    && pathname === object['to']
                                    ? 

                                        <Button
                                        width={gridSett.buttonWidth}
                                        >
        
                                            <ButtonTextHold

                                                width={gridSett.buttonWidth}
                                                padding={gridSett.buttonTextPadding}

                                                backgroundColor={navStyl.buttonBackgroundColorHold}
                                                color={navStyl.buttonTextColorHold}

                                            >

                                                {object['name']}

                                            </ButtonTextHold>


                                            <ArrowBox
                                            //backgroundColor={"red"}
                                            width={gridSett.arrowBoxWidth}
                                            >

                                                <Arrow
                                                arrowColor={navStyl.buttonBackgroundColorHold}
                                                ></Arrow>

                                            </ArrowBox>

                        
                                        </Button>


                                
                                    //------------------------------------
                                    // active buttons
                                    //------------------------------------
                                    : 

                                        <Button
                                        width={gridSett.buttonWidth}
                                        >
        
                                            <ButtonText

                                                width={gridSett.buttonWidth}
                                                padding={gridSett.buttonTextPadding}

                                                backgroundColorOver={navStyl.buttonBackgroundColorOver}
                                                backgroundColorOut={navStyl.buttonBackgroundColorOut}

                                                textColorOver={navStyl.buttonTextColorOver}
                                                textColorOut={navStyl.buttonTextColorOut}

                                            >

                                                {object['name']}

                                            </ButtonText>


                                            <ArrowBox
                                            width={gridSett.arrowBoxWidth}
                                            >

                                                <Arrow
                                                arrowColor={"transparent"}
                                                >
                                                </Arrow>

                                            </ArrowBox>

                        
                                        </Button>



                                    //-------------------------------------
                                    }
                                

                                </Link>

                            </div>

                        ))

                        //------------------------------------------------

                        }


                    </NavGridRight> 
                    


                :

                //===========================
                // Left or Spread
                //===========================



                    <NavGrid
                    
                        width={gridSett.gridWidth}
                        left={gridSett.gridLR}
                        opacity={fadeOpacity}
                        numAcross={numAcross}
                    
                    >


                    
                        {

                        //-----------------------------------------------

                        //stuff.data.map((object, k) => (
                        array.map((object, k) => (

                            <div 
                            key={k}
                            >


                                <Link 
                                to={"/"+object['to']}
                                >

                                    {
                                    //------------------------------------
                                    // home button (current)
                                    //------------------------------------
                                    object['to'] === '' 
                                    && pathname === ''
                                    ?

                                        <Button
                                        width={gridSett.buttonWidth}
                                        >
        
                                            <ButtonTextHold

                                                width={gridSett.buttonWidth}
                                                padding={gridSett.buttonTextPadding}

                                                backgroundColor={navStyl.buttonBackgroundColorHold}
                                                color={navStyl.buttonTextColorHold}

                                            >

                                                {object['name']}

                                            </ButtonTextHold>

                                            <ArrowBox
                                            width={gridSett.arrowBoxWidth}
                                            >
                                                <Arrow
                                                arrowColor={navStyl.buttonBackgroundColorHold}
                                                ></Arrow>

                                            </ArrowBox>

                        
                                        </Button>


                                    :
                                    //------------------------------------
                                    // other buttons (current)
                                    //------------------------------------
                                    object['to'] !== ''
                                    && pathname.match(RegExp(object['to'],'gi'))
                                    && pathname === object['to']
                                    ? 

                                        <Button
                                        width={gridSett.buttonWidth}
                                        >
        
                                            <ButtonTextHold

                                                width={gridSett.buttonWidth}
                                                padding={gridSett.buttonTextPadding}

                                                backgroundColor={navStyl.buttonBackgroundColorHold}
                                                color={navStyl.buttonTextColorHold}
                                         
                                            >

                                                {object['name']}

                                            </ButtonTextHold>


                                            <ArrowBox
                                            //backgroundColor={"red"}
                                            width={gridSett.arrowBoxWidth}
                                            >

                                                <Arrow
                                                arrowColor={navStyl.buttonBackgroundColorHold}
                                                ></Arrow>

                                            </ArrowBox>

                        
                                        </Button>


                                
                                    //------------------------------------
                                    // active buttons
                                    //------------------------------------
                                    : 

                                        <Button
                                        width={gridSett.buttonWidth}
                                        >
        
                                            <ButtonText
                                            
                                                width={gridSett.buttonWidth}
                                                padding={gridSett.buttonTextPadding}

                                                backgroundColorOver={navStyl.buttonBackgroundColorOver}
                                                backgroundColorOut={navStyl.buttonBackgroundColorOut}

                                                textColorOver={navStyl.buttonTextColorOver}
                                                textColorOut={navStyl.buttonTextColorOut}

                                            >

                                                {object['name']}

                                            </ButtonText>


                                            <ArrowBox
                                            width={gridSett.arrowBoxWidth}
                                            >

                                                <Arrow
                                                arrowColor={"transparent"}
                                                >
                                                </Arrow>

                                            </ArrowBox>

                        
                                        </Button>



                                    //-------------------------------------
                                    }
                                

                                </Link>

                            </div>

                        ))

                        //------------------------------------------------

                        }


                    </NavGrid> 



                }



            </NavTrack>
   
        </Nav>
     



    );


}

export default Index



