export const KNOWLEDGE_BASE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	let darklite = props.darklite || "lite";
	if(darklite === 'dark')
	{

		return(
			<div>
			<svg xmlns="http://www.w3.org/2000/svg"
				width={svgWidth}
				height={svgHeight}
				viewBox={svgViewBox}
				preserveAspectRatio={svgPreserveAspectRatio}
				style={svgStyle}
			>
	
				<path 
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M191.269,415.723	c0,16.104,13.176,29.277,29.28,29.277h58.905c16.103,0,29.279-13.174,29.279-29.277H191.269L191.269,415.723z M179.388,372.793 h141.228c8.109,0,14.811,6.701,14.811,14.811c0,8.147-6.666,14.812-14.811,14.812H179.388c-8.109,0-14.812-6.702-14.812-14.812 C164.576,379.457,171.241,372.793,179.388,372.793L179.388,372.793z M178.526,330.551h142.947c7.922,0,14.47,6.548,14.47,14.468 c0,7.958-6.51,14.466-14.47,14.466H178.526c-7.92,0-14.467-6.546-14.467-14.466C164.059,337.061,170.567,330.551,178.526,330.551 L178.526,330.551z M250.084,80.391c-56.968,1.209-102.881,46.806-102.833,103.978c0.634,28.854,12.51,48.057,23.705,73.214 c7.182,16.461,14.976,35.97,16.475,54.062c0.423,5.107-1.157,5.477-5.203,5.6l-17.357-0.016c-3.303-0.005-4.903-1.003-5.65-4.979 c-5.456-29.057-15.916-48.446-28.296-74.655c-11.932-25.256-15.37-57.18-7.827-86.694C138.097,92.206,190.533,55,250,55 c59.468,0,111.904,37.206,126.903,95.899c7.543,29.515,4.104,61.438-7.827,86.694c-12.381,26.209-22.84,45.599-28.297,74.655	c-0.746,3.976-2.346,4.974-5.648,4.979l-17.357,0.016c-4.044-0.123-5.626-0.492-5.202-5.6c1.499-18.092,9.292-37.601,16.475-54.062 c11.194-25.157,23.07-44.36,23.705-73.214C352.797,127.196,307.053,81.6,250.084,80.391L250.084,80.391z"
				/>
				
			</svg>
			</div>
	
		);//return
	


	}
	else // lite
	{


		return(
			<div>
			<svg xmlns="http://www.w3.org/2000/svg"
				width={svgWidth}
				height={svgHeight}
				viewBox={svgViewBox}
				preserveAspectRatio={svgPreserveAspectRatio}
				style={svgStyle}
			>
	
	
				<path 
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M195.862,334.094c-15.708,0-17.702-0.899-20.386-14.675 c-10.213-48.652-54.147-95.569-54.585-141.896C120.255,110.158,177.324,55,250,55c72.678,0,129.747,55.158,129.11,122.522 c-0.437,46.327-44.371,93.244-54.586,141.896c-2.684,13.775-4.677,14.675-20.386,14.675H195.862L195.862,334.094z M200.213,420.182 c0,13.651,11.169,24.818,24.82,24.818h49.935c13.65,0,24.818-11.167,24.818-24.818H200.213L200.213,420.182z M190.141,383.788	h119.719c6.873,0,12.555,5.683,12.555,12.558c0,6.904-5.65,12.555-12.555,12.555H190.141c-6.875,0-12.556-5.682-12.556-12.555 C177.585,389.437,183.235,383.788,190.141,383.788L190.141,383.788z M189.411,347.981H310.59c6.713,0,12.264,5.548,12.264,12.263 c0,6.746-5.52,12.266-12.264,12.266H189.411c-6.713,0-12.263-5.551-12.263-12.266C177.147,353.5,182.666,347.981,189.411,347.981	L189.411,347.981z"
				/>
				
	
			</svg>
			</div>
	
		);//return




	}




}