import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


// import { 
//     useSelector, 
//     useDispatch 
// } 
// from 'react-redux';
// import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
//import useAddScript from 'utils/myHooks/AddScript';
//import { useFetch }  from 'utils/hooks/Fetch';
import { useMatomo } from '@datapunt/matomo-tracker-react';






// -- library -- //

// import { 
//     //LibArray,
//     LibElem 
// } from 'library';




const Track = (props) => {



    //-------------------------------
    // add scripts
    //-------------------------------

    // -- add scripts -- //

    /*

    useAddScript({

        to:'body',
        async:false,
        url: 'https://apis.google.com/js/client.js?onload=googleApiClientReady'

    });
    */



    //-------------------------------
    // window dims
    //-------------------------------

    // const { 
    //     width, 
    //     height 
    // } = useWindowDimensions();




    // -- screen orientation -- //
    //const screenOrientation = useScreenOrientation()



    //-------------------------------
    // router
    //-------------------------------

    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    //console.log("Location: "+JSON.stringify(location,null,2));

    // location: {
    //     "pathname": "/Text1",
    //     "search": "",
    //     "hash": "#!",
    //     "key": "90mmxu"
    // }




    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-------------------------------
    // redux
    //-------------------------------

    // //const dispatch = useDispatch();
    // const zoneObj = useSelector(state => state.zone);
    // //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");
    // if(currentZone === '') currentZone = 'Home';

    //let zoneKey = currentZone + "_fetch";
    
    // -- Fetch -- // 
    // let fetchd = Fetch({

    //     key      : currentZone+"_fetch",
    //     route    : pathname

    // }); 



    //-------------------------------
    // matomo
    //-------------------------------
    
    const { 
        trackPageView, 
        //trackEvent 
    } = useMatomo();



    // Track page view
    useEffect(() => {
     
        //var documentTitle = pathname;

        // var href = window.location.href;                    //returns the href (URL) of the current page
        // var host = window.location.host;                //returns the domain with port number
        // var hostname = window.location.hostname;        //returns the domain name of the web host
        // var pathname = window.location.pathname;        //returns the path and filename of the current page
        // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
        // var assign = window.location.assign;            //loads a new document

        trackPageView({

            //documentTitle: documentTitle, // optional
            //href: href, // optional
            customDimensions: [

                {
                    user: 1,
                    value: 'TM',
                }

            ], // optional

        })



    },[
        trackPageView
    ])
  
    // const handleMatomoEvent = (hndl) => {

    //     alert("handleMatomoEvent: "+hndl.call);

    //     // Track click on button
    //     trackEvent({ 
    //         category: 'sample-page', 
    //         action: 'click-event' 
    //     });

        
    // }









    //------------------------------------------
    // state/effect - view zoneObj
    //------------------------------------------

    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {


        if(!isLoaded)
        {
            setIsLoaded(true);
            props.handle({

                call:"Track",
                data:pathname

            });

        }

    },
    [

        props,
        isLoaded,
        pathname
    ])






    

    return(<div></div>);


}

export default Track;
