import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import CreateMarkup from '../../CreateMarkup';
import { Print, Grid, Cell } from './Styled';


const Index = (props) => {




    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- ref -- //
    const refA = useRef(null);

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [printOut, setPrintOut] = useState(false);
    const [items, setItems] = useState([]);


    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);
            setPrintOut(false);
          

            console.log("PROPS: "+JSON.stringify(props,null,2));



            //------------------------------------------------
            var array = [];
            var regx = new RegExp(props.data.value,"gi");

            for(var keyname in zoneObj.calc_overhead.form_items)
            {
                if(keyname.match(regx))
                {
                    array.push(zoneObj.calc_overhead.form_items[keyname])
                }
            }
            setItems(array);


            /*
            let printOut;
            //printOut = JSON.stringify(props.data,null,2);

            if(array.length > 0) 
                printOut = JSON.stringify(array,null,2);

            printOut = printOut.replace(/\n/gi,"<br/>");
            setPrintOut(printOut);
            */
            
            //-------------------------------------------------

            //console.log("PRINT H: "+refA.current.offsetHeight);
            props.handle({

                call:"adjustContentsHeight",
                data:{

                    ref:refA,
                    h:refA.current.offsetHeight

                }

            })


        }


    },[

        props,
        isLoaded,     
        refA,
        zoneObj

    ])




    // -- colors -- //

    var white_color = '#EEF3F9';//'#EDEEF1' /* #EBECF1  #F9F9F9  #EEF3F9   */

    //var yellow_color = '#FF9';
    //var green_color = '#D9FFD9';
    //var blue_color = '#D5E6FF';//'#B0D0FF';//
    

    //var mask_green_color = '#00FF00';
    //var mask_blue_color = '#0060BF';
    //var mask_trans_color = 'transparent';



    // -- handle -- //

    const handleOverOut= (hndl) => {

        switch(hndl.call)
        {
        case'over':


        break;
        case'out':



        break;
        default:
        }


    }




    if(printOut)
    {
        return (

            <Print
            ref={refA}
            backgrounColor={"lightyellow"}
            >
    
                <div dangerouslySetInnerHTML={CreateMarkup(printOut)} />
    
            </Print>
    
        );

    }
    else
    {

        return (

            <div>

                <Grid
                ref={refA}
                >

                    {items.map((item, i) => (

                        <Cell
                        key={i}
                        //backgroundColor={'#fafc66'}
                        backgroundColor={white_color}
                        border={"1px solid gray"}
                        fontWeight={"bold"}


                        onMouseEnter={() => handleOverOut({

                            call        :"over",
                            bgColor     :"orangered",
                            txtColor    :"#FFF",
                            brColor     :"orangered"
                
                        })}
                
                        onMouseOut={() => handleOverOut({
                
                            call        :"out",
                            bgColor     :"#07a2e3",
                            txtColor    :"#FFF",
                            brColor     :"#07a2e3"
                
                
                        })}


                        >
                            {item.name}

                        </Cell>

                    ))} 

                </Grid>
                

            </div>
                    
        );
    

    }



}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/