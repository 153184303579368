import React, { 

    useState, 
    //useEffect, 
    //useRef 

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';


import { useLiqGrid } from 'utils/hooks/LiquidFormula/LiqGrid';

import StylGrid from './Grid.module.css';



const Index = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();


    //console.log(JSON.stringify(location,null,2));
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- window dims -- //
    //const { height } = useWindowDimensions();



     // -- multi refs -- //

     const [cellRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
     const setCellRef = (ref) => {
 
         if(ref && ref !== null && ref !== undefined)
         {
             //console.log("ref::::"+ref.id);
             var found;
             found = false;
             for(var i=0; i < cellRefs.length; i++)
             {
                 if(cellRefs[i].id === ref.id) found = true;
             }
             if(!found) cellRefs.push(ref);
             //console.log(cellRefs.length);
 
         }
 
     };

    //  const maskRefs = useState([]);
    //  const setMaskRef = (ref) => {
 
    //      if(ref && ref !== null && ref !== undefined)
    //      {
    //          //console.log("ref::::"+ref.id);
    //          var found;
    //          found = false;
    //          for(var i=0; i < maskRefs.length; i++)
    //          {
    //              if(maskRefs[i].id === ref.id) found = true;
    //          }
    //          if(!found) maskRefs.push(ref);
    //          //console.log(cellRefs.length);
 
    //      }
 
    //  };

     






/*


    // -- handle -- //

    const handleOverOut = (hndl) => {


        //hndl.bgColor;
        //hndl.txtColor;
        //hndl.brColor;


        
        var cell = false;
        var mask = false;

        if(cellRefs.length > 0)
        {
            var cellRegex = new RegExp(hndl.id.toString(),"gi");
            for(var cellRef of cellRefs)
            {
                if(cellRef.id.match(cellRegex) 
                )
                {
                    cell = cellRef;
                    break;
                }
            }
        }

        if(maskRefs.length > 0)
        {
            var maskRegex = new RegExp(hndl.id.toString(),"gi");
            for(var maskRef of maskRefs)
            {
                if(maskRef.id.match(maskRegex) 
                )
                {
                    mask = maskRef;
                    break;
                }
            }
        }

    

        //console.log(hndl.gridType);

        switch(hndl.call)
        {
        case'over':
    
            switch(hndl.gridType)
            {
            case"ImageText":
           
                if(cell) cell.style.borderColor = "red";
            break;
            default:
    
                //if(mask) mask.style.backgroundColor = 'magenta';
            break;
            }//==

        break;
        default:

            switch(hndl.gridType)
            {
            case"ImageText":
    
                if(cell) cell.style.borderColor = "lightgray";
            break;
            default:
    
                //if(mask) mask.style.backgroundColor = 'transparent';
            break;
            }//==

        break;
        }   



    };


*/





    var gridObj;
    gridObj = useLiqGrid(props.items, props.info);
    //console.log("myBuilds/FGrid/FixedGrid - vars..."+JSON.stringify(gridObj['vars'],null,2));

    const liqItems = gridObj['items'];
    if(props.info.call === 'flex')
    {
        var data = [];
        for(var itm of gridObj['items'].data)
        {
            if(Object.prototype.toString.call(itm) === '[object Array]')
            {
                for(var obj of itm)
                {

                    data.push(obj);

                }
            }
        }
        liqItems.data = data;
    }
    //console.log("LIQITEMS: "+JSON.stringify(liqItems.data));
    //alert('check liqItems')





    var gridClass;
    var cellClass;

    gridClass = StylGrid.FixedGrid;
    cellClass = StylGrid.CellA;

    if(props.info.cellClass 
    && props.info.cellClass !== undefined
    ) 
    {
        switch(props.info.cellClass)
        {
        case'B':
            cellClass = StylGrid.CellB
        break;
        case'B2':
            cellClass = StylGrid.CellB2
        break;
        case'C':
            cellClass = StylGrid.CellC;
        break;
        case'D':
            cellClass = StylGrid.CellD;
        break;
        default:
        }
    }


    //alert(cellClass+" "+gridClass);






    return (<div>



        {props.info.gridType === 'ImageOnlyStatic' ?


            <div
            className={gridClass}
            style={{
                backgroundColor :props.info.gridBgColor,
                maxWidth        :props.info.gridMaxWidth
            }}
            >
                {liqItems.data.map((box, i) => (


                    <div
                    key={i}   
                    //ref={setCellRef}
                    //id={box.id.toString()}   
                    //className={StylGrid.Cell}
                    className={cellClass}
                    style={{
                        cursor:"default"
                    }}
                    >


                        <div 
                        className={StylGrid.Sizer}
                        style={{

                            //backgroundColor:"magenta",
                            width:liqItems.cell.thumb.width,
                            //height:liqItems.cell.thumb.height,
                            margin:liqItems.cell.thumb.margin,
                            border:liqItems.cell.border

                        }}
                        >

                            <div
                            className={StylGrid.Thmb}
                            style={{
                                backgroundColor:"black"
                            }}
                            >
                                {
                                box.image.src.match(/http/gi) 
                                ?

                                    <img
                                    className={StylGrid.ImageOnly_StaticImg}
                                    style={{
    
                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,
    
                                        marginTop:box.image.top,
                                        marginLeft:box.image.left,
    
                                        opacity:props.info.opacity
    
                                    }}

                                        src={box.image.src}
                                        //src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='(!)'
                                    />  
    
    





                                :


  
                                    <img
                                    className={StylGrid.ImageOnly_StaticImg}
                                    style={{

                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,

                                        marginTop:box.image.top,
                                        marginLeft:box.image.left,

                                        opacity:props.info.opacity

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='(!)'
                                    />  




                                }

                            

                            </div>


                        </div>


                    </div>

                
                ))} 


            </div>

        :null}






        {props.info.gridType === 'ImageOnly' ?


            <div
            className={gridClass}
            style={{
                backgroundColor :props.info.gridBgColor,
                maxWidth        :props.info.gridMaxWidth
            }}
            >
                {liqItems.data.map((box, i) => (


                    <div
                    key={i}   
                    //ref={setCellRef}
                    //id={box.id.toString()}   
                    //className={StylGrid.Cell}
                    className={cellClass}

                    onClick={() => props.handle({

                        call        :'showFGridFlyr',
                        data        :liqItems.data,
                        gridType    :props.info.gridType,
                        position    :i,
                        id          :box.id
    
                    })}

                    >


                        <div 
                        className={StylGrid.Sizer}
                        style={{

                            //backgroundColor:"magenta",
                            width:liqItems.cell.thumb.width,
                            //height:liqItems.cell.thumb.height,
                            margin:liqItems.cell.thumb.margin,
                            border:liqItems.cell.border

                        }}
                        >

                            <div
                            //ref={setMaskRef}
                            //id={"Mask_"+box.id.toString()}
                            className={StylGrid.Mask}
                            style={{

                                //backgroundColor:"magenta",
                                //width:liqItems.cell.mask.width,
                                //height:liqItems.cell.mask.height,
                                //top:liqItems.cell.mask.top,
                                //left:liqItems.cell.mask.left
                                
                            }}
                            >

                                {props.info.mask.message.boolean ?
                                
                    
                                    <div 
                                    className={StylGrid.MaskBox}
                                    >
                                        {/* <div
                                        className={StylGrid.MaskTxt}
                                        style={{

                                            //backgroundColor:props.info.mask.message.backgroundColor,
                                            color:props.info.mask.message.textColor

                                        }}
                                        >

                                            View 

                                        </div> */}


                                        <div
                                        className={StylGrid.Icon}
                                        >
                                            <div
                                            className={StylGrid.MagnifyingGlass}
                                            >
                                            </div>

                                        </div>


                                        <div
                                        className={StylGrid.Text}
                                        >
                                            View

                                        </div>


                                    </div>
        

                                :null}



                            </div>





                            <div
                            className={StylGrid.Thmb}
                            >


                                {
                                box.image.src.match(/http/gi) 
                                ?

                                    <img
                                    className={StylGrid.ImageOnly_Img}
                                    style={{

                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,

                                        marginTop:box.image.top,
                                        marginLeft:box.image.left

                                    }}

                                        src={box.image.src}
                                        //src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='(!)'
                                    />   



                                :


                                    <img
                                    className={StylGrid.ImageOnly_Img}
                                    style={{

                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,

                                        marginTop:box.image.top,
                                        marginLeft:box.image.left

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='(!)'
                                    />   



                                }


                            </div>


                        </div>


                    </div>

                
                ))} 


            </div>

        :null}






        {props.info.gridType === 'ImageText' ?


            <div
            className={gridClass}
            style={{
                backgroundColor :props.info.gridBgColor,
                maxWidth        :props.info.gridMaxWidth
            }}
            >
                {liqItems.data.map((box, i) => (


                    <div
                    key={i}
                    //className={StylGrid.Cell}
                    className={cellClass}
                    >


                        <div
                        ref={setCellRef}
                        id={box.id.toString()}
                        className={StylGrid.Container}
                        style={{

                            width:liqItems.cell.container.width,
                            height:liqItems.cell.container.height,
                            margin:liqItems.cell.container.margin,
                            padding:liqItems.cell.container.padding,
                            border:liqItems.cell.border
                        }}

                        onClick={() => props.handle({

                            call        :'showFGridFlyr',
                            data        :[box],
                            gridType    :props.info.gridType,
                            position    :i,
                            id          :box.id
        
                        })}

                        >



                            <div 
                            className={StylGrid.ThmbSizer}
                            style={{

                                //backgroundColor:"blue",
                                width:liqItems.cell.thumb.width,
                                //height:liqItems.cell.thumb.height, //vh instead
                                margin:liqItems.cell.thumb.margin,
                            

                            }}
                            >


                                <div
                                //ref={setMaskRef}
                                //id={"Mask_"+box.id.toString()}
                                className={StylGrid.Mask}
                                >
    
                                    {props.info.mask.message.boolean ?
                                        
                            
                                        <div 
                                        className={StylGrid.MaskBox}
                                        >


                                            {/* <div
                                            className={StylGrid.MaskTxt}
                                            style={{

                                                backgroundColor:props.info.mask.message.backgroundColor,
                                                color:props.info.mask.message.textColor
        
                                            }}
                                            >

                                                View 

                                            </div> */}



                                            <div
                                            className={StylGrid.Icon}
                                            >
                                                <div
                                                className={StylGrid.MagnifyingGlass}
                                                >
                                                </div>

                                            </div>


                                            <div
                                            className={StylGrid.Text}
                                            >
                                                View

                                            </div>



                                        </div>
        

                                    :null}

                                </div>





                                <div
                                className={StylGrid.Thmb}
                                >

        
                                    {
                                    box.image.src.match(/http/gi) 
                                    ?

    
                                        <img
                                        className={StylGrid.ImageText_Img}
                                        style={{
    
                                            minWidth:box.image.maxWidth,
                                            maxHeight:box.image.maxHeight,
        
                                            marginTop:box.image.top,
                                            marginLeft:box.image.left
    
                                        }}
    

                                            src={box.image.src}
                                            //src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                            //src={window.location.origin + box.image.src} 
                                            //width={box.image.w}
                                            //height={box.image.h} 
                                            alt='(!)'
                                        />  
    
    


                                    :


                                        <img
                                        className={StylGrid.ImageText_Img}
                                        style={{

                                            minWidth:box.image.maxWidth,
                                            maxHeight:box.image.maxHeight,
        
                                            marginTop:box.image.top,
                                            marginLeft:box.image.left

                                        }}

                                            src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                            //src={window.location.origin + box.image.src} 
                                            //width={box.image.w}
                                            //height={box.image.h} 
                                            alt='(!)'
                                        />  



                                    }


                                </div>


                            </div>


                

                            <div 
                            className={StylGrid.Heading}
                            >
                                {box.title}

                            </div>

                            <div 
                            className={StylGrid.Pgraph}
                            >
                                {box.text}

                            </div>

                        </div>


                    </div>


                


                ))} 

            </div>




        :null}




    </div>)




}

export default Index





/*

    <div
    ref={setMaskRef}
    id={"Mask_"+box.id.toString()}
    className={StylGrid.Mask}
    style={{

        //backgroundColor:"magenta",
        width:liqItems.cell.mask.width,
        height:liqItems.cell.mask.height,
        top:liqItems.cell.mask.top,
        left:liqItems.cell.mask.left
        
    }}

    onClick={() => props.handle({

        call        :'showImageFlyr',
        data        :[box],
        gridType    :props.info.gridType,
        position    :i,
        id          :box.id

    })}


    onMouseEnter={() => handleOverOut({

        gridType    :props.info.gridType,
        id          :box.id.toString(),
        call        :"over",
        bgColor     :"orangered",
        txtColor    :"#FFF",
        brColor     :"orangered"

    })}

    onMouseOut={() => handleOverOut({

        gridType    :props.info.gridType,
        id          :box.id.toString(),
        call        :"out",
        bgColor     :"#0291cd",
        txtColor    :"#FFF",
        brColor     :"#0291cd"


    })}

    >
    </div>

*/

