import React, { 

    // useState, 
    // useEffect, 
    // //useRef 

} from 'react';

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'

import StylColumn from '../StylModules/Column.module.css';

import CreateMarkup from 'utils/components/Universal/CreateMarkup';
//import CreateLink from 'utils/components/Universal/CreateLink';


const SingleTextBox = (props) => {


    const items = props.items;
    //const columnClass = props.columnClass;


    return (




        <div
        className={StylColumn.SingleColumn}
        >
            {items.map((item, i) => (

                <div 
                key={i}
                className={StylColumn.Box}
                >
                    <div className={StylColumn.Txt}>


                        <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />


                    </div>

                </div>


            ))}

        </div>


    )



}

export default SingleTextBox;
