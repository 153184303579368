import React, { 

    useState, 
    useEffect, 
    // //useRef 

} from 'react';

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import { 

    Headline

} from '../Styled/Headline';


//import CreateMarkup from '../CreateMarkup';

const HeadlineText = (props) => {


    //const items = props.items;
    //const columnClass = props.columnClass;

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState(false);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            
        }


        //console.log("HeadlineText: "+JSON.stringify(props,null,2));

        if(props.items[0] && props.items[0] !== undefined)
        {
            if(props.items[0].styl && props.items[0].styl !== undefined)
            {
                setItems(props.items);
            }
        }


    },[
        props,
        isLoaded
    
    ])



    if(!items)
    {

        return(<div></div>)

    }else{


        return (


            <Headline>

                {Object.keys(items[0]).map(keyName => (

                    // items[0][keyName].map((item, i) => (
                                                                
                    //     <div className={"XLarge"}>

                    //         {item}

                    //     </div>

                    // ))

                    keyName !== 'styl'
                    ?
                        <div 
                        key={keyName}
                        className={items[0].styl[keyName].className}
                        style={{

                            textAlign   :items[0].styl[keyName].textAlign,
                            color       :items[0].styl[keyName].textColor
                        }}
                        >

                            {items[0][keyName]}

                        </div>

                    :null

                ))}
            




    {/* 
                <div className = "Big">

                    Big

                </div>


                <div className = "XLarge">

                    XLarge

                </div>


                <div className = "Large">

                    Large

                </div>


                <div className = "Medium">

                    Medium

                </div>


                <div className = "Small">

                    Small

                </div>


                <div className = "Note">

                    Note

                </div> */}


            </Headline>


        )


    }





}

export default HeadlineText;
