

export const ModifyZoneStore = (obj) => {

    //alert(JSON.stringify(obj.item,null,2));

    return {
        type:obj.type,
        item:obj.item
    }

}