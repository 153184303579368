export const BUTTON_LOGO_BRIDGESTONE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    
    {/*Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M322.93,274.864c-1.081,0.1-2.435,0.257-4.543,0.257 c-10.072,0-8.955-3.036-4.709-19.483c4.424-17.088,6.994-20.211,13.87-20.211c6.052,0,6.1,4.083,4.564,9.238h15.362 c2.492-9.633,0.828-18.165-17.137-18.165c-20.005,0-25.438,4.218-31.882,29.138c-6.005,23.213-4.032,29.368,17.596,29.368 c10.252,0,14.544-0.753,20.79-1.884l7.231-27.134h-15.416L322.93,274.864L322.93,274.864z M370.515,240.668 c0.613-2.382,2.299-4.402,5.603-4.402h16.466l2.317-8.965h-23.919c-9.918,0-12.899,1.774-16.747,16.697l-7.105,27.302 c-2.178,9.153,0.536,13.139,8.028,13.139h20.634c4.063,0,6.681-2.154,7.939-5.47l1.692-4.372h-20.718 c-2.007,0-2.701-0.611-2.321-2.222l3.488-13.658h20.551l2.32-8.953h-20.561L370.515,240.668L370.515,240.668z M611.225,236.266	h16.457l2.318-8.965h-23.915c-9.925,0-12.913,1.774-16.765,16.697l-7.093,27.292c-2.181,9.16,0.536,13.148,8.021,13.148h20.639 c4.059,0,6.678-2.154,7.937-5.47l1.697-4.372h-20.718c-2.01,0-2.707-0.611-2.326-2.229l3.484-13.651h20.554l2.315-8.953h-20.554 l2.339-9.102C606.229,238.289,607.921,236.266,611.225,236.266L611.225,236.266z M274.48,227.301h-20.578l-14.325,55.428	c-0.247,0.944,0.305,1.71,1.236,1.71h18.318c23.019,0,26.9-4.396,33.213-28.811C297.83,234.415,294.447,227.301,274.48,227.301 L274.48,227.301z M276.58,255.628c-4.302,16.654-6.803,19.053-15.288,19.053h-4.221l9.938-38.403
		c1.276-0.005,1.755-0.053,4.922-0.053C280.849,236.217,280.839,239.172,276.58,255.628L276.58,255.628z M562.29,227.189
		c-11.854,0-17.361,0.255-20.597,0.111l-14.337,55.428c-0.25,0.944,0.3,1.71,1.228,1.71h13.748l12.459-48.161 c1.271-0.005,3.19-0.108,5.146-0.108c6.684,0,7.562,2.374,5.805,9.185l-9.676,37.375c-0.244,0.944,0.313,1.719,1.238,1.719h13.744 l10.113-39.094C584.664,231.81,578.714,227.189,562.29,227.189L562.29,227.189z M485.707,231.102l1.441-3.801h-43.923l-2.378,9.283 h15.268c0.156,0,0.156,0.194,0.022,0.3c-2.441,1.832-3.934,4.449-4.645,7.188l-10.011,38.657c-0.243,0.941,0.311,1.71,1.238,1.71 h14.759l12.396-47.854h7.568C481.688,236.584,484.264,234.808,485.707,231.102L485.707,231.102z M242.289,227.301h-14.527 l-14.34,55.428c-0.241,0.941,0.312,1.71,1.242,1.71h14.53l14.332-55.423C243.775,228.068,243.214,227.301,242.289,227.301 L242.289,227.301z M510.766,226.513c-17.3,0-24.809,3.077-31.604,29.36c-5.887,22.771-1.168,29.359,16.423,29.359
		c17.903,0,24.975-3.682,31.609-29.359C532.004,237.287,531.021,226.513,510.766,226.513L510.766,226.513z M511.535,255.414
		c-4.215,16.287-8.378,20.22-13.474,20.22c-6.589,0-7.573-2.406-2.966-20.22c3.828-14.819,7.487-19.984,13.375-19.984 C515.054,235.43,515.787,238.977,511.535,255.414L511.535,255.414z M146.351,249c6.898-1.179,13.859-3.957,16.338-14.021 c2.685-10.892-5.642-16.855-15.221-16.245l-14.654,51.301c-0.792,2.776-3.76,5.105-6.58,5.105h-5.622l8.863-30.983 c0.129-0.467-0.374-0.81-0.756-0.486c-13.033,11.209-28.752,27.587-38.593,39.972c-0.25,0.317-0.126,0.795,0.307,0.795h38.342 c16.166,0,25.015-5.013,28.221-16.979c2.614-9.749-2.496-16.395-10.65-18.113C146.208,249.323,146.078,249.049,146.351,249 L146.351,249z M214.798,243.998c2.404-9.294-3.456-16.697-18.257-16.697h-21.994l-14.337,55.428 c-0.249,0.941,0.304,1.71,1.234,1.71h13.747l12.455-48.161c1.329-0.005,2.603-0.053,5.767-0.053c5.3,0,7.257,3.266,6.114,7.68 c-1.506,5.815-6.01,9.081-11.368,9.081h-2.019l5.716,27.65c0.682,2.697,2.037,3.812,5.134,3.812h12.278l-5.335-22.917 c-0.516-1.708-1.065-2.946-1.88-3.961c-0.107-0.128-0.129-0.303,0.063-0.37C208.685,255.177,212.978,251.025,214.798,243.998	L214.798,243.998z M420.051,226.029c-13.568,0-20.889,5.322-23.512,15.518c-0.729,2.802-0.457,7.362,1.213,10.22 c3.716,6.377,13.588,7.172,19.047,10.966c2.373,1.648,2.447,3.874,1.811,6.321c-1.223,4.759-3.615,7.027-8.379,7.027 c-4.572,0-7.321-2.658-4.699-11.001h-14.523c-5.818,13.921,1.769,20.229,17.226,20.229c14.108,0,21.944-3.356,25.301-16.33 c1.146-4.439,0.401-9.228-2.313-12.18c-5.278-5.735-15.426-6.812-18.992-10.135c-1.815-1.688-1.795-4.055-1.274-6.096 c0.964-3.734,3.477-5.777,7.551-5.777c6.381,0,6.537,3.931,4.966,8.58h13.687C440.379,233.654,436.163,226.029,420.051,226.029 L420.051,226.029z"
    />
    
    {/*Red*/}
    <path 
        fill="rgb(220,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.772,256.708c12.443-12.939,29.606-27.391,43.845-37.135 c0.348-0.237,0.202-0.838-0.254-0.838h-27.375c-3.605,0-7.173,2.947-8.038,6.174l-8.953,31.386 C98.867,256.774,99.443,257.049,99.772,256.708L99.772,256.708z"
    />
    
    
    

    

		</svg>
		</div>

	);//return
}