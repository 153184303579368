import { useState, useEffect } from "react";

import axios from "axios";

import DefaultFetchResponse from './DefaultFetchResponse';


const queryString = params =>
    Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&")

const createUrl = (url, queryOptions) => {

    if(Object.keys(queryOptions).length > 0)
    {
        url = url + "?" + queryString(queryOptions);
    }
    return url

}

const createPostParams = (bodyOptions) => {

    var ret;
    var params = "";
    var count = 0;
    for(var keyname in bodyOptions)
    {
        if(count === 0)
        {
            params += keyname+"="+bodyOptions[keyname];
        } else {
            params += "&"+keyname+"="+bodyOptions[keyname];
        }
        count++;
    }

    ret = params;
    if(ret === "") ret = false;
    return ret;

}





export function useFetch(

    url, 
    options = { 

        method:'',
        body: {}, 
        query: {} 
    },
    props

) {

    const [data, setData] = useState({

        response    : null,
        error       : false,
        loading     : true

    })

    useEffect(() => {


        const runAxios = () => {


            setData({
                response   : 'processing',
                error      : false,
                loading    : true,
            })


            let result;

            let axiosParams;
            if(options.method.match(/GET/gi))
            {
                axiosParams = {
                    method: "GET",
                    url: createUrl(url, options.query),
                }
                
            }
            else
            if(options.method.match(/POST/gi))
            {

                axiosParams = {

                    method: "POST",
                    url: createUrl(url, options.query),
                    data: createPostParams(options.body),
                    headers: {

                        //'Content-Type': "application/json",
                        'Content-Type'  : 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        //'Authorization' : 'Bearer '+localStorage.accessToken
                    },
                    // responseType:'json'
                }

            }

            // Content-Type
            //     'application/x-www-form-urlencoded' 
            //     'application/json' 
            
            // responseType 
            //     'arraybuffer', 
            //     'blob', 
            //     'document', 
            //     'json', 
            //     'text', 
            //     'stream'


            axios(axiosParams)
            .then(response => {

                return response.data
            })
            .then(data =>  {

                console.log("Object prototype: "+Object.prototype.toString.call(data));
                console.log("data.success: "+data.success);
                console.log("data.result: "+data.result);
                console.log("data.result: "+JSON.stringify(data.result,null,2));

                //alert('success');

                // if(props.key.match(/MSZ/gi))
                // {

                //     console.log("MSZ RESULT: "+JSON.stringify(result,null,2));
                //     //alert('success');


                // }

                /*
                result={
                    "success": true,
                    "result": {
                        "tokenError": {
                            "name": "TokenExpiredError",
                            "message": "jwt expired",
                            "expiredAt": "2020-12-27T18:13:18.000Z"
                        },
                        "authorized": false,
                        "user": false
                    }
                }

                */
                //alert(result.success);
                if(data.success)
                {
                    result = data.result;
                }

                setData({
                    response   : result,
                    error      : false,
                    loading    : false,
                })


            })
            .catch(error =>  {

                console.log(error)

                DefaultFetchResponse.sceneInfo[0].items = [{

                    heading :"Fetch Error",
                    pgraph  :error
                
                }];
                result = JSON.parse(JSON.stringify(DefaultFetchResponse));//avoids the react-redux mutation error
                setData({
                    response   : result,
                    error      : error,
                    loading    : false,
                })

            });




        };
          
        

        //#############################################################################
        //#############################################################################

        /**
         * 
         * 
         * To block multiple hits to the server 
         * 
         * 1) Set response to "processing" or "not null" once fetch begins - this prevents a function rerun
         * 2) Keep loading set to true until fetch is complete
         * 3) On page component check that loading is "false" before calling dispatch
         * 
         * 
         * NOTE: There are two version async and non async - no differcene I see between the two
         * use the non async version
         * 
         * 
         */


        let zoneObj = props.zoneObj;
        let zoneKey = props.zoneKey;
        var okay = true;
        if(zoneObj[zoneKey] && zoneObj[zoneKey] !== undefined)
        {
            okay = false
        }
        if(okay && data.response === null)
        {
            runAxios();
        }

    

    },[data,url,options,props])
    return data
}




//------------------------
// USAGE
//------------------------

// const { response, error, loading } = useFetch(
//     "https://www.anapioficeandfire.com/api/houses",
//     {
//         query: {
    //         page: 1,
    //         pageSize: 10,
//         },
//     }
// );



// const fetchd = useFetch(
//     "http://localhost:3001/fetch-blueprint",
//     {
//         method:'POST',
//         body:{
//             site: 'powerdigitalmedia.net',
//             tree: 'blueprint-reax1',
//             route: pathname

//         }
//     }
// );

