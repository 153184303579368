const LibString =  {


	//CapitalizeWords('this IS THE wOrst string eVeR');
	
	CapitalizeWords : (s) => {
	
	
		return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
	
		
    },
	

	TrimWhitespaces : function(dirty) {

		var clean = dirty.replace(/(^\s+|\s+$)/g, '');//replace kills whitespace
		return clean;

	},


	
    InputMaxlength: (v) => {

        var str = v.str.toString();
        var maxlength = parseInt(v.maxlength);
        var diff;
    

        // console.log("IN STR: "+str+"\nmaxlength: "+maxlength)

        if(str.length === parseInt(maxlength)-1)
        {
            if(!str.match(/\./gi)) maxlength = maxlength-2;
            diff = str.length - maxlength;
            str = str.substr(0,str.length-diff);
        }
        else
        if(str.length > maxlength 
        || str.length === maxlength) 
        {
            if(!str.match(/\./gi)) maxlength = maxlength-1;
            diff = str.length - maxlength;
            str = str.substr(0,str.length-diff);
        }

        // console.log("OUT STR: "+str+"\nmaxlength: "+maxlength)


        return str;

    },






	//#######################################################################
	//#######################################################################

	ReplaceSpecialChars : function(string){


		var R = string;

			//=============================================
			//REPLACE all special chars except( - _  . ')
			//=============================================


			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;

			//WITHOUT APOSTROPHE
			//var regex = /[!@#\$%^&\*,+=":;?()\<\>\[\]\{\}\/]+/gi;


			//---------------------
			//React Version
			//---------------------

			//WITH APOSTROPHE
			//var regex = /[!@#\$%^&\*,+="':;?()\<\>\[\]\{\}\/]+/gi;

			//WITHOUT APOSTROPHE
			var regex = /[!@#$%^&*,+=":;?()<>[]{}\/]+/gi;


			R = R.replace(regex,'');


		return R;


	},



	//#######################################################################
	//#######################################################################

	
	LettersToNumbers : function(str) {


		var pos;
		var out = 0, len = str.length;

		for(pos = 0; pos < len; pos++) 
		{
			out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
		}

		return out;


	},





	//#######################################################################
	//#######################################################################

	CharacterLength : function(text,charlen,use_dots) {
					
		var R = text;

			R = R.substring(0,charlen);					
			if(use_dots)
			{
				if(R.length > charlen) R = R + "..";

			}//

		return R;

	},





	//#######################################################################
	//#######################################################################

	
	LimitString : function( string, charlen, dots ){


		var R = string;
		var use_dots;

			if(dots)
			{
				if(R.length > charlen)
				{ 
					use_dots = true;
				}else{
					use_dots = false;
				}//#

			}//#
										
			R = R.substring(0,charlen);
							
			if(use_dots) R = R + "..";


		return R;


	},

	






	//#######################################################################
	//#######################################################################

	

	SlashCount : function(str) {
	

		if(str && str !== '')
		{
			var arr = str.split('/');
			var count = arr.length-1;
			return count;

		}//#

	
	},





	//#######################################################################
	//#######################################################################
	
	HasInvalidChars : function(str){
	
		var r = 'safe';
	
		//var regex = /[!@#\$%^&\*,+="':;?\<\>\[\]\{\}\/]+/i;


		var regex = /[!@#$%^&*,+="':;?<>[]{}\/]+/i;
		var invalid_str = str.match(regex);
	
		if(invalid_str)
		{
	
			r = '! @ # $ % ^ & * , "'; 
			r += " ' + = : ; ? [] {} < > ()";
		}//
	
		return r;

	},







	//#######################################################################
	//#######################################################################


	RemoveInvalidChars : function(str){
	
		//var regex = /[!@#\$%^&\*,+="':;?\<\>\[\]\{\}\/]+/i;
		var regex = /[!@#$%^&*,+="':;?<>[]{}\/]+/i;
		str = str.replace(regex,'');
		return str;
	
	},






	//#######################################################################
	//#######################################################################

	EncodeDecode :  (props) => {


        var str = props.str;
        var call = props.call;
        var type = props.type;

	
		var amp_chars = { 
						"&": "&amp;",
						"<": "&lt;",
						">": "&gt;",
						'"': '&quot;',
						"'": '&#39;',
						"/": '&#x2F;',
						"?": '&#63'
						};
		
		
		var percent_chars = {
							"&": "%26",
							"<": "%26lt;",
							">": "%26gt;",
							'"': '%26quot;',
							"'": '%26#39;',
							"/": '%26#x2F;',
							"?": '%26#63',
                            " ": '%20'
							};
		
		
		switch(call)
		{
		case'decode':
	

			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp(percent_chars['"'],'g'), '"');
				str = str.replace(new RegExp(percent_chars["'"],'g'), "'");
				str = str.replace(new RegExp(percent_chars["&"],'g'), "&");
				str = str.replace(new RegExp(percent_chars["?"],'g'), "?");
				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
	
				str = str.replace(new RegExp("%26nbsp;",'g'), "");


			break;
			case'amp':

				str = str.replace(new RegExp(amp_chars["&"],'g'), "&");
				str = str.replace(new RegExp(amp_chars['"'],'g'), '"');
				str = str.replace(new RegExp(amp_chars["'"],'g'), "'");
				str = str.replace(new RegExp(amp_chars["?"],'g'), "?");
		
				str = str.replace(new RegExp("&nbsp;",'g'), "");
	
			break;
			default:


				str = str.replace(new RegExp(percent_chars["&"],'g'), "&");
				str = str.replace(new RegExp(percent_chars['"'],'g'), '"');
				str = str.replace(new RegExp(percent_chars["'"],'g'), "'");
				str = str.replace(new RegExp(percent_chars["?"],'g'), "?");
				str = str.replace(new RegExp(percent_chars[" "],'g'), " ");
		
		
				str = str.replace(new RegExp(amp_chars["&"],'g'), "&");
				str = str.replace(new RegExp(amp_chars['"'],'g'), '"');
				str = str.replace(new RegExp(amp_chars["'"],'g'), "'");
				str = str.replace(new RegExp(amp_chars["?"],'g'), "?");
		
		
				str = str.replace(new RegExp("%26nbsp;",'g'), "");
				str = str.replace(new RegExp("&nbsp;",'g'), "");
		

			break;
			}//switch
			//=======

	
		break;
		case'encode':


			switch(type)
			{
			case'percent':
	
				str = str.replace(new RegExp('"','g'), percent_chars['"']);
				str = str.replace(new RegExp("'",'g'), percent_chars["'"]);
				str = str.replace(new RegExp("&",'g'), percent_chars["&"]);
				str = str.replace(/\?/g, percent_chars["?"]);
	
			break;
			case'amp':

				str = str.replace(new RegExp("&",'g'), amp_chars["&"]);
				str = str.replace(new RegExp('"','g'), amp_chars['"']);
				str = str.replace(new RegExp("'",'g'), amp_chars["'"]);
				str = str.replace(/\?/g, amp_chars["?"]);
	
			break;
			default:


				str = str.replace(new RegExp("&",'g'), percent_chars["&"]);
				str = str.replace(new RegExp('"','g'), percent_chars['"']);
				str = str.replace(new RegExp("'",'g'), percent_chars["'"]);
				str = str.replace(/\?/g, percent_chars["?"]);
		
		
				str = str.replace(new RegExp("&",'g'), amp_chars["&"]);
				str = str.replace(new RegExp('"','g'), amp_chars['"']);
				str = str.replace(new RegExp("'",'g'), amp_chars["'"]);
				str = str.replace(/\?/g, amp_chars["?"]);


			break;
			}//switch
			//=======


		break;
		default:
		}//switch
		//#######
	
	
		return str;
	
	
	},






}




export default LibString;




/*



        
        //-----

        targetVal = targetVal.toString();
        if(targetVal.length === parseInt(maxlength)-1)
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-2;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }
        else
        if(targetVal.length > maxlength 
        || targetVal.length === maxlength) 
        {
            if(!targetVal.match(/\./gi)) maxlength = maxlength-1;
            diff = targetVal.length - maxlength;
            targetVal = targetVal.substr(0,targetVal.length-diff);
        }

        //------
        


*/