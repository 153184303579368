

/*
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
*/


const AddItems = (props) => {


    var info = props.info;
    var items = props.items;
    var loopnum = props.loopnum;

    items = items.concat(items);
    items = items.concat(items);

    if(items.length < info.cells.total)
    {
        items = items.concat(items);
        items = items.concat(items);
    }
    //items.reverse();
    //items = shuffleArray(items);


    var array = [];
    var i;
    for(i=0; i < loopnum; i++)
    {
        if(!items[i] || items[i] === undefined) 
        {
            array.push(items[0])
        }else{
            array.push(items[i]);
        }

    }
    return array;

}





const ExtraItems = (props) => {

    let info = props.info;
    let items = props.items;
    let totalCells = info.cells.total;

    let loopnum;
    let array;

    if(items.length < totalCells)
    {
        // add to
        loopnum = totalCells - items.length;
        array = AddItems({
            info    :info,
            items   :items,
            loopnum :loopnum
        });
        //console.log(JSON.stringify(array,null,2));
        items = items.concat(array);

    }

    if(items.length > totalCells)
    {
        loopnum = totalCells;
        array = AddItems({

            info    :info,
            items   :items,
            loopnum :loopnum
        });
        //console.log(JSON.stringify(array,null,2));
        items = array;

    }

    //console.log("EXTRA ITEMS: "+JSON.stringify(items,null,2));

    return items;

}//==

export default ExtraItems;






/*

  
        
        if(items.length % across !== 0)
        {
            //alert('no');

            array = [];
            for(i=0; i < across; i++)
            {
                array.push(items[i]);
            }
            console.log(JSON.stringify(array,null,2));
            items = items.concat(array);

        }
        


        if(items.length < totalCells)
        {
            loopnum = totalCells - items.length;

            array = [];
            for(i=0; i < loopnum; i++)
            {
                if(!items[i] || items[i] === undefined) 
                    array.push(items[0])
                else 
                    array.push(items[i]);

            }
            //console.log(JSON.stringify(array,null,2));
            items = items.concat(array);

        }

        if(items.length > totalCells)
        {
            array = [];
            for(i=0; i < totalCells; i++)
            {
                if(!items[i] || items[i] === undefined) 
                    array.push(items[0])
                else 
                    array.push(items[i]);

            }
            //console.log(JSON.stringify(array,null,2));
            items = array;

        }

        


 */
