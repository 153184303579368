var Data = [


    {
        call:'button',
        data:{
            "name": "Home",
            "to": "http://site.site/",
            "descr": "This is the Home"
        }

    },

    {

        call:'button',
        data:{
            "name": "Trail Page SVG",
            "to": "http://site.site/TrialPageSVG",
            "descr": "This is the Trial Page SVG"
        }

    },

    {

        call:'button',
        data:{
            "name": "Trail Page Slideshow",
            "to": "http://site.site/TrialPageSlideshow",
            "descr": "This is the Trial Page Slideshow"
        }

    },







    //=================================================
    {

        call:'title',
        data:{

            name:"Trial Pages"

        }

    },
    {

        call:'button',
        data:{
            "name": "Trail Page",
            "to": "TrialPage",
            "descr": "This is the Trial Page"
        }

    },
    {

        call:'button',
        data:{

            "name": "Trail Page - Row 1",
            "to": "TrialPage#row1",
            "descr": "This is the Trial Page - row1"
        }

    },
    {

        call:'button',
        data:{

            "name": "Trail Page - Row 2",
            "to": "TrialPage#row2",
            "descr": "This is the Trial Page - row2"
        }

    },
    {

        call:'button',
        data:{

            "name": "Trail Page - Row 3",
            "to": "TrialPage#row3",
            "descr": "This is the Trial Page - row3"
        }

    },
    {

        call:'button',
        data:{

            "name": "Trail Page - Row 4",
            "to": "TrialPage#row4",
            "descr": "This is the Trial Page - row4"
        }

    },





    //=================================================
    {

        call:'title',
        data:{

            name:"Landing Page"

        }

    },
    {

        call:'button',
        data:{
            "name": "Landing Page",
            "to": "TrialPageLandingPage",
            "descr": "This is the Trial Page"
        }

    },
    {

        call:'button',
        data:{

            "name": "Landing Page - Row 1",
            "to": "TrialPageLandingPage#row1",
            "descr": "This is the Trial Page - row1"
        }

    },
    {

        call:'button',
        data:{

            "name": "Landing Page - Row 2",
            "to": "TrialPageLandingPage#row2",
            "descr": "This is the Trial Page - row2"
        }

    },
    {

        call:'button',
        data:{

            "name": "Landing Page - Row 3",
            "to": "TrialPageLandingPage#row3",
            "descr": "This is the Trial Page - row3"
        }

    },
    {

        call:'button',
        data:{

            "name": "Landing Page - Row 4",
            "to": "TrialPageLandingPage#row4",
            "descr": "This is the Trial Page - row4"
        }

    },





    

    //===================================================
    {

        call:'title',
        data:{

            name:"External Links"
        }

    },
    {
        call:'button',
        data:{
            "name": "Power Digital Media",
            "to": "http://powerdigitalmedia.net/",
            "descr": "Power Digital Media"
        }

    },
    {

        call:'button',
        data:{
            "name": "Impelos",
            "to": "//impelos.com/",
            "descr": "Impelos"
        }

    },






]


export default Data;




/*


http://site.site/Billboards






    {
        "name": "Home",
        "to": "",
        "descr": "This link will go to Home"
    },
    {
        "name": "MyBillboard",
        "to": "MyBillboard",
        "descr": "This link will go to MyBillboard"
    },
    {
        "name": "Text 1",
        "to": "Text1",
        "descr": "This link will go to Text1"
    },
    {
        "name": "Billboards",
        "to": "Billboards",
        "descr": "This link will go to Billboards"
    },
    {
        "name": "Video 1",
        "to": "Video1",
        "descr": "This link will go to Video1"
    },
    {
        "name": "FilmStrip 1",
        "to": "FilmStrip1",
        "descr": "This link will go to FilmStrip1"
    },
    {
        "name": "Collage 1",
        "to": "Collage1",
        "descr": "This link will go to Collage1"
    },
    {
        "name": "All Text 1",
        "to": "AllText1",
        "descr": "This link will go to AllText1"
    },
    {
        "name": "Image Items",
        "to": "ImageItems",
        "descr": "This link will go to ImageItems"
    },
    {
        "name": "Image Gallery",
        "to": "ImageGallery",
        "descr": "This link will go to ImageGallery"
    }















*/