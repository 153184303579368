import styled from "styled-components";



//#####################################################
//GUTTER GRID
//#####################################################

const GutterGrid = styled.div.attrs(props => ({ 
    
    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "0",
    width:              props.width || "85%",
    height:             props.height || "auto",
    padding:            props.padding || "10px 0 100px 0",
    margin:             props.margin || "auto",
    justifyContent:     props.justifyContent || "center"

}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    display: flex;
    flex-wrap: wrap;
    justify-content:${props => props.justifyContent};



    @media (max-width: 1450px) {


        width: 100%;


    }


`;


    const GutterCel = styled.div.attrs(props => ({ 
        
        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "1px solid #ccc",
        borderRadius:       props.borderRadius || "10px",
        height:             props.height || "auto",
        margin:             props.margin || "10px",


        flex4:              props.flex4 || "0 1 350px",//24.75%
        flex3:              props.flex3 || "0 1 350px",//32.75%
        flex2:              props.flex2 || "0 1 40%",//49.5%
        flex1:              props.flex1 || "0 1 85%"//100%

    }))`


        background-color: ${props => props.backgroundColor}; 
        border: ${props => props.border}; 
        border-radius: ${props => props.borderRadius};  
        height: ${props => props.height}; 
        flex: ${props => props.flex4};    
        margin: ${props => props.margin}; 
        overflow:hidden;


        @media (max-width: 1150px) {

            background-color: white; 
            flex: ${props => props.flex2}; 
        }
        @media (max-width: 800px) {
        
            background-color: white;
            flex: ${props => props.flex1}; 

        }

        &:hover {

            border:1px solid #0291cd;

        }

    `;



        const GutterCelCenter = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "100%",
            height:             props.height || "100%",
            position:           props.position || "relative",  
            float:              props.float || "left"

        }))`

            background-color: ${props => props.backgroundColor}; 
            width: ${props => props.width}; 
            height: ${props => props.height};  
            position: ${props => props.position}; 
            float: ${props => props.float};    
    
        `;


        const GutterCelGraphic = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "lightgray",
            width:              props.width || "80px",
            height:             props.height || "80px",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "30px auto",
            position:           props.position || "relative",  
            float:              props.float || "left"

        }))`

            background-color:${props => props.backgroundColor};
            border:1px solid #1A1926;

            width   :${props => props.width};
            height  :${props => props.height};
        
            padding :0 0 0 0;
            margin  :${props => props.margin};
        
            overflow:visible;
        
         
    
    
        `;




        const GutterCelTitle = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",

        }))`

            background-color:${props => props.backgroundColor};
            width:96%;
            height:auto;
            padding:20px 0 18px 0;
            margin:0 0 0 15px;

            position:relative;
            float:left;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:22px;
            font-weight:normal;
            color:#000;
            text-align:left;
        
        `;



        const GutterCelName = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "90%",
            height:             props.height || "auto",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 5%",
            position:           props.position || "relative",  
            float:              props.float || "left",
            textAlign:          props.textAlign || "center"

        
        }))`

            background-color:${props => props.backgroundColor};

            width   :${props => props.width};
            height  :${props => props.height};
    
            padding:4px 0 4px 0;
            margin  :${props => props.margin};
    
            position:relative;
            float:left;
            overflow:hidden;
    
            font-family:Arial, Helvetica, sans-serif;
            font-size:22px;
            font-weight:bold;
            color:#000;
            text-align:${props => props.textAlign};
        
        `;





        const GutterCelDescr = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "90%",
            height:             props.height || "auto",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 5%",
            position:           props.position || "relative",  
            float:              props.float || "left",
            textAlign:          props.textAlign || "center"

        
        }))`

            background-color:${props => props.backgroundColor};
        
            width   :${props => props.width};
            height  :${props => props.height};
    
            padding:4px 0 4px 0;
            margin  :${props => props.margin};
    
            position:relative;
            float:left;
            overflow:hidden;
    
            font-family:Arial, Helvetica, sans-serif;
            font-size:18px;
            font-weight:normal;
            color:#000;
            text-align:${props => props.textAlign};
        
        `;





export {

    GutterGrid,  
    GutterCel,
    GutterCelCenter,
    GutterCelGraphic,
    GutterCelTitle,
    GutterCelName,
    GutterCelDescr


}
