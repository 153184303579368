export const BUTTON_LOGO_BENSON_AND_HEDGES = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    
    {/*Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M125.84,190.331L54,52.021l321.031,83.72c-4.88-5.492-9.731-11.008-14.383-16.696 l35.927,6.135c2.676,0.674,4.89,2.645,6.629,4.811c32.2,5.671,63.739,13.718,89.164,35.646 c69.396,10.211,122.961,70.247,122.961,142.401c0,35.008-11.825,68.528-34.844,95.223L666,421.128l-119.54,10.235
		c-22.658,13.747-48.497,20.616-75.073,20.616c-76.64,0-143.941-64.071-143.941-143.942c0-25.982,6.945-50.396,19.074-71.477
		C318.058,248.986,189.043,312.019,125.84,190.331L125.84,190.331z M426.036,171.443c11.503-3.836,23.646-6.251,36.217-7.047
		c-13.655-10.907-44.626-15.761-60.126-18.49c-0.102,0.099-0.205,0.195-0.31,0.291C411.842,152.848,420.974,162.344,426.036,171.443
		L426.036,171.443z"
    />
    {/*Yellow*/}
    <path 
        fill="rgb(255,223,28)" 
        d="M495.029,308.855l-0.303,0.421 c-10.74-7.842-21.154-22.863-28.912-33.623c-1.025-1.423-2.804-4.189-1.321-5.969s3.765,0.054,5.167,1.13
		c10.856,8.328,22.568,18.387,30.219,29.88l-0.233,0.247c-1.654-1.928-5.994-6.252-8.773-3.483 C487.555,300.766,492.959,306.486,495.029,308.855L495.029,308.855z M479.766,320.858c-1.887-2.153-5.672-7.707-2.746-10.124	c2.928-2.418,6.38,1.116,8.102,2.99l0.422-0.217c-6.018-11.607-20.252-22.357-30.28-30.13c-1.117-0.866-3.733-3.047-5.163-1.513	s-0.363,4.041,0.811,5.695c7.282,10.271,18.223,26.323,28.494,33.59L479.766,320.858L479.766,320.858z M464.604,332.252	c-1.717-2.037-4.324-6.703-1.781-8.891c2.543-2.188,4.708,0.249,6.74,1.431l0.528-0.39c-6.901-10.943-22.321-24.035-31.698-31.078 c-1.145-0.859-3.138-1.518-4.266-0.308c-1.127,1.21,0.272,3.95,0.977,4.966c8.226,11.865,18.014,24.898,28.892,34.453
		L464.604,332.252L464.604,332.252z M538.255,328.598c2.545,3.883,8.475,8.615,13.736,9.363	c-4.743-7.398-10.569-17.949-10.774-27.016c3.174-2.513,7.334-4.838,10.978-6.601c-0.114-1.325-0.028-3.228-0.042-4.317	c-8.17,0.751-19.466,1.973-27.775,2.012c0.049,17.825,4.746,35.695,17.969,48.221C539.209,340.655,537.954,331.838,538.255,328.598 L538.255,328.598z M380.576,286.679c10.543,9.42,27.001,13.444,40.955,11.434l-7.239-23.131l27.039,11.952l-8.547-27.678	l26.272,15.748l-7.506-27.591l22.342,11.412c0.185-6.068-0.672-12.093-1.34-18.12c-1.642-14.808-16.031-36.635-34.582-38.572 c3.846,6.316,8.344,16.404,9.195,23.313c-8.552-4.742-25.273-11.015-41.818-8.484c10.188,6.129,26.562,18.419,28.842,24.52
		c-16.487-7.767-34.978-14.955-47.294-15.617C372.912,239.512,363.561,271.477,380.576,286.679L380.576,286.679z M479.568,206.34
		c-8.759,36.884,10.266,71.978,40.148,92.769l0.637-1.454c11.761-0.006,23.449-0.771,35.073-2.611 c0.565,3.143,0.626,9.514,0.626,11.137c-1.033,1.35-2.409,2.398-3.986,3.034c1.962,1.568,11.751,8.878,13.825,10.78l-8.778,9.572 c5.027,7.39,12.984,13.82,21.404,16.712c0.615,0.212,1.608,0.063,1.964-0.481c5.26-11.342,5.904-26.449,7.147-38.178	c-6.545,0.713-14.145,0.142-19.378-4.278c5.918-0.757,17.167-2.281,18.896-9.34c0.914-3.753,0.253-7.728-0.654-11.511 c-6.962,9.131-23.002,8.222-32.834,5.707c-23.9-6.115-51.15-20.15-63.642-42.543c4.975,3.189,19.868,9.225,27.236,10.837
		c-12.388-10.063-23.584-19.206-28.688-35.251c4.702,3.372,17.539,10.449,25.863,9.924c-9.457-8.624-17.707-19.957-18.915-33.05 c2.938,2.296,15.878,11.068,30.04,16.698c4.915-4.307,11.941-7.568,21.459-7.534c-7.913-6.261-17.257-10.419-27.173-12.291 C511.614,193.434,492.049,191.711,479.568,206.34L479.568,206.34z M422.193,321.662c-4.48,6.477-33.559,20.486-40.752,23.245 c1.715,2.065,10.614,3.056,13.395,3.532c-5.134,4.627-27.792,12.043-32.894,13.67c3.669,8.145,8.306,15.813,13.805,22.853 c25.063-10.902,49.411-25.498,70.371-43.022c-5.042,14.279-17.011,28.926-27.372,39.477c5.141,0.211,10.28-1.702,13.656-2.969 c-3.957,10.334-22.18,25.493-29.907,31.771l-0.687,0.557c7.172,5.481,15.17,9.612,23.697,12.288
		c13.533-8.818,38.027-46.771,45.361-61.204c-0.701,15.422-7.36,35.643-9.887,42.14l11.276-7.042 c-1.122,5.269-9.084,23.488-14.571,33.008c8.377,1.486,18.367,2.587,26.859,2.439c7.763-14.02,13.625-29.01,17.438-44.575 c3.758,6.799,1.395,22.834,0.625,30.214c2.565-1.399,3.855-4.741,5.898-6.827c1.035,6.463-0.736,13.082-1.844,19.428	c5.117,0.063,10.578-1.108,15.473-2.641l5.428-29.723c-27.557-16.933-51.666-36.599-73.31-60.415
		c-17.745-19.527-41.477-25.627-65.319-33.165c-18.074-5.715-30.594-21.885-30.854-40.961l-0.153-0.219 c-12.301,18.199-12.594,44.131-7.859,64.764C372.838,329.842,397.838,327.974,422.193,321.662L422.193,321.662z"
    />
    {/*Red*/}
    <path 
        fill="rgb(220,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M533.231,403.11l78.144,14.626l-81.549,4.713L533.231,403.11 L533.231,403.11z M566.201,283.412c2.414,0.762,4.94,1.022,7.465,1.183c4.172,0.265,8.344-1.813,9.803-5.724 c1.092-2.931,0.786-6.149,0.727-9.278c-0.316-16.52-5.484-32.665-15.82-45.565c-4.283-5.342-10.029-9.423-16.55-11.495 c-6.89-2.188-14.267-0.499-20.41,3.306c-4.805,2.975-8.999,7.104-10.53,12.545c-1.985,7.037-0.399,14.559,3.489,20.754 c5.605,8.933,12.597,16.938,20.939,23.393C551.567,277.371,558.658,281.027,566.201,283.412L566.201,283.412z M569.665,280.075	c-16.767-2.2-33.51-19.146-41.511-31.794c-5.354-8.463-6.918-22.906,4.983-28.106C531.95,245.363,550.001,266.533,569.665,280.075 L569.665,280.075z"
    />
    {/*White*/}
    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M479.134,345.551c-4.52-4.614-9.396-11.455-13.027-17.348 c-0.193-0.313-0.361-0.899-0.046-1.104c0.314-0.205,0.771,0.195,1.063,0.445c5.507,4.745,10.108,9.727,14.64,15.411 c0.79,0.991,1.594,2.27,0.563,3.288C481.297,347.263,480.145,346.583,479.134,345.551L479.134,345.551z M570.945,235.83 c-3.743-10.903-14.053-20.535-25.803-21.212C548.354,215.987,563.383,225.558,570.945,235.83L570.945,235.83z M493.712,333.355
		c1.012,1.033,2.163,1.713,3.193,0.693c1.029-1.018,0.226-2.296-0.563-3.287c-4.531-5.686-9.134-10.666-14.641-15.411 c-0.29-0.251-0.747-0.649-1.063-0.445c-0.314,0.204-0.147,0.79,0.046,1.104C484.316,321.901,489.193,328.742,493.712,333.355 L493.712,333.355z M507.145,318.4c0.972,0.95,2.069,1.566,3.016,0.587c0.948-0.979,0.166-2.164-0.597-3.079 c-4.376-5.254-8.806-9.844-14.081-14.193c-0.278-0.229-0.716-0.596-1.008-0.396c-0.292,0.198-0.123,0.746,0.065,1.037	C498.075,307.822,502.801,314.154,507.145,318.4L507.145,318.4z M186.275,120.658l-6.589,7.695l-74.435-18.147l10.656,19.746 l51.584,12.577l-6.82,7.929l-38.804-9.461l10.475,19.412l16.342,3.984l-7.026,8.169l-3.175-0.773l2.752,5.1 c2.761,4.873,5.646,9.539,8.676,13.98l105.785,25.79l5.84,11.239L158.95,202.887c16.079,19.298,36.147,32.926,63.124,38.754 c36.997,6.16,83.487-3.009,118.265-26.23c32.546-21.729,53.722-20.861,82.254-12.256c-9.92-22.845-26.09-45.887-51.492-52.079 L90.372,82.633l9.1,16.863L186.275,120.658L186.275,120.658z M327.528,155.097l-18.808,21.864l17.515,4.271l18.808-21.864 l23.724,5.784l-44.643,51.897l-23.723-5.783l19.015-22.104l-17.516-4.271l-19.015,22.104l-22.948-5.595l44.642-51.898 L327.528,155.097L327.528,155.097z M185.904,183.352l-39.404-9.607l44.642-51.898l31.715,7.732
		c6.867,1.673,20.159,5.883,18.054,15.436c-1.965,8.9-14.562,12.599-22.375,12.593c-0.46-0.001-0.883,0.239-1.165,0.603l0.123,0.541 c3.764,2.254,7.973,6.821,6.656,11.404C220.278,183.559,196.95,186.044,185.904,183.352L185.904,183.352z M259.414,170.158 c0.295,2.021,2.021,2.25,3.409,1.387l-1.696-1.428c1.103-1.015,2.034-1.833,3.441-3.069l11.684,2.849l-3.053,3.549l-2.775-0.677 c-2.264,1.976-4.873,3.462-7.698,4.483c-0.748,0.27-2.291,1.476-0.254,1.975l3.697,0.906l-1.007,4.133 c-0.453,0.264-1.048,0.459-1.557,0.334l-6.434-1.568c-1.463-0.356-2.773-1.582-3.549-2.871c-5.82,0.304-21.408,0.145-19.403-9.497 c1.132-5.47,9.796-6.916,14.342-6.886c0.528-11.811,17.313-8.607,23.037-2.904l-3.539,3.108c-1.749-1.552-10.337-5.426-9.434,0.767 C258.964,166.616,259.153,168.373,259.414,170.158L259.414,170.158z M194.021,171.535c-4.527,1.456-9.488,1.599-14.098,0.475 l-3.252-0.792l11.988-13.937l9.573,2.334c2.344,0.572,4.253,2.178,3.626,4.291C200.768,167.572,197.671,170.358,194.021,171.535
		L194.021,171.535z M210.805,148.33c-3.469,1.618-6.603,1.822-10.971,0.758l-3.412-0.832l10.301-11.976l2.933,0.715 c2.095,0.511,8.246,1.859,7.009,4.995C215.576,144.759,213.501,147.067,210.805,148.33L210.805,148.33z M250.452,175.184	c-2.139,0.724-6.404-0.835-5.715-3.525c0.522-2.029,2.656-3.209,4.75-3.091L250.452,175.184L250.452,175.184z M294.98,153.557 l-49.508-12.07c-0.325-2.732-1.588-5.185-3.402-7.225l57.458,14.008L294.98,153.557L294.98,153.557z M254.885,200.168	l-50.133-12.222c3.799-0.651,7.53-1.683,11.04-3.289l43.346,10.567L254.885,200.168L254.885,200.168z M398.525,139.138 c45.19,7.959,82.068,16.335,104.957,53.618l3.246-0.625c-25.076-41.632-70.664-49.699-107.895-56.899 c-0.844-1.402-2.191-2.973-4.027-3.343c-2.062-0.449-12.25-2.003-16.784-3.069c2.591,3.62,9.559,13.323,14.3,13.981 C394.96,143.162,397.481,141.59,398.525,139.138L398.525,139.138z"
    />
    
    

    

		</svg>
		</div>

	);//return
}