//import styled from "styled-components";
import styled from "styled-components/macro";


export const ArrowButton = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",// #fcfcfc  -  #07a2e3

    borderTop:          props.borderTop || "0",
    borderRight:        props.borderRight || "0",
    borderBottom:       props.borderBottom || "0",
    borderLeft:         props.borderLeft || "0",

    borderRadius:       props.borderRadius || "2px",

    width:              props.width || "35px", 
    height:             props.height || "100%",

    margin:             props.margin || "0 0 0 0", 
    padding:            props.padding || "0",

    position:           props.position || "relative", 
    float:              props.float || "left",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",



    alfaPercent:         props.alfaPercent || '0',
    alfaDecimal:         props.alfaDecimal || "0.0",






}))`
  

background-color:${props => props.backgroundColor};

border-top:${props => props.borderTop};
border-right:${props => props.borderRight};
border-bottom:${props => props.borderBottom};
border-left:${props => props.borderLeft};

border-radius:${props => props.borderRadius};

width:${props => props.width};
height:${props => props.height};

margin:${props => props.margin};
padding:${props => props.padding};

position:${props => props.position};
float:${props => props.float};

overflow:hidden;

font-family:${props => props.fontFamily};     
font-size: ${props => props.fontSize};
font-weight: ${props => props.fontWeight};
text-align: ${props => props.textAlign};
text-decoration:${props => props.textDecoration};
color:${props => props.color};



.arrowbox-alfa{
            
    background-color:black;

    width:100%;
    height:100%;
    padding:0 0 0 0;
    margin:0 0 0 0;

    position:relative;   
    float:left;

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.alfaPercent})";
    filter : alpha(opacity=${props => props.alfaPercent});
    -moz-opacity : ${props => props.alfaDecimal};
    -khtml-opacity : ${props => props.alfaDecimal};
    opacity : ${props => props.alfaDecimal};

}

.arrowbox-bg{
            
    background-color:transparent;
 
    width:100%;
    height:100%;
    padding:0 0 0 0;
    margin:0 0 0 0;

    position:absolute;   
    z-index:1;
    top:0;
    left:0;


}



.arrowbox-mask{
            
    background-color:transparent;
    width:100%;
    height:100%;
    padding:0 0 0 0;
    margin:0 0 0 0;

    position:absolute;   
    z-index:5;
    top:0;
    left:0;

}



.arrowbox-base{
            
    background-color:transparent;
    width:35px;
    height:24px;
    padding:0 0 0 0;
    margin:0 0 0 0;

    position:absolute;   
    z-index:3;
    top:calc(50% - 10px);
    left:0px;

}

.arrowbox{
            
    background-color:yellow;
    width:35px;
    height:24px;
    padding:0 0 0 0;
    margin:7px 0 0 14px;

    position:relative;   
    float:left;


}



    .arrow-left {
    
        width: 0; 
        height: 0; 
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent; 
        
        border-right:12px solid black;
    
    
        float:left;
        margin:0 0 0 8px;
        padding:0 0 0 0;	
    
    }

    .arrow-right {

        width: 0; 
        height: 0; 
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        
        border-left: 12px solid black;
    
    
        float:right;
        margin:0 8px 0 0;
        padding:0 0 0 0;	
    
    }
      



    .arrow-up {

    
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        
        border-bottom: 10px solid darkred;


        float:left;
        margin: 0 0 0 0;
        padding:0 0 0 0;

    }
  

    .arrow-down {

        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        
        border-top: 10px solid darkred;

        float:left;
        margin: 0 0 0 0;
        padding:0 0 0 0;	

    }
  




`;




