import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


import '../../StyleSheets/dynamic-form.css';



//https://stackoverflow.com/questions/21733847/react-jsx-selecting-selected-on-selected-select-option


export const FFlabelfield = (props) => {


    const [fieldVal, setFieldVal] = useState('');
    const [fieldStyl, setFieldStyl] = useState({});

    useEffect(() => {

        var fontStyle = 'normal';
        var fontColor = "#000";

        let data = props.data;

        for(var keyname in data)
        {
            switch(keyname)
            {
            case'label':
            break;
            case'field':

                setFieldVal(data[keyname].text);

                if(data[keyname].fieldtype === 'select')
                {
                    if(data[keyname].hint === data[keyname].text 
                    || data[keyname].text === ''
                    )
                    {
                        fontStyle = 'italic';
                        fontColor = '#777';
                    }

                }//==

            break;
            default:
            break;
            }//==

        }


        setFieldStyl({
            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor
        });


        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.ij === problemArr[i]['key'])
            {
                setFieldStyl({
                    "bgColor":"lightyellow",
                    "fontStyle":fontStyle,
                    "fontColor":fontColor
                });
                break;
            }
    
        }//== i
 
    
 
     },[props])
 
 
  

    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     

      

    // -- handle -- //

    function handleFocus(target) {

        target.select();
    }


    
    function handleBlur(e) {


        //console.log("TARGET VALUE: ", e.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);

        var newVal;
        newVal = e.target.value;
    
        switch(e.fieldtype)
        {
        case'price':


            if(isNaN(newVal) || newVal === '') newVal = 0;
            newVal = parseFloat(newVal);
            newVal = newVal.toFixed(2);

            setFieldVal(newVal);

            props.handle({

                call        :'capture',
                fieldCall   :"labelfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        break;
        case'percent':

            if(isNaN(newVal) || newVal === '') newVal = 0;
            newVal = parseFloat(newVal);
            newVal = newVal.toFixed(1);

            setFieldVal(newVal);

            props.handle({

                call        :'capture',
                fieldCall   :"labelfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        break;
        default:
        }
        

    }
    



    function handleChange(e) {

        console.log("TARGET VALUE: ", e.target.value);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.field.hint);
        console.log("IJ: "+props.ij+" \nINDX: "+e.indx);



        var newStyl;
        var newVal;
        var newArr;
        var i;
    

        /*
        if(e.target.type === 'select')
        {

            newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);

        }

        setFieldVal(e.target.value);

        */





        /*
        if(props.problemArr.length > 0)
        {
            props.handle({

                call        :'clear',
                fieldCall   :"labelfield",
                key         :props.ij,
                id          :props.data.id,
                data        :props.data,
                newValue    :newVal
            
            });

        }
        */


        if(props.problemArr.length > 0)
        {
            if(props.ij !== undefined)
            {
                newArr = [];
                for(i=0; i < props.problemArr.length; i++)
                {

                    //console.log(props.problemArr[i]['key'] +" "+ e.indx);
                    if(props.problemArr[i]['key'] !== props.ij)
                    {
                        newArr.push(props.problemArr[i]);
                    }
                }

                console.log(JSON.stringify(newArr,null,2));
                //alert('new')
                props.handle({

                    call:"resetProblemArr",
                    errors:newArr

                });

            }
        }
        





        newVal = e.target.value;
        
        switch(e.fieldtype)
        {
        case'select':

            newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);

        break;
        case'price':

            if(isNaN(newVal) || newVal === '') newVal = 0;
        break;
        case'percent':

            if(isNaN(newVal) || newVal === '') newVal = 0;
        break;
        default:
        }

        setFieldVal(newVal);

        props.handle({

            call        :'capture',
            fieldCall   :"labelfield",
            key         :props.ij,
            id          :props.data.id,
            data        :props.data,
            newValue    :newVal
        
        })


    }






    // -- display -- //

    return (


        <div className="frm">


            {
            //------------------------------------
            //HIDDEN
            //------------------------------------

            props.data.field.fieldtype === "hidden" 
            ? 


                <div 
                key={props.ij}
                >

                    <input
                        type="hidden"
                        id={props.data.id}
                        value={fieldVal}          
                        placeholder={props.data.hint}
                    />

                </div>



            : null


            //-------------------------------------
            }




            {
            //------------------------------------
            //TEXT FIELD
            //------------------------------------

            props.data.field.fieldtype === "text" 
            ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >

                        <input
                            type="text"
                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}

                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}  
                            */
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}


                        />

                   
                    </div>

                </div>



            : null


            //-------------------------------------
            }





            {
            //------------------------------------
            //TEXTAREA
            //------------------------------------

            props.data.field.fieldtype === "textarea" 
            ? 
 
                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    <div 
                    className="label-ta"
                    >

                        {props.data.label.text}

                    </div>



                    <div 
                    className="field-wrap"
                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor

                        }}

                        id={props.data.id}
                        placeholder={props.data.hint}
                        value={fieldVal}


                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}

                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }} 
                            */ 
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}

                        >
                        </textarea>

                    </div>
                    
                </div>
            
            : null


            //-------------------------------------
            }







            {
            //------------------------------------
            //SELECT
            //------------------------------------

            props.data.field.fieldtype === "select" 
            ? 
 
                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >

                        <select

                        className="sel-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.field.id}
                        placeholder={props.data.field.hint}
                        value={fieldVal}
                        onChange={event => {

                            handleChange({

                                target      :event.target,
                                fieldtype   :props.data.field.fieldtype

                            })

                        }}
                        
                
                        /*
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        
                        onBlur={event => {

                            handleBlur({

                                target      :event.target,
                                fieldtype   :props.data.field.fieldtype

                            })

                        }}
                        */
                        
        
                        >

                                {
                                //====================================			
                                //first option
                                //====================================
                                }

                                <option 
                                className="opt"       
                                key={props.data.field.hint} 
                                value={props.data.field.hint}
                                >

                                    {props.data.field.hint}

                                </option>
                            

                
                                {
                                //====================================			
                                //options
                                //====================================


                                props.data.field.array.map((option) => (

                                    <option 
                                    className="opt"                     
                                    key={option} 
                                    value={option}

                                    >

                                        {option}

                                    </option>
                                ))

                                }


                        </select>


                    </div>

                </div>
            
            : null


            //-------------------------------------
            }









            {
            //------------------------------------
            //PRICE FIELD
            //------------------------------------

            props.data.field.fieldtype === "price" 
            ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >


                        <div
                        className="symbol"
                        >
                            $
                        </div>


                        <input
                            type="text"
                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus={event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}  
    
                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}


                        />

                   
                    </div>

                </div>



            : null


            //-------------------------------------
            }







            {
            //------------------------------------
            //PERCENT FIELD
            //------------------------------------

            props.data.field.fieldtype === "percent" 
            ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >


                        <div
                        className="symbol"
                        >
                            %
                        </div>




                        <input
                            type="text"
                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus={event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
         
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}

                            onChange={event => {

                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}


                        />

                   
                    </div>

                </div>



            : null


            //-------------------------------------
            }






            {
            //------------------------------------
            //STANDARD FIELD
            //------------------------------------

            props.data.field.fieldtype === "standard" 
            ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >

                        <input
                            type="text"
                            className="symbol-input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus={event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            /*
                            onBlur={event => {

                                handleBlur({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype

                                })

                            }}  
                            */
                            onChange={event => {
               
                                handleChange({

                                    target      :event.target,
                                    fieldtype   :props.data.field.fieldtype
    

                                });
                            }}


                        />

                   
                    </div>

                </div>



            : null


            //-------------------------------------
            }









        </div>

    )


}

