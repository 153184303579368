export const GLOVE_GRAY_WHITE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    
    {/*Glove Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M350.139,200.906l13.026-4.559l0.406-2.246l10.475-3.441 c2.145-0.703,3.572-3.045,5.403-4.123c1.024-0.566,1.818-1.252,2.991-1.285c0.721,0.002,1.396,0.155,2.103,0.26 c0.902,0.127,1.809,0.222,2.713,0.338c0.865,0.115,1.754,0.229,2.577,0.531c1.621,0.703,3.423,3.615,4.274,4.85 c0.473,0.736,0.792,1.944,0.783,2.823c-0.036,0.956-0.624,2.475-0.762,3.03c1.706,2.922-0.933,5.302-1.112,5.861 c0.039,0.315,0.041,0.637-0.023,0.958L350.139,200.906L350.139,200.906z"
    /> 
    {/*Glove White*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M374.843,190.968c0.843-0.367,2.558-1.475,3.206-2.047	c1.912-1.684,3.861-2.12,5.533-1.78c1.67,0.339,4.773,0.487,6.025,0.979c1.251,0.492,3.703,3.17,4.093,3.893 c0.39,0.722,0.651,1.508,0.57,2.232c-0.079,0.727-0.735,2.016-0.735,2.016c-0.32-2.6-2.349-3.161-6.958-5.289 c-1.038-0.48-1.271,0.279-0.396,0.793c0.874,0.516,4.529,2.919,5.725,3.922c1.195,1.004,1.818,1.482,1.98,2.535 c0.289,1.869-0.32,2.426-1.233,3.797c0,0-0.18-1.191-1.167-2.037s-3.438-1.911-4.295-2.374c-0.855-0.463-1.521-0.096-0.619,0.537	c0.899,0.632,3.754,2.241,4.381,2.748c0.629,0.508,1.488,1.882,1.492,2.345c0.002,0.278-0.031,0.469-0.068,0.624l-12.535-0.877 L374.843,190.968L374.843,190.968z M366.181,202.028l-2.009-4.84l0.255-2.113l2.931,7.035L366.181,202.028L366.181,202.028z"
    /> 
    {/*Glove Shadow*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M380.854,203.055l-5.01-11.877l0.926-0.517l5.263,12.476 L380.854,203.055L380.854,203.055z M391.239,189.278c1.094,0.983,2.209,2.267,2.461,2.733c0.39,0.722,0.651,1.508,0.57,2.232 c-0.079,0.727-0.735,2.016-0.735,2.016c-0.197-1.595-1.037-2.422-2.72-3.315C391.309,192.143,391.508,190.527,391.239,189.278 L391.239,189.278z M391.63,195.463c0.103,0.08,0.195,0.154,0.276,0.223c1.195,1.004,1.818,1.482,1.98,2.535 c0.289,1.869-0.32,2.426-1.233,3.797c0,0-0.18-1.191-1.167-2.037c-0.354-0.304-0.899-0.637-1.49-0.959	C390.709,198.25,391.784,196.584,391.63,195.463L391.63,195.463L391.63,195.463z M391.239,201.163 c0.574,0.617,1.203,1.678,1.206,2.073c0.002,0.278-0.031,0.469-0.068,0.624l-2.138-0.149l0,0 C390.727,202.963,391.25,202.076,391.239,201.163L391.239,201.163z"
    /> 
    




    

		</svg>
		</div>

	);//return
}