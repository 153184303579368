
import React, { 

    useState, 
    useEffect, 
    //useRef,
    //useMemo

} from 'react';


import 'App.css';

import HeaderA from './A';
import HeaderB from './B';
import HeaderC from './C';


const Index = (props) => {


    //.log("HEADER PROPS zoneObj:" + props.zoneObj);

    //const { width } = useWindowDimensions()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    // const location = useLocation();
    // const history = useHistory();

 
 
    // var pathname = location.pathname;

    // if(pathname.charAt(0) === '/') 
    //     pathname = pathname.substring(1,pathname.length);


    // if(pathname.charAt(pathname.length-1) === '/') 
    //     pathname = pathname.substring(0,pathname.length-1);


    //let zoneId;
    //zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
  
  


    // -- ref -- //
    //const refMain = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false)  

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }


    },[
    
        props,
        isLoaded

    ])

    


    // TM - this page renders the requested Header using "headerCall" variable





    return (
    
        <div>
        {
        props.databind.headerCall === 'A'
        ?
            <HeaderA 

                databind={props.databind} 
            
            />
        :
        props.databind.headerCall === 'B'
        ?
            <HeaderB 

                databind={props.databind} 
            
            />
        :
        props.databind.headerCall === 'C'
        ?

            <HeaderC 
            
                databind={props.databind} 
            
            />

        :
        null
        }
        </div>


    );

}

export default Index;



