const DefaultFetchResponse = {

    "name": "Home",
    "path": "/Users/tm/Work/PROJEX/reaxBuilds/reax1/src/app-components/Zone/Home/",
    "sceneName": "home",
    "sceneInfo": [
        {

            styl:{

                call:"indent",
                class:"IndentRow",
    
                marginTop:"50px",
                marginBottom:"0"
    
    
            },
            call:'TxtComponent',
            info:{
    
                call:'flex', //fixed, flex
                gridType: "TextBox", //Newspaper
                gridBgColor:'transparent',
                gridCall: "grid_cell",  //grid_cell or grid_column_cell
                cellCall: "default",  //container or default 
                modCall: 'fill_div',
                thumb: {
    
                    call    : "default", //flex_height or default
                    diff    : 0
    
                },    
                cellBorder: {
                    "boolean"       :true,
                    "thickness"     :1,//px
                    "color"         :"gray"  
                },
                gutter: {
                    "boolean"   :true,
                    "size"      :20
                },
                thresh: {
                    "refWidth"    :false,   //number or false = default screen width
                    "across1"     :501,
                    "across2"     :801,
                    "across3"     :1050
                }
    
    
    
            },
    
            items:[{
    
                heading : "Default Page",
                pgraph  : "This is a default page"
            }]


        }
    
    
    ],
    "sceneSettings": {
        "call": "settings",
        "data": {
            "duration": 8000,
            "fadetime": 4000
        },
        "multi": false
    }
}

export default DefaultFetchResponse;