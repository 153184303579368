
const zoneReducer = (state = {}, action) => {

    const newState = Object.assign({}, state);

    switch(action.type)
    {
    case'UPDATE_ZONE': 

        //alert(action.item.key);
        newState[action.item.key] = action.item.value;
    break;
    case'DELETE_ZONE':

        delete newState[action.item.key];
    break;
    default:
    break;
    }

    return newState;


}

export default zoneReducer;




