export const BUTTON_LOGO_AP2V3 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.239,190.002h-42.554l-4.258,10.676h4.258c4.229,0,7.233,0.816,9.013,2.449
		c1.808,1.634,2.712,3.733,2.712,6.358c0,2.566-0.904,4.667-2.712,6.271c-1.779,1.634-4.784,2.45-9.013,2.45h-23.566
		c-4.229,0-7.233-0.816-9.012-2.45c-1.809-1.604-2.713-3.732-2.713-6.357s0.934-4.754,2.829-6.388
		c1.866-1.662,4.987-2.45,9.304-2.333l27.533-68.512h-11.463c-4.199,0-7.232-0.816-9.012-2.45c-1.809-1.604-2.684-3.733-2.684-6.358
		s0.875-4.754,2.684-6.357c1.779-1.634,4.813-2.45,9.012-2.45l37.858,0.087l34.854,86.041c4.112,0,6.825,0.467,8.138,1.371
		c2.596,1.895,3.908,4.374,3.908,7.437c0,2.566-0.875,4.667-2.654,6.271c-1.75,1.634-4.754,2.45-8.954,2.45h-23.596
		c-4.2,0-7.204-0.816-9.013-2.45c-1.778-1.604-2.683-3.732-2.683-6.357c0-2.566,0.904-4.638,2.683-6.271
		c1.809-1.633,4.813-2.449,9.013-2.449h4.288L286.239,190.002L286.239,190.002z M278.977,172.503l-14.088-34.708l-14.203,34.708
		H278.977L278.977,172.503z M359.737,182.652v18.025h17.704c4.2,0,7.204,0.816,9.013,2.449c1.778,1.634,2.683,3.733,2.683,6.358
		c0,2.566-0.904,4.667-2.683,6.271c-1.809,1.634-4.813,2.45-9.013,2.45h-37.945c-4.229,0-7.232-0.816-9.012-2.45
		c-1.809-1.604-2.713-3.732-2.713-6.357c0-2.566,0.934-4.638,2.742-6.271c1.837-1.633,4.812-2.449,8.982-2.449h2.742v-68.512h-2.742
		c-4.229,0-7.232-0.816-9.012-2.45c-1.809-1.604-2.713-3.733-2.713-6.358s0.904-4.754,2.713-6.357
		c1.779-1.634,4.783-2.45,9.012-2.45l40.337,0.087c11.842,0,21.233,3.238,28.146,9.713c6.912,6.445,10.383,14.32,10.383,23.624
		c0,5.134-1.166,9.976-3.5,14.524c-1.779,3.413-4.725,6.797-8.837,10.121c-4.142,3.325-8.37,5.834-12.658,7.525
		c-4.316,1.662-9.975,2.508-17.062,2.508H359.737L359.737,182.652z M359.737,165.152h16.246c7.612,0,13.679-1.721,18.142-5.191
		c4.491-3.441,6.708-7.583,6.708-12.424c0-4.084-1.809-7.7-5.425-10.763c-3.617-3.063-8.779-4.608-15.487-4.608h-20.184V165.152
		L359.737,165.152z M458.523,200.678c17.5-14.846,29.254-25.171,35.291-30.916c7.699-7.292,12.541-13.009,14.583-17.15
		c2.013-4.112,3.033-8.604,3.033-13.445c0-9.508-3.528-17.616-10.616-24.296c-7.059-6.708-16.1-10.062-27.154-10.062
		c-7.116,0-13.707,1.545-19.774,4.607c-6.066,3.092-10.704,7.204-13.854,12.309c-3.15,5.133-4.754,9.42-4.754,12.833
		c0,2.274,0.874,4.259,2.624,5.979c1.722,1.691,3.792,2.566,6.184,2.566c2.042,0,3.792-0.525,5.162-1.546
		c1.4-1.021,2.509-2.741,3.297-5.134c1.312-3.82,3.063-6.679,5.221-8.633c4.141-3.646,9.361-5.454,15.633-5.454
		c6.212,0,11.17,1.634,14.903,4.899c3.733,3.297,5.601,7.059,5.601,11.346c0,3.413-1.634,7.029-4.959,10.85
		c-6.533,7.438-25.607,24.209-57.165,50.225v18.55h79.827v-8.37c0-4.288-0.787-7.292-2.362-9.07
		c-1.575-1.751-3.675-2.626-6.358-2.626c-1.896,0-3.879,0.846-5.979,2.538H458.523L458.523,200.678z M317.315,371.074l-25.199-60.49
		h4.083c4.229,0,7.204-0.816,8.983-2.449c1.75-1.604,2.654-3.734,2.654-6.359s-0.904-4.725-2.713-6.328
		c-1.779-1.575-4.754-2.393-8.925-2.393l-23.653-0.087c-4.229,0-7.233,0.817-9.013,2.45c-1.809,1.604-2.713,3.732-2.713,6.357
		c0,3.18,1.371,5.659,4.113,7.438c1.313,0.904,4.024,1.371,8.107,1.371l35.904,86.041h16.828l35.963-86.041
		c4.053,0,6.736-0.467,8.049-1.371c2.713-1.837,4.084-4.316,4.084-7.438c0-2.625-0.875-4.725-2.684-6.328
		c-1.809-1.575-4.813-2.393-9.012-2.393l-23.509-0.087c-4.2,0-7.204,0.817-9.013,2.45c-1.778,1.604-2.683,3.732-2.683,6.357
		s0.904,4.755,2.683,6.359c1.809,1.633,4.813,2.449,9.013,2.449h4.025L317.315,371.074L317.315,371.074z M447.367,338.088
		c3.879-3.296,6.796-6.912,8.721-10.791c1.926-3.909,2.917-8.108,2.917-12.629c0-8.634-3.296-16.042-9.829-22.196
		c-6.563-6.153-15.254-9.245-26.074-9.245c-11.434,0-21,2.712-28.612,8.137c-5.25,3.705-7.875,7.496-7.875,11.434
		c0,2.508,0.875,4.608,2.566,6.329c1.722,1.721,3.763,2.566,6.154,2.566c1.487,0,2.829-0.321,4.024-0.933
		c0.788-0.409,1.838-1.313,3.15-2.742c4.434-4.9,10.879-7.35,19.309-7.35c6.563,0,11.462,1.342,14.758,4.054
		c3.267,2.713,4.9,5.921,4.9,9.625c0,2.333-0.701,4.579-2.101,6.796c-1.399,2.188-3.237,3.908-5.513,5.162
		c-2.274,1.254-4.55,1.983-6.824,2.217c-0.408,0.059-3.5,0.088-9.305,0.088c-2.625,0-4.783,0.846-6.504,2.566
		c-1.721,1.691-2.566,3.82-2.566,6.329c0,2.392,0.787,4.403,2.362,6.008c1.546,1.634,3.792,2.538,6.708,2.684
		c6.096,0.408,10.412,1.138,12.979,2.158c4.433,1.809,7.991,4.403,10.646,7.729c2.654,3.324,3.967,6.795,3.967,10.383
		c0,4.842-2.275,8.574-6.825,11.229c-4.579,2.654-11.958,3.967-22.138,3.967c-5.979,0-10.674-0.379-14.115-1.195
		c-2.217-0.555-4.58-1.692-7.088-3.413c-1.75-1.138-3.471-1.721-5.133-1.721c-2.393,0-4.404,0.875-6.096,2.625
		c-1.692,1.721-2.509,3.851-2.509,6.358c0,3.296,1.896,6.154,5.716,8.546c6.826,4.229,17.033,6.329,30.596,6.329
		c10.412,0,18.842-1.342,25.287-4.055s11.346-6.708,14.729-11.958c3.384-5.279,5.104-11.024,5.104-17.237
		c0-5.746-1.283-10.938-3.85-15.574C456.438,345.7,452.559,341.617,447.367,338.088L447.367,338.088z M123.97,244.447
		c-4.154,0-7.553,3.398-7.553,7.553c0,4.135,3.418,7.553,7.553,7.553H596.03c4.154,0,7.553-3.398,7.553-7.553
		c0-4.135-3.417-7.553-7.553-7.553H123.97L123.97,244.447z"
    />
    





    

		</svg>
		</div>

	);//return
}