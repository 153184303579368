
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0;

*/






const Flank = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "100%",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "1",
    //top:                props.top || "calc(50% - 175px)",
    //left:               props.left || "calc(50% - 210px)"

    top:                props.top || "0px",
    right:              props.right || "0px"



}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    right:${props => props.right};


`;






const Menu = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc
    border:             props.border || "1px solid lightgray",

    width:              props.width || "400px", 
    height:             props.height || "100%",

    padding:            props.padding|| "0 0 50px 0",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "3",
    //top:                props.top || "calc(50% - 175px)",
    //left:               props.left || "calc(50% - 210px)"

    top:                props.top || "0px",
    right:              props.right || "0px"



}))`

    background-color:${props => props.backgroundColor};
    border-left:${props => props.border};

    max-width: 375px;
    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    right:${props => props.right};


`;






    const MenuControls = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%", 
        height:             props.height || "auto",

        padding:            props.padding|| "0 0 0 0",
        margin:             props.margin || "0 0 0 0",


    }))`

        background-color:${props => props.backgroundColor};
        border-bottom:${props => props.border};

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        position:relative;   
        float:left;



        .Img {

            background-color:transparent;
            width:260px;
            height:40px;
            margin:10px 10px 10px 10px;
            padding:0 0 0 0;

            position:relative;   
            float:left;

        }



        .Name {

            background-color:transparent;
            width:auto;
            height:auto;

            margin:0 0 0 0;
            padding:10px 0 10px 15px;

            position:relative;
            float:left;

            font-family:Arial, Helvetica, sans-serif;;      
            font-size: 20px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#000;

        }


        .XButton {

            background-color:transparent;
            width:50px;
            height:auto;

            margin:0 0 0 0;
            padding:18px 0 10px 0;

            position:relative;
            float:right;

            font-family:Arial, Helvetica, sans-serif;;      
            font-size: 20px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#000;


            cursor:pointer;


        }


    `;




    const MenuTitle = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%", 
        height:             props.height || "auto",

        padding:            props.padding|| "0 0 0 0",
        margin:             props.margin || "0 0 0 0",


    }))`

        background-color:${props => props.backgroundColor};
        border-bottom:${props => props.border};

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        position:relative;   
        float:left;



        .Img {

            background-color:transparent;
            width:40px;
            height:40px;
            margin:10px 10px 0 10px;
            padding:0 0 0 0;

            position:relative;   
            float:left;

        }



        .Text {

            background-color:transparent;
            width:auto;
            height:auto;

            margin:0 0 0 0;
            padding:21px 0 21px 15px;

            font-family:Arial, Helvetica, sans-serif;;      
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            text-decoration:none;
            color:#000;

        }


    `;





    const MenuButton = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(100% - 20px);", 
        height:             props.height || "auto",

        padding:            props.padding|| "10px 0 10px 20px",
        margin:             props.margin || "0 0 0 0",

        hoverBackgroundColor:    props.hoverBackgroundColor || "#0291cd",
        hoverTextColor:          props.hoverTextColor || "#fff",


    }))`

        background-color:${props => props.backgroundColor};
        border-bottom:${props => props.border};

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        position:relative;   
        float:left;


        font-family:Arial, Helvetica, sans-serif;;      
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:#000;

        cursor:pointer;


        &:hover {

            background-color:${props => props.hoverBackgroundColor};
            color:${props => props.hoverTextColor};

        }



    `;









export {

    Flank,
    Menu, 
    MenuControls,
    MenuTitle, 
    MenuButton

}
