import {BUTTON_BANNER_BLUE_BLUE} from "./SVGS/BUTTON_BANNER_BLUE_BLUE";
import {BUTTON_BANNER_BLUE_DARK_BLUE} from "./SVGS/BUTTON_BANNER_BLUE_DARK_BLUE";
import {BUTTON_BANNER_GRAY_BLACK} from "./SVGS/BUTTON_BANNER_GRAY_BLACK";
import {BUTTON_BANNER_GRAY_SILVER} from "./SVGS/BUTTON_BANNER_GRAY_SILVER";
import {BUTTON_BANNER_GREEN_LIME_GREEN} from "./SVGS/BUTTON_BANNER_GREEN_LIME_GREEN";
import {BUTTON_BANNER_ORANGE_ORANGE} from "./SVGS/BUTTON_BANNER_ORANGE_ORANGE";
import {BUTTON_BANNER_PURPLE_PURPLE} from "./SVGS/BUTTON_BANNER_PURPLE_PURPLE";
import {BUTTON_BANNER_RED_RED} from "./SVGS/BUTTON_BANNER_RED_RED";
import {BUTTON_BANNER_YELLOW_YELLOW} from "./SVGS/BUTTON_BANNER_YELLOW_YELLOW";
import {BUTTON_ELEMENT_CR_APEX_BLUE} from "./SVGS/BUTTON_ELEMENT_CR_APEX_BLUE";
import {BUTTON_ELEMENT_CR_BERLINA_BLACK} from "./SVGS/BUTTON_ELEMENT_CR_BERLINA_BLACK";
import {BUTTON_ELEMENT_CR_GRAND_PRIX_WHITE} from "./SVGS/BUTTON_ELEMENT_CR_GRAND_PRIX_WHITE";
import {BUTTON_ELEMENT_CR_RIO_YELLOW} from "./SVGS/BUTTON_ELEMENT_CR_RIO_YELLOW";
import {BUTTON_HELMET_BLACK_DRIVER_JAMES_HUNT} from "./SVGS/BUTTON_HELMET_BLACK_DRIVER_JAMES_HUNT";
import {BUTTON_HELMET_BLACK_SOLID_BERLINA_BLACK} from "./SVGS/BUTTON_HELMET_BLACK_SOLID_BERLINA_BLACK";
import {BUTTON_HELMET_BLUE_SOLID_APEX_BLUE} from "./SVGS/BUTTON_HELMET_BLUE_SOLID_APEX_BLUE";
import {BUTTON_HELMET_BLUE_SOLID_LAGUNA_BLUE} from "./SVGS/BUTTON_HELMET_BLUE_SOLID_LAGUNA_BLUE";
import {BUTTON_HELMET_GRAY_DRIVER_NIGEL_MANSELL} from "./SVGS/BUTTON_HELMET_GRAY_DRIVER_NIGEL_MANSELL";
import {BUTTON_HELMET_GRAY_DRIVER_STEVE_MCQUEEN} from "./SVGS/BUTTON_HELMET_GRAY_DRIVER_STEVE_MCQUEEN";
import {BUTTON_HELMET_GRAY_DRIVER_THE_STIG} from "./SVGS/BUTTON_HELMET_GRAY_DRIVER_THE_STIG";
import {BUTTON_HELMET_GRAY_SOLID_GRAND_PRIX_WHITE} from "./SVGS/BUTTON_HELMET_GRAY_SOLID_GRAND_PRIX_WHITE";
import {BUTTON_HELMET_GRAY_SOLID_SUNSET_MAUVE} from "./SVGS/BUTTON_HELMET_GRAY_SOLID_SUNSET_MAUVE";
import {BUTTON_HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA} from "./SVGS/BUTTON_HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA";
import {BUTTON_HELMET_GREEN_SOLID_LIME_GREEN} from "./SVGS/BUTTON_HELMET_GREEN_SOLID_LIME_GREEN";
import {BUTTON_HELMET_ORANGE_SOLID_IMOLA_ORANGE} from "./SVGS/BUTTON_HELMET_ORANGE_SOLID_IMOLA_ORANGE";
import {BUTTON_HELMET_RED_DRIVER_NELSON_PIQUET} from "./SVGS/BUTTON_HELMET_RED_DRIVER_NELSON_PIQUET";
import {BUTTON_HELMET_RED_DRIVER_VAN_HALEN} from "./SVGS/BUTTON_HELMET_RED_DRIVER_VAN_HALEN";
import {BUTTON_HELMET_RED_SOLID_NEW_FORMULA_RED} from "./SVGS/BUTTON_HELMET_RED_SOLID_NEW_FORMULA_RED";
import {BUTTON_HELMET_YELLOW_DRIVER_AYRTON_SENNA} from "./SVGS/BUTTON_HELMET_YELLOW_DRIVER_AYRTON_SENNA";
import {BUTTON_HELMET_YELLOW_SOLID_RIO_YELLOW} from "./SVGS/BUTTON_HELMET_YELLOW_SOLID_RIO_YELLOW";
import {BUTTON_HELMET_YELLOW_SOLID_SPA_YELLOW} from "./SVGS/BUTTON_HELMET_YELLOW_SOLID_SPA_YELLOW";
import {BUTTON_LOGO_95} from "./SVGS/BUTTON_LOGO_95";
import {BUTTON_LOGO_ADVAN} from "./SVGS/BUTTON_LOGO_ADVAN";
import {BUTTON_LOGO_AP1V1} from "./SVGS/BUTTON_LOGO_AP1V1";
import {BUTTON_LOGO_AP2V1} from "./SVGS/BUTTON_LOGO_AP2V1";
import {BUTTON_LOGO_AP2V3} from "./SVGS/BUTTON_LOGO_AP2V3";
import {BUTTON_LOGO_BBS} from "./SVGS/BUTTON_LOGO_BBS";
import {BUTTON_LOGO_BENSON_AND_HEDGES} from "./SVGS/BUTTON_LOGO_BENSON_AND_HEDGES";
import {BUTTON_LOGO_BRIDGESTONE} from "./SVGS/BUTTON_LOGO_BRIDGESTONE";
import {BUTTON_LOGO_CAMEL} from "./SVGS/BUTTON_LOGO_CAMEL";
import {BUTTON_LOGO_CANON} from "./SVGS/BUTTON_LOGO_CANON";
import {BUTTON_LOGO_CR} from "./SVGS/BUTTON_LOGO_CR";
import {BUTTON_LOGO_DUNLOP} from "./SVGS/BUTTON_LOGO_DUNLOP";
import {BUTTON_LOGO_FIRESTONE} from "./SVGS/BUTTON_LOGO_FIRESTONE";
import {BUTTON_LOGO_FONDMETAL} from "./SVGS/BUTTON_LOGO_FONDMETAL";
import {BUTTON_LOGO_GOODYEAR} from "./SVGS/BUTTON_LOGO_GOODYEAR";
import {BUTTON_LOGO_GULF} from "./SVGS/BUTTON_LOGO_GULF";
import {BUTTON_LOGO_HONDA} from "./SVGS/BUTTON_LOGO_HONDA";
import {BUTTON_LOGO_HONDA_RA300} from "./SVGS/BUTTON_LOGO_HONDA_RA300";
import {BUTTON_LOGO_LUCKY_STRIKE} from "./SVGS/BUTTON_LOGO_LUCKY_STRIKE";
import {BUTTON_LOGO_MARLBORO} from "./SVGS/BUTTON_LOGO_MARLBORO";
import {BUTTON_LOGO_MARTINI} from "./SVGS/BUTTON_LOGO_MARTINI";
import {BUTTON_LOGO_MICHELIN} from "./SVGS/BUTTON_LOGO_MICHELIN";
import {BUTTON_LOGO_OEM} from "./SVGS/BUTTON_LOGO_OEM";
import {BUTTON_LOGO_OZ_RACING} from "./SVGS/BUTTON_LOGO_OZ_RACING";
import {BUTTON_LOGO_PIRELLI} from "./SVGS/BUTTON_LOGO_PIRELLI";
import {BUTTON_LOGO_REDBULL} from "./SVGS/BUTTON_LOGO_REDBULL";
import {BUTTON_LOGO_ROTHMANS} from "./SVGS/BUTTON_LOGO_ROTHMANS";
import {BUTTON_LOGO_SW388} from "./SVGS/BUTTON_LOGO_SW388";
import {BUTTON_LOGO_TRS9} from "./SVGS/BUTTON_LOGO_TRS9";
import {BUTTON_MAIN_BANNER} from "./SVGS/BUTTON_MAIN_BANNER";
import {BUTTON_MAIN_BODY} from "./SVGS/BUTTON_MAIN_BODY";
import {BUTTON_MAIN_CALIPERS} from "./SVGS/BUTTON_MAIN_CALIPERS";
import {BUTTON_MAIN_HARDTOP} from "./SVGS/BUTTON_MAIN_HARDTOP";
import {BUTTON_MAIN_HELMET} from "./SVGS/BUTTON_MAIN_HELMET";
import {BUTTON_MAIN_RACING_WING} from "./SVGS/BUTTON_MAIN_RACING_WING";
import {BUTTON_MAIN_ROLLBAR} from "./SVGS/BUTTON_MAIN_ROLLBAR";
import {BUTTON_MAIN_SOFTTOP} from "./SVGS/BUTTON_MAIN_SOFTTOP";
import {BUTTON_MAIN_STEERING_WHEEL} from "./SVGS/BUTTON_MAIN_STEERING_WHEEL";
import {BUTTON_MAIN_TIRES} from "./SVGS/BUTTON_MAIN_TIRES";
import {BUTTON_MAIN_WHEELS} from "./SVGS/BUTTON_MAIN_WHEELS";
import {BUTTON_MAIN_WING_RACING} from "./SVGS/BUTTON_MAIN_WING_RACING";
import {BUTTON_OFF} from "./SVGS/BUTTON_OFF";
import {BUTTON_PILL_BLUE_APEX_BLUE} from "./SVGS/BUTTON_PILL_BLUE_APEX_BLUE";
import {BUTTON_PILL_BLUE_BLUE} from "./SVGS/BUTTON_PILL_BLUE_BLUE";
import {BUTTON_PILL_BLUE_DARK_BLUE} from "./SVGS/BUTTON_PILL_BLUE_DARK_BLUE";
import {BUTTON_PILL_BLUE_GULF_BLUE} from "./SVGS/BUTTON_PILL_BLUE_GULF_BLUE";
import {BUTTON_PILL_BLUE_LAGUNA_BLUE} from "./SVGS/BUTTON_PILL_BLUE_LAGUNA_BLUE";
import {BUTTON_PILL_BLUE_MONTE_CARLO_BLUE} from "./SVGS/BUTTON_PILL_BLUE_MONTE_CARLO_BLUE";
import {BUTTON_PILL_BLUE_ROYAL_NAVY} from "./SVGS/BUTTON_PILL_BLUE_ROYAL_NAVY";
import {BUTTON_PILL_BLUE_SUZUKA_BLUE} from "./SVGS/BUTTON_PILL_BLUE_SUZUKA_BLUE";
import {BUTTON_PILL_GRAY_BATTLESHIP_GRAY} from "./SVGS/BUTTON_PILL_GRAY_BATTLESHIP_GRAY";
import {BUTTON_PILL_GRAY_BERLINA_BLACK} from "./SVGS/BUTTON_PILL_GRAY_BERLINA_BLACK";
import {BUTTON_PILL_GRAY_BLACK} from "./SVGS/BUTTON_PILL_GRAY_BLACK";
import {BUTTON_PILL_GRAY_CARBON_FIBER} from "./SVGS/BUTTON_PILL_GRAY_CARBON_FIBER";
import {BUTTON_PILL_GRAY_CR_BERLINA_BLACK} from "./SVGS/BUTTON_PILL_GRAY_CR_BERLINA_BLACK";
import {BUTTON_PILL_GRAY_GOLD} from "./SVGS/BUTTON_PILL_GRAY_GOLD";
import {BUTTON_PILL_GRAY_GRAND_PRIX_WHITE} from "./SVGS/BUTTON_PILL_GRAY_GRAND_PRIX_WHITE";
import {BUTTON_PILL_GRAY_GUNMETAL} from "./SVGS/BUTTON_PILL_GRAY_GUNMETAL";
import {BUTTON_PILL_GRAY_MOONROCK} from "./SVGS/BUTTON_PILL_GRAY_MOONROCK";
import {BUTTON_PILL_GRAY_SEBRING_SILVER} from "./SVGS/BUTTON_PILL_GRAY_SEBRING_SILVER";
import {BUTTON_PILL_GRAY_SILVER} from "./SVGS/BUTTON_PILL_GRAY_SILVER";
import {BUTTON_PILL_GRAY_SILVERSTONE} from "./SVGS/BUTTON_PILL_GRAY_SILVERSTONE";
import {BUTTON_PILL_GRAY_SUNSET_MAUVE} from "./SVGS/BUTTON_PILL_GRAY_SUNSET_MAUVE";
import {BUTTON_PILL_GREEN_LIME_GREEN} from "./SVGS/BUTTON_PILL_GREEN_LIME_GREEN";
import {BUTTON_PILL_GREEN_TAHITIAN_GREEN} from "./SVGS/BUTTON_PILL_GREEN_TAHITIAN_GREEN";
import {BUTTON_PILL_ORANGE_IMOLA_ORANGE} from "./SVGS/BUTTON_PILL_ORANGE_IMOLA_ORANGE";
import {BUTTON_PILL_ORANGE_ORANGE} from "./SVGS/BUTTON_PILL_ORANGE_ORANGE";
import {BUTTON_PILL_PURPLE_PURPLE} from "./SVGS/BUTTON_PILL_PURPLE_PURPLE";
import {BUTTON_PILL_RED_NEW_FORMULA_RED} from "./SVGS/BUTTON_PILL_RED_NEW_FORMULA_RED";
import {BUTTON_PILL_RED_RED} from "./SVGS/BUTTON_PILL_RED_RED";
import {BUTTON_PILL_YELLOW_RIO_YELLOW} from "./SVGS/BUTTON_PILL_YELLOW_RIO_YELLOW";
import {BUTTON_PILL_YELLOW_SPA_YELLOW} from "./SVGS/BUTTON_PILL_YELLOW_SPA_YELLOW";
import {BUTTON_PILL_YELLOW_YELLOW} from "./SVGS/BUTTON_PILL_YELLOW_YELLOW";
import {BUTTON_RECT_BLUE_BLACK_BLUE} from "./SVGS/BUTTON_RECT_BLUE_BLACK_BLUE";
import {BUTTON_RECT_GRAY_BLACK_GRAY} from "./SVGS/BUTTON_RECT_GRAY_BLACK_GRAY";
import {BUTTON_RECT_GREEN_BLACK_GREEN} from "./SVGS/BUTTON_RECT_GREEN_BLACK_GREEN";
import {BUTTON_RECT_ORANGE_BLACK_ORANGE} from "./SVGS/BUTTON_RECT_ORANGE_BLACK_ORANGE";
import {BUTTON_RECT_PURPLE_BLACK_PURPLE} from "./SVGS/BUTTON_RECT_PURPLE_BLACK_PURPLE";
import {BUTTON_RECT_RED_BLACK_RED} from "./SVGS/BUTTON_RECT_RED_BLACK_RED";
import {BUTTON_RECT_YELLOW_BLACK_YELLOW} from "./SVGS/BUTTON_RECT_YELLOW_BLACK_YELLOW";
import {BUTTON_THEME_BLUE_S2R_BLUE_AND_RED} from "./SVGS/BUTTON_THEME_BLUE_S2R_BLUE_AND_RED";
import {BUTTON_THEME_BLUE_S2R_BLUE_AND_YELLOW} from "./SVGS/BUTTON_THEME_BLUE_S2R_BLUE_AND_YELLOW";
import {BUTTON_THEME_GRAY_S2R_BERLINA_BLACK} from "./SVGS/BUTTON_THEME_GRAY_S2R_BERLINA_BLACK";
import {BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE} from "./SVGS/BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE";
import {BUTTON_THEME_GRAY_S2R_SEBRING_SILVER} from "./SVGS/BUTTON_THEME_GRAY_S2R_SEBRING_SILVER";
import {BUTTON_THEME_RED_S2R_NEW_FORMULA_RED} from "./SVGS/BUTTON_THEME_RED_S2R_NEW_FORMULA_RED";
import {BUTTON_TIRE_DUNLOP} from "./SVGS/BUTTON_TIRE_DUNLOP";
import {BUTTON_TIRE_DUNLOP_REVERSED} from "./SVGS/BUTTON_TIRE_DUNLOP_REVERSED";
import {BUTTON_TIRE_PIRELLI_HARD_BLUE} from "./SVGS/BUTTON_TIRE_PIRELLI_HARD_BLUE";
import {BUTTON_TIRE_PIRELLI_HARD_ORANGE} from "./SVGS/BUTTON_TIRE_PIRELLI_HARD_ORANGE";
import {BUTTON_TIRE_PIRELLI_INTER_GREEN} from "./SVGS/BUTTON_TIRE_PIRELLI_INTER_GREEN";
import {BUTTON_TIRE_PIRELLI_MEDIUM_WHITE} from "./SVGS/BUTTON_TIRE_PIRELLI_MEDIUM_WHITE";
import {BUTTON_TIRE_PIRELLI_MEDIUM_YELLOW} from "./SVGS/BUTTON_TIRE_PIRELLI_MEDIUM_YELLOW";
import {BUTTON_TIRE_PIRELLI_SOFT_MAGENTA} from "./SVGS/BUTTON_TIRE_PIRELLI_SOFT_MAGENTA";
import {BUTTON_TIRE_PIRELLI_SOFT_PINK} from "./SVGS/BUTTON_TIRE_PIRELLI_SOFT_PINK";
import {BUTTON_TIRE_PIRELLI_SOFT_RED} from "./SVGS/BUTTON_TIRE_PIRELLI_SOFT_RED";
import {BUTTON_TIRE_PIRELLI_WET_BLUE} from "./SVGS/BUTTON_TIRE_PIRELLI_WET_BLUE";
import {BUTTON_WHEEL_OZ_RACING_BLACK} from "./SVGS/BUTTON_WHEEL_OZ_RACING_BLACK";
import {BUTTON_WHEEL_OZ_RACING_BLACK_WHITE} from "./SVGS/BUTTON_WHEEL_OZ_RACING_BLACK_WHITE";
import {BUTTON_WHEEL_OZ_RACING_BLUE_BLUE} from "./SVGS/BUTTON_WHEEL_OZ_RACING_BLUE_BLUE";
import {BUTTON_WHEEL_OZ_RACING_BLUE_DARK_BLUE} from "./SVGS/BUTTON_WHEEL_OZ_RACING_BLUE_DARK_BLUE";
import {BUTTON_WHEEL_OZ_RACING_GREEN_BLACK_GREEN} from "./SVGS/BUTTON_WHEEL_OZ_RACING_GREEN_BLACK_GREEN";
import {BUTTON_WHEEL_OZ_RACING_ORANGE_BLACK_ORANGE} from "./SVGS/BUTTON_WHEEL_OZ_RACING_ORANGE_BLACK_ORANGE";
import {BUTTON_WHEEL_OZ_RACING_PURPLE_BLACK_PURPLE} from "./SVGS/BUTTON_WHEEL_OZ_RACING_PURPLE_BLACK_PURPLE";
import {BUTTON_WHEEL_OZ_RACING_RED_BLACK_RED} from "./SVGS/BUTTON_WHEEL_OZ_RACING_RED_BLACK_RED";
import {BUTTON_WHEEL_OZ_RACING_YELLOW_BLACK_YELLOW} from "./SVGS/BUTTON_WHEEL_OZ_RACING_YELLOW_BLACK_YELLOW";



const Index = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div
		style={{
			width:"100%",
			height:"100%"
		}}
		>

			{
			props.call === 'BUTTON_BANNER_BLUE_BLUE' ?
				<BUTTON_BANNER_BLUE_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_BLUE_DARK_BLUE' ?
				<BUTTON_BANNER_BLUE_DARK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_GRAY_BLACK' ?
				<BUTTON_BANNER_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_GRAY_SILVER' ?
				<BUTTON_BANNER_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_GREEN_LIME_GREEN' ?
				<BUTTON_BANNER_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_ORANGE_ORANGE' ?
				<BUTTON_BANNER_ORANGE_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_PURPLE_PURPLE' ?
				<BUTTON_BANNER_PURPLE_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_RED_RED' ?
				<BUTTON_BANNER_RED_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_BANNER_YELLOW_YELLOW' ?
				<BUTTON_BANNER_YELLOW_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_ELEMENT_CR_APEX_BLUE' ?
				<BUTTON_ELEMENT_CR_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_ELEMENT_CR_BERLINA_BLACK' ?
				<BUTTON_ELEMENT_CR_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_ELEMENT_CR_GRAND_PRIX_WHITE' ?
				<BUTTON_ELEMENT_CR_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_ELEMENT_CR_RIO_YELLOW' ?
				<BUTTON_ELEMENT_CR_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_BLACK_DRIVER_JAMES_HUNT' ?
				<BUTTON_HELMET_BLACK_DRIVER_JAMES_HUNT
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_BLACK_SOLID_BERLINA_BLACK' ?
				<BUTTON_HELMET_BLACK_SOLID_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_BLUE_SOLID_APEX_BLUE' ?
				<BUTTON_HELMET_BLUE_SOLID_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_BLUE_SOLID_LAGUNA_BLUE' ?
				<BUTTON_HELMET_BLUE_SOLID_LAGUNA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GRAY_DRIVER_NIGEL_MANSELL' ?
				<BUTTON_HELMET_GRAY_DRIVER_NIGEL_MANSELL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GRAY_DRIVER_STEVE_MCQUEEN' ?
				<BUTTON_HELMET_GRAY_DRIVER_STEVE_MCQUEEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GRAY_DRIVER_THE_STIG' ?
				<BUTTON_HELMET_GRAY_DRIVER_THE_STIG
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GRAY_SOLID_GRAND_PRIX_WHITE' ?
				<BUTTON_HELMET_GRAY_SOLID_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GRAY_SOLID_SUNSET_MAUVE' ?
				<BUTTON_HELMET_GRAY_SOLID_SUNSET_MAUVE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA' ?
				<BUTTON_HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_GREEN_SOLID_LIME_GREEN' ?
				<BUTTON_HELMET_GREEN_SOLID_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_ORANGE_SOLID_IMOLA_ORANGE' ?
				<BUTTON_HELMET_ORANGE_SOLID_IMOLA_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_RED_DRIVER_NELSON_PIQUET' ?
				<BUTTON_HELMET_RED_DRIVER_NELSON_PIQUET
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_RED_DRIVER_VAN_HALEN' ?
				<BUTTON_HELMET_RED_DRIVER_VAN_HALEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_RED_SOLID_NEW_FORMULA_RED' ?
				<BUTTON_HELMET_RED_SOLID_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_YELLOW_DRIVER_AYRTON_SENNA' ?
				<BUTTON_HELMET_YELLOW_DRIVER_AYRTON_SENNA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_YELLOW_SOLID_RIO_YELLOW' ?
				<BUTTON_HELMET_YELLOW_SOLID_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_HELMET_YELLOW_SOLID_SPA_YELLOW' ?
				<BUTTON_HELMET_YELLOW_SOLID_SPA_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_95' ?
				<BUTTON_LOGO_95
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_ADVAN' ?
				<BUTTON_LOGO_ADVAN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_AP1V1' ?
				<BUTTON_LOGO_AP1V1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_AP2V1' ?
				<BUTTON_LOGO_AP2V1
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_AP2V3' ?
				<BUTTON_LOGO_AP2V3
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_BBS' ?
				<BUTTON_LOGO_BBS
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_BENSON_AND_HEDGES' ?
				<BUTTON_LOGO_BENSON_AND_HEDGES
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_BRIDGESTONE' ?
				<BUTTON_LOGO_BRIDGESTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_CAMEL' ?
				<BUTTON_LOGO_CAMEL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_CANON' ?
				<BUTTON_LOGO_CANON
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_CR' ?
				<BUTTON_LOGO_CR
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_DUNLOP' ?
				<BUTTON_LOGO_DUNLOP
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_FIRESTONE' ?
				<BUTTON_LOGO_FIRESTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_FONDMETAL' ?
				<BUTTON_LOGO_FONDMETAL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_GOODYEAR' ?
				<BUTTON_LOGO_GOODYEAR
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_GULF' ?
				<BUTTON_LOGO_GULF
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_HONDA' ?
				<BUTTON_LOGO_HONDA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_HONDA_RA300' ?
				<BUTTON_LOGO_HONDA_RA300
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_LUCKY_STRIKE' ?
				<BUTTON_LOGO_LUCKY_STRIKE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_MARLBORO' ?
				<BUTTON_LOGO_MARLBORO
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_MARTINI' ?
				<BUTTON_LOGO_MARTINI
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_MICHELIN' ?
				<BUTTON_LOGO_MICHELIN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_OEM' ?
				<BUTTON_LOGO_OEM
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_OZ_RACING' ?
				<BUTTON_LOGO_OZ_RACING
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_PIRELLI' ?
				<BUTTON_LOGO_PIRELLI
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_REDBULL' ?
				<BUTTON_LOGO_REDBULL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_ROTHMANS' ?
				<BUTTON_LOGO_ROTHMANS
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_SW388' ?
				<BUTTON_LOGO_SW388
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_LOGO_TRS9' ?
				<BUTTON_LOGO_TRS9
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_BANNER' ?
				<BUTTON_MAIN_BANNER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_BODY' ?
				<BUTTON_MAIN_BODY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_CALIPERS' ?
				<BUTTON_MAIN_CALIPERS
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_HARDTOP' ?
				<BUTTON_MAIN_HARDTOP
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_HELMET' ?
				<BUTTON_MAIN_HELMET
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_RACING_WING' ?
				<BUTTON_MAIN_RACING_WING
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_ROLLBAR' ?
				<BUTTON_MAIN_ROLLBAR
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_SOFTTOP' ?
				<BUTTON_MAIN_SOFTTOP
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_STEERING_WHEEL' ?
				<BUTTON_MAIN_STEERING_WHEEL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_TIRES' ?
				<BUTTON_MAIN_TIRES
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_WHEELS' ?
				<BUTTON_MAIN_WHEELS
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_MAIN_WING_RACING' ?
				<BUTTON_MAIN_WING_RACING
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_OFF' ?
				<BUTTON_OFF
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_APEX_BLUE' ?
				<BUTTON_PILL_BLUE_APEX_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_BLUE' ?
				<BUTTON_PILL_BLUE_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_DARK_BLUE' ?
				<BUTTON_PILL_BLUE_DARK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_GULF_BLUE' ?
				<BUTTON_PILL_BLUE_GULF_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_LAGUNA_BLUE' ?
				<BUTTON_PILL_BLUE_LAGUNA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE' ?
				<BUTTON_PILL_BLUE_MONTE_CARLO_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_ROYAL_NAVY' ?
				<BUTTON_PILL_BLUE_ROYAL_NAVY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_BLUE_SUZUKA_BLUE' ?
				<BUTTON_PILL_BLUE_SUZUKA_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_BATTLESHIP_GRAY' ?
				<BUTTON_PILL_GRAY_BATTLESHIP_GRAY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_BERLINA_BLACK' ?
				<BUTTON_PILL_GRAY_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_BLACK' ?
				<BUTTON_PILL_GRAY_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_CARBON_FIBER' ?
				<BUTTON_PILL_GRAY_CARBON_FIBER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_CR_BERLINA_BLACK' ?
				<BUTTON_PILL_GRAY_CR_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_GOLD' ?
				<BUTTON_PILL_GRAY_GOLD
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_GRAND_PRIX_WHITE' ?
				<BUTTON_PILL_GRAY_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_GUNMETAL' ?
				<BUTTON_PILL_GRAY_GUNMETAL
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_MOONROCK' ?
				<BUTTON_PILL_GRAY_MOONROCK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_SEBRING_SILVER' ?
				<BUTTON_PILL_GRAY_SEBRING_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_SILVER' ?
				<BUTTON_PILL_GRAY_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_SILVERSTONE' ?
				<BUTTON_PILL_GRAY_SILVERSTONE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GRAY_SUNSET_MAUVE' ?
				<BUTTON_PILL_GRAY_SUNSET_MAUVE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GREEN_LIME_GREEN' ?
				<BUTTON_PILL_GREEN_LIME_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_GREEN_TAHITIAN_GREEN' ?
				<BUTTON_PILL_GREEN_TAHITIAN_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_ORANGE_IMOLA_ORANGE' ?
				<BUTTON_PILL_ORANGE_IMOLA_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_ORANGE_ORANGE' ?
				<BUTTON_PILL_ORANGE_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_PURPLE_PURPLE' ?
				<BUTTON_PILL_PURPLE_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_RED_NEW_FORMULA_RED' ?
				<BUTTON_PILL_RED_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_RED_RED' ?
				<BUTTON_PILL_RED_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_YELLOW_RIO_YELLOW' ?
				<BUTTON_PILL_YELLOW_RIO_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_YELLOW_SPA_YELLOW' ?
				<BUTTON_PILL_YELLOW_SPA_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_PILL_YELLOW_YELLOW' ?
				<BUTTON_PILL_YELLOW_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_BLUE_BLACK_BLUE' ?
				<BUTTON_RECT_BLUE_BLACK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_GRAY_BLACK_GRAY' ?
				<BUTTON_RECT_GRAY_BLACK_GRAY
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_GREEN_BLACK_GREEN' ?
				<BUTTON_RECT_GREEN_BLACK_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_ORANGE_BLACK_ORANGE' ?
				<BUTTON_RECT_ORANGE_BLACK_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_PURPLE_BLACK_PURPLE' ?
				<BUTTON_RECT_PURPLE_BLACK_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_RED_BLACK_RED' ?
				<BUTTON_RECT_RED_BLACK_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_RECT_YELLOW_BLACK_YELLOW' ?
				<BUTTON_RECT_YELLOW_BLACK_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_THEME_BLUE_S2R_BLUE_AND_RED' ?
				<BUTTON_THEME_BLUE_S2R_BLUE_AND_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_THEME_BLUE_S2R_BLUE_AND_YELLOW' ?
				<BUTTON_THEME_BLUE_S2R_BLUE_AND_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_THEME_GRAY_S2R_BERLINA_BLACK' ?
				<BUTTON_THEME_GRAY_S2R_BERLINA_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE' ?
				<BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_THEME_GRAY_S2R_SEBRING_SILVER' ?
				<BUTTON_THEME_GRAY_S2R_SEBRING_SILVER
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_THEME_RED_S2R_NEW_FORMULA_RED' ?
				<BUTTON_THEME_RED_S2R_NEW_FORMULA_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_DUNLOP' ?
				<BUTTON_TIRE_DUNLOP
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_DUNLOP_REVERSED' ?
				<BUTTON_TIRE_DUNLOP_REVERSED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_HARD_BLUE' ?
				<BUTTON_TIRE_PIRELLI_HARD_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_HARD_ORANGE' ?
				<BUTTON_TIRE_PIRELLI_HARD_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_INTER_GREEN' ?
				<BUTTON_TIRE_PIRELLI_INTER_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_MEDIUM_WHITE' ?
				<BUTTON_TIRE_PIRELLI_MEDIUM_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_MEDIUM_YELLOW' ?
				<BUTTON_TIRE_PIRELLI_MEDIUM_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_SOFT_MAGENTA' ?
				<BUTTON_TIRE_PIRELLI_SOFT_MAGENTA
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_SOFT_PINK' ?
				<BUTTON_TIRE_PIRELLI_SOFT_PINK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_SOFT_RED' ?
				<BUTTON_TIRE_PIRELLI_SOFT_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_TIRE_PIRELLI_WET_BLUE' ?
				<BUTTON_TIRE_PIRELLI_WET_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_BLACK' ?
				<BUTTON_WHEEL_OZ_RACING_BLACK
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_BLACK_WHITE' ?
				<BUTTON_WHEEL_OZ_RACING_BLACK_WHITE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_BLUE_BLUE' ?
				<BUTTON_WHEEL_OZ_RACING_BLUE_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_BLUE_DARK_BLUE' ?
				<BUTTON_WHEEL_OZ_RACING_BLUE_DARK_BLUE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_GREEN_BLACK_GREEN' ?
				<BUTTON_WHEEL_OZ_RACING_GREEN_BLACK_GREEN
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_ORANGE_BLACK_ORANGE' ?
				<BUTTON_WHEEL_OZ_RACING_ORANGE_BLACK_ORANGE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_PURPLE_BLACK_PURPLE' ?
				<BUTTON_WHEEL_OZ_RACING_PURPLE_BLACK_PURPLE
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_RED_BLACK_RED' ?
				<BUTTON_WHEEL_OZ_RACING_RED_BLACK_RED
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
			{
			props.call === 'BUTTON_WHEEL_OZ_RACING_YELLOW_BLACK_YELLOW' ?
				<BUTTON_WHEEL_OZ_RACING_YELLOW_BLACK_YELLOW
					svgWidth={svgWidth}
					svgHeight={svgHeight}
					svgViewBox={svgViewBox}
					svgPreserveAspectRatio={svgPreserveAspectRatio}
					svgStyle={svgStyle}
				/>
			:null
			}
		</div>

	);//return
}
export default Index;