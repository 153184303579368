import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


/*
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,  
    //useHistory,
    //useParams


} from "react-router-dom";

*/


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
// import { useCollage } from 'utils/hooks/LiquidFormula/Collage';
// import { useLiqGrid } from 'utils/hooks/LiquidFormula/LiqGrid';




import {

    SlideShow,
    // SlideScroller,
    // SlideHolder,
    Slide,

    Controls,Button,
    Layer


} from './Styled';


//import SampleData from './SampleData';
//import StylColumn from './StylModules/Column.module.css';

//import FGrid from 'utils/components/Grid/FGrid';
import {
    BasicImageSlide,
    LiquidImageSlide
} from './Slides';


import {

    //HandleAnimate,
   HandlePrevNext

} from './Handles';


const Index = (props) => {



    

    //console.log(JSON.stringify(props,null,2));

    /*

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    */


    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));




    //##############################################################

    // var gridObj;
    // gridObj = useLiqGrid(ssData.items, ssData.info);
    // //console.log("myBuilds/FGrid/FixedGrid - vars..."+JSON.stringify(gridObj['vars'],null,2));

    // const liqItems = gridObj['items'];
    // if(ssData.info.call === 'flex')
    // {
    //     var data = [];
    //     for(var itm of gridObj['items'].data)
    //     {
    //         if(Object.prototype.toString.call(itm) === '[object Array]')
    //         {
    //             for(var obj of itm)
    //             {

    //                 data.push(obj);

    //             }
    //         }
    //     }
    //     liqItems.data = data;
    // }
    // //console.log("LIQITEMS: "+JSON.stringify(liqItems.data));
    // //alert('check liqItems')


    //################################################################




    

    // -- ref -- //
    const refA = useRef(null);
    // const refB = useRef(null);
    // const refC = useRef(null);
    const refDisplayLayer = useRef(null);
    const refActionLayer = useRef(null);
    const refControls = useRef(null);


    // -- state -- //
    const [rowid] = useState(props.refid);
    //console.log("ROWID: "+rowid);

    const [isLoaded, setIsLoaded] = useState(false);

    const [screenW, setScreenW] = useState(0);
    const [screenH, setScreenH] = useState(0);

    const [showHeight, setShowHeight] = useState("500px");


    const [slideSize, setSlideSize] = useState({
        width:"100vw",
        //height:"calc(100vh - 100px)"
        height:"100%"
    });

    const [slides, setSlides] = useState([]);
    const [actionSlides, setActionSlides] = useState([]);
    const [displaySlides, setDisplaySlides] = useState([]);
    const [slideNumber, setSlideNumber] = useState(0);
    const [slideCall, setSlideCall] = useState('Basic');

    const [actionZ, setActionZ] = useState(1);
    const [displayZ] = useState(2);  
    const [controlsZ] = useState(3);

 
    const [slideShowData, setSlideShowData] = useState(props.data);



    useEffect(() => {


        // if(!isLoaded)
        // {

        //     setIsLoaded(true);

        // } 

        //console.log(JSON.stringify(props,null,2));
        //alert('props');

        //----------------------------------------
        // ensures the height and width adjusts happen

        setScreenW(width);
        setScreenH(height);
        
        //-----------------------------------------


        var ssData;
        if(props.data && props.data !== undefined)
        {
            ssData = JSON.parse(JSON.stringify(props.data));

        }


        if(!isLoaded)
        {

            var items = [];
            var i;
            var object;


            // if(Object.prototype.toString.call(liqItems.data === "[object Array]"))
            // {
            //     // setSlides(liqItems.data);
            //     // setDisplaySlides([liqItems.data[0]]);
            //     // setActionSlides([liqItems.data[0]]);

            //     //console.log("LIQ ITEMS: "+JSON.stringify(liqItems.data,null,2));


            //     for(i=0; i < liqItems.data.length; i++)
            //     {
            //         object = liqItems.data[i];
            //         if((i+1) % 2  === 0)
            //         {
            //             object.backgroundColor = 'lightgray';
            //         }else{
            //             object.backgroundColor = 'gray';
            //         }
            //         items.push(object);
            //     }


            //     // console.log("INFO CHECK: "+JSON.stringify(ssData.info,null,2));
            //     // console.log("ITEMS CHECK: "+JSON.stringify(items,null,2));
            //     // console.log("DISPLAY SLIDES: "+JSON.stringify(items[0],null,2));
            //     // console.log("ACTION SLIDES: "+JSON.stringify(items[0],null,2));

            //     setSlides(items);
            //     setDisplaySlides([items[slideNumber]]);
            //     setActionSlides([items[slideNumber]]);


            // }
            // else


            if(Object.prototype.toString.call(ssData.items === "[object Array]"))
            {
                // setSlides(ssData.items);
                // setDisplaySlides([ssData.items[0]]);
                // setActionSlides([ssData.items[0]]);

                // setSlides(liqItems);
                // setDisplaySlides([liqItems[0]]);
                // setActionSlides([liqItems[0]]);


                for(i=0; i < ssData.items.length; i++)
                {
                    object = ssData.items[i];
                    if((i+1) % 2  === 0)
                    {
                        object.backgroundColor = 'lightgray';
                    }else{
                        object.backgroundColor = 'gray';
                    }
                    items.push(object);
                }


                // console.log("INFO CHECK: "+JSON.stringify(ssData.info,null,2));
                // console.log("ITEMS CHECK: "+JSON.stringify(items,null,2));
                // console.log("DISPLAY SLIDES: "+JSON.stringify(items[0],null,2));
                // console.log("ACTION SLIDES: "+JSON.stringify(items[0],null,2));

                setSlides(items);
                setDisplaySlides([items[slideNumber]]);
                setActionSlides([items[slideNumber]]);

            }
            else
            {
                // setSlides(SampleData);
                // setDisplaySlides([SampleData[slideNumber]]);
                // setActionSlides([SampleData[slideNumber]]);

                setSlides(ssData.items);
                setDisplaySlides([ssData.items[0]]);
                setActionSlides([ssData.items[0]]);

                //console.log("SAMPLE DATA: "+JSON.stringify(ssData.items[0]));

            }


            setIsLoaded(true);
            
        
        


        }else{

            // console.log("SLIDE NUMBER: "+JSON.stringify(slideNumber,null,2));
            // console.log("DISPLAY SLIDES: "+JSON.stringify(displaySlides,null,2));
            // console.log("ACTION SLIDES: "+JSON.stringify(actionSlides,null,2));
        }



        // if(!isLoaded)
        // {
        //     setIsLoaded(true);
        //     setSlides(ssData.items);
        //     setDisplaySlides([ssData.items[0]]);
        //     setActionSlides([ssData.items[0]]);

        //     //console.log("SAMPLE DATA: "+JSON.stringify(ssData.items[0]));
        // }










        /**
         * 
         * 
         * slideWidth - choice
         * 
         * viewableSlideCount
         * 
         * ActionLayer
         * DisplayLayer
         * 
         *
         * 
         */


 
        // console.log(JSON.stringify(props,null,2));
        //alert('props');




        // -- Show Sizing -- //

        //let headerCall = props.databind.settings.appearance.header.headerCall
        //let headerW = props.databind.headerSizes.groupW;
        let headerH = props.databind.headerSizes.groupH;

        var showH = props.databind.viewHeight;
        var vh;

        
        //if(headerCall !== 'C')
        //{
            if(isNaN(showH))
            {
                vh = showH;
                if(showH.match(/px/gi)) vh = showH.split("px")[0];
            }else{ 
                vh = showH;
            }
            showH = parseInt(vh) - headerH - (0) +"px";

            //console.log(showH);
            //console.log("yeah!!!");
        //}


        //alert("showH: "+showH);
        setShowHeight(showH);



        // -- Slide Sizing -- //

        // setSlideSize({
        //     width:"100vw",
        //     //height:"calc(100vh - 100px)"
        //     height:"100%"
        // });

        setSlideSize({
            width:refA.current.offsetWidth + "px",
            //width:"100%",
            //height:refA.current.offsetHeight + "px"
            height:showH
        });



        if(ssData.info.call === 'collage') setSlideCall('Liquid');

    
        if(ssData.info.view === 'fullScreen')
        {

            //alert(props.databind.viewHeight);

            ssData.info.view = 'fullScreen';
            ssData.info.viewHeight = showH;

            var totalRows;
            var thumbHInt;
            totalRows = parseInt(ssData.info.cells.total)/parseInt(ssData.info.cells.across);
            thumbHInt = parseInt(props.databind.viewHeight)/parseInt(totalRows);
            if(!isNaN(thumbHInt)
            )
            {
                thumbHInt = parseInt(props.databind.viewHeight) / parseInt(totalRows);
                //console.log(thumbHInt);
                ssData.info.thumbHInt = thumbHInt;
                
            }

            if(refA.current.offsetWidth - 20 < refA.current.offsetHeight)
            {
                //ssData.info.view = 'default';
                //alert('portrait');
            }

        }


        // if(ssData.slideshow.controls)
        // {

        //     var ct = "calc(100% - "+(refControls.current.offsetHeight+15)+"px)";
        //     var cl = "calc(50% - "+refControls.current.offsetWidth/2+"px)";

        //     //console.log(refControls.current.offsetHeight+"\n"+refControls.current.offsetWidth);

        //     ssData.slideshow.controls.styl.width = "auto";
        //     ssData.slideshow.controls.styl.top = ct;
        //     ssData.slideshow.controls.styl.left = cl;

        //     setControlsT(ct);
        //     setControlsL(cl);


        // }



        if(Object.prototype.toString.call(ssData.info === "[object Object]"))
        {

            //console.log("INFO CHECK: "+JSON.stringify(ssData.info,null,2));

            //Only one image per 
            if(ssData.info.cells && ssData.info.cells !== undefined)
            {
                ssData.info.cells.total = 1;
                ssData.info.cells.across = 1;
                ssData.info.view = 'fullScreen';
                ssData.info.viewHeight = vh + "px";
                ssData.info.opacity = 1;
                ssData.info.webkitFilter = 'grayscale(0%)';
                ssData.info.filter = 'grayscale(0%)';

            }

        }


        setSlideShowData(ssData);





    },
    [
        props,
        width,
        height,
        isLoaded,
        screenW,screenH,
        refA,

        //refControls

        //liqItems,

        slides,
        slideNumber,
        displaySlides,
        actionSlides
 
    ])





    //#############################################################
    //#############################################################
    //#############################################################

    // -- handle -- //

    const handle = (hndl) => {


        // console.log("HNDL: "+JSON.stringify(hndl,null,2));
        // console.log("hndl.call: "+hndl.call);


        switch(hndl.call)
        {
        // case'animate':

        //     handleAnimate(hndl.data);
        // break;
        // case'prev':
        // case'next':

        //     alert(JSON.stringify(hndl.data))
        // break;
        case'setActionZ':

            setActionZ(hndl.data);
        break;
        case'setActionSlides':

            //console.log("ACTION SLIDES: "+JSON.stringify(hndl.data,null,2));
            setActionSlides(hndl.data);
        break;
        case'setDisplaySlides':

            setDisplaySlides(hndl.data);
        break;
        case'setSlideNumber':

            //console.log("setSlideNumber: "+hndl.data)
            setSlideNumber(hndl.data);
            setDisplaySlides([slides[hndl.data]]);
            setActionSlides([slides[hndl.data]]);
        break;
        default:
        }

        // console.log("SLIDE NUMBER: "+slideNumber);
        // console.log("DISPLAY SLIDES: "+JSON.stringify(displaySlides,null,2));
        // console.log("ACTION SLIDES: "+JSON.stringify(actionSlides,null,2));



    };




    



    //#############################################################
    //#############################################################
    //#############################################################


    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

    
            <SlideShow
            ref={refA}
            id={rowid+"_slideShow"}
            height={showHeight}
            >

                <Controls
                ref={refControls}
                id={rowid+"_controls"}
                //backgroundColor={"darkblue"}
                zIndex={controlsZ}
                >

                    <Button
                    id={rowid+"_controls_prevButton"}
                    //backgroundColor={"purple"}
                    margin={"0 0 0 15px"}
                    float={"left"}
                    >

                        P
                    
                    </Button>

                    <Button
                    id={rowid+"_controls_nextButton"}
                    //backgroundColor={"purple"}
                    margin={"0 15px 0 0"}
                    float={"right"}
                    >
                    
                        N
                    
                    </Button>


                </Controls>


                <Layer
                ref={refDisplayLayer}
                id={rowid+"_displayLayer"}
                backgroundColor={"tranparent"}
                width={slideSize.width}
                height={slideSize.height}
                zIndex={displayZ}
                >

                </Layer>


                <Layer
                ref={refActionLayer}
                id={rowid+"_actionLayer"}
                backgroundColor={"transparent"}
                width={slideSize.width}
                height={slideSize.height}
                zIndex={actionZ}
                >

                </Layer>




            </SlideShow>

        )

    } 
    else 
    {



        return (


            <SlideShow
            ref={refA}
            id={"slideshow"}
            height={showHeight}
            >


                <Controls
                ref={refControls}
                id={rowid+"_controls"}
                //backgroundColor={"darkblue"}
                zIndex={controlsZ}
                >
                    <Button
                    id={rowid+"_controls_prevButton"}
                    //backgroundColor={"purple"}
                    margin={"0 0 0 15px"}
                    float={"left"}
                    onClick={(v) => HandlePrevNext({

                        handle:handle,
                        call:'prev',
                        slideNumber:slideNumber,
                        slides:slides,
                        refA:refA,
                        refActionLayer:refActionLayer


                    })}
                    >
                    
                        <div className="Text">

                            REV

                        </div>
                 
                    </Button>

                    <Button
                    id={rowid+"_controls_nextButton"}
                    //backgroundColor={"purple"}
                    margin={"0 15px 0 0"}
                    float={"right"}
                    onClick={(v) => HandlePrevNext({

                        handle:handle,
                        call:'next',
                        slideNumber:slideNumber,
                        slides:slides,
                        refA:refA,
                        refActionLayer:refActionLayer


                    })}
                    >
                        <div className="Text">

                            FWD

                        </div>
                 
                
                    </Button>


                </Controls>


                <Layer
                ref={refDisplayLayer}
                id={rowid+"_displayLayer"}
                //backgroundColor={"lightblue"}
                width={slideSize.width}
                height={slideSize.height}
                zIndex={displayZ}
                >


                    {displaySlides.map((item, i) => (
                    <div key={i}>


                        <Slide
                        backgroundColor={item.backgroundColor}
                        width={slideSize.width}
                        height={slideSize.height}
                        >

                            {
                            slideCall === "Basic"
                            ?


                                <BasicImageSlide

                                    handle      = {(v) => handle(v)}
                                    databind    = {{

                                        info        :slideShowData.info,         
                                        item        :item,
                                        slideSize   :slideSize,
                                        slideNumber :slideNumber
                                        

                                    }}
                
                                    
                                />

                            :
                            slideCall === 'Liquid'
                            ?

                                <LiquidImageSlide

                                    handle      = {(v) => handle(v)}
                                    databind    = {{

                                        info        :slideShowData.info,         
                                        item        :item,
                                        slideSize   :slideSize,
                                        slideNumber :slideNumber
                                        

                                    }}
            
                                />


                            :
                            null
                            }
                        </Slide>


                    </div>
                    ))}

                </Layer>



                <Layer
                ref={refActionLayer}
                id={rowid+"_actionLayer"}
                //backgroundColor={"lightgreen"}
                width={slideSize.width}
                height={slideSize.height}
                zIndex={actionZ}
                >


                    {actionSlides.map((item, i) => (
                    <div key={i}>


                        <Slide
                        backgroundColor={item.backgroundColor}
                        width={slideSize.width}
                        height={slideSize.height}
                        >

                            {
                            slideCall === "Basic"
                            ?


                                <BasicImageSlide

                                    handle      = {(v) => handle(v)}
                                    databind    = {{

                                        info        :slideShowData.info,         
                                        item        :item,
                                        slideSize   :slideSize,
                                        slideNumber :slideNumber
                                        

                                    }}
                
                                    
                                />

                            :
                            slideCall === 'Liquid'
                            ?

                                <LiquidImageSlide

                                    handle      = {(v) => handle(v)}
                                    databind    = {{

                                        info        :slideShowData.info,         
                                        item        :item,
                                        slideSize   :slideSize,
                                        slideNumber :slideNumber
                                        

                                    }}
            
                                />


                            :
                            null
                            }
                        </Slide>


                    </div>
                    ))}

                </Layer>


            </SlideShow>

                   
        )


    }


}

export default Index;



