

const DistributeToColumns = (data,across) => {

    //alert(data+"\n"+across);
    //return data;

    function GetShortestColumn(ColData){

        var lowest;
        var colnum;
        var totals = [];
        var total;

        for(var z=0; z < ColData.length; z++)
        {
            total = 0;
            var col = ColData[z];
            for(var j=0; j < col.length; j++)
            {
                var box = col[j];
                total = total + parseInt(box.image.h);
     
            }//== j

           totals.push(total);

        }//== z

       
        for(var i=0; i < totals.length; i++)
        {
            if(i===0) 
            {
                lowest = totals[i];
                colnum = i;
            }else{

                if(lowest > totals[i])
                {
                    lowest = parseInt(totals[i]);
                    colnum = i;
                }
            }
      
        }//== z


        //console.log("COLNUM: "+colnum+"\nLOWEST: "+lowest);

        return colnum;

    }//func


    let i;
    const ColData = [];
    if(across && across !== undefined)
    {

        for(i=0; i < across; i++)
        {
            ColData.push([]);
        }

        for(i=0; i  < data.length; i++)
        {
            var box = data[i];
         
            if(i < across)
            {
                ColData[i].push(box);
            }else{
    
                var colnum = GetShortestColumn(ColData);
                ColData[colnum].push(box);
            }//==
    
    
        }//== i


    }

    //console.log(JSON.stringify("FIRST COL: "+ColData[0],null,2));
    //console.log(JSON.stringify("LAST COL: "+ColData[across-1],null,2));

    return ColData;


}//==


export default DistributeToColumns;

