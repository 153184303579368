
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0

*/



const Flyr = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "0 0 0 0",



    //bin
    binBackgroundColor:    props.binBackgroundColor || "transparent",//#fcfcfc

    binWidth:              props.binWidth || "96%", 
    binHeight:             props.binHeight || "auto",

    binMargin:            props.binMargin || "0 auto 0 auto",
    binPadding:           props.binPadding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    min-height: 500px;

    padding :${props => props.padding}; 
    margin  :${props => props.margin};


    position:absolute;   
    z-index:3;
    top:0;
    left:0;



    .Bin {

        background-color:${props => props.binBackgroundColor};

        width   :${props => props.binWidth};
        height  :${props => props.binHeight};

        padding :${props => props.binPadding}; 
        margin  :${props => props.binMargin};
    
        position:relative; 
      

    }


`;






export {

    Flyr

}
