export const STATIC_TIRES = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Front Tire*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M544.891,237.312c43.678,0.763,78.68,37.009,77.918,80.687s-37.008,78.68-80.688,77.918c-43.676-0.762-78.68-37.008-77.918-80.686 C464.967,271.551,501.213,236.549,544.891,237.312L544.891,237.312z"
    />
    {/*Rear Tire*/}   
    <path 
        fill="rgb(0,0,0)" 
        d="M181.273,237.312c43.678,0.763,78.68,37.009,77.918,80.687c-0.763,43.678-37.009,78.68-80.687,77.918 c-43.679-0.762-78.681-37.008-77.918-80.686C101.35,271.551,137.595,236.549,181.273,237.312L181.273,237.312z"
    />
    
    
    


    

		</svg>
		</div>

	);//return
}