// rec-calc-overhead-result-totals


const VPindirectLabor = {


    "name":"Indirect Labor",
    "value":{

        "idl_rate_yearly" : {

            "print"		:'price',
            "name"		:'Yearly Rate',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },  		
        
        "idl_rate_monthly" : {

            "print"		:'price',
            "name"		:'Monthly Rate',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "idl_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "idl_burden_ira_monthly" : {

            "print"		:'price',
            "name"		:'IRA Monthly (Burden)',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "idl_burden_tax_monthly" : {

            "print"		:'price',
            "name"		:'Tax Monthly (Burden)',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};



const VPidlEmployee = {


    "name": "New IDL",
    "value": {

        /*
        "idl_call":{//used for adding new = filter adds call stored

            "name": "Call",
            "print": "hidden",
            "default": "new",
            "value": "new",
            "required": false

        },
        */


        "idl_name": {
            "name": "Name",
            "print": "standard",
            "default": "",
            "value": "",
            "required": true
        },
        "idl_hourly_rate": {
            "name": "Hourly Rate",
            "print": "price",
            "default": 18.00,
            "value": 18,
            "required": true
        },
        "idl_burden_weekly_hours": {
            "name": "Weekly Hours Burden",
            "print": "standard",
            "default": 40,
            "value": 40,
            "required": true
        },
        "idl_vacation_weeks": {
            "name": "Vacation Weeks",
            "print": "standard",
            "default": 2,
            "value": 2,
            "required": true
        },
        "idl_health_insurance_monthly": {
            "name": "Health Insurance Monthly",
            "print": "price",
            "default": 200.00,
            "value": 200.00,
            "required": true
        },
        "idl_ira_percent": {
            "name": "IRA %",
            "print": "percent",
            "default": 2.0,
            "value": 2.0,
            "required": true
        },
        "idl_tax_comp_percent": {
            "name": "Tax Comp %",
            "print": "percent",
            "default": 20.0,
            "value": 20.0,
            "required": true
        }



    }





};




export {

    VPindirectLabor,
    VPidlEmployee

}

