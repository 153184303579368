
import { useWindowDimensions } from '../../WindowDimensionsProvider'



export function useLiqGrid(

    items, 
    info

) {

    //console.log("LiqGrid - info: "+JSON.stringify(info,null,2));
    //if(info.gridCall === 'flyr') console.log("items::::: "+JSON.stringify(items,null,2));

    const { width } = useWindowDimensions()



    //#############################################
    //#############################################
    //#############################################

    var refWidth;
    var maxWidth;

    refWidth = width;
    if(info.thresh.refWidth) 
    {
        refWidth = info.thresh.refWidth;
        maxWidth = refWidth;
    }



 
    var across;
    // var totalCells;

    // if(info.cells && info.cells !== undefined)
    // {
    //     if(info.cells.total && info.cells.total > (-1)) 
    //     {
    //         totalCells = info.cells.total;
    //     }

    //     if(info.cells.across && info.cells.across > (-1)) 
    //     {
    //         across = info.cells.across;

    //     }
    // }

    // console.log(across+" "+totalCells);




    var gridDif = 0;

    var gridWInt;
    var gridW;
    var gridMarl;

    var gutterSize;
  
    var columnWPerc;
    var columnWInt;
    var columnW;
    var columnMar;

    //var columnColors = ["red","blue","yellow","purple"];

    var cellWPerc;
    var cellWPercDif;
    var cellWInt;
    var cellW;
    var cellMar;

    var cellBorderDif; 
    var cellBorder;



    var containerWPerc;
    var containerWPercDif;
    var containerMar;
    var containerPad;


    var maskW;
    var maskH;
    var maskTop;
    var maskLeft;


    var thumbDif;

    var thumbWPerc;
    var thumbWPercDif;
    var thumbHPerc;

    var thumbWInt;
    var thumbW;
    var thumbHInt;
    var thumbH;
    var thumbMar;

    var thumbBorderThickness = 0;


    //var actualImgW;
    //var actualImgH;

    var imgW;
    var imgH;

    //var imgdims;




    //=============================================


    if(maxWidth)
    {

        gridWInt = maxWidth;
        gridW = gridWInt+"px";
        gridMarl = 0 + "px";

    }else{

        if(width < 1220)
        {
            gridWInt = (width - gridDif);
            gridW = gridWInt+"px";
            gridMarl = (gridDif/2) + "px";

        }else{

            //gridWInt = 1200;
            gridWInt = (width - gridDif);
            gridW = gridWInt+ "px";
            gridMarl = (width - gridWInt)/2 + "px";

        }//==

    }

 




    //==============================================

    if(!across)
    {

        across = 4;

        if(refWidth < info.thresh.across1)
        {
            across = 1;
        }
        else 
        if(refWidth < info.thresh.across2)
        {
            across = 2;
        }
        else 
        if(refWidth < info.thresh.across3)
        {
            across = 3;
        }


    }//==


    if(items.length === 2) 
    {
        across = 2;
        if(info.cellClass === "C") across = 1;
    }

    if(items.length === 1) across = 1;

    if(info.gridCall === 'flyr') 
    {
        across = 1;

    }//=

    //==============================================



    // Every 1px == .25% 
    // - Example width: 24.75% / 1px gutter 
    // - Example 2 width:24.5% / 2px gutter;


    gutterSize = 0;
    if(info.gutter.boolean)
    {
        gutterSize = info.gutter.size;
    }



    //==================================================

    columnWInt = (gridWInt/across) - gutterSize;

    if(info.gutter.boolean)
    {
        switch(across)
        {
        case 4:

            columnWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            columnWInt = columnWInt - (gutterSize/(across/3.2));
        break;
        case 3:

            columnWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            columnWInt = columnWInt - (gutterSize/(across/2.2));
        break;
        case 2:

            columnWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            columnWInt = columnWInt - (gutterSize/(across/1.2));
        break;
        default:
        break;
        }

    }
    columnW = (columnWInt) + "px";
    columnMar = "0 0 0 0";
    if(info.gutter.boolean) columnMar = "0 "+(gutterSize/2)+"px 0 "+(gutterSize/2)+"px";
    
  





    //===============================================

    cellBorderDif = 0;
    if(info.cellBorder.boolean) cellBorderDif = (info.cellBorder.thickness * 2)

    cellBorder = info.cellBorder.thickness +"px solid "+info.cellBorder.color;

    cellWInt = (gridWInt/across) - gutterSize;



    thumbDif = info.thumb.diff;
    thumbWPercDif = 0;

    switch(info.gridCall)
    {
    case'grid_column_cell':
    //case'grid_cell':

        cellWInt = columnWInt;
        //cellMar = "0 0 "+(gutterSize)+"px 0";

        cellMar = (0)+"px "+(gutterSize/2)+"px "+(gutterSize)+"px "+(gutterSize/2)+"px";

        containerMar = (0)+"px "+(0)+"px "+(gutterSize/2)+"px "+(gutterSize/3)+"px";




        if(info.cellBorder.boolean)
        { 
            cellWInt = cellWInt - (info.cellBorder.thickness * 2.0);

            thumbWInt = (cellWInt - thumbDif) - (thumbBorderThickness*2);
            thumbMar = (thumbDif/2)+"px 0 "+(thumbDif/2)+"px "+(thumbDif/2)+"px";



        }else{

            switch(info.thumb.call)
            {
            case'flex_height':

                thumbWInt = cellWInt;
                thumbMar = "0 0 0 0"; 
                
                thumbWPercDif = 0;
            break;
            default:
                //thumbWPercDif = 1.7;
            break;
            }

        }


    break;
    default:


        if(info.gutter.boolean)
        {

            switch(across)
            {
            case 4:
    
                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across/1.7));
            break;
            case 3:
    
                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across/1.2));
            break;
            case 2:
    
                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across*2));
            break;
            default:
            break;
            }


    
        }


    
        cellMar = "0 0 "+(gutterSize/2)+"px "+(gutterSize/2)+"px";

        //if(info.gridCall === 'filmstrip') cellMar = "0 0 "+(gutterSize/2)+"px "+(gutterSize)+"px";


        containerMar = (0)+"px "+(0)+"px "+(gutterSize/2)+"px "+(gutterSize/3)+"px";
        containerPad = (thumbDif/2)+"px "+(0)+"px "+(15)+"px "+(0)+"px";

        
    
        thumbWInt = cellWInt - (thumbDif/2) - (thumbBorderThickness*2);
        //thumbMar = "0 0 "+(thumbDif/2)+"px "+(thumbDif/2)+"px";
        //thumbMar = (thumbDif/2)+"px 0 0 "+(thumbDif/2)+"px";
        thumbMar = (0)+"px "+(0)+"px "+(thumbDif)+"px "+(thumbDif/2)+"px";



    break;
    }


    cellW = (cellWInt - cellBorderDif) + "px";

    //=================================================

    thumbW = thumbWInt + "px";
    thumbHInt = (64/100) * thumbWInt;
    if(info.call === 'vid') 
    {

        thumbHInt = (57/100) * thumbWInt;

    }
    thumbH = thumbHInt + "px";

    
    imgW = thumbWInt;
    imgH = thumbHInt;



    //=================================================

    columnWPerc = (columnWInt / gridWInt) * 100;
    if(columnWPerc % 1 !== 0) 
        columnWPerc = columnWPerc.toFixed(2);

    columnWPerc = columnWPerc + "%";


    switch(info.gridCall)
    {
    case'grid_column_cell':
    //case'grid_cell':

        cellWPerc = (cellWInt / columnWInt) * 100;
        if(cellWPerc % 1 !== 0) 
            cellWPerc = cellWPerc.toFixed(2);

        cellWPerc = cellWPerc + "%";

    break;
    default:

        cellWPerc = (cellWInt / gridWInt) * 100;
        if(cellWPerc % 1 !== 0) 
            cellWPerc = cellWPerc.toFixed(2);
    
        cellWPerc = cellWPerc + "%";
    
    break;
    }



    
    cellWPerc = 100;
    cellWPercDif = 0;

    if(info.gutter.boolean) 
        cellWPercDif = ( (parseFloat(info.gutter.size) ) * .20 );

    cellWPerc = cellWPerc - parseFloat(cellWPercDif);
    cellWPerc = cellWPerc.toFixed(2);
    cellWPerc = cellWPerc + "%";



    

    containerWPerc = 100;
    containerWPercDif = 0;

    if(info.gutter.boolean) 
        containerWPercDif = ( (info.gutter.size) * .20 );

    containerWPerc = containerWPerc - containerWPercDif;
    containerWPerc = containerWPerc.toFixed(2);
    containerWPerc = containerWPerc + "%";





    thumbWPerc = (thumbWInt / cellWInt) * 100;
    if(thumbWPerc % 1 !== 0) 
        thumbWPerc = thumbWPerc.toFixed(2);


    if(info.gridCall === 'grid_cell')
        thumbWPercDif = ( (info.thumb.diff/2) / thumbWInt ) * 100;

    thumbWPerc = thumbWPerc - thumbWPercDif;
    thumbWPerc = thumbWPerc.toFixed(2);
    thumbWPerc = thumbWPerc + "%";


    thumbHPerc = (thumbHInt / thumbWInt) * 100;
    if(thumbHPerc % 1 !== 0) 
        thumbHPerc = thumbHPerc.toFixed(2);

    thumbHPerc = thumbHPerc + "%";







    if(info.gridType === "ImageText") 
    {
        maskW = 100 + "%";

        //maskH = 100 + "%";
        //maskTop = 0 + "px";
        //maskLeft = 0 + "px"; 
        
        maskH = thumbHInt + "px";
        maskTop = (thumbDif/2) + "px";
        maskLeft = (thumbDif/2) + "px";

    }else{
        maskW = thumbWPerc;
        maskH = thumbHInt + "px";
        maskTop = (0) + "px";
        maskLeft = (thumbDif/2) + "px";
    }







    //#############################################
    //#############################################
    //#############################################

    const _VARS = {

        columnWPerc     : columnWPerc,
        cellWPerc       : cellWPerc,
        containerWPerc  : containerWPerc,
        thumbWPerc      : thumbWPerc,
        thumbHPerc      : thumbHPerc,

          
        info            :info,
        across          :across,
        maxWidth        :maxWidth,

        gridDif         :gridDif,

        gridWInt        :gridWInt,
        gridW           :gridW,
        gridMarl        :gridMarl,
    
    
        columnWInt      :columnWInt,
        columnW         :columnW,
        columnMar       :columnMar,

        cellWInt        :cellWInt,
        cellW           :cellW,
        cellMar         :cellMar,
    
        cellBorderDif   :cellBorderDif, 
        cellBorder      :cellBorder,


        maskW           :maskW,
        maskH           :maskH,
        maskTop         :maskTop,
        maskLeft        :maskLeft,

    
    
        thumbDif        :thumbDif,
        thumbWInt       :thumbWInt,
        thumbW          :thumbW,
        thumbHInt       :thumbHInt,
        thumbH          :thumbH,
        thumbMar        :thumbMar,
    

        imgW            :imgW,
        imgH            :imgH



    };










    //#############################################
    //#############################################
    //#############################################

    const R = {};

    R['grid'] = {

        "bgColor"       :"transparent",
        "width"         :gridW,
        "height"        :"auto",
        "margin"        :"10px 0 100px "+gridMarl

    };

    R['column'] = {

        "width"         :columnWPerc,
        "height"        :"auto",
        "margin"        :columnMar,

    };

    R['cell'] = {

        "bgColor"       :"lightcoral",

        "width"         :cellWPerc,
        "height"        :'auto',
        "margin"        :cellMar,

        "border"        :cellBorder,



        "container" : {

            "width"    :containerWPerc,
            "margin"   :containerMar,
            "padding"  :containerPad,
            "border"   :cellBorder

        },


        "mask" : {

            "width"     :maskW,
            "height"    :maskH,
            "top"       :maskTop,
            "left"      :maskLeft

        },


        "thumb" : {

            "width"    :thumbWPerc,
            "height"   :thumbH,
            "margin"   :thumbMar


        }


    };


    //console.log(JSON.stringify(R,null,2));
 





    //#############################################
    //#############################################
    //#############################################

    const RevData = [];

    for(var i=0; i < items.length; i++)
    {

        var box = items[i];

        imgW = box.image.w;
        imgH = box.image.h;



        //#####################################################

        //var mod_call = 'fill_div';

        var divWidth = thumbWInt;
        var divHeight = thumbHInt;

        var thumbWidth;
        var thumbHeight;
    
        //var decimal;
        //var h;
    

        var thumb_height;

        var img_width;
        var img_height;
    
        //var mod;
    
        var top_margin;
        var left_margin;
    
    
        var modHeight;
        var diff;                                           
        var modWidth; 
                        
        var remodWidth;
        var remodHeight;
    
        var h_diff;


       //########################################################

        modHeight = divHeight;
        diff = imgH / modHeight;                                           
        modWidth = imgW / diff; 
    
        remodWidth = divWidth;
        remodHeight = divHeight;

        //---------------------------------------

 
        switch(info.modCall)
        {
        case'fill_div':



            //################
            //GREATER THAN		
            //################

            if(modWidth > divWidth)
            {
                //alert('modWidth > divWidth');

                remodWidth = modWidth;
                diff = modWidth / remodWidth;                                           
                remodHeight = modHeight / diff; 


                //LESS THAN HEIGHT
                if(remodHeight < divHeight)
                {
                    //alert('remodHeight < divHeight');

                    h_diff = (divHeight - remodHeight);
                    remodHeight = remodHeight + h_diff;
                    remodWidth = remodWidth + h_diff;

                }//###

            }//############


            //###############
            //LESS THAN
            //###############

            if(modWidth < divWidth)
            {
                //alert('modWidth < divWidth');

                top_margin = 0;
                left_margin = 0;


                remodWidth = divWidth;
                diff = modWidth / remodWidth;                                           
                remodHeight = modHeight / diff;

            
                //LESS THAN HEIGHT
                if(remodHeight < divHeight)
                {
                    //alert('remodHeight < divHeight');

                    h_diff = (divHeight - remodHeight);
                    remodHeight = remodHeight + h_diff;
                }//###


            }//###########



            thumbWidth = remodWidth;
            thumbHeight = remodHeight;




            //##################
            //ADJUST VARS
            //##################

            //TOP MARGIN
            if(remodHeight > divHeight)
            {
                top_margin = (remodHeight - divHeight)/2;
                top_margin = -top_margin;

            }else{

                //var top_margin = 0;
                top_margin = (divHeight - thumbHeight)/2;

            }//###
                                
            //LEFT MARGIN
            if(remodWidth > divWidth)
            {
                left_margin = (remodWidth - divWidth)/2;
                left_margin = -left_margin;
            }else{
                left_margin = 0;
            }//###



        break;
        case'readjust':


            if(modWidth > divWidth)
            {
                //READJUST MOD SIZE
                remodWidth = divWidth;
                diff = modWidth / remodWidth;                                           
                remodHeight = modHeight / diff; 

                //TO SCALE
                thumbWidth = remodWidth;
                thumbHeight = remodHeight;


            }else{

                thumbWidth = modWidth;
                thumbHeight = modHeight;
            }//###




            top_margin = (divHeight - thumbHeight)/2;
            left_margin = 0;
            

        break;
        case "fitTo":

            thumbWidth = remodWidth;
            thumbHeight = remodHeight;


            top_margin = -50;
            left_margin = -150;

        break;
        default:
        break;
        }//==


        //-------------------------------------------

        img_width = Math.round(thumbWidth);
        img_height = Math.round(thumbHeight);


        top_margin = Math.round(top_margin);
        left_margin = Math.round(left_margin)

        //adjust_vars = mod_call+'|'+top_margin+'|'+left_margin;


        //mod = mod_call;
        //########################################################


    






        //########################################################

        if(info.thumb.call === "flex_height")
        {
            thumb_height = img_height;

            top_margin = 0;
            left_margin = 0;

        }else{

            thumb_height = thumbHInt;
        }

        //########################################################


        var img_width_perc;
        var img_height_perc;

        img_width_perc = (img_width / thumbWInt);
        img_height_perc = (img_height / thumbHInt);

        img_width_perc = (img_width_perc * 100);
        img_height_perc = (img_height_perc * 100);

        if(img_height_perc < 100) img_height_perc = 100;


        if(info.gridCall === 'flyr' || info.call === 'flex'
        )
        {
            if(imgH > imgW)
            {//tall
                img_height_perc = (imgH / imgW) * 100;
            }else{
                img_height_perc = (imgH / imgW) * 100;
            }
            
        }

        img_width_perc = img_width_perc.toFixed(2) + "%";
        img_height_perc = img_height_perc.toFixed(2) + "%";





        var top_margin_perc;
        var left_margin_perc;

        top_margin_perc = (top_margin / img_height);
        left_margin_perc = (left_margin / img_width);


        if(info.call === 'vid') 
        {
    
            top_margin_perc = top_margin_perc/2;
            //alert(top_margin_perc+"\n"+top_margin+"\n"+imgW+"\n"+imgH);
        }



        //console.log("ONE....\ntop perc: "+top_margin_perc+"\nleft perc: "+left_margin_perc);

        top_margin_perc = (top_margin_perc*100);
        left_margin_perc = (left_margin_perc*100);



    
        //console.log("TWO....\ntop perc: "+top_margin_perc+"\nleft perc: "+left_margin_perc);


        if(top_margin_perc < -5) top_margin_perc = top_margin_perc.toFixed(2) + "%";
        else top_margin_perc = "0%";

        if(left_margin_perc < -5) left_margin_perc = left_margin_perc.toFixed(2) + "%";
        else left_margin_perc = "0%";

        //console.log("THREE....\ntop perc: "+top_margin_perc+"\nleft perc: "+left_margin_perc);


        
        /*
        console.log("IMG AND THUMB...."
            +"\n thumbWInt: "+thumbWInt
            +"\n imgW: "+imgW
            +"\n imgH: "+imgH
            +"\n img_height_perc: "+img_height_perc
            +"\n img_height: "+img_height
            +"\n top_margin_perc: "+top_margin_perc
            +"\n left_margin_perc: "+left_margin_perc
        );
        */
        

        var video;
        video = false;

        if(info.call === 'vid' 
        && box.video 
        && box.video !== undefined) video = box.video;
      

        var title;
        var text;

        if(box.title 
        || box.title !== undefined) title = box.title;
      
        if(box.text 
        || box.text !== undefined) text = box.text;
          

        if(box.heading 
        || box.heading !== undefined) title = box.heading;
        
        if(box.pgraph 
        || box.pgraph !== undefined) text = box.pgraph;
            


        //########################################################

        RevData.push({

            position    :box.position,
    
            id          :box.id,
            title       :title,
            text        :text,
            bgColor     :box.bgColor,

            thumb:{

                w       :img_width+"px",
                h       :thumb_height+"px",

                //w       :img_width_perc,
                //h       :img_height_perc

            },

            image:{
        
                src     :box.image.src,
                w       :img_width,
                h       :img_height,
                top     :top_margin_perc,
                left    :left_margin_perc,

                maxWidth   :img_width_perc,
                maxHeight  :img_height_perc

            },

            video:video


        });


    }//== i





    //########################################################




    //########################################################

    switch(info.gridCall)
    {
    case'grid_column_cell':
    //case'grid_cell':

        if(RevData.length > 0)
        {
            var ColData = DistributeToColumns(RevData,across);
            R['data'] = ColData;
        }else{
            R['data'] = RevData;
        }


    break;
    default:

        R['data'] = RevData;
    
    break;
    }



    return {

        "vars"      : _VARS,
        "items"     : R
    };


}






function DistributeToColumns(data,across){

    //alert(data+"\n"+across);
    //return data;

    function GetShortestColumn(ColData){

        var lowest;
        var colnum;
        var totals = [];
        var total;

        for(var z=0; z < ColData.length; z++)
        {
            total = 0;
            var col = ColData[z];
            for(var j=0; j < col.length; j++)
            {
                var box = col[j];
                total = total + parseInt(box.image.h);
     
            }//== j

           totals.push(total);

        }//== z

       
        for(var i=0; i < totals.length; i++)
        {
            if(i===0) 
            {
                lowest = totals[i];
                colnum = i;
            }else{

                if(lowest > totals[i])
                {
                    lowest = parseInt(totals[i]);
                    colnum = i;
                }
            }
      
        }//== z


        //console.log("COLNUM: "+colnum+"\nLOWEST: "+lowest);

        return colnum;

    }//func




    const ColData = [];

    switch(across)
    {
    case 1:
        ColData.push([]);
    break;
    case 2:
        ColData.push([]);
        ColData.push([]);
    break;
    case 3:
        ColData.push([]);
        ColData.push([]);
        ColData.push([]);
    break;
    case 4:
        ColData.push([]);
        ColData.push([]);
        ColData.push([]);
        ColData.push([]);
    break;
    default:
    break;
    }//===



    for(var i=0; i  < data.length; i++)
    {
        var box = data[i];
     
        if(i < across)
        {
            ColData[i].push(box);
        }else{

            var colnum = GetShortestColumn(ColData);
            ColData[colnum].push(box);
        }//==


    }//== i


    //console.log(JSON.stringify("FIRST COL: "+ColData[0],null,2));
    //console.log(JSON.stringify("LAST COL: "+ColData[across-1],null,2));

    return ColData;


}//==



