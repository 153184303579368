import React, { 

    useState, 
    useEffect, 
    //useRef,
    //useMemo

} from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import { 
    GroupFixed,
    //GroupAbsolute, 
    Member 
} from './StylAttrs';


import HeaderBarA from './A';
import HeaderBarB from './B';
import HeaderBarC from './C';


const Index = (props) => {



    // -- redux -- //
    // const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    // //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- ref -- //
    // const refMain = useRef(null);
    // const refNav = useRef(null);
    // const refSubNav = useRef(null);

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);

    //const [groupWidth, setGroupWidth] = useState(props.databind.width);
    const [groupWidth, setGroupWidth] = useState("calc(100% - 15px)");
    const [groupTop, setGroupTop] = useState(props.databind.top);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            // var w = props.databind.width;
            // if(groupWidth !== w) setGroupWidth(w);
        
            // var top = props.databind.top;
            // if(groupTop !== top) setGroupTop(top);

        }


        var calc = "calc(100% - "+props.databind.offsetClientDif+"px)";
        if(groupWidth !== calc)
        {
            //alert(calc);
            setGroupWidth(calc);
        }

        // var w = props.databind.width;
        // if(groupWidth !== w) setGroupWidth(w);
    
        var top = props.databind.top;
        if(groupTop !== top) setGroupTop(top);



        //console.log("databind: "+Object.keys(props.databind));
    

    },[
    
        props,
        isLoaded,
        groupWidth,
        groupTop

    ])

    



    let headerCall = props.databind.headerCall;
    // if(props.databind.settings.appearance)
    // {
    //     if(props.databind.settings.appearance.header)
    //     {
    //         headerCall = props.databind.settings.appearance.header.headerCall;
    //     }
    // }

    // if(props.databind.primeProps)
    // {
    //     console.log("has primeProps")
    // }




    return (


        // <div></div>
        
        <GroupFixed
        id={"fixedHeaderBar"}
        width={groupWidth}
        top={groupTop}
        >

            {
            headerCall === 'A'
            ?
                <Member>

                    <HeaderBarA 
                
                        databind={props.databind}
               
                    /> 

                </Member>
         
            :
            headerCall === 'B'
            ?
                <Member>

                    <HeaderBarB 
                        
                        databind={props.databind}

                    /> 

                </Member>
         
            :
            headerCall === 'C'
            ?
                <Member>

                    <HeaderBarC
                    
                        databind={props.databind}

                    /> 

                </Member>
         
            :
            null
            }

        </GroupFixed>


    );






}

export default Index



