
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0


    backgroundColor :"lightyellow",
    width           :"100%",
    height          :"500px",
    margin          : "10px 0 0 0"



*/





const Print = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "10px auto 0 auto"


}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    min-height: 400px;

    padding :${props => props.padding}; 
    margin  :${props => props.margin};


    position:relative;   
   


`;




const Grid = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "96%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "20px auto 0 auto"


}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    min-height: 400px;

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:relative;   
   

`;



    const Cell = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc

        width:              props.width || "100%", 
        height:             props.height || "auto",

        padding:            props.padding || "10px 0 10px 0",
        margin:             props.margin || "0 0 10px 0",

        border:            props.border || "1px solid black",
        borderRadius:      props.borderRadius || "4px",


        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight || "bold",
        fontColor:          props.fontColor || "black",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",


    }))`

        background-color:${props => props.backgroundColor};

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        position:relative; 
        float:left;  

        border :${props => props.border}; 
        border-radius  :${props => props.borderRadius};

        cursor:pointer;

        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.fontColor};
        line-height: 1.3;


    `;




    const Row = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "#ffffff",//#fcfcfc

        width:              props.width || "100%", 
        height:             props.height || "auto",

        padding:            props.padding || "0 0 0 0",
        margin:             props.margin || "0 0 10px 0",


    }))`

        background-color:${props => props.backgroundColor};

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        position:relative; 
        float:left;  

    `;












export {

    Print,
    Grid,
    Cell,
    Row

}
