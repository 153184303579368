//------------------------------------------------
//https://medium.com/@rezaduty/how-to-write-secure-code-in-react-937579011d3c

import dompurify from "dompurify";


//const sanitizer = dompurify.sanitize;

// import dompurify from 'dompurify';

// function MyComponent() {
//   const title = response.from.backend.title;
//   const sanitizer = dompurify.sanitize;
  
//   return <div dangerouslySetInnerHTML={{__html: sanitizer(title)}} />; // Good
// }


// DOMPurify.sanitize('<img src=x onerror=alert(1)//>'); // becomes <img src="x">
// DOMPurify.sanitize('<svg><g/onload=alert(2)//<p>'); // becomes <svg><g></g></svg>




//------------------------------------------------


const CreateLink = (props) => {


    const sanitizer = dompurify.sanitize;


    //var linkName = props.name;
    var linkValue = props.value;

    var html = '';
    var sanitized_html = '';
    //var txtColor = 'black';

    html += linkValue;

    sanitized_html = sanitizer(html);

    // console.log("RAW: "+html);
    // console.log("SANITIZED: "+sanitized_html);

    return sanitized_html;

}

export default CreateLink;