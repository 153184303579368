


const SceneInfo = [


    //##########################################################
    //##########################################################
    //##########################################################

    // SVGboard


    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0",
            "marginBottom": "0px"
        },
        "call": "SVGboard",
        "info": {
            "call": "board",//single or multi
            "gridType": "Fixed",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": []

    }


]

export default SceneInfo;