import React, { 

    // useState, 
    // useEffect, 
    // //useRef 

} from 'react';

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import StylColumn from '../StylModules/Column.module.css';

import CreateMarkup from 'utils/components/Universal/CreateMarkup';
//import CreateLink from 'utils/components/Universal/CreateLink';



const Newspaper = (props) => {

    const items = props.items;
    const columnClass = props.columnClass;

    return (

        <div
        className={StylColumn.TextColumns}
        >

            {
            columnClass === 'page' ?


                <div
                className={StylColumn.Page}
                >

                    {items.map((item, i) => (

                        <div
                        key={i}
                        >
    
                            <div 
                            className={StylColumn.Headline}
                            >

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </div>


                            <div 
                            className={StylColumn.Newspaper}
                            >

                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />


                            </div>


                        </div>


                    ))}



                </div>



            :// === FULL ===



                <div
                className={StylColumn.Full}
                >

                    {items.map((item, i) => (

                        <div
                        key={i}
                        >
    
                            <div 
                            className={StylColumn.Headline}
                            >

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </div>


                            <div 
                            className={StylColumn.Newspaper}
                            >

                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />


                            </div>


                        </div>


                    ))}


                </div>

            }

        </div>




    )


}

export default Newspaper;
