
import React from 'react';

import Loader from 'utils/components/Process/Loader';


export const FSloading = (props) => {


    let data = {

        isError: false,
        errorType: 'process',
        isTimed: true,
        items:[ 

            "SUCCESS: Process was successfull!!"

        ]

    };

    return ( 


        <div>

            <Loader 

                handle={(v) => props.handle(v)}
                data={data}
            
            />

        </div>

    );



}





