
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;



    
    position:absolute;   
    z-index:3;
    top:0;
    left:0;
    







*/

const Messngr = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc
    border:             props.border || "1px solid #fcfcfc",
    borderRadius:       props.borderRadius || "10px",

    width:              props.width || "375px", 
    height:             props.height || "auto",

    padding:            props.padding|| "20px 20px 20px 20px",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "3",
    top:                props.top || "calc(50% - 175px)",
    left:               props.left || "calc(50% - 210px)"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:12px;

    max-width: 375px;
    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};
      

`;




const Title = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightgreen",//#fcfcfc
    border:             props.border || "1px solid transparent",
    borderRadius:       props.borderRadius || "2px",

    width:              props.width || "100%", 
    height:             props.height || "auto",

    margin:            props.margin || "0 0 10px 0",
    padding:           props.padding || "10px 0 10px 0",

    color:             props.color || "black"



}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:relative;  
    float:left; 

    font-family:Verdana, Geneva, sans-serif;
    font-size:18px;
    font-weight:normal;
    color:${props => props.color};
    text-align:center;


`;









const Message = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",//#fcfcfc
    border:             props.border || "1px solid #454d5f",
    borderRadius:       props.borderRadius || "2px",

    width:              props.width || "96%", 
    height:             props.height || "auto",
    maxHeight:          props.maxHeight || "100px",

    margin:            props.margin || "0 0 0 0",
    padding:           props.padding || "0 2% 15px 2%",



}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    max-width: 375px;
    min-height: ${props => props.maxHeight};

    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:relative;  
    float:left; 

    font-family:Verdana, Geneva, sans-serif;
    font-size:16px;
    font-weight:normal;
    color:#000;
    text-align:center;


`;



const Button = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",//#fcfcfc
    border:             props.border || "1px solid white",
    borderRadius:       props.borderRadius || "10px",

    width:              props.width || "100px", 
    height:             props.height || "auto",

    padding:            props.padding || "10px 0 9px 0",
    margin:             props.margin || "20px 0 0px calc(50% - 50px)",

    color:              props.color || "white"


}))`

    background-color:${props => props.backgroundColor};




    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};
    position:relative;
    float:left;

    font-size: 16px;
    font-weight:500;
    text-align:center;
    color:${props => props.color};

    border-radius:7px;

    cursor:pointer;


`;




export {

    Messngr,
    Title,
    Message,
    Button  

}
