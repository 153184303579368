export const BUTTON_MAIN_TIRES = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Tires*/}


    <path 
        fill="rgb(150,150,150)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.609,297.225l81.729-11.608v-43.772l-82.087,11.651v38.798	C180.25,293.962,180.373,295.608,180.609,297.225L180.609,297.225z M262.337,332.25v-42.441l-80.879,11.483	c4.84,17.732,23.641,30.958,45.919,30.958H262.337L262.337,332.25z M324.52,332.25V197.661l-48.521,6.165v3.199l20.553,2.871 l-6.957,5.16l-13.596-1.77v39.938l32.007,5.809l-6.957,5.161l-25.051-4.675v42.847l20.553,2.871l-6.957,5.161l-13.596-1.77v23.62 H324.52L324.52,332.25z M378.269,332.25V190.832l-40.089,5.094V332.25H378.269L378.269,332.25z M435.383,332.25v-35.028 l-12.693-4.438v-6.158l12.693,4.438V203.35l-12.693-4.438v-6.158l12.693,4.438v-13.617l-45.307,5.757v33.645l13.825,4.884v6.158 l-13.825-4.883v87.713l13.825,4.883v6.159l-13.825-4.884v9.244H435.383L435.383,332.25z M180.25,249.303l82.087-11.65v-32.091	l-82.087,10.431V249.303L180.25,249.303z M440.249,332.25h52.873c18.323,0,34.29-8.986,42.076-22.018H507.93v-5.55h29.983 c1.514-3.905,2.337-8.07,2.337-12.39v-75.951h-32.32v-5.551h32.32V170.25l-100.001,12.706v80.349l100.001-11.797v6.085 l-100.001,11.796V332.25L440.249,332.25z"
    />
    





    

		</svg>
		</div>

	);//return
}