export const BUTTON_OFF = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M360,112.125c77.041,0,139.875,62.834,139.875,139.875	S437.041,391.875,360,391.875c-77.042,0-139.875-62.834-139.875-139.875S282.958,112.125,360,112.125L360,112.125z M417.475,336.399L275.6,194.525c-11.208,16.394-17.778,36.19-17.778,57.475c0,56.278,45.9,102.178,102.178,102.178
		C381.284,354.178,401.08,347.608,417.475,336.399L417.475,336.399z M302.525,167.6l141.875,141.875	c11.21-16.393,17.778-36.19,17.778-57.475c0-56.277-45.9-102.178-102.178-102.178C338.715,149.822,318.918,156.391,302.525,167.6 L302.525,167.6z"
    />
    





    

		</svg>
		</div>

	);//return
}