import React from 'react';

import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';

import Loader from 'utils/components/Process/Loader';

export const FSloading = (props) => {
    
    const portalTarget = usePortal("stage");

    let data = {

        isError: false,
        errorType: 'process',
        isTimed: true,
        items:[ 

            "SUCCESS: Process was successfull!!"

        ]

    };

    return createPortal(<div>

        <Loader 

            handle={(v) => props.handle(v)}
            data={data}
        
        />

    </div>, portalTarget);

}


