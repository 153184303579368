export const REPORTS = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M178.571,308.819H321.43v15.75H178.571V308.819L178.571,308.819z M178.571,270.407H321.43v15.75H178.571V270.407L178.571,270.407z M178.571,231.996H321.43v15.749H178.571V231.996L178.571,231.996	z M178.571,193.583H321.43v15.75H178.571V193.583L178.571,193.583z M178.571,155.171h86.208v15.749h-86.208V155.171	L178.571,155.171z M134.387,100.381h152.662l78.564,78.567v220.67H134.387V100.381L134.387,100.381z M108.182,72.571h283.637 v354.857H108.182V72.571L108.182,72.571z"
			/>



		</svg>
		</div>

	);//return
	




}