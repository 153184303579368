


const SceneInfo = [










    //##########################################################
    //##########################################################
    //##########################################################

    // SlideShow


    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0",
            "marginBottom": "50px"
        },
        "call": "SlideShow",
        "info": {
            "call": "collage",//basic or collage
            "gridType": "Fixed",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "cells": {
                "call": "default",
                "total": 1,
                "across": 1
            },
            "thumb": {
                "call": "default",
                "diff": "0",
                "bgColor": "black"
            },
            "cellBorder": {
                "boolean": false,
                "thickness": 0,
                "color": "lightgray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },
            "mask": {
                "boolean": false,
                "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                }
            },
            "opacity": 1,
            "webkitFilter": "grayscale(0%)",
            "filter": "grayscale(0%)",
            "view": "fullScreen",
            "viewHeight": "613px",
            "thumbHInt": 723
        },
        "items": [



            {
                "image": {
                    "h": "400",
                    "src": "/_resources/images/engine.jpg",
                    "w": "640"
                },
                "id": "20200406238952",
                "text": "",
                "title": "3483addfile",
                "backgroundColor": "black"
            },





            {
                "image": {
                    "h": "400",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3483addfile_sys20200406238952id_.jpg",
                    "w": "640"
                },
                "id": "20200406238952",
                "text": "",
                "title": "3483addfile",
                "backgroundColor": "black"
            },


            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3489addfile_sys20200406240792id_.jpg",
                    "w": "640"
                },
                "id": "20200406240792",
                "text": "",
                "title": "3489addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "560",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3ec30de69c622daf0acfa08fa7244657_sys20200427149343id_.jpeg",
                    "w": "840"
                },
                "id": "20200427149343",
                "text": "",
                "title": "3ec30de69c622daf0acfa08fa7244657",
                "backgroundColor": "black"
            },
            {
                "image": {

                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/4353addfile_sys20200406238384id_.jpg",
                    "w": "640"
                },
                "id": "20200406238384",
                "text": "",
                "title": "4353addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/4907addfile_sys20200406239278id_.jpg",
                    "w": "640"
                },
                "id": "20200406239278",
                "text": "",
                "title": "4907addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "480",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/5693addfile_sys20200406234756id_.jpg",
                    "w": "640"
                },
                "id": "20200406234756",
                "text": "",
                "title": "5693addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/5992addfile_sys20200406242443id_.jpg",
                    "w": "640"
                },
                "id": "20200406242443",
                "text": "",
                "title": "5992addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/7181addfile_sys20200406239152id_.jpg",
                    "w": "640"
                },
                "id": "20200406239152",
                "text": "",
                "title": "7181addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/7267addfile_sys20200406236133id_.jpg",
                    "w": "640"
                },
                "id": "20200406236133",
                "text": "",
                "title": "7267addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8295addfile_sys20200406241565id_.jpg",
                    "w": "640"
                },
                "id": "20200406241565",
                "text": "",
                "title": "8295addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "360",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                    "w": "640"
                },
                "id": "20200406242958",
                "text": "",
                "title": "8773addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "384",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8790addfile_sys20200406234539id_.jpg",
                    "w": "640"
                },
                "id": "20200406234539",
                "text": "",
                "title": "8790addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "1400",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/ferrari-1_sys20200407008911id_.jpg",
                    "w": "2240"
                },
                "id": "20200407008911",
                "text": "",
                "title": "ferrari-1",
                "backgroundColor": "black"
            },


            {
                "image": {
                    "h": "640",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
                    "w": "427"
                },
                "id": "20200406239837",
                "text": "",
                "title": "2139addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "424",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2657addfile_sys20200406237501id_.jpg",
                    "w": "640"
                },
                "id": "20200406237501",
                "text": "",
                "title": "2657addfile",
                "backgroundColor": "black"
            },

            

        ]

    },





    //##########################################################
    //##########################################################
    //##########################################################

    // SlideShow


    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0",
            "marginBottom": "50px"
        },
        "call": "SlideShow",
        "info": {
            "call": "basic",//basic or collage
            "gridType": "Fixed",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "cells": {
                "call": "default",
                "total": 1,
                "across": 1
            },
            "thumb": {
                "call": "default",
                "diff": "0",
                "bgColor": "black"
            },
            "cellBorder": {
                "boolean": false,
                "thickness": 0,
                "color": "lightgray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },
            "mask": {
                "boolean": false,
                "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                }
            },
            "opacity": 1,
            "webkitFilter": "grayscale(0%)",
            "filter": "grayscale(0%)",
            "view": "fullScreen",
            "viewHeight": "613px",
            "thumbHInt": 723
        },
        "items": [



            {
                "image": {
                    "h": "400",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3483addfile_sys20200406238952id_.jpg",
                    "w": "640"
                },
                "id": "20200406238952",
                "text": "",
                "title": "3483addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3489addfile_sys20200406240792id_.jpg",
                    "w": "640"
                },
                "id": "20200406240792",
                "text": "",
                "title": "3489addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "560",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3ec30de69c622daf0acfa08fa7244657_sys20200427149343id_.jpeg",
                    "w": "840"
                },
                "id": "20200427149343",
                "text": "",
                "title": "3ec30de69c622daf0acfa08fa7244657",
                "backgroundColor": "black"
            },
            {
                "image": {

                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/4353addfile_sys20200406238384id_.jpg",
                    "w": "640"
                },
                "id": "20200406238384",
                "text": "",
                "title": "4353addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/4907addfile_sys20200406239278id_.jpg",
                    "w": "640"
                },
                "id": "20200406239278",
                "text": "",
                "title": "4907addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "480",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/5693addfile_sys20200406234756id_.jpg",
                    "w": "640"
                },
                "id": "20200406234756",
                "text": "",
                "title": "5693addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/5992addfile_sys20200406242443id_.jpg",
                    "w": "640"
                },
                "id": "20200406242443",
                "text": "",
                "title": "5992addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/7181addfile_sys20200406239152id_.jpg",
                    "w": "640"
                },
                "id": "20200406239152",
                "text": "",
                "title": "7181addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/7267addfile_sys20200406236133id_.jpg",
                    "w": "640"
                },
                "id": "20200406236133",
                "text": "",
                "title": "7267addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "426",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8295addfile_sys20200406241565id_.jpg",
                    "w": "640"
                },
                "id": "20200406241565",
                "text": "",
                "title": "8295addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "360",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8773addfile_sys20200406242958id_.jpg",
                    "w": "640"
                },
                "id": "20200406242958",
                "text": "",
                "title": "8773addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "384",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/8790addfile_sys20200406234539id_.jpg",
                    "w": "640"
                },
                "id": "20200406234539",
                "text": "",
                "title": "8790addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "1400",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/ferrari-1_sys20200407008911id_.jpg",
                    "w": "2240"
                },
                "id": "20200407008911",
                "text": "",
                "title": "ferrari-1",
                "backgroundColor": "black"
            },


            {
                "image": {
                    "h": "640",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
                    "w": "427"
                },
                "id": "20200406239837",
                "text": "",
                "title": "2139addfile",
                "backgroundColor": "black"
            },
            {
                "image": {
                    "h": "424",
                    "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2657addfile_sys20200406237501id_.jpg",
                    "w": "640"
                },
                "id": "20200406237501",
                "text": "",
                "title": "2657addfile",
                "backgroundColor": "black"
            },

            

        ]

    }






]

export default SceneInfo;