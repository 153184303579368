import styled from "styled-components";


export const MainSide = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 0 0",
    padding:            props.padding  || "0 0 0 0",

    sideWidth:          props.sideWidth || "50%",
    textFromTop:        props.textFromTop || "0px",

    imgWidth:           props.imgWidth || "100%",
    imgHeight:          props.imgHeight || "auto",



    //width:${props => props.sideWidth};
    //margin-right:${props => props.gutterSize};



}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};
    padding:${props => props.padding};

    display:flex;
    align-items: left;


    overflow:hidden;


    .Main {

        background-color:transparent;
        height:auto;
        min-height:300px;
        flex-basis: calc(100% - ${props => props.sideWidth});  
    }

        .Main .Container {


            background-color:transparent;

            width:90%;
            height:auto;

            padding:0 10% 0 0;
            margin:${props => props.textFromTop} 0 0 0;

            position:relative;
            float:left;

        }


    .Side {

        background-color:transparent;
        width:${props => props.sideWidth};
        height:auto;
        min-height:300px;

    }



    .Img {

        width:${props => props.imgWidth};
        height:${props => props.imgHeight};
  
    }




    @media (max-width: 1366px) {

        background-color:transparent;
        width: 98%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 100px 1%;
    
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
 

    }
        

    @media (max-width: 900px) {


        background-color:transparent;
        width: 96%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 100px 2%;
    
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    

            .Main {

                margin:0 0 20px 0;
                height:auto;
                min-height:300px;

                flex: 1 1 500px;/*Stretching*/
            }


                .Main .Container {

                    background-color:transparent;
        
                    width:100%;
                    height:auto;
        
                    padding:0 0 0 0;
                    margin:0 0 0 0;
        
                    position:relative;
                    float:left;
        
                }


            .Side {

                margin:0;
                height:auto;
                min-height:300px;
                flex: 1 1 500px;/*Stretching*/

            }

    }



`;






export const SideMain = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 0 0",
    padding:            props.padding  || "0 0 0 0",

    sideWidth:          props.sideWidth || "50%",
    textFromTop:        props.textFromTop || "0px",

    imgWidth:           props.imgWidth || "100%",
    imgHeight:          props.imgHeight || "auto",


}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};
    padding:${props => props.padding};

    display:flex;
    align-items: left;


    overflow:hidden;



    .Side {

        background-color:transparent;
        width:${props => props.sideWidth};
        height:auto;
        min-height:300px;

    }

    .Main {

        background-color:transparent;
        height:auto;
        min-height:300px;
        flex-basis: calc(100% - ${props => props.sideWidth});  
    }

        .Main .Container {

            width:95%;
            height:auto;

            padding:0 0 0 5%;
            margin:${props => props.textFromTop} 0 0 0;

            position:relative;
            float:left;

        }


    .Img {

        width:${props => props.imgWidth};
        height:${props => props.imgHeight};
  
    }






    @media (max-width: 1366px) {

        background-color:transparent;
        width: 98%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 100px 1%;
    
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
 

    }
        

    @media (max-width: 900px) {


        background-color:transparent;
        width: 96%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 100px 2%;
    
        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    

            .Side {

                margin:0;
                height:auto;
                min-height:300px;
                flex: 1 1 500px;/*Stretching*/

            }

            .Main {

                margin:0 0 20px 0;
                height:auto;
                min-height:300px;

                flex: 1 1 500px;/*Stretching*/
            }


                .Main .Container {

                    width:100%;
                    height:auto;
        
                    padding:0 0 0 0;
                    margin:15px 0 0 0;
        
                    position:relative;
                    float:left;
        
                }



    }



`;








export const Heading = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "0 0 15px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "32px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"

}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


    line-height: 1.0;

`;

export const Pgraph = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin  || "15px 0 25px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "normal",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    margin:${props => props.margin};

    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    line-height: 1.5;

`;






