export const BUTTON_THEME_GRAY_S2R_BERLINA_BLACK = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*S2-R Logo*/}
    
    <path 
        fill="rgb(0,0,0)" 
        d="M150.338,163.847h419.324c23.587,0,42.885,19.298,42.885,42.886v90.535	c0,23.587-19.298,42.886-42.885,42.886H150.338c-23.587,0-42.885-19.299-42.885-42.886v-90.535
		C107.453,183.145,126.751,163.847,150.338,163.847L150.338,163.847z"
    />
    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M331.631,235.273l25.557,0.047c2.799,0,4.046,1.664,4.046,3.258 v2.395c0,1.595-1.247,3.258-4.046,3.258h-21.223h-44.457c-10.44,0-15.336,6.48-15.336,15.553c0,8.153,0.001,16.308,0.001,24.462 l104.136-0.062v-15.549l-81.018,0.057c-2.799,0-4.047-1.662-4.047-3.257v-2.395c0-1.594,1.248-3.257,4.047-3.257h33.225h32.456	c10.439,0,15.336-6.48,15.336-15.553v-8.91c0-9.072-4.896-15.553-15.336-15.553h-41.7l-31.26-0.012
		c-10.44,0-15.335,6.479-15.335,15.553v3.264l19.071-0.006c0-1.595,1.248-3.258,4.047-3.258L331.631,235.273L331.631,235.273z M388.41,248.598h26.144v8.99H388.41V248.598L388.41,248.598z M223.158,259.783h21.223c2.8,0,4.048,1.663,4.048,3.257v2.395	c0,1.595-1.248,3.257-4.048,3.257L162,268.738v15.507h90.163c10.441,0,15.337-6.48,15.337-15.554v-8.908 c0-9.072-4.896-15.553-15.337-15.553h-32.455h-33.225c-2.799,0-4.046-1.663-4.046-3.258v-2.395c0-1.594,1.247-3.258,4.046-3.258 h79.867v-15.553h-87.648c-10.441,0-15.337,6.48-15.337,15.553v8.91c0,9.072,4.896,15.553,15.337,15.553H223.158L223.158,259.783z"
    />
    <path 
        fill="rgb(200,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M450.198,248.648V235.32h65.854c3.665,0,6.664,3,6.664,6.664l0,0 c0,3.665-2.999,6.664-6.664,6.664H450.198L450.198,248.648z M428.278,219.768c0,0,87.903,0,98.193,0 c10.289,0,20.11,2.197,20.11,22.137c0,19.94-17.186,19.94-20.781,19.94l32.199,22.4h-37.283l-30.586-22.4h-39.933v22.4h-21.92 V219.768L428.278,219.768z"
    />
    

    

		</svg>
		</div>

	);//return
}