//------------------------------------------------
//https://medium.com/@rezaduty/how-to-write-secure-code-in-react-937579011d3c

import dompurify from "dompurify";


// html = DOMPurify.sanitize(html,{
//     // ALLOWED_TAGS: ['p', '#text'], // only <P> and text nodes
//     // KEEP_CONTENT: false, // remove content from non-allow-listed nodes too
//     ADD_ATTR: ['onclick'], // permit kitty-litter attributes
//     // ADD_TAGS: ['ying', 'yang'], // permit additional custom tags
//     // RETURN_DOM: true, // return a document object instead of a string
// });



// dompurify.addHook('afterSanitizeAttributes', function (node) {

// 	// set all elements owning target to target=_blank
// 	if('target' in node)
// 	{
//         // console.log('dompurify node Object ---- '+Object.prototype.toString.call(node));
//         // console.log('dompurify node href ---- '+node.href);

//         let regxSite = new RegExp("theprofitcalculator.com","gi");
//         if(!node.href.match(regxSite))
//         {
//             node.setAttribute('target', '_blank');
//             node.setAttribute('rel', 'noopener');
//         }

// 	}

// });

//const sanitizer = dompurify.sanitize;

// import dompurify from 'dompurify';

// function MyComponent() {
//   const title = response.from.backend.title;
//   const sanitizer = dompurify.sanitize;
  
//   return <div dangerouslySetInnerHTML={{__html: sanitizer(title)}} />; // Good
// }


// DOMPurify.sanitize('<img src=x onerror=alert(1)//>'); // becomes <img src="x">
// DOMPurify.sanitize('<svg><g/onload=alert(2)//<p>'); // becomes <svg><g></g></svg>


/*

	<a href="https://examplepetstore.com" target="_blank" rel="noopener">
		Example Pet Store
	</a>

	rel="noopener" prevents the new page from being able to access the window.opener property and ensures it runs in a separate process.
	rel="noreferrer" has the same effect but also prevents the Referer header from being sent to the new page. See Link type "noreferrer".




    var href = window.location.href;                //returns the href (URL) of the current page
    var host = window.location.host;                //returns the domain with port number
    var hostname = window.location.hostname;        //returns the domain name of the web host
    var pathname = window.location.pathname;        //returns the path and filename of the current page
    var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
    var assign = window.location.assign;     



*/





const CreateMarkup = (value,call,name) => {


    dompurify.addHook('afterSanitizeAttributes', function (node) {

        // set all elements owning target to target=_blank
        if('target' in node)
        {
            // console.log('dompurify node Object ---- '+Object.prototype.toString.call(node));
            // console.log('dompurify node href ---- '+node.href);
            // console.log("window.location.hostname: "+window.location.hostname);

            //let regxSite = new RegExp(window.location.hostname,"gi");
            let regxSite = new RegExp("theprofitcalculator.com","gi");
            if(!node.href.match(regxSite)
            && !node.href.match(/#/i)
            )
            {
                node.setAttribute('target', '_blank');
                //node.setAttribute('rel', 'noopener');
                node.setAttribute('rel', 'noreferrer');
            }
    
        }
    
    });

    const sanitizer = dompurify.sanitize;

    var html = '';
    var sanitized_html = '';
    //var txtColor = 'black';



    //console.log(".............."+value);




    var problem_text = '';
    // problem_text += '<img src=x onerror=alert(1)//>';
    // problem_text += '<svg><g/onload=alert(2)//<p>';

    switch(call)
    {
    case'anchor':

        html += '<a';
            html += ' href="'+value+'"';
            html += ' target="_blank"';
            html += ' rel="noreferrer"'
        html += '>'

            html += name;

        html += '</a>';


    break;
    default:

        // html += '<span ';
        //     html += 'style="';
        //     //html += 'color:'+txtColor+';';
        // html += '">'
        
        //     html += value;
        //     html += problem_text;
        
        // html += '</span>';


        // html += '<div ';
        //     //html += 'style="';
        //     //html += 'color:'+txtColor+';';
        // html += '">'
        
        //     html += value;
        //     html += problem_text;
        
        // html += '</div>';



        // let regxAnchorClass = new RegExp('class="Anchor"',"gi");
        // if(value.match(regxAnchorClass)
        // )
        // {
        //     value = value.replace(regxAnchorClass,'className="Anchor"');
        //     //console.log("CreateMarkup...."+value);
        // }

        // let regxOnClick = new RegExp('onclick=',"gi");
        // if(value.match(regxOnClick)
        // )
        // {
        //     //value = value.replace(regxOnClick,'className="Anchor"');
        //     console.log("CreateMarkup...."+value);
        //     alert('has onclick');
        // }


        html += value;
        html += problem_text;
 


    }

    sanitized_html = sanitizer(html,{

        // ALLOWED_TAGS: ['p', '#text'], // only <P> and text nodes
        // KEEP_CONTENT: false, // remove content from non-allow-listed nodes too
        ADD_ATTR: ['onclick'], // permit kitty-litter attributes
        // ADD_TAGS: ['ying', 'yang'], // permit additional custom tags
        // RETURN_DOM: true, // return a document object instead of a string
    });



    // console.log("RAW: "+html);
    //console.log("SANITIZED: "+sanitized_html);


    return {__html: sanitized_html};

}

export default CreateMarkup;