

import styled from "styled-components";




/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;


*/

const LoaderBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "1px solid #fcfcfc",
    borderRadius:       props.borderRadius || "10px",

    width:              props.width || "100px", 
    height:             props.height || "auto",

    padding:            props.padding|| "25px 0 25px 0",
    margin:             props.margin || "0 0 0 0",

    top:                props.top || "calc(50% - 100px)",
    left:               props.left || "calc(50% - 50px)"


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:12px;

    max-width: 200px;
    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:absolute;   
    z-index:3;
    top:${props => props.top};
    left:${props => props.left};
      

`;



export {

    LoaderBox,

}
