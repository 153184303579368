export const BUTTON_LOGO_FONDMETAL = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    
    {/*Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M588.643,278.241v-52.482h20.173v36.649H630v15.833H588.643L588.643,278.241z M527.537,278.241l19.755-52.482h22.407l18.431,52.482h-19.135l-1.516-5.147h-19.387l-1.632,5.147H527.537L527.537,278.241z M554.747,256.83h6.173l-2.992-11.827L554.747,256.83L554.747,256.83z M445.523,278.241v-52.482h41.907v15.354h-23.415v2.809 h20.974v13.911h-20.974v4.415h24.179v15.994H445.523L445.523,278.241z M405.777,278.241l-4.986-14.587v14.587h-18.59v-52.482 h23.436l5.995,15.868l5.994-15.868h23.435v52.482h-18.589v-14.587l-4.985,14.587H405.777L405.777,278.241z M328.355,225.687h25.953 c8.403,0,14.795,2.24,19.197,6.722c4.402,4.482,6.598,11.022,6.598,19.61c0,8.563-2.187,15.079-6.57,19.537 c-4.384,4.457-10.785,6.686-19.225,6.686h-25.953V225.687L328.355,225.687z M346.136,241.494v20.019h6.104 c3.18,0,5.461-0.786,6.835-2.349c1.382-1.564,2.068-4.122,2.068-7.675c0-3.533-0.694-6.082-2.09-7.646 c-1.389-1.563-3.663-2.35-6.813-2.35H346.136L346.136,241.494z M274.521,278.241v-52.482h19.387l11.409,17.102v-17.102h18.35	v52.482h-17.876l-12.519-17.001v17.001H274.521L274.521,278.241z M218.094,252.019c0-9.133,2.291-16.024,6.872-20.664 c4.59-4.651,11.377-6.977,20.381-6.977s15.791,2.325,20.381,6.977c4.581,4.64,6.872,11.531,6.872,20.664 c0,9.108-2.291,15.988-6.872,20.628c-4.59,4.65-11.378,6.976-20.381,6.976s-15.791-2.325-20.381-6.976 C220.385,268.007,218.094,261.127,218.094,252.019L218.094,252.019z M237.854,252.003c0,3.298,0.641,5.816,1.931,7.558
		c1.282,1.74,3.139,2.615,5.563,2.615s4.28-0.875,5.563-2.615c1.29-1.741,1.931-4.26,1.931-7.558c0-3.314-0.641-5.851-1.931-7.608 c-1.283-1.759-3.139-2.643-5.563-2.643s-4.281,0.884-5.563,2.643C238.494,246.152,237.854,248.688,237.854,252.003L237.854,252.003	z M174.197,278.241v-52.482h44.243v15.641h-23.489v4.381h18.538v12.912h-18.538v19.549H174.197L174.197,278.241z M503.854,278.241 v-37.105h-14.007v-15.377c16.315,0,32.631,0,48.946,0v15.377h-14.007v37.105C517.81,278.241,510.832,278.241,503.854,278.241 L503.854,278.241z"
    />


    {/*Yellow*/}
    <polygon 
        fill="rgb(246,177,42)" 
        points="166.667,278.241 90,278.241 90,254.675 166.667,254.675 166.667,278.241"
    />


    {/*Red*/}
    <polygon 
        fill="rgb(237,33,42)" 
        points="166.667,249.325 90,249.325 90,225.759 166.667,225.759 166.667,249.325"
    />

   
    
    

    

		</svg>
		</div>

	);//return
}