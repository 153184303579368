import styled from "styled-components";



//#####################################################
//JOINED GRID
//#####################################################


const JoinedGrid = styled.div.attrs(props => ({ 
    
    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "10px 0 50px 0",
    margin:             props.margin || "auto",
    justifyContent:     props.justifyContent || "center"

}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    display: flex;
    flex-wrap: wrap;
    justify-content:${props => props.justifyContent};

`;



const JoinedCell = styled.div.attrs(props => ({ 
    
    backgroundColor:    props.backgroundColor || "darkred",
    border:             props.border || "0",
    height:             props.height || "auto",
    margin:             props.margin || "0px",

    flex4:              props.flex4 || "0 1 25%",
    flex3:              props.flex3 || "0 1 33.3333%",
    flex2:              props.flex2 || "0 1 50%",
    flex1:              props.flex1 || "0 1 100%"


}))`

    background-color: ${props => props.backgroundColor};
    border: ${props => props.border}; 
    height: ${props => props.height}; 
    flex: ${props => props.flex4};    
    margin: ${props => props.margin}; 

    @media (max-width: 1050px) {

        background-color: darkgreen; 
        flex: ${props => props.flex3}; 
    }
    @media (max-width: 800px) {

        background-color: darkred;    
        flex: ${props => props.flex2}; 
    }
    @media (max-width: 500px) {
    
        background-color:royalblue;
        flex: ${props => props.flex1}; 

    }


`;



export {

    JoinedGrid,
    JoinedCell

}
