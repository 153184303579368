import React, { 

    //useState, 
    //useEffect, 
    //useRef 

} from 'react';


import Fetchd from './Fetchd';
import NoFetchd from './NoFetchd';

const Index = (props) => {


    return(<div>

        {
        props.basename === '/source/'
        ?

            <NoFetchd 
            
                basename={props.basename}
                handle={props.handle}
            
            />

        :

            <Fetchd 
        
                basename={props.basename}
                handle={props.handle}
                editMode={props.editMode}
            
            />

        }

    </div>);


}

export default Index;