
import React, { 

    useState, 
    useEffect, 
    useRef,
    //useMemo

} from 'react';


import 'App.css';


import { GroupRelative, Member } from '../StylAttrs';

import Main from './Main';
import Nav from './Nav';


const Index = (props) => {


   // -- redux -- //
    // const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    // //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- ref -- //
    const refMain = useRef(null);
    const refNav = useRef(null);
    const refSubNav = useRef(null);

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [groupHeight, setGroupHeight] = useState("auto");


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            
            //var w = refMain.current.offsetWidth;
            var h = refMain.current.offsetHeight + refNav.current.offsetHeight;
            setGroupHeight(h+"px");


        }


    },[
    
        props,
        isLoaded,
        refMain,
        refNav,
        refSubNav

    ])

    



    return (
    
        <GroupRelative
        height={groupHeight}
        >
            <Member 
            ref={refNav} 
            >

                 <Nav
                    
                    databind={props.databind}  

                /> 

            </Member>

            <Member ref={refMain} >

                <Main
              
                    databind={props.databind}  

                /> 

            </Member>

        </GroupRelative>


    );

}

export default Index;



