import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    //BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';
//import NavGrid from 'utils/myBuilds/NavGrid/Tags';


import {

    GutterGrid,  
    GutterCel,
    GutterCelCenter,
    GutterCelGraphic,
    //GutterCelTitle,
    GutterCelName,
    GutterCelDescr, 


} from './Styled/GutterGrid';



//import { LibArray } from 'library';



export const Tiles = (props) => {




    // -- router -- //
    let match = useRouteMatch();
    let location = useLocation();
    //let history = useHistory();
    //let params = useParams();


    // console.log("MATCH: "+match.path);
    // console.log("LOC: "+JSON.stringify(location,null,2));
    // //console.log("PARAMS: "+JSON.stringify(params,null,2));



    // -- paths -- //

    let pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    //console.log(pathname);

    //let mainpath;
    //mainpath = pathname.split("/")[0];

    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = '';



    // -- dims -- //
    //const { width, height } = useWindowDimensions();
    //console.log("STAGE WIDTH: "+width+"\nSTAGE HEIGHT: "+height);


    // -- ref -- //
    //const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);





    //===============================================
    // state/effect - main
    //===============================================

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [switchRoute, setSwitchRoute] = useState(false);
    const [navArr, setNavArr] = useState([]);
    // const [highlights, setHighlights] = useState([]);
    // const [styling] = useState({

    //     main:{

    //         backgroundColor:{

    //             over:'lightseagreen',
    //             out:false

    //         },
    //         textColor:{

    //             over:'white',
    //             out:false

    //         },
    //         fontWeight:{

    //             over:'550',
    //             out:false

    //         }

    //     },


    //     sub:{

    //         backgroundColor:{

    //             over:'white',
    //             out:"white"

    //         },
    //         textColor:{

    //             over:'black',
    //             out:false

    //         },
    //         fontWeight:{

    //             over:'550',
    //             out:false

    //         }

    //     },


    //     sub2:{

    //         backgroundColor:{

    //             over:'lightyellow',
    //             out:false

    //         },
    //         textColor:{

    //             over:'black',
    //             out:false

    //         },
    //         fontWeight:{

    //             over:'550',
    //             out:false

    //         }

    //     }



    // });




    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);

            if(props.data.info && props.data.info !== undefined)
            {
                if(props.data.info.call
                && props.data.info.call === 'switchRoute'
                )
                {
                    setSwitchRoute(true);
                }

            }else{

                setSwitchRoute(props.data.withSwitchRoute);
            }


            if(props.data.items && props.data.items.length > 0)
            {

                setNavArr(props.data.items);


                // // -- set highlights -- //

                // var nav;
                // var subnav;
                // //var sub2nav;

                // var regxNavTo;
                // //var regxSubnavTo
                // //var regxSub2navTo

                // var hi = []
                // for(nav of props.data.items)
                // {

                //     //console.log(pathname + ' ' + nav.to)
                //     if(pathname === nav.to)
                //     {
                //         hi.push(nav.name);

                //     }else{

                //         regxNavTo = new RegExp(nav.to,"gi")
                //         if(nav.subs && nav.subs !== undefined)
                //         {
                //             if(pathname.match(regxNavTo)
                //             && !LibArray.InArray(pathname,hi)
                //             )
                //             {
                //                 hi.push(nav.name);
                //                 for(subnav of nav.subs)
                //                 {
                //                     if(pathname === subnav.to)
                //                     {
    
                //                         hi.push(subnav.name);
    
                //                     }
                //                 }
        
                //             }
                //         }
                //     }
                 
                // }
                // setHighlights(hi);
     
         
            }

            //console.log(JSON.stringify(props.data,null,2));
            //alert('props.data');


        }// isLoaded


    },
    [
        props,
        isLoaded,
        pathname
    ])




    

    // //===============================================
    // // state/effect - currentPage
    // //===============================================

    // // -- state -- //
    // const [currentPage, setCurrentPage] = useState('na');

    // useEffect(() => {

    //     if(isLoaded && !currentPage)
    //     {
    //         var pathArr = pathname.split("/");
    //         console.log("Current Page: "+pathArr[pathArr.length-1]);
    
    //         setCurrentPage(pathArr[pathArr.length-1]);
  
    //     }

    // },
    // [
    //     props,
    //     pathname,
    //     isLoaded,
    //     currentPage
  
    // ])





    // //===============================================
    // // state/effect - data test
    // //===============================================

    // // -- state -- //
    // const [dataTest, setDataTest] = useState(false);

    // useEffect(() => {

    //     if(isLoaded && !dataTest)
    //     {
    //         setDataTest(props);

    //         console.log("====== NAVIGATOR (props) ======");

    //         console.log(pathname);
    //         var pathArr = pathname.split("/");
    //         console.log("Extracted Path 2: "+pathArr[pathArr.length-1]);

    //         console.log(JSON.stringify(props,null,2));




    //         console.log("====== NAVIGATOR (split data) ======");

    //         if(props.data.info && props.data.info !== undefined)
    //         {
    //             if(props.data.info.call
    //             && props.data.info.call === 'switchRoute'
    //             )
    //             {
    //                 console.log("setSwitchRoute --- here");
    //             }
    //             if(props.data.info.gridType 
    //             && props.data.info.gridType !== undefined
    //             )
    //             {
        
    //                 console.log("setLayout --- "+props.data.info.gridType);
    //             }
    
    //         }else{
    

    //             console.log("No Info");

    //             console.log("setSwitchRoute --- "+props.data.withSwitchRoute);
    //             console.log("setLayout --- "+props.data.layout);


    
    //         }
    
    //         if(props.data.items && props.data.items.length > 0)
    //         {
               
    //             console.log("setNavArr --- "+props.data.items);
             
    //         }else{
           
    //             console.log("NO Items");
    //             console.log("setNavArr --- "+props.data.items);
             
    //         }
    

    //     }

    // },
    // [
    //     props,
    //     dataTest,
    //     isLoaded,
    //     pathname
    // ])












    // // -- handle -- //

    // const handle = (hndl) => {

    //     //console.log(JSON.stringify(hndl));

    
    //     switch(hndl.call)
    //     {
    //     case'messngr':


    //         if(hndl.name.match(/error/gi))
    //         {

    //             props.handle({

    //                 call       :"showMessngr",
                
    //                 isError    : true,
    //                 errorType  : 'process',
    //                 isTimed    : false,
    //                 items      : [

    //                     {
    //                         call:'span',
    //                         text: "ERROR : Huh"

    //                     }

    //                 ]
                

    //             });


    //         }else{

    //             props.handle({

    //                 call       :"showMessngr",
                
    //                 isError    : false,
    //                 errorType  : 'process',
    //                 isTimed    : true,
    //                 items      : [ 

    //                     {
    //                         call:'heading',
    //                         text: "SUCCESS"

    //                     },

    //                     {

    //                         call:'span',
    //                         text: "SUCCESS: Process was successfull!!"

    //                     }

    //                 ]
                
    
    //             });
    

    //         }


        

    //     break;
    //     case'loader':

  
    //         props.handle({

    //             call       :"showLoader",
             
    //             isError    : false,
    //             errorType  : 'process',
    //             isTimed    : true,
    //             items      : [

    //                 "SUCCESS: Process was successfull!!"

    //             ]
            

    //         });

    //     break;
    //     case'login':

  
    //         props.handle({

    //             call       :"showLogin",
             
    //             isError    : false,
    //             errorType  : 'process',
    //             isTimed    : true,
    //             items      : [

    //                 "SUCCESS: Process was successfull!!"

    //             ]
            

    //         });

    //     break;
    //     case'goBack':

    //         history.goBack();
    //     break;
    //     case'reload':

    //         window.location.reload();//regular dom 
    //     break;
    //     case'redirect':

    //         history.replace(hndl.val);
    //     break;
    //     case'push':

    //         history.push(hndl.val);
    //     break;
    //     default:
    //         history.goBack();
    //     }



    // }





    return (

        <div>
        {

        switchRoute === true
        ?

            <Switch>
            <Route path={match.path}>
            

                <GutterGrid
                backgroundColor="transparent"
                >

                    {navArr.map((nav, i) => (

                        <GutterCel
                        key={i}
                        height="300px"
                        >
                            <Link 
                            to={"/"+nav['to']}
                            >
                                <GutterCelCenter>

                                    <GutterCelGraphic></GutterCelGraphic>
                                    <GutterCelName>{nav.name}</GutterCelName>
                                    <GutterCelDescr>{nav.descr}</GutterCelDescr>
                        
                                </GutterCelCenter>

                            </Link>
                        </GutterCel>
                        

                    ))} 

                </GutterGrid>


            </Route>
            </Switch>


        :
        switchRoute === false
        ?


            <GutterGrid
            backgroundColor="transparent"
            >

                {navArr.map((nav, i) => (

                    <GutterCel
                    key={i}
                    height="300px"
                    >
                        <Link 
                        to={"/"+nav['to']}
                        >
                            <GutterCelCenter>

                                <GutterCelGraphic></GutterCelGraphic>
                                <GutterCelName>{nav.name}</GutterCelName>
                                <GutterCelDescr>{nav.descr}</GutterCelDescr>
                    
                            </GutterCelCenter>

                        </Link>
                    </GutterCel>
                    

                ))} 

            </GutterGrid>


        :

            <div 
            style={{

                backgroundColor:'powderblue',
                width:"100%",
                height:"500px",
                padding:"15px 0 15px 0",
                margin:"15px auto",
                textAlign:"center",
                color:"black"
            }}
            >
                Navigator SideNav - null
            
            </div> 


        }
        </div>

    )


}//END















            // <Switch>

            //     <Route path={match.path}>


            //         <PrimeStage>

            //             <PrimeScrollBox>

            //                 <PrimeArea>

            //                     <Navigator 

            //                         navSwitchRoute={true}
            //                         layout={"XGrid"}
            //                         navArr={Data.navigation}

            //                     />

            //                 </PrimeArea>

            //             </PrimeScrollBox>

            //         </PrimeStage>
                    

            //     </Route>

            // </Switch>



