export const BUTTON_TIRE_PIRELLI_HARD_ORANGE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Black*/}

    <polygon 
        fill="rgb(0,0,0)" 
        points="108,90 612,90 612,414 108,414 108,90 	"
    />
{/*Brackets*/}
    <path 
        fill="rgb(243,112,33)" 
        d="M344.358,345.169c-44.636-7.491-78.834-46.46-78.834-93.169 c0-46.708,34.198-85.679,78.834-93.17v-22.777C287.267,143.738,243,192.861,243,252s44.267,108.262,101.358,115.947V345.169 L344.358,345.169z M375.642,158.83c44.636,7.491,78.834,46.462,78.834,93.17c0,46.709-34.198,85.678-78.834,93.169v22.778 C432.732,360.262,477,311.139,477,252s-44.268-108.262-101.358-115.947V158.83L375.642,158.83z"
    />  
{/*Letter*/}
    <polygon 
        fill="rgb(255,255,255)" 
        points="311.684,299.997 311.684,204.003 337.281,204.003 337.281,240.353 382.719,240.353 382.719,204.003 408.316,204.003 408.316,299.997 382.719,299.997 382.719,262.752 337.281,262.752 337.281,299.997 311.684,299.997 	"
    />




    

		</svg>
		</div>

	);//return
}