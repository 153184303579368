export const BODY_THEME_GRAY_MARTINI = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    {/*Two*/}
    <polygon 
        fill="rgb(255,255,255)" 
        points="60.552,329.4 92.127,396 655.779,396 655.779,275.4 642.933,250.745 608.1,230.634 436.5,209.563 86.8,185.109 76.176,203.256 76.193,275.4 60.568,275.4 60.552,329.4"
    />
    {/*One*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M86.8,185.109l349.7,24.454l171.6,21.07l34.833,20.111v15.957 H612.34c-15.189-20.885-39.639-34.682-67.349-35.165c-23.848-0.417-45.626,9.108-61.376,24.737 c-26.417-13.027-57.538-16.255-83.05-17.921l-147.315-9.01c-18.387-1.125-5.965,30.828-22.414,18.791 c-13.847-10.339-30.925-16.596-49.456-16.92c-32.641-0.569-61.421,17.41-76.258,44.186H76.176v-72.144L86.8,185.109L86.8,185.109z"
    />
    {/*One Front Bumper*/}   
    <polygon 
        fill="rgb(230,230,230)" 
        points="655.779,275.399 642.933,250.744 608.099,230.634 642.933,285.278 642.933,298.921 655.779,298.921 655.779,275.399 	"
    />
    {/*Three*/}
    <polygon 
        fill="rgb(230,230,230)" 
        points="92.127,396 642.933,396 642.933,298.921 227.025,275.4 60.568,275.4 60.552,329.4 92.127,396"
    />
    {/*Three Front Bumper*/}
    <polygon 
        fill="rgb(210,210,210)" 
        points="655.779,379.3 655.779,298.921 642.933,298.921 642.933,379.3 655.779,379.3 	"
    />
    {/*Vent Background*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M629.269,348.577l18.681,4.628l2.489-0.004 c1.779-0.004,3.422-0.325,3.422-2.821l-0.001-29.823c0-1.021-0.404-1.261-0.984-1.281l-21.414-0.759 c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603C625.877,344.809,626.697,347.94,629.269,348.577L629.269,348.577z"
    />
    {/*Vent*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M648.527,353.204l-9.437-5.126c-1.72-0.934-2.519-2.618-2.52-4.77 l-0.005-11.837c0-2.773,0.814-4.013,2.066-4.716l13.386-7.511l-20.558-0.729c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603 c0,2.714,0.82,5.846,3.392,6.482l18.681,4.628L648.527,353.204L648.527,353.204z"
    />
    {/*Vent Shadow*/}
    <path 
        fill="rgb(130,130,130)" 
        d="M628.042,324.644c-0.001-2.038,1.858-2.907,3.285-3.068 l20.691-2.33l-20.558-0.729c-4.165-0.147-5.582,1.514-5.583,5.976l-0.001,17.603c0,2.714,0.82,5.846,3.392,6.482 c-0.957-1.553-1.22-4.483-1.221-5.71L628.042,324.644L628.042,324.644z"
    />
    {/*Vent Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M632.043,348.424l12.192,3.385l-7.12-4.247 c-6.029-3.596-8.149-15.236-8.107-8.605C629.051,345.587,629.347,347.676,632.043,348.424L632.043,348.424z"
    />
    {/*Rear Bumper*/}
    <polygon 
        fill="rgb(210,210,210)" 
        points="76.176,329.4 92.127,396 179.855,396 179.855,329.4 76.176,329.4"
    />
    {/*Rear Bumper Highlight*/}
    <polygon 
        fill="rgb(255,255,255)" 
        points="76.176,285.279 60.552,285.279 60.568,275.4 76.176,275.4 76.176,285.279"
    />
    {/*Rear Bumper Shadow*/}
    <polygon 
        fill="rgb(190,190,190)" 
        points="60.552,329.4 76.176,329.4 92.127,396 60.552,329.4"
    />
    {/*Rocker*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M642.933,379.3V396H92.127l-0.001-0.002L92.127,396l0,0l-4-16.7 C283.727,379.3,447.309,379.3,642.933,379.3L642.933,379.3z"
    />
    {/*Rocker Front*/}
    <polygon 
        fill="rgb(170,170,170)" 
        points="642.933,379.3 642.933,396 655.779,396 655.779,379.3 642.933,379.3 	"
    />
    {/*Inset Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	L179.855,318.02v41.717l280.313-0.024C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />
    {/*Inset Shadow*/}
    <path 
        fill="rgb(130,130,130)" 
        d="M459.484,351.795c-4.402-10.491-8.731-21.947-22.984-22.57	L179.855,318.02v12.312l251.604,10.985c10.855,0.475,22.532,9.438,26.881,16.582c0.765,1.256,1.308,1.813,1.828,1.813 C461.342,359.712,461.205,355.898,459.484,351.795L459.484,351.795z"
    />  
    {/*Door Handle Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M302.899,232.38c-0.903-1.849-2.974-4.587-8.14-6.69 c-9.912-4.039-26.723-4.895-28.843-5.051c-2.937-0.219-9.122-0.149-8.186,5.914l0.478,3.094L302.899,232.38L302.899,232.38z"
    />
    {/*Door Handle Shadow*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M266.162,231.082c1.954,0,3.548,1.594,3.548,3.548	s-1.594,3.548-3.548,3.548c-1.953,0-3.547-1.594-3.547-3.548S264.209,231.082,266.162,231.082L266.162,231.082z M300.152,232.212 l-29.457-1.802c0.471,5.016,2.687,6.109,4.926,6.132l20.105,1.186C300.893,237.779,300.152,232.212,300.152,232.212 L300.152,232.212z"
    />
    {/*One Highlight*/}
    <polygon 
        fill="rgb(255,255,255)" 
        points="436.5,209.563 86.8,185.109 76.176,203.256 77.452,203.257 436.5,209.563"
    />
    {/*Mirror Body Shadow*/}
    <path 
        fill="rgb(200,200,200)" 
        d="M420.018,215.521l1.227,1.87 c-8.865-0.307-14.482,2.165-16.687,3.683c-2.327,1.602-2.546,5.234,3.085,4.68l25.139-2.47c1.26,0.041,2.572-0.003,3.807-0.202 c1.378-0.223,4.542-1.097,3.238-3.086l-2.383-3.635c-1.073,0.17-2.324,0.216-3.747,0.117 C429.137,216.16,424.577,215.84,420.018,215.521L420.018,215.521z"
    />

    {/*Mirror Background*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M437.443,216.361c4.697-0.74,6.028-3.823,3.786-7.244l-3.907-5.96 c-0.822-1.253-3.626-4.647-5.669-6.215c-6.813-5.228-12.349-6.552-21.103-6.552c-4.264,0-5.566,0.952-7.2,4.458 c-0.749,1.604-1.124,3.95-1.124,5.72v5.09c0,1.447,0.091,3.408,1.29,4.729l2.525,2.78c0.802,0.882,2.007,1.519,3.195,1.601 l10.78,0.754L423.61,221c0.935,1.426,3.336,1.578,4.617,1.802c2.035,0.354,5.423,0.755,8.36,0.28	c1.378-0.223,4.542-1.097,3.238-3.086L437.443,216.361L437.443,216.361z"
    />
    {/*Mirror Base*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M437.443,216.361c-1.073,0.17-2.324,0.217-3.747,0.117	l-13.679-0.957L423.61,221c0.935,1.426,3.336,1.578,4.617,1.802c2.035,0.354,5.423,0.755,8.36,0.28	c1.378-0.223,4.542-1.097,3.238-3.086L437.443,216.361L437.443,216.361z"
    />
    {/*Mirror_Base_Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M438.299,219.592l-1.062-1.619
		c-0.336-0.513-1.093-0.805-1.733-0.85l-14.267-0.997l0.845,1.288l7.073,0.523c2.369,0.174,3.686,0.219,5.114,2.258
		c0.733,1.045,2.132,1.713,3.148,1.344C438.434,221.169,438.88,220.408,438.299,219.592L438.299,219.592z"
    />
    {/*"Mirror_Shadow"*/}
    <path 
        fill="rgb(210,210,210)" 
        d="M437.443,216.361c-1.073,0.17-2.324,0.217-3.747,0.117	l-24.459-1.711c-1.188-0.082-2.394-0.719-3.195-1.601l-2.525-2.78c-0.374-0.412-0.64-0.886-0.829-1.388	c6.532,0.457,33.813,2.364,38.542,0.118C443.472,212.538,442.141,215.621,437.443,216.361L437.443,216.361z"
    />
    {/*"Mirror_Highlight"*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M402.947,203.555c10.225,2.229,20.418-6.53,32.119-2.197 c-1.167-1.412-2.479-2.742-3.855-3.847c-3.146-2.373-6.389-4.21-10.196-5.287c-3.183-0.815-6.384-1.06-9.66-1.109	c-3.919-0.018-5.603,0.411-7.351,4.036c-0.744,1.691-1.035,3.584-1.057,5.423V203.555L402.947,203.555z"
    />



    


    {/*"Livery"*/}
 
    {/*Two Navy*/}
    <path 
        fill="rgb(33,29,112)"
        d="M338.36,390.096c-40.369,0-73.069-33.144-73.072-73.512 c-0.003-45.533-36.08-83.202-81.46-85.285l-107.652-6.588v-21.455L86.8,185.109l197.422,13.805 c32.563,28.918,53.071,71.058,53.072,117.67c0,9.517,7.717,17.494,17.233,17.494l301.252,0.001v56.017H338.36L338.36,390.096z"
    /> 
    {/*Two Red*/}
    <path 
        fill="rgb(200,0,0)"
        d="M342.965,374.686l312.814,0.001v-25.2H350.208	c-18.002,0-32.716-14.902-32.716-32.902c-0.001-51.488-28.625-96.733-70.967-120.306L86.8,185.109l-10.624,18.146v1.612	l109.52,6.698c55.456,3.045,99.391,49.242,99.395,105.018C285.092,348.467,311.082,374.686,342.965,374.686L342.965,374.686z"
    /> 
    {/*Two Blue*/}
    <path 
        fill="rgb(0,174,239)"
        d="M339.088,388.798l316.691,0.001v-5.758l-315.291-0.004	c-36.482,0-66.197-29.972-66.199-66.453c-0.003-49.879-39.164-91.221-88.688-94.204l-109.425-6.69v7.208l108.559,6.646 c45.95,2.541,82.351,40.824,82.354,87.041C267.091,356.238,299.432,388.798,339.088,388.798L339.088,388.798z M341.036,381.742 h314.743v-5.758l-313.303-0.003c-32.597,0-59.186-26.803-59.188-59.397c-0.002-54.788-43.137-100.174-97.598-103.215
		l-109.516-6.702v7.218l109.607,6.703c50.434,3.076,90.303,45.19,90.306,95.996C276.091,352.353,305.267,381.742,341.036,381.742	L341.036,381.742z M352.152,342.43c-14.117,0-25.659-11.73-25.659-25.846c0-48.775-24.112-92.287-61.168-118.991l-14.683-1.026 c41.107,24.353,68.648,69.182,68.65,120.018c0,17.288,14.104,31.605,31.393,31.605h305.094v-5.757L352.152,342.43L352.152,342.43z M281.246,198.706c33.222,28.621,54.248,70.954,54.248,117.878c0,10.229,8.371,18.79,18.602,18.79l301.684,0.003v5.758 l-303.15-0.001c-13.402,0-24.336-11.147-24.336-24.55c0-48.355-23.412-91.6-59.614-118.757L281.246,198.706L281.246,198.706z"
    />
    {/*Three Red*/}
    <path 
        fill="rgb(175,0,0)"
        d="M342.965,374.686l299.968,0.001v-25.199l-184.416-0.001 c0.324,0.771,0.646,1.542,0.967,2.309c1.722,4.104,1.858,7.917,0.686,7.917h-155.87
		C314.559,369.002,328.123,374.686,342.965,374.686L342.965,374.686z M318.36,324.066c-0.566-2.408-0.868-4.914-0.868-7.483 c0-12.561-1.703-24.749-4.895-36.344l-34.768-1.966c4.682,11.895,7.259,24.82,7.26,38.31c0,2.04,0.109,4.058,0.316,6.045	L318.36,324.066L318.36,324.066z"
    /> 
    {/*Three Front Red*/}
    <polygon 
        fill="rgb(140,0,0)" 
        points="642.933,374.687 655.779,374.687 655.779,349.487 642.933,349.487 642.933,374.687"
    />
    {/*Three Front Blue*/}
    <path 
        fill="rgb(0,137,207)" 
        d="M642.933,379.301h12.847v-3.318h-12.847V379.301L642.933,379.301z M655.779,342.433h-1.919v5.755h1.919V342.433L655.779,342.433z M653.859,335.377h1.92v5.756l-1.919-0.001L653.859,335.377 L653.859,335.377z"
    />
    {/*One Red*/}
    <path 
        fill="rgb(185,0,0)"
        d="M287.852,231.459c-11.279-14.268-25.322-26.271-41.325-35.18 L86.8,185.109l-10.624,18.146v1.612l1.248,0.076l108.271,6.622c22.027,1.21,42.234,9.233,58.595,21.955 c1.677-2.662,4.349-4.46,8.96-4.178L287.852,231.459L287.852,231.459z"
    />
    {/*One Blue*/}
    <path 
        fill="rgb(0,125,197)"
        d="M240.938,244.702c-15.097-12.846-34.279-21.054-55.337-22.322 l-109.425-6.69v7.208l108.559,6.646c19.975,1.104,38.142,8.966,52.302,21.293C239.356,250.629,240.227,248.028,240.938,244.702 L240.938,244.702z M243.432,235.135c-16.1-12.597-36.018-20.553-57.74-21.766l-109.516-6.702v7.218l109.607,6.703	c21.08,1.285,40.312,9.396,55.561,22.105C241.84,240.178,242.383,237.46,243.432,235.135L243.432,235.135z M299.613,232.179	c-9.432-13.326-21.031-25.032-34.287-34.585l-14.682-1.027c15.328,9.082,28.764,21.014,39.596,35.039L299.613,232.179 L299.613,232.179z M301.904,232.319c-9.149-13.194-20.381-24.856-33.225-34.491l12.566,0.879	c11.467,9.879,21.481,21.393,29.68,34.164L301.904,232.319L301.904,232.319z"
    />
    {/*Rocker*/}
    <path 
        fill="rgb(0,114,188)"
        d="M339.088,388.798l303.845-0.002v-5.757l-302.444-0.001	c-7.647,0-14.994-1.32-21.834-3.738c-5.043,0-10.092,0-15.145,0C314.015,385.337,326.162,388.798,339.088,388.798L339.088,388.798z M341.036,381.742l301.896-0.002v-2.44c-113.074,0-215.443,0-319.478,0C329.051,380.89,334.947,381.742,341.036,381.742 L341.036,381.742z"
    />
    {/*Rocker Front*/}
    <path 
        fill="rgb(3,78,162)"
        d="M642.933,388.797l12.847-0.001v-5.757l-12.847-0.001V388.797 L642.933,388.797z M642.933,381.74h12.847v-2.44c-4.412,0-8.674,0-12.847,0V381.74L642.933,381.74z"
    />
    {/*Inset Blue*/}
    <path 
        fill="rgb(0,185,242)"
        d="M290.23,359.712c-8.793-10.308-14.512-23.321-15.704-37.559 l-7.244-0.316c1.026,14.105,6.14,27.133,14.158,37.875H290.23L290.23,359.712z M301.898,359.712 c-10.082-9.567-16.842-22.623-18.309-37.163l-7.251-0.316c1.239,14.276,7.126,27.288,16.131,37.479H301.898L301.898,359.712z  M352.152,342.43c-11.373,0-21.072-7.613-24.414-17.953l-7.521-0.328c3.396,13.709,15.781,24.042,30.469,24.041l107.281-0.001 c-0.838-1.957-1.713-3.902-2.687-5.759H352.152L352.152,342.43z M354.096,335.374h96.341c1.601,1.699,2.947,3.657,4.138,5.759 l-101.945,0.001c-10.621,0-19.688-6.999-22.996-16.574l7.818,0.341C340.512,331.062,346.834,335.374,354.096,335.374 L354.096,335.374z"
    />
    {/*Inset Shadow Blue*/}
    <path 
        fill="rgb(0,102,179)"
        d="M276.771,334.563c-1.128-4-1.888-8.15-2.244-12.409l-7.244-0.316 c0.309,4.241,0.987,8.386,2.003,12.399L276.771,334.563L276.771,334.563z M286.222,334.976c-1.303-3.979-2.198-8.138-2.632-12.427	l-7.251-0.316c0.37,4.264,1.153,8.415,2.312,12.412L286.222,334.976L286.222,334.976z M336.736,337.181	c-4.168-3.186-7.354-7.614-8.998-12.704l-7.521-0.328c1.16,4.683,3.369,8.972,6.359,12.589L336.736,337.181L336.736,337.181z	M448.897,348.188h9.069c-0.838-1.957-1.712-3.902-2.687-5.759h-17.622C441.634,343.645,445.513,345.731,448.897,348.188 L448.897,348.188z M354.096,335.374h96.342c1.601,1.699,2.947,3.657,4.138,5.759l-27.319,0.001l-87.602-3.825 c-4.622-2.972-8.197-7.469-10.021-12.749l7.818,0.341C340.512,331.062,346.834,335.374,354.096,335.374L354.096,335.374z"
    />
    {/*Inset Shadow Red*/}
    <path 
        fill="rgb(120,0,0)"
        d="M458.517,349.486h-7.919c3.348,2.687,6.082,5.682,7.743,8.412 c0.764,1.256,1.308,1.813,1.828,1.813c1.173,0,1.037-3.813-0.686-7.917C459.162,351.028,458.841,350.258,458.517,349.486	L458.517,349.486z M324.351,336.641c-2.825-3.668-4.901-7.94-5.99-12.574l-32.954-1.438c0.448,4.295,1.368,8.457,2.707,12.431 L324.351,336.641L324.351,336.641z"
    />
    {/*Front Lip Highlight*/}
    <polygon 
        fill="rgb(255,0,0)" 
        points="543.528,365.473 655.779,365.473 655.779,364.026 642.933,362.38 633.41,364.026 543.528,364.026 543.528,365.473 	"
    />
    {/*Vent Back Red*/}
    <path 
        fill="rgb(255,0,0)"
        d="M653.86,349.487h-20.92l15.009,3.718l2.489-0.004 c1.779-0.004,3.422-0.325,3.422-2.821V349.487L653.86,349.487z"
    />
    {/*Vent Back Blue*/}
    <path 
        fill="rgb(0,192,243)"
        d="M628.315,348.188h25.545v-5.757h-27.98 C625.937,344.689,626.576,347.149,628.315,348.188L628.315,348.188z M625.877,335.375h27.982l0.001,5.757h-27.983V335.375 L625.877,335.375z"
    />
    {/*Vent Blue*/}
    <path 
        fill="rgb(0,149,218)"
        d="M628.315,348.188h10.976l-0.2-0.109 c-1.72-0.934-2.519-2.618-2.52-4.77v-0.878H625.88C625.937,344.689,626.576,347.149,628.315,348.188L628.315,348.188z M625.877,335.375h10.691l0.002,5.757h-10.693V335.375L625.877,335.375z"
    />
    {/*Red Vent*/}
    <polygon 
        fill="rgb(180,0,0)" 
        points="641.683,349.487 632.94,349.487 647.949,353.205 648.527,353.204 641.683,349.487 	"
    />
    {/*Red HL Vent*/}
    <polygon 
        fill="rgb(255,0,0)" 
        points="640.343,349.487 635.872,349.487 644.235,351.809 640.343,349.487 	"
    />
    {/*Vent HL Blue*/}
    <path 
        fill="rgb(0,192,243)"
        d="M631.373,348.188h6.791l-1.049-0.626 c-1.953-1.164-3.496-3.172-4.688-5.131h-3.346C629.245,345.93,629.754,347.468,631.373,348.188L631.373,348.188z M631.678,341.132 h-2.641c-0.016-0.665-0.023-1.388-0.029-2.176C628.982,334.954,629.744,337.608,631.678,341.132L631.678,341.132z"
    />
    {/*Vent Shadow Blue*/}
    <path 
        fill="rgb(0,102,179)"
        d="M628.315,348.188h0.739c-0.783-1.612-1.006-4.189-1.007-5.32 v-0.437h-2.168C625.937,344.689,626.576,347.149,628.315,348.188L628.315,348.188z M625.877,335.375h2.169l0.002,5.757h-2.171	V335.375L625.877,335.375z"
    />
    {/*One HL Red*/}
    <path 
        fill="rgb(255,0,0)"
        d="M86.8,185.109l-10.624,18.146l1.276,0.001l184.81,3.246 c-4.994-3.749-10.248-7.169-15.734-10.224L86.8,185.109L86.8,185.109z"
    />
    {/*One HL Blue*/}
    <path 
        fill="rgb(0,192,243)"
        d="M276.809,206.758c-3.667-3.24-7.502-6.297-11.482-9.164 l-14.682-1.027c5.1,3.022,9.988,6.365,14.645,9.99L276.809,206.758L276.809,206.758z M279.543,206.807	c-3.475-3.158-7.1-6.155-10.863-8.979l12.566,0.879c3.075,2.648,6.051,5.409,8.909,8.285L279.543,206.807L279.543,206.807z"
    />
     {/*Door Handle Shadow Red*/}
    <path 
        fill="rgb(100,0,0)"
        d="M266.162,231.082c1.954,0,3.548,1.594,3.548,3.548	s-1.594,3.548-3.548,3.548c-1.953,0-3.547-1.594-3.547-3.548S264.209,231.082,266.162,231.082L266.162,231.082z M287.85,231.459	l-17.154-1.049c0.471,5.016,2.687,6.109,4.926,6.132l16.758,0.988C290.923,235.465,289.416,233.439,287.85,231.459L287.85,231.459z"
    />
    {/*Door Handle Shadow Blue*/}
    <path 
        fill="rgb(0,102,179)"
        d="M300.189,233.003c-0.191-0.275-0.383-0.55-0.576-0.823	l-9.373-0.574c1.528,1.979,3.004,4,4.427,6.06l1.06,0.063C299.668,237.768,300.172,234.537,300.189,233.003L300.189,233.003z"
    />
    {/*Door Handle HL Red*/}
    <path 
        fill="rgb(255,0,0)"
        d="M279.678,222.021c-6.68-0.981-12.563-1.294-13.761-1.382 c-2.937-0.219-9.122-0.149-8.186,5.914l0.478,3.094l29.643,1.813C285.268,228.191,282.541,225.041,279.678,222.021L279.678,222.021 z"
    />
    {/*Door Handle HL Blue*/}
    <path 
        fill="rgb(0,174,239)"
        d="M299.613,232.18c-1.564-2.212-3.189-4.379-4.871-6.498	c-3.518-1.431-7.9-2.461-12.187-3.201c2.688,2.93,5.251,5.975,7.685,9.125L299.613,232.18L299.613,232.18z M301.904,232.318	c-1.092-1.574-2.213-3.131-3.363-4.662c2.494,1.661,3.719,3.415,4.358,4.724L301.904,232.318L301.904,232.318z"
    />
     {/*Martini Background Blue*/}
    <path 
        fill="rgb(30,25,106)"
        d="M400.352,248.594l57.298,3.305l-2.807,48.668l-57.297-3.304 c-8.575,11.392-22.494,18.447-37.731,17.568s-28.253-9.486-35.462-21.788l-57.298-3.304l2.807-48.669l57.297,3.304	c8.576-11.392,22.494-18.446,37.731-17.568C380.128,227.685,393.144,236.293,400.352,248.594L400.352,248.594z"
    />
    {/*Martini Background Gold*/}
    <path 
        fill="rgb(210,171,103)"
        d="M398.857,250.717l56.464,3.255l-2.552,44.268l-56.465-3.256 c-8.055,11.293-21.547,18.354-36.355,17.501c-14.808-0.854-27.398-9.42-34.103-21.563l-56.464-3.256l2.553-44.267l56.463,3.255	c8.055-11.292,21.548-18.354,36.356-17.501C379.563,230.008,392.154,238.572,398.857,250.717L398.857,250.717z"
    />
    {/*Martini Red Circle*/}
    <path 
        fill="rgb(210,0,0)"
        d="M364.482,233.869c20.327,1.172,35.949,18.727,34.775,39.077 c-1.173,20.352-18.709,35.993-39.036,34.821s-35.948-18.727-34.775-39.077S344.155,232.697,364.482,233.869L364.482,233.869z"
    />
    {/*Red Vent*/}
    <polygon 
        fill="rgb(30,25,106)" 
        points="275.387,247.277 451.446,257.429 449.317,294.36 273.258,284.209 275.387,247.277 	"
    />
    {/*Martini Text*/}
    <path 
        fill="rgb(255,255,255)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.999,286.278l6.231,0.359l1.316-22.835l-6.23-0.359 L431.999,286.278L431.999,286.278z M391.931,283.969l6.23,0.359l1.316-22.836l-6.23-0.359L391.931,283.969L391.931,283.969z M404.578,284.72l5.894,0.34l0.82-14.234l0.065,0.003l7.531,14.717l6.431,0.371l1.317-22.839l-5.891-0.34l-0.812,14.074	l-0.065-0.004l-7.543-14.556l-6.431-0.371L404.578,284.72L404.578,284.72z M387.666,260.83l-19.895-1.147l-0.295,5.119l6.923,0.399
		l-1.021,17.72l6.048,0.349l1.021-17.72l6.923,0.399L387.666,260.83L387.666,260.83z M342.437,281.137l6.048,0.349l0.48-8.318 l4.584,0.265c2.829,0.163,3.082,2.555,3.113,4.865c0.03,0.643-0.005,2.886,0.474,3.688l5.98,0.345	c-0.765-0.88-0.669-4.243-0.68-5.204c-0.068-2.189-0.626-4.948-3.065-5.665l0.004-0.065c2.491-0.785,3.83-3.055,3.972-5.517	c0.266-4.607-3.663-6.661-7.756-6.897l-11.837-0.683L342.437,281.137L342.437,281.137z M349.527,263.414l4.811,0.277 c1.691,0.098,3.014,0.945,2.916,2.639c-0.1,1.726-1.132,2.628-2.949,2.523l-5.074-0.293L349.527,263.414L349.527,263.414z M327.459,263.715l0.066,0.004l2.105,8.144l-5.267-0.304L327.459,263.715L327.459,263.715z M314.804,279.544l6.212,0.358 l1.697-4.011l8.093,0.467l1.196,4.177l6.31,0.364l-7.366-23.34l-6.144-0.354L314.804,279.544L314.804,279.544z M285.158,277.832 l5.686,0.328l1-17.334l0.068,0.004l3.876,17.615l4.616,0.267l5.877-17.053l0.066,0.004l-1,17.333l5.688,0.328l1.317-22.838 l-8.91-0.514l-4.877,14.994l-0.063-0.004l-3.121-15.454l-8.908-0.515L285.158,277.832L285.158,277.832z"
    />



    

		</svg>
		</div>

	);//return
}