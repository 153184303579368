//import styled from "styled-components";
import styled from "styled-components/macro";



/*

var white_color = '#FFF';//'#EDEEF1' #EBECF1  #F9F9F9  #EEF3F9   

var yellow_color = '#FF9';
var green_color = '#D9FFD9';
var blue_color = '#D5E6FF';//'#B0D0FF';//

var soft_gray_color = "lightgray";
	
var mask_green_color = '#00FF00';
var mask_blue_color = '#0060BF';
var mask_trans_color = 'transparent';

*/






const Controls = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "50px",
    height:             props.height || "100vh",

    // width:              "800px",
    // height:             "400px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    // zIndex:             props.zIndex  || "3",

    // top:                props.top || "0px",
    // right:              props.right || "0x",
    // bottom:             props.bottom || "0px",  
    // left:               props.left || "0px",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
    
    // position:absolute;   
    // top:${props => props.top}; 
    // right:${props => props.right}; 
    // z-index:${props => props.zIndex}; 


    // ${props => props.on ? "green" : "red"}


}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;  
    float:right;
    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};




`;


const ControlsButton = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#1a1926",
    borderRadius:       props.borderRadius || "2px",

    width:              props.width || "40px",
    height:             props.height || "40px",

    // width:              "800px",
    // height:             "400px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "7px 0 0 5px",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "12px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",



    // -webkit-touch-callout: none; /* iOS Safari */
    // -webkit-user-select: none; /* Safari */
    // -khtml-user-select: none; /* Konqueror HTML */
    // -moz-user-select: none; /* Old versions of Firefox */
    // -ms-user-select: none; /* Internet Explorer/Edge */
    // user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    



}))`

    background-color:${props => props.backgroundColor};
    border-radius:${props => props.borderRadius};
 
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    cursor:pointer;


    .Text{

        background-color:transparent;
        width:100%;
        height:auto;

        padding:0 0 4px 0;
        margin:13px 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 

    }



`;





const ControlPanel = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "1px solid gray",

    width:              props.width || "325px",
    height:             props.height || "100%",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    zIndex:             props.zIndex  || "5",

    top:                props.top || "0px",
    right:              props.right || "0px",
    bottom:             props.bottom || "0px",  
    left:               props.left || "0px",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    border-left:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;  
    z-index:${props => props.zIndex};
    top:${props => props.top}; 
    right:${props => props.right}; 

    overflow-x:hidden;
    overflow-y:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};



`;






const ControlPanelButton = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",

    padding:            props.padding || "4px 0 4px 0",
    margin:             props.margin  || "0 0 0 0",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",



}))`

    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;


    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    cursor:pointer;


    &:hover{

        background-color:#EDEEF1;

    }

    .Text{

        background-color:transparent;
        width:95%;
        height:auto;

        padding:0 0 0 0;
        margin:0 0 0 12px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 

    }



`;






const ControlPanelWing = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "0",

    width:              props.width || "100%",
    height:             props.height || "40px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "14px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;  
    overflow:hidden;


    .Title{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:0 0 4px 0;
        margin:14px 20px 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }




    .XButton{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:14px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        cursor:pointer;

    }

    .XButton:hover{

        background-color:#818692;
       
    }




`;







const ControlPanelScroller = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "100%",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


}))`
  
    background-color:${props => props.backgroundColor};
    border-top:${props => props.border};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;  

    overflow-x:hidden;
    overflow-y:scroll;

`;




const ControlPanelCategory = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#ccc",
    border:             props.border || "0",

    width:              props.width || "100%",
    height:             props.height || "auto",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;  
    overflow:hidden;

    .Text{

        background-color:transparent;
        width:95%;
        height:auto;

        padding:5px 0 5px 7px;
        margin: 0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }



`;

const ControlPanelGroup = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",

    width:              props.width || "100%",
    height:             props.height || "auto",

    minHeight:          props.minHeight || "20px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

  
}))`
  
    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;  
    overflow:hidden;

`;




const ControlPanelTile = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "125px",


    padding:            props.padding || "4px 0 4px 0",
    margin:             props.margin  || "0 0 0 0",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "12px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",



    grfxWidth:              props.grfxWidth || "142px",
    grfxHeight:             props.grfxHeight || "100px",
    grfxMarginLeft:         props.grfxMarginLeft || "calc(50% - 71px)",





}))`

    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;


    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    cursor:pointer;


    &:hover{

        background-color:#EDEEF1;

    }



    .Grfx{

        background-color:transparent;

        width:${props => props.grfxWidth};
        height:${props => props.grfxHeight};

        padding:0 0 0 0;
        margin:5px 0 0 ${props => props.grfxMarginLeft};

        position:relative;
        float:left;


        overflow:hidden;

        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 




        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:white;




    }




    .Text{

        background-color:transparent;
        width:90%;
        height:auto;

        padding:0 0 0 0;
        margin:3px 0 0 5%;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 

    }



`;









export {

    Controls,
    ControlsButton,

    ControlPanel,
    // ControlPanelTop,
    // ControlPanelBottom,
    ControlPanelWing,
    ControlPanelScroller, 
    ControlPanelGroup,   
    ControlPanelTile,
    
    ControlPanelCategory, 
    ControlPanelButton

}
  


