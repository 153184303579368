export const BUTTON_LOGO_LUCKY_STRIKE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        

    
    {/*Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M360,450.001c109.057,0,198-89.036,198-197.923 c0-109.044-88.943-198.079-198-198.079c-109.054,0-198,89.035-198,198.079C162,360.965,250.946,450.001,360,450.001L360,450.001z"
    />
    {/*Gold*/}
    <path 
        fill="rgb(227,183,108)" 
        d="M360,435.491c101.057,0,183.521-82.385,183.521-183.414 c0-101.03-82.465-183.566-183.521-183.566s-183.521,82.536-183.521,183.566C176.479,353.106,258.943,435.491,360,435.491 L360,435.491z"
    />
    {/*White*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M360,423.241c94.227,0,171.17-76.999,171.17-171.16 c0-94.323-76.943-171.32-171.17-171.32c-94.224,0-171.169,76.997-171.169,171.32C188.831,346.242,265.776,423.241,360,423.241 L360,423.241z"
    />
    {/*Red*/}
    <path 
        fill="rgb(220,0,0)" 
        d="M360,402.882c83.11,0,150.874-67.787,150.874-150.8 c0-83.177-67.764-150.964-150.874-150.964s-150.874,67.787-150.874,150.964C209.126,335.095,276.89,402.882,360,402.882 L360,402.882z"
    />
    {/*Black*/}
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.554,251.579c-9.007,0-18.757-2.937-25.205-9.272v8.357h-50.104v-83.675 h29.213l0.163,58.916h14.019v-58.43h27.769v57.344c0.411,1.611,2.794,2.202,4.365,2.202c1.459,0,3.678-0.524,4.125-1.946v-57.6	h27.766v18.833c6.011-12.787,17.418-20.132,32.588-20.132c6.926,0,12.627,1.02,17.371,3.106v-1.969h28.254v17.375l8.201-17.388 l42.691,0.185l8.683,17.409l6.825-17.581h30.05l-22.801,48.833l0.182,34.679h-28.115v-34.593l-15.098-29.906l-9.824,21.004 l24.512,43.333h-32.473l-12.996-22.278v22.604h-28.091v-2.732c-5.216,1.96-11.054,2.913-17.789,2.913	c-0.593,0-1.19-0.005-1.791-0.02c-14.661-0.332-25.369-7.214-30.897-19.194C323.151,243.919,310.895,251.579,295.554,251.579 L295.554,251.579z M365.003,191.849c-3.082,0-5.734,1.119-7.88,3.322c-3.111,3.194-4.966,8.572-4.838,14.039 c0.12,3.925,1.334,16.847,11.807,16.847c3.13,0,6.807-1.084,10.932-3.219l2.602-1.35V196.32l-2.845-1.545	C371.211,192.834,367.924,191.849,365.003,191.849L365.003,191.849z M238.837,337.821c-6.607,0-16.31-1.435-21.984-4.178 l-2.568-1.242l0.259-28.505l6.771,4.145c5.177,3.175,10.351,4.927,15.813,5.357h0.327c3.501,0,3.533-0.959,3.565-1.98
		c0.025-2.152-2.424-3.539-8.022-6.168c-7.745-3.638-19.446-9.132-19.333-25.364c0-16.278,10.988-26.651,28.678-27.039 c0.438-0.009,0.865-0.013,1.291-0.013c6.123,0,11.478,0.92,15.728,2.682l0.013-1.207h92.358c7.36,0,13.988,5.627,17.839,12.654 l0.026-12.816h26.392v-0.163h28.252v17.303l8.191-17.281l31.885-0.237l-0.258,0.541h41.957v24.146l-22.013-0.164v4.258h19.259 v22.652h-19.464l-0.077,8.021h22.665l-0.259,23.97l-39.399-0.15l0.148,0.295h-32.054l-10.745-20.676v20.834h-28.089v-0.158h-26.572 v-0.316l-22.132,0.176l-9.023-19.011v19.31h-27.769v-59.389l-8.345,0.099v59.167l-28.913-0.237l0.162-58.825l-8.328,0.096	l0.097,10.302l-7.689-7.303c-4.082-3.877-8.707-5.257-11.673-5.257c-3.684,0-3.714,1.677-3.725,2.228 c-0.018,1.174,3.987,2.919,6.913,4.196c8.316,3.625,20.883,9.104,20.767,26.751c-0.207,14.182-10.765,28.503-30.546,28.503
		L238.837,337.821L238.837,337.821z M369.452,321.184l0.057-26.403c-2.674,4.499-6.354,7.048-9.236,9.029L369.452,321.184 L369.452,321.184z M454.979,313.769l0.091-40.757l-10.08,20.976L454.979,313.769L454.979,313.769z M338.905,285.881 c1.616-0.113,3.412-0.382,4.431-0.994c0.425-0.256,1.308-0.784,1.308-3.835c0-2.112,0-3.637-5.739-4.104V285.881L338.905,285.881z"
    />
    {/*White*/}
    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.973,171.498h-20.232v74.657h41.112v-15.742h-20.718 L244.973,171.498L244.973,171.498z M268.137,171.983v57.617c5.343,22.724,48.234,23.858,55.033,0v-57.617h-18.775v53.562 c-1.295,8.114-16.349,7.952-17.482-0.324v-53.237H268.137L268.137,171.983z M376.924,173.932
		c-4.856-2.434-10.682-3.246-16.671-3.246c-23.308,0-32.698,18.503-32.858,38.952c-0.162,20.614,9.228,36.518,30.755,37.007 c6.958,0.162,13.27-0.649,19.098-3.086l-0.161-16.716c-19.425,10.063-28.812-1.786-29.299-17.53 c-0.322-13.956,10.037-28.891,29.137-18.5V173.932L376.924,173.932z M382.121,171.822v74.658h19.099v-34.733l20.073,34.408h22.172 l-21.851-38.626l14.729-31.488l19.747,39.115v31.162h19.1l-0.162-31.162l20.232-43.334h-19.908l-9.387,24.181l-11.979-24.02 l-37.066-0.161l-15.538,32.947v-32.947H382.121L382.121,171.822z M260.404,261.09c-3.885-2.435-10.034-3.897-17.966-3.734	c-14.89,0.327-24.279,8.441-24.279,22.561c-0.159,23.212,27.518,18.989,27.354,31.647c-0.159,5.032-4.045,6.495-8.739,6.33
		c-6.151-0.483-11.977-2.434-17.804-6.006l-0.163,17.695c4.694,2.268,13.597,3.73,20.07,3.73 c17.966,0.162,26.221-12.821,26.383-24.021c0.163-24.344-27.84-20.451-27.679-31.001c0.163-8.762,13.761-8.925,22.985-0.163 L260.404,261.09L260.404,261.09z M263.82,258.817l-0.161,15.093l14.243-0.163l-0.162,58.919l19.91,0.163v-59.082l13.918-0.162 l-0.161-14.768H263.82L263.82,258.817z M374.083,258.655l-0.162,74.174h20.069v-74.174H374.083L374.083,258.655z M501.519,258.817 h-41.922l-0.163,73.685l42.246,0.164l0.163-14.936h-22.661l0.162-17.038h19.422v-13.636h-19.261v-13.31l22.014,0.163V258.817 L501.519,258.817z M457.134,258.329l-21.851,0.162l-15.539,32.783v-32.783h-19.26v74.495h19.097v-34.729l17.968,34.571h22.014
		l-19.586-38.794L457.134,258.329L457.134,258.329z M334.409,272.286v18.179c9.224,0.163,14.731-0.973,14.731-9.413 C349.14,273.747,344.121,272.286,334.409,272.286L334.409,272.286z M314.986,258.817h36.743	c8.094-0.162,16.833,11.036,16.833,20.773c0.16,15.907-8.902,18.505-14.083,22.885l15.863,30.026l-20.233,0.164l-12.785-26.945 h-3.561v27.266h-18.778V258.817L314.986,258.817z"
    />
    
    

    

		</svg>
		</div>

	);//return
}