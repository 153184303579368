import styled from "styled-components";



//#####################################################
//X GRID
//#####################################################


const XGrid = styled.div.attrs(props => ({ 
    
    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",
    width:              props.width || "70%",
    height:             props.height || "auto",
    padding:            props.padding || "10px 0 50px 0",
    margin:             props.margin || "auto",
    justifyContent:     props.justifyContent || "center"

}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    max-width:600px;
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    display: flex;
    flex-wrap: wrap;
    justify-content:${props => props.justifyContent};



    @media (max-width: 1050px) {

        width:86%;
  
    }

    @media (max-width: 500px) {
    
        width:90%;
    
    }


`;

    const XCell = styled.div.attrs(props => ({ 
        
        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "0",
        height:             props.height || "auto",
        margin:             props.margin || "0px",

        flex:               props.flex || "1 1 99%",
        flex4:              props.flex4 || "0 1 25%",
        flex3:              props.flex3 || "0 1 33.3333%",
        flex2:              props.flex2 || "0 1 50%",
        flex1:              props.flex1 || "0 1 100%"


    }))`

        background-color: ${props => props.backgroundColor};
        border: ${props => props.border}; 
        height: ${props => props.height}; 
        flex: ${props => props.flex};    
        margin: ${props => props.margin}; 
        overflow: visible;


    `;


        const XContainer = styled.div.attrs(props => ({ 
                
            backgroundColor:    props.backgroundColor || "white",
            border:             props.border || "1px solid black",
            borderRadius:       props.borderRadius || "7px",
            width:              props.width || "100%",
            height:             props.height || "auto",
            padding:            props.padding || "7px 0 7px 0",
            margin:             props.margin || "0 0 15px 0",
            position:           props.position || "relative",  
            float:              props.float || "left"

        }))`

            background-color: ${props => props.backgroundColor}; 
            border: ${props => props.border}; 
            border-radius: ${props => props.borderRadius}; 
            width: ${props => props.width}; 
            height: ${props => props.height}; 
            padding: ${props => props.padding};
            margin: ${props => props.margin};
            position: ${props => props.position}; 
            float: ${props => props.float};  
            
            
            cursor:pointer;

        `;


        const XGraphic = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "lightgray",
            width:              props.width || "80px",
            height:             props.height || "80px",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 0",
            position:           props.position || "relative",  
            float:              props.float || "left"

        }))`

            background-color:${props => props.backgroundColor};
            border:1px solid #1A1926;

            width   :${props => props.width};
            height  :${props => props.height};
        
            padding :0 0 0 0;
            margin  :${props => props.margin};
        
            overflow:visible;
        
        


        `;


        const XName = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "90%",
            height:             props.height || "auto",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 15px",
            position:           props.position || "relative",  
            float:              props.float || "left",
            textAlign:          props.textAlign || "left"

        
        }))`

            background-color:${props => props.backgroundColor};

            width   :${props => props.width};
            height  :${props => props.height};

            padding:4px 0 4px 0;
            margin  :${props => props.margin};

            position:relative;
            float:left;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:22px;
            font-weight:bold;
            color:#000;
            text-align:${props => props.textAlign};
        
        `;





        const XDescr = styled.div.attrs(props => ({ 
            
            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "90%",
            height:             props.height || "auto",
            padding:            props.padding || "0 0 0 0",  
            margin:             props.margin || "0 0 0 15px",
            position:           props.position || "relative",  
            float:              props.float || "left",
            textAlign:          props.textAlign || "left"

        
        }))`

            background-color:${props => props.backgroundColor};
        
            width   :${props => props.width};
            height  :${props => props.height};

            padding:4px 0 4px 0;
            margin  :${props => props.margin};

            position:relative;
            float:left;
            overflow:hidden;

            font-family:Arial, Helvetica, sans-serif;
            font-size:18px;
            font-weight:normal;
            color:#000;
            text-align:${props => props.textAlign};
        
        `;








export {


    XGrid,
    XCell,
    XContainer,
    XGraphic,
    XName,
    XDescr

}
