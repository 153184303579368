export const ROLLBAR_ORANGE_IMOLA_ORANGE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


    {/*Rollbar*/}
    <polygon  
        fill="rgb(247,148,30)" 
        points="183.587,191.877 229.675,153.205 241.115,195.9 257.658,197.057 243.172,142.992 286.217,129.83 383.633,135.785 373.529,120.376 283.919,114.895 230.858,131.117 160.381,190.254 183.587,191.877 	"
    />
    {/*Rollbar Shadow*/}
    <polygon 
        fill="rgb(243,112,33)" 
        points="378.475,127.918 287.023,122.324 236.453,137.785 172.881,191.129 183.587,191.877 229.675,153.205 241.115,195.9 250.435,196.552 237.141,146.939 241.086,143.629 286.229,129.828 379.252,135.518 427.38,208.925 431.787,209.232 378.475,127.918 	"
    />
    {/*Trim*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M248.915,190.881l5.121,3.306l140.989,9.858l-0.548,2.579l-243.359-17.018 c7.452-6.253,8.916-7.483,17.935-6.595L248.915,190.881L248.915,190.881z"
    />
    





    

		</svg>
		</div>

	);//return
}