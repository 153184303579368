
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />



https://stackoverflow.com/questions/43702454/reactjs-fade-in-div-and-fade-out-div-based-on-state


        transition: opacity 0.5s;
        opacity:${props => (props.opacity ? '1' : '0')};






**************************************************

.FlexContainer{ 
    display: flex; 
    min-height: 200px;  
    font-size:15px; 
} 

.FlexColumns{ 
    flex: 1; 
    padding: 10px; 

} 
.FlexColumns:nth-of-type(even){ 
    flex: 2; 
    
} 
.FlexColumns:nth-of-type(odd){ 
    background: #85929E; 
    color: white; 
} 
.FlexColumns:nth-of-type(even){ 
    background: #A5DDEF; 
    color: green; 
} 



<div
className={Styl.FlexContainer}
>
    <div
    className={Styl.FlexColumns}
    >

        Column 1
    </div>
    <div
    className={Styl.FlexColumns}
    >

        Column 2
    </div>
    <div
    className={Styl.FlexColumns}
    >

        Column 3
    </div>
    <div
    className={Styl.FlexColumns}
    >

        Column 4
    </div>

</div>




*************************************************














*/



    
// export const NavFade = styled.div.attrs(props => ({

//     backgroundColor:    props.backgroundColor || "transparent",
//     width:              props.width || "100%",
//     height:             props.height || "auto",
//     padding:            props.padding || "0 0 0 0",
//     margin:             props.margin  || "0 0 0 0",

//     opacity:            props.opacity || '1'



// }))`

//     background-color:${props => props.backgroundColor};
//     width:${props => props.width};
//     height:${props => props.height};
//     padding:${props => props.padding};
//     margin:${props => props.margin};

//     position:relative;   
//     float:left;

//     transition: opacity 1s;
//     opacity:${props => props.opacity};


// `;     



export const Nav = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;
`;





    export const NavTrack = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "100%",
        height:             props.height || "auto",//54px
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        overflow:visible;
    `;



        export const NavBar = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#1a1926",
            border:             props.border || "1px solid #1a1926",
            width:              props.width || "100%",
            height:             props.height || "35px",//54px
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            border:${props => props.border};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;
        `;












        //#######################################################################
        //https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/

        //  grid-template-columns: repeat(6, auto);

       //  grid-template-columns: repeat(6, minmax(275px, 1fr));





        //#######################################################################

        export const NavGrid = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "100%",
            height:             props.height || "100%",
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0",


            opacity:            props.opacity || '1'


            //transition: opacity 0.5s;
            //opacity:${props => props.opacity};


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

 
            position:absolute;   
            top:0px;
            left:0px;
            z-index:1;

            display:inline-grid;
            grid-template-columns: repeat(6, auto);




        `;


            export const Button = styled.div.attrs(props => ({

                backgroundColor:    props.backgroundColor || "transparent",
                width:              props.width || "100%",
                height:             props.height || "auto",
                padding:            props.padding || "0 0 0 0",
                margin:             props.margin  || "0 0 0 0"


            }))`

                background-color:${props => props.backgroundColor};
                width:${props => props.width};
                height:${props => props.height};
                padding:${props => props.padding};
                margin:${props => props.margin};

                position:relative;   
                float:left;


            `;




                export const ButtonText = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "transparent",
                    width:              props.width || "auto",
                    height:             props.height || "auto",
                    padding:            props.padding || "11px 15px 11px 15px",
                    margin:             props.margin  || "auto"


                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;
        
                    display:block;
            
                    font-family:"Verdana", sans-serif;      
                    font-size: 12px;
                    font-weight: normal;
                    text-align: center;
                    text-decoration:none;
                    color:#fff;


                    &:hover{

                        background-color:#818692;
                        color:white;
                    }




                `;


                export const ButtonTextHold = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "lightseagreen",
                    width:              props.width || "auto",
                    height:             props.height || "auto",
                    padding:            props.padding || "11px 15px 11px 15px",
                    margin:             props.margin  || "auto"


                }))`

                    background-color:${props => props.backgroundColor};
                    width:${props => props.width};
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;
        
                    display:block;
            
                    font-family:"Verdana", sans-serif;      
                    font-size: 12px;
                    font-weight: normal;
                    text-align: center;
                    text-decoration:none;
                    color:#fff;


                `;









    



        //#######################################################################
        //#######################################################################


        export const FlexNav = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "0 0 0 0",
            margin:             props.margin  || "0 0 0 0",


            opacity:            props.opacity || '1'


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

 
            position:absolute;   
            top:0px;
            left:10px;
            z-index:1;



        `;




            export const FlexGrid = styled.div.attrs(props => ({

                backgroundColor:    props.backgroundColor || "darkgreen",
                

            }))`


                background-color:${props => props.backgroundColor};
                width:100%;

                position:relative;
                float:left;

                display: flex;   
                font-size:15px; 
                min-height:35px


                .Button{ 

                    background-color:pink;
                    flex: 1; 
                    padding: 10px; 
                
                } 
                .Button:nth-of-type(odd){ 
                    background: #85929E; 
                    color: white; 
                } 
                .Button:nth-of-type(even){ 
                    background: #A5DDEF; 
                    color: green; 
                } 
                


            `;




                export const FlexButton = styled.div.attrs(props => ({

                    backgroundColor:    props.backgroundColor || "orange",
                    width:              props.width || "100%",
                    height:             props.height || "35px",
                    padding:            props.padding || "0 0 0 0",
                    margin:             props.margin  || "0 0 0 0"


                }))`

                    background-color:${props => props.backgroundColor};
         
                    height:${props => props.height};
                    padding:${props => props.padding};
                    margin:${props => props.margin};

                    position:relative;   
                    float:left;

                    flex: 1; 
                    padding:0; 



                `;




                    export const FlexButtonText = styled.div.attrs(props => ({

                        backgroundColor:    props.backgroundColor || "darkred",
                        width:              props.width || "auto",
                        height:             props.height || "auto",
                        padding:            props.padding || "11px 15px 11px 15px",
                        margin:             props.margin  || "auto"


                    }))`

                        background-color:${props => props.backgroundColor};
                        width:${props => props.width};
                        height:${props => props.height};
                        padding:${props => props.padding};
                        margin:${props => props.margin};

                        position:relative;
            
                        display:block;
                
                        font-family:"Verdana", sans-serif;      
                        font-size: 12px;
                        font-weight: normal;
                        text-align: center;
                        text-decoration:none;
                        color:#fff;


                        &:hover{

                            background-color:#818692;
                            color:white;
                        }




                    `;


                    export const FlexButtonTextHold = styled.div.attrs(props => ({

                        backgroundColor:    props.backgroundColor || "lightseagreen",
                        width:              props.width || "auto",
                        height:             props.height || "auto",
                        padding:            props.padding || "11px 15px 11px 15px",
                        margin:             props.margin  || "auto"


                    }))`

                        background-color:${props => props.backgroundColor};
                        width:${props => props.width};
                        height:${props => props.height};
                        padding:${props => props.padding};
                        margin:${props => props.margin};

                        position:relative;
            
                        display:block;
                
                        font-family:"Verdana", sans-serif;      
                        font-size: 12px;
                        font-weight: normal;
                        text-align: center;
                        text-decoration:none;
                        color:#fff;


                    `;








        










//##########################################
//Button
//##########################################


/*

        export const Button = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "10px 15px 13px 15px",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;

            display:block;

            font-family:"Verdana", sans-serif;      
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;


            &:hover {

                background-color:transparent;
                color:lightseagreen;

            }

        `;



        export const ButtonHold = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "#818692",
            width:              props.width || "auto",
            height:             props.height || "100%",
            padding:            props.padding || "10px 15px 13px 15px",
            margin:             props.margin  || "0 0 0 0"


        }))`

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            padding:${props => props.padding};
            margin:${props => props.margin};

            position:relative;   
            float:left;

            display:block;
        
            font-family:"Verdana", sans-serif;      
            font-size: 14px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;

        `;



*/










