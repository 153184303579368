import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


/*
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,  
    //useHistory,
    //useParams


} from "react-router-dom";

*/


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
import useDeviceDetect from 'utils/hooks/DeviceDetect';
import useScreenOrientation from 'utils/hooks/ScreenOrientation';


import {

    Screen,
    Alfa,
    Base,
    Section

} from './Styled';


import FGrid from 'utils/components/Grid/FGrid';


//import { Fade } from 'utils/components/Transitions';


//import Overlay from './Overlay';



const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));

    /*

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    */


    // -- window dims -- //
    const { 
        width, 
        height
    } = useWindowDimensions();

    // -- device detect -- //
    const { isMobile } = useDeviceDetect();

    // -- screen orientation -- //
    const screenOrientation = useScreenOrientation()




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);
    // const refControls = useRef(null);



    //---------------------------------------------
    // state - fade variables
    //---------------------------------------------

    //const [fadeImage, setFadeImage] = useState(true);


    //---------------------------------------------
    // state/effect - boardInfo, currentScreen
    //---------------------------------------------
    const [isLoaded, setIsLoaded] = useState(false);
    const [isWide, setIsWide] = useState(true);
    const [threshold] = useState(800);
    const [currentOrientation, setCurrentOrientation] = useState(false);
    const [currentScreen, setCurrentScreen] = useState({

        height:"550px",
        info:false,
        alfa:false,
        base:false,
        controller:false,
        images:[]

    });

 
    useEffect(() => {



        // -- effect -- //

        const effectCurrentScreen = (eff) => {

            setIsLoaded(true);

            const props = eff.props;
            //const isMobile = eff.isMobile;
            const screenOrientation = eff.screenOrientation;
            const items = eff.items;
            //const currentScreen = eff.currentScreen;

            let images = [];       
            let imgW,imgH,imgPerc,imgChoice;
            
            let itemA = items[0];
            let itemB = items[1];

            if(itemA.image && itemA.image !== undefined)
            {
                if(itemA.image.w && itemA.image.w !== undefined)
                {
                    imgW = parseInt(itemA.image.w);
                    imgH = parseInt(itemA.image.h);
                    imgPerc = (imgH/imgW) * 100;
                    if(imgPerc < 50)
                    {
                        imgChoice={
                            wide:itemA,
                            mobi:itemB
                        }

                    }else{

                        imgChoice={
                            wide:itemB,
                            mobi:itemA
                        }
                    } 
                }
            }


            var screenHeight = "550px";
            // switch(screenOrientation)
            // {
            // case'PORTRAIT':

            //     images.push(imgChoice.mobi);
            //     screenHeight = images[0].image.height + "px";
            // break;
            // default:
            //     images.push(imgChoice.wide);
            //     screenHeight = "550px";
            // }


            if(!eff.wide)
            {
                images.push(imgChoice.mobi);
                screenHeight = images[0].image.height + "px";
            }else{
                images.push(imgChoice.wide);
                screenHeight = "550px";
            }
            setIsWide(eff.wide);

        
            setCurrentOrientation(screenOrientation);
            setCurrentScreen({

                height:screenHeight,
                info:props.data.info,
                items:images,
                alfa:false,
                base:false,
                controller:false

            });


        };





        // -- cause -- //

        var wide;
        var cause = false;
        if(!isLoaded) cause = true;
        if(currentOrientation !== screenOrientation) cause = true;
        if(width < threshold && isWide) 
        {
            cause = true;
            wide = false;
        }
        if(width > threshold && !isWide) 
        {
            cause = true;
            wide = true;
        }
        if(cause)
        {
            //console.log('cause - effect');
            effectCurrentScreen({
        
                props:props,
                width:width,
                threshold:threshold,
                wide:wide,
                isMobile:isMobile,
                screenOrientation:screenOrientation,
                items:props.data.items,
                currentScreen:currentScreen

            });

        }

        //console.log("WideScreen width: "+width+" "+threshold);
        //console.log("currentScreen: "+JSON.stringify(currentScreen,null,2));


    },
    [

        props, 
        width,height,  
        isMobile,
        screenOrientation, 
        isLoaded,
        isWide,
        threshold,
        currentOrientation,
        currentScreen,
        refA
 
    ])









    //---------------------------------------------
    // handle - general
    //---------------------------------------------

    // const handle = (hndl) => {


    //     // var obj;
    //     // var num;

    //     //alert(JSON.stringify(hndl));
    //     switch(hndl.call)
    //     {
    //     case'size':




    //         //  obj = boardInfo;
    //         // if(currentScreen.info.view === 'wideScreen') 
    //         // {
    //         //     //alert(hndl.size.height.toString() + " --- " +currentScreen.info.view);
    //         //     //num = hndl.size.height.toString();
    //         //     num = currentScreen.info.viewHeight.toString();
    //         //     if(num.match(/px/gi))
    //         //     {
    //         //         num = num.replace(/px/gi,"");  
    //         //     }
    //         //     //console.log("wideScreen Number: "+num);

    //         //     obj.viewHeight = num+"px";
    //         //     setBoardInfo(obj);
    //         // }
    //         // else
    //         // if(currentScreen.info.view === 'fullScreen') 
    //         // {
    //         //     if(refA.current.offsetWidth - 200 < refA.current.offsetHeight)
    //         //     {
    //         //         obj.viewHeight = "auto";
    //         //         setBoardInfo(obj);
    //         //     }
    //         // }
    //         // else
    //         // {
    //         //     //alert(hndl.size.height.toString() + " --- " +currentScreen.info.view);
    //         //     num = hndl.size.height.toString();
    //         //     if(num.match(/px/gi))
    //         //     {
    //         //         num = num.replace(/px/gi,"");  
    //         //     }
    //         //     //console.log("size number: "+num);

    //         //     obj.viewHeight = num+"px";
    //         //     setBoardInfo(obj);

    //         // }
        

    //     break;
    //     case'progressChange':

    //         // num = boardNumber + 1;
    //         // if(num === boards.length) num = 0;

    //         // //alert(hndl.call+" "+num);
    //         // setBoardNumber(num);

    //     break;
    //     case'clickChange':

    //         // setBoardNumber(hndl.number);
    //     break;
    //     default:
    //     }

    // }












    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

    
            <Screen
            ref={refA}          
            //height={boardInfo.viewHeight}
            //height={currentScreen.height}
            height={"auto"}
            >
                <Section 
                >
                </Section>

            </Screen>

        )

    } 
    else 
    {



        return (


            <Screen
            ref={refA}
            //backgroundColor={'red'}
            //height={currentScreen.height}
            height={"auto"}
            >


                {
                currentScreen.alfa 
                ?

                    <Alfa

                    backgroundColor     ={currentScreen.alfa.backgroundColor}
                    //backgroundColor     ={"darkblue"}  

                    border              ={currentScreen.alfa.border}
                    borderRadius        ={currentScreen.alfa.borderRadius}

                    percent             ={currentScreen.alfa.percent}
                    decimal             ={currentScreen.alfa.decimal}

                    zIndex              ={currentScreen.alfa.zIndex}
                    //zIndex              ={"1"}    

                    width               ={currentScreen.alfa.width}
                    height              ={currentScreen.alfa.height}

                    top                 ={currentScreen.alfa.top}
                    left                ={currentScreen.alfa.left}

                    >  
                    </Alfa>

                :null
                }


                {
                currentScreen.base 
                ?

                    <Base
                                        
                    backgroundColor     ={currentScreen.base.backgroundColor}
                    //backgroundColor     ={"darkred"}

                    border              ={currentScreen.base.border}
                    borderRadius        ={currentScreen.base.borderRadius}

                    zIndex              ={currentScreen.base.zIndex}
                    //zIndex              ={"2"}

                    width               ={currentScreen.base.width}
                    height              ={currentScreen.base.height}

                    top                 ={currentScreen.base.top}
                    left                ={currentScreen.base.left}

                    > 
{/* 
                        {currentScreen.overlay && currentScreen.overlay.boolean === 1
                        ?
                            <Overlay

                                // databind={props.databind}
                                // data={props.data}  
                                
                                databind={props.databind}
                                data={currentScreen.overlay} 
                                handle={(v) => handle(v)} 
                                totalBoards={boards.length}

                            />

                        :
                        currentScreen.overlay && currentScreen.overlay.boolean === undefined
                        ?
                            <Overlay

                                // databind={props.databind}
                                // data={props.data}  
                                
                                databind={props.databind}
                                data={currentScreen.overlay} 
                                handle={(v) => handle(v)} 
                                totalBoards={boards.length}

                            />

                        :null} */}


                    </Base>

                :null
                }




                {
                currentScreen.items.length > 0
                ?


                    <Section 
                    >

                        {

                        <FGrid

                            handle      = {(v) => props.handle(v)} 
                            info        = {currentScreen.info}          
                            items       = {currentScreen.items}
                            refid       = {props.refid}
                            
                        />

                        }


                    </Section>


                :null
                }


            </Screen>

                   
        )


    }


}

export default Index;



