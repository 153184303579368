
import styled from "styled-components";


/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;

    position:absolute;   
    z-index:3;
    top:0;
    left:0

*/



const Columns = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

    width:              props.width || "100%", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};

    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:relative;
    float:left;

`;



    const Full = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

        width:              props.width || "90%", 
        height:             props.height || "auto",

        margin:            props.margin || "auto",
        padding:           props.padding || "0 0 0 0",


    }))`

        background-color:${props => props.backgroundColor};

        max-width:1366px;
        min-height:10px;

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};


        display:flex;
        align-items: left;



        @media (max-width: 1680px) {

            width: 84%;
            
        }
        @media (max-width: 1250px) {
    
            width: 96%;
    
        }
        @media (max-width: 800px) {
    
            width: 70%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

        }
        @media (max-width: 650px) {
    
            width: 80%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center; 
    
        }
        @media (max-width: 550px) {
    
            width: 90%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

        }
    
    


    `;




    const Page = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

        width:              props.width || "96%", 
        height:             props.height || "auto",

        margin:            props.margin || "0 0 0 0",
        padding:           props.padding || "0 auto 0 auto",


    }))`

        background-color:${props => props.backgroundColor};

        max-width:1366px;
        min-height:10px;

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        display:flex;
        align-items: left;


        @media (max-width: 800px) {

            width: 96%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

        }
        @media (max-width: 650px) {
    
            width: 96%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;

        }
        @media (max-width: 550px) {
    
            width: 96%;

            display:flex;
            flex-wrap: wrap;
            justify-content: center;
    
        }
    


    `;






    const Small = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc

        width:              props.width || "100%", 
        height:             props.height || "auto",

        margin:            props.margin || "0 auto 0 auto",
        padding:           props.padding || "0 0 0 0",


    }))`

        background-color:${props => props.backgroundColor};

        max-width:1366px;
        min-height:10px;

        width   :${props => props.width};
        height  :${props => props.height};

        padding :${props => props.padding}; 
        margin  :${props => props.margin};

        display:flex;
        flex-wrap: wrap;
        justify-content: center;
    

    `;














    const Box = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
        border:             props.border || "1px solid lightgray",

        width:              props.width || "96%", 
        height:             props.height || "auto",

        margin:            props.margin || "20px 20px 20px 20px",
        padding:           props.padding || "0 2% 0 2%",


    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};

        min-height:200px;

        width   :${props => props.width};
        height  :${props => props.height};
        margin  :${props => props.margin};
        padding  :${props => props.padding};


        flex-grow:1;
    
        position:relative;

        @media screen and (max-width: 800px) {

            width:100%;
            flex-grow:0;
            margin:20px 0 20px 0;

        }


    `;




        const Controls = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "transparent"

        }))`


            background-color:${props => props.backgroundColor};
        
            width:auto;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;

            position:absolute;
            top:4px;
            right:7px;
            z-index:3;



            .Button {

                background-color:transparent;
        
                width:40px;
                height:40px;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:relative;
                float:left;

                cursor:pointer;


            }

            .ButtonMask {

                background-color:transparent;
        
                width:40px;
                height:40px;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:absolute;
                top:0px;
                left:0px;
                z-index:1;

                cursor:pointer;

            }


            .Img {

                width:40px;
                height:40px;

            }


        `;




    const Heading = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`

        background-color:${props => props.backgroundColor};
      
        width:auto;
        height:auto;
        margin:15px 0 0 0;
        padding:0 0 0 0;


        position:relative;
        float:left;



        font-size: 22px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:#454d5f;
        line-height: 1.0;


    `;



    const BulletHeading = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",

        //bullet
        bulletColor:        props.bulletColor || "red"

    }))`

        background-color:${props => props.backgroundColor};
        
        width:auto;
        height:auto;
        margin:15px 0 0 0;
        padding:0 0 0 0;


        position:relative;
        float:left;





            .Bullet{

                background-color:${props => props.bulletColor};
                border:1px solid lightgray;
                border-radius: 13px;

                width:13px;
                height:13px;

                margin:3.5px 10px 0 0;


                position:relative;
                float:left;
        


           
            }


            .Text{

                background-color:${props => props.textBgColor};

                width:auto;
                height:auto;
                margin:0 0 0 0;
                padding:0 0 0 0;

                position:relative;
                float:left;

                font-size: 22px;
                font-weight: normal;
                text-align: left;
                text-decoration:none;
                color:#454d5f;
                line-height: 1.0;
        
            }


    `;













    const Stripe = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "#818692"

    }))`

        background-color:${props => props.backgroundColor};
      
        width:100%;
        height:1px;
        margin:12px 0 15px 0;
        padding:0 0 0 0;

        position:relative;
        float:left;

    `;


    const Pgraph = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`

        background-color:${props => props.backgroundColor};
      
        width:auto;
        height:auto;
        margin:30px 0 25px 0;

        padding:0 4px 0 2px;

        font-size: 16px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:black;
        line-height: 1.3;


    `;





    const Info = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`

        background-color:${props => props.backgroundColor};
      
        width:94%;
        height:auto;
        margin:0 0 40px 3%;

        padding:0 0 0 0;

        position:relative;
        float:left;


        .SingleLine {

            background-color:transparent;
            border-bottom:1px solid lightgray;
         
            width:100%;
            height:auto;
            margin:0 0 0 0;

            padding:7px 0 7px 0;

            position:relative;
            float:left;

            font-size: 16px;
            font-weight: normal;
            text-align: left;
            text-decoration:none;
            color:black;
            line-height: 1.3;


        }


        .Line {

            background-color:transparent;
            border-bottom:1px solid lightgray;
            
            width:100%;
            height:auto;
            margin:0 0 0 0;

            padding:0 0 0 0;

            position:relative;
            float:left;



        }



            .Name {


                background-color:aqua;

                width:35%;
                height:auto;
                margin:0 0 0 0;

                padding:10px 0 10px 0;

                position:relative;
                float:left;

                font-size: 16px;
                font-weight: normal;
                text-align: left;
                text-decoration:none;
                color:black;
                line-height: 1.3;
                

            }


            .Value {

                background-color:pink;

                width:65%;
                height:auto;
                margin:0 0 0 0;

                padding:10px 0 10px 0;

                position:relative;
                float:left;

                font-size: 16px;
                font-weight: normal;
                text-align: right;
                text-decoration:none;
                color:black;
                line-height: 1.3;
                

            }



    `;





    const List = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`

        background-color:${props => props.backgroundColor};
    
        width:94%;
        height:auto;
        margin:0 0 40px 3%;

        padding:0 0 0 0;


        position:relative;
        float:left;



        .Line {

            background-color:transparent;
            border-bottom:1px solid lightgray;
            
            width:100%;
            height:auto;
            margin:0 0 0 0;

            padding:0 0 0 0;

            position:relative;
            float:left;



        }


            .Name {


                background-color:transparent;

                width:60%;
                height:auto;
                margin:0 0 0 0;

                padding:10px 0 10px 0;

                position:relative;
                float:left;

                font-size: 16px;
                font-weight: normal;
                text-align: left;
                text-decoration:none;
                color:black;
                line-height: 1.3;
                

            }


            .Value {

                background-color:transparent;

                width:40%;
                height:auto;
                margin:0 0 0 0;

                padding:10px 0 10px 0;

                position:relative;
                float:left;

                font-size: 16px;
                font-weight: normal;
                text-align: right;
                text-decoration:none;
                color:black;
                line-height: 1.3;
                

            }





    `;





    const AccountList = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent"

    }))`

        background-color:${props => props.backgroundColor};
    
        width:94%;
        height:auto;
        margin:0 0 40px 3%;

        padding:0 0 0 0;


        position:relative;
        float:left;



        .Line {

            background-color:transparent;
            border-bottom:1px solid lightgray;
            
            width:100%;
            height:auto;
            margin:0 0 0 0;

            padding:0 0 0 0;

            position:relative;
            float:left;



        }


            .Name {


                background-color:lightblue;

                width:40%;
                height:auto;
                margin:0 0 0 0;

                padding:10px 0 10px 0;

                position:relative;
                float:left;

                font-size: 16px;
                font-weight: normal;
                text-align: left;
                text-decoration:none;
                color:black;
                line-height: 1.3;
                

            }


            .Value {

                background-color:lightgreen;

                width:60%;
                height:auto;
                margin:0 0 0 0;

                padding:10px 0 10px 0;

                position:relative;
                float:left;

                font-size: 16px;
                font-weight: normal;
                text-align: right;
                text-decoration:none;
                color:black;
                line-height: 1.3;
                

            }





    `;






    /*

    https://www.w3schools.com/howto/howto_css_aspect_ratio.asp

    padding-top: 100%       1:1 Aspect Ratio 
    padding-top: 56.25%;    16:9 Aspect Ratio (divide 9 by 16 = 0.5625) 
    padding-top: 75%;       4:3 Aspect Ratio (divide 3 by 4 = 0.75) 
    padding-top: 66.66%;    3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  
    padding-top: 62.5%;     8:5 Aspect Ratio (divide 5 by 8 = 0.625) 

    */



    const Thmb = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "black"

    }))`

        background-color:${props => props.backgroundColor};
    
        border:1px solid gray;
        position: relative;
        width: 99.5%;
        padding-top: 57%; 
        margin:0 auto 0 auto;
        overflow:hidden;


        @media screen and (max-width: 800px) {

            width:99.5%;
        }


        .Holder {

            position: absolute;
            width: 100%;
            height:100%;

            top:0px;
            left:0px;

            margin:0 0 0 0;

            overflow:hidden;

        }


            .Img{

                width:100%;
                height:auto;
                margin:0 0 15px 0;
                position:relative;

            }



    `;










export {

    Columns,

    Full,
    Page,
    Small,

    Box,
    Controls,
    
    Heading,
    BulletHeading,

    Stripe,
    Pgraph,


    Info,
    List,
    Thmb,



    AccountList

}
