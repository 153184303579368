export const BUTTON_MAIN_RACING_WING = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Racing Wing*/}

    <path 
        fill="rgb(150,150,150)" 
        d="M189,169.832v143.512c0,24.475,18.332,44.018,44.575,47.524 l203.73,21.038h45.961c12.724,0,24.703-4.974,33.729-14.001c9.033-9.031,14.006-21.011,14.004-33.731l-0.011-164.346 c-0.003-26.319-21.415-47.734-47.732-47.734H236.734C210.412,122.094,189,143.509,189,169.832L189,169.832z"
    />

{/*Number*/}

    <path 
        fill="rgb(255,255,255)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.159,287.486v-83.361c-4.661,1.499-9.162,2.65-13.471,3.449 c-4.275,0.828-8.521,1.309-12.703,1.499v-33.865c8.428-1.437,15.576-3.704,21.451-6.831c5.903-3.13,10.629-7.123,14.172-11.975	h38.432v131.084h21.977v42.567h-95.241v-42.567H338.159L338.159,287.486z"
    />
    





    

		</svg>
		</div>

	);//return
}