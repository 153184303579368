//import styled from "styled-components";
import styled from "styled-components/macro";

const MobiControls = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "yellow",

    width:              props.width || "100%",
    height:             props.height || "50px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    zIndex:             props.zIndex  || "3",

    top:                props.top || "0px",
    right:              props.right || "0x",
    bottom:             props.bottom || "0px",  
    left:               props.left || "0px",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;  
    z-index:${props => props.zIndex};
    bottom:${props => props.bottom};
    left:${props => props.left};

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};



`;


const MobiControlsButton = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#1a1926",
    borderRadius:       props.borderRadius || "0px",


    width:              props.width || "100%",
    height:             props.height || "42px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "8px 0 0 0",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "300",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "white",



}))`

    background-color:${props => props.backgroundColor};
    border-radius:${props => props.borderRadius};
 
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    cursor:pointer;


    .Text{

        background-color:transparent;
        width:100%;
        height:auto;

        padding:0 0 4px 0;
        margin:13px 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 

    }



`;






const MobiController = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",

    width:              props.width || "300px",
    height:             props.height || "50px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 calc(50% - 150px",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "14px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;  
    overflow:hidden;


    .Title{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:0 0 4px 0;
        margin:14px 20px 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }




    .XButton{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:14px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        cursor:pointer;

    }

    .XButton:hover{

        background-color:#818692;
       
    }




`;



















const MobiControlPanel = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "1px solid black",

    width:              props.width || "100%",
    height:             props.height || "130px",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    zIndex:             props.zIndex  || "5",

    top:                props.top || "0px",
    right:              props.right || "0px",
    bottom:             props.bottom || "40px",  
    left:               props.left || "0px",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    border-top:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;  
    z-index:${props => props.zIndex};
    bottom:${props => props.bottom}; 
    left:${props => props.left}; 

    overflow-x:hidden;
    overflow-y:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};



`;






const MobiControlPanelButton = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",

    padding:            props.padding || "4px 0 4px 0",
    margin:             props.margin  || "0 0 0 0",

    float:              props.float  || "left",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",



}))`

    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:${props => props.float};

    overflow:hidden;


    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    cursor:pointer;


    &:hover{

        background-color:lightgreen;

    }

    .Text{

        background-color:transparent;
        width:95%;
        height:auto;

        padding:0 0 0 0;
        margin:0 0 0 12px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 

    }



`;




const MobiControlPanelTop = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "1px solid black",

    width:              props.width || "34px",
    height:             props.height || "100%",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
  
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute; 
    zIndex:10;  
    top:0px;
    left:0px;
    overflow:hidden;


    .Title{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:0 0 4px 0;
        margin:12px 0 0 7px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }




    .XButton{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:12px 12px 12px 12px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        cursor:pointer;

    }

    .XButton:hover{

        background-color:#818692;
       
    }



`;


const MobiControlPanelBottom = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "1px solid black",

    width:              props.width || "34px",
    height:             props.height || "100%",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
 
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute; 
    zIndex:10;  
    top:0px;
    right:0px;
    overflow:hidden;


    .Title{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:0 0 4px 0;
        margin:12px 0 0 7px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }




    .XButton{

        background-color:transparent;
        width:auto;
        height:auto;

        padding:12px 12px 12px 12px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        cursor:pointer;

    }

    .XButton:hover{

        background-color:#818692;
       
    }



`;










const MobiControlPanelScroller = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",

    width:              props.width || "calc(100vw - 70px)",
    height:             props.height || "110px",

    padding:            props.padding || "0 0 5px 0",
    margin:             props.margin  || "10px 0 0 35px",


}))`
  
    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    white-space:nowrap;

    overflow-x:scroll;
    overflow-y:hidden;


`;

const MobiControlPanelGroup = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",
    border:             props.border || "0",

    width:              props.width || "auto",
    height:             props.height || "90px",

    minHeight:          props.minHeight || "20px",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


}))`
  
    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};
    padding:${props => props.padding};
    margin:${props => props.margin};


    display:inline-block;
    overflow:hidden;

`;




const MobiControlPanelTile = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "140px",
    height:             props.height || "90px",

    padding:            props.padding || "4px 0 4px 0",
    margin:             props.margin  || "0 0 0 0",

    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "12px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",


    grfxWidth:              props.grfxWidth || "108px",
    grfxHeight:             props.grfxHeight || "75px",
    grfxMarginLeft:         props.grfxMarginLeft || "calc(50% - 54px)",





}))`

    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    display:inline-block;

    overflow:hidden;


    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};

    cursor:pointer;


    &:hover{

        background-color:#EDEEF1;

    }





    .Grfx{

        background-color:transparent;

        width:${props => props.grfxWidth};
        height:${props => props.grfxHeight};

        padding:0 0 0 0;
        margin:0 0 0 ${props => props.grfxMarginLeft};

        position:relative;
        float:left;


        overflow:hidden;

        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 




        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:white;




    }





    .Text{

        background-color:transparent;
        width:100%;
        height:auto;

        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 

    }



`;






const MobiControlPanelCategory = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#ccc",
    border:             props.border || "0",

    width:              props.width || "100%",
    height:             props.height || "auto",

    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative; 
    float:left;  
    overflow:hidden;

    .Text{

        background-color:transparent;
        width:95%;
        height:auto;

        padding:5px 0 5px 7px;
        margin: 0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};     
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }



`;








export {

    MobiControls,
    MobiControlsButton,

    MobiController,

    MobiControlPanel,
    MobiControlPanelTop,
    MobiControlPanelBottom,
    MobiControlPanelScroller,  
    MobiControlPanelCategory, 
    MobiControlPanelGroup,
    MobiControlPanelButton,
    MobiControlPanelTile


}
  


