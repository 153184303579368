export const BUTTON_RECT_BLUE_BLACK_BLUE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Black*/}

    <polygon 
        fill="rgb(0,0,0)" 
        points="143.702,100.885 575.702,100.885 575.702,388.885 143.702,388.885 143.702,100.885 	"
    />
 {/*Back*/}

    <polygon 
        fill="rgb(0,115,190)" 
        points="143.702,100.885 575.702,100.885 143.702,388.885 143.702,100.885 	"
    />  
{/*Hightlight*/}

    <polygon 
        fill="rgb(0,150,220)" 
        points="575.702,100.885 143.702,100.885 143.702,388.885 184.094,361.957 184.094,141.277 515.114,141.277 575.702,100.885 	"
    />




    

		</svg>
		</div>

	);//return
}