export const BUTTON_HELMET_GRAY_DRIVER_NIGEL_MANSELL = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M349.303,75.196c81.227,0,145.156,48.81,158.676,121.744l19.116,102.742
		c23.52,6.317,9.076,41.796,1.958,58.749l-10.815,25.488c-4.961,10.244-14.554,27.163-25.429,26.88l-33.152-1.739
		c-16.751-0.975-34.455-3.137-50.977-5.984L209.465,367.95C194.345,328.565,180,284.349,180,234.297
		C180,136.64,251.643,75.196,349.303,75.196L349.303,75.196z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M186.253,234.292c0-59.346,44.645-150.666,163.05-150.666
		c132.61,0,152.691,114.836,152.691,114.836l19.802,106.813c19.056,0,11.521,27.183,1.472,50.855l-10.767,25.364
		c-3.31,7.789-11.819,10.408-19.673,9.996l-33.45-1.75c-12.983-0.686-37.557-4.046-49.456-6.144l-194.671-34.324
		C198.848,306.576,186.253,274.046,186.253,234.292L186.253,234.292z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(215,215,215)" 
        d="M349.293,89.859c70.361,1.903,130.403,42.188,146.15,108.603
		l20.339,109.734l6.779,1.198l0.397,2.162c12.138,1.81-3.251,36.945-5.438,42.153l-10.696,25.205
		c-2.614,5.388-8.211,6.501-13.748,6.352l-33.352-1.749c-9.324-0.557-19.007-1.621-28.321-2.898l10.309-24.235
		c3.181-7.599,25.012-56.836,8.121-59.808l-3.528-0.627l-18.073-97.487C426.891,191.21,415.3,120,344.68,90.053
		C346.151,89.969,347.662,89.898,349.293,89.859L349.293,89.859z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M349.293,89.859c70.361,1.903,130.403,42.188,146.15,108.603
		l20.339,109.734l6.779,1.198l0.397,2.162c12.138,1.81-3.251,36.945-5.438,42.153l-10.696,25.205
		c-2.614,5.388-8.211,6.501-13.748,6.352l-24.782-1.302l9.97-23.49c3.341-7.957,26.434-54.231,8.738-57.348l-0.695-0.124
		l-19.374-104.541c-4.782-25.767-23.65-90.202-118.824-108.573C348.497,89.879,348.885,89.869,349.293,89.859L349.293,89.859z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M379.643,371.494l-155.167-27.361
		c-13.698-35.747-31.602-70.917-31.989-109.801c0.944-40.368,15.278-72.83,38.183-96.688c-5.1,39.257-26.721,85.729,32.983,205.759
		l119.012,20.974L379.643,371.494L379.643,371.494z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M186.253,234.292c0-59.346,44.645-150.666,163.05-150.666
		c17.078,0,32.277,1.919,45.827,5.239c-15.508-0.944-30.24-1.84-45.648-0.463c-52.1,6.795-73.86,38.118-89.01,84.621
		l-47.577,170.115C197.655,303.405,186.253,272.122,186.253,234.292L186.253,234.292z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M217.517,326.627c-12.127-29.843-24.702-59.773-25.03-92.295
		c0.944-40.368,15.278-72.83,38.183-96.688c-3.887,29.912-17.356,64.029,2.873,131.686L217.517,326.627L217.517,326.627z"
    />
    
    {/*NM Red Shape*/}
    <path 
        fill="rgb(190,0,0)" 
        d="M208.312,157.645l156.399,30.399l-76.773-94.795
		c10.508-3.604,21.92-6.303,34.296-7.913l140.464,173.45L300.274,364.262l-41.99-7.406l93.395-60.647l-162.96-31.681
		c-1.343-8.271-2.178-16.681-2.396-25.408l184.194,35.807l42.298-27.477l-24.375-30.096l-191.262-37.178
		C200.21,172.575,203.908,165.011,208.312,157.645L208.312,157.645z M467.063,344.953l3.389-8.524l35.062,2.117l6.75-16.944
		l7.854,0.478l-6.74,16.949l9.663,0.581c-1.104,3.171-2.237,6.124-3.211,8.539l-9.852-0.596l-6.74,16.947l-7.854-0.477l6.74-16.948
		L467.063,344.953L467.063,344.953z M460.332,361.856l-0.021,0.045l5.767,0.348l31.611-12.529l-5.855-0.358L460.332,361.856
		L460.332,361.856z M477.172,319.529l-1.263,3.171l15.727,12.098l5.854,0.354L477.172,319.529L477.172,319.529z"
    />
    {/*NM Navy Shape*/}
    <path 
        fill="rgb(0,55,123)" 
        d="M190.229,272.769l141.309,27.466l-84.219,54.688l-32.068-5.65
		C204.385,321,195.2,297.172,190.229,272.769L190.229,272.769z M214.476,148.151l123.455,23.997l-49.197-60.753l192.037,37.332
		c-5.537-8.43-12.446-17.072-21.005-25.209L293.981,91.301C257.748,102.166,231.812,123.499,214.476,148.151L214.476,148.151z"
    />
    {/*NM Highlight Base*/}
    <path 
        fill="rgb(0,15,71)" 
        d="M399.861,132.997c-6.918-9.16-15.497-18.167-26.104-26.194
		l69.576,13.525c10.349,7.545,19.594,16.391,27.437,26.451L399.861,132.997L399.861,132.997z M509.639,321.442l-23.749-1.437
		l18.619,14.33L509.639,321.442L509.639,321.442z M525.425,332.029c0.914-3.305,1.64-6.58,1.958-9.513l-4.652-0.283l-5.13,12.897
		L525.425,332.029L525.425,332.029z M505.851,364.659l6.85,0.413l3.837-9.036l-5.557-4.269L505.851,364.659L505.851,364.659z
		 M469,362.423l23.769,1.441l5.13-12.893L469,362.423L469,362.423z M465.929,347.796l-3.698,9.289l20.816-8.25L465.929,347.796
		L465.929,347.796z M475.283,324.291l-3.698,9.294l17.118,1.034L475.283,324.291L475.283,324.291z"
    />
    {/*NM Highlight*/}
    <path 
        fill="rgb(0,38,97)" 
        d="M441.097,141.014c-7.605-9.473-17.387-18.748-30.002-26.949
		l32.238,6.264c10.349,7.545,19.594,16.391,27.437,26.451L441.097,141.014L441.097,141.014z M509.639,321.442l-16.721-1.014
		c-0.249,1.387-0.557,2.818-0.925,4.279l12.516,9.628L509.639,321.442L509.639,321.442z M525.425,332.029
		c0.914-3.305,1.64-6.58,1.958-9.513l-4.652-0.283l-5.13,12.897L525.425,332.029L525.425,332.029z M505.851,364.659l6.85,0.413
		l3.837-9.036l-5.557-4.269L505.851,364.659L505.851,364.659z M479.189,358.387c-0.378,0.85-0.695,1.561-0.925,2.088l-1.033,2.45
		l15.537,0.939l5.13-12.893L479.189,358.387L479.189,358.387z"
    />
    {/*NM Roof Shadow Red*/}
    <path 
        fill="rgb(140,0,0)" 
        d="M208.312,157.645l53.621,10.423
		c-0.497,1.625-0.984,3.275-1.461,4.956l-5.159,18.455l-58.135-11.303C200.21,172.575,203.908,165.011,208.312,157.645
		L208.312,157.645z M299.937,108.061c8.539-6.392,18.222-11.084,29.116-14.305l-6.819-8.42c-12.376,1.61-23.788,4.31-34.296,7.913
		L299.937,108.061L299.937,108.061z M232.498,273.042l6.62-23.654l-52.796-10.269c0.219,8.728,1.054,17.138,2.396,25.408
		L232.498,273.042L232.498,273.042z M317.721,352.93l-42-7.396l-9.255,6.003l42,7.406L317.721,352.93L317.721,352.93z M467.063,344.953l3.389-8.524l17.526,1.059c-1.094,2.957-2.237,5.84-3.36,8.529L467.063,344.953L467.063,344.953z M460.332,361.856l-0.021,0.045l5.767,0.348l13.837-5.482c0.438-0.975,0.925-2.063,1.462-3.256L460.332,361.856L460.332,361.856z M477.172,319.529l-1.263,3.171l13.589,10.458c0.378-1.104,0.736-2.217,1.074-3.325L477.172,319.529L477.172,319.529z"
    />
    {/*NM Roof Shadow Navy*/}
    <path 
        fill="rgb(0,28,84)" 
        d="M190.229,272.769l40.161,7.808l-17.496,62.563
		C203.063,317.511,194.832,295.393,190.229,272.769L190.229,272.769z M214.476,148.151l50.827,9.881
		c6.729-18.241,15.468-32.356,26.402-42.963l-2.972-3.674l5.735,1.118c7.754-6.879,16.542-12.138,26.443-15.984l-26.931-5.229
		C257.748,102.166,231.812,123.499,214.476,148.151L214.476,148.151z"
    />
    {/*NM ShadowS Navy*/}
    <path 
        fill="rgb(0,0,56)" 
        d="M198.51,274.379l39.067,7.594
		c6.164,18.082,14.612,38.401,26.075,61.43l1.113,0.193l-9.255,6.015l-31.035-5.478C215.618,321.03,205.012,298.161,198.51,274.379
		L198.51,274.379z M220.658,149.354l7.863,1.531c0.825-4.558,1.591-8.946,2.147-13.241
		C227.12,141.342,223.77,145.248,220.658,149.354L220.658,149.354z"
    />
    {/*NM ShadowS Red*/}
    <path 
        fill="rgb(100,0,0)" 
        d="M234.804,273.489l-38.381-7.461
		c-1.919-8.41-3.241-16.949-3.729-25.667l35.111,6.824C229.575,255.372,231.871,264.12,234.804,273.489L234.804,273.489z
		 M214.117,158.777l12.535,2.436c-1.302,7.366-2.555,15.284-3.29,24.053l-20.607-4.002
		C205.906,173.262,209.714,165.767,214.117,158.777L214.117,158.777z"
    />
    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M352.859,173.311c6.859-6.71,14.255-9.394,23.356-9.553 c7.043,0.442,12.689,2.276,18.932,5.82l24.777,15.185c7.689,4.448,16.89,7.257,25.797,7.471h53.247 c11.924,0.869,17.482,5.949,19.932,17.471l16.164,87.21c1.252,7.61-0.268,13.843-9.125,14.594h-19.743 c-6.362-0.08-12.596-0.93-18.854-1.998l-77.563-13.674c-21.8-14.643-36.775-34.216-49.196-57.085
		c-3.788-7.167-7.282-14.464-10.637-21.84C343.326,202.318,341.756,186.273,352.859,173.311L352.859,173.311z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(116,138,150)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.679,212.269c-14.255-31.616,12.992-52.254,37.298-37.327 l24.763,15.174c8.519,5.22,21.06,8.346,28.907,8.346h53.094c10.597,0,12.813,5.815,14.047,12.456l16.139,87.071 c0.924,4.985,0.637,7.286-3.246,7.286l-26.373,0.02l-87.146-15.368C381.081,269.881,364.426,233.87,354.679,212.269 L354.679,212.269z M380.426,177.307c-11.537,0-20.945,9.409-20.945,20.945s9.408,20.945,20.945,20.945 c11.541,0,20.944-9.409,20.944-20.945S391.967,177.307,380.426,177.307L380.426,177.307z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(158,179,190)" 
        d="M498.74,198.461h-53.094h-0.517l18.639,100.57l35.538,6.263 l22.486-0.015l-19.757-106.6C501.037,198.535,499.943,198.461,498.74,198.461L498.74,198.461z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(195,212,221)" 
        points="466.93,198.461 495.44,198.461 515.242,305.274 499.208,305.274 486.305,303.003 466.93,198.461 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M380.426,179.753c10.188,0,18.504,8.31,18.504,18.499 s-8.315,18.5-18.504,18.5c-10.189,0-18.5-8.311-18.5-18.5S370.236,179.753,380.426,179.753L380.426,179.753z M380.426,186.582 c-6.427,0-11.671,5.243-11.671,11.67c0,6.432,5.244,11.676,11.671,11.676c6.432,0,11.675-5.244,11.675-11.676 C392.101,191.825,386.857,186.582,380.426,186.582L380.426,186.582z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M423.31,206.692c0-3.808-3.111-6.919-6.924-6.919 c-3.812,0-6.924,3.111-6.924,6.919c0,1.595,0.563,3.047,1.472,4.22c-2.913,7.187-8.177,13.221-15.07,16.536 c-7.704,3.459-3.568,10.885,2.396,8.042c9.414-4.533,16.8-12.56,20.612-22.352C421.461,212.135,423.31,209.63,423.31,206.692z"
    />
    {/*Blue Visor Stripe Background*/}
    <path 
        fill="rgb(0,75,160)" 
        d="M428.653,200.708l-7.58,23.058h94.101l-2.387-12.849 c-0.76-4.115-1.909-7.913-5.189-10.209H428.653L428.653,200.708z"
    />
    {/*Blue Visor Stripe Highlight*/}
    <polygon 
        fill="rgb(0,125,200)" 
        points="445.547,200.708 449.822,223.766 506.683,223.766 502.413,200.708 445.547,200.708 	"
    />
    {/*Blue Visor Stripe Highlight1*/}
    <polygon 
        fill="rgb(0,175,240)" 
        points="467.348,200.708 495.857,200.708 500.132,223.766 471.646,223.766 467.348,200.708 	"
    />
    {/*Mansell*/}
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M426.95,320.25c-0.935-0.169-0.904-1.834-0.686-3.137
		c0.357-2.067,1.392-5.219,1.829-7.624c0.179-0.96,0.526-2.908-0.418-2.302c-0.686,0.428-1.48,1.063-2.146,1.685
		c0.05,1.268-0.109,2.321-0.378,3.648c-0.299,1.441-0.626,2.878-0.915,4.31c-0.238,1.237-0.139,3.191-0.606,4.27
		c-0.417,1.004-3.429,1.834-3.39,0.318c0.021-0.487,0.428-0.616,0.716-0.965c0.507-0.576,1.104-1.347,1.382-2.037
		c0.775-1.909,0.994-6.983-1.819-4.99c-1.451,1.023-3.092,3.638-3.589,5.75c-0.437,1.85-0.109,4.066,1.184,4.494
		c0.378,0.119,0.735,0.02,1.252,0c0.428-0.011,0.855,0.069,1.253,0.06c1.362-0.07,2.445-1.536,3.37-1.521
		c0.597,0.005,1.252,0.811,2.038,0.756c1.163-0.1,3.976-3.116,3.459-4.354C428.472,318.878,428.253,320.508,426.95,320.25
		L426.95,320.25z M392.813,310.871c-2.147,0.01-7.257,16.954-7.883,19.483c-0.169,0.616-0.735,2.873,0.1,3.077
		c0.438,0.094,1.023-0.597,1.123-0.984c0.179-0.716-0.04-1.461,0.09-2.262c0.606-3.563,5.537-16.034,5.994-16.02
		c0.864,0.04,3.39,12.441,6.65,12.659c1.401,0.095,1.889-2.51,2.067-3.449c0.229-1.133,0.209-2.4,0.547-3.425
		c0.357-1.108,0.467-1.009,0.079-2.217c-0.397-1.212-0.407-2.813-0.467-4.239c-0.06-1.422,0.069-4.802-1.253-5.114
		c-1.998-0.478-1.392,6.511-1.173,7.391c0.159,0.642,0.825,1.531,1.044,2.267c0.378,1.197,0.249,2.978,0.109,4.438
		c-0.04,0.467,0.05,1.099-0.397,1.332C396.66,322.417,395.666,310.855,392.813,310.871L392.813,310.871z M403.54,315.533
		c0.219,1.073,1.929-0.005,2.217-0.478c0.407-0.735,0.308-1.934-0.288-1.968C404.644,313.043,403.331,314.399,403.54,315.533
		L403.54,315.533z M412.864,325.186c-0.05,0.711,0.119,5.129-0.984,5.149c-1.58,0.029-1.739-3.843-3.071-3.748
		c-0.537,0.035-0.895,1.472-0.885,2.307c0,1.028,1.163,2.391,1.689,2.654c1.541,0.785,3.43,0.104,4.166-0.815
		c1.978-2.49,1.471-8.738,1.779-12.078c0.089-1.183,0.766-4.314-0.616-3.917c-0.687,0.204-1.7,0.095-2.188,0.264
		c-0.686,0.219-1.292,0.939-1.779,1.427c-2.187,2.202-2.485,3.941-3.758,6.372c-0.248,0.462-1.382,1.272-1.77,1.208
		c-1.471-0.254-0.188-4.314,0.03-5.349c0.129-0.547,0.438-1.536,0.06-1.869c-0.527-0.184-1.869,1.024-2.067,1.467
		c-0.666,1.476-1.283,5.055-1.114,6.66c0.07,0.701,0.706,1.646,1.393,1.67c1.292,0.04,2.436-2.196,3.31-2.147
		c0.567,0.03,0.706,0.87,1.402,0.915c0.695,0.05,1.928-0.78,2.286-1.099c0.626-0.521,1.392-2.262,2.127-2.366
		C413.282,322.819,412.934,324.018,412.864,325.186L412.864,325.186z M439.844,341.956c-0.666-0.229-0.656-1.557-0.576-2.441
		c0.208-2.291,1.223-5.323,1.68-7.933c0.109-0.602,0.606-2.808-0.119-2.51c-0.557,0.233-2.227,1.228-2.436,1.824
		c-0.109,0.328,0.01,0.716,0.02,1.078c0.021,1.819-0.755,4.668-1.104,6.611c-0.129,0.646-0.159,1.759-0.378,2.306
		c-0.327,0.771-2.236,2.371-3.041,2.178c-0.537-0.14-0.716-0.965-0.727-1.491c-0.039-3.077,1.283-6.521,1.78-9.573
		c0.079-0.582,0.348-1.308-0.011-1.755c-0.536-0.064-2.455,1.198-2.604,1.695c-0.109,0.383,0.05,0.835,0.05,1.253
		c0.01,2.783-1.54,6.635-1.59,9.588c-0.011,0.278,0.079,0.546,0.039,0.805c-0.109,0.611-1.033,1.248-1.61,1.392
		c-0.875,0.194-2.008-0.144-1.968-0.82c0.029-0.497,0.438-0.521,0.686-0.785c0.626-0.631,1.382-1.77,1.61-2.704
		c0.487-1.894,0.358-5.417-1.689-4.642c-1.641,0.641-3.201,3.544-3.698,5.129c-0.14,0.452-0.179,1.894-0.577,1.993
		c-0.387,0.109-2.604-2.733-2.386-3.221c0.189-0.393,1.303,0.005,1.621-0.318c0.566-0.541,0.148-1.953-0.199-2.112
		c-1.839-0.353-4.016,2.664-4.086,4.235c0.408,1.028,3.092,3.399,2.376,4.537c-0.576,0.93-1.72-0.949-2.405-0.959
		c-0.597-0.01-0.825,0.91-1.342,0.845c-0.606-0.069-0.616-1.416-0.597-2.037c0.029-1.819,0.417-5.085-1.074-5.165
		c-1.411-0.074-2.803,3.619-3.2,4.549c-0.159,0.367-0.278,1.038-0.796,1.173c-0.377-1.536,0.637-2.903,0.696-4.543
		c-0.964-1.054-2.177,0.153-2.356,1.038c-0.348,1.562-0.477,4.076-0.526,5.82c-0.03,0.915,0.069,2.192,0.755,2.228
		c0.368,0.015,1.055-0.706,1.233-1.024c0.835-1.501,1.382-3.509,2.117-5.1c0.209-0.477,0.378-1.263,1.014-1.218
		c0.428,1.094-0.238,3.171-0.179,4.533c0.03,0.671,0.357,1.76,0.964,1.844c0.766,0.125,1.074-0.845,1.671-0.814
		c0.367,0.024,0.715,0.566,1.023,0.666c1.66,0.526,4.056-0.627,4.94-1.895c0.358-0.486,0.259-1.023,0.915-1.272
		c0.626,0.492,0.735,1.377,1.561,1.562c0.935,0.193,2.684-0.05,3.608-0.249c0.735-0.149,1.432-1.197,2.107-1.228
		c0.766-0.024,1.392,0.721,2.217,0.651c1.163-0.09,2.704-2.973,3.38-2.823c1.272,0.269,1.153,3.002,3.718,1.019
		c0.637-0.487,3.052-2.465,2.356-3.484C441.713,340.574,441.086,342.368,439.844,341.956L439.844,341.956z M390.278,344.704
		c0.109-2.4,0.687-4.98,0.438-7.341c-0.05-0.452-0.606-2.62-1.342-2.123c-0.994,0.666-1.561,2.262-2.196,3.499
		c-2.834,5.508-4.275,10.672-6.303,16.382c-0.278,0.766-1.084,1.556-0.566,2.51c1.441,0.021,1.62-0.755,1.948-2.042
		c1.342-5.507,3.081-10.139,5.407-15.328c0.189-0.407,0.259-1.128,0.855-1.178c0.497,0.497-0.219,6.332-0.209,7.271
		c0.029,1.392-0.407,4.742,1.232,4.657c1.163-0.06,1.8-2.659,2.128-3.444c0.705-1.71,2.048-6.829,3.718-7.804
		c0.576,3.116-1.303,6.521-0.766,9.717c0.288,1.636,1.65,1.869,2.884,0.88c0.486-0.388,1.619-2.072,1.162-2.669
		c-0.537-0.104-0.577,0.79-1.213,0.676c-0.687-0.124-0.626-1.421-0.597-2.207c0.09-2.584,1.113-6.054,0.357-8.444
		c-0.09-0.323-0.418-0.87-0.756-0.92c-1.828-0.278-4.681,7.704-5.188,8.912c-0.169,0.413-0.308,1.054-0.845,1.173
		C390.109,346.324,390.239,345.499,390.278,344.704L390.278,344.704z M408.57,349.426c0.119-0.383-0.209-0.869-0.278-1.272
		c-0.18-0.959-0.199-2.743-0.13-4.279c0.06-1.561,0.468-3.102-0.248-3.608c-1.521-0.571-2.436-0.109-3.41,0.606
		c-2.117,1.531-5.457,5.562-4.632,8.51c0.328,1.178,1.312,1.138,2.217,0.66c1.014-0.546,3.031-3.121,3.459-3.047
		c0.418,0.075,0.497,1.064,0.597,1.472c0.179,0.681,0.288,1.089,0.676,1.432C407.427,349.963,408.391,349.963,408.57,349.426
		L408.57,349.426z M421.075,318.59c0.328-0.651,1.184-3.385,0.09-3.52c-1.372-0.174-2.326,4.444-1.441,5.209
		C420.19,320.041,420.718,319.285,421.075,318.59L421.075,318.59z M410.29,322.79c1.72,0.671,3.528-5.662,3.599-6.557
		C412.507,314.151,409.008,322.298,410.29,322.79L410.29,322.79z M426.731,342.984c0.766,0.204,1.819-2.634,1.929-3.181
		c0.199-1.01-0.129-1.829-0.755-1.774C426.603,338.158,425.827,342.756,426.731,342.984L426.731,342.984z M402.377,347.389
		c-0.01,1.689,1.282,0.502,1.779-0.185c0.348-0.467,3.668-5.925,1.829-5.8C404.365,341.514,402.387,346.166,402.377,347.389
		L402.377,347.389z M261.575,231.404c-1.124,3.151,2.058,1.765,3.439,0.811c0.944-0.651,10.656-8.724,7.177-9.678
		C269.09,221.692,262.38,229.128,261.575,231.404L261.575,231.404z M309.927,238.89c1.332,0.88,5.12-3.747,5.677-4.701
		c1.023-1.76,0.944-3.515-0.259-3.798C312.819,229.789,308.406,237.876,309.927,238.89L309.927,238.89z M271.824,239.208
		c0.477-0.636,0.158-1.77,0.288-2.574c0.308-1.889,1.421-5.249,2.535-8.077c1.123-2.878,2.893-5.487,1.868-6.899
		c-2.485-2.058-4.463-1.77-6.77-1.078c-4.94,1.501-13.798,6.874-14.155,12.923c-0.149,2.406,1.72,2.982,3.718,2.659
		c2.236-0.362,7.674-3.872,8.43-3.459c0.735,0.402,0.258,2.306,0.188,3.156c-0.119,1.371-0.169,2.196,0.328,3.091
		C269.349,239.467,271.147,240.093,271.824,239.208L271.824,239.208z M240.679,218.571c1.75-4.414,4.494-8.878,5.558-13.469
		c0.198-0.86,0.576-5.27-1.144-4.816c-2.286,0.591-4.384,3.235-6.362,5.124c-8.867,8.464-14.901,17.177-22.377,26.551
		c-1.004,1.263-3.021,2.197-2.684,4.324c2.714,0.984,3.529-0.343,4.961-2.56c6.083-9.434,12.336-16.964,20.03-25.15
		c0.616-0.661,1.203-1.942,2.336-1.655c0.616,1.253-4.483,11.69-5.06,13.45c-0.865,2.635-3.837,8.599-0.716,9.514
		c2.227,0.646,5.09-3.813,6.213-5.07c2.416-2.724,8.241-11.441,11.987-12.188c-0.944,6.193-6.659,11.343-7.692,17.675
		c-0.537,3.241,1.868,4.563,4.801,3.51c1.163-0.408,4.364-2.839,3.887-4.27c-0.935-0.522-1.581,1.128-2.685,0.512
		c-1.202-0.676-0.238-3.066,0.318-4.523c1.83-4.781,5.985-10.587,6.124-15.558c0.021-0.666-0.229-1.898-0.845-2.216
		c-3.221-1.68-13.708,11.382-15.448,13.325c-0.576,0.651-1.263,1.779-2.336,1.65C239.327,221.483,240.103,220.022,240.679,218.571
		L240.679,218.571z M335.127,245.421c-1.104-0.845-0.229-3.33,0.497-4.936c1.859-4.141,5.696-9.16,8.261-13.733
		c0.587-1.054,2.942-4.86,1.412-4.771c-1.193,0.069-4.971,0.845-5.746,1.839c-0.418,0.537-0.438,1.343-0.666,2.038
		c-1.123,3.395-4.424,8.236-6.343,11.646c-0.626,1.119-1.411,3.177-2.187,4.051c-1.104,1.238-5.715,3.003-7.089,2.098
		c-0.924-0.596-0.715-2.246-0.387-3.24c1.919-5.786,6.61-11.348,9.493-16.74c0.547-1.034,1.512-2.232,1.113-3.311
		c-0.954-0.457-5.348,0.666-5.954,1.501c-0.457,0.631-0.428,1.591-0.706,2.386c-1.789,5.204-7.187,11.393-9.186,16.88
		c-0.188,0.517-0.198,1.093-0.437,1.521c-0.606,1.099-2.734,1.681-3.897,1.571c-1.77-0.175-3.678-1.551-3.171-2.784
		c0.368-0.914,1.163-0.705,1.81-1.023c1.58-0.775,3.708-2.421,4.751-4.006c2.128-3.241,4.176-9.922-0.148-9.777
		c-3.51,0.114-8.291,4.548-10.239,7.192c-0.566,0.761-1.551,3.409-2.366,3.36c-0.805-0.05-3.102-6.82-2.376-7.575
		c0.597-0.616,2.426,0.835,3.231,0.467c1.411-0.656,1.53-3.568,0.983-4.105c-3.19-1.834-9.234,2.396-10.357,5.294
		c0.089,2.182,3.568,8.345,1.511,10.021c-1.69,1.371-2.595-2.889-3.897-3.355c-1.103-0.388-2.107,1.188-3.031,0.73
		c-1.094-0.542-0.259-3.057,0.179-4.22c1.242-3.355,4.065-9.22,1.332-10.339c-2.585-1.049-7.555,4.951-8.917,6.438
		c-0.537,0.591-1.183,1.764-2.236,1.694c0.288-3.131,3.071-5.03,4.234-8.047c-1.133-2.59-4.165-1.128-5.1,0.412
		c-1.63,2.694-3.519,7.316-4.731,10.527c-0.646,1.7-1.303,4.146-0.011,4.663c0.656,0.268,2.416-0.642,2.963-1.124
		c2.535-2.256,4.841-5.651,7.237-8.171c0.695-0.736,1.53-2.107,2.693-1.62c0.09,2.326-2.495,5.775-3.271,8.359
		c-0.378,1.272-0.457,3.52,0.616,4.091c1.343,0.711,2.545-0.899,3.648-0.452c0.687,0.278,0.975,1.516,1.481,1.894
		c2.773,2.063,7.982,1.451,10.468-0.328c0.964-0.69,1.133-1.744,2.535-1.809c0.854,1.327,0.486,3.052,1.918,3.932
		c1.581,0.969,5.03,1.64,6.899,1.868c1.461,0.18,3.459-1.321,4.722-0.944c1.451,0.438,2.147,2.252,3.728,2.654
		c2.217,0.572,6.979-3.797,8.142-3.092c2.197,1.313,0.209,6.362,6.303,4.305c1.491-0.507,7.286-2.64,6.66-4.99
		C339.511,244.06,337.194,247.012,335.127,245.421L335.127,245.421z"
    />
    
    

    

		</svg>
		</div>

	);//return
}