import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


/*
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,  
    //useHistory,
    //useParams


} from "react-router-dom";

*/


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {

    Board,
    BoardAlfa,
    BoardBase,
    BoardSection

} from './Styled';



import Overlay from './Overlay';


import FGrid from 'utils/components/Grid/FGrid';


//import { Fade } from 'utils/components/Transitions';


import {
    LibString
} from 'library';


const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));

    /*

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    */


    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);
    // const refControls = useRef(null);






    //---------------------------------------------
    // state/effect - isLoaded, isMultiBoard
    //---------------------------------------------

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [isMultiBoard, setIsMultiBoard] = useState(false);
    const [screenW, setScreenW] = useState(0);
    const [screenH, setScreenH] = useState(0);
 

    useEffect(() => {

        var item;
        if(!isLoaded)
        {
            setIsLoaded(true);

            // console.log(""

            // +"\nprops.data: "+props.data
            // +"\nprops.data.items: "+props.data.items.length
            // +"\nprops.data.items.length: "+props.data.items.length
 
            // )

            if(props.data.items.length > 0)
            {
                item = props.data.items[0];
                if(item.info !== undefined 
                && item.images !== undefined)
                {
                    setIsMultiBoard(true);
                }
            } 

        } 
        //console.log(JSON.stringify(props,null,2));
        //alert('props');



        //----------------------------------------
        // ensures the height and width adjusts happen

        setScreenW(width);
        setScreenH(height);
        
        //-----------------------------------------



    },
    [
        props,
        width,height,
        isLoaded,
        isMultiBoard,
        screenW,screenH,
    
    ])





    //---------------------------------------------
    // state - fade variables
    //---------------------------------------------

    //const [fadeImage, setFadeImage] = useState(true);





    //---------------------------------------------
    // state/effect - boards
    //---------------------------------------------

    // -- state -- //
    const [boards, setBoards] = useState([]);

    useEffect(() => {

        const effectBoards = (eff) => {

            var boards = eff.props.data.items;
            setBoards(boards);

        }
        if(isLoaded && boards.length === 0)
        {
         
            effectBoards({

                props:props,
         
            });
            
        } 
    },
    [
        props,
        isLoaded,
        isMultiBoard,
        boards   

    ])






    //---------------------------------------------
    // state/effect - boardInfo, currentBoard
    //---------------------------------------------

    // -- state -- //
    const [boardInfo, setBoardInfo] = useState({
        
        view:"fullScreen",
        viewHeight: 550+"px",
        thumbHInt:0
    
    });
    const [boardNumber, setBoardNumber] = useState(0);
    const [currentBoardNumber, setCurrentBoardNumber] = useState(0);
    const [currentBoard, setCurrentBoard] = useState({

        info:false,
        alfa:false,
        base:false,
        controller:false,
        images:[]

    });

 
    useEffect(() => {


        // -- effect -- //

        const effectCurrentBoard = (eff) => {

            const props = eff.props;
            const boards = eff.boards;
            const boardNumber = eff.boardNumber;
            const currentBoard = eff.currentBoard;

            const board = boards[boardNumber];


            let headerCall;
            let headerH;

            var viewHInt;
            var headerHInt = 0;
            var boardHInt,boardH;

            var totalRows;
            var thumbHInt;

            var change;

            //console.log("selected Board: "+JSON.stringify(board,null,2));



            if(board.info.view === 'fullScreen')
            {

                //console.log("fullScreen");

                //alert(props.databind.viewHeight);


                headerCall = props.databind.settings.appearance.header.headerCall
                //let headerW = props.databind.headerSizes.groupW;
                headerH = props.databind.headerSizes.groupH;


                viewHInt = props.databind.viewHeight;
                switch(Object.prototype.toString.call(props.databind.viewHeight))
                {
                case'[object String]':

                    viewHInt = props.databind.viewHeight.replace(/px/gi,"");

                break;
                default:
                }


                headerHInt = 0;
                switch(Object.prototype.toString.call(headerH))
                {
                case'[object Number]':

                    headerHInt = headerH;
                break;
                case'[object String]':

                    headerHInt = headerH.replace(/px/gi,"");
                break;
                default:
                }


                boardHInt = viewHInt;
 
                // console.log("SIZES..."
                //     +"\nwidth: "+width
                //     +"\nheight: "+height
                //     +"\nviewHInt: "+viewHInt
        
                // );

                // console.log("Before..."
                //     +"\nboardHInt: "+boardHInt
                //     +"\nheaderHInt: "+headerHInt
                //     +"\nviewHInt: "+viewHInt
                // );

                if(headerCall !== 'C')
                {
                    boardHInt = viewHInt - headerHInt;
                    //console.log(boardH);
                    //console.log("yeah!!!");
                }
                if(props.refid !== 'row_0') 
                {
                    boardHInt = viewHInt - headerHInt;

                }

                // console.log("After... "
                //     +"\nboardHInt: "+boardHInt
                //     +"\nheaderHInt: "+headerHInt
                //     +"\nviewHInt: "+viewHInt
                //     +"\nboardNumber: "+boardNumber
                //     +"\ncurrentBoardNumber: "+currentBoardNumber
                // );



                change = false;
                if(boardNumber !== currentBoardNumber) change = true;
                if(currentBoard.info.viewHeight !== boardHInt) change = true;
                if(change)
                {

                    board.info.viewHeight = boardHInt;

                    totalRows = parseInt(board.info.cells.total)/parseInt(board.info.cells.across);
                    thumbHInt = parseInt(props.databind.viewHeight)/parseInt(totalRows);
                    if(!isNaN(thumbHInt)
                    )
                    {
                        thumbHInt = parseInt(props.databind.viewHeight) / parseInt(totalRows);
                        //console.log(thumbHInt);
                        board.info.thumbHInt = thumbHInt;
                        
                    }


                    boardH = boardHInt.toString();
                    if(!boardH.match(/px/))
                    { 
                        boardH = boardH + "px";
                    }
                    //alert(boardH);
                    setBoardInfo({

                        view        :board.view,
                        viewHeight  :boardH,
                        thumbHInt   :thumbHInt

                    });

                    //console.log("board: "+JSON.stringify(board,null,2));
                    //alert('setting Current Board');
            

                
                    // if(boardNumber !== 0)
                    // {
                    //     var newImages = board.images;
                    //     board.images = []; 
                    //     board.images = newImages;
                    // }


                    board.overlay.boardNumber = boardNumber;
                    board.overlay.title = LibString.EncodeDecode({

                        str:board.overlay.title,
                        call:'decode',
                        type:'amp'

                    });
    
                    setCurrentBoard(board);
                    setCurrentBoardNumber(boardNumber);
                    //setFadeImage(true);

                }else{
                    //setFadeImage(false);
                }



            }
            else
            {

                console.log("Not - fullScreen");

                headerCall = props.databind.settings.appearance.header.headerCall
                //let headerW = props.databind.headerSizes.groupW;
                headerH = props.databind.headerSizes.groupH;


                viewHInt = props.databind.viewHeight;
                switch(Object.prototype.toString.call(props.databind.viewHeight))
                {
                case'[object String]':

                    viewHInt = props.databind.viewHeight.replace(/px/gi,"");

                break;
                default:
                }


                headerHInt = 0;
                switch(Object.prototype.toString.call(headerH))
                {
                case'[object Number]':

                    headerHInt = headerH;
                break;
                case'[object String]':

                    headerHInt = headerH.replace(/px/gi,"");
                break;
                default:
                }



                //boardHInt = viewHInt;
                boardHInt = board.info.viewHeight;
            

                // console.log("SIZES..."
                //     +"\nwidth: "+width
                //     +"\nheight: "+height
                //     +"\nviewHInt: "+viewHInt
        
                // );

                // console.log("Before..."
                //     +"\nboardHInt: "+boardHInt
                //     +"\nheaderHInt: "+headerHInt
                //     +"\nviewHInt: "+viewHInt
                // );

                if(headerCall !== 'C')
                {
                    boardHInt = viewHInt - headerHInt;
                    //console.log(boardH);
                    //console.log("yeah!!!");
                }
                if(props.refid !== 'row_0') 
                {
                    boardHInt = viewHInt - headerHInt;

                }

                // console.log("After... "
                //     +"\nboardHInt: "+boardHInt
                //     +"\nheaderHInt: "+headerHInt
                //     +"\nviewHInt: "+viewHInt
                //     +"\nboardNumber: "+boardNumber
                //     +"\ncurrentBoardNumber: "+currentBoardNumber
                // );



                change = false;
                if(boardNumber !== currentBoardNumber) change = true;
                if(currentBoard.info.viewHeight !== boardHInt) change = true;
                if(change)
                {

                    board.info.viewHeight = boardHInt;

                    totalRows = parseInt(board.info.cells.total)/parseInt(board.info.cells.across);
                    thumbHInt = parseInt(props.databind.viewHeight)/parseInt(totalRows);
                    if(!isNaN(thumbHInt)
                    )
                    {
                        thumbHInt = parseInt(props.databind.viewHeight) / parseInt(totalRows);
                        //console.log(thumbHInt);
                        board.info.thumbHInt = thumbHInt;
                        
                    }

                    console.log("thumbHInt: "+thumbHInt);


                    boardH = boardHInt.toString();
                    if(!boardH.match(/px/))
                    { 
                        boardH = boardH + "px";
                    }
                    //alert(boardH);
                    setBoardInfo({

                        view        :board.view,
                        viewHeight  :boardH,
                        thumbHInt   :thumbHInt

                    });

                    //console.log("board: "+JSON.stringify(board,null,2));
                    //alert('setting Current Board');
            

                
                    // if(boardNumber !== 0)
                    // {
                    //     var newImages = board.images;
                    //     board.images = []; 
                    //     board.images = newImages;
                    // }


                    board.overlay.boardNumber = boardNumber;
                    board.overlay.title = LibString.EncodeDecode({

                        str:board.overlay.title,
                        call:'decode',
                        type:'amp'

                    });

    
                    setCurrentBoard(board);
                    setCurrentBoardNumber(boardNumber);
                    //setFadeImage(true);

                }else{
                    //setFadeImage(false);
                }


            }
        
         
        
        }




        // -- cause -- //

        // console.log(""

        // +"\nisLoaded: "+isLoaded
        // +"\nisMultiBoard: "+isMultiBoard
        // +"\nboards.length: "+boards.length
        // +"\ncurrentBoard.images.length: "+currentBoard.images.length

        // )

        var cause = false;
        if(boards.length > 0) cause = true;
        if(cause)
        {
            //alert('cause change for current board')
            effectCurrentBoard({
        
                props:props,
                boards:boards,
                boardNumber:boardNumber,
                currentBoardNumber:currentBoardNumber,
                currentBoard:currentBoard

            });

        }



    },
    [
        props,

        isLoaded,
        isMultiBoard,

        boardInfo,
        boards,   
        boardNumber,
        currentBoardNumber,
        currentBoard,

        refA
 
    ])









    //---------------------------------------------
    // handle - general
    //---------------------------------------------

    const handle = (hndl) => {


        var obj;
        var num;

        //alert(JSON.stringify(hndl));
        switch(hndl.call)
        {
        case'size':

            // if(props.data.info.view !== 'fullScreen') 
            // {
            //     setBoardHeight(hndl.size.height+"px");

            // }else{

            //     if(refA.current.offsetWidth - 200 < refA.current.offsetHeight)
            //     {
            //         setBoardHeight("auto");
    
            //     }

            // }


             obj = boardInfo;
            if(currentBoard.info.view === 'wideScreen') 
            {
                //alert(hndl.size.height.toString() + " --- " +currentBoard.info.view);
                //num = hndl.size.height.toString();
                num = currentBoard.info.viewHeight.toString();
                if(num.match(/px/gi))
                {
                    num = num.replace(/px/gi,"");  
                }
                //console.log("wideScreen Number: "+num);

                obj.viewHeight = num+"px";
                setBoardInfo(obj);
            }
            else
            if(currentBoard.info.view === 'fullScreen') 
            {
                if(refA.current.offsetWidth - 200 < refA.current.offsetHeight)
                {
                    obj.viewHeight = "auto";
                    setBoardInfo(obj);
                }
            }
            else
            {
                //alert(hndl.size.height.toString() + " --- " +currentBoard.info.view);
                num = hndl.size.height.toString();
                if(num.match(/px/gi))
                {
                    num = num.replace(/px/gi,"");  
                }
                //console.log("size number: "+num);

                obj.viewHeight = num+"px";
                setBoardInfo(obj);

            }
        

        break;
        case'progressChange':

            num = boardNumber + 1;
            if(num === boards.length) num = 0;

            //alert(hndl.call+" "+num);
            setBoardNumber(num);

        break;
        case'clickChange':

            setBoardNumber(hndl.number);
        break;
        default:
        }

    }












    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

    
            <Board
            height={boardInfo.viewHeight}
            ref={refA}
            >
                <BoardSection 
                >
                </BoardSection>

            </Board>

        )

    } 
    else 
    {



        return (


            <Board
            ref={refA}
            //backgroundColor={'red'}
            height={boardInfo.viewHeight}
            //height={"auto"}
            >


                {
                currentBoard.alfa 
                ?

                    <BoardAlfa

                    backgroundColor     ={currentBoard.alfa.backgroundColor}
                    //backgroundColor     ={"darkblue"}  

                    border              ={currentBoard.alfa.border}
                    borderRadius        ={currentBoard.alfa.borderRadius}

                    percent             ={currentBoard.alfa.percent}
                    decimal             ={currentBoard.alfa.decimal}

                    zIndex              ={currentBoard.alfa.zIndex}
                    //zIndex              ={"1"}    

                    width               ={currentBoard.alfa.width}
                    height              ={currentBoard.alfa.height}

                    top                 ={currentBoard.alfa.top}
                    left                ={currentBoard.alfa.left}

                    >  
                    </BoardAlfa>

                :null
                }


                {
                currentBoard.base 
                ?

                    <BoardBase
                                        
                    backgroundColor     ={currentBoard.base.backgroundColor}
                    //backgroundColor     ={"darkred"}

                    border              ={currentBoard.base.border}
                    borderRadius        ={currentBoard.base.borderRadius}

                    zIndex              ={currentBoard.base.zIndex}
                    //zIndex              ={"2"}

                    width               ={currentBoard.base.width}
                    height              ={currentBoard.base.height}

                    top                 ={currentBoard.base.top}
                    left                ={currentBoard.base.left}

                    > 

                        {currentBoard.overlay && currentBoard.overlay.boolean === 1
                        ?
                            <Overlay

                                // databind={props.databind}
                                // data={props.data}  
                                
                                databind={props.databind}
                                data={currentBoard.overlay} 
                                handle={(v) => handle(v)} 
                                totalBoards={boards.length}

                            />

                        :
                        currentBoard.overlay && currentBoard.overlay.boolean === undefined
                        ?
                            <Overlay

                                // databind={props.databind}
                                // data={props.data}  
                                
                                databind={props.databind}
                                data={currentBoard.overlay} 
                                handle={(v) => handle(v)} 
                                totalBoards={boards.length}

                            />

                        :null}


                    </BoardBase>

                :null
                }




                {
                    currentBoard.images.length > 0
                    ?


                        <BoardSection 
                        >

                            {

                            <FGrid

                                handle      = {(v) => handle(v)} 
                                info        = {currentBoard.info}          
                                items       = {currentBoard.images}
                                refid       = {props.refid}
                                
                            />

                            }

    
                        </BoardSection>


                    :null

                }


            </Board>

                   
        )


    }


}

export default Index;



