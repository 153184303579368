
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



import {

    Browser,
    Mobile

} from './XFunx';


const Index = (props) => {
   

    // -- ref -- //
    const vidRef = useRef(null)


    // -- state -- //
  
    const [temp, setTemp] = useState(0)

    useEffect(() => {


        console.log(temp);
        setTemp(100);



        // http://stackoverflow.com/questions/7988476/listening-for-youtube-event-in-javascript-or-jquery/7988536#7988536


        function getFrameID(id){
            var elem = document.getElementById(id);
            if (elem) {
                if(/^iframe$/i.test(elem.tagName)) return id; //Frame, OK
                // else: Look for frame
                var elems = elem.getElementsByTagName("iframe");
                if (!elems.length) return null; //No iframe found, FAILURE
                for (var i=0; i<elems.length; i++) {
                if (/^https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com(\/|$)/i.test(elems[i].src)) break;
                }
                elem = elems[i]; //The only, or the best iFrame
                if (elem.id) return elem.id; //Existing ID, return it
                // else: Create a new ID
                do { //Keep postfixing `-frame` until the ID is unique
                    id += "-frame";
                } while (document.getElementById(id));
                elem.id = id;
                return id;
            }
            // If no element, return null.
            return null;
        }

        // Define YT_ready function.
        var YT_ready = (function(){
            var onReady_funcs = [], api_isReady = false;
            /* @param func function     Function to execute on ready
            * @param func Boolean      If true, all qeued functions are executed
            * @param b_before Boolean  If true, the func will added to the first
                                        position in the queue*/
            return function(func, b_before){
                if (func === true) {
                    api_isReady = true;
                    for (var i=0; i<onReady_funcs.length; i++){
                        // Removes the first func from the array, and execute func
                        onReady_funcs.shift()();
                    }
                }
                else if(typeof func == "function") {
                    if (api_isReady) func();
                    else onReady_funcs[b_before?"unshift":"push"](func); 
                }
            }
        })();


        /*
        // This function will be called when the API is fully loaded
        function onYouTubePlayerAPIReady() {


            alert('onYouTubePlayerAPIReady');

            YT_ready(true)
        
        }
        */



        // Load YouTube Frame API
        (function(){ //Closure, to not leak to the scope
        var s = document.createElement("script");
        s.src = "//www.youtube.com/player_api"; /* Load Player API*/
        var before = document.getElementsByTagName("script")[0];
        before.parentNode.insertBefore(s, before);
        })();



        var ytplayer;





        //####################################################

        //ON EVENT FUNCTIONS

            //PLAYER STATES
            /*
            window.YT.PlayerState.ENDED
            window.YT.PlayerState.PLAYING
            window.YT.PlayerState.PAUSED
            window.YT.PlayerState.BUFFERING
            window.YT.PlayerState.CUED
            */

        //####################################################


        function YouTube_PlayerReady(event) {

            //alert('youtube player is ready');

            /*
            if(zoneObj['videoautostart'] == 'yes') 
            {
                YouTube_PlayVideo();
            }////
            */


            var browser = Browser();
            var dm = Mobile();

            var is_mobile = dm['is_mobile'];
            //var mobile_agent = dm['mobile_agent'];



            if(is_mobile && browser.toUpperCase() !== "SAFARI") 
                YouTube_PlayVideo();

        }


        function YouTube_PlayerPlaybackQualityChange(event) {

            //alert('youtube player quality change');

        }

        function YouTube_PlayerError(event) {

            //alert('youtube player error');

        }


        function YouTube_PlayerStateChange(event) {

            var browser = Browser();
            var dm = Mobile();

            var is_mobile = dm['is_mobile'];
            //var mobile_agent = dm['mobile_agent'];



            if(event.data === window.YT.PlayerState.PAUSED)
            {
                if(is_mobile && browser.toUpperCase() !== "SAFARI") 
                    YouTube_PauseVideo();
            }

            if(event.data === window.YT.PlayerState.PLAYING)
            { 
                if(is_mobile && browser.toUpperCase() !== "SAFARI") 
                    YouTube_PlayVideo();
            }


            if(event.data === window.YT.PlayerState.BUFFERING) 
            {
            
                //alert('buffering');
            }

            if(event.data === window.YT.PlayerState.CUED) 
            {
            
                //alert('cued');

            }//

            if(event.data === window.YT.PlayerState.ENDED) 
            {
                
                YouTube_EndVideo();

            }



        }

            




        //################################

        //YOUTUBE PLAYER FUNCTIONS

        //################################
            
        /*
        function YouTube_StopVideo() {

            ytplayer.stopVideo();

        }
        */


        function YouTube_PlayVideo() {

            ytplayer.playVideo();//Getting error does not support this function ???
        }


        function YouTube_PauseVideo() {
            ytplayer.pauseVideo();
        }



    /*

        function YouTube_SeekVideo(num,hold) {

            if(!num) num = 0;
            ytplayer.seekTo(num);

            if(hold) ytplayer.pauseVideo();
        }


        function YouTube_SeekStopVideo(num) {

            if(!num) num = 0;
            ytplayer.seekTo(num);
            
        }

        

        function YouTube_LoadVideo(video_id) {

            //alert('Load Video');

            //var video_id = '_qeF6eWhUn4';
            ytplayer.loadVideoById(video_id,2);
            YouTube_PauseVideo();

        }
    */

        function YouTube_EndVideo() {


            alert('video Complete')

        }





        if(vidRef && vidRef !== null)
        {

            //console.log('running');
            //alert('yt ready')
    
            YT_ready(function(){
    
                var frameID = getFrameID("youtube_iframe");
                if(frameID) 
                { //If the frame exists
            
                    ytplayer = new window.YT.Player(frameID, {
    

                       events: {
                            'onReady'                   : YouTube_PlayerReady,
                            'onPlaybackQualityChange'   : YouTube_PlayerPlaybackQualityChange,
                            'onStateChange'             : YouTube_PlayerStateChange,
                            'onError'                   : YouTube_PlayerError
                        }
    
    
                    });
            
                }
            
            });
    
        }



    },[

        props,
        temp,
        vidRef

    ])




    const vidTitle = props.vidTitle

    var vidSrc = 'https://www.youtube.com/embed/'+props.vidId;
    vidSrc += "?wmode=transparent";
    vidSrc += "&autoplay=1";
    vidSrc += "&enablejsapi=1";
    vidSrc += "&playerapiid=ytplayer";
    vidSrc += "&modestbranding=1";
    vidSrc += "&showinfo=0";
    vidSrc += "&rel=0";
    


    return (


        <iframe 
            ref={vidRef}
            id="youtube_iframe"
            type="text/html"
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen='allowfullscreen'
            //mozallowfullscreen='mozallowfullscreen'
            //webkitallowfullscreen='webkitallowfullscreen'
            title={vidTitle}
            width={props.vidWidth}
            height={props.vidHeight}
            src={vidSrc}


        />

    );



}


export default Index;

