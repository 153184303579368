
/*
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
*/


const BuildColumn = (props) => {

    let info = props.info;
    let columns = props.columns;
    let column = props.column;
    let indx = props.indx;

    /*
    let array = [];
    for(i=0; i < columns.length; i++)
    {
        col = columns[i];
        colHInt = 0;
        for(j=0; j < col.length; j++)
        {
            box = col[j];
            array.push(box);
        }
    }
    let items = shuffleArray(array);
    */



    let items;
    items = column.concat(column);
    items = items.concat(column);


    var colHInt;
    var colDif;
    var dif;


    var i,j;
    var col,box;

    
    for(i=0; i < columns.length; i++)
    {
        col = columns[i];
        colHInt = 0;
        for(j=0; j < col.length; j++)
        {
            box = col[j];
            colHInt = colHInt + box.image.h;
        }
        if(i === indx)
        {
            if(colHInt < info.viewHeight)
            {
                colDif = info.viewHeight - colHInt;

                dif = 0;
                for(j=0; j < items.length; j++)
                {
                    box = items[j];
                    colHInt = colHInt + box.image.h;
                    column.push(box);
                    dif = dif + box.image.h;
                    if(dif > colDif || dif === colDif) break;

                }

            }
            break;
        }

    }
    
    //console.log(JSON.stringify(column,null,2));
    return column;

}

const FillColumns = (props) => {

    let info = props.info;
    let items = props.items;
    let columns = props.columns;

    //console.log(JSON.stringify(info,null,2));
    //console.log(JSON.stringify(items,null,2));

    var i;
    var col;


    //var colHInt;
    //var colDif;

    let newItems = [];
    let newCol;
    //var refCol;

    //var indx;
    for(i=0; i < columns.length; i++)
    {

        col = columns[i];
        newCol = BuildColumn({

            info    :info,
            items   :items,
            columns :columns,
            column  :col,
            indx    :i

        });
        newItems.push(newCol)

    }

    //console.log("FILL COLUMNS ITEMS: "+JSON.stringify(newItems,null,2));

    return newItems;

}//==

export default FillColumns;




/*
        

    colHInt = 0;
    for(j=0; j < col.length; j++)
    {
        box = col[j];
        colHInt = colHInt + box.image.h;
        newCol.push(box);
    }

    if(colHInt < info.viewHeight)
    {
        AddToColumn({

            info    :info,
            items   :items

        });

        colDif = info.viewHeight - colHInt;

        if(i === items.length-1)
        {
            indx = 0;
        }else{
            indx = (i+1);
        }

        //alert(indx);

        refCol = items[indx];
        for(j=0; j < refCol.length; j++)
        {
            box = refCol[j];
            colHInt = colHInt + box.image.h;

            //console.log(i+"_BOX: "+JSON.stringify(box,null,2));
            newCol.push(box);

        }



    }
    




*/
