const LibNumber =  {


	Round : (props) => {

	
        var round = function (num, precision) {
            num = parseFloat(num);
            if (!precision) return num.toLocaleString();
            return (Math.round(num / precision) * precision).toLocaleString();
        };
        var roundDown = function (num, precision) {
            num = parseFloat(num);
            if (!precision) return num.toLocaleString();
            return (Math.floor(num / precision) * precision).toLocaleString();
        };
        var roundUp = function (num, precision) {
            num = parseFloat(num);
            if (!precision) return num.toLocaleString();
            return (Math.ceil(num / precision) * precision).toLocaleString();
        };
    


        var r;

        switch(props.call)
        {
        case'roundDown':

            r = roundDown(props.number,props.precision)
        break;
        case'roundUp':
            r = roundUp(props.number,props.precision)
        break;
        default:
            r = round(props.number,props.precision)
        }

        return r;

    },


	PrintMoney : (props) => {



        //--------------------------
        // version 1
        //--------------------------

	    // function numberWithCommas(x) {
        //     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        // }
        
        // function test(x, expect) {
        //     const result = numberWithCommas(x);
        //     const pass = result === expect;
        //     console.log(`${pass ? "✓" : "ERROR ====>"} ${x} => ${result}`);
        //     return pass;
        // }
        
        // let failures = 0;
        // failures += !test(0,        "0");
        // failures += !test(100,      "100");
        // failures += !test(1000,     "1,000");
        // failures += !test(10000,    "10,000");
        // failures += !test(100000,   "100,000");
        // failures += !test(1000000,  "1,000,000");
        // failures += !test(10000000, "10,000,000");
        // if(failures) {
        //     console.log(`${failures} test(s) failed`);
        // } else {
        //     console.log("All tests passed");
        // }
    




        //----------------------
        // version 2
        //----------------------

        function numberWithCommas(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
        
        // function test(x, expect) {
        //     const result = numberWithCommas(x);
        //     const pass = result === expect;
        //     console.log(`${pass ? "✓" : "ERROR ====>"} ${x} => ${result}`);
        //     return pass;
        // }
        
        // let failures = 0;
        // failures += !test(0              , "0");
        // failures += !test(0.123456       , "0.123456");
        // failures += !test(100            , "100");
        // failures += !test(100.123456     , "100.123456");
        // failures += !test(1000           , "1,000");
        // failures += !test(1000.123456    , "1,000.123456");
        // failures += !test(10000          , "10,000");
        // failures += !test(10000.123456   , "10,000.123456");
        // failures += !test(100000         , "100,000");
        // failures += !test(100000.123456  , "100,000.123456");
        // failures += !test(1000000        , "1,000,000");
        // failures += !test(1000000.123456 , "1,000,000.123456");
        // failures += !test(10000000       , "10,000,000");
        // failures += !test(10000000.123456, "10,000,000.123456");
        // if (failures) {
        //     console.log(`${failures} test(s) failed`);
        // } else {
        //     console.log("All tests passed");
        // }




        //----------------------
        // version 3
        //----------------------

        // var n = 34523453.345
        // n.toLocaleString()
        // "34,523,453.345"



        var r;
        r = numberWithCommas(props);
        return r;

    }



}

export default LibNumber;




/*

     
    
    //https://gomakethings.com/how-to-round-to-the-nearest-number-with-vanilla-js/

    var round = function (num, precision) {
        num = parseFloat(num);
        if (!precision) return num.toLocaleString();
        return (Math.round(num / precision) * precision).toLocaleString();
    };
    var roundDown = function (num, precision) {
        num = parseFloat(num);
        if (!precision) return num.toLocaleString();
        return (Math.floor(num / precision) * precision).toLocaleString();
    };
    var roundUp = function (num, precision) {
        num = parseFloat(num);
        if (!precision) return num.toLocaleString();
        return (Math.ceil(num / precision) * precision).toLocaleString();
    };



*/  