import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import * as serviceWorker from './serviceWorker';

import store from './app-store/store';

import { 
    Provider, 
    //ReactReduxContext 
} from 'react-redux';

import { 
    MatomoProvider, 
    createInstance 
} from '@datapunt/matomo-tracker-react'


//matomo-start
const instance = createInstance({
	urlBase: "http://s2-r.com",
	siteId: "3",
	userId: "s2r_user",
	trackerUrl: "https://matomo.powerdigitalmedia.com/matomo/matomo.php",
	srcUrl: "https://matomo.powerdigitalmedia.com/matomo/matomo.js",
	disabled: false,
});
//matomo-end



// ReactDOM.render(
//     <React.StrictMode>
//         <Provider store={store}>
//             <App />
//         </Provider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={instance}>
            <Provider store={store}>
                <App />
            </Provider>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
