export const BUTTON_PILL_BLUE_APEX_BLUE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Base*/}

    <path 
        fill="rgb(0,143,213)" 
        d="M360,72c99.141,0,180,80.859,180,180s-80.859,180-180,180 s-180-80.859-180-180S260.859,72,360,72L360,72z"
    />
    
{/*Shadow*/}

    <path 
        fill="rgb(0,102,179)" 
        d="M539.734,242.278c0.172,3.22,0.266,6.46,0.266,9.722 c0,99.141-80.859,180-180,180c-51.972,0-98.91-22.23-131.818-57.657c29.268,13.729,61.891,21.424,96.259,21.424 C423.903,395.767,508.813,331.445,539.734,242.278L539.734,242.278z"
    />

{/*Mid*/}

    <path 
        fill="rgb(0,131,202)" 
        d="M360,72c87.123,0,160.117,62.446,176.537,144.866 c-184.474-33.93-152.897,101.937-335.041,120.274C187.793,311.767,180,282.766,180,252C180,152.859,260.859,72,360,72L360,72z"
    />

{/*Highlight*/}

    <path 
        fill="rgb(0,174,239)" 
        d="M360,87.288c55.609,0,104.926,27.806,134.775,70.22
		c-31.041-25.813-70.887-41.369-114.252-41.369c-98.624,0-179.062,80.438-179.062,179.062c0,0.512,0.015,1.021,0.02,1.531
		c-4.031-14.233-6.193-29.237-6.193-44.731C195.288,161.279,269.279,87.288,360,87.288L360,87.288z"
    />



    

		</svg>
		</div>

	);//return
}