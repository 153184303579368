
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';

import CreateMarkup from '../../CreateMarkup';
import { Print } from './Styled';


import Chart from 'utils/components/Chart';
import { ChartSamples } from 'utils/components/Chart/Cmp/Samples';


const Index = (props) => {


    // -- ref -- //
    const printRef = useRef(null)


    // -- state -- //
    const [printOut, setPrintOut] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [chartInfo, setChartInfo] = useState(ChartSamples.barChart);

    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);

            //let printOut;
            //printOut = JSON.stringify(props.data,null,2);
            //printOut = printOut.replace(/\n/gi,"<br/>");
            //setPrintOut("Chart goes here!!");
            setPrintOut(false);



            //console.log("PRINT H: "+printRef.current.offsetHeight);
            props.handle({

                call:"adjustContentsHeight",
                data:{

                    ref:printRef,
                    h:printRef.current.offsetHeight

                }

            })




            console.log("CHART PROPS: "+JSON.stringify(props,null,2));

            if(props.data && props.data !== undefined)
            {
                if(Object.prototype.toString.call(props.data.value) === '[object Array]')
                {
                    chartInfo.chart.data.items = props.data.value;
                    setChartInfo(chartInfo);  
                }

            }else{

                chartInfo.chart.data.items = [];
                setChartInfo(chartInfo);
            }
    

        }


        // console.log("FLYR CHART INFO: "+JSON.stringify(chartInfo,null,2));
        // alert('Flyr Chart Info')

   
    
    },[

        props,
        isLoaded,
        printRef,
        chartInfo

    ])



    
    if(printOut)
    {

        return (

            <Print
            ref={printRef}
            backgroundColor={'lightyellow'}
            >
    
                <div dangerouslySetInnerHTML={CreateMarkup(printOut)} />
    
            </Print>
    
        );


    }
    else
    {

        return (


            <Print
            ref={printRef}
            id={"flyr_print"}
            backgroundColor={'$f9f9f9'}
            >
                <Chart 

                    parentId = {"flyr_print"}
                    id = {"flyr_chart_canvas"}

                    //backgroundColor = {chartInfo.backgroundColor}
                    backgroundColor = {'transparent'}
                    border={chartInfo.border}
                    width={chartInfo.width}
                    margin={chartInfo.margin}

                    drawCheckerBoard = {chartInfo.drawCheckerBoard}
                    draw = {false}   
                    render = {false}
                    chart = {{
                        call    :chartInfo.chart.call,
                        data    :chartInfo.chart.data
                    }}

                />

            </Print>


 
                    
        );
    

    }




}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/