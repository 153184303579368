import React, { 
    useState, 
    useEffect, 
    //useRef 
} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {
    MediaColumn,
    MediaHashList
} from '../Styled/Media';


import '../StyleSheets/Anchor.css';
import '../StyleSheets/Checklist.css';
import '../StyleSheets/HashLinx.css';


// import CreateMarkup from 'utils/components/Universal/CreateMarkup';
// import CreateLink from 'utils/components/Universal/CreateLink';

import DefaultFetchResponse from 'utils/components/Pages/Default/FetchResponse';





const HashList = (props) => {


 
    const info = props.info;
    const items = props.items;
    //const columnClass = props.columnClass;


    // var headingStyl = false;
    // var pgraphStyl = false;
    // if(props.info.compStyl.box)
    // {
    //     if(props.info.compStyl.box.heading) headingStyl = props.info.compStyl.box.heading;
    //     if(props.info.compStyl.box.pgraph) pgraphStyl = props.info.compStyl.box.pgraph;
    // }




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");





    // -- ref -- //
    // const refA = useRef(null);
    // const refB = useRef(null);
    // const refC = useRef(null);



    //==========================================
    // state/effect - isLoaded, settings
    //==========================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [settings, setSettings] = useState(false);

    useEffect(() => {

        var resp;
        var sett;
        if(!isLoaded)
        {
            setIsLoaded(true);


            //----------------------------------
            // Default Settings 
            //----------------------------------
            
            if(zoneObj["settings"])
            {
                if(!settings)
                {
                    
                    resp = zoneObj["settings"];
                    if(resp )
                    {
                        sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
                        //console.log(JSON.stringify(sett));
                        setSettings(sett);
                    }

                }

            }else{

                //console.log("headerNav: "+JSON.stringify(DefaultFetchResponse.settings.headerNav));
                //alert('hold')

                setSettings(DefaultFetchResponse.settings);
            }


            // setSettings(DefaultFetchResponse.settings);


            // console.log("--------"+JSON.stringify(DefaultFetchResponse.settings,null,2));
          
        }


    },[

        props,
        zoneObj,
        isLoaded,
        settings
    ])







    //==========================================
    // state/effect - sizes
    //==========================================

    // const [nvRefs] = useState([]);// useState to avoid warnings when using cellRefs inside useEffect
    // const setNVRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < nvRefs.length; i++)
    //         {
    //             if(nvRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) nvRefs.push(ref);
    //         //console.log(nvRefs.length);
    //     }

    // };
    // const [nvSizes, setNVSizes] = useState([]);


    // useEffect(() => {


    //     if(nvRefs.length > 0)
    //     {
    //         //var indx;
    //         var ref;
    //         var maxWidth = 0;
    //         var array = [];
    //         var object;
    //         for(var i=0; i < nvRefs.length; i++)
    //         {
    //             object = {};
    //             if(nvRefs[i].id.match(/nvLeft/gi))
    //             {
    //                 //indx = nvRefs[i].id.split("-")[1];
    //                 ref = nvRefs[i];
    //                 //console.log(ref.offsetWidth);

    //                 if(maxWidth === 0) maxWidth = ref.clientWidth;
    //                 if(ref.clientWidth > maxWidth) maxWidth = ref.clientWidth;
    //             } 

    //             if(nvRefs[i].id.match(/nvRight/gi))
    //             {
    //                 //indx = nvRefs[i].id.split("-")[1];
    //                 ref = nvRefs[i];
    //                 //console.log(ref.clientHeight);
    //                 object['height'] = ref.clientHeight+"px";

    //             } 
    //             array.push(object);

    //         }
    //         // alert(maxWidth);
    //         // var sizes = [];
    //         // for(object of array)
    //         // {
    //         //     object.width = maxWidth+"px";
    //         //     sizes.push(object);
    //         // }
    //         //console.log(JSON.stringify(array,null,2));
    //         //alert(JSON.stringify(array));
    //         setNVSizes(array);

  
    //     }
    


    // },[

    //     nvRefs,
    //     isLoaded
    // ])










    //==========================================
    // state/effect - style
    //==========================================

    const [doStyle, setDoStyle] = useState(false);
    const [styl, setStyl] = useState({

        heading:{
            fontFamily:false,
            fontSize:false,
            fontWeight:false,
            textAlign:false,
            textDecoration:false,
            color:false
        },
        pgraph:{
            fontFamily:false,
            fontSize:false,
            fontWeight:false,
            textAlign:false,
            textDecoration:false,
            color:false
        },
        button:{},
        link:{}

    });

    useEffect(() => {


        // -- effect -- //
 
        const effectStyle = (eff) => {


            //console.log("Content: eff "+JSON.stringify(eff,null,2));

            // {
            //     "call": "style",
            //     "data": {
            //         "button": {
            //             "backgroundColorOver": "#fc0",
            //             "backgroundColorOut": "#0291cd",
            //             "borderOver": "1px solid black",
            //             "borderOut": "1px solid #0291cd",
            //             "textColorOVer:"black",
            //             "textColorOut": "white"
            //         },
            //         "link": {
            //             "textColorOver": "orange",
            //             "textColorOut": "#0291cd"
            //         }
            //     }
            // }


            var itm;
            var key;
            for(itm of eff.props.items)
            {
                if(itm.call === 'style')
                {
                    for(key in itm.data)
                    {
                        styl[key] = itm.data[key];
                    }
                }

            }
            setStyl(styl);




        }


        // -- cause -- //

        //console.log('location: '+JSON.stringify(location,null,2));

        if(isLoaded)
        {
            if(!doStyle)
            {
                effectStyle({

                    props:props,
                    styl:styl

                });
                setDoStyle(true);
            } 

        }

    },
    [
        props,
        isLoaded,
        doStyle,
        styl
    ])








    //==========================================
    // handle
    //==========================================

    // const handle = (hndl) => {

    //     //alert(JSON.stringify(hndl));
    //     switch(hndl.call)
    //     {
    //     case'url':

    //         // check url

    //         // var href = window.location.href;                //returns the href (URL) of the current page
    //         // var host = window.location.host;                //returns the domain with port number
    //         // var hostname = window.location.hostname;        //returns the domain name of the web host
    //         // var pathname = window.location.pathname;        //returns the path and filename of the current page
    //         // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
    //         // var assign = window.location.assign;            //loads a new document

    //         var externalUrl = false;
    //         var url;
    //         url = hndl.data;

    //         var internalSite = "site.site/";

    //         var regxInternalSite = new RegExp(internalSite,"gi");
    //         var regxHost = new RegExp(window.location.host,"gi");
    //         if(url.match(regxInternalSite))
    //         {
    //             url = url.split(".site/")[1];
    //         }//==
    //         else
    //         if(!url.match(regxHost))
    //         {
    //             externalUrl = true;
    //         }//==
    //         //alert(url);



    //         if(externalUrl)
    //         {
    //             props.handle({

    //                 call:'url',
    //                 data:{

    //                     action:'target',
    //                     url:url

    //                 }

    //             });

    //         }else{

    //             props.handle({

    //                 call:'url',
    //                 data:{

    //                     action:'push',
    //                     url:url

    //                 }

    //             });

    //         }




    //     break;
    //     case'HashLink':

    //         // alert(hndl.data);
    //         // history.push(hndl.data);
    //         // window.location.href = hndl.data;

    //         // props.handle({

    //         //     call      :"HashLink",
    //         //     data      :hndl.data

    //         // });

    //     break;
    //     case'handleHash':

    //         //HashButtonClick(hndl);
    //     break;
    //     default:
    //     }

    // }



    

    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl));

    //     //console.log(JSON.stringify(hndl,null,2));

    //     if(hndl.id.match(/_arrowButton/gi))
    //     {

    //         //var btn;
    //         var arrowBg;
    //         var arrow1;
    //         //var arrow2;

    //         //btn = document.getElementById(hndl.id);
    //         //btn.style.borderColor = hndl.borderColor;

    //         arrowBg = document.getElementById(hndl.id+"_bg");
    //         arrowBg.style.backgroundColor = hndl.bgColor;


    //         if(hndl.id.match(/_arrowButtonDown/gi))
    //         {
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderTopColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderTopColor = hndl.arrowColor;

    //         }
    //         else
    //         if(hndl.id.match(/_arrowButtonUp/gi))
    //         {
    
    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderBottomColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderBottomColor = hndl.arrowColor;
    //         }
    //         else
    //         {

    //             arrow1 = document.getElementById(hndl.id+"_arrow1");
    //             arrow1.style.borderTopColor = hndl.arrowColor;

    //             //arrow2 = document.getElementById(hndl.id+"_arrow2");
    //             //arrow2.style.borderTopColor = hndl.arrowColor;

    //         }



        
    //     }



    // }










    if(!isLoaded)
    {

     

        return (

            <MediaColumn>

                {items.map((item, i) => (

                    <div 
                    key={i}
                    className={"Row"}
                    >

                        {
                        item.call === 'cke'
                        ?
                    

                            item.data.map((cke, j) => (


                                <div
                                key={j}
                                >

                                    {
                                    cke.call === 'HashList'
                                    ?


                                        <MediaHashList
                                        //id={info.compData.id}
                                        
                                        backgroundColor={info.compStyl.box['backgroundColor']}
                                        border={info.compStyl.box['border']}
                                        borderRadius={info.compStyl.box['borderRadius']}
                                        
                                        width={info.compStyl.box['width']}
                                        height={info.compStyl.box['height']}
                                        padding={info.compStyl.box['padding']}
                                        margin={info.compStyl.box['margin']}
                                        float={info.compStyl.box['float']}

                                        titleColor={info.compStyl.title['textColor']}
                                        titleFontSize={info.compStyl.title['fontSize']}

                                        linkOutColor={info.compStyl.link['outColor']}
                                        linkOverColor={info.compStyl.link['overColor']}
                                        linkFontSize={info.compStyl.link['fontSize']}

                                        bulletType={info.compStyl.bullet['type']}
                                        bulletWidth={info.compStyl.bullet['width']}
                                        bulletHeight={info.compStyl.bullet['height']}

                                        >

                                        <div 
                                        className="ListTitle" 
                                        style={{fontSize:"24px"}}>
                                            {cke.data.title}
                                        </div>

                                        {cke.data.list.map((dat, k) => (


                                            <div
                                            key={k}
                                            className={"ListItem"}
                                            onClick={(v) => props.handle({

                                                call      :"HashLink",
                                                data      :dat.value

                                            })}
                                            >
                    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div className="Bullet">
                                                        <div className="Square">&nbsp;</div>
                                                    </div>
                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    {/* <a className="ListAnchor" href={dat.value}>{dat.name}</a> */}
                                            
                                                    <div
                                                    className="ListItemName"
                                                    >
                                                        {dat.name}
                                                    </div>

                                                </div>


                                            </div>

                                    
                                        ))}
                                        </MediaHashList>


                                    :null
                                    }


                                </div>



                            ))


                        :null
                        }






                    </div>


                ))}

            </MediaColumn>
        

        )



    }else{




        return (



            <MediaColumn>

                {items.map((item, i) => (

                    <div 
                    key={i}
                    className={"Row"}
                    >

                        {
                        item.call === 'cke'
                        ?
                    

                            item.data.map((cke, j) => (


                                <div
                                key={j}
                                >


                                    {
                                    cke.call === 'HashList'
                                    ?


                                        <MediaHashList
                                        //id={info.compData.id}
                                        
                                        backgroundColor={info.compStyl.box['backgroundColor']}
                                        border={info.compStyl.box['border']}
                                        borderRadius={info.compStyl.box['borderRadius']}
                                        
                                        width={info.compStyl.box['width']}
                                        height={info.compStyl.box['height']}
                                        padding={info.compStyl.box['padding']}
                                        margin={info.compStyl.box['margin']}
                                        float={info.compStyl.box['float']}

                                        titleColor={info.compStyl.title['textColor']}
                                        titleFontSize={info.compStyl.title['fontSize']}

                                        linkOutColor={info.compStyl.link['outColor']}
                                        linkOverColor={info.compStyl.link['overColor']}
                                        linkFontSize={info.compStyl.link['fontSize']}

                                        bulletType={info.compStyl.bullet['type']}
                                        bulletWidth={info.compStyl.bullet['width']}
                                        bulletHeight={info.compStyl.bullet['height']}

                                        >

                                        <div 
                                        className="ListTitle" 
                                        style={{fontSize:"24px"}}>
                                            {cke.data.title}
                                        </div>

                                        {cke.data.list.map((dat, k) => (


                                            <div
                                            key={k}
                                            className={"ListItem"}
                                            onClick={(v) => props.handle({

                                                call      :"HashLink",
                                                data      :dat.value

                                            })}
                                            >
                    
                                                <div 
                                                className={"Left"}
                                                >

                                                    <div className="Bullet">
                                                        <div className="Square">&nbsp;</div>
                                                    </div>
                                            
                                                </div>

                                                <div 
                                                className={"Right"}
                                                >
                                                    {/* <a className="ListAnchor" href={dat.value}>{dat.name}</a> */}
                                            
                                                    <div
                                                    className="ListItemName"
                                                    >
                                                        {dat.name}
                                                    </div>

                                                </div>


                                            </div>

                                    
                                        ))}
                                        </MediaHashList>


                                    :null
                                    }


                                </div>

   

                            ))


                        :null
                        }


                    </div>


                ))}

            </MediaColumn>
        

        )
    
    }



}

export default HashList;


