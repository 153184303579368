export const SETTINGS = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";

	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M217.82,173.957c42.028-17.409,90.504,2.671,107.915,44.699 c17.408,42.028-2.672,90.505-44.699,107.913c-42.029,17.408-90.506-2.672-107.914-44.699 C155.712,239.843,175.792,191.366,217.82,173.957L217.82,173.957z M296.699,72.967l-30.291,37.896 c-8.555,10.517-24.223,10.568-32.748,0.09L203.37,73.057c-19.26-2.046-33.243,3.751-45.489,18.892l5.378,48.217 c1.379,13.424-9.667,24.392-23.134,23.007l-48.216-5.378c-15.064,12.173-20.991,26.251-18.945,45.616l37.896,30.292 c10.517,8.554,10.568,24.223,0.09,32.746l-37.897,30.291c-2.045,19.26,3.751,33.244,18.893,45.49l48.216-5.379 c13.424-1.381,24.392,9.668,23.007,23.135l-5.378,48.217c12.172,15.064,26.274,20.877,45.639,18.83l30.292-37.896 c8.542-10.504,24.11-10.559,32.628-0.092l30.289,37.898c19.242,2.033,33.27-3.641,45.525-18.809l-5.379-48.215 c-1.381-13.438,9.734-24.48,23.219-23.092l48.217,5.377c15.045-12.168,20.867-26.135,18.807-45.525l-37.896-30.291 c-10.502-8.543-10.559-24.11-0.09-32.626l37.898-30.291c2.031-19.241-3.641-33.27-18.809-45.525l-48.217,5.378	c-13.434,1.383-24.479-9.733-23.092-23.219l5.379-48.216C330.033,76.845,316.09,70.907,296.699,72.967L296.699,72.967z"
			/>


		</svg>
		</div>

	);//return




}