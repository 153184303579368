export const WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

    
    {/*Wing Backgroung*/}
     <path 
        fill="rgb(2,32,95)" 
        d="M76.175,164.732v26.066c0,4.445,3.33,7.995,8.097,8.632 l37.004,3.821h8.348c2.311,0,4.487-0.903,6.126-2.543c1.641-1.641,2.544-3.816,2.544-6.127l-0.002-29.851 c-0.001-4.78-3.89-8.67-8.67-8.67H84.846C80.064,156.062,76.175,159.951,76.175,164.732L76.175,164.732z"
    />
    {/*Center*/}
    <path 
        fill="rgb(18,59,136)" 
        d="M78.335,164.787v25.961c0.177,3.737,2.663,5.918,6.234,6.542 l36.818,3.802h8.21c3.675-0.092,6.451-2.854,6.537-6.534l-0.002-29.771c-0.191-3.798-2.766-6.374-6.564-6.565H84.9 C81.099,158.412,78.529,160.988,78.335,164.787L78.335,164.787z"
    />
    {/*Filler*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M76.175,203.256v-12.457c0,2.741,1.266,5.141,3.354,6.729L76.175,203.256 L76.175,203.256z"
    />
    {/*1*/}
     <path 
        fill="rgb(0,0,0)" 
        d="M103.268,186.102v-15.141c-0.846,0.272-1.664,0.481-2.447,0.627 c-0.776,0.15-1.548,0.237-2.307,0.271v-6.15c1.531-0.262,2.829-0.673,3.896-1.24c1.073-0.569,1.931-1.295,2.574-2.176h6.981v23.809	h3.992v7.732H98.657v-7.732H103.268L103.268,186.102z"
    />


    

		</svg>
		</div>

	);//return
}