export const HELMET_RED_DRIVER_NELSON_PIQUET = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      

{/*White*/}
    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M295.31,140.592c15.959,0,28.52,9.59,31.176,23.92l3.755,20.187 c4.622,1.241,1.783,8.212,0.386,11.543l-2.126,5.008c-0.975,2.013-2.859,5.337-4.996,5.281l-6.514-0.342 c-3.291-0.191-6.769-0.616-10.016-1.176l-39.141-6.901c-2.97-7.738-5.789-16.426-5.789-26.26	C262.045,152.664,276.121,140.592,295.31,140.592L295.31,140.592z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c26.055,0,30.001,22.563,30.001,22.563l3.89,20.986c3.744,0,2.264,5.341,0.289,9.992l-2.115,4.983 c-0.649,1.53-2.321,2.045-3.865,1.964l-6.571-0.344c-2.552-0.135-7.379-0.795-9.718-1.207l-38.247-6.744 C265.748,186.053,263.274,179.661,263.274,171.851L263.274,171.851z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338	l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248	l-6.552-0.344c-1.833-0.109-3.734-0.318-5.565-0.569l2.025-4.762c0.626-1.493,4.915-11.167,1.597-11.751l-0.694-0.123l-3.55-19.154 c-0.264-1.425-2.542-15.416-16.416-21.3C294.689,143.494,294.986,143.48,295.307,143.473L295.307,143.473z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M295.307,143.473c13.824,0.374,25.621,8.289,28.715,21.338	l3.996,21.561l1.332,0.235l0.078,0.425c2.385,0.355-0.638,7.259-1.067,8.282l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248	l-4.869-0.256l1.96-4.615c0.656-1.563,5.192-10.655,1.716-11.268l-0.137-0.024l-3.807-20.54 c-0.938-5.063-4.646-17.723-23.346-21.332C295.15,143.477,295.227,143.475,295.307,143.473L295.307,143.473z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(220,220,220)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c3.355,0,6.343,0.377,9.004,1.029l-0.699-0.043c-13.895-0.85-21.986,3.098-25.757,16.578l-5.293,18.924 c1.241,4.15,3.126,8.941,5.917,14.552l23.384,4.121l-0.594,1.398l-30.487-5.376c-0.439-1.147-0.902-2.293-1.367-3.439l-0.908,3.244 C265.514,185.43,263.274,179.283,263.274,171.851L263.274,171.851z"
    />


    
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M269.416,189.992c-2.382-5.863-4.854-11.744-4.917-18.134 c0.185-7.932,3.001-14.31,7.501-18.997c-0.763,5.877-3.409,12.58,0.564,25.873L269.416,189.992L269.416,189.992z"
    />
    {/*Front Edge*/}
    <path 
        fill="rgb(210,210,210)" 
        d="M323.118,158.533l-1.59-0.28c1.089,2.009,1.935,4.199,2.493,6.558 l3.996,21.561l1.332,0.235l0.078,0.425c1.88,0.28,0.399,4.626-0.521,6.957l1.223,0.215c1.602-4.198,2.394-8.406-0.93-8.406 l-3.89-20.986C325.31,164.811,324.833,162.088,323.118,158.533L323.118,158.533z"
    />

{/*Red*/}


    {/*Base Color*/}
    <path 
        fill="rgb(200,0,0)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c17.002,0,24.588,9.608,27.809,16.285l-43.849-7.732c-6.694-1.18-12.547,6.165-14.203,14.713 c-1.367,8.601,1.618,17.503,8.315,18.684l56.746,10.006c-0.202,0.532-0.417,1.063-0.639,1.586l-2.115,4.983 c-0.649,1.53-2.321,2.045-3.865,1.964l-6.571-0.344c-2.552-0.135-7.379-0.795-9.718-1.207l-38.247-6.744
		C265.748,186.053,263.274,179.661,263.274,171.851L263.274,171.851z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(180,0,0)" 
        d="M295.307,143.473c11.325,0.307,21.288,5.675,26.222,14.78 l-13.825-2.438c-2.338-4.406-6.374-9.365-13.302-12.304C294.689,143.494,294.986,143.48,295.307,143.473L295.307,143.473z M328.906,193.988c-0.248,0.626-0.455,1.108-0.546,1.325l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248l-6.552-0.344 c-1.833-0.109-3.734-0.318-5.565-0.569l2.025-4.762c0.236-0.565,0.998-2.304,1.674-4.278L328.906,193.988L328.906,193.988z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(255,0,0)" 
        d="M295.307,143.473c11.325,0.307,21.288,5.675,26.222,14.78 l-5.729-1.011c-2.876-5.379-8.699-11.443-20.725-13.764C295.15,143.477,295.227,143.475,295.307,143.473L295.307,143.473z M328.906,193.988c-0.248,0.626-0.455,1.108-0.546,1.325l-2.103,4.952c-0.514,1.059-1.612,1.277-2.701,1.248l-4.869-0.256 l1.96-4.615c0.234-0.558,0.962-2.074,1.643-3.82L328.906,193.988L328.906,193.988z"
    />
    {/*Red Shadow*/}
    <path 
        fill="rgb(160,0,0)" 
        d="M301.271,198.808l-30.487-5.376 c-0.439-1.148-0.901-2.293-1.367-3.439l1.814-6.49c0.668,0.324,1.385,0.561,2.15,0.695l1.139,0.201 c1.056,2.717,2.357,5.667,3.961,8.89l23.384,4.121L301.271,198.808L301.271,198.808z"
    />
    {/*Red Roof Shadow*/}
    <path 
        fill="rgb(160,0,0)" 
        d="M263.274,171.851c0-11.66,8.771-29.603,32.034-29.603 c3.357,0,6.346,0.377,9.008,1.03l-0.703-0.044c-10.318-0.631-17.438,1.387-21.962,7.987l-2.383-0.421 c-6.694-1.18-12.547,6.165-14.203,14.713c-1.211,7.617,0.993,15.471,6.166,17.986l-2.724,9.736 C265.514,185.43,263.274,179.283,263.274,171.851L263.274,171.851z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(100,0,0)" 
        d="M269.416,189.992c-2.382-5.863-4.854-11.744-4.917-18.134 c0.028-1.249,0.124-2.458,0.28-3.628c-0.357,6.692,1.895,13.054,6.451,15.271L269.416,189.992L269.416,189.992z"
    />
    


{/*Logo*/}


    {/*Logo Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M309.179,173.293l-5.308-1.825c-3.251-1.253-6.157-3-9.021-4.966 c-2.076-1.467-4.092-3.009-6.104-4.562c-1.809-1.398-3.619-2.788-5.512-4.073c-1.57-1.025-3.092-1.946-4.966-2.354 c-6.3-0.854-9.78,5.266-10.79,10.425c-0.817,5.204,0.361,12.128,6.574,13.487c1.896,0.255,3.627-0.083,5.472-0.514	c2.217-0.562,4.394-1.248,6.572-1.942c2.421-0.772,4.843-1.531,7.296-2.198c3.362-0.871,6.691-1.517,10.175-1.582L309.179,173.293 L309.179,173.293z"
    />
    {/*Logo Red*/}
    <path 
        fill="rgb(200,0,0)" 
        d="M304.481,172.463c-9.249-2.979-17.908-12.243-24.089-15.435 c-6.182-3.191-11.088,2.837-12.182,9.04c-1.094,6.201,1.455,13.545,8.354,12.661C283.465,177.845,294.771,172.101,304.481,172.463 L304.481,172.463z"
    />
    {/*Logo White*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M287.115,162.559c-1.738-1.337-3.481-2.657-5.318-3.856 c-1.193-0.738-2.391-1.421-3.787-1.724c-5.327-0.72-8.228,4.947-9.067,9.219c-0.673,4.305,0.116,10.611,5.368,11.764 c1.438,0.193,2.74-0.032,4.147-0.326c2.137-0.497,4.228-1.143,6.317-1.804c2.422-0.771,4.842-1.543,7.286-2.237 c2.868-0.781,5.886-1.509,8.854-1.76c-2.702-1.249-5.293-2.967-7.719-4.682C291.137,165.664,289.127,164.111,287.115,162.559 L287.115,162.559z"
    /> 
    {/*Logo Yellow*/}
    <path 
        fill="rgb(255,185,20)" 
        d="M282.619,160.144c-1.461-0.993-2.989-2.052-4.738-2.433 c-4.85-0.654-7.433,4.681-8.207,8.615c-0.609,3.912-0.01,9.855,4.766,10.902c1.777,0.238,3.57-0.231,5.284-0.666 c2.214-0.6,4.396-1.302,6.581-1.999c1.846-0.585,3.694-1.157,5.558-1.687c2.178-0.593,4.352-1.111,6.581-1.479 c-1.969-1.109-3.836-2.338-5.678-3.641c-1.57-1.135-3.112-2.305-4.646-3.486C286.305,162.87,284.494,161.464,282.619,160.144 L282.619,160.144z"
    />
    {/*Logo Yellow Shadow*/}
    <path 
        fill="rgb(250,150,30)" 
        d="M278.456,157.861c-0.188-0.058-0.381-0.109-0.575-0.15	c-4.85-0.654-7.433,4.681-8.207,8.615c-0.547,3.516-0.117,8.67,3.441,10.439l4.741-16.953 C278.045,159.139,278.246,158.489,278.456,157.861L278.456,157.861z"
    />
    

{/*Visor*/}    

    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M296.008,159.869c1.348-1.318,2.801-1.846,4.589-1.877	c1.384,0.087,2.493,0.447,3.72,1.144l4.868,2.983c1.511,0.874,3.318,1.426,5.068,1.468h10.462c2.343,0.171,3.435,1.169,3.916,3.433 l3.176,17.135c0.246,1.495-0.053,2.72-1.793,2.867h-3.879c-1.25-0.016-2.475-0.183-3.704-0.393l-15.239-2.687 c-4.283-2.877-7.226-6.723-9.666-11.216c-0.744-1.408-1.431-2.842-2.09-4.291C294.135,165.568,293.826,162.416,296.008,159.869	L296.008,159.869z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(0,60,130)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.365,167.523c-2.801-6.212,2.553-10.267,7.328-7.334 l4.865,2.981c1.674,1.025,4.138,1.64,5.68,1.64h10.432c2.082,0,2.518,1.143,2.76,2.447l3.171,17.107 c0.182,0.979,0.125,1.432-0.638,1.432l-5.182,0.004l-17.122-3.02C301.553,178.843,298.28,171.768,296.365,167.523L296.365,167.523z M301.424,160.654c-2.267,0-4.115,1.849-4.115,4.115s1.849,4.115,4.115,4.115c2.268,0,4.115-1.849,4.115-4.115 S303.691,160.654,301.424,160.654L301.424,160.654z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(0,125,200)" 
        d="M324.67,164.811h-10.432h-0.102l3.662,19.76l6.982,1.23 l4.418-0.003l-3.882-20.944C325.121,164.825,324.906,164.811,324.67,164.811L324.67,164.811z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(0,175,240)" 
        points="318.42,164.811 324.021,164.811 327.912,185.797 324.762,185.797 322.227,185.351 318.42,164.811 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M301.424,161.135c2.002,0,3.636,1.633,3.636,3.635	s-1.634,3.635-3.636,3.635s-3.635-1.633-3.635-3.635S299.422,161.135,301.424,161.135L301.424,161.135z M301.424,162.477 c-1.263,0-2.293,1.03-2.293,2.293c0,1.264,1.03,2.294,2.293,2.294c1.264,0,2.294-1.03,2.294-2.294	C303.718,163.507,302.688,162.477,301.424,162.477L301.424,162.477z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M309.85,166.428c0-0.748-0.611-1.359-1.36-1.359s-1.36,0.611-1.36,1.359 c0,0.313,0.11,0.599,0.289,0.829c-0.572,1.412-1.606,2.598-2.961,3.249c-1.514,0.68-0.701,2.139,0.471,1.58 c1.85-0.891,3.301-2.468,4.05-4.392C309.486,167.497,309.85,167.005,309.85,166.428z"
    />

    {/*Suit*/}
    <polygon 
        fill="rgb(255,245,0)" 
        points="363.313,198.04 353.853,201.166 364.894,201.938 363.313,198.04 	"
    />

    

		</svg>
		</div>

	);//return
}