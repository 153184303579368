
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />


*/




export const Bar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#0291cd",
    width:              props.width || "100%",
    height:             props.height || "30px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    border-bottom:1px solid lightgray;

`;


    export const Shuttle = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        width:              props.width || "auto",
        height:             props.height || "auto",
        padding:            props.padding || "0 0 0 0",
        margin:             props.margin  || "0 0 0 0"


    }))`

        background-color:${props => props.backgroundColor};
        width:${props => props.width};
        height:${props => props.height};
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;   
        float:left;

        display:inline-grid;
        grid-template-columns: auto auto auto auto auto;



        .button {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:5px 12px 5px 12px;
            margin:0 3px 0 3px;
    
            position:relative;
            float:left;
    
            font-family:"Verdana", sans-serif;      
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#000;
    
            display:block;
    
            border-radius:7px;
    
        }
    
        .button:hover {
    
            background-color:lightgreen;/*#07a2e3;*/
            color:#000;
    
        }
    
    

        .button-hold {
    
            background-color:#0291cd;
    
            width:auto;
            height:auto;
            padding:5px 12px 5px 12px;
            margin:0 3px 0 3px;
    
            position:relative;
            float:left;
    
            font-family:"Verdana", sans-serif;      
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            text-decoration:none;
            color:#fff;
    
            display:block;
    
            border-radius:7px;
    
        }
    
  

    `;



