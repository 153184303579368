import { configureStore } from '@reduxjs/toolkit';


//import zCounterReducer from '../app-components/Zone/Counter/counterSlice';
//import counterReducer from './reducers/counter';
import loggedReducer from './reducers/logged';
import zoneReducer from './reducers/zone';


export default configureStore({

    reducer: {

        //zCounter    :zCounterReducer,    
        //counter     :counterReducer,
        logged      :loggedReducer,
        zone        :zoneReducer
        
    }

});



