export const BUTTON_HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M349.306,75.195c81.227,0,145.155,48.81,158.675,121.745l19.111,102.742 c23.525,6.317,9.076,41.796,1.963,58.75l-10.82,25.488c-4.961,10.244-14.554,27.163-25.429,26.88l-33.152-1.739 c-16.75-0.975-34.45-3.137-50.977-5.984l-199.213-35.126C194.35,328.565,180,284.35,180,234.298 C180,136.64,251.643,75.195,349.306,75.195L349.306,75.195z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(0,141,72)" 
        d="M254.307,195.086l57.378,99.393 c6.124,11.422-1.72,11.93-11.879,10.478l-72.646-12.804l2.987-10.681l33.534,5.909c17.963,3.172,23.088-7.714,13.967-23.52 l-28.684-49.674L254.307,195.086L254.307,195.086z M274.134,138.498l221.52,39.058c4.847,12.391,6.343,20.905,6.343,20.905	l19.797,106.813c19.057,0,11.521,27.184,1.473,50.857l-10.768,25.363c-3.305,7.789-11.814,10.408-19.673,9.996l-33.445-1.75	c-12.987-0.686-37.557-4.046-49.46-6.144l-194.665-34.325c-0.79-2.067-1.586-4.115-2.361-6.139l4.842-17.287l190.892,33.665	c22.526,3.966,34.917-7.729,21.999-30.101L330.572,156.108l-60.032-10.582C271.693,143.091,272.891,140.755,274.134,138.498 L274.134,138.498z M395.167,88.864c28.411,6.979,49.476,20.17,65.013,35.061L316.148,98.526c20.08-9.076,45.021-11.74,75.421-9.881 L395.167,88.864L395.167,88.864z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(0,114,54)" 
        d="M242.204,294.802l-15.045-2.649l2.987-10.686l7.729,1.366 C239.207,286.715,240.653,290.711,242.204,294.802L242.204,294.802z M217.735,325.847l40.886,7.212 c1.61,3.385,3.28,6.83,5.03,10.344l119.016,20.975l-3.022,7.118l-155.17-27.362c-2.236-5.84-4.593-11.675-6.958-17.506 L217.735,325.847L217.735,325.847z"
    /> 
    {/*Roof Shadow*/}
    <path 
        fill="rgb(0,88,38)" 
        d="M202.437,168.435c5.109-10.293,11.506-20.363,19.295-29.768 l3.131,5.428c-6.461,7.67-12.107,16.119-16.79,25.334L202.437,168.435L202.437,168.435z M228.59,150.536l25.717,44.55 l-5.343,19.102l-24.121-41.781C225.966,164.538,227.357,157.346,228.59,150.536L228.59,150.536z M202.615,287.818l-9.235-1.625 c-1.033-3.902-1.968-7.794-2.793-11.695l8.37,1.471C200.08,279.945,201.303,283.892,202.615,287.818L202.615,287.818z M223.009,137.146c1.913-2.247,3.901-4.448,5.979-6.605l45.146,7.957c-1.243,2.257-2.44,4.593-3.594,7.028l-39.986-7.053	c0.04-0.278,0.074-0.552,0.114-0.83c-0.229,0.238-0.462,0.487-0.691,0.726L223.009,137.146L223.009,137.146z M212.894,343.134 c-2.564-6.695-5.02-13.146-7.336-19.434l11.601,2.042c0.119,0.299,0.239,0.587,0.358,0.885L212.894,343.134L212.894,343.134z M287.35,93.446c17.898-6.218,38.471-9.821,61.951-9.821c17.093,0,32.313,1.919,45.866,5.239l-3.598-0.219 c-30.399-1.859-55.341,0.805-75.421,9.881L287.35,93.446L287.35,93.446z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(0,166,81)" 
        d="M417.733,163.817l70.4,12.416	c3.002,7.038,5.467,14.454,7.307,22.228l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154	l-10.701,25.205c-2.614,5.388-8.206,6.501-13.748,6.352l-33.347-1.749c-9.329-0.557-19.007-1.621-28.326-2.898l10.309-24.24	c3.187-7.595,25.016-56.831,8.132-59.804l-3.539-0.626l-18.067-97.489C427.679,195.449,425.338,181.383,417.733,163.817	L417.733,163.817z M444.438,121.151l-67.582-11.919c-9.061-7.291-19.688-13.887-32.173-19.176c1.466-0.089,2.977-0.159,4.607-0.198 C385.202,90.827,418.419,101.807,444.438,121.151L444.438,121.151z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(114,191,68)" 
        d="M458.858,171.069l29.275,5.164 c3.002,7.038,5.467,14.454,7.307,22.228l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154 l-10.701,25.205c-2.614,5.388-8.206,6.501-13.748,6.352l-24.782-1.302l9.976-23.49c3.34-7.958,26.428-54.231,8.732-57.349 l-0.695-0.124L466.93,198.461C465.647,191.563,463.356,181.89,458.858,171.069L458.858,171.069z M444.438,121.151l-30.881-5.447 c-16.332-11.129-37.626-20.448-65.45-25.816c0.388-0.01,0.775-0.02,1.184-0.029C385.202,90.827,418.419,101.807,444.438,121.151 L444.438,121.151z"
    />
    {/*Pattern Base*/}
    <path 
        fill="rgb(167,169,172)" 
        d="M186.258,234.292c0-18.896,4.532-41.03,14.523-62.428l21.73,3.832 l51.93,89.949c9.489,16.428,0.522,20.006-10.179,18.117l-74.461-13.127C187.535,258.881,186.258,246.952,186.258,234.292	L186.258,234.292z M232.134,127.375c13.008-12.734,29.052-23.649,48.437-31.398l184.123,32.462	c14.593,15.338,23.748,31.925,29.325,45.111L232.134,127.375L232.134,127.375z M204.106,319.724 c-3.688-10.18-6.959-19.946-9.663-29.619l104.786,18.476c20.388,3.594,21.005-6.626,15.661-15.886l-87.18-151l100.54,17.729 l99.169,171.757c10.234,17.733,2.342,28.331-18.221,24.707L204.106,319.724L204.106,319.724z"
    />
    {/*Pattern Shadow*/}
    <path 
        fill="rgb(128,130,133)" 
        d="M186.258,234.292c0-18.896,4.532-41.03,14.523-62.428l21.73,3.832 l25.07,43.427l-16.447,58.805l-41.333-7.292C187.535,258.881,186.258,246.952,186.258,234.292L186.258,234.292z M232.134,127.375	c13.008-12.734,29.052-23.649,48.437-31.398l30.017,5.294c-13.982,7.515-25.414,18.559-34.52,33.848L232.134,127.375 L232.134,127.375z M204.106,319.724c-3.688-10.18-6.959-19.946-9.663-29.619l31.722,5.592l-7.441,26.606L204.106,319.724 L204.106,319.724z M255.688,190.15l-27.978-48.456l41.259,7.271c-3.206,7.257-6.034,15.259-8.495,24.057L255.688,190.15 L255.688,190.15z"
    />
    {/*Pattern Shadow 1*/}
    <path 
        fill="rgb(128,130,133)" 
        d="M206.393,172.854l16.119,2.843l1.546,2.68	c-2.873,24.38-2.456,55.34,12.476,100.501l-38.64-6.814c-3.211-12.277-5.274-24.792-5.403-37.73 C193.032,211.2,197.963,190.667,206.393,172.854L206.393,172.854z M203.967,291.785l39.773,7.008
		c3.702,9.488,8.002,19.544,12.973,30.21l-41.189-7.267C211.487,311.806,207.486,301.854,203.967,291.785L203.967,291.785z"
    />
    {/*Pattern Roof Shadow*/}
    <path 
        fill="rgb(88,89,91)" 
        d="M206.393,172.854l16.119,2.843l1.546,2.68	c-2.664,22.63-2.5,50.921,9.474,90.973l-2.396,8.579l-33.241-5.865c-3.211-12.277-5.274-24.792-5.403-37.73	C193.032,211.2,197.963,190.667,206.393,172.854L206.393,172.854z M203.967,291.785l22.198,3.911l-7.441,26.606l-3.2-0.566 C211.487,311.806,207.486,301.854,203.967,291.785L203.967,291.785z"
    />
    {/*Highlight Base*/}
    <path 
        fill="rgb(209,211,212)" 
        d="M382.374,113.925l68.163,12.019 c15.041,12.516,27.308,28.053,35.797,46.254l-70.42-12.421C409.045,145.069,398.498,128.423,382.374,113.925L382.374,113.925z"
    />
    {/*Hightlight*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M420.303,120.615l30.234,5.328 c15.041,12.516,27.308,28.053,35.797,46.254l-29.235-5.159C450.21,151.948,438.946,135.104,420.303,120.615L420.303,120.615z M379.232,302.427c13.927,24.121-1.461,37.979-22.089,41.408l52.627,8.43c16.65,2.307,22.585-3.877,14.438-19.311l-98.274-170.221 l-29.98-4.553L379.232,302.427L379.232,302.427z"
    />
    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M352.859,173.311c6.859-6.71,14.255-9.394,23.356-9.553 c7.043,0.442,12.689,2.276,18.932,5.82l24.777,15.185c7.689,4.448,16.89,7.257,25.797,7.471h53.247 c11.924,0.869,17.482,5.949,19.932,17.471l16.164,87.21c1.252,7.61-0.268,13.843-9.125,14.594h-19.743	c-6.362-0.08-12.596-0.93-18.854-1.998l-77.563-13.674c-21.8-14.643-36.775-34.216-49.196-57.085
		c-3.788-7.167-7.282-14.464-10.637-21.84C343.326,202.318,341.756,186.273,352.859,173.311L352.859,173.311z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(2,63,136)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.679,212.269c-14.255-31.616,12.992-52.254,37.298-37.327 l24.763,15.174c8.519,5.22,21.06,8.346,28.907,8.346h53.094c10.597,0,12.813,5.815,14.047,12.456l16.139,87.071 c0.924,4.985,0.637,7.286-3.246,7.286l-26.373,0.02l-87.146-15.368C381.081,269.881,364.426,233.87,354.679,212.269 L354.679,212.269z M380.426,177.307c-11.537,0-20.945,9.409-20.945,20.945s9.408,20.945,20.945,20.945 c11.541,0,20.944-9.409,20.944-20.945S391.967,177.307,380.426,177.307L380.426,177.307z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(0,102,179)" 
        d="M498.74,198.461h-53.094h-0.517l18.639,100.57l35.538,6.263 l22.486-0.015l-19.757-106.6C501.037,198.535,499.943,198.461,498.74,198.461L498.74,198.461z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(0,174,239)" 
        points="466.93,198.461 495.44,198.461 515.242,305.274 499.208,305.274 486.305,303.003 466.93,198.461 	"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M380.426,179.753c10.188,0,18.504,8.31,18.504,18.499 s-8.315,18.5-18.504,18.5c-10.189,0-18.5-8.311-18.5-18.5S370.236,179.753,380.426,179.753L380.426,179.753z M380.426,186.582 c-6.427,0-11.671,5.243-11.671,11.67c0,6.432,5.244,11.676,11.671,11.676c6.432,0,11.675-5.244,11.675-11.676 C392.101,191.825,386.857,186.582,380.426,186.582L380.426,186.582z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M423.31,206.692c0-3.808-3.111-6.919-6.924-6.919 c-3.812,0-6.924,3.111-6.924,6.919c0,1.595,0.563,3.047,1.472,4.22c-2.913,7.187-8.177,13.221-15.07,16.536 c-7.704,3.459-3.568,10.885,2.396,8.042c9.414-4.533,16.8-12.56,20.612-22.352C421.461,212.135,423.31,209.63,423.31,206.692z"
    />
    {/*Blue Visor Stripe Background*/}
    <path 
        fill="rgb(241,90,34)" 
        d="M428.653,200.708l-7.58,23.058h94.101l-2.387-12.849 c-0.76-4.115-1.909-7.913-5.189-10.209H428.653L428.653,200.708z"
    />
    {/*Blue Visor Stripe Highlight*/}
    <polygon 
        fill="rgb(245,130,32)" 
        points="445.547,200.708 449.822,223.766 506.683,223.766 502.413,200.708 445.547,200.708 	"
    />
    {/*Blue Visor Stripe Highlight1*/}
    <polygon 
        fill="rgb(253,185,19)" 
        points="467.348,200.708 495.857,200.708 500.132,223.766 471.646,223.766 467.348,200.708 	"
    />
    
    
    

    

		</svg>
		</div>

	);//return
}