export const GridArray = [



    //----------------
    //red
    //----------------
    {
        subCategory:false,  
        name:'New Formula Red', 
        thumbComponent:'BUTTON_PILL_RED_NEW_FORMULA_RED',
        layerComponent:'BODY_SOLID_RED_NEW_FORMULA_RED',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    
    },

    //----------------
    //yellow
    //----------------
    {
        subCategory:false,  
        name:'Rio Yellow', 
        thumbComponent:'BUTTON_ELEMENT_CR_RIO_YELLOW',
        layerComponent:'BODY_SOLID_YELLOW_CR_RIO_YELLOW',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_CR_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_YELLOW_RIO_YELLOW'

        }
    },

    {
        subCategory:false,  
        name:'Spa Yellow', 
        thumbComponent:'BUTTON_PILL_YELLOW_SPA_YELLOW',
        layerComponent:'BODY_SOLID_YELLOW_SPA_YELLOW',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },


    //----------------
    //blue
    //----------------

    {
        subCategory:false,  
        name:'Royal Navy', 
        thumbComponent:'BUTTON_PILL_BLUE_ROYAL_NAVY',
        layerComponent:'BODY_SOLID_BLUE_ROYAL_NAVY',
        matchingComponents:{

            hardtop:'',
            softtop:'',
            rollbar:'ROLLBAR_YELLOW_RIO_YELLOW',
            tires:'TIRES_DUNLOP',         
            wheels:'WHEELS_OEM_AP2V3_GRAY_SILVER',
            caliper:'CALIPER_4P_FRONT_YELLOW_YELLOW',
            banner:'BANNER_YELLOW_YELLOW',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_SOLID_YELLOW_RIO_YELLOW_1'

        }
    },

    {
        subCategory:false,  
        name:'Monte Carlo Blue', 
        thumbComponent:'BUTTON_PILL_BLUE_MONTE_CARLO_BLUE',
        layerComponent:'BODY_SOLID_BLUE_MONTE_CARLO_BLUE',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },

    {
        subCategory:false,  
        name:'Laguna Blue', 
        thumbComponent:'BUTTON_PILL_BLUE_LAGUNA_BLUE',
        layerComponent:'BODY_SOLID_BLUE_LAGUNA_BLUE',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP2V3_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },

    {
        subCategory:false,  
        name:'Apex Blue', 
        thumbComponent:'BUTTON_ELEMENT_CR_APEX_BLUE',
        layerComponent:'BODY_SOLID_BLUE_CR_APEX_BLUE',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_CR_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_BLUE_APEX_BLUE'

        }
    },


    {
        subCategory:false,  
        name:'Suzuka Blue', 
        thumbComponent:'BUTTON_PILL_BLUE_SUZUKA_BLUE',
        layerComponent:'BODY_SOLID_BLUE_SUZUKA_BLUE',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },







    //----------------
    //green
    //----------------
    {
        subCategory:false,  
        name:'Tahitian Green', 
        thumbComponent:'BUTTON_PILL_GREEN_TAHITIAN_GREEN',
        layerComponent:'BODY_SOLID_GREEN_TAHITIAN_GREEN',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },
    {
        subCategory:false,  
        name:'Lime Green', 
        thumbComponent:'BUTTON_PILL_GREEN_LIME_GREEN',
        layerComponent:'BODY_SOLID_GREEN_LIME_GREEN',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP2V1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },





    //----------------
    //gray
    //----------------
    {
        subCategory:false,  
        name:'Grand Prix White', 
        thumbComponent:'BUTTON_ELEMENT_CR_GRAND_PRIX_WHITE',
        layerComponent:'BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_CR_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE'

        }
    },

    // {
    //     subCategory:false,  
    //     name:'Black', 
    //     thumbComponent:'BUTTON_PILL_GRAY_BLACK',
    //     layerComponent:'BODY_SOLID_GRAY_BLACK'
    // },

    {
        subCategory:false,  
        name:'Berlina Black CR', 
        thumbComponent:'BUTTON_ELEMENT_CR_BERLINA_BLACK',
        layerComponent:'BODY_SOLID_GRAY_CR_BERLINA_BLACK',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_CR_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_GRAY_BERLINA_BLACK'

        }
    },

    {
        subCategory:false,  
        name:'Berlina Black', 
        thumbComponent:'BUTTON_PILL_GRAY_BERLINA_BLACK',
        layerComponent:'BODY_SOLID_GRAY_BERLINA_BLACK',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },


    // {
    //     subCategory:false,  
    //     name:'Gold', 
    //     thumbComponent:'BUTTON_PILL_GRAY_GOLD',
    //     layerComponent:'BODY_SOLID_GRAY_GOLD'
    // },
    // {
    //     subCategory:false,  
    //     name:'Gunmetal', 
    //     thumbComponent:'BUTTON_PILL_GRAY_GUNMETAL',
    //     layerComponent:'BODY_SOLID_GRAY_GUNMETAL'
    // },
    // {
    //     subCategory:false,  
    //     name:'Silver', 
    //     thumbComponent:'BUTTON_PILL_GRAY_SILVER',
    //     layerComponent:'BODY_SOLID_GRAY_SILVER'
    // },

    {
        subCategory:false,  
        name:'Silverstone', 
        thumbComponent:'BUTTON_PILL_GRAY_SILVERSTONE',
        layerComponent:'BODY_SOLID_GRAY_SILVERSTONE',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP2V1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },


    {
        subCategory:false,  
        name:'Moonrock', 
        thumbComponent:'BUTTON_PILL_GRAY_MOONROCK',
        layerComponent:'BODY_SOLID_GRAY_MOONROCK',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_MOONROCK',
            softtop:'',
            rollbar:'',
            tires:'TIRES_BRIDGESTONE',         
            wheels:'WHEELS_BBS_GRAY_GOLD',
            caliper:'CALIPER_4P_FRONT_ORANGE_ORANGE',
            banner:'BANNER_ORANGE_ORANGE',
            helmet:'HELMET_YELLOW_SOLID_RIO_YELLOW',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1'

        }
    },


    {
        subCategory:false,  
        name:'Sunset Mauve', 
        thumbComponent:'BUTTON_PILL_GRAY_SUNSET_MAUVE',
        layerComponent:'BODY_SOLID_GRAY_SUNSET_MAUVE',
        matchingComponents:{

            hardtop:'',
            softtop:'SOFTTOP_GRAY_BLACK',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP2V3_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    },









    //##########################
    //THEMES
    //##########################

    {
        subCategory:false,  
        name:'Gulf', 
        thumbComponent:'BUTTON_LOGO_GULF',
        layerComponent:'BODY_THEME_BLUE_GULF',
        matchingComponents:{

            //hardtop:'HARDTOP_OEM_SOLID_BLUE_GULF_BLUE',
            hardtop:'',
            softtop:'',
            rollbar:'ROLLBAR_ORANGE_IMOLA_ORANGE',
            tires:'TIRES_GOODYEAR',         
            wheels:'WHEELS_RA300_SILVER',
            caliper:'CALIPER_4P_FRONT_ORANGE_ORANGE',
            banner:'BANNER_GRAY_SILVER',
            helmet:'HELMET_GRAY_DRIVER_STEVE_MCQUEEN',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_CARBON_FIBER'


        }
    
    },
    {
        subCategory:false,  
        name:'Red Bull', 
        thumbComponent:'BUTTON_LOGO_REDBULL',
        layerComponent:'BODY_THEME_BLUE_REDBULL',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY',
            softtop:'',
            rollbar:'',
            tires:'TIRES_PIRELLI_MEDIUM_YELLOW',         
            wheels:'WHEELS_SW388_BLUE_BLACK_BLUE',
            caliper:'CALIPER_4P_FRONT_RED_RED',
            banner:'BANNER_YELLOW_YELLOW',
            helmet:'HELMET_RED_SOLID_NEW_FORMULA_RED',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_SOLID_YELLOW_SPA_YELLOW_1'


        }
    
    },
    {
        subCategory:false,  
        name:'Marlboro', 
        thumbComponent:'BUTTON_LOGO_MARLBORO',
        layerComponent:'BODY_THEME_RED_MARLBORO',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL',
            softtop:'',
            rollbar:'',
            tires:'TIRES_GOODYEAR_XL',         
            wheels:'WHEELS_SW388_RED_BLACK_RED',
            caliper:'CALIPER_4P_FRONT_RED_RED',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_YELLOW_DRIVER_AYRTON_SENNA',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_THEME_MARLBORO_12'

                        
        }
    },
    {
        subCategory:false,  
        name:'Canon', 
        thumbComponent:'BUTTON_LOGO_CANON',
        layerComponent:'BODY_THEME_YELLOW_CANON',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW',
            softtop:'',
            rollbar:'',
            tires:'TIRES_GOODYEAR',         
            wheels:'WHEELS_FONDMETAL',
            caliper:'CALIPER_4P_FRONT_RED_RED',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_GRAY_DRIVER_NIGEL_MANSELL',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_THEME_CANON_5'


        }
    },
    {
        subCategory:false,  
        name:'Camel', 
        thumbComponent:'BUTTON_LOGO_CAMEL',
        layerComponent:'BODY_THEME_YELLOW_CAMEL',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW',
            softtop:'',
            rollbar:'',
            tires:'TIRES_GOODYEAR_XL',         
            wheels:'WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW',
            caliper:'CALIPER_4P_FRONT_GRAY_SILVER',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_YELLOW_SOLID_SPA_YELLOW',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_THEME_CAMEL_12'


        }
    },
    {
        subCategory:false,  
        name:'Spoon', 
        thumbComponent:'BUTTON_LOGO_95',
        layerComponent:'BODY_THEME_YELLOW_SPOON',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW',
            softtop:'',
            rollbar:'',
            tires:'TIRES_ADVAN',         
            wheels:'WHEELS_SW388_BLUE_BLACK_BLUE',
            caliper:'CALIPER_4P_FRONT_BLUE_BLUE',
            banner:'BANNER_BLUE_BLUE',
            helmet:'HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_CARBON_FIBER'


        }
    },
    {
        subCategory:false,  
        name:'Lucky Strike', 
        thumbComponent:'BUTTON_LOGO_LUCKY_STRIKE',
        layerComponent:'BODY_THEME_RED_LUCKY_STRIKE',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_THEME_RED_LUCKY_STRIKE',
            softtop:'',
            rollbar:'',
            tires:'TIRES_MICHELIN_FULL_COLORS',         
            wheels:'WHEELS_BBS_GRAY_SILVER',
            caliper:'CALIPER_4P_FRONT_GRAY_SILVER',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_THEME_LUCKY_STRIKE_1'


        }
    },
    {
        subCategory:false,  
        name:'Rothmans', 
        thumbComponent:'BUTTON_LOGO_ROTHMANS',
        layerComponent:'BODY_THEME_BLUE_ROTHMANS',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE',
            softtop:'',
            rollbar:'',
            tires:'TIRES_MICHELIN_FULL_COLOR',         
            wheels:'WHEELS_RA300_GOLD',
            caliper:'CALIPER_4P_FRONT_RED_RED',
            banner:'BANNER_YELLOW_YELLOW',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_THEME_ROTHMANS_1'


        }
    },
    {
        subCategory:false,  
        name:'Martini', 
        thumbComponent:'BUTTON_LOGO_MARTINI',
        layerComponent:'BODY_THEME_GRAY_MARTINI',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_THEME_GRAY_MARTINI',
            softtop:'',
            rollbar:'',
            tires:'TIRES_PIRELLI_SOFT_RED',         
            wheels:'WHEELS_OZ_RACING_GRAY_BLACK_BLACK',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_SOLID_GRAY_BERLINA_BLACK_1'


        }
    },
    {
        subCategory:false,  
        name:'Benson & Hedges', 
        thumbComponent:'BUTTON_LOGO_BENSON_AND_HEDGES',
        layerComponent:'BODY_THEME_YELLOW_BENSON_AND_HEDGES',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES',
            softtop:'',
            rollbar:'',
            tires:'TIRES_BRIDGESTONE',         
            wheels:'WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW',
            caliper:'CALIPER_4P_FRONT_RED_RED',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_SOLID_CARBON_FIBER'


        }
    },


    {
        subCategory:false,  
        name:'Honda RA300', 
        thumbComponent:'BUTTON_LOGO_HONDA_RA300',
        layerComponent:'BODY_THEME_GRAY_RA300',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL',
            softtop:'',
            rollbar:'',
            tires:'TIRES_FIRESTONE',         
            wheels:'WHEELS_RA300_GOLD',
            caliper:'CALIPER_4P_FRONT_GRAY_SILVER',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING_RACING_SOLID_CARBON_FIBER'


        }
    
    },





    //----------------
    //S2-R
    //----------------
    
    {
        subCategory:false,  
        name:'S2-R Berlina Black', 
        thumbComponent:'BUTTON_THEME_GRAY_S2R_BERLINA_BLACK',
        layerComponent:'BODY_THEME_GRAY_S2R_BERLINA_BLACK',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_TRS9_GRAY_BLACK',
            caliper:'CALIPER_4P_FRONT_RED_RED',
            banner:'BANNER_RED_RED',
            helmet:'HELMET_RED_DRIVER_VAN_HALEN',
            steering_wheel:'STEERING_WHEEL_RED_RED',
            wing:'WING_RACING_SOLID_RED_NEW_FORMULA_RED_1'

        }
    },
    {
        subCategory:false,  
        name:'S2-R Grand Prix White', 
        thumbComponent:'BUTTON_THEME_GRAY_S2R_GRAND_PRIX_WHITE',
        layerComponent:'BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'


        }
    },
    {
        subCategory:false,  
        name:'S2-R Sebring Silver', 
        thumbComponent:'BUTTON_THEME_GRAY_S2R_SEBRING_SILVER',
        layerComponent:'BODY_THEME_GRAY_S2R_SEBRING_SILVER',
        matchingComponents:{

            hardtop:'HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER',
            softtop:'',
            rollbar:'',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'


        }
    },
    {
        subCategory:false,  
        name:'S2-R New Formula Red', 
        thumbComponent:'BUTTON_THEME_RED_S2R_NEW_FORMULA_RED',
        layerComponent:'BODY_THEME_RED_S2R_NEW_FORMULA_RED',
        matchingComponents:{

            hardtop:'',
            softtop:'',
            rollbar:'ROLLBAR_GRAY_BATTLESHIP_GRAY',
            tires:'TIRES',         
            wheels:'WHEELS_OEM_AP1_GRAY_SILVER',
            caliper:'CALIPER_OEM_FRONT_FACTORY',
            banner:'BANNER_GRAY_BLACK',
            helmet:'HELMET_GRAY_SOLID_GRAND_PRIX_WHITE',
            steering_wheel:'STEERING_WHEEL_GRAY_BLACK',
            wing:'WING'

        }
    }






];
