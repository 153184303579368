import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    //BrowserRouter as Router,
    // Switch,
    // Route,
    // Link,
    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';
//import NavGrid from 'utils/myBuilds/NavGrid/Tags';


import { 

    SideNav,
    Bars,
    Tiles,
    Joined

} from './Comps';


const Index = (props) => {




    // -- router -- //
    //let match = useRouteMatch();
    let location = useLocation();
    //let history = useHistory();
    //let params = useParams();


    // console.log("MATCH: "+match.path);
    // console.log("LOC: "+JSON.stringify(location,null,2));
    // //console.log("PARAMS: "+JSON.stringify(params,null,2));



    // -- paths -- //

    let pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    //console.log(pathname);

    //let mainpath;
    //mainpath = pathname.split("/")[0];

    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = '';



    // -- dims -- //
    //const { width, height } = useWindowDimensions();
    //console.log("STAGE WIDTH: "+width+"\nSTAGE HEIGHT: "+height);


    // -- ref -- //
    //const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);





    //===============================================
    // state/effect - main
    //===============================================

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [layout, setLayout] = useState("Bars");

    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);

            if(props.data.info && props.data.info !== undefined)
            {
                if(props.data.info.gridType 
                && props.data.info.gridType !== undefined
                )
                {
                    setLayout(props.data.info.gridType);
                }

            }else{

                setLayout(props.data.layout);
            }

            //console.log(JSON.stringify(props.data,null,2));
            //alert('props.data');

        }// isLoaded

        //console.log("Navigator--props.data "+JSON.stringify(props.data,null,2));
        

    },
    [
        props,
        isLoaded
    ])




    

    // //===============================================
    // // state/effect - currentPage
    // //===============================================

    // // -- state -- //
    // const [currentPage, setCurrentPage] = useState('na');

    // useEffect(() => {

    //     if(isLoaded && !currentPage)
    //     {
    //         var pathArr = pathname.split("/");
    //         console.log("Current Page: "+pathArr[pathArr.length-1]);
    
    //         setCurrentPage(pathArr[pathArr.length-1]);
  
    //     }

    // },
    // [
    //     props,
    //     pathname,
    //     isLoaded,
    //     currentPage
  
    // ])





    // //===============================================
    // // state/effect - data test
    // //===============================================

    // // -- state -- //
    // const [dataTest, setDataTest] = useState(false);

    // useEffect(() => {

    //     if(isLoaded && !dataTest)
    //     {
    //         setDataTest(props);

    //         console.log("====== NAVIGATOR (props) ======");

    //         console.log(pathname);
    //         var pathArr = pathname.split("/");
    //         console.log("Extracted Path 2: "+pathArr[pathArr.length-1]);

    //         console.log(JSON.stringify(props,null,2));




    //         console.log("====== NAVIGATOR (split data) ======");

    //         if(props.data.info && props.data.info !== undefined)
    //         {
    //             if(props.data.info.call
    //             && props.data.info.call === 'switchRoute'
    //             )
    //             {
    //                 console.log("setSwitchRoute --- here");
    //             }
    //             if(props.data.info.gridType 
    //             && props.data.info.gridType !== undefined
    //             )
    //             {
        
    //                 console.log("setLayout --- "+props.data.info.gridType);
    //             }
    
    //         }else{
    

    //             console.log("No Info");

    //             console.log("setSwitchRoute --- "+props.data.withSwitchRoute);
    //             console.log("setLayout --- "+props.data.layout);


    
    //         }
    
    //         if(props.data.items && props.data.items.length > 0)
    //         {
               
    //             console.log("setNavArr --- "+props.data.items);
             
    //         }else{
           
    //             console.log("NO Items");
    //             console.log("setNavArr --- "+props.data.items);
             
    //         }
    

    //     }

    // },
    // [
    //     props,
    //     dataTest,
    //     isLoaded,
    //     pathname
    // ])





    return (

       
  

        <div>
        {
        layout === 'Tiles' 
        ?

            <Tiles 
                
                handle      = {props.handle}
                data        = {props.data}
                refid       = {props.refid}

                viewHeight  = {props.viewHeight}
                databind    = {props.databind}

            />


        :
        layout === 'JoinedGrid'
        ?


            <Joined
                    
                handle      = {props.handle}
                data        = {props.data}
                refid       = {props.refid}

                viewHeight  = {props.viewHeight}
                databind    = {props.databind}

            />


        :
        layout === 'Bars'
        ?

            <Bars 
                
                handle      = {props.handle}
                data        = {props.data}
                refid       = {props.refid}

                viewHeight  = {props.viewHeight}
                databind    = {props.databind}

            />


        :
        layout === 'SideNav'
        ?


            <SideNav 
            
                handle      = {props.handle}
                data        = {props.data}
                refid       = {props.refid}

                viewHeight  = {props.viewHeight}
                databind    = {props.databind}

            />



        :null
        }
        </div>



    )


}

export default Index


