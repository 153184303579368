import React, { 

    // useState, 
    // useEffect, 
    // useRef 

} from 'react';



// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


// import {
//     //BrowserRouter as Router,
//     //Switch,
//     //Route,
//     //Link,

//     //useRouteMatch,
//     useLocation,
//     //useHistory,   
//     //useParams,


// } from "react-router-dom";

// import { useWindowDimensions } from 'utils/WindowDimensionsProvider';



// import {

//     Stage,
//     ScrollBox,
//     Area,
//     //AreaTitle

// } from './Styled';






import { ErrorPage } from "utils/components/Pages";

const Index = (props) => {


    return (

        <ErrorPage 
        
            databind={props.databind}
        
        />

    )


}

export default Index;


