//import styled from "styled-components";
import styled from "styled-components/macro";


const Grid = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fff",//#edeef1
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 50px 0",
    margin:             props.margin  || "0 0 0 0",

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;

`;




const Cell = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightgray",
    width:              props.width || "calc(50% - 30px)",
    height:             props.height || "350px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "15px 0 0 20px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "25px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#000",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "14px",
    buttonFontWeight:         props.buttonFontWeight || "normal",
    buttonTextAlign:          props.buttonTextAlign || "left",
    buttonTextDecoration:     props.buttonTextDecoration || "none",
    buttonColor:              props.buttonColor || "#000",

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;



    .Top {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin: 0 0 0;
        position:relative;   
        float:left;

    }

        .Top .Name {

            background-color:transparent;
            width:calc(100% - 0px);
            height:auto;
            padding:15px 0 5px 0;
            margin:0 0 0 0;
            position:relative;   
            float:left;
        
            font-family:${props => props.fontFamily};     
            font-size: ${props => props.fontSize};
            font-weight: ${props => props.fontWeight};
            text-align: ${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

        }

        .Top .Ctrls {

            background-color:transparent;
            width:auto;
            height:auto;
            padding:10px 10px 0 0;
            margin:0 0 0 0;
            position:absolute;   
            z-index:2;
            top:0px;
            right:0px;
        

        }

            .Top .Ctrls .Btn {

                background-color:transparent;
                border-radius:7px;

                width:auto;
                height:auto;
                padding:7px 7px 5px 7px;
                margin:0 0 0 0;
                position:relative;   
                float:left;
            
                font-family:${props => props.buttonFontFamily};     
                font-size: ${props => props.buttonFontSize};
                font-weight: ${props => props.buttonFontWeight};
                text-align: ${props => props.buttonTextAlign};
                text-decoration:${props => props.buttonTextDecoration};
                color:${props => props.buttonColor};


                cursor:pointer;

                &:hover{

                    background-color:#0291cd;
                    color:#fff;

                }


            }




`;





const Layer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "100%",
    height:             props.height || "100%",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    top:                props.top || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex  || "1",


    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:absolute;   
    top:${props => props.top}; 
    left:${props => props.left}; 
    z-index:${props => props.zIndex}; 

    overflow:hidden;

    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;



const Slide = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "100%",
    height:             props.height || "100%",


    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",



    fontFamily:         props.fontFamily || '"Verdana", sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight || "normal",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration || "none",
    color:              props.color || "#fff",

  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};     
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};



`;







export {

    Grid,
    Cell,
    Layer,
    Slide
}
  


