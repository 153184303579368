const List = {

	BANNER_BLUE_BLUE:"BANNER_BLUE_BLUE",
	BANNER_BLUE_DARK_BLUE:"BANNER_BLUE_DARK_BLUE",
	BANNER_GRAY_BLACK:"BANNER_GRAY_BLACK",
	BANNER_GRAY_SILVER:"BANNER_GRAY_SILVER",
	BANNER_GREEN_LIME_GREEN:"BANNER_GREEN_LIME_GREEN",
	BANNER_ORANGE_ORANGE:"BANNER_ORANGE_ORANGE",
	BANNER_PURPLE_PURPLE:"BANNER_PURPLE_PURPLE",
	BANNER_RED_RED:"BANNER_RED_RED",
	BANNER_YELLOW_YELLOW:"BANNER_YELLOW_YELLOW",
	BODY_SOLID_BLUE_APEX_BLUE:"BODY_SOLID_BLUE_APEX_BLUE",
	BODY_SOLID_BLUE_CR_APEX_BLUE:"BODY_SOLID_BLUE_CR_APEX_BLUE",
	BODY_SOLID_BLUE_GULF_BLUE:"BODY_SOLID_BLUE_GULF_BLUE",
	BODY_SOLID_BLUE_LAGUNA_BLUE:"BODY_SOLID_BLUE_LAGUNA_BLUE",
	BODY_SOLID_BLUE_MONTE_CARLO_BLUE:"BODY_SOLID_BLUE_MONTE_CARLO_BLUE",
	BODY_SOLID_BLUE_ROYAL_NAVY:"BODY_SOLID_BLUE_ROYAL_NAVY",
	BODY_SOLID_BLUE_SUZUKA_BLUE:"BODY_SOLID_BLUE_SUZUKA_BLUE",
	BODY_SOLID_GRAY_BERLINA_BLACK:"BODY_SOLID_GRAY_BERLINA_BLACK",
	BODY_SOLID_GRAY_CR_BERLINA_BLACK:"BODY_SOLID_GRAY_CR_BERLINA_BLACK",
	BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL:"BODY_SOLID_GRAY_CR_GRAND_PRIX_WHITE_HL",
	BODY_SOLID_GRAY_GRAND_PRIX_WHITE:"BODY_SOLID_GRAY_GRAND_PRIX_WHITE",
	BODY_SOLID_GRAY_GRAND_PRIX_WHITE_HL:"BODY_SOLID_GRAY_GRAND_PRIX_WHITE_HL",
	BODY_SOLID_GRAY_MOONROCK:"BODY_SOLID_GRAY_MOONROCK",
	BODY_SOLID_GRAY_SEBRING_SILVER:"BODY_SOLID_GRAY_SEBRING_SILVER",
	BODY_SOLID_GRAY_SILVERSTONE:"BODY_SOLID_GRAY_SILVERSTONE",
	BODY_SOLID_GRAY_SUNSET_MAUVE:"BODY_SOLID_GRAY_SUNSET_MAUVE",
	BODY_SOLID_GREEN_LIME_GREEN:"BODY_SOLID_GREEN_LIME_GREEN",
	BODY_SOLID_GREEN_TAHITIAN_GREEN:"BODY_SOLID_GREEN_TAHITIAN_GREEN",
	BODY_SOLID_ORANGE_IMOLA_ORANGE:"BODY_SOLID_ORANGE_IMOLA_ORANGE",
	BODY_SOLID_RED_NEW_FORMULA_RED:"BODY_SOLID_RED_NEW_FORMULA_RED",
	BODY_SOLID_YELLOW_CR_RIO_YELLOW:"BODY_SOLID_YELLOW_CR_RIO_YELLOW",
	BODY_SOLID_YELLOW_RIO_YELLOW:"BODY_SOLID_YELLOW_RIO_YELLOW",
	BODY_SOLID_YELLOW_SPA_YELLOW:"BODY_SOLID_YELLOW_SPA_YELLOW",
	BODY_THEME_BLUE_GULF:"BODY_THEME_BLUE_GULF",
	BODY_THEME_BLUE_REDBULL:"BODY_THEME_BLUE_REDBULL",
	BODY_THEME_BLUE_REDBULL_XL:"BODY_THEME_BLUE_REDBULL_XL",
	BODY_THEME_BLUE_ROTHMANS:"BODY_THEME_BLUE_ROTHMANS",
	BODY_THEME_BLUE_S2R_BLUE_AND_RED:"BODY_THEME_BLUE_S2R_BLUE_AND_RED",
	BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW:"BODY_THEME_BLUE_S2R_BLUE_AND_YELLOW",
	BODY_THEME_GRAY_MARTINI:"BODY_THEME_GRAY_MARTINI",
	BODY_THEME_GRAY_RA300:"BODY_THEME_GRAY_RA300",
	BODY_THEME_GRAY_S2R_BERLINA_BLACK:"BODY_THEME_GRAY_S2R_BERLINA_BLACK",
	BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE:"BODY_THEME_GRAY_S2R_GRAND_PRIX_WHITE",
	BODY_THEME_GRAY_S2R_SEBRING_SILVER:"BODY_THEME_GRAY_S2R_SEBRING_SILVER",
	BODY_THEME_RED_LUCKY_STRIKE:"BODY_THEME_RED_LUCKY_STRIKE",
	BODY_THEME_RED_MARLBORO:"BODY_THEME_RED_MARLBORO",
	BODY_THEME_RED_S2R_NEW_FORMULA_RED:"BODY_THEME_RED_S2R_NEW_FORMULA_RED",
	BODY_THEME_YELLOW_BENSON_AND_HEDGES:"BODY_THEME_YELLOW_BENSON_AND_HEDGES",
	BODY_THEME_YELLOW_CAMEL:"BODY_THEME_YELLOW_CAMEL",
	BODY_THEME_YELLOW_CANON:"BODY_THEME_YELLOW_CANON",
	BODY_THEME_YELLOW_SPOON:"BODY_THEME_YELLOW_SPOON",
	CALIPER_4P_FRONT_BLUE_BLUE:"CALIPER_4P_FRONT_BLUE_BLUE",
	CALIPER_4P_FRONT_BLUE_DARK_BLUE:"CALIPER_4P_FRONT_BLUE_DARK_BLUE",
	CALIPER_4P_FRONT_GRAY_GOLD:"CALIPER_4P_FRONT_GRAY_GOLD",
	CALIPER_4P_FRONT_GRAY_SILVER:"CALIPER_4P_FRONT_GRAY_SILVER",
	CALIPER_4P_FRONT_GREEN_LIME_GREEN:"CALIPER_4P_FRONT_GREEN_LIME_GREEN",
	CALIPER_4P_FRONT_ORANGE_ORANGE:"CALIPER_4P_FRONT_ORANGE_ORANGE",
	CALIPER_4P_FRONT_PURPLE_PURPLE:"CALIPER_4P_FRONT_PURPLE_PURPLE",
	CALIPER_4P_FRONT_RED_RED:"CALIPER_4P_FRONT_RED_RED",
	CALIPER_4P_FRONT_YELLOW_YELLOW:"CALIPER_4P_FRONT_YELLOW_YELLOW",
	CALIPER_OEM_FRONT_FACTORY:"CALIPER_OEM_FRONT_FACTORY",
	GLOVE_GRAY_WHITE:"GLOVE_GRAY_WHITE",
	HARDTOP_OEM_SOLID_BLUE_APEX_BLUE:"HARDTOP_OEM_SOLID_BLUE_APEX_BLUE",
	HARDTOP_OEM_SOLID_BLUE_GULF_BLUE:"HARDTOP_OEM_SOLID_BLUE_GULF_BLUE",
	HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE:"HARDTOP_OEM_SOLID_BLUE_LAGUNA_BLUE",
	HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE:"HARDTOP_OEM_SOLID_BLUE_MONTE_CARLO_BLUE",
	HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY:"HARDTOP_OEM_SOLID_BLUE_ROYAL_NAVY",
	HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE:"HARDTOP_OEM_SOLID_BLUE_SUZUKA_BLUE",
	HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK:"HARDTOP_OEM_SOLID_GRAY_BERLINA_BLACK",
	HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE:"HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE",
	HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL:"HARDTOP_OEM_SOLID_GRAY_GRAND_PRIX_WHITE_HL",
	HARDTOP_OEM_SOLID_GRAY_MOONROCK:"HARDTOP_OEM_SOLID_GRAY_MOONROCK",
	HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER:"HARDTOP_OEM_SOLID_GRAY_SEBRING_SILVER",
	HARDTOP_OEM_SOLID_GRAY_SILVERSTONE:"HARDTOP_OEM_SOLID_GRAY_SILVERSTONE",
	HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE:"HARDTOP_OEM_SOLID_GRAY_SUNSET_MAUVE",
	HARDTOP_OEM_SOLID_GREEN_LIME_GREEN:"HARDTOP_OEM_SOLID_GREEN_LIME_GREEN",
	HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN:"HARDTOP_OEM_SOLID_GREEN_TAHITIAN_GREEN",
	HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE:"HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE",
	HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED:"HARDTOP_OEM_SOLID_RED_NEW_FORMULA_RED",
	HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW:"HARDTOP_OEM_SOLID_YELLOW_RIO_YELLOW",
	HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW:"HARDTOP_OEM_SOLID_YELLOW_SPA_YELLOW",
	HARDTOP_OEM_THEME_GRAY_MARTINI:"HARDTOP_OEM_THEME_GRAY_MARTINI",
	HARDTOP_OEM_THEME_RED_LUCKY_STRIKE:"HARDTOP_OEM_THEME_RED_LUCKY_STRIKE",
	HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES:"HARDTOP_OEM_THEME_YELLOW_BENSON_AND_HEDGES",
	HARDTOP_OEM_THEME_YELLOW_CAMEL:"HARDTOP_OEM_THEME_YELLOW_CAMEL",
	HELMET_BLACK_DRIVER_JAMES_HUNT:"HELMET_BLACK_DRIVER_JAMES_HUNT",
	HELMET_BLACK_SOLID_BERLINA_BLACK:"HELMET_BLACK_SOLID_BERLINA_BLACK",
	HELMET_BLUE_DRIVER_REDBULL:"HELMET_BLUE_DRIVER_REDBULL",
	HELMET_BLUE_SOLID_APEX_BLUE:"HELMET_BLUE_SOLID_APEX_BLUE",
	HELMET_BLUE_SOLID_LAGUNA_BLUE:"HELMET_BLUE_SOLID_LAGUNA_BLUE",
	HELMET_GRAY_DRIVER_NIGEL_MANSELL:"HELMET_GRAY_DRIVER_NIGEL_MANSELL",
	HELMET_GRAY_DRIVER_STEVE_MCQUEEN:"HELMET_GRAY_DRIVER_STEVE_MCQUEEN",
	HELMET_GRAY_DRIVER_THE_STIG:"HELMET_GRAY_DRIVER_THE_STIG",
	HELMET_GRAY_SOLID_GRAND_PRIX_WHITE:"HELMET_GRAY_SOLID_GRAND_PRIX_WHITE",
	HELMET_GRAY_SOLID_SUNSET_MAUVE:"HELMET_GRAY_SOLID_SUNSET_MAUVE",
	HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA:"HELMET_GREEN_DRIVER_KEIICHI_TSUCHIYA",
	HELMET_GREEN_SOLID_LIME_GREEN:"HELMET_GREEN_SOLID_LIME_GREEN",
	HELMET_ORANGE_SOLID_IMOLA_ORANGE:"HELMET_ORANGE_SOLID_IMOLA_ORANGE",
	HELMET_RED_DRIVER_NELSON_PIQUET:"HELMET_RED_DRIVER_NELSON_PIQUET",
	HELMET_RED_DRIVER_VAN_HALEN:"HELMET_RED_DRIVER_VAN_HALEN",
	HELMET_RED_SOLID_NEW_FORMULA_RED:"HELMET_RED_SOLID_NEW_FORMULA_RED",
	HELMET_YELLOW_DRIVER_AYRTON_SENNA:"HELMET_YELLOW_DRIVER_AYRTON_SENNA",
	HELMET_YELLOW_SOLID_RIO_YELLOW:"HELMET_YELLOW_SOLID_RIO_YELLOW",
	HELMET_YELLOW_SOLID_SPA_YELLOW:"HELMET_YELLOW_SOLID_SPA_YELLOW",
	ROLLBAR_BLUE_APEX_BLUE:"ROLLBAR_BLUE_APEX_BLUE",
	ROLLBAR_BLUE_LAGUNA_BLUE:"ROLLBAR_BLUE_LAGUNA_BLUE",
	ROLLBAR_BLUE_MONTE_CARLO_BLUE:"ROLLBAR_BLUE_MONTE_CARLO_BLUE",
	ROLLBAR_BLUE_ROYAL_NAVY:"ROLLBAR_BLUE_ROYAL_NAVY",
	ROLLBAR_BLUE_SUZUKA_BLUE:"ROLLBAR_BLUE_SUZUKA_BLUE",
	ROLLBAR_GRAY_BATTLESHIP_GRAY:"ROLLBAR_GRAY_BATTLESHIP_GRAY",
	ROLLBAR_GRAY_BERLINA_BLACK:"ROLLBAR_GRAY_BERLINA_BLACK",
	ROLLBAR_GRAY_GRAND_PRIX_WHITE:"ROLLBAR_GRAY_GRAND_PRIX_WHITE",
	ROLLBAR_GREEN_LIME_GREEN:"ROLLBAR_GREEN_LIME_GREEN",
	ROLLBAR_ORANGE_IMOLA_ORANGE:"ROLLBAR_ORANGE_IMOLA_ORANGE",
	ROLLBAR_RED_NEW_FORMULA_RED:"ROLLBAR_RED_NEW_FORMULA_RED",
	ROLLBAR_YELLOW_RIO_YELLOW:"ROLLBAR_YELLOW_RIO_YELLOW",
	ROLLBAR_YELLOW_SPA_YELLOW:"ROLLBAR_YELLOW_SPA_YELLOW",
	SOFTTOP_GRAY_BLACK:"SOFTTOP_GRAY_BLACK",
	STATIC_BACKGROUND:"STATIC_BACKGROUND",
	STATIC_BACKGROUND_GLASS:"STATIC_BACKGROUND_GLASS",
	STATIC_BRAKES_REAR:"STATIC_BRAKES_REAR",
	STATIC_GROUND:"STATIC_GROUND",
	STATIC_HEADLIGHT_TAILLIGHT:"STATIC_HEADLIGHT_TAILLIGHT",
	STATIC_MUFFLER:"STATIC_MUFFLER",
	STATIC_TIRES:"STATIC_TIRES",
	STEERING_WHEEL_GRAY_BLACK:"STEERING_WHEEL_GRAY_BLACK",
	STEERING_WHEEL_RED_RED:"STEERING_WHEEL_RED_RED",
	TIRES_ADVAN:"TIRES_ADVAN",
	TIRES_BRIDGESTONE:"TIRES_BRIDGESTONE",
	TIRES_DUNLOP:"TIRES_DUNLOP",
	TIRES_DUNLOP_REVERSED:"TIRES_DUNLOP_REVERSED",
	TIRES_FIRESTONE:"TIRES_FIRESTONE",
	TIRES_GOODYEAR:"TIRES_GOODYEAR",
	TIRES_GOODYEAR_XL:"TIRES_GOODYEAR_XL",
	TIRES_MICHELIN_FULL_COLOR:"TIRES_MICHELIN_FULL_COLOR",
	TIRES_PIRELLI_HARD_BLUE:"TIRES_PIRELLI_HARD_BLUE",
	TIRES_PIRELLI_HARD_ORANGE:"TIRES_PIRELLI_HARD_ORANGE",
	TIRES_PIRELLI_INTER_GREEN:"TIRES_PIRELLI_INTER_GREEN",
	TIRES_PIRELLI_MEDIUM_WHITE:"TIRES_PIRELLI_MEDIUM_WHITE",
	TIRES_PIRELLI_MEDIUM_YELLOW:"TIRES_PIRELLI_MEDIUM_YELLOW",
	TIRES_PIRELLI_SOFT_MAGENTA:"TIRES_PIRELLI_SOFT_MAGENTA",
	TIRES_PIRELLI_SOFT_PINK:"TIRES_PIRELLI_SOFT_PINK",
	TIRES_PIRELLI_SOFT_RED:"TIRES_PIRELLI_SOFT_RED",
	TIRES_PIRELLI_WET_BLUE:"TIRES_PIRELLI_WET_BLUE",
	WHEELS_BBS_GRAY_BLACK:"WHEELS_BBS_GRAY_BLACK",
	WHEELS_BBS_GRAY_GOLD:"WHEELS_BBS_GRAY_GOLD",
	WHEELS_BBS_GRAY_GUNMETAL:"WHEELS_BBS_GRAY_GUNMETAL",
	WHEELS_BBS_GRAY_SILVER:"WHEELS_BBS_GRAY_SILVER",
	WHEELS_FONDMETAL:"WHEELS_FONDMETAL",
	WHEELS_OEM_AP1_GRAY_SILVER:"WHEELS_OEM_AP1_GRAY_SILVER",
	WHEELS_OEM_AP2V1_GRAY_SILVER:"WHEELS_OEM_AP2V1_GRAY_SILVER",
	WHEELS_OEM_AP2V3_GRAY_SILVER:"WHEELS_OEM_AP2V3_GRAY_SILVER",
	WHEELS_OEM_CR_GRAY_SILVER:"WHEELS_OEM_CR_GRAY_SILVER",
	WHEELS_OZ_RACING_BLUE_BLACK_BLUE:"WHEELS_OZ_RACING_BLUE_BLACK_BLUE",
	WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE:"WHEELS_OZ_RACING_BLUE_BLACK_DARK_BLUE",
	WHEELS_OZ_RACING_GRAY_BLACK_BLACK:"WHEELS_OZ_RACING_GRAY_BLACK_BLACK",
	WHEELS_OZ_RACING_GRAY_BLACK_WHITE:"WHEELS_OZ_RACING_GRAY_BLACK_WHITE",
	WHEELS_OZ_RACING_GREEN_BLACK_GREEN:"WHEELS_OZ_RACING_GREEN_BLACK_GREEN",
	WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE:"WHEELS_OZ_RACING_ORANGE_BLACK_ORANGE",
	WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE:"WHEELS_OZ_RACING_PURPLE_BLACK_PURPLE",
	WHEELS_OZ_RACING_RED_BLACK_RED:"WHEELS_OZ_RACING_RED_BLACK_RED",
	WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW:"WHEELS_OZ_RACING_YELLOW_BLACK_YELLOW",
	WHEELS_RA300:"WHEELS_RA300",
	WHEELS_RA300_GOLD:"WHEELS_RA300_GOLD",
	WHEELS_RA300_SILVER:"WHEELS_RA300_SILVER",
	WHEELS_SW388_BLUE_BLACK_BLUE:"WHEELS_SW388_BLUE_BLACK_BLUE",
	WHEELS_SW388_GRAY_BLACK_GRAY:"WHEELS_SW388_GRAY_BLACK_GRAY",
	WHEELS_SW388_GREEN_BLACK_GREEN:"WHEELS_SW388_GREEN_BLACK_GREEN",
	WHEELS_SW388_ORANGE_BLACK_ORANGE:"WHEELS_SW388_ORANGE_BLACK_ORANGE",
	WHEELS_SW388_PURPLE_BLACK_PURPLE:"WHEELS_SW388_PURPLE_BLACK_PURPLE",
	WHEELS_SW388_RED_BLACK_RED:"WHEELS_SW388_RED_BLACK_RED",
	WHEELS_SW388_YELLOW_BLACK_YELLOW:"WHEELS_SW388_YELLOW_BLACK_YELLOW",
	WHEELS_TRS9_GRAY_BLACK:"WHEELS_TRS9_GRAY_BLACK",
	WING_RACING_SOLID_BLUE_APEX_BLUE:"WING_RACING_SOLID_BLUE_APEX_BLUE",
	WING_RACING_SOLID_BLUE_APEX_BLUE_1:"WING_RACING_SOLID_BLUE_APEX_BLUE_1",
	WING_RACING_SOLID_BLUE_GULF_BLUE_1:"WING_RACING_SOLID_BLUE_GULF_BLUE_1",
	WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1:"WING_RACING_SOLID_BLUE_LAGUNA_BLUE_1",
	WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1:"WING_RACING_SOLID_BLUE_MONTE_CARLO_BLUE_1",
	WING_RACING_SOLID_BLUE_ROYAL_NAVY_BLUE_1:"WING_RACING_SOLID_BLUE_ROYAL_NAVY_BLUE_1",
	WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1:"WING_RACING_SOLID_BLUE_SUZUKA_BLUE_1",
	WING_RACING_SOLID_CARBON_FIBER:"WING_RACING_SOLID_CARBON_FIBER",
	WING_RACING_SOLID_CARBON_FIBER_WHITE_1:"WING_RACING_SOLID_CARBON_FIBER_WHITE_1",
	WING_RACING_SOLID_GRAY_BERLINA_BLACK:"WING_RACING_SOLID_GRAY_BERLINA_BLACK",
	WING_RACING_SOLID_GRAY_BERLINA_BLACK_1:"WING_RACING_SOLID_GRAY_BERLINA_BLACK_1",
	WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE:"WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE",
	WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1:"WING_RACING_SOLID_GRAY_GRAND_PRIX_WHITE_1",
	WING_RACING_SOLID_GRAY_MAUVE_SUNSET_PEARL_1:"WING_RACING_SOLID_GRAY_MAUVE_SUNSET_PEARL_1",
	WING_RACING_SOLID_GRAY_MOONROCK_1:"WING_RACING_SOLID_GRAY_MOONROCK_1",
	WING_RACING_SOLID_GRAY_SEBRING_SILVER_1:"WING_RACING_SOLID_GRAY_SEBRING_SILVER_1",
	WING_RACING_SOLID_GRAY_SILVERSTONE_1:"WING_RACING_SOLID_GRAY_SILVERSTONE_1",
	WING_RACING_SOLID_GREEN_LIME_GREEN_1:"WING_RACING_SOLID_GREEN_LIME_GREEN_1",
	WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1:"WING_RACING_SOLID_GREEN_TAHITIAN_GREEN_1",
	WING_RACING_SOLID_ORANGE_IMOLA_ORANGE_1:"WING_RACING_SOLID_ORANGE_IMOLA_ORANGE_1",
	WING_RACING_SOLID_RED_NEW_FORMULA_RED_1:"WING_RACING_SOLID_RED_NEW_FORMULA_RED_1",
	WING_RACING_SOLID_YELLOW_RIO_YELLOW:"WING_RACING_SOLID_YELLOW_RIO_YELLOW",
	WING_RACING_SOLID_YELLOW_RIO_YELLOW_1:"WING_RACING_SOLID_YELLOW_RIO_YELLOW_1",
	WING_RACING_SOLID_YELLOW_SPA_YELLOW_1:"WING_RACING_SOLID_YELLOW_SPA_YELLOW_1",
	WING_RACING_THEME_CAMEL_12:"WING_RACING_THEME_CAMEL_12",
	WING_RACING_THEME_CANON_5:"WING_RACING_THEME_CANON_5",
	WING_RACING_THEME_LUCKY_STRIKE_1:"WING_RACING_THEME_LUCKY_STRIKE_1",
	WING_RACING_THEME_MARLBORO_12:"WING_RACING_THEME_MARLBORO_12",
	WING_RACING_THEME_ROTHMANS_1:"WING_RACING_THEME_ROTHMANS_1"

}
export default List;