import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


/*
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


*/

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    //useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";



import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



// import { 
//     Fade, 
//     //FadeMove 
// } from 'utils/components/Animations';



import {

    TransparencyLayer,
    Container,

    TopSection,
        Category,
        Title,
        Button,

    BottomSection,
        //ProcessBar,
        Navigation,

} from './Styled';



import { ProgressTimer } from './ProgressTimer'


const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    //const location = useLocation();
    const history = useHistory();



    /*

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    */


    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    //const refC = useRef(null);
    // const refControls = useRef(null);



    //---------------------------------------------
    // state/effect - main
    //---------------------------------------------

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);

    const [navButtons, setNavButtons] = useState([]);
    const [navXPos, setNavXPos] = useState(false);

    const [complete, setComplete] = useState(false);


    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);

        } 
        //console.log(JSON.stringify(props,null,2));
        //alert('props');


        var buttons = [];
        var button = {

            name:"",
            action:""
        
        };
        var i;
        var count;
        count = props.totalBoards;
        if(isNaN(props.totalBoards) || !props.totalBoards) count = 3;

        for(i=0; i < count; i++)
        {
            button.name = i;
            buttons.push(button);

        }
        setNavButtons(buttons);


        var buttonWInt = 12;
        var buttonBorderWInt = 2;
        var buttonTotalMargin = 10;

        var formula = buttonWInt + buttonTotalMargin + (buttonBorderWInt*2);
        var totalNavWidth = (formula * buttons.length);
        var navXPos = "calc(50% - "+(totalNavWidth/2)+"px)";

        //alert(navXPos);

        setNavXPos(navXPos);


        // if(props.pauseTimer)
        // {
        //     setPause(true);
        // }else{
        //     setPause(false);
        // }



    },
    [
        props,
        width,
        height,
        isLoaded,
        refA
    ])








    // //---------------------------------------------
    // // state/effect - pauseTimer
    // //---------------------------------------------

    // // -- state -- //
    // const [pauseTimer,setPauseTimer] = useState(false);

    // useEffect(() => {

    //     const effectPauseTimer = (eff) => {

    //         const timer = setTimeout(() => {

    //             console.log("Times Up");
    //             setPauseTimer(false);
    
    //         }, 7000);
    
    //         return () => clearTimeout(timer);

    //     }

    //     if(isLoaded && pauseTimer)
    //     {

    //         effectPauseTimer({

    //             props:props,
         
    //         });
   
    //     } 
    // },
    // [
    //     props,
    //     isLoaded,
    //     pauseTimer 

    // ])






    //---------------------------------------------
    // state/effect - pause
    //---------------------------------------------

    // TM: Notes on this effect 
    // - timeout must not be enclosed in an useEffect sub function or return clearTimeout will cause a memory leak

    // -- state -- //
    const [pause, setPause] = useState(false);



    useEffect(() => {

        // https://www.alexhughes.dev/blog/setTimeout-with-hooks/

        // https://stackoverflow.com/questions/57995978/why-is-cleartimeout-not-clearing-the-timeout-in-this-react-component


        if(isLoaded && pause)
        {

            console.log("pausing...");

            var timer = setTimeout(() => {

                //alert("Billboard Overlay - pause timer - Times Up");
                setPause(false);
                //setOnPause(false);
    
            }, 7000);
            //if(clearPause) { console.log('clearTimeout'); clearTimeout(timer);}
            return () => clearTimeout(timer);
            
        } 


    },
    [
        props,
        isLoaded,
        pause
    ])










    //---------------------------------------------
    // state/effect - transition
    //---------------------------------------------


    // -- state -- //
    const [boardNumber, setBoardNumber] = useState(0);
    const [animateOut, setAnimateOut] = useState(true);
    const [inTransition, setInTransition] = useState(false);
    const [wasClicked, setWasClicked] = useState(false);


    useEffect(() => {


        //alert(animateOut);
        // console.log(boardNumber+" "+props.data.boardNumber);

        if(isLoaded 
        && boardNumber !== props.data.boardNumber
        )
        {
            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            setBoardNumber(props.data.boardNumber);

            setInTransition(false);
            setAnimateOut(true);
     

        }else{


            if(!inTransition)
            {
            
                var timer;
                if(wasClicked)
                {

                    //Pause after click
                    timer = setTimeout(() => {

                        setWasClicked(false); 

                    }, 200);
                    return () => clearTimeout(timer);

                }else{

                    //Delay before starting animation
                    timer = setTimeout(() => {

                        setAnimateOut(false); 

                    }, 10);
                    return () => clearTimeout(timer);

                }



            }

        } 



        // console.log("props.data:::::: "+JSON.stringify(props.data,null,2));


 

    },
    [
        props,
        isLoaded,
        boardNumber,
        animateOut,
        inTransition,
        wasClicked

    ])




    //---------------------------------------------
    // state/effect - timer CleanUp
    //---------------------------------------------

    const [timer, setTimer] = useState(false);

    useEffect(() => {

        if(timer)
        {
            return () => {

                clearTimeout(timer);
                setTimer(false);
            }
        }

    }, [

        props,
        timer

    ]);




    //---------------------------------------------
    // handle - url
    //---------------------------------------------

    const handleUrl = (hndl) => {

        setPause(true);
        //setOnPause(true);

        // var timer = setTimeout(() => {
            
        //     history.push(hndl.url);

        // }, 40);
        // return () => clearTimeout(timer);

        history.push(hndl.url);

    }






    //---------------------------------------------
    // handle - change
    //---------------------------------------------

    const handleChange = (hndl) => {

        //console.log(JSON.stringify(hndl,null,2));


        switch(hndl.call)
        {
        case'transition':


            console.log(hndl.case);
            switch(hndl.case)
            {
            case'out':

                setInTransition(true);
                setAnimateOut(true);
            break;
            default:
                setInTransition(true);
                setAnimateOut(false);
            }

        break;
        case'progressChange':

            setPause(true);
            setComplete(true);

            //---------------------------

            //Action Goes Here


            setTimer(

                setTimeout(() => {

                    props.handle({
    
                        call:hndl.call,
        
                    });
    
                    setPause(false);
                    setComplete(false)
    
                }, 800)

            );



        break;
        case'clickChange':

            setWasClicked(true);
            setInTransition(true);
            setPause(true);
            setComplete(true);
        

            //---------------------------

            //Action Goes Here

            setTimer(

                setTimeout(() => {

                    props.handle({
    
                        call:hndl.call,
                        number:hndl.number
        
                    });
    
                    //setPause(false);
                    setComplete(false);
        
                }, 100)

            );


        break;
        default:
        }



    }







    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>

        return (

            <Container
            ref={refA}
            >
            </Container>
        )

    } 
    else 
    {

        return (


            <div>

                <TransparencyLayer
                ref={refB}
                >
                </TransparencyLayer>

                <Container
                ref={refA}
                >

                    {/* <Fade
                    out={animateOut}> */}
                    <TopSection
                    out={animateOut}
                    >

                        <Category
                        out={animateOut}
                        >
                            {props.data.category}

                        </Category>

                        <Title
                        out={animateOut}
                        >
                            {props.data.title}

                        </Title>

        
                        {/* 
                        <Link 
                        to={props.data.button.url}
                        >

                            <Button>

                                {props.data.button.name}

                            </Button>


                        </Link> */}



                        <Button

                        backgroundColorHover={props.data.button.style.backgroundColorOver}
                        backgroundColor={props.data.button.style.backgroundColorOut}
                        border={"2px solid "+props.data.button.style.backgroundColorOut}

                        // backgroundColor={'red'}
                        // border={"2px solid yellow"}

                        out={animateOut}
                        onClick={(v) => handleUrl({

                            url:props.data.button.url
                       
                        })}
                        >
                            {props.data.button.name}

                        </Button>



                    </TopSection>
                    {/* </Fade> */}




                    {
                    props.totalBoards > 1
                    ?


                        <BottomSection
                        out={animateOut}
                        >

                            <Navigation
                            //yPos={navYPos}
                            xPos={navXPos}
                            >
                            
                                {navButtons.map((btn, i) => (



                                    props.data.boardNumber === i
                                    ?

                                        <div
                                        key={i}
                                        className="Circle"
                                        style={{

                                            backgroundColor:props.data.button.style.backgroundColorOut,
                                            border:"2px solid "+props.data.button.style.backgroundColorOut
                                        }}
                                        >
                                        </div>


                                    :

                                        <div
                                        key={i}
                                        className="Circle"
                                        onClick={(v) => handleChange({

                                            call:'clickChange',
                                            number:i

                                        })}
                                        >
                                        </div>




                                ))}

                            </Navigation>

                        </BottomSection>

                    :null
                    }




                    
                    {
                    props.totalBoards > 1
                    ?

                        !pause
                        ?

                            <ProgressTimer 
                    
                                barColor={props.data.button.style.backgroundColorOut}
                                complete={false}
                                timerReset={true}
                                handle={(v) => handleChange(v)}
                                //handle={props.handle}
                            
                            />

                        :
                        pause && complete
                        ?

                            <ProgressTimer
                    
                                barColor={props.data.button.style.backgroundColorOver}
                                complete={true}
                                timerReset={false}
                                handle={(v) => handleChange(v)}
                                //handle={props.handle}
                            
                            />

                        :null



                    :null
                    }


                </Container>

            </div>

                   
        )


    }


}

export default Index;



