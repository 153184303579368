
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


/*
import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';
*/



import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

//import { useLiqGrid } from 'utils/myHooks/LiquidFormula/LiqGrid';


import { Alfa, Base, Tab, Flank} from 'app-styled/AlfaBase.js';
import { Print, Readr } from './Styled';


import Cards from './Comps/Cards';
import Handler from './Handler';


import { LibElem } from 'library';

import CreateMarkup from './CreateMarkup';


const Index = (props) => {
   

    // -- window dims -- //
    const { width, height } = useWindowDimensions();


    // -- router -- //
    //let match = useRouteMatch();
    //let location = useLocation();
    //let history = useHistory();


    // -- redux -- //

    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    //if(zoneObj['handle'] && zoneObj['handle'] !== undefined) alert(zoneObj['handle']);




    // -- vars -- //

    var title;
    //var call;
    var type = 'default';
    var data;
    var styl;

    //call = props.data.call;

    var level;
    if(props.data.data.data !== undefined) level = 2;
    else if(props.data.data !== undefined) level = 1;
    else level = 0;

    switch(level)
    {
    case 1:

        type = props.data.flyr.type;
        data = props.data.data;
        styl = props.data.flyr.styl;
    break;
    case 2:

        type = props.data.data.flyr.type;
        data = props.data.data.data;
        styl = props.data.data.flyr.styl;
    break;
    default:

        type = props.flyr.type;
        data = props.data;
        styl = props.flyr.styl;
    break;
    }//===




    switch(type.toLowerCase())
    {
    case'cards':

        title = 'Cards'; 
        if(data.name !== '') title = data.name;
    break;
    default:
        title = '';      
    }



    if(width < 400) title = '';


    var maxWidth;
    maxWidth = 900;

    if(styl
    && styl !== undefined) 
    {
        maxWidth = styl.maxWidth;
    }

    if(width < maxWidth + 50)
    {
       maxWidth = width;

    }//==


    
    console.log(""

        //+"\nCALL: "+call
        +"\nTYPE: "+type
        +"\nDATA: "+JSON.stringify(data,null,2)
        +"\nSTYL: "+JSON.stringify(styl,null,2)
    );
    

    //console.log("READR DATA :::::::::"+JSON.stringify(props.data,null,2));
    //alert('props');






    //#########################################################
    //#########################################################


    // -- ref -- //
    const baseRef = useRef(null)
    const tabRef = useRef(null)
    const flankRef = useRef(null)
    const flyrRef = useRef(null)
    const binRef = useRef(null)

    
    /*
    const cellRefs = [];
    const setRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };
    */






    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])




    // -- state -- //
    const [baseScrollHeight, setBaseScrollHeight] = useState(0)
    const [tabHeight, setTabHeight] = useState(10)
    const [flyrHeight, setFlyrHeight] = useState(height)
    const [flyrType, setFlyrType] = useState(false)
    const [flyrData, setFlyrData] = useState(false)
    const [flyrStyl, setFlyrStyl] = useState(false)
    const [contentsHeight, setContentsHeight] = useState(200)



    useEffect(() => {

        setFlyrType(type.toLowerCase());
        setFlyrStyl(styl);
        setFlyrData(data);
        


        if(tabHeight < 50)
        {
            setTabHeight(tabRef.current.offsetHeight);
        }


        var flyrH;
        if(flyrHeight < 50)
        {
            setBaseScrollHeight(baseRef.current.scrollHeight);
            flyrH = flyrRef.current.offsetHeight;
            
        }
        else
        if(flyrHeight < contentsHeight+50)
        {
            if(contentsHeight < height) 
                flyrH = baseRef.current.scrollHeight; 
            else
                flyrH = contentsHeight+50; 

        }//==


        if(flyrHeight < binRef.current.offsetHeight)
        {
            flyrH = baseRef.current.scrollHeight;
        }

        
        /*
        if(flyrHeight < baseRef.current.scrollHeight-200
        )
        {
            console.log(baseRef.current.scrollHeight);
            setFlyrHeight(baseRef.current.scrollHeight);
        }
        */

        if(flyrH !== undefined) setFlyrHeight(flyrH);    


        /*
        console.log(""

            +   "\n FLYR HEIGHT: " +flyrHeight

            +   "\n CONTENTS: " +contentsHeight 
            +   "\n BIN: "+binRef.current.offsetHeight
            +   "\n FLYR: " +flyrRef.current.offsetHeight
            +   "\n TAB: " +tabRef.current.offsetHeight
            +   "\n BASE SCROLL: " +baseRef.current.scrollHeight
            +   "\n HEIGHT: " +height
   
        );
        */


        
        /*
        console.log("Readr"

            +"\nTYPE: "+type
            +"\nDATA: "+JSON.stringify(data,null,2)
            +"\nSTYL: "+JSON.stringify(styl,null,2)
        );
        */

        //console.log("READR DATA :::::::::"+JSON.stringify(props.data,null,2));
        //alert('readr props');


    },[

        props,
        height,
        baseScrollHeight,
        tabHeight,     
        flyrHeight,
        contentsHeight,
        type,
        data,
        styl
 
    ])



    // -- handle -- //

    const handle = (hndl) => {

        console.log("Readr - handle: "+hndl);

        //hndl['zoneObj'] = zoneObj;
        hndl['handleState'] = (v) => {


            if(v.call === 'contentsHeight') setContentsHeight(v.value);       
            //if(v.call === 'handleDispatch') handleDispatch(v.value);
            if(v.call === 'handleCloseReadr') handleCloseReadr(v.value); 

        };
        Handler(hndl);

    }


    /*
    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')
    
        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }
    */


    const handleCloseReadr = (hndl) => {

        props.handle({

            call:'hide'

        })

    }











    //###########################################################
    //###########################################################

    var flyrPadBottomInt = 0;
    var flyrPadBottom = flyrPadBottomInt + "px";

     var flyrWInt;
     var flyrW;

     var flyrH;

     var flyrMarl;

     var flyrMartInt;
     var flyrMart;

     var tabMartInt;
     var tabMart;



     flyrWInt = maxWidth;
     flyrMarl = (width - flyrWInt)/2 + "px";

     if(width < flyrWInt+50)
     {
        flyrWInt = width;
        flyrMarl = 0 + "px";
     }//==

     flyrW = flyrWInt + "px";


    //-------------------------------------------

    flyrMartInt = tabHeight;
    tabMartInt = 0;

    flyrMart = flyrMartInt + 'px';
    tabMart = tabMartInt + "px";

    //-------------------------------------------

    var flankLeftWidthInt;
    var flankLeftWidth;
    var flankLeftMarl;
       
    var flankRightWidthInt;
    var flankRightWidth;
    var flankRightMarl;

    var flankVertHeight;


    flankLeftWidthInt = (width - flyrWInt)/2;
    flankLeftWidth = flankLeftWidthInt + "px";
    flankLeftMarl = "0px";
       
    flankRightWidthInt = (width - flyrWInt)/2;
    flankRightWidth = flankRightWidthInt + "px";
    flankRightMarl = flankLeftWidthInt + flyrWInt + "px";


    //flyrH = baseScrollHeight - (tabHeight+flyrPadBottomInt) + "px";
    flyrH = flyrHeight + (0) + "px";
    flankVertHeight = flyrHeight + (flyrMartInt) + "px";






    // console.log("Readr - flyrType: "+flyrType);
    // alert('hold Readr');



    return (


        <div>

            <Alfa
            zIndex={highZ+1}
            ></Alfa>




            <Tab
            ref={tabRef}
            zIndex={highZ+3}
            //backgroundColor={'white'}
            width={flyrW}
            top={tabMart}
            left={flyrMarl}
            >

            
                <div 
                className="Title" 
                >  

                    {title}

                </div>
            
        
                <div 
                className="X" 
                onClick={() => props.handle({

                    call:'hide'

                })}
                >  
                    X
                </div>


            </Tab>




            <Base 
            id="flyrBase"
            ref={baseRef}
            zIndex={highZ+2}
            >


                <Flank
                id="flankleft"
                ref={flankRef}
                //backgroundColor={'blue'}
                width={flankLeftWidth}
                height={flankVertHeight}
                left={flankLeftMarl}
                onClick={() => props.handle({

                    call:'hide'

                })}
                >
                </Flank>


                <Flank
                id="flankright"
                //backgroundColor={'red'}
                width={flankRightWidth}
                height={flankVertHeight}
                left={flankRightMarl}
                onClick={() => props.handle({

                    call:'hide'

                })}
                >
                </Flank>



                <Readr 
                id="readr"
                ref={flyrRef}
                backgroundColor={'#fcfcfc'}
                width={flyrW}
                height={flyrH}
                margin={flyrMart+" 0 0 "+flyrMarl}
                padding={"0 0 "+flyrPadBottom+" 0"}
                >


                    <div 
                    ref={binRef}
                    className="Bin"
                    >

                        {
                
                            flyrType === 'cards'
                            ?

                                <Cards 
                                
                                    primeHandle={(v) => props.handle(v)}
                                    handle={(v) => handle(v)}
                                    data={flyrData}
                                    styl={flyrStyl}
  
                                />

                            :
                         
                                <Print
                                backgroundColor={"#181926"}
                                color={"#f8f8f8"}
                                >

                                    <div dangerouslySetInnerHTML={CreateMarkup(flyrData.value)} />

                                </Print>


                        }

                    </div>


                </Readr>

            </Base>


        </div>

    );




}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/