import React from 'react';

import {
  //BrowserRouter as Router,
  Switch,
  Route
  //Link
} from "react-router-dom";


//imports-start
import X from "./X";
import ErrorPage from "./ErrorPage";
import Home from "./Home";
import Cars from "./Cars";
import Configurator from "./Configurator";
import McLarenHonda from "./McLarenHonda";
import WilliamsHonda from "./WilliamsHonda";
import SpoonSportsAP1 from "./SpoonSportsAP1";
import S2000CR from "./S2000CR";
import S2000 from "./S2000";
import ContactForm from "./ContactForm";
//imports-end

const Index = (props) => {


    //alert('props.primeDif....'+props.primeDif)

    return (

		<Switch>

			<Route path="/ErrorPage">

				<ErrorPage
					databind={props.databind}
				/>

			</Route>

			<Route path="/Cars">

				<Cars
					databind={props.databind}
				/>

			</Route>

			<Route path="/Configurator">

				<Configurator
					databind={props.databind}
				/>

			</Route>

			<Route path="/McLarenHonda">

				<McLarenHonda
					databind={props.databind}
				/>

			</Route>

			<Route path="/WilliamsHonda">

				<WilliamsHonda
					databind={props.databind}
				/>

			</Route>

			<Route path="/SpoonSportsAP1">

				<SpoonSportsAP1
					databind={props.databind}
				/>

			</Route>

			<Route path="/S2000CR">

				<S2000CR
					databind={props.databind}
				/>

			</Route>

			<Route path="/S2000">

				<S2000
					databind={props.databind}
				/>

			</Route>

			<Route path="/ContactForm">

				<ContactForm
					databind={props.databind}
				/>

			</Route>

			<Route exact path="/">

				<Home
					databind={props.databind}
				/>

			</Route>

			<Route path="/">

				<X
					databind={props.databind}
				/>

			</Route>

		</Switch>

	
	);//return
}
export default Index;