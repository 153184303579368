export const HOME = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M349.899,76.71h28.68c11.172,0,20.313,9.141,20.313,20.313v92.525l38.879,38.879c11.154,11.153,11.154,29.405,0,40.558 l-39.477-0.055v151.924c0,14.459-11.828,26.289-26.287,26.289H300.31V322.511c0-13.604-10.754-24.736-23.898-24.736h-51.382 c-13.145,0-23.899,11.131-23.899,24.736v124.633h-71.696c-14.459,0-26.289-11.83-26.289-26.289V268.929l-40.916,0.057	c-11.153-11.152-11.154-29.404,0-40.558L228.684,61.972c5.535-5.535,13.422-9.324,21.316-9.106	c7.896-0.216,15.778,3.569,21.315,9.106l58.27,58.271V97.023C329.585,85.851,338.728,76.71,349.899,76.71L349.899,76.71z"
			/>

			
		</svg>
		</div>

	);//return







}