
const ApiResources = {

    "local":{

        "3001":{

            "novpc":"localhost:3001",
            "vpc":"localhost:3001"
        },

        "5000":{

            "novpc":"localhost:5000",
            "vpc":"localhost:5000"
        }

    },

    "network":{

        "3001":{

            "novpc":"192.168.1.110:3001",
            "vpc":"192.168.1.110:3001"
        },

        "5000":{

            "novpc":"192.168.1.110:5000",
            "vpc":"192.168.1.110:5000"
        }

    },


    // "aws":{


    //     "main":{

    //         "novpc":"8wwjxztk4g.execute-api.us-east-1.amazonaws.com/Prod",
    //         "vpc":"9kq6tobs96.execute-api.us-east-1.amazonaws.com/Prod"

    //     },

    //     "admin":{

    //         "novpc":"8wwjxztk4g.execute-api.us-east-1.amazonaws.com/Prod",
    //         "vpc":"9kq6tobs96.execute-api.us-east-1.amazonaws.com/Prod"

    //     },

    //     "x":{

    //         "novpc":"8wwjxztk4g.execute-api.us-east-1.amazonaws.com/Prod",
    //         "vpc":"9kq6tobs96.execute-api.us-east-1.amazonaws.com/Prod"

    //     }


    // }



    "aws":{


        "main":{

            "novpc":"4rn4w8h6b8.execute-api.us-east-1.amazonaws.com/Prod",
            "vpc":"4rn4w8h6b8.execute-api.us-east-1.amazonaws.com/Prod"

        },

        "admin":{

            "novpc":"ul3slir2c7.execute-api.us-east-1.amazonaws.com/Prod",
            "vpc":"ul3slir2c7.execute-api.us-east-1.amazonaws.com/Prod"

        },

        "x":{

            "novpc":"kscuszzhj6.execute-api.us-east-1.amazonaws.com/Prod",
            "vpc":"kscuszzhj6.execute-api.us-east-1.amazonaws.com/Prod"

        }


    }


}
export default ApiResources;