
const LibCheck = {


    IsIterable: function(input) {

        if(input === null || input === undefined) 
        {
            return false
        }

        return typeof input[Symbol.iterator] === 'function'

    }


}


export default LibCheck;