export const BUTTON_LOGO_CR = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Button*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.49,187.513H258.937c-13.11,0-39.276,0-49.08,12.106l-25.07,30.959c-8.069,9.965-8.545,24.622,19.338,24.622H332.17 l14.535-17.948H238.768c-12.963,0-17.229-3.557-11.188-11.018l8.786-10.849c5.374-6.636,13.075-9.028,28.107-9.028h93.757 L373.49,187.513L373.49,187.513z M319.737,303.161l51.466-63.556h124.002c10.685,0,28.376,3.633,12.731,22.953l-9.053,11.179 c-8.481,10.474-23.603,13.858-39.298,15.305l78.68,24.054c2.427,0.742,2.305,3.391-0.346,3.391h-70.546 c-0.95,0-2.142-0.167-2.888-0.396l-91.001-27.821l-12.059,14.892H319.737L319.737,303.161z M387.153,271.392l14.09-17.399h56.681 c7.598,0,9.676,3,5.976,7.57l-3.667,4.529c-2.703,3.337-5.771,5.3-17.668,5.3H387.153L387.153,271.392z"
    />
    





    

		</svg>
		</div>

	);//return
}