import React, { 

    useState, 
    useEffect, 
    useRef,
    //useMemo

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    //useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 
    Header, 
    // HeaderMain, 
    //HeaderLogo,
    // HeaderTitle,
    // HeaderArrowButton

    //Hamburger

} from './StylAttrs';



const Index = (props) => {


    //const primeHandle = props.databind.primeProps.handle;


    const { width } = useWindowDimensions()


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    //const location = useLocation();
    //const history = useHistory();

 
 
    // var pathname = location.pathname;

    // if(pathname.charAt(0) === '/') 
    //     pathname = pathname.substring(1,pathname.length);


    // if(pathname.charAt(pathname.length-1) === '/') 
    //     pathname = pathname.substring(0,pathname.length-1);


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // -- ref -- //
    const refLogo = useRef(null);
    const refBurger = useRef(null);
    const refBun = useRef(null);


    // -- state -- //
    //const [isLoaded, setIsLoaded] = useState(false);
    //const [headerGrfx, setHeaderGrfx] = useState(false);
    const [headerStyl, setHeaderStyl] = useState({

        bgColor:'#001f36',
        height: "75px",
        opacity: "1.0"

    });
    // const [hamburgerStyl, setHamburgerStyl] = useState({

    //     margin:false

    // });


    useEffect(() => {

        var placeholder;
        placeholder = false;
        if(props.databind.placeholder) placeholder = props.databind.placeholder;
        //alert("placeholder---"+placeholder)
        if(placeholder)
        {
            // setHeaderStyl({

            //     bgColor:'#001f36',
            //     height: "75px",
            //     opacity: "1.0"


            // });

            var object;
            object = {

                bgColor:'#000000',//"#001f36"
                height: "75px",
                opacity: "1.0"

            };
            if(zoneObj.settings 
            && zoneObj.settings !== undefined)
            {
    
                var headerHInt;
                var headerMainColor = false;
                var headerOpacity = "1.0";

                if(zoneObj.settings.appearance)
                {
                    if(zoneObj.settings.appearance.header)
                    {
                        if(zoneObj.settings.appearance.header.headerMainColor)
                        {
                            headerMainColor = zoneObj.settings.appearance.header.headerMainColor;
                        }
        
                        headerHInt = zoneObj.settings.appearance.header.headerMainHeight;
                        if(headerHInt.match(/px/gi))
                        {
                            headerHInt = headerHInt.replace(/px/gi,"");
                        }
                        if(headerHInt < 75) headerHInt = 75;


                        // if(zoneObj.settings.appearance.header.headerOpacity)
                        // {
                        //     headerOpacity = zoneObj.settings.appearance.header.headerOpacity;
                        // }
                        object = {
        
                            backgroundColor: headerMainColor,
                            height:  headerHInt + "px",
                            opacity: headerOpacity
                        };  
                    }
                }


    
            }
            setHeaderStyl(object);

        }else{

            setHeaderStyl({

                bgColor:'transparent',
                height: "0px",
                opacity: "0.0"

            });

        }


        //---------------------------------------
        // setHeaderGrfx
        //---------------------------------------

        // if(!headerGrfx)
        // {
        //     if(zoneObj.settings 
        //     && zoneObj.settings !== undefined)
        //     {
        //         if(zoneObj.settings.graphic.header)
        //         {
        //             setHeaderGrfx({

        //                 logo:{

        //                     src:zoneObj.settings.graphic.header.image.src,
        //                     w:zoneObj.settings.graphic.header.image.w,
        //                     h:zoneObj.settings.graphic.header.image.h
        //                 }

        //             });

        //             var burgerTop = (refLogo.current.offsetHeight - refBurger.current.offsetHeight)/2 - refBun.current.offsetTop*2;
        //             setHamburgerStyl({

        //                 margin: burgerTop + "px 15px 0 0"

        //             });

        //         }

        //     }

        // }






    },[
        
        props,
        zoneObj,
        width,

        refLogo,
        refBurger,
        refBun,

        //headerGrfx

    ])











    return (<div>

        <Header
        backgroundColor={headerStyl.bgColor}
        height={headerStyl.height}
        opacity={headerStyl.opacity}
        >


            {/* <Link 
            to={""}
            >
                {
                headerGrfx
                ?

                    <HeaderLogo
                    ref={refLogo}
                    width={headerGrfx.logo.w + "px"}
                    height={headerGrfx.logo.h + "px"}
                    >

                        <div>

                            <img 
                                src={headerGrfx.logo.src}
                                w={headerGrfx.logo.w} 
                                h={headerGrfx.logo.h}
                                alt="logo" 
                                // style={{
                                //     width:headerGrfx.logo.w + "px",
                                //     height:headerGrfx.logo.h + "px"
                                // }} 
                                    
                            />

                        </div>

                    </HeaderLogo>

                :
            
                    <HeaderLogo
                    ref={refLogo}
                    >
                    </HeaderLogo>
                }
                

            </Link>




            <Hamburger
            ref={refBurger}

                margin={hamburgerStyl.margin}
                onClick={() => primeHandle({

                    call:"showMenu",
                    data:[]

                })}

            >

                <div ref={refBun} className='bun'></div>
                <div className='bun'></div>
                <div className='bun'></div>

            </Hamburger>
 */}


        </Header>
    
    </div>);

}

export default Index;



