export const BUTTON_LOGO_ADVAN = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.576,264.297h19.569l-9.484-27.742L149.576,264.297L149.576,264.297z M106.795,291.494l34.188-78.879h38.774l31.895,78.879h-33.114l-2.621-7.736h-33.551l-2.821,7.736H106.795L106.795,291.494z M247.77,232.076v38.465h15.201c7.919,0,13.599-1.51,17.021-4.514c3.44-3.004,5.152-7.92,5.152-14.746 c0-6.789-1.729-11.688-5.207-14.691c-3.459-3.004-9.12-4.514-16.966-4.514H247.77L247.77,232.076z M217.205,212.506h50.554 c16.366,0,28.817,3.369,37.392,10.104c8.574,6.736,12.853,16.566,12.853,29.473c0,12.871-4.26,22.664-12.798,29.363 c-8.537,6.699-21.008,10.049-37.446,10.049h-50.554V212.506L217.205,212.506z M350.371,291.494l-34.825-78.879h34.516 l16.693,52.793l17.585-52.793h34.188l-36.263,78.879H350.371L350.371,291.494z M449.475,264.297h19.57l-9.484-27.742 L449.475,264.297L449.475,264.297z M406.695,291.494l34.188-78.879h38.775l31.893,78.879h-33.113l-2.621-7.736h-33.551 l-2.822,7.736H406.695L406.695,291.494z M516.994,291.494v-78.879h36.154l30.457,45.438v-45.438h29.6v78.879h-34.297 l-33.113-46.184v46.184H516.994L516.994,291.494z"
    />
    





    

		</svg>
		</div>

	);//return
}