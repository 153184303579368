import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
import useDeviceDetect from 'utils/hooks/DeviceDetect';
import useScreenOrientation from 'utils/hooks/ScreenOrientation';


import {

    DynamicForm,
    Section

} from './Styled';


//import FGrid from 'utils/components/Grid/FGrid';



//import { Fade } from 'utils/components/Transitions';



import FormComponent from 'utils/components/Form';
import FormHandler from 'utils/components/Form/Handler';
import FormConstructComposer from './Form/ConstructComposer';
import FormBuild from './Form/Build';
import {

    FScomplete, 
    FSerror,
    FSloading,
    //FSmessage,
    FSprintout,
    FSprocess,
    //FStimeout


} from './Form/Submit';



const Index = (props) => {



    //console.log(JSON.stringify(props,null,2));

    const Composer = FormConstructComposer(props);
    const FormCompose = FormBuild({Composer:Composer});


    // -- window dims -- //
    const { 
        width, 
        height
    } = useWindowDimensions();

    // -- device detect -- //
    const { isMobile } = useDeviceDetect();

    // -- screen orientation -- //
    const screenOrientation = useScreenOrientation()


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    var pathname = location.pathname;
    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);

    let zoneId;
    zoneId = pathname.replace(/\//gi,"-");



    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    
    


    // -- ref -- //
    const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);
    // const refControls = useRef(null);



    //---------------------------------------------
    // state - fade variables
    //---------------------------------------------

    //const [fadeImage, setFadeImage] = useState(true);


    //---------------------------------------------
    // state/effect - currentGroup
    //---------------------------------------------

    const [isLoaded, setIsLoaded] = useState(false);
    const [isWide, setIsWide] = useState(true);
    const [threshold] = useState(800);
    const [currentOrientation, setCurrentOrientation] = useState(false);
    const [currentGroup, setCurrentGroup] = useState({

        height:"auto",
        info:false,
        alfa:false,
        base:false,
        controller:false,
        images:[]

    });

 
    useEffect(() => {



        // -- effect -- //

        const effectCurrentGroup = (eff) => {

            setIsLoaded(true);

            const props = eff.props;
            //const isMobile = eff.isMobile;
            const screenOrientation = eff.screenOrientation;
            const items = eff.items;
            //const currentGroup = eff.currentGroup;

            //let images = [];       
            // let imgW,imgH,imgPerc,imgChoice;
            
            // let itemA = items[0];
            // let itemB = items[1];

            // if(itemA.image && itemA.image !== undefined)
            // {
            //     if(itemA.image.w && itemA.image.w !== undefined)
            //     {
            //         imgW = parseInt(itemA.image.w);
            //         imgH = parseInt(itemA.image.h);
            //         imgPerc = (imgH/imgW) * 100;
            //         if(imgPerc < 50)
            //         {
            //             imgChoice={
            //                 wide:itemA,
            //                 mobi:itemB
            //             }

            //         }else{

            //             imgChoice={
            //                 wide:itemB,
            //                 mobi:itemA
            //             }
            //         } 
            //     }
            // }


            var bandHeight = "350px";
            // switch(screenOrientation)
            // {
            // case'PORTRAIT':

            //     images.push(imgChoice.mobi);
            //     bandHeight = images[0].image.height + "px";
            // break;
            // default:
            //     images.push(imgChoice.wide);
            //     bandHeight = "550px";
            // }


            // if(!eff.wide)
            // {
            //     images.push(imgChoice.mobi);
            //     bandHeight = images[0].image.height + "px";
            // }else{
            //     images.push(imgChoice.wide);
            //     bandHeight = "300px";
            // }



            //alert("ITEMS: "+JSON.stringify(items,null,2));
   

            setIsWide(eff.wide);

        
            setCurrentOrientation(screenOrientation);
            setCurrentGroup({

                height:bandHeight,
                info:props.data.info,
                items:items,
                alfa:false,
                base:false,
                controller:false

            });


        };





        // -- cause -- //

        var wide;
        var cause = false;
        if(!isLoaded) cause = true;
        if(currentOrientation !== screenOrientation) cause = true;
        if(width < threshold && isWide) 
        {
            cause = true;
            wide = false;
        }
        if(width > threshold && !isWide) 
        {
            cause = true;
            wide = true;
        }
        if(cause)
        {
            //console.log('cause - effect');
            effectCurrentGroup({
        
                props:props,
                width:width,
                threshold:threshold,
                wide:wide,
                isMobile:isMobile,
                screenOrientation:screenOrientation,
                items:props.data.items,
                currentGroup:currentGroup

            });

        }

        //console.log("WideScreen width: "+width+" "+threshold);
        //console.log("currentGroup: "+JSON.stringify(currentGroup,null,2));


    },
    [

        props, 
        width,height,  
        isMobile,
        screenOrientation, 
        isLoaded,
        isWide,
        threshold,
        currentOrientation,
        currentGroup,
        refA
 
    ])







    //========================================
    //form stuff
    //========================================

    // -- ref -- //
    const formRef = useRef(null);

    // -- state -- //
    //const [formWidthInt, setFormWidthInt] = useState(450);
    const [hashData, setHashData] = useState(false);
    const [processSubmit, setProcessSubmit] = useState(false); 
    const [processError, setProcessError] = useState(false);   
    const [processComplete, setProcessComplete] = useState(false); 
    const [formArr, setFormArr] = useState([]); 
    const [problemArr, setProblemArr] = useState([]);
    const [errorType, setErrorType] = useState('');
    const [currentCaptcha, setCurrentCaptcha] = useState(false);
    const [printout, setPrintout] = useState(false);
    const [processing, setProcessing] = useState(false);


    useEffect(() => {


        if(formArr.length === 0)
        {
            var newArr = [];
            for(var i=0; i < FormCompose.length; i++)
            {
                //console.log(object['section'] + "\n" + JSON.stringify(object['data'],null,2));
                var frm = FormCompose[i];
                newArr.push(frm);
    
            }//== i

            setFormArr(newArr);

        }
        

        //setFormWidthInt(formRef.current.offsetWidth);
        if(hashData === "#!" && location.hash === "")
        {
            setHashData(false);
            setProcessSubmit(false);  
        } 


        //console.log(JSON.stringify(problemArr,null,2));
        //alert('problemArr')




        if(zoneObj['currentZone'] === zoneId)
        {

            if(!zoneObj['problemArr'] 
            || zoneObj['problemArr'] === undefined
            )
            {
                if(problemArr.length > 0
                )
                {
                    //alert('dispatch');
                    dispatch(ModifyZoneStore({
        
                        type:"UPDATE_ZONE",
                        item:{
                            key     :"problemArr",
                            value   :problemArr
                        }
        
                    })); 
                    
                }

            }

            //REMOVED TO USE WITH CAPTCHA
            
            // else
            // if(zoneObj['problemArr'].length > 0)
            // {

            //     setProblemArr(zoneObj['problemArr']);
 
            // }




        }else{



            //alert('dispatch');
            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",
                item:{
                    key     :"currentZone",
                    value   :zoneId
                }

            })); 
                   
            
            //alert('dispatch');
            dispatch(ModifyZoneStore({

                type:"DELETE_ZONE",
                item:{
                    key     :"problemArr",
                    value   :[]
                }

            })); 


        }




    },[

        location,
        hashData,
        formRef,
        formArr,
        problemArr,
        dispatch,
        zoneObj,
        zoneId,
        FormCompose
    ])





    //---------------------------------------------
    // handles
    //---------------------------------------------


    const handle = (hndl) => {

        //alert(hndl.call);

        switch(hndl.call)
        {
        case'captcha':

            //alert("SetCurrentCaptcha: "+JSON.stringify(hndl.data,null,2));
            setCurrentCaptcha(hndl.data);
        break;
        case'timeout':

            setProcessing(false);
            setProcessComplete(false);
            setProcessError(false);

            if(!hndl.isError) history.push("");
        break;
        case'printout':

            //alert('printout');
            setPrintout(hndl.data.data);
        break;
        case'handleDispatch':

            handleDispatch(hndl.value);
        break;
        default:

            hndl['location'] = location;
            hndl['formArr'] = formArr;
            hndl['handleState'] = (v) => {


                //if(v.call === 'formWidthInt') setFormWidthInt(v.value);
                if(v.call === 'hashData') setHashData(v.value);   
                if(v.call === 'processSubmit') 
                {
                    //alert(v.value);
                    setProcessing(v.value);
                    setProcessSubmit(v.value);
                }
                if(v.call === 'processError') setProcessError(v.value);
                if(v.call === 'processComplete') 
                {
                    setProcessing(false);
                    setProcessComplete(v.value);
                }
                if(v.call === 'formArr') setFormArr(v.value);
                if(v.call === 'problemArr') 
                {
                    //alert(JSON.stringify(v.value,null,2));
                    setProblemArr(v.value);
                }
                if(v.call === 'errorType') setErrorType(v.value);
                if(v.call === 'handleDispatch') handleDispatch(v.value);

            
            };
            hndl['handleRoute'] = (v) => {
        
                switch(v.call)
                {
                case'goBack':
        
                    history.goBack();
                break;
                case'reload':
        
                    window.location.reload();//regular dom 
                break;
                case'redirect':
        
                    history.replace(v.val);
                break;
                case'push':
        
                    history.push(v.val);
                break;
                default:
                }
        
            }

            FormHandler(hndl);  
            
            
        }//switch




    }


    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')

        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }

    





    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

    
            <DynamicForm
            ref={refA}          
            //height={boardInfo.viewHeight}
            //height={currentGroup.height}
            height={"auto"}
            >
                <Section 
                >
                </Section>

            </DynamicForm>

        )

    } 
    else 
    {



        return (


            <DynamicForm
            ref={refA}
            //backgroundColor={'red'}
            //height={currentGroup.height}
            height={"auto"}
            >



                {
                processing ? 

                    <div>

                        <FSloading

                            width={width} 
                            height={height} 
                            formArr={formArr}
                            handle={(v) => handle(v)} 
                                    
                        /> 

                    </div>

                : null

                }



                {
                printout ? 

                    <div>

                        <FSprintout

                            width={width} 
                            height={height} 
                            formArr={formArr}
                            handle={(v) => handle(v)} 
                            data={printout}                 
  
                        /> 

                    </div>



                : null

                }


                {
                processSubmit ? 


                    <div>

                        <FSprocess

                            width={width} 
                            height={height} 
                            formArr={formArr}
                            handle={(v) => handle(v)}                   
                            currentCaptcha={currentCaptcha}
  
                        />

                    </div>



                : null

                }

                {
                processError ? 

                    <FSerror

                        width={width} 
                        height={height} 
                        errorType={errorType}
                        errorArr={problemArr}
                        //handle={(v) => props.databind.handle(v)} // prime.handle 
                        handle={(v) => handle(v)}  
                
                    /> 

                : null

                }


                {
                processComplete ? 

                    <FScomplete

                        width={width} 
                        height={height} 
                        formArr={formArr}
                        handle={(v) => handle(v)}   
                
                    /> 

                : null

                }




                {
                currentGroup.items.length > 0
                ?

                    <Section 
                    >
                        {

                        <FormComponent

                            refid       = {props.refid+"_dynamicForm"}    
                            info        = {currentGroup.info}  
                            items       = {formArr} 
                            handle      = {(v) => handle(v)} 
                            problemArr  = {problemArr}
                            border      = {"1px solid gray"}

                        />

                        }

                    </Section>


                :null
                }



            </DynamicForm>

                   
        )


    }


}

export default Index;



