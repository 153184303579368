export const BUTTON_LOGO_AP2V1 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.239,191.285h-42.554l-4.258,10.676h4.258c4.229,0,7.233,0.816,9.013,2.449
		c1.808,1.634,2.712,3.733,2.712,6.358c0,2.566-0.904,4.667-2.712,6.271c-1.779,1.634-4.783,2.45-9.013,2.45H220.12
		c-4.229,0-7.233-0.816-9.013-2.45c-1.808-1.604-2.712-3.732-2.712-6.357s0.934-4.754,2.829-6.388
		c1.867-1.662,4.987-2.45,9.304-2.333l27.533-68.512h-11.462c-4.2,0-7.233-0.816-9.012-2.45c-1.809-1.604-2.684-3.733-2.684-6.358
		s0.875-4.754,2.684-6.357c1.779-1.634,4.813-2.45,9.012-2.45l37.858,0.087l34.854,86.041c4.112,0,6.825,0.467,8.137,1.371
		c2.596,1.895,3.909,4.374,3.909,7.437c0,2.566-0.875,4.667-2.654,6.271c-1.75,1.634-4.754,2.45-8.954,2.45h-23.596
		c-4.2,0-7.204-0.816-9.012-2.45c-1.779-1.604-2.683-3.732-2.683-6.357c0-2.566,0.904-4.638,2.683-6.271
		c1.809-1.633,4.813-2.449,9.012-2.449h4.288L286.239,191.285L286.239,191.285z M278.977,173.786l-14.087-34.708l-14.204,34.708
		H278.977L278.977,173.786z M359.738,183.936v18.025h17.704c4.2,0,7.204,0.816,9.013,2.449c1.779,1.634,2.683,3.733,2.683,6.358
		c0,2.566-0.903,4.667-2.683,6.271c-1.809,1.634-4.813,2.45-9.013,2.45h-37.945c-4.229,0-7.233-0.816-9.012-2.45
		c-1.808-1.604-2.712-3.732-2.712-6.357c0-2.566,0.934-4.638,2.742-6.271c1.837-1.633,4.813-2.449,8.983-2.449h2.742v-68.512h-2.742
		c-4.229,0-7.233-0.816-9.012-2.45c-1.808-1.604-2.712-3.733-2.712-6.358s0.904-4.754,2.712-6.357
		c1.779-1.634,4.784-2.45,9.012-2.45l40.338,0.087c11.841,0,21.232,3.238,28.145,9.713c6.912,6.445,10.383,14.32,10.383,23.624
		c0,5.134-1.166,9.976-3.5,14.524c-1.779,3.413-4.725,6.797-8.837,10.121c-4.142,3.325-8.37,5.834-12.658,7.525
		c-4.316,1.662-9.975,2.508-17.062,2.508H359.738L359.738,183.936z M359.738,166.436h16.246c7.612,0,13.679-1.721,18.142-5.191
		c4.491-3.441,6.708-7.583,6.708-12.424c0-4.084-1.809-7.7-5.425-10.763c-3.617-3.063-8.779-4.608-15.487-4.608h-20.183V166.436
		L359.738,166.436z M458.523,201.961c17.5-14.846,29.254-25.171,35.291-30.916c7.7-7.292,12.541-13.009,14.583-17.15
		c2.013-4.112,3.034-8.604,3.034-13.445c0-9.508-3.529-17.616-10.617-24.296c-7.059-6.708-16.1-10.062-27.154-10.062
		c-7.116,0-13.707,1.545-19.773,4.607c-6.067,3.092-10.705,7.204-13.854,12.309c-3.149,5.133-4.754,9.42-4.754,12.833
		c0,2.274,0.875,4.259,2.624,5.979c1.722,1.691,3.792,2.566,6.184,2.566c2.042,0,3.792-0.525,5.162-1.546
		c1.4-1.021,2.509-2.741,3.297-5.134c1.313-3.82,3.063-6.679,5.221-8.633c4.141-3.646,9.361-5.454,15.633-5.454
		c6.212,0,11.17,1.634,14.904,4.899c3.732,3.297,5.6,7.059,5.6,11.346c0,3.413-1.633,7.029-4.959,10.85
		c-6.533,7.438-25.607,24.209-57.165,50.225v18.55h79.827v-8.37c0-4.288-0.787-7.292-2.361-9.07
		c-1.576-1.751-3.676-2.626-6.359-2.626c-1.896,0-3.879,0.846-5.979,2.538H458.523L458.523,201.961z M317.315,372.357l-25.199-60.49
		h4.083c4.229,0,7.204-0.816,8.983-2.45c1.75-1.604,2.654-3.732,2.654-6.358c0-2.624-0.904-4.725-2.713-6.328
		c-1.779-1.575-4.754-2.392-8.924-2.392l-23.654-0.088c-4.229,0-7.233,0.816-9.013,2.45c-1.809,1.604-2.712,3.733-2.712,6.357
		c0,3.18,1.371,5.659,4.113,7.438c1.313,0.904,4.024,1.371,8.108,1.371l35.904,86.04h16.829l35.962-86.04
		c4.054,0,6.737-0.467,8.049-1.371c2.713-1.837,4.084-4.316,4.084-7.438c0-2.624-0.875-4.725-2.684-6.328
		c-1.809-1.575-4.813-2.392-9.012-2.392l-23.509-0.088c-4.199,0-7.204,0.816-9.012,2.45c-1.779,1.604-2.683,3.733-2.683,6.357
		c0,2.626,0.904,4.755,2.683,6.358c1.809,1.634,4.813,2.45,9.012,2.45h4.025L317.315,372.357L317.315,372.357z M431.297,284.51
		l-37.683,9.741c-3.792,1.021-6.3,2.217-7.495,3.559c-1.196,1.342-1.809,3.179-1.809,5.512c0,2.567,0.816,4.726,2.449,6.476
		c1.604,1.778,3.529,2.654,5.746,2.654c1.254,0,3.15-0.321,5.658-1.021l15.633-4.083v73.032h-17.703c-4.2,0-7.204,0.816-9.013,2.449
		c-1.778,1.634-2.683,3.705-2.683,6.271c0,2.625,0.904,4.754,2.683,6.358c1.809,1.633,4.813,2.449,9.013,2.449h52.907
		c4.2,0,7.204-0.816,9.013-2.449c1.809-1.604,2.683-3.704,2.683-6.271c0-2.626-0.874-4.726-2.683-6.359
		c-1.809-1.633-4.813-2.449-9.013-2.449h-17.704V284.51L431.297,284.51z M123.97,244.447c-4.154,0-7.553,3.398-7.553,7.553
		c0,4.135,3.418,7.553,7.553,7.553H596.03c4.154,0,7.553-3.398,7.553-7.553c0-4.135-3.417-7.553-7.553-7.553H123.97L123.97,244.447z
		"
    />
    





    

		</svg>
		</div>

	);//return
}