export const BUTTON_ELEMENT_CR_APEX_BLUE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Background*/}

    <rect
        fill="rgb(0,0,0)" 
        x="188.0" y="72" width="344" height="360"/>
    />

{/*Swatch*/}

    <path 
        fill="rgb(0,131,202)" 
        d="M504.354,99.296H215.646v218.917h288.707V99.296z M504.354,404.704H215.646V344.46h288.707V404.704z"
    />

{/*CR Body*/}

    <path 
        fill="rgb(0,0,0)" 
        d="M356.485,187.912h-16.357c-0.902-6.358-3.549-11.296-7.942-14.81c-4.394-3.515-10.15-5.271-17.27-5.271 c-9.508,0-16.864,3.419-22.069,10.256c-5.205,6.838-7.807,16.495-7.807,28.974c0,12.576,2.636,22.473,7.909,29.691 c5.272,7.221,12.505,10.83,21.697,10.83c7.3,0,13.327-2.283,18.082-6.85c4.753-4.565,7.401-10.602,7.942-18.108h16.223 c-0.406,11.953-4.608,21.743-12.606,29.368c-7.999,7.627-18.104,11.439-30.315,11.439c-14.194,0-25.381-4.948-33.56-14.846	s-12.268-23.499-12.268-40.808c0-17.5,4.145-31.149,12.437-40.951c8.291-9.801,19.827-14.703,34.608-14.703	c11.986,0,21.64,3.181,28.964,9.539C351.472,168.022,355.583,176.772,356.485,187.912z M372.978,154.85h42.922 c1.307,0,2.748,0.048,4.326,0.144c0.495,0.048,0.788,0.071,0.879,0.071c8.787,0,15.67,2.606,20.649,7.817 c4.979,5.212,7.469,12.384,7.469,21.516c0,5.978-1.239,11.093-3.717,15.348c-2.479,4.257-5.994,7.315-10.545,9.181	c4.056,1.387,6.973,3.574,8.753,6.562c1.78,2.989,2.827,7.854,3.144,14.595l0.879,15.133v0.144	c0.27,6.599,1.645,10.614,4.123,12.049v3.084h-18.521c-0.722-1.386-1.274-3.023-1.656-4.913c-0.384-1.888-0.665-4.888-0.845-9 l-0.541-12.623c-0.361-6.788-1.701-11.307-4.021-13.555c-2.321-2.247-6.253-3.371-11.795-3.371h-3.178h-21.899v43.462h-16.426	V154.85z M389.268,169.695v32.488h23.726c6.938,0,11.918-1.291,14.938-3.872c3.019-2.582,4.529-6.766,4.529-12.551 c0-5.642-1.477-9.729-4.428-12.265c-2.952-2.533-7.83-3.801-14.634-3.801H389.268z M310.85,360.43h11.482
		c2.776,0,4.852,0.593,6.228,1.779c1.376,1.186,2.064,2.971,2.064,5.354c0,1.339-0.31,2.525-0.928,3.558	c-0.619,1.033-1.487,1.792-2.605,2.276c1.526,0.459,2.683,1.269,3.47,2.429s1.181,2.64,1.181,4.438	c0,2.563-0.826,4.594-2.479,6.092s-3.915,2.247-6.787,2.247H310.85V360.43z M315.158,364.351v7.536h6.273 c1.659,0,2.869-0.319,3.632-0.957c0.763-0.637,1.145-1.638,1.145-3.002c0-1.199-0.381-2.095-1.145-2.688s-1.919-0.89-3.47-0.89 H315.158z M315.158,375.712v8.74h6.94c1.707,0,2.983-0.366,3.831-1.1s1.271-1.846,1.271-3.338c0-1.479-0.427-2.565-1.28-3.261 c-0.854-0.694-2.211-1.042-4.074-1.042H315.158z M347.551,359.703c3.941,0,7.048,1.323,9.319,3.969s3.407,6.271,3.407,10.873	c0,4.604-1.135,8.228-3.407,10.873c-2.271,2.646-5.378,3.969-9.319,3.969c-3.954,0-7.066-1.319-9.337-3.959	c-2.271-2.64-3.407-6.267-3.407-10.883c0-4.615,1.136-8.243,3.407-10.883C340.485,361.022,343.597,359.703,347.551,359.703z M347.551,363.777c-2.584,0-4.603,0.946-6.057,2.84c-1.455,1.894-2.181,4.536-2.181,7.928c0,3.379,0.727,6.019,2.181,7.918 c1.454,1.9,3.473,2.85,6.057,2.85c2.571,0,4.584-0.949,6.039-2.85c1.454-1.899,2.181-4.539,2.181-7.918 c0-3.392-0.724-6.034-2.172-7.928S350.135,363.777,347.551,363.777z M364.748,360.43h10.257c3.894,0,6.817,1.17,8.77,3.51	c1.952,2.34,2.929,5.837,2.929,10.49c0,4.553-1.069,8.053-3.209,10.501c-2.139,2.448-5.215,3.672-9.229,3.672h-9.518V360.43z M369.092,364.427v20.025h5.426c2.632,0,4.57-0.835,5.813-2.506c1.244-1.67,1.865-4.258,1.865-7.765 c0-3.188-0.625-5.61-1.874-7.269c-1.25-1.657-3.083-2.486-5.498-2.486H369.092z M387.946,360.43h5.48l6.489,12.814l6.273-12.814 h5.101l-9.121,17.041v11.132h-4.435v-10.921L387.946,360.43z"
    />



    

		</svg>
		</div>

	);//return
}