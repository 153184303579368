import styled, { keyframes } from 'styled-components';


const fadeIn = keyframes`

    from {
        transform: scale(1.2);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
`;

const fadeOut = keyframes`

    from {
        transform: scale(1.2);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
`;





// For overall general use

export const Fade = styled.div.attrs(props => ({


    out:                    props.out || false,
    animationTime:          props.animationTime || '0.2s',
    transitionTime:         props.transitionTime || '0.2s'


    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;


}))`
  
    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    animation: ${props => props.out ? fadeOut : fadeIn} ${props => props.animationTime} linear;
 
`;



// For specific call

export const FadeTransform = styled.div.attrs(props => ({


    out:                    props.out || false,
    animationTime:          props.animationTime || '1s',
    transitionTime:         props.transitionTime || '1s'


    // TM: - for reference - transitions won't work on visibility - must be numeric value for keyframes timing
    //transition: visibility ${props => props.transitionTime} linear;


}))`


    width:${props => props.width}
    height:${props => props.height}
  
    display: inline-block;
    visibility: ${props => props.out ? 'hidden' : 'visible'};
    animation: ${props => props.out ? fadeOut : fadeIn} ${props => props.animationTime} linear;
    transition: visibility ${props => props.transitionTime} linear;

`;









// export const Fade = styled.div.attrs`

//     ${props => props.out ? `display: none;` : `display: inline-block;`}
//     animation: ${props => props.out ? fadeOut : fadeIn} 1s linear infinite;
// `;

// export const Fade = styled.div`

//     ${props => props.out ? `display: none;` : `display: inline-block;`}
//     animation: ${props => props.out ? fadeOut : fadeIn} 1s linear infinite;
// `;


// export const Fade = styled.div`

//     display: inline-block;
//     visibility: ${props => props.out ? 'hidden' : 'visible'};
//     animation: ${props => props.out ? fadeOut : fadeIn} 1s linear;
//     transition: visibility 1s linear;

// `;



