import React, { 

    useState, 
    useEffect, 
    useRef,
    //useMemo

} from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import { Group, Member } from './StylAttrs';

import Main  from './Main';
import { 
    Nav, 
    //SubNav 
} from './Navigation';

const Index = (props) => {


    // -- redux -- //
    // const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    // //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- ref -- //
    const refMain = useRef(null);
    const refNav = useRef(null);
    const refSubNav = useRef(null);

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [groupHeight, setGroupHeight] = useState("auto");


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            
            var w = refMain.current.offsetWidth;
            var h = refMain.current.offsetHeight + refNav.current.offsetHeight + refSubNav.current.offsetHeight;


            setGroupHeight(h);

            // dispatch(ModifyZoneStore({

            //     type:"UPDATE_ZONE",
            //     item:{
            //         key:"headerHeight",
            //         value:h
            //     }

            // })); 


            // props.handle({

            //     call:'dispatch',
            //     data:{

            //         action  :"UPDATE_ZONE",
            //         key     :"headerHeight",
            //         value   :h

            //     }


            // });


            props.databind.handle({

                call:'headerSizes',
                data:{

                    groupW      :w,
                    groupH      :h,
                    mainW       :w,
                    mainH       :refMain.current.offsetHeight,
                    navW        :w,
                    navH        :refNav.current.offsetHeight,
                    subnavW     :w,
                    subnavH     :refSubNav.current.offsetHeight,

                }


            });




        }

    },[
    
        props,
        isLoaded,
        refMain,
        refNav,
        refSubNav

    ])

    


    // TM - this page renders the order of the header ( Main,Nav,SubNav - OR - Nav,Main,SubNav)


    let headerCall = props.databind.headerCall;
    // if(props.databind.settings.appearance)
    // {
    //     if(props.databind.settings.appearance.header)
    //     {
    //         headerCall = props.databind.settings.appearance.header.headerCall;
    //     }
    // }




    return (<div>



        {

        headerCall === 'A'
        ?


            <Group
            height={groupHeight}
            >
                <Member ref={refMain} >

                    <Main 
                    
                        handle = {props.databind.handle}
                        databind = {props.databind}

                    /> 
            
                </Member>

                <Member ref={refNav} >

                    <Nav 
                    
                        handle = {props.databind.handle}
                        databind = {props.databind}
                    
                    /> 
            
                </Member>

                <Member ref={refSubNav} >

                    {/* <SubNav />  */}

                </Member>

            </Group>



        :
        headerCall === 'B'
        ?


        

            <Group
            height={groupHeight}
            >

                <Member ref={refNav} >

                    <Nav 

                        handle = {props.databind.handle}
                        databind = {props.databind}
                    /> 

                </Member>


                <Member ref={refMain} >

                    <Main 

                        handle = {props.databind.handle}
                        databind = {props.databind}

                    /> 

                </Member>


                <Member ref={refSubNav} >

                    {/* <SubNav />  */}

                </Member>

            </Group>



        :
        headerCall === 'C'
        ?



            <Group
            height={groupHeight}
            >
                <Member ref={refMain} >

                <Main 

                    handle = {props.databind.handle}
                    databind = {props.databind}

                /> 

                </Member>

                <Member ref={refNav} >
                </Member>

                <Member ref={refSubNav} >
                </Member>

            </Group>



        :
        null

        }



    </div>);







}

export default Index



