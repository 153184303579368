


//import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 
    VPindirectLabor,
    VPidlEmployee

} from 'app-components/Prime/VPdata/form.js';



//import { LibBase } from "library";


const SIform = (props) => {


    const form_items = props.form_items;
    const result_items = props.result_items;




    //var actual_monthly_expenses;
    //var projected_monthly_sales;
    //var overhead_percent;
    var idl_employees = {};

    //actual_monthly_expenses = VPmonthlyExpenses.value;
    //projected_monthly_sales = VPmonthlySales.value;
    //projected_monthly_sales = VPoverhead.settings.value.projected_monthly_sales;
    //overhead_percent = 75.5 + "%";



    var keyname;
    var kn;
    var item;
    //var name;
    var value;
    var newValue;




    if(form_items 
    && form_items !== undefined
    )
    {


        //console.log("FORM ITEMS: "+JSON.stringify(form_items));

        for(keyname in form_items)
        {
            if(keyname.match(/idl/gi))
            {

                //idl_employees[keyname] = form_items[keyname];

                
                //order card item based on Prime VPdata
                item = form_items[keyname];
          
                value = VPidlEmployee.value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                idl_employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                





            }
            else
            {
                //console.log("KEYNAME: "+keyname);

                item = form_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                value = VPoverhead[keyname].value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                VPoverhead[keyname] = {
                    name:item.name,
                    value:newValue
                };
 

            }

        }// -- for

        


        VPexpenses.value.monthly_expenses.value = result_items['totals'].total;
        VPincome.value.monthly_sales.value = form_items['settings'].value.projected_monthly_sales.value;


        //overhead_percent = result_items['overhead'].overhead;
        //overhead_percent = parseFloat(overhead_percent) 
        //overhead_percent = overhead_percent.toFixed(1) + "%";



        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */


    /*

        "total_idl_rate_yearly": "31200.00",
        "total_idl_burden_tax_monthly": "520.00",
        "total_idl_health_insurance_monthly": "500.00",
        "total_idl_rate_monthly": "2600.00",
        "total_idl_burden_ira_monthly": "52.00"

      
    */


        
        var total_idl_rate_yearly = result_items['totals'].total_idl_rate_yearly;
        var total_idl_rate_monthly = result_items['totals'].total_idl_rate_monthly;
        var total_idl_health_insurance_monthly = result_items['totals'].total_idl_health_insurance_monthly;
        var total_idl_burden_ira_monthly = result_items['totals'].total_idl_burden_ira_monthly;
        var total_idl_burden_tax_monthly = result_items['totals'].total_idl_burden_tax_monthly;  


        VPindirectLabor.value.idl_rate_yearly.value = total_idl_rate_yearly;
        VPindirectLabor.value.idl_rate_monthly.value = total_idl_rate_monthly;
        VPindirectLabor.value.idl_health_insurance_monthly.value = total_idl_health_insurance_monthly;
        VPindirectLabor.value.idl_burden_ira_monthly.value = total_idl_burden_ira_monthly;
        VPindirectLabor.value.idl_burden_tax_monthly.value = total_idl_burden_tax_monthly;
        

        
    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));





    var R = [];
    //var index;
    //index = 0;
    for(keyname in VPoverhead)
    {

        switch(keyname)
        {
        case'settings':
        case'work_schedule':
        case'rates':
        break;
        default:

            R.push({

                keyname:keyname,
                name:VPoverhead[keyname].name,
                value:VPoverhead[keyname].value,
        
            });

            //index++

        break;
        }




    }


    


    //--------------------------
    // Indirect Labor
    //--------------------------

    R.push({

        keyname:"IDLS",
        name:VPindirectLabor.name,
        value:VPindirectLabor.value,

    });




    
    
    
 


    return R;


}



export default SIform;
