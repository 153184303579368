


const Handler = (props) => {


    var frm;
    var ij;
    var indx;

    var scrollbox;
    var scrollpos;

    var newArr;
    var newChoice;
    var object;


    //console.log(JSON.stringify(props,null,2));
    //alert("PROPS: "+JSON.stringify(props,null,2));


    switch(props.call)
    {
    case'button':
    break;
    case'capture':

        switch(props.fieldCall)
        {
        case'clicker':


            console.log("Clicker");


            frm = props.formArr[props.key];

            newArr = props.newValue;

            newChoice = [];
            for(var i=0; i < newArr.length; i++)
            {
                object = newArr[i];
                if(object['innerColor'] === 'orangered') 
                {
                    newChoice.push(object['clickerVal']);

                }//==

            }//== i

            frm['data']['choice'] = newChoice;


            props.formArr[props.key] = frm;
            props.handleState({call:'formArr',value:props.formArr});


        
        break;
        case'slider':
        case'dualslider':

         
            console.log("Slider / DualSlider"
            +"\nKEY: "+props.key
            +"\nNEW VALUE: "+props.newValue
            );

            frm = props.formArr[props.key];
            frm.data['value'] = props.newValue;

            props.formArr[props.key] = frm;
            props.handleState({call:'formArr',value:props.formArr});

        break;
        case'field':

            console.log("Field");
            console.log(props['key']);
            console.log(props['id']);

            ij = props.key.split("-");
        
            frm = props.formArr[ij[0]];
            if(frm.section === 'fields')
            {

                frm.data[ij[1]]['text'] = props.newValue;

                //var fields = frm.data;
                //var field = fields[ij[1]];
                //field['text'] = props.newValue;
                
                props.formArr[ij[0]] = frm;
                props.handleState({call:'formArr',value:props.formArr});

            }


        break;
        case'labelfield':

            console.log("Label Field");
            console.log(props['key']);
            console.log(props['id']);

            ij = props.key.split("-");
        
            frm = props.formArr[ij[0]];
            if(frm.section === 'labelfields')
            {

                frm.data[ij[1]].field.text = props.newValue;

                props.formArr[ij[0]] = frm;
                props.handleState({call:'formArr',value:props.formArr});

            }

        break;
        default:
        }


    break;
    case'resetProblemArr':

        console.log(JSON.stringify(props.errors,null,2));

        props.handleState({call:'problemArr',value:props.errors});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :"problemArr",
            value   :props.errors

        }});


    break;
    case'clear':

        props.handleState({call:'problemArr',value:[]});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :"problemArr",
            value   :[]

        }});

    break;
    case'error':


        //alert(JSON.stringify(props.errors,null,2));


        props.handleState({call:'hashData',value:false});
        props.handleState({call:'processSubmit',value:false});
        props.handleState({call:'processError',value:true});
        props.handleState({call:'errorType',value:props.type});
        props.handleState({call:'problemArr',value:props.errors});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :"problemArr",
            value   :props.errors

        }});
        

        // switch(props.type)
        // {
        // case'input':
        //     props.handleState({call:'problemArr',value:JSON.parse(JSON.stringify(props.errors))});
        // break;
        // default:
        //     props.handleState({call:'problemArr',value:props.errors});
        // }

  

        /*
        if(props.location.hash === "#!")
        {
            //history.goBack();  
            props.handleRoute({call:'goBack',value:""});

        }
        */
   
        scrollbox = document.getElementById('scrollbox');

        if(props.errors
        && props.errors !== null
        && props.errors !== undefined
        )
        {
            if(props.errors.length > 0)
            {

                var keystr = props.errors[0]['key'].toString();
                var firstkey = keystr;
                if(firstkey.match(/-/gi))
                {
                    indx = firstkey.split("-")[0];
                }else{
                    indx = firstkey;
                }//==

                var formSection = document.getElementById("formSection_"+indx);

                //alert(formSection+" "+indx);

                if(formSection !== null)
                {
                    //alert(formSection.offsetTop);
                    scrollbox.scrollTop = formSection.offsetTop;
                }else{
                    scrollbox.scrollTop = 0;
                }//==

            
            }else{

                scrollbox.scrollTop = 0;

            }
            
        }




        

    break;
    case'url':
        
        props.handleState({call:'handleUrl',value:{

            url :props.data.url
    

        }});

    break;
    case'submit':


        props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'processSubmit',value:true});
    
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});
        }

    break;
    case'complete':


        props.handleState({call:'hashData',value:"#!"});
        props.handleState({call:'processComplete',value:true});
     
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }

    break;
    default:


        // remove all flyrs and reset
        
        scrollbox = document.getElementById('scrollbox');
        scrollpos = scrollbox.scrollTop;

        props.handleState({call:'hashData',value:false});   
        props.handleState({call:'processSubmit',value:false});
        props.handleState({call:'processComplete',value:false});
        props.handleState({call:'problemArr',value:[]});
        props.handleState({call:'processError',value:false});
        props.handleState({call:'errorType',value:''});


        switch(props.action)
        {
        case'reload':

            //window.location.reload();//regular dom 
            props.handleRoute({call:"reload",value:""});
        break;
        case'redirect':

            //history.replace("/");
            props.handleRoute({call:"redirect",value:"/"});
        break;
        default:

            if(props.location.hash === "#!")
            {
                //history.goBack(); 
                props.handleRoute({call:"goBack",value:""});
            }

        break;
        }//==

        scrollbox.scrollTop = scrollpos;

    break;
    }//==


}


export default Handler;

