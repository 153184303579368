export const BUTTON_HELMET_GRAY_DRIVER_STEVE_MCQUEEN = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Background Black*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M349.306,75.195c81.227,0,145.155,48.81,158.675,121.745l19.111,102.742 c23.525,6.317,9.076,41.796,1.963,58.75l-10.82,25.488c-4.961,10.244-14.554,27.163-25.429,26.88l-33.152-1.739 c-16.75-0.975-34.45-3.137-50.977-5.984l-199.213-35.126C194.35,328.565,180,284.35,180,234.298 C180,136.64,251.643,75.195,349.306,75.195L349.306,75.195z"
    />
    {/*Base Color*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c132.609,0,152.695,114.836,152.695,114.836l19.797,106.813c19.057,0,11.521,27.184,1.473,50.857l-10.768,25.363 c-3.305,7.789-11.814,10.408-19.673,9.996l-33.445-1.75c-12.987-0.686-37.557-4.046-49.46-6.144l-194.665-34.325 C198.848,306.577,186.258,274.046,186.258,234.292L186.258,234.292z"
    />
    {/*Hightlight Base*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M349.291,89.858c70.36,1.903,130.402,42.188,146.149,108.603 l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154l-10.701,25.205 c-2.614,5.388-8.206,6.501-13.748,6.352l-33.347-1.749c-9.329-0.557-19.007-1.621-28.326-2.898l10.309-24.235 c3.187-7.6,25.016-56.836,8.127-59.809l-3.534-0.626l-18.067-97.489c-1.342-7.252-12.938-78.463-83.552-108.409 C346.149,89.968,347.66,89.897,349.291,89.858L349.291,89.858z"
    /> 
    {/*Highlight*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M349.291,89.858c70.36,1.903,130.402,42.188,146.149,108.603 l20.339,109.736l6.779,1.197l0.398,2.162c12.137,1.81-3.246,36.945-5.434,42.154l-10.701,25.205 c-2.614,5.388-8.206,6.501-13.748,6.352l-24.782-1.302l9.976-23.49c3.34-7.958,26.428-54.231,8.732-57.349l-0.695-0.124 L466.93,198.461c-4.776-25.767-23.648-90.202-118.822-108.573C348.495,89.878,348.883,89.868,349.291,89.858L349.291,89.858z"
    />
    {/*Stripe*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M349.292,89.858c70.36,1.903,130.402,42.188,146.148,108.603l20.339,109.736 l6.779,1.197l0.397,2.162c12.138,1.81-3.245,36.945-5.433,42.154l-10.701,25.205c-2.614,5.388-8.206,6.501-13.748,6.352 l-8.768-0.531l15.806-35.364c8.932-19.991,14.121-41.448,2.854-43.432l-3.668-0.695l-19.375-104.537 c-4.776-25.767-36.642-92.449-131.814-110.82C348.496,89.878,348.884,89.868,349.292,89.858L349.292,89.858z"
    />
    {/*Shadow Shape*/}
    <path 
        fill="rgb(190,190,190)" 
        d="M379.645,371.495l-155.17-27.362 c-13.698-35.747-31.597-70.917-31.984-109.801c0.939-40.369,15.274-72.831,38.178-96.688
		c-5.095,39.256-26.716,85.729,32.983,205.759l119.016,20.975L379.645,371.495L379.645,371.495z"
    />
    {/*Roof Shadow*/}
    <path 
        fill="rgb(230,230,230)" 
        d="M186.258,234.292c0-59.346,44.639-150.667,163.043-150.667	c17.078,0,32.282,1.919,45.827,5.239c-15.503-0.944-30.235-1.839-45.648-0.463c-52.1,6.795-73.859,38.118-89.005,84.621	l-47.581,170.116C197.654,303.405,186.258,272.122,186.258,234.292L186.258,234.292z"
    />
    {/*Roof Double Shadow*/}
    <path 
        fill="rgb(170,170,170)" 
        d="M217.517,326.627c-12.123-29.842-24.703-59.773-25.026-92.295 c0.939-40.369,15.274-72.831,38.178-96.688c-3.882,29.911-17.352,64.028,2.873,131.686L217.517,326.627L217.517,326.627z"
    />


    {/*Visor Black Background*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M352.859,173.311c6.859-6.71,14.255-9.394,23.356-9.553 c7.043,0.442,12.689,2.276,18.932,5.82l24.777,15.185c7.689,4.448,16.89,7.257,25.797,7.471h53.247 c11.924,0.869,17.482,5.949,19.932,17.471l16.164,87.21c1.252,7.61-0.268,13.843-9.125,14.594h-19.743 c-6.362-0.08-12.596-0.93-18.854-1.998l-77.563-13.674c-21.8-14.643-36.775-34.216-49.196-57.085
		c-3.788-7.167-7.282-14.464-10.637-21.84C343.326,202.318,341.756,186.273,352.859,173.311L352.859,173.311z"
    />
    {/*Visor Color Base*/}
    <path 
        fill="rgb(253,185,19)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.679,212.269c-14.255-31.616,12.992-52.254,37.298-37.327 l24.763,15.174c8.519,5.22,21.06,8.346,28.907,8.346h53.094c10.597,0,12.813,5.815,14.047,12.456l16.139,87.071 c0.924,4.985,0.637,7.286-3.246,7.286l-26.373,0.02l-87.146-15.368C381.081,269.881,364.426,233.87,354.679,212.269 L354.679,212.269z M380.426,177.307c-11.537,0-20.945,9.409-20.945,20.945s9.408,20.945,20.945,20.945 c11.541,0,20.944-9.409,20.944-20.945S391.967,177.307,380.426,177.307L380.426,177.307z"
    />
    {/*Visor Highlight Base*/}
    <path 
        fill="rgb(255,209,0)" 
        d="M498.74,198.461h-53.094h-0.517l18.639,100.57l35.538,6.263 l22.486-0.015l-19.757-106.6C501.037,198.535,499.943,198.461,498.74,198.461L498.74,198.461z"
    />
    {/*Visor Hightlight*/}
    <polygon 
        fill="rgb(255,242,0)" 
        points="479.505,198.461 495.44,198.461 515.242,305.274 499.208,305.274 479.505,198.461"
    />
    {/*Visor Rivet*/}
    <path 
        fill="rgb(150,150,150)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.426,179.753c10.188,0,18.504,8.31,18.504,18.499 s-8.315,18.5-18.504,18.5c-10.189,0-18.5-8.311-18.5-18.5S370.236,179.753,380.426,179.753L380.426,179.753z M380.426,186.582 c-6.427,0-11.671,5.243-11.671,11.67c0,6.432,5.244,11.676,11.671,11.676c6.432,0,11.675-5.244,11.675-11.676 C392.101,191.825,386.857,186.582,380.426,186.582L380.426,186.582z"
    /> 
    {/*Visor Slot*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M423.31,206.692c0-3.808-3.111-6.919-6.924-6.919 c-3.812,0-6.924,3.111-6.924,6.919c0,1.595,0.563,3.047,1.472,4.22c-2.913,7.187-8.177,13.221-15.07,16.536 c-7.704,3.459-3.568,10.885,2.396,8.042c9.414-4.533,16.8-12.56,20.612-22.352C421.461,212.135,423.31,209.63,423.31,206.692z"
    />

    
    {/*Flag & Name Black*/}
    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.969,280.109c-2.733,1.406-5.835,2.212-9.11,2.212	c-11.024,0-20.021-8.996-20.021-20.025c0-5.373,2.142-10.254,5.606-13.862c-0.358-0.602-0.711-1.208-1.063-1.814 c-4.057,4.001-6.581,9.553-6.581,15.677c0,12.152,9.906,22.063,22.059,22.063c3.832,0,7.44-0.994,10.587-2.724
		C385.948,281.128,385.456,280.621,384.969,280.109L384.969,280.109z M389.124,284.289c-3.877,2.347-8.42,3.708-13.266,3.708	c-14.15,0-25.702-11.546-25.702-25.701c0-7.48,3.23-14.23,8.37-18.938c-0.1-0.174-0.198-0.343-0.288-0.517 c-0.343-0.611-0.676-1.218-1.009-1.834c-5.93,5.198-9.688,12.818-9.688,21.288c0,15.602,12.714,28.321,28.316,28.321	c5.602,0,10.83-1.646,15.229-4.469c-0.313-0.293-0.632-0.581-0.939-0.875C389.8,284.945,389.462,284.617,389.124,284.289 L389.124,284.289z M284.736,306.497c-0.05,0.318-0.109,0.656-0.164,1.009c-0.064,0.363-0.199,0.801-0.209,1.283l0.005,7.103 l5.323-5.587c0.492-0.517,0.73-1.108,0.999-1.556c0.283-0.442,0.542-0.88,0.785-1.308l8.888,1.253	c-0.433,1.148-0.91,2.455-1.437,3.916c-0.114,0.323-0.189,0.537-0.229,0.656l-4.498,12.601c-0.035,0.109-0.104,0.313-0.219,0.621 c-0.104,0.303-0.189,0.557-0.259,0.75c-0.149,0.473-0.309,1.004-0.497,1.605c-0.174,0.606-0.357,1.258-0.552,1.959l-6.158-0.865 c0.259-0.571,0.521-1.168,0.785-1.805c0.264-0.631,0.507-1.302,0.746-1.963l2.883-7.674c0.06-0.159,0.308-0.855,0.735-2.078 c0.428-1.228,0.805-2.316,1.143-3.25l-7.426,8.354c-0.507,0.562-0.735,1.113-0.969,1.486c-0.233,0.363-0.452,0.73-0.651,1.094 l-5.104-0.716c0.06-0.338,0.124-0.716,0.194-1.113c0.064-0.408,0.179-0.855,0.179-1.322l0.09-9.559l-4.345,10.702 c-0.259,0.655-0.562,1.436-0.914,2.36c-0.358,0.93-0.786,2.098-1.313,3.52l-5.497-0.771c1.263-2.659,2.659-5.855,4.189-9.573
		c1.526-3.718,3.127-7.833,4.797-12.356L284.736,306.497L284.736,306.497z M319.206,316.92c-0.706-0.428-1.441-0.761-2.202-1.028	c-0.751-0.249-1.516-0.438-2.296-0.547c-2.118-0.299-3.962,0.089-5.532,1.163c-1.586,1.073-2.734,2.714-3.439,4.926	c-0.671,2.067-0.642,3.663,0.079,4.796c0.73,1.134,2.172,1.85,4.359,2.162c0.785,0.104,1.585,0.164,2.405,0.154	c0.815,0,1.68-0.069,2.6-0.194l-2.371,5.105c-0.954,0.099-1.913,0.139-2.883,0.119c-0.964-0.01-1.923-0.09-2.883-0.229 c-3.703-0.513-6.208-1.859-7.535-4.017c-1.327-2.167-1.406-5.06-0.238-8.668c1.223-3.813,3.23-6.631,5.999-8.45 s6.044-2.455,9.837-1.929c0.944,0.14,1.879,0.328,2.788,0.577c0.915,0.243,1.824,0.562,2.719,0.935L319.206,316.92L319.206,316.92z	 M356.906,316.637c-0.328,0.735-0.646,1.486-0.965,2.247c-0.313,0.771-0.605,1.53-0.879,2.281l-3.227,8.534	c-0.044,0.114-0.099,0.248-0.153,0.397s-0.135,0.378-0.224,0.676c-0.358,1.099-0.333,1.948,0.079,2.56 c0.397,0.616,1.193,0.999,2.386,1.168c1.303,0.185,2.376,0,3.201-0.546c0.83-0.558,1.511-1.536,2.043-2.958l2.893-7.714 c0.407-1.078,0.711-1.898,0.904-2.466c0.204-0.566,0.383-1.103,0.542-1.604c0.139-0.428,0.243-0.751,0.309-0.97 c0.069-0.219,0.124-0.417,0.174-0.606l7.271,1.019c-0.313,0.691-0.637,1.402-0.939,2.152c-0.313,0.731-0.646,1.541-0.979,2.406
		l-2.912,7.659c-0.13,0.318-0.289,0.771-0.502,1.347c-0.811,2.207-1.566,3.748-2.292,4.643c-1.049,1.268-2.584,2.138-4.582,2.619	c-2.014,0.482-4.369,0.532-7.088,0.145c-3.191-0.442-5.492-1.387-6.884-2.818c-1.397-1.446-1.755-3.221-1.069-5.338	c0.169-0.537,0.915-2.555,2.227-6.06c1.313-3.494,2.466-6.755,3.46-9.762L356.906,316.637L356.906,316.637z M366.459,341.171 c1.184-2.685,2.466-5.86,3.843-9.538c1.387-3.669,2.867-7.839,4.434-12.481l18.28,2.565l-1.934,5.095 c-0.899-0.164-1.689-0.313-2.391-0.442c-0.695-0.13-1.168-0.204-1.411-0.244l-7.217-0.994c-0.354,0.835-0.622,1.467-0.78,1.874 c-0.154,0.413-0.289,0.79-0.403,1.144l-0.139,0.417l5.915,0.835c0.765,0.104,1.456,0.194,2.063,0.259	c0.616,0.064,1.188,0.109,1.71,0.14l-1.954,5.174c-0.362-0.085-0.76-0.159-1.183-0.233c-0.423-0.075-1.073-0.174-1.953-0.299 l-1.576-0.224l-4.915-0.681c-0.214,0.447-0.408,0.885-0.587,1.317c-0.179,0.438-0.333,0.875-0.478,1.316 c-0.089,0.269-0.139,0.458-0.174,0.592c-0.044,0.13-0.069,0.244-0.094,0.354c0.631,0.164,1.332,0.317,2.082,0.452	c0.756,0.144,1.69,0.298,2.799,0.452c1.163,0.159,2.286,0.298,3.354,0.397c1.079,0.104,2.093,0.188,3.052,0.229l-1.934,5.12	L366.459,341.171L366.459,341.171z M387.757,344.163c1.184-2.685,2.461-5.86,3.843-9.538c1.387-3.674,2.862-7.834,4.434-12.481
		l18.276,2.565l-1.924,5.1c-0.899-0.169-1.705-0.318-2.396-0.447c-0.696-0.125-1.163-0.209-1.417-0.239l-7.217-1.004	c-0.358,0.84-0.621,1.472-0.78,1.884c-0.149,0.402-0.283,0.785-0.397,1.139l-0.14,0.427l5.905,0.825 c0.775,0.109,1.466,0.194,2.067,0.254c0.606,0.064,1.183,0.109,1.715,0.139l-1.953,5.18c-0.368-0.09-0.766-0.164-1.188-0.233 c-0.422-0.07-1.068-0.169-1.958-0.289l-1.571-0.229l-4.915-0.686c-0.209,0.447-0.402,0.885-0.577,1.313 c-0.179,0.442-0.348,0.88-0.481,1.321c-0.085,0.269-0.149,0.463-0.18,0.587c-0.039,0.14-0.064,0.249-0.094,0.353 c0.636,0.175,1.342,0.318,2.087,0.463c0.756,0.144,1.69,0.288,2.799,0.447c1.168,0.164,2.276,0.298,3.35,0.402 c1.079,0.104,2.098,0.184,3.052,0.219l-1.928,5.125L387.757,344.163L387.757,344.163z M432.585,332.611l0.035-0.109 c0.467-1.307,0.805-2.286,1.014-2.938c0.085-0.248,0.189-0.586,0.323-1.043c0.139-0.458,0.238-0.791,0.303-1.01l6.427,0.905 c-0.462,0.994-0.845,1.844-1.143,2.564c-0.299,0.716-0.572,1.392-0.806,1.998l-4.722,12.54c-0.179,0.442-0.323,0.865-0.462,1.258 c-0.145,0.393-0.278,0.795-0.408,1.203c-0.079,0.219-0.184,0.596-0.353,1.152s-0.298,0.989-0.402,1.303l-8.962-1.253	c-0.229-2.177-0.467-4.244-0.716-6.192l-0.034-0.259l-1.517-10.309l-3.271,8.772c-0.283,0.78-0.78,2.183-1.481,4.24	c-0.393,1.123-0.7,2.008-0.924,2.629l-6.437-0.909c0.75-1.631,1.694-3.927,2.857-6.899c1.163-2.967,3.042-8.002,5.637-15.09	l8.718,1.223l2.505,16.919L432.585,332.611L432.585,332.611z M325.612,323.834c-0.695,2.152-0.766,3.822-0.219,5.02	c0.552,1.184,1.72,1.914,3.499,2.168c0.323,0.044,0.642,0.069,0.945,0.064l-0.259-2.147l2.152-1.034l0.819,2.485 c0.299-0.159,0.587-0.357,0.87-0.581c1.308-1.044,2.312-2.645,3.007-4.792c0.642-1.993,0.677-3.578,0.104-4.741 c-0.581-1.163-1.739-1.869-3.494-2.117c-1.675-0.234-3.171,0.148-4.483,1.143C327.243,320.295,326.264,321.806,325.612,323.834
		L325.612,323.834z M334.236,335.515l2.649,8.052l-5.14,3.29l-1.282-10.587c-1.108,0.064-2.267,0.021-3.489-0.148 c-3.668-0.518-6.188-1.895-7.575-4.126c-1.392-2.236-1.511-5.104-0.388-8.623c1.213-3.738,3.206-6.526,5.979-8.36 c2.778-1.834,6.01-2.49,9.678-1.974c3.807,0.537,6.432,1.898,7.843,4.096c1.427,2.207,1.556,5.1,0.402,8.668	c-1.212,3.778-3.226,6.571-6.023,8.4C336.051,334.739,335.166,335.182,334.236,335.515L334.236,335.515z M310.006,298.236 c-21.999-5.92-47.278-9.17-66.37-10.338c11.094-12.237,11.676-34.027-0.422-43.834c20.438,7.252,39.946,8.499,50.161,10.145 c-1.998-6.184-4.071-12.903-6.218-16.954c-2.147-4.051,2.067-3.698,8.126-2.6c13.36,2.51,39.296,8.111,56.062,7.565 c-3.798,5.243-7.223,12.848-7.223,20.075c0,7.227,3.857,16.238,8.505,21.76c-13.098-3.663-27.243-6.601-40.34-8.285 c-0.562-0.069-1.248,0.552-0.949,1.416l5.397,15.488C318.645,298.137,317.208,300.175,310.006,298.236L310.006,298.236z"
    />
    {/*Flag Gray*/}
    <path 
        fill="rgb(150,150,150)" 
        d="M308.539,275.78l-0.502-0.259	c-5.378-0.974-5.597,2.391-5.397,3.932c0.462,3.599,8.534,7.023,11.184,11.602L308.539,275.78L308.539,275.78z"
    />
    {/*Flag White*/}
    <path 
        fill="rgb(255,255,255)" 
        d="M268.175,263.314l-14.051-2.465 c-0.388-4.33-1.094-7.824-2.903-11.671c4.488,1.382,9.275,2.471,13.763,3.261L268.175,263.314L268.175,263.314z M288.211,266.828 l4.03,13.067l-20.04-3.514l-4.026-13.067L288.211,266.828L288.211,266.828z M284.88,255.531l9.454,1.247l3.663,11.765l-9.786-1.72 L284.88,255.531L284.88,255.531z M309.906,295.334c-4.662-1.228-9.324-2.456-13.986-3.679l-3.679-11.76l8.877,1.565 c1.681,4.419,6.899,6.854,10.259,10.344C313.867,294.578,313.206,296.198,309.906,295.334L309.906,295.334z M272.201,276.382 l3.653,11.765c-5.791-1.089-12.943-1.744-20.125-2.366l-3.022-9.771c0.273-0.964,0.507-1.938,0.706-2.923L272.201,276.382	L272.201,276.382z M336.294,277.077l-3.703-11.228l9.772,1.779c0.666,3.917,2.584,8.399,4.841,12.068L336.294,277.077 L336.294,277.077z M297.689,259.811l16.814,2.883l3.813,11.571l-9.802-1.457c-2.654-0.243-4.066-0.681-6.288,0.875L297.689,259.811 L297.689,259.811z M342.557,244.805l1.744,5.786c-0.496,1.332-1.321,3.752-1.521,5.536l-14.195-2.693l-3.41-10.646	C329.122,243.696,337.338,244.586,342.557,244.805L342.557,244.805z M310.503,250.277l18.082,3.156l4.006,12.416l-18.087-3.156
		L310.503,250.277L310.503,250.277z M306.979,239.188l3.524,11.089l-16.82-2.883l-2.515-8.052c-0.965-2.312,0.333-3.112,4.096-2.242 L306.979,239.188L306.979,239.188z"
    />
    

    

		</svg>
		</div>

	);//return
}