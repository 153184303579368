export const HARDTOP_OEM_SOLID_ORANGE_IMOLA_ORANGE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
    <defs>
        <linearGradient id="Gradient1" x1="0%" y1="0%" x2="0%" y2="100%" gradientTransform="rotate(7)">
            <stop offset="0%" style={{"stopColor":"rgb(45,60,65)","stopOpacity":"1"}} />
            <stop offset="100%" style={{"stopColor":"rgb(90,105,115)","stopOpacity":"1"}} />
        </linearGradient>
    </defs> 


    
    {/*Hardtop Background*/}
    <path 
        fill="rgb(244,121,32)" 
        d="M388.614,143.384l-84.655-5.178 c-18.273-1.117-27.085,5.95-31.021,20.143l-10.821,39.02l-110.999-7.762l70.952-59.536c25.756-21.611,57.809-19.651,90.203-17.67 l58.368,3.57L388.614,143.384L388.614,143.384z"
    />
    {/*Hardtop Highlight*/}
    <path 
        fill="rgb(247,148,30)" 
        d="M314.215,116.748l51.524,3.151 c2.567,0.157,4.228,1.456,4.986,2.614l3.368,5.136c1.175,1.792,0.282,2.982-1.493,2.874l-69.825-4.271
		c-18.301-1.119-31.311,5.039-39.139,21.044c-1.647,3.368-3.912,4.024-7.766,3.928h-24.02l-28.012,36.505l-10.797-0.754 c5.654-2.935,8.23-6.293,9.328-7.723l27.365-35.663c3.301-4.303,8.141-9.425,6.455-15.212 C254.469,113.571,288.219,115.158,314.215,116.748L314.215,116.748z"
    />
    {/*Glass Background*/}
    <path 
        fill="rgb(60,70,80)" 
        d="M223.531,143.823l-25.127,32.747 c-3.97,5.173-16.656,12.561-28.822,11.711l-15.585-1.09l68.073-57.121C233.545,130.772,227.795,138.584,223.531,143.823 L223.531,143.823z"
    />
    {/*Glass Highlight*/}
    <path 
        fill="rgb(90,100,120)" 
        d="M223.531,143.823l-5.679,7.4c-7.452,0-16.197,1.489-24.78,3.18	l28.998-24.333C233.545,130.772,227.795,138.584,223.531,143.823L223.531,143.823z"
    />
    {/*Defroster Lines*/}
    <path 
        fill="rgb(245,130,30)" 
        d="M220.121,140.949c0.41,0.037,0.713,0.401,0.676,0.813c-0.037,0.41-0.4,0.713-0.812,0.676l-11.408-1.045 l1.612-1.353L220.121,140.949L220.121,140.949z M212.323,148.831c0.411,0.038,0.714,0.401,0.677,0.813s-0.4,0.714-0.812,0.676 l-12.847-1.177l1.612-1.354L212.323,148.831L212.323,148.831z M204.756,156.822c0.41,0.037,0.713,0.4,0.676,0.812 s-0.4,0.714-0.811,0.676l-14.608-1.339l1.612-1.354L204.756,156.822L204.756,156.822z M197.876,165.117 c0.411,0.037,0.714,0.401,0.677,0.813c-0.037,0.41-0.4,0.713-0.812,0.676l-17.314-1.592l1.612-1.353L197.876,165.117	L197.876,165.117z M189.621,173.586c0.41,0.037,0.713,0.401,0.676,0.813c-0.037,0.41-0.4,0.713-0.812,0.676l-18.968-1.745 l1.611-1.353L189.621,173.586L189.621,173.586z"
    />
    {/*Trim*/}
    <path 
        fill="rgb(0,0,0)" 
        d="M253.324,196.754l11.305-40.774c5.502-19.843,20.23-27.599,39.799-26.401 l78.272,4.785l5.914,9.021l-84.655-5.178c-18.272-1.117-27.086,5.951-31.021,20.143l-10.109,36.452l132.196,9.244l-0.548,2.579 L253.324,196.754L253.324,196.754z"
    />


    

		</svg>
		</div>

	);//return
}