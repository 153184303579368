
const Browser = () => { 


    var R = false;

    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) 
    {
        //console.log('Opera');
        R = 'OPERA';
    }
    else if(navigator.userAgent.indexOf("Chrome") !== -1 )
    {
        //console.log('Chrome');
        R = 'CHROME';
    }
    else if(navigator.userAgent.indexOf("Safari") !== -1)
    {
        //console.log('Safari');
        R = 'SAFARI';
    }
    else if(navigator.userAgent.indexOf("Firefox") !== -1 ) 
    {
        //console.log('Firefox');
        R = 'FIREFOX';
    }
    else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) //IF IE > 10
    {
        //console.log('IE');
        R = 'IE';
    }  
    else 
    {
        //console.log('unknown');
        R = 'UNKNOWN';
    }


    
    //console.log(R);

    return R;

};



const Mobile = () => { 

    var is_mobile;
    var agent;

    is_mobile = false;
    agent = false;

    //#########################################
    //SIMPLE VERSION

    if(navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
        
        is_mobile = true;
        agent = 'other';
        
        if(navigator.userAgent.match(/Android/i)) agent = 'android';
        if(navigator.userAgent.match(/webOS/i)) agent = 'webOS';
        if(navigator.userAgent.match(/iPhone/i)) agent = 'iphone';
        if(navigator.userAgent.match(/iPad/i)) agent = 'ipad';
        if(navigator.userAgent.match(/iPod/i)) agent = 'ipod';
        if(navigator.userAgent.match(/BlackBerry/i)) agent = 'blackberry';
        if(navigator.userAgent.match(/Windows Phone/i)) agent = 'windows phone';


    }

    //########################################

    return {

        'is_mobile':is_mobile,
        'mobile_agent':agent
    };
    

};



export {


    Browser,
    Mobile



}



