import styled from "styled-components";


export const DynamicForm = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#edeef1",
    width:              props.width || "100%",
    height:             props.height || "500px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    min-height:100px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;


`;




export const Section = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "25px 0 200px 0"
  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;

`;



