//import Composer from '../ConstructComposer/Composer';

const ConstructComposer = (props) => {

    //console.log(props);
    //console.log(JSON.stringify(Composer,null,2));


    //console.log("ConstructComposer - items: "+JSON.stringify(props.data.items,null,2));

    /*

    {
        "choice": "email",
        "name": "Test Form",
        "email": "comments@powerdigitalmedia.com",
        "price": "0.00",
        "felems": [
            {
                "element": "input",
                "call": "text",
                "value": "Name"
            },
            {
                "element": "inputspecial",
                "call": "email",
                "value": "email"
            },
            {
                "element": "tbar",
                "call": "normal",
                "value": "Favorite Color"
            },
            {
                "element": "clickselect",
                "call": "radio",
                "value": [
                    "Red",
                    "Yellow",
                    "Green"
                ]
            },
            {
                "element": "tbar",
                "call": "normal",
                "value": "Favorite Code"
            },
            {
                "element": "clickselect",
                "call": "checkbox",
                "value": [
                    "Javascript",
                    "Php",
                    "Python",
                    "Java",
                    "NodeJS"
                ]
            },
            {
                "element": "tbar",
                "call": "normal",
                "value": "Message"
            },
            {
                "element": "inputspecial",
                "call": "textarea",
                "value": "Message"
            }
        ]
    }

    */


    let formItem = props.data.items[0];
    let felems = formItem.felems;
    
    let object,prevObject;
    //var elob = {};
    //var keys;
    var key;
    //var keyB;

    // var count = 0;
    // for(object of felems)
    // {
    //     if(object.element.match(/input/gi))
    //     {
    //         key = object.value;
    //         key = key.toLowerCase();
    //         key = key.replace(/ /gi,"_");

    //         elob[key] = object.value;

    //     }

    //     if(object.element.match(/clickselect/gi))
    //     {
    //         prevObject = felems[count-1];
    //         //alert(prevObject.value);

    //         key = prevObject.value;
    //         key = key.toLowerCase();
    //         key = key.replace(/ /gi,"_");
    //         //console.log("key: "+key);

    //         elob[key] = object.value;
    //     }

    //     count++;

    // }//for 




    //console.log("elob: "+JSON.stringify(elob,null,2));
    /*
    elob: {
        "name": "Name",
        "email": "email",
        "favorite_color": [
          "Red",
          "Yellow",
          "Green"
        ],
        "favorite_code": [
          "Javascript",
          "Php",
          "Python",
          "Java",
          "NodeJS"
        ],
        "message": "Message"
    }
    */




    let R = [];

    //==========================================
    //header
    //==========================================

    R.push({

        "call":"header",
        "data":{

            "text":formItem.name
        }

    });


    var count = 0;
    for(object of felems)
    {

       
        //==========================================
        //bar
        //==========================================

        if(object.element.match(/tbar/gi))
        {
            key = object.value;
            key = key.toLowerCase();
            key = key.replace(/ /gi,"_");

            //elob[key] = object.value;


            if(count > 1)
            {
                R.push({
                    "call"   :"spacer",
                    "data"      :{
            
                        "name"		:'spacer-'+count,
                        "color"   	:'transparent',
                        "height"  	:25
            
                    }
            
                });
            
            }

            R.push({

                "call"   :"bar",
                "data"      :{

                    "text"  :object.value

                }

            });

        }


    
        //==========================================
        //fields
        //==========================================

        if(object.element.match(/input/gi))
        {
            key = object.value;
            key = key.toLowerCase();
            key = key.replace(/ /gi,"_");

            //elob[key] = object.value;


            if(key === 'email')
            {
                R.push({

                    call:"fields",
                    data:[{


                        "fieldtype"               	:"text",
                        "required"					:true,

                        "class"                   	:'',
                        "id"                      	:key,
                        "hint"                    	:key.toUpperCase(),

                        "text"                     	:'',

                        "keyboardType"            	:"email",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",

                        //"secure"                  :true,
                        //"returnKeyType"           :"done"

                    }]
                
                });

            }else{

                R.push({

                    call:"fields",
                    data:[{


                        "fieldtype"               	:object.call,
                        "required"					:true,

                        "class"                   	:'',
                        "id"                      	:key,
                        "hint"                    	:key.toUpperCase(),

                        "text"                     	:'',

                        "keyboardType"            	:"",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",

                    }]
                
                });


            }



        }



        //==========================================
        //radiobuttons,checkboxes
        //==========================================

        if(object.element.match(/clickselect/gi))
        {
            prevObject = felems[count-1];
            //alert(prevObject.value);

            key = prevObject.value;
            key = key.toLowerCase();
            key = key.replace(/ /gi,"_");
            //console.log("key: "+key);

            //elob[key] = object.value;


            switch(object.call)
            {
            case'radio':

                R.push({

                    "call"   :"radiobuttons",
                    "data"      :{
                        
                        "call"      :'rad',
                        "required"	:true,
                        "name"      :key,
                        "array"     :object.value,
                        "choice"    :[]

                    }


                });



            break;
            case'checkbox':


                R.push({

                    "call"   :"checkboxes",
                    "data"      :{
                        
                        "call"      :'chk',
                        "required"	:false,
                        "name"      :key,
                        "array"     :object.value,
                        "choice"    :[]
            
                    }
            
            
                });
            

            break;
            default:
            }



        }

        count++;

    }//for 




    //==========================================
    //captcha
    //==========================================

    R.push({
        "call"   :"spacer",
        "data"      :{

            "name"		:"spacer-captcha",
            "color"   	:'transparent',
            "height"  	:25

        }

    });


    R.push({

        "call"   :"bar",
        "data"      :{

            "text"  :"Enter Code"

        }

    });


    R.push({

        "call"   :"captcha",
        "data"      :{

            "text"  :'Captcha',   
        }

    });




    //==========================================
    //button
    //==========================================

    R.push({
        "call"   :"spacer",
        "data"      :{

            "name"		:"spacer-button",
            "color"   	:'transparent',
            "height"  	:50

        }

    });

    R.push({

        "call"   :"button",
        "data"      :{

            "text"  :'Submit',
            "class" :'btn-submit',
            "funct"	:{

                "name":"FormSubmit",
                "object":false

            },
            "action":'submit'
            
        }

    });



    //==========================================
    //bottom spacer
    //==========================================

    R.push({
        "call"   :"spacer",
        "data"      :{

            "name"		:"bottom",
            "color"   	:'transparent',
            "height"  	:10

        }

    });



    //console.log("ConstructComposer - R: "+JSON.stringify(R,null,2));


    return R;
    //return Composer;

};

export default ConstructComposer;
