export const BUTTON_MAIN_BODY = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Body*/}

   
    <path 
        fill="rgb(130,130,130)" 
        d="M658.055,311.692v-37.922l-12.847-24.654l-34.834-20.111l-171.599-21.069l-349.7-24.455l-8.586,16.67 l198.319,6.284c0.828,0.026,1.479,0.72,1.453,1.548s-0.719,1.479-1.547,1.453l-199.013-6.307l-1.233,70.643H62.844l-0.942,54 l32.501,66.6l563.696-0.094L658.055,311.692z"
    />
    

{/*Body Lines*/}
    
    <path 
        fill="rgb(255,255,255)" 
        d="M647.054,379.248c0.828,0,1.5,0.672,1.5,1.5c0,0.829-0.672,1.501-1.5,1.501h-44.947	c-0.828,0-1.5-0.672-1.5-1.501c0-0.828,0.672-1.5,1.5-1.5H647.054L647.054,379.248z M127.408,379.248c0.828,0,1.5,0.672,1.5,1.5	c0,0.829-0.672,1.501-1.5,1.501H93.94c-0.829,0-1.5-0.672-1.5-1.501c0-0.828,0.672-1.5,1.5-1.5H127.408L127.408,379.248z M481.482,379.248c0.828,0,1.5,0.672,1.5,1.5c0,0.829-0.672,1.501-1.5,1.501H244.361c-0.829,0-1.5-0.672-1.5-1.501 c0-0.828,0.671-1.5,1.5-1.5H481.482L481.482,379.248z M270.375,334.059c-0.828-0.036-1.471-0.737-1.435-1.565 c0.035-0.828,0.735-1.471,1.564-1.435l163.296,7.129l0.039,0.002c1.599,0.073,3.231,0.325,4.871,0.725	c1.617,0.395,3.214,0.93,4.768,1.574c7.961,3.306,15.152,9.64,18.416,15.003c0.43,0.705,0.206,1.627-0.499,2.057 c-0.705,0.431-1.626,0.207-2.056-0.498c-2.987-4.909-9.625-10.729-17.01-13.796c-1.434-0.595-2.883-1.083-4.322-1.434 c-1.418-0.346-2.857-0.565-4.297-0.631l-0.039-0.001L270.375,334.059L270.375,334.059z M653.754,289.42c0.828,0,1.5,0.672,1.5,1.5 s-0.672,1.5-1.5,1.5h-25.993c-0.829,0-1.501-0.672-1.501-1.5s0.672-1.5,1.501-1.5H653.754L653.754,289.42z M95.214,285.979	c0.828,0,1.5,0.672,1.5,1.5c0,0.829-0.672,1.501-1.5,1.501H69.221c-0.828,0-1.5-0.672-1.5-1.501c0-0.828,0.672-1.5,1.5-1.5H95.214 L95.214,285.979z M476.328,248.809c0.77,0.301,1.15,1.169,0.85,1.939c-0.301,0.77-1.169,1.15-1.939,0.85 c-11.897-4.644-24.447-7.623-36.791-9.623c-12.319-1.996-24.535-3.021-35.7-3.75l0,0l-147.31-9.01 c-0.828-0.051-1.46-0.764-1.412-1.592c0.049-0.828,0.76-1.459,1.588-1.408l147.316,9.01h0.006 c11.157,0.729,23.413,1.76,35.982,3.797C451.463,241.054,464.224,244.085,476.328,248.809L476.328,248.809z"
/>

{/*Wheels*/}

    <path 
        fill="rgb(255,255,255)" 
        d="M183.549,235.683c43.678,0.763,78.68,37.008,77.918,80.687	c-0.763,43.678-37.008,78.681-80.687,77.918s-78.68-37.009-77.918-80.687C103.625,269.922,139.871,234.92,183.549,235.683 L183.549,235.683z M547.166,235.683c43.678,0.763,78.68,37.008,77.918,80.687c-0.763,43.678-37.009,78.681-80.687,77.918 c-43.679-0.763-78.681-37.009-77.918-80.687C467.242,269.922,503.487,234.92,547.166,235.683L547.166,235.683z"
/>

{/*Hardtop Outline*/}

    <path 
        fill="rgb(150,150,150)" 
        d="M430.011,198.691l-54.199-82.667l13.63,0.833l9.259,9.967c-0.612,1.231-0.854,2.385-0.45,3.696 l46.812,71.398c0.453,0.692,1.383,0.886,2.075,0.433c0.691-0.453,0.886-1.381,0.433-2.073l-26.104-39.815 c0.073-2.408,0.865-4.752,2.243-6.72l47.958,51.622c0.563,0.605,1.51,0.64,2.114,0.077s0.64-1.511,0.077-2.116l-82.637-88.951 l-1.008-0.48c-6.73-0.412-13.46-0.824-20.19-1.235l0,0l-55.385-3.388c-16.244-0.993-32.402-1.981-47.867,0.006 c-15.548,1.998-30.261,7.003-43.386,18.017l-62.457,52.407c-0.633,0.531-0.717,1.477-0.186,2.111	c0.531,0.633,1.475,0.717,2.108,0.186l61.985-52.012c3.959,0.353,5.001,1.688,4.672,3.344c-0.47,2.361-2.84,5.438-4.861,7.921 l-0.029,0.035l-25.127,32.747c-0.503,0.655-0.378,1.594,0.278,2.096c0.657,0.501,1.598,0.376,2.101-0.279l25.127-32.746l0-0.001 c2.236-2.749,4.853-6.183,5.453-9.198c0.591-2.973-0.309-5.426-4.442-6.473c11.939-9.104,25.184-13.396,39.138-15.189	c15.099-1.941,31.165-0.958,47.316,0.029l57.618,3.524l10.002,15.257l-75.291-4.604c-14.572-0.891-26.587,3.061-34.361,13.076 c-1.509,1.945-2.854,4.116-4.021,6.521c-1.153,2.378-2.134,4.989-2.928,7.844l-0.004-0.001c-0.007,0.022-0.013,0.047-0.02,0.069 l-9.199,33.192c-0.221,0.797,0.246,1.62,1.042,1.84c0.795,0.221,1.62-0.246,1.841-1.042l9.203-33.193	c0.707-2.55,2.581-7.12,2.76-7.408c1.302-2.091,2.31-4.212,3.692-5.993c7.094-9.139,18.229-12.734,31.818-11.904l77.523,4.739 l1.949,2.974l1.908,2.91l-81.673-4.995c-9.479-0.579-16.555,0.999-21.818,4.595c-5.295,3.617-8.669,9.211-10.731,16.649 l-8.721,31.444c-0.22,0.796,0.247,1.62,1.042,1.84c0.796,0.22,1.621-0.246,1.841-1.043l8.721-31.444 c1.872-6.753,4.874-11.79,9.536-14.974c4.694-3.207,11.162-4.604,19.955-4.066l83.905,5.131l37.451,57.123 c0.453,0.691,1.383,0.885,2.075,0.432C430.27,200.312,430.464,199.383,430.011,198.691L430.011,198.691z M421.635,151.512	c-1.184,1.563-2.086,3.326-2.616,5.221l-17.906-27.313L421.635,151.512L421.635,151.512z"
/>



    

		</svg>
		</div>

	);//return
}