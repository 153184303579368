export const BUTTON_LOGO_TRS9 = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Logo*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M331.632,141.492l25.557,0.047c2.799,0,4.046,1.663,4.046,3.257v2.395 c0,1.595-1.247,3.258-4.046,3.258h-21.224h-44.457c-10.44,0-15.336,6.479-15.336,15.553c0,8.153,0.001,16.308,0.001,24.462
		l104.136-0.062v-15.549l-81.018,0.058c-2.8,0-4.048-1.663-4.048-3.258v-2.395c0-1.594,1.248-3.257,4.048-3.257h33.224h32.455 c10.44,0,15.337-6.48,15.337-15.553v-8.909c0-9.072-4.896-15.554-15.337-15.554h-41.699l-31.26-0.013
		c-10.44,0-15.336,6.48-15.336,15.553v3.266l19.071-0.008c0-1.594,1.248-3.258,4.048-3.258L331.632,141.492L331.632,141.492z M388.41,154.814h26.144v8.992H388.41V154.814L388.41,154.814z M223.158,166.001h21.223c2.8,0,4.048,1.663,4.048,3.257v2.395
		c0,1.595-1.248,3.258-4.048,3.258L162,174.957v15.506h90.163c10.441,0,15.337-6.48,15.337-15.553v-8.909 c0-9.073-4.896-15.553-15.337-15.553h-32.454h-33.226c-2.799,0-4.046-1.663-4.046-3.258v-2.395c0-1.594,1.247-3.257,4.046-3.257
		h79.866v-15.554h-87.648c-10.44,0-15.336,6.481-15.336,15.554v8.909c0,9.072,4.896,15.553,15.336,15.553H223.158L223.158,166.001z M450.198,154.865v-13.326h65.854c3.664,0,6.663,2.998,6.663,6.662l0,0c0,3.666-2.999,6.664-6.663,6.664H450.198L450.198,154.865z
		 M428.277,125.985c0,0,87.904,0,98.194,0c10.289,0,20.109,2.197,20.109,22.138c0,19.939-17.185,19.939-20.78,19.939l32.199,22.4	h-37.284l-30.586-22.4h-39.932v22.4h-21.921V125.985L428.277,125.985z M249.461,245.15h-27.17v129.307h-32.725V245.15H162v-31.932
		h87.461V245.15L249.461,245.15z M352.59,374.457h-31.732c-1.058-7.933-1.652-16.923-1.784-26.972c0-8.858-0.331-14.809-0.992-17.85 c-1.189-5.288-3.966-10.114-8.329-14.478v-27.964c4.099-2.247,6.809-5.421,8.131-9.52c0.794-2.645,1.19-7.007,1.19-13.09
		c0-12.692-3.107-20.757-9.321-24.195v-27.171h13.089c10.578,0,18.511,6.941,23.799,20.824c3.438,8.991,5.156,17.651,5.156,25.981 c0,23.667-8.329,37.682-24.988,42.045c1.058,0.396,2.711,0.925,4.958,1.586c7.933,2.776,12.759,9.058,14.478,18.841
		c1.19,7.008,2.578,16.396,4.165,28.162c1.454,10.975,2.182,17.057,2.182,18.246V374.457L352.59,374.457z M298.249,374.457h-32.327 V213.219h32.327V374.457L298.249,374.457z M444.017,265.379h-27.367c0.396-6.347,0.131-11.635-0.795-15.866
		c-0.527-2.776-1.387-5.223-2.578-7.338c-1.189-2.115-1.783-3.372-1.783-3.769v-26.972c5.42,0.132,12.031,3.371,19.832,9.718	c8.461,7.008,12.691,13.619,12.691,19.832V265.379L444.017,265.379z M451.753,329.041c0,11.635-2.645,21.684-7.934,30.146
		c-6.479,10.445-16.264,16.726-29.352,18.841h-2.975v-28.162c5.156-2.38,7.734-7.734,7.734-16.064 c0-8.727-4.629-16.461-13.883-23.204c-8.594-5.949-17.123-11.966-25.584-18.047c-9.256-8.727-13.883-19.899-13.883-33.518
		c0-9.52,2.379-18.378,7.139-26.575c6.082-10.313,15.139-17.32,27.172-21.022v30.542c0,0.264-0.563,1.289-1.688,3.074 c-1.123,1.785-1.686,3.867-1.686,6.247c0,8.594,4.76,16.064,14.279,22.411c16.66,11.105,25.453,17.121,26.379,18.047
		C446.991,301.011,451.753,313.439,451.753,329.041L451.753,329.041z M400.188,378.027c-6.479-0.926-13.686-4.363-21.617-10.313 c-8.992-7.008-13.486-14.016-13.486-21.022v-27.766h28.162v21.815c0.131,1.322,2.445,4.099,6.941,8.33V378.027L400.188,378.027z
		 M553.294,284.418c0,18.113-0.926,33.781-2.775,47.003c-4.1,28.162-11.635,42.243-22.609,42.243h-4.563V212.426	c12.957,1.983,21.75,12.098,26.377,30.344C552.104,252.687,553.294,266.568,553.294,284.418L553.294,284.418z M511.845,314.365
		c-1.982,0.661-5.256,0.991-9.883,0.991c-21.982,0-32.955-16.593-32.955-49.779c0-12.561,1.982-23.468,5.949-32.724 c6.082-14.279,16.064-21.419,29.947-21.419h6.941v25.584c-5.949,0.396-9.652,3.769-11.107,10.114
		c-0.527,2.115-0.793,7.272-0.793,15.47s0.396,14.081,1.158,17.65c1.719,7.536,5.289,11.569,10.742,12.098V314.365L511.845,314.365z M511.845,377.234h-8.727c-7.271,0-14.08-2.645-20.428-7.934c-6.742-5.686-10.115-12.362-10.115-20.03v-17.453
		c6.381-0.925,15.635-2.38,27.766-4.362c0.662,1.586,0.859,3.569,0.596,5.949c0,4.495,0,6.677,0,6.545 c0,2.512,0.992,5.619,2.975,9.321c2.381,4.23,5.025,6.412,7.934,6.545V377.234L511.845,377.234z"
    />
    





    

		</svg>
		</div>

	);//return
}