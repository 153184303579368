export const BUTTON_LOGO_OEM = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

     
        


{/*Button*/}

    <path 
        fill="rgb(0,0,0)" 
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.152,199.752c0-18.424,3.575-31.578,10.725-39.416	c7.195-7.837,19.021-11.732,35.474-11.732c16.454,0,28.279,3.85,35.474,11.596c7.15,7.746,10.725,20.945,10.725,39.553v104.499 c0,18.517-3.575,31.67-10.679,39.462c-7.104,7.791-18.975,11.688-35.52,11.688s-28.417-3.896-35.52-11.688 c-7.104-7.792-10.679-20.945-10.679-39.462V199.752L194.152,199.752z M233.751,186.461v129.34c0,3.483,0.504,5.958,1.558,7.471
		c1.008,1.467,2.704,2.2,5.042,2.2c2.521,0,4.308-0.779,5.317-2.292c1.054-1.513,1.558-3.987,1.558-7.379v-129.34 c0-3.482-0.504-5.958-1.558-7.379c-1.009-1.421-2.796-2.154-5.317-2.154c-2.337,0-4.033,0.779-5.042,2.292	C234.255,180.732,233.751,183.161,233.751,186.461L233.751,186.461z M298.375,350.954h73.699v-29.929h-34.1v-66.319h33.274v-28.6 h-33.274v-45.649h34.1v-27.545h-73.699V350.954L298.375,350.954z M382.845,350.954h31.945c0-53.99-0.092-101.702-0.274-143.135	c0-2.658,0-4.217,0-4.538l1.328,0.092c0.643,9.35,1.283,17.463,1.971,24.383c0.688,6.967,1.33,12.191,1.926,15.767l18.424,107.432 h33.55l19.204-113.069c0-0.092,0.046-0.32,0.138-0.779c1.971-10.908,3.208-22.229,3.758-33.824l1.101,0.092
		c-0.275,8.893-0.505,16.867-0.643,23.971c-0.137,7.15-0.183,13.43-0.183,18.883v104.728h35.979V152.912h-54.129l-15.445,100.693	c-1.146,7.242-2.063,13.888-2.75,19.938c-0.642,6.05-1.191,12.191-1.649,18.471l-1.329,0.138c-0.093-0.642-0.184-1.513-0.275-2.704 c-1.009-14.025-2.017-25.116-3.116-33.138l-12.879-103.397h-56.649V350.954L382.845,350.954z"
    />
    





    

		</svg>
		</div>

	);//return
}