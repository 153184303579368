
const Handler = (props) => {

    //console.log(JSON.stringify(props,null,2));
    //console.log("Hander Call: "+props.call);



    var currentFlyr = props.zoneObj.currentFlyr;
    var scrollbox;
    var scrollpos;
    var flyrBase;
    var indx;


    // console.log("== HANDLER ==");
    // console.log(props.call);
    // console.log(currentFlyr);


    //alert("Flyr Handler - "+props.call);


    switch(props.call)
    {
    case'flyrHeight':

        props.handleState({

            call:props.call,
            value:props.data

        });
        
    break;
    case'adjustContentsHeight':

        // contents are set

        //console.log("CONTENTS HEIGHT: "+props.data.h);

        //setFlyrHeight(1000);//causes a reset
        //setContentsHeight(props.data.h);//causes a reset 

        props.handleState({call:'contentsHeight',value:props.data.h});
        

    break;
    case'handleDispatch':

        props.handleState({call:'handleDispatch',value:{

            action  : props.data.action,
            key     : props.data.key,
            value   : props.data.value

        }});

    break;
    case'setFormArr':

        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: props.data

        }});
        
    break;
    case'formSubmit':


        //alert('utils/components/Flyr/Handler - formSubmit');

        switch(props.submitCall)
        {
        case'account':

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runAccount",
                value   :true

            }});
        break;
        case'calculate':

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runCalculate",
                value   :true

            }});
        break;
        default:

            props.handleState({call:'handleDispatch',value:{

                action:"UPDATE_ZONE",
                key: currentFlyr + "_runSubmit",
                value: true

            }});

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_output",
                value   :props.data
    
            }});
    
        }


        //alert("POST TO: "+props.postTo);

        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_postTo",
            value: props.postTo

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_currentKey",
            value: props.currentKey

        }});


    break;
    case'capture':


        //console.log("FIELD CALL: "+props.fieldCall);

       
        let tempArr = [];
        for(var formObj of props.zoneObj[currentFlyr + "_formArr"])
        {
            tempArr.push(formObj);
        }


        //kill it - to avoid mutation error
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: []

        }});


        var object;
        var frm;
        var newArr;
        var i;
        var ij;
        var newChoice;


        switch(props.fieldCall)
        {
        case'clicker':


            //console.log("Clicker");


            frm = tempArr[props.key];

            newArr = props.newValue;

            newChoice = [];
            for(i=0; i < newArr.length; i++)
            {
                object = newArr[i];
                if(object['innerColor'] === 'orangered') 
                {
                    newChoice.push(object['clickerVal']);

                }//==

            }//== i

            frm['data']['choice'] = newChoice;


            tempArr[props.key] = frm;
            //props.handleState({call:'formArr',value:tempArr});

        
        break;
        case'slider':
        case'dualslider':


            //call        :'capture',
            //fieldCall   :"dualslider",
            //key         :props.indx,
            //data        :props.data,
            //newValue    :hndl.valueA

            //console.log("Slider / DualSlider" + props.newValue);
            frm = tempArr[props.key];
            //console.log("FRM: "+JSON.stringify(frm,null,2));
            frm['data']['value'] = props.newValue;

            tempArr[props.key] = frm;
          

        break;
        case'field':

            //console.log("Field");
            //console.log(props['key']);
            //console.log(props['id']);

            ij = props.key.split("-");
        
            frm = tempArr[ij[0]];
            if(frm.section === 'fields')
            {

                frm.data[ij[1]]['text'] = props.newValue;

                //var fields = frm.data;
                //var field = fields[ij[1]];
                //field['text'] = props.newValue;
                
                tempArr[ij[0]] = frm;
                //props.handleState({call:'formArr',value:tempArr});

            }


        break;
        case'labelfield':

            //console.log("Label Field");
            //console.log(props['key']);
            //console.log(props['id']);
            //console.log("VALUE: "+props.newValue);

            ij = props.key.split("-");
        
            frm = tempArr[ij[0]];
            if(frm.section === 'labelfields')
            {

                frm.data[ij[1]].field.text = props.newValue;

                tempArr[ij[0]] = frm;
                //props.handleState({call:'formArr',value:tempArr});

            }

        break;
        default:
        }//===
    


        //reset it - after edit
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: tempArr

        }});

        

    break;
    case'resetProblemArr':

        console.log(JSON.stringify(props.errors,null,2));

        props.handleState({call:'problemArr',value:props.errors});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :props.errors

        }});


    break;
    case'clear':


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :[]

        }});


    break;
    case'output':

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runOutput",
            value   :true

        }});

        //alert('output data....'+JSON.stringify(props.data));

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_output",
            value   :props.data

        }});


        
    break;
    case'error':


        //props.handleState({call:'hashData',value:false});
        //props.handleState({call:'processSubmit',value:false});
        //props.handleState({call:'processError',value:true});
        //props.handleState({call:'errorType',value:props.type});
        //props.handleState({call:'problemArr',value:props.errors});

        //console.log(props.type);
        //console.log(Object.prototype.toString.call(props.errors));
        console.log("ERROR - PROBLEM ARR: "+JSON.stringify(props.errors));

     
        
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        /*
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runComplete",
            value   :false

        }});
        */
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runError",
            value   :true

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :props.type

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :props.errors

        }});




        // -- adjust scroller -- //

        scrollbox = document.getElementById('flyrBase');

        if(props.errors
        && props.errors !== null
        && props.errors !== undefined
        )
        {
            if(props.errors.length > 0)
            {

                var keystr = props.errors[0]['key'].toString();
                var firstkey = keystr;
                if(firstkey.match(/-/gi))
                {
                    indx = firstkey.split("-")[0];
                }else{
                    indx = firstkey;
                }//==

                var formSection = document.getElementById("formSection_"+indx);

                //alert(formSection+" "+indx);

                if(formSection !== null)
                {
                    //alert(formSection.offsetTop);
                    scrollbox.scrollTop = formSection.offsetTop;
                }else{
                    scrollbox.scrollTop = 0;
                }//==

            
            }else{

                scrollbox.scrollTop = 0;

            }
            
        }



    break;
    case'submit':
      
        //props.handleState({call:'hashData',value:"#!"});
        //props.handleState({call:'processSubmit',value:true});
    
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});
        }
        */


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :true

        }});



    break;
    case'complete':

        //props.handleState({call:'hashData',value:"#!"});
        //props.handleState({call:'processComplete',value:true});
     
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */




        // -- KILL: formArr - problemArr - errorType -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_formArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :''

        }});


        // -- KILL : account - calculate - output - postTo - completeMessage  -- //


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_output",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"DELETE_ZONE",
            key: currentFlyr + "_postTo",
            value: false

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"DELETE_ZONE",
            key: currentFlyr + "_currentKey",
            value: false

        }});


        
        // -- SET: complete -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runComplete",
            value   :true

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_completeMessage",
            value   :props.message

        }});



        if(props.reset)
        {

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :props.reset.key,
                value   :props.reset.value

            }});

        }



    break;
    case'timeout':




        // -- CLEAR = account - calculate - submit - error - errorType -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runError",
            value   :false

        }});

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :''

        }});




        // -- prime handler -- //

        //alert('timeout - primeHandle');

        props.handleState({call:'primeHandle',value:{

            handle  :'messngr',
            action  :'hideMessngr'

        }});
        
        props.handleRoute({call:"goBack",value:""});


    break;
    case'goback':

        props.handleRoute({call:"goBack",value:""});

    break;
    default:

        console.log("Flyr Handler Default");



        // reset 

        if(document.getElementById('scrollbox')
        && document.getElementById('scrollbox') !== undefined
        )
        {
            scrollbox = document.getElementById('scrollbox');
            scrollpos = scrollbox.scrollTop;
            //scrollpos = 0;
            scrollbox.scrollTop = scrollpos;

        }

        if(document.getElementById('flyrBase')
        && document.getElementById('flyrBase') !== undefined
        )
        {
            flyrBase = document.getElementById('flyrBase');
            flyrBase.scrollTop = 0;
        }








        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runError",
            value   :false

        }});




        // -- formArr - problemArr - errorType -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_formArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :''

        }});





        // -- account - calculate - output - postTo - completeMessage  -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runOutput",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_output",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_postTo",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"DELETE_ZONE",
            key: currentFlyr + "_currentKey",
            value: false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_completeMessage",
            value   :false

        }});







        if(props.zoneObj[currentFlyr + "_runComplete"])
        {

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runComplete",
                value   :false
    
            }});


            //close flyr
            props.handleState({

                call:"handleCloseFlyr",
                value:''
    
            });

        }else{

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runComplete",
                value   :false
    
            }});

        }



        if(props.call === 'closeFlyr')
        {
            props.handleState({call:'handleCloseFlyr',value:false});

        }

    break;
    }//==


}


export default Handler;

