


const SceneInfo = [



    //TM: Billboard 1


    //##########################################################
    //##########################################################
    //##########################################################

    // Billboard 
    

    {
        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px"
        },

        "call": "Billboard",

        "info": {
            "call": "collage",
            "gridType": "Fixed",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "container",
            "modCall": "fill_div",
            "cells": {
                "call": "default",
                "total": 12,
                "across": 3
            },
            "thumb": {
                "call": "default",
                "diff": "0",
                "bgColor": "black"
            },
            "cellBorder": {
                "boolean": false,
                "thickness": 0,
                "color": "lightgray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            },
            "mask": {
                "boolean": false,
                "message": {
                    "boolean": false,
                    "backgroundColor": "#4CAF50",
                    "textColor": "white"
                }
            },

            "view": "fullScreen",
            "opacity": 0.7,
            "webkitFilter": "grayscale(0%)",
            "filter": "grayscale(0%)"

        },


        "items":[

            {

                "overlay": {

                    "category":"CATEGORY NAME",
                    "title":'Your are viewing default overlay data from the "#1 Billboard Scene Info"',
                    "button":{


                        "name":"Read More",
                        "url":"/TrialPage",

                        "style":{

                            "backgroundColorOver":"#02aef6",
                            "backgroundColorOut":"#0291cd",

                            "borderOver":"1px solid #02aef6",
                            "borderOut":"1px solid #0291cd",

                            "textColorOver":"white",
                            "textColorOut":"white",

                        }

                    }

                },


                "alfa": {
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",
                    "zIndex": "10",
                    "decimal": "0.0",
                    "percent": "0"
    
                },
                "base": {
                    "border": "0",
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",           
                    "zIndex": "11"
                },
    

                "info": {
                    "call": "collage",
                    "gridType": "Fixed",
                    "gridBgColor": "transparent",
                    "gridCall": "grid_cell",
                    "cellCall": "container",
                    "modCall": "fill_div",
                    "cells": {
                        "call": "default",
                        "total": 1,
                        "across": 1
                    },
                    "thumb": {
                        "call": "default",
                        "diff": "0",
                        "bgColor": "black"
                    },
                    "cellBorder": {
                        "boolean": false,
                        "thickness": 0,
                        "color": "lightgray"
                    },
                    "gutter": {
                        "boolean": "0",
                        "size": "0"
                    },
                    "thresh": {
                        "refWidth": false,
                        "across1": "501",
                        "across2": "801",
                        "across3": "1051"
                    },
                    "mask": {
                        "boolean": false,
                        "message": {
                            "boolean": false,
                            "backgroundColor": "#4CAF50",
                            "textColor": "white"
                        }
                    },
        
                    "view": "fullScreen",
                    "opacity": 0.6,
                    "webkitFilter": "grayscale(0%)",
                    "filter": "grayscale(0%)"
        
                },


                "images": [

                    {
                    "image": {
                        "h": "608",
                        "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                        "w": "1080",
                    },
                    "id": "20210407067841",
                    "text": "",
                    "title": "f1-1",
                    "bgColor": "black"
                    }
        
                ]


            },


            {

                "info": {
                    "call": "collage",
                    "gridType": "Fixed",
                    "gridBgColor": "transparent",
                    "gridCall": "grid_cell",
                    "cellCall": "container",
                    "modCall": "fill_div",
                    "cells": {
                        "call": "default",
                        "total": 1,
                        "across": 1
                    },
                    "thumb": {
                        "call": "default",
                        "diff": "0",
                        "bgColor": "black"
                    },
                    "cellBorder": {
                        "boolean": false,
                        "thickness": 0,
                        "color": "lightgray"
                    },
                    "gutter": {
                        "boolean": "0",
                        "size": "0"
                    },
                    "thresh": {
                        "refWidth": false,
                        "across1": "501",
                        "across2": "801",
                        "across3": "1051"
                    },
                    "mask": {
                        "boolean": false,
                        "message": {
                            "boolean": false,
                            "backgroundColor": "#4CAF50",
                            "textColor": "white"
                        }
                    },
        
                    "view": "fullScreen",
                    "opacity": 0.7,
                    "webkitFilter": "grayscale(0%)",
                    "filter": "grayscale(0%)"
        
                },



                "alfa": {
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",
                    "zIndex": "10",
                    "decimal": "0.0",
                    "percent": "0"
    
                },
                "base": {
                    "border": "0",
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",           
                    "zIndex": "11"
                },
    



                "overlay": {

                    "category":"CATEGORY NAME",
                    "title":'Your are viewing default overlay data from the "#2 Billboard Scene Info"',
                    "button":{


                        "name":"Read More",
                        "url":"/TrialPage",

                        "style":{

                            "backgroundColorOver":"#02aef6",
                            "backgroundColorOut":"#0291cd",

                            "borderOver":"1px solid #02aef6",
                            "borderOut":"1px solid #0291cd",

                            "textColorOver":"white",
                            "textColorOut":"white",

                        }

                        

                    }

                },



                "images": [

                    {

                        "image": {
                            "h": "2677",
                            "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/M6027-SP1-AMG-11_-_Ferrari_SP1_Amalgam_Version_1.8_Scale_-_Rear_3.4_611ef627-0fbe-488c-ac45-1b2d57c0fe95_4000x2677_crop_center_sys20200427151838id_.jpg",
                            "w": "4000"
                        },
                        "id": "20200427151838",
                        "title": "TITLE",
                        "text": "Text",
                        "bgColor": "black"
             
                    }
    
        
                ]


            },



            {

                "info": {
                    "call": "collage",
                    "gridType": "Fixed",
                    "gridBgColor": "transparent",
                    "gridCall": "grid_cell",
                    "cellCall": "container",
                    "modCall": "fill_div",
                    "cells": {
                        "call": "default",
                        "total": 4,
                        "across": 4
                    },
                    "thumb": {
                        "call": "default",
                        "diff": "0",
                        "bgColor": "black"
                    },
                    "cellBorder": {
                        "boolean": false,
                        "thickness": 0,
                        "color": "lightgray"
                    },
                    "gutter": {
                        "boolean": "0",
                        "size": "0"
                    },
                    "thresh": {
                        "refWidth": false,
                        "across1": "501",
                        "across2": "801",
                        "across3": "1051"
                    },
                    "mask": {
                        "boolean": false,
                        "message": {
                            "boolean": false,
                            "backgroundColor": "#4CAF50",
                            "textColor": "white"
                        }
                    },
        
                    "view": "fullScreen",
                    "opacity": 0.6,
                    "webkitFilter": "grayscale(0%)",
                    "filter": "grayscale(0%)"
        
                },


                "alfa": {
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",
                    "zIndex": "10",
                    "decimal": "0.0",
                    "percent": "0"
    
                },
                "base": {
                    "border": "0",
                    "backgroundColor": "transparent",
                    "top": "0px",
                    "left": "0px",
                    "width": "100%",
                    "height": "100%",           
                    "zIndex": "11"
                },
    




                "overlay": {

                    "category":"CATEGORY NAME",
                    "title":'Your are viewing default overlay data from the "#3 Billboard Scene Info"',
                    "button":{


                        "name":"Read More",
                        "url":"/TrialPage",

                        "style":{

                            "backgroundColorOver":"#02aef6",
                            "backgroundColorOut":"#0291cd",

                            "borderOver":"1px solid #02aef6",
                            "borderOut":"1px solid #0291cd",

                            "textColorOver":"white",
                            "textColorOut":"white",

                        }

                    }

                },



                
        
                "images": [


                    {
                    "image": {
                        "h": "640",
                        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2139addfile_sys20200406239837id_.jpg",
                        "w": "427"
                    },
                    "id": "20200406239837",
                    "text": "",
                    "title": "2139addfile",
                    "bgColor": "black"
                    },
                    {
                    "image": {
                        "h": "424",
                        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/2657addfile_sys20200406237501id_.jpg",
                        "w": "640"
                    },
                    "id": "20200406237501",
                    "text": "",
                    "title": "2657addfile",
                    "bgColor": "black"
                    },
                    {
                    "image": {
                        "h": "400",
                        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3483addfile_sys20200406238952id_.jpg",
                        "w": "640"
                    },
                    "id": "20200406238952",
                    "text": "",
                    "title": "3483addfile",
                    "bgColor": "black"
                    },
                    {
                    "image": {
                        "h": "426",
                        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/ferrari/3489addfile_sys20200406240792id_.jpg",
                        "w": "640"
                    },
                    "id": "20200406240792",
                    "text": "",
                    "title": "3489addfile",
                    "bgColor": "black"
                    }


        
                ]


            }




        ]


    }







]

export default SceneInfo;