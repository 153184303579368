export const BANNER_ORANGE_ORANGE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 720 504";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

        
      


    
    {/*Windshield Banner*/}
    <path 
        fill="rgb(250,166,26)" 
        d="M417.684,162.526l-20.455-31.197 c-0.009-0.489,0.039-1.634,1.055-3.075l23.063,24.826C418.941,155.759,417.67,159.045,417.684,162.526L417.684,162.526z"
    />
    
    
    
    


    

		</svg>
		</div>

	);//return
}